import React, { Fragment } from 'react';
import { EuiTitle } from '@elastic/eui';

import { CreateComponent } from './CreateComponent';

export class ComponentTab extends React.Component {
  render() {
    return (
      <Fragment>
        <EuiTitle size={'s'}>
          <h2>{'Create Component'}</h2>
        </EuiTitle>
        <CreateComponent stackName={this.props.stackName} />
      </Fragment>
    );
  }
}
