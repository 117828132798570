import {
  Box,
  ColumnLayout,
  Container,
  Header,
  SpaceBetween,
  Alert,
} from '@amzn/awsui-components-react';
import { get } from 'lodash';
import React from 'react';
import { AnyObject } from '../../models/types';
import { ElasticResponse } from './hooks/useClusterInfoQuery';
import {DecommissionInfo} from "./DecommissionInfo";

interface ZoneWiseInfoProps {
  isLoading: boolean;
  elasticResponse: ElasticResponse;
  isCarbonDomain: string;
}

const ValueWithLabel = ({ label, children }) => (
  <div>
    <Box margin={{ bottom: 'xxxs' }} color="text-label">
      {label}
    </Box>
    <div>{children}</div>
  </div>
);

const ZoneWiseInfo = (props: ZoneWiseInfoProps) => {
  const clusterHealth: AnyObject =
    get(props, 'elasticResponse.cluster.health', {}) || {};
  let zones = [];
  for (let az in clusterHealth?.awareness_attributes?.zone) {
    zones.push(
      <SpaceBetween size="l">
        {props.isLoading ? '--' : <center><b><font size="+1">AZ: {az}</font></b></center>}
        <ColumnLayout columns={2} variant="text-grid">
        <SpaceBetween size="l">
        <ValueWithLabel label="Active Shards">
          {props.isLoading ? '--' : clusterHealth?.awareness_attributes?.zone[az]?.active_shards}
        </ValueWithLabel>
        <ValueWithLabel label="Initializing Shards">
          {props.isLoading ? '--' : clusterHealth?.awareness_attributes?.zone[az]?.initializing_shards}
        </ValueWithLabel>
        <ValueWithLabel label="Relocating Shards">
          {props.isLoading ? '--' : clusterHealth?.awareness_attributes?.zone[az]?.relocating_shards}
        </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
        <ValueWithLabel label="Unassigned Shards">
          {props.isLoading ? '--' : clusterHealth?.awareness_attributes?.zone[az]?.unassigned_shards}
        </ValueWithLabel>
        <ValueWithLabel label="Data Nodes">
          {props.isLoading ? '--' : clusterHealth?.awareness_attributes?.zone[az]?.data_nodes}
        </ValueWithLabel>
        <ValueWithLabel label="AZ Weight">
          {props.isLoading ? '--' : clusterHealth?.awareness_attributes?.zone[az]?.weight}
        </ValueWithLabel>
        </SpaceBetween>
        </ColumnLayout>
      </SpaceBetween>
    );
  }
  return (props.isCarbonDomain === 'true') ? (
    <Container header={<Header variant="h2">Zone Wise Info</Header>}>
        <SpaceBetween size="xxl">
        <DecommissionInfo
            elasticResponse={props.elasticResponse}
            isLoading={props.isLoading}
            isCarbonDomain={props.isCarbonDomain}
        />
        {props.isLoading ? '--' : ((zones.length !== 0) ? (
        <div>
          <ColumnLayout columns={3} variant="text-grid">
            {zones}
          </ColumnLayout><br/>
        <Alert header="Note..!!">
            The unassigned shards count at AZ level is based on a predictive model and the actual unassigned shards on
            Cluster level might be different. Unassigned shards count on AZ level can have a negative value as well.
        </Alert>
        </div>) : (
        <b>Zone-wise information is not available for this carbon domain.</b>))
      }
        </SpaceBetween>
    </Container>
  ) : (<></>);
};

export { ZoneWiseInfo };
