import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks/dist/cjs/interfaces';
import { TimezoneToggle } from '../../../Components/TimezoneToggle';

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Main distribution properties',
    options: [
      {
        id: 'issueType',
        label: 'issueType',
      },
      { id: 'tmsUpdateTimestamp', label: 'tmsUpdateTimestamp' },
      { id: 'tmsCreateTimestamp', label: 'tmsCreateTimestamp' },
      { id: 'details', label: 'details' },
      { id: 'sop', label: 'sop' },
    ],
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 Issues' },
  { value: 30, label: '30 Issues' },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 30,
  visibleContent: ['issueType', 'tmsUpdateTimestamp', 'tmsCreateTimestamp', 'details', 'sop'],
  wraplines: false,
};

export const columns = [
  {
    id: 'tmsUpdateTimestamp',
    sortingField: 'tmsUpdateTimestamp',
    name: 'TMS Update Timestamp',
    header: 'TMS Update Timestamp',
    cell: (e) => <TimezoneToggle date={new Date(e.tmsUpdateTimestamp)} />,
  },
  {
    id: 'tmsCreateTimestamp',
    sortingField: 'tmsCreateTimestamp',
    name: 'TMS Create Timestamp',
    header: 'TMS Create Timestamp',
    cell: (e) => <TimezoneToggle date={new Date(e.tmsCreateTimestamp)} />,
  },
  {
    id: 'details',
    sortingField: 'details',
    name: 'Details',
    header: 'Details',
    cell: (e) => e.details,
    truncateText: true,
  },
  {
    id: 'sop',
    sortingField: 'sop',
    name: 'Relevant SOP',
    header: 'Relevant SOP',
    cell: (e) => {
      if (e.sop === 'Not found')
        return (
          <a
            href="https://quip-amazon.com/RmQnA6iMF7Kw/Adding-SOP-for-an-Issue"
            target="_blank"
            rel="noopener noreferrer"
          >
            SOP to be added
          </a>
        );
      else
        return (
          <a href={e.sop} target="_blank" rel="noopener noreferrer">
            SOP
          </a>
        );
    },
    truncateText: true,
  },
];

export const FILTERING_PROPERTIES: readonly PropertyFilterProperty[] = [
  {
    propertyLabel: 'IssueType',
    key: 'issueType',
    groupValuesLabel: 'IssueType Values',
    operators: ['=', '!=', ':', '!:'],
  },
  {
    propertyLabel: 'TMS Update Timestamp',
    key: 'tmsUpdateTimestamp',
    groupValuesLabel: 'TMS Update Timestamp Values',
    operators: ['=', '!=', ':', '!:', '<', '<=', '>', '>='],
  },
  {
    propertyLabel: 'TMS Create Timestamp',
    key: 'tmsCreateTimestamp',
    groupValuesLabel: 'TMS Create Timestamp Values',
    operators: ['=', '!=', ':', '!:', '<', '<=', '>', '>='],
  },
  {
    propertyLabel: 'Details',
    key: 'details',
    groupValuesLabel: 'Details Values',
    operators: ['=', '!=', ':', '!:'],
  },
  {
    propertyLabel: 'SOP',
    key: 'sop',
    groupValuesLabel: 'SOP Values',
    operators: ['=', '!=', ':', '!:'],
  },
];
