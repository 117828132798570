import {
  Box,
  Button,
  Container,
  Flashbar,
  FormField,
  Input,
  SpaceBetween,
  Spinner,
} from '@amzn/awsui-components-react';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { CodeViewer } from '../../Components/Common/CodeViewer';
import { CommonDomainProps } from '../../models/types';
import { AppContext } from '../../utils/appContext';
import { useDomainProgressQuery } from './hooks/useDomainProgressQuery';

const BGProgress = (props: CommonDomainProps) => {
  const appContext = React.useContext(AppContext);
  const domainId = props.domainIdentifier;
  const [inputChangeId, setInputChangeId] = useState('');

  var [getProgress, { loading, data: domainProgress }] =
    useDomainProgressQuery();

  useEffect(() => {
    getProgress({
      variables: {
        domainId: domainId,
        changeId: inputChangeId === '' ? null : inputChangeId,
      },
    });
  }, [domainId, getProgress, inputChangeId]);

  const handleButtonClick = () => {
    getProgress({
      variables: {
        domainId: domainId,
        changeId: inputChangeId === '' ? null : inputChangeId,
      },
    });
  };

  let output = get(domainProgress, 'progress', 'Error Fetching Details');

  return (
    <Container
      header={
        <div>
          <Flashbar
            items={[
              {
                type: 'info',
                content: `Displaying progress results for the latest Change ID. To get info regarding a specific Change ID, please  use the input field.`,
              },
            ]}
          />
          <Flashbar
            items={[
              {
                type: 'info',
                content: `CLI Command: tumbler ${appContext.region.airportCode.toLowerCase()} aes domain cp describe-domain-change-progress -D ${
                  props.domainIdentifier
                }`,
              },
            ]}
          />
        </div>
      }
    >
      <SpaceBetween direction="vertical" size="xxl">
        {loading ? (
          <Box textAlign="center">
            <Spinner size="large" />
          </Box>
        ) : (
          <SpaceBetween direction="vertical" size="l">
            <SpaceBetween direction="horizontal" size="l">
              <FormField stretch label={<strong>Change Id</strong>}>
                <Input
                  onChange={({ detail }) => setInputChangeId(detail.value)}
                  value={inputChangeId}
                  inputMode="text"
                  placeholder="Enter Change Id"
                />
              </FormField>
              <FormField label={'-'}>
                <Button variant="primary" onClick={handleButtonClick}>
                  Submit
                </Button>
              </FormField>
            </SpaceBetween>
            <CodeViewer
              codeData={JSON.stringify(output, null, 4)}
              language={{ label: 'json', value: 'json' }}
              theme={{ label: 'coy', value: 'coy' }}
            />
          </SpaceBetween>
        )}
      </SpaceBetween>
    </Container>
  );
};

export { BGProgress };
