import {
  Button,
  Container,
  FormField,
  Header,
  Input,
  SpaceBetween,
  Spinner,
  TextContent,
} from '@amzn/awsui-components-react';
import * as React from 'react';
import { AppContext } from '../../utils/appContext';
import { useGetHawkeyeAnalysisStatusQuery } from './useGetHawkeyeAnalysisStatusQuery';

const AnalysisStatusChecker = (props) => {
  const appContext = React.useContext(AppContext);
  const [analysisId, setAnalysisId] = React.useState('');
  const [output, setOutput] = React.useState('');
  const command = `tumbler ${appContext.region.airportCode.toLowerCase()} aes hawkeye get-analysis-status -a MTA3NTQ4MzQ0MDM2Omhhd2tleWUxIzE2NDQ5OTQ4MDAjMyMw`;
  const [
    getHawkeyeAnalysisStatus,
    {
      loading: getHawkeyeAnalysisStatusLoading,
      data: getHawkeyeAnalysisStatusResponse,
    },
  ] = useGetHawkeyeAnalysisStatusQuery();

  const getAnalysisResult = () => {
    console.log(`Lets get analysis result for id ${analysisId}`);
    getHawkeyeAnalysisStatus({ variables: { analysisId: analysisId } });
  };
  React.useEffect(() => {
    if (
      getHawkeyeAnalysisStatusResponse &&
      getHawkeyeAnalysisStatusResponse.getHawkeyeAnalysisStatus
    ) {
      const response =
        getHawkeyeAnalysisStatusResponse.getHawkeyeAnalysisStatus.record;
      setOutput(JSON.stringify(response));
    } else if (
      getHawkeyeAnalysisStatusResponse &&
      !getHawkeyeAnalysisStatusLoading
    ) {
      setOutput(
        `Analysis id: ${analysisId} is not valid. Please enter valid id`
      );
    }
  }, [
    analysisId,
    getHawkeyeAnalysisStatusLoading,
    getHawkeyeAnalysisStatusResponse,
  ]);
  return (
    <Container
      header={
        <Header variant="h2" description={command}>
          Analysis Status
        </Header>
      }
    >
      <SpaceBetween direction="vertical" size="l">
        <FormField stretch label={<strong>Analysis ID</strong>}>
          <Input
            onChange={({ detail }) => setAnalysisId(detail.value)}
            value={analysisId}
            inputMode="text"
            placeholder="Enter analysis id"
          />
        </FormField>
        <FormField>
          <Button variant="primary" onClick={getAnalysisResult}>
            Submit
          </Button>
        </FormField>
        {getHawkeyeAnalysisStatusLoading && <Spinner size="large" />}
        {!getHawkeyeAnalysisStatusLoading && getHawkeyeAnalysisStatusResponse && (
          <TextContent>
            <span
              className="custom-wrapping"
              style={{
                fontFamily: 'courier, monospace',
                backgroundColor: '#eee',
                borderRadius: '3px',
                padding: '0 3px',
              }}
            >
              {output}
            </span>
          </TextContent>
        )}
      </SpaceBetween>
    </Container>
  );
};

export { AnalysisStatusChecker };
