export interface selectedOptionsType {
  value: string;
  label: string;
}

export interface LogOption {
  env: string;
  label: string;
  value: string;
}
export interface LogsGroupOptions {
  label: string;
  value: string;
  options: LogOption[];
}

export const ES_LOGS = {
  label: 'Elasticsearch',
  value: 'Elasticsearch',
  options: [
    {
      label: 'elasticsearch.log',
      value: 'elasticsearch.log',
      env: 'ES',
    },
    {
      label: 'supervisord.log',
      value: 'supervisord.log',
      env: 'ES',
    },
    {
      label: 'supervisord.es.log',
      value: 'supervisord.es.log',
      env: 'ES',
    },
    {
      label: 'supervisord.pa.log',
      value: 'supervisord.pa.log',
      env: 'ES',
    },
    {
      label: 'elasticsearch_index_search_slowlog.log',
      value: 'elasticsearch_index_search_slowlog.log',
      env: 'ES',
    },
    {
      label: 'elasticsearch_index_indexing_slowlog.log',
      value: 'elasticsearch_index_indexing_slowlog.log',
      env: 'ES',
    },
    {
      label: 'read-node-discovery-file.general.log.*',
      value: 'read-node-discovery-file.general.log.*',
      env: 'ES',
    },
    {
      label: 'write-node-discovery-file.general.log.*',
      value: 'write-node-discovery-file.general.log.*',
      env: 'ES',
    },
  ],
};

export const SERVICE_PROXY_LOGS = {
  label: 'ServiceProxy',
  value: 'ServiceProxy',
  options: [
    {
      label: 'haproxy.log',
      value: 'haproxy.log',
      env: 'ServiceProxy',
    },
    {
      label: 'supervisord.log',
      value: 'supervisord.log',
      env: 'ServiceProxy',
    },
  ],
};

export const SDPD_AGENT_LOGS = {
  label: 'SDPDAgent',
  value: 'SDPDAgent',
  options: [
    {
      label: 'sdpd_agent.log.*',
      value: 'sdpd_agent.log.*',
      env: 'SDPDAgent',
    },
    {
      label: 'supervisord.log',
      value: 'supervisord.log',
      env: 'SDPDAgent',
    },
    {
      label: 'supervisord.sdpd.log',
      value: 'supervisord.sdpd.log',
      env: 'SDPDAgent',
    },
    {
      value: 'sdpd_metrics.log.*',
      label: 'sdpd_metrics.log.*',
      env: 'SDPDAgent',
    },
    {
      label: 'service_log.*',
      value: 'service_log.*',
      env: 'SDPDAgent',
    },
  ],
};

export const LOG_PUSHER_LOGS = {
  value: 'LogPusher',
  label: 'LogPusher',
  options: [
    {
      label: 'supervisord.log',
      value: 'supervisord.log',
      env: 'LogPusher',
    },
    {
      label: 'cw.agent.log.*',
      value: 'cw.agent.log.*',
      env: 'LogPusher',
    },
    {
      label: 'cwagent.metrics.log.*',
      value: 'cwagent.metrics.log.*',
      env: 'LogPusher',
    },
  ],
};

export const INSTANCE_CONTROLLER_LOGS = {
  value: 'InstanceController',
  label: 'InstanceController',
  options: [
    {
      label: 'PMAdmin.log',
      value: 'PMAdmin.log',
      env: 'InstanceController',
    },
  ],
};

export const BOOT_LOGS = {
  value: 'BootLogs',
  label: 'BootLogs',
  options: [
    {
      label: 'a9-ec2-boot-start.log',
      value: 'a9-ec2-boot-start.log',
      env: 'Boot',
    },
  ],
};

export const KBN_LOGS = {
  value: 'Kibana',
  label: 'Kibana',
  options: [
    {
      label: 'kibana.log',
      value: 'kibana.log',
      env: 'Kibana',
    },
    {
      value: 'supervisord.log',
      label: 'supervisord.log',
      env: 'Kibana',
    },
    {
      label: 'supervisord.kibana.log',
      value: 'supervisord.kibana.log',
      env: 'Kibana',
    },
  ],
};

export const tableSearchParameters: selectedOptionsType[] = [
  {
    value: 'all',
    label: 'All nodes',
  },
  {
    value: 'data',
    label: 'Data nodes',
  },
  {
    value: 'master',
    label: 'Master nodes',
  },
  {
    value: 'warm',
    label: 'Warm nodes',
  },
];
