export const ComponentTableColumnDefinition = [
  {
    field: 'componentName',
    name: 'Component Name',
    dataType: 'string',
    sortable: true,
  },
  {
    field: 'majorVersion',
    name: 'Version',
    render: (major, component) => {
      return `${major}.${component.minorVersion}`;
    },
  },
  {
    field: 'componentScopeDefinition',
    name: 'Component Scope',
    render: (data) => {
      return data.componentScope;
    },
  },
  {
    field: 'componentScopeDefinition',
    name: 'Component Scope Value',
    render: (data) => {
      return data.componentScopeValue || '-';
    },
  },
];
