// Contains function that sets the node skews
import React from 'react';
import { AnyObject } from '../../../../models/types';
import {
  getDataInBytes,
  getHumanReadableSize,
  getMedianAndPercent,
} from './constants';

export const setNodeSkewData = (
  allocationResp: Array<AnyObject>,
  diNodeMapping: { [key: string]: Set<string> },
  di: string,
  nodeBoxTypeMapping: { [key: string]: string },
  nodeShardCount: Array<AnyObject>,
  nodeShardStorage: Array<AnyObject>,
  dropdowns: Array<Array<boolean>>
) => {
  const nodeShardStorageThreshold = 15;
  // Mapping of boxtypes (hot,warm) to the nodes of that type with shards and size info
  // {boxType1->[node1->{shards->6,size->123}]} and so on for each boxType
  // {Not Present->[...]} if no boxType attribute is present
  const boxtypeNode: { [key: string]: AnyObject } = {};
  // Sample object in the allocationResp
  // {
  //   "node": "egWT5Xh",
  //   "host": "172.16.27.79",
  //   "disk.percent": "50",
  //   "ip": "172.16.27.79",
  //   "disk.total": "1.4tb",
  //   "nodisk.availde": "752.4gb",
  //   "shards": "59",
  //   "disk.indices": "754.7gb",
  //   "disk.used": "759.4gb"
  // }
  for (let obj of allocationResp) {
    let len = Object.keys(obj).length;
    let [ip, shards, diskIndices] = [obj.ip, obj.shards, obj['disk.indices']];
    if (len > 2 && diNodeMapping[di].has(ip)) {
      if (nodeBoxTypeMapping[ip] === undefined) {
        nodeBoxTypeMapping[ip] = 'Not Present';
      }
      if (nodeBoxTypeMapping[ip] in boxtypeNode) {
        boxtypeNode[nodeBoxTypeMapping[ip]][ip] = {
          shards: parseInt(shards),
          size: getDataInBytes(diskIndices),
        };
      } else {
        boxtypeNode[nodeBoxTypeMapping[ip]] = {
          [ip]: {
            shards: parseInt(shards),
            size: getDataInBytes(diskIndices),
          },
        };
      }
    }
  }

  // Dropdown array to hold booleans indicating if respective table should be collapsed or not
  const nodeShardCountDropDown: Array<boolean> = [];
  // Iterating over the boxtypes and finding min-max shard count and size of nodes in respective boxtypes and checking if skews are present or not
  for (let boxType in boxtypeNode) {
    nodeShardCountDropDown.push(false);
    let minShards = Infinity;
    let maxShards = -Infinity;
    let minSize = Infinity;
    let maxSize = -Infinity;
    let nodeShardRows: Array<AnyObject> = [];
    let nodeStorageRows: Array<AnyObject> = [];
    let sizeArray = [];
    let shardArray = [];
    let totalNodes = 0;
    for (let node in boxtypeNode[boxType]) {
      totalNodes += 1;
      const nodeShardRow: AnyObject = {};
      nodeShardRow.nodeName = node;
      nodeShardRow.shardCount = boxtypeNode[boxType][node].shards;
      nodeShardRows.push(nodeShardRow);

      const nodeStorageRow: AnyObject = {};
      nodeStorageRow.nodeName = node;
      nodeStorageRow.size = boxtypeNode[boxType][node].size;
      nodeStorageRows.push(nodeStorageRow);

      sizeArray.push(boxtypeNode[boxType][node].size);
      shardArray.push(boxtypeNode[boxType][node].shards);

      if (boxtypeNode[boxType][node].shards < minShards) {
        minShards = boxtypeNode[boxType][node].shards;
      }
      if (boxtypeNode[boxType][node].shards > maxShards) {
        maxShards = boxtypeNode[boxType][node].shards;
      }
      if (boxtypeNode[boxType][node].size < minSize) {
        minSize = boxtypeNode[boxType][node].size;
      }
      if (boxtypeNode[boxType][node].size > maxSize) {
        maxSize = boxtypeNode[boxType][node].size;
      }
    }

    // Adding the skew information along with the message to the final skew arrays
    let nodeType = boxType === 'Not Present' ? '' : ' ' + boxType;
    // NodeShardCount
    const countRowObjNode: AnyObject = {};
    countRowObjNode.boxTypeName = boxType === 'Not Present' ? '' : boxType;
    if (maxShards - minShards > Object.keys(boxtypeNode[boxType]).length - 1) {
      countRowObjNode.skews = 1;
      countRowObjNode.desc = (
        <>
          Number of shards are unevenly distributed among{' '}
          {totalNodes.toString()}
          {nodeType}
          {totalNodes > 0 ? ' nodes ' : ' node '}
          (difference of more than {(totalNodes - 1).toString()}).
        </>
      );
    } else {
      countRowObjNode.skews = 0;
      countRowObjNode.desc = (
        <>
          <b>No issues. </b>
          {totalNodes.toString()}
          {nodeType}
          {totalNodes > 0 ? ' nodes have ' : ' node has '}
          been checked.
        </>
      );
    }

    countRowObjNode.minVal = minShards;
    countRowObjNode.maxVal = maxShards;
    countRowObjNode.diff = maxShards - minShards;

    const medianAndPercentCount = getMedianAndPercent(
      shardArray,
      maxShards - minShards
    );
    countRowObjNode.median = medianAndPercentCount.median;
    countRowObjNode.rangePercent = medianAndPercentCount.rangePercent;

    countRowObjNode.nodeValues = nodeShardRows;
    nodeShardCount.push(countRowObjNode);

    // NodeShardStorage
    const storageRowObjNode: AnyObject = {};
    storageRowObjNode.boxTypeName = boxType === 'Not Present' ? '' : boxType;
    if (maxSize - minSize > (minSize * nodeShardStorageThreshold) / 100) {
      storageRowObjNode.skews = 1;
      storageRowObjNode.desc = (
        <>
          Shard Storage unevenly distributed among {totalNodes.toString()}
          {nodeType}
          {totalNodes > 0 ? ' nodes ' : ' node '}
          (crossed the {nodeShardStorageThreshold.toString()}% threshold).
        </>
      );
    } else {
      storageRowObjNode.skews = 0;
      storageRowObjNode.desc = (
        <>
          <b>No issues. </b>
          {totalNodes.toString()}
          {nodeType}
          {totalNodes > 0 ? ' nodes have ' : ' node has '}
          been checked.
        </>
      );
    }
    storageRowObjNode.minVal = getHumanReadableSize(minSize);
    storageRowObjNode.maxVal = getHumanReadableSize(maxSize);
    storageRowObjNode.diff = getHumanReadableSize(maxSize - minSize);

    const medianAndPercentSize = getMedianAndPercent(
      sizeArray,
      maxSize - minSize
    );
    storageRowObjNode.median = getHumanReadableSize(
      Number(medianAndPercentSize.median)
    );
    storageRowObjNode.rangePercent = medianAndPercentSize.rangePercent;

    storageRowObjNode.nodeValues = nodeStorageRows;
    nodeShardStorage.push(storageRowObjNode);
  }
  dropdowns.push(nodeShardCountDropDown);
  // Using same for the shard storage dropdown
  let copyArr = [...nodeShardCountDropDown];
  dropdowns.push(copyArr);
};
