import { gql, useLazyQuery } from '@apollo/client';
export interface TmsIssuesVars {
  domainIdentifier: string;
  startTime: string;
  endTime: string;
  issuePrefix: string;
}

export interface TmsIssue {
  issueType: string;
  timestamp: Date;
  details: string;
  isResolved: string;
}

export interface TmsIssuesData {
  domain: {
    tmsIssuesArchive: TmsIssue[];
  };
}
const GQL_DOMAIN_TMS_ISSUES = gql`
  query getDomainTMSIssuesArchive(
    $domainIdentifier: String!
    $startTime: String!
    $endTime: String!
    $issuePrefix: String
  ) {
    domain(
      domainIdentifier: $domainIdentifier
      startTime: $startTime
      endTime: $endTime
      issuePrefix: $issuePrefix
    ) {
      tmsIssuesArchive
    }
  }
`;

export const useTmsQueryArchive = () => {
  return useLazyQuery<TmsIssuesData, TmsIssuesVars>(GQL_DOMAIN_TMS_ISSUES, {
    errorPolicy: 'all',
  });
};
