import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../models/types';
 
export interface StoreLogRedactionFeedbackVars {
    domainId: string;
    ticket: string;
    description: string;
    breakglassJustification: string;
}
 
const STORE_LOG_REDACTION_FEEDBACK = gql`
    query StoreLogRedactionFeedback(
      $domainId: String!,
      $ticket: String,
      $description: String,
      $breakglassJustification: String
    ) {
        storeLogRedactionFeedback(
          domainId: $domainId,
          ticket: $ticket,
          description: $description,
          breakglassJustification: $breakglassJustification
        ) {
            validation
            message
          }
    }
`;
 
export const useLogRedactionFeedbackQuery = () => {
    return useLazyQuery<AnyObject, StoreLogRedactionFeedbackVars>(STORE_LOG_REDACTION_FEEDBACK, {
        errorPolicy: 'all',
      });
};