import { ESEC2 } from '../models/types';
import { RegionInfo, regionInfo } from './regionInfo';

export const getDomainIdentifier = (clientId: string, domainName: string) =>
  `${clientId}:${domainName}`;

/**
 * Pick an ALB attached healthy instance. This function prefers instances in following order:
 * 1. Choose a healthy coordinating node if present.
 * 2. Choose a healthy data node if present.
 * 3. Choose a healthy cluster manager node if present.
 * 4. Choose a random node.
 */
export const getHealthyInstance = (ec2Instance: ESEC2[]) => {

  const shuffledEc2InstancesList = [...ec2Instance];

  let currentIndex = shuffledEc2InstancesList.length;

  while (currentIndex !== 0) {
    const randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [shuffledEc2InstancesList[currentIndex], shuffledEc2InstancesList[randomIndex]] = [shuffledEc2InstancesList[randomIndex], shuffledEc2InstancesList[currentIndex]];
  }

  let healthyInstance = shuffledEc2InstancesList.find(
    (instance: ESEC2) =>
      instance.state === 'running' &&
      instance.nodeType === 'coordinating' &&
      instance.elbHealth === 'healthy'
  );
  if (!healthyInstance) {
    healthyInstance = shuffledEc2InstancesList.find(
      (instance: ESEC2) =>
        instance.state === 'running' &&
        instance.nodeType === 'data' &&
        instance.elbHealth === 'healthy'
    );
  }
  if (!healthyInstance) {
    healthyInstance = shuffledEc2InstancesList.find(
      (instance: ESEC2) =>
        instance.state === 'running' &&
        instance.nodeType === 'master' &&
        instance.elbHealth === 'healthy'
    );
  }
  if (!healthyInstance) {
    healthyInstance = shuffledEc2InstancesList[0];
  }
  return healthyInstance;
};

export const getRegion = (airPortCode: string): RegionInfo => {
  const region = regionInfo.find(
    (currentRegion: any) =>
      currentRegion.airportCode.toLowerCase() === airPortCode.toLowerCase()
  );
  if (region) {
    return region;
  }
  // TODO:: Log warning
  // Default to 'us-east-1';
  const iadRegion = regionInfo.find(
    (currentRegion: any) => currentRegion.airportCode.toLowerCase() === 'iad'
  );
  return {
    regionName: iadRegion ? iadRegion.regionName : '',
    publicRegion: iadRegion ? iadRegion.publicRegion : 'us-east-1',
    airportCode: iadRegion ? iadRegion.airportCode : 'IAD',
  };
};

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts: string[] = value.split(`; ${name}=`);
  //@ts-ignore
  if (parts.length === 2) return parts.pop().split(';').shift();
};
