// @ts-ignore
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Box,
  Button,
  Header,
  Pagination,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react';
import { get } from 'lodash';
import { useState, useEffect } from 'react';
import { useToastNotifications } from '../../hooks/useToastList';
import { CommonDomainProps } from '../../models/types';
import { useUpgradeHistoryQuery } from './hooks/useUpgradeHistoryQuery';
import { columns, DEFAULT_PREFERENCES, Preferences } from './utils';
import { isError } from "../../ErrorHandler/apiErrorHandler";
import { ErrorMessageComponent } from "../../ErrorHandler/errorMessageComponent";
import { ApiList }from "../../ErrorHandler/utils";

const UpgradeDetails = (props: CommonDomainProps) => {
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const { loading, data, error } = useUpgradeHistoryQuery({
    domainIdentifier: props.domainIdentifier,
  });

  const [errorMessage, setErrorMessage] = useState(null)
  const isErrorPresent = isError(error)
  useEffect(() => {
    if (isErrorPresent) {
      setErrorMessage("Unable to get Upgrade details. Failed with " + error.message);
    }
  }, [isErrorPresent, error]);
  function EmptyState({ title, subtitle, action }) {
    return (
      <Box textAlign="center" color="inherit">
        <Box variant="strong" textAlign="center" color="inherit">
          {title}
        </Box>
        <Box variant="p" padding={{ bottom: 's' }} color="inherit">
          {subtitle}
        </Box>
        {action}
      </Box>
    );
  }
  const { items, actions, collectionProps, filterProps, paginationProps } =
    useCollection(data ? [] : get(data, 'domain.upgradeHistory', []), {
      filtering: {
        empty: (
          <Box variant="strong" textAlign="center" color="inherit">
            There are no Upgrades.
          </Box>
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={
              <Button onClick={() => actions.setFiltering('')}>
                Clear filter
              </Button>
            }
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
    });
  return (
    <div>
    {errorMessage ? (
        <ErrorMessageComponent errorMessage={errorMessage} apiName={ApiList.UPGRADE_DETAILS}/>
      ) : (
      <Table
        {...collectionProps}
        header={<Header counter={`(${items.length})`}>Upgrade Details</Header>}
        columnDefinitions={columns}
        stickyHeader={true}
        resizableColumns={true}
        wrapLines={preferences.wraplines}
        loading={loading}
        loadingText={
          loading ? 'Loading upgrade history...' : 'There were no upgrades.'
        }
        visibleColumns={preferences.visibleContent}
        items={items}
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
          />
        }
        filter={
          <TextFilter {...filterProps} filteringAriaLabel="Filter instances" />
        }
        preferences={
          <Preferences
            preferences={preferences}
            setPreferences={setPreferences}
            disabled={false}
          />
        }
      />
     )}
    </div>
  );
};

export { UpgradeDetails };
