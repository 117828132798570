import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Box,
  Button,
  Container,
  DateRangePicker,
  DateRangePickerProps,
  Header,
  Pagination,
  PropertyFilter,
  Select,
  SpaceBetween,
  Table,
  TextContent,
} from '@amzn/awsui-components-react';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CommonDomainProps } from '../../models/types';
import { EmptyState, getFilterCounterText } from '../../utils/tablePreferences';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from './../../utils/constants';
import { keyEventEndPoints } from './constants';
import { useKeyEventsQuery } from './keyEventsAllQuery';
import {
  columns,
  DEFAULT_PREFERENCES,
  FILTERING_PROPERTIES,
  i18nStrings,
  PAGE_SIZE_OPTIONS,
  Preferences,
  relativeOptions,
  timezoneOptions,
  VISIBLE_CONTENT_OPTIONS,
} from './utils';
import { isError } from "../../ErrorHandler/apiErrorHandler";
import { ErrorMessageComponent } from "../../ErrorHandler/errorMessageComponent";
import { ApiList } from "../../ErrorHandler/utils";

const KeyEvents = (props: CommonDomainProps) => {
  const host = window.location.host;
  const currentRegion = host.split('.')[0];
  const currentStage = host.split('.')[1];

  var validRegionStage = false;
  if (currentStage in keyEventEndPoints) {
    if (keyEventEndPoints[currentStage].includes(currentRegion)) {
      validRegionStage = true;
    }
  }

  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const [currentDate, setCurrentDate] = React.useState<any>({
    type: 'relative',
    amount: 1,
    unit: 'week',
  });

  const [selectedTimeZoneOption, setSelectedTimeZoneOption] = React.useState({
    inputDisplay: 'UTC',
    label: 'UTC',
    value: 'UTC',
    code: 'UTC',
  });

  const domainId = props.domainIdentifier;

  var [getKeyevents, { loading, data: keyData, error: error }] = useKeyEventsQuery();

  const [errorMessage, setErrorMessage] = useState(null)
  const isErrorPresent = isError(error)
  useEffect(() => {
    if (isErrorPresent) {
      setErrorMessage("Unable to get Keys Events. Failed with " + error.message);
    }
  }, [isErrorPresent, error]);

  useEffect(() => {
    getKeyevents({
      variables: {
        domainId: domainId,
        startTime: '7 days ago',
        endTime: 'now',
      },
    });
  }, [domainId, getKeyevents]);

  const convertTZ = (date, tzOption) => {
    const tz = tzOption.value;
    if (tz === 'Browser Timezone') {
      return (typeof date === 'string' ? new Date(date) : date).toLocaleString(
        'en-US',
        { hour12: false, timeZoneName: 'short' }
      );
    }
    return (
      (typeof date === 'string' ? new Date(date) : date).toLocaleString(
        'en-US',
        { timeZone: tz, hour12: false }
      ) +
      ' ' +
      tzOption.code
    );
  };

  const handleTimezoneChange = (selectedOption) => {
    setSelectedTimeZoneOption(selectedOption);
  };

  const handleDateChange = ({ detail }) => {
    var startMoment, endMoment;

    if ('absolute' === detail.value.type) {
      startMoment = moment(detail.value.startDate);
      endMoment = moment(detail.value.endDate);
    } else if ('relative' === detail.value.type) {
      startMoment = moment().subtract(detail.value.amount, detail.value.unit);
      endMoment = moment();
    }

    getKeyevents({
      variables: {
        domainId: domainId,
        startTime: startMoment.toISOString(),
        endTime: endMoment.toISOString(),
      },
    });

    setCurrentDate(detail.value);
  };

  const isValidRange: DateRangePickerProps.ValidationFunction = (e) => {
    const timeKeyEvents = (start, end) => {
      const a = moment(end).isBefore(moment(start));
      return a;
    };
    if ('absolute' === e.type) {
      const [a] = e.startDate.split('T'),
        [n] = e.endDate.split('T');
      if (!a || !n)
        return {
          valid: !1,
          errorMessage:
            'The selected date range is incomplete. Select a start and end date for the date range.',
        };
      if (
        moment(e.startDate).isAfter(moment()) ||
        moment(e.endDate).isAfter(moment())
      )
        return {
          valid: !1,
          errorMessage:
            'Either of start date or end date cannot be future date.',
        };
      if (timeKeyEvents(e.startDate, e.endDate))
        return {
          valid: !1,
          errorMessage: 'The end date must be greater than the start date.',
        };
    } else if ('relative' === e.type) {
      if (isNaN(e.amount))
        return {
          valid: !1,
          errorMessage:
            'The selected date range is incomplete. Specify a duration for the date range.',
        };
    }
    return { valid: !0 };
  };

  const events = get(keyData, 'domainKeyEvents.keyEvents', []);

  const limit = 8000;

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(
      loading ? [] : (events && events.length > limit ? events.slice(0, limit) : events),
    {
      propertyFiltering: {
        filteringProperties: FILTERING_PROPERTIES,
        empty: (
          <EmptyState
            title="No Key Events"
            subtitle="No Key Events to display."
            action={<span></span>}
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={
              <Button onClick={() => actions.setFiltering('')}>
                Clear filter
              </Button>
            }
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
    }
  );

  return (
    <>
      {errorMessage ? (
        <ErrorMessageComponent errorMessage={errorMessage} apiName={ApiList.KEY_EVENTS}/>
      ) : !validRegionStage ? (
        <Box variant="h3" margin="m" color="text-status-info">
          Currently the Key Events are not supported in this stage and/or
          region!
        </Box>
      ) : (
        <div>
          <Container>
            Sometimes it is needed to figure out why domain is in a particular
            state, and what trail of actions were applied on domain. There are
            various actors in AES ecosystem which can mutate a domain. E.g.
            Config Service via customer driven changes, Self-Healing, Software
            Upgrade (S2D2), etc. To know more about it, please visit{' '}
            <a
              href="https://w.amazon.com/bin/view/Search/A9/CloudSearch/Development/DesignReview/AuditSystem"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            . Below is the table of all such Key events.
          </Container>
          <div>
            <Table
              {...collectionProps}
              header={
                <div>
                  <Header
                    counter={`(${items.length})`}
                    actions={
                      <SpaceBetween direction="horizontal" size="s">
                        <DateRangePicker
                          onChange={handleDateChange}
                          value={currentDate}
                          relativeOptions={relativeOptions}
                          isValidRange={isValidRange}
                          i18nStrings={i18nStrings}
                          placeholder="Filter by a date and time range"
                        />
                        <TextContent>
                          <strong>Timezone :</strong>
                        </TextContent>
                        <Select
                          selectedOption={selectedTimeZoneOption}
                          onChange={({ detail }) =>
                            handleTimezoneChange(detail.selectedOption)
                          }
                          options={timezoneOptions}
                          selectedAriaLabel="Selected"
                        />
                      </SpaceBetween>
                    }
                  >
                    Key Events
                  </Header>
                  {events.length > limit && (
                    <p>
                      There are more than {limit} Key events and we are
                      displaying the latest {limit} key events
                    </p>
                  )}
                </div>
              }
              columnDefinitions={[
                {
                  id: 'event_time',
                  header: 'Event Time',
                  sortingField: 'event_time',
                  cell: (e) => {
                    var newTime = e.event_time;
                    if (e.event_time.slice(-1) !== 'Z') {
                      newTime = e.event_time + 'Z';
                    }
                    return convertTZ(newTime, selectedTimeZoneOption);
                  },
                },
                ...columns,
              ]}
              stickyHeader={true}
              resizableColumns={false}
              wrapLines={preferences.wraplines}
              loading={loading}
              loadingText={
                loading ? 'Loading key events...' : 'There were no key events.'
              }
              visibleColumns={preferences.visibleContent}
              items={items}
              pagination={
                <Pagination
                  {...paginationProps}
                  ariaLabels={{
                    nextPageLabel: 'Next page',
                    previousPageLabel: 'Previous page',
                    pageLabel: (pageNumber) =>
                      `Page ${pageNumber} of all pages`,
                  }}
                />
              }
              filter={
                <div className="input-container">
                  <PropertyFilter
                    className="input-filter"
                    i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                    {...propertyFilterProps}
                    countText={getFilterCounterText(filteredItemsCount)}
                  />
                </div>
              }
              preferences={
                <Preferences
                  preferences={preferences}
                  setPreferences={setPreferences}
                  disabled={false}
                  pageSizeOptions={PAGE_SIZE_OPTIONS}
                  visibleContentOptions={VISIBLE_CONTENT_OPTIONS}
                />
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export { KeyEvents };
