import React, { useState } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  EmptyState,
  getFilterCounterText,
  Preferences,
} from './../../../utils/tablePreferences';
import {
  Button,
  Header,
  SpaceBetween,
  Pagination,
  PropertyFilter,
  Table,
} from '@amzn/awsui-components-react/polaris';
import { ExportCSV } from '../../../utils/exportCSV';
import {
  IMPACTED_DOMAINS_COLUMN_DEFINITIONS,
  VISIBLE_CONTENT_OPTIONS,
  DEFAULT_PREFERENCES,
  PAGE_SIZE_OPTIONS,
  FILTERING_PROPERTIES,
} from './constants';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from './../../../utils/constants';

const ImpactedDomain = (props) => {
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(props.isLoading ? [] : props.impactedDomains, {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES,
      empty: (
        <EmptyState
          title="No Impacted Domains"
          subtitle="No impacted domains to display."
          action={<span></span>}
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can’t find a match."
          action={
            <Button onClick={() => actions.setFiltering('')}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  return (
    <Table
      header={
        <Header
          actions={
            <SpaceBetween direction="horizontal" size="s">
              <ExportCSV
                data={props.impactedDomains}
                filename={'impacted_domains.csv'}
                loading={props.isLoading}
              />
            </SpaceBetween>
          }
        >
          Impacted Domains
        </Header>
      }
      {...collectionProps}
      columnDefinitions={IMPACTED_DOMAINS_COLUMN_DEFINITIONS}
      stickyHeader={true}
      wrapLines={preferences.wraplines}
      loading={props.isLoading}
      loadingText={
        props.isLoading
          ? 'Loading impacted domains list'
          : 'No impacted domain found'
      }
      visibleColumns={preferences.visibleContent}
      items={items}
      pagination={
        <Pagination
          {...paginationProps}
          ariaLabels={{
            nextPageLabel: 'Next page',
            previousPageLabel: 'Previous page',
            pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
          }}
        />
      }
      filter={
        <div className="input-container">
          <PropertyFilter
            className="input-filter"
            i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
            {...propertyFilterProps}
            countText={getFilterCounterText(filteredItemsCount)}
          />
        </div>
      }
      preferences={
        <Preferences
          preferences={preferences}
          setPreferences={setPreferences}
          disabled={false}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          visibleContentOptions={VISIBLE_CONTENT_OPTIONS}
        />
      }
    />
  );
};
export { ImpactedDomain };
