import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks/dist/cjs/interfaces';
import {
  CollectionPreferences,
  DateRangePickerProps,
} from '@amzn/awsui-components-react';

export const columns = [
  {
    id: 'source',
    field: 'source',
    name: 'Source',
    header: 'Source',
    sortingField: 'source',
    cell: (e) => e.source,
  },
  {
    id: 'action',
    field: 'action',
    name: 'Action',
    header: 'Action',
    sortingField: 'action',
    cell: (e) => e.action,
  },
  {
    id: 'action_state',
    field: 'action_state',
    name: 'Action State',
    header: 'Action State',
    sortingField: 'action_state',
    cell: (e) => e.action_state,
  },
  {
    id: 'host',
    field: 'host',
    name: 'Host',
    header: 'Host',
    sortingField: 'host',
    cell: (e) => e.host,
  },
  {
    id: 'domain_ident',
    field: 'domain_ident',
    name: 'Domain Ident',
    header: 'Domain Ident',
    sortingField: 'domain_indent',
    cell: (e) => e.domain_ident,
  },
  {
    id: 'id',
    field: 'id',
    name: 'Id',
    header: 'Id',
    sortingField: 'id',
    cell: (e) => e.id,
  },
  {
    id: 'tagging',
    field: 'tagging',
    name: 'Tagging',
    header: 'Tagging',
    sortingField: 'tagging',
    cell: (e) => e.tagging,
  },
  {
    id: 'version',
    field: 'version',
    name: 'Version',
    header: 'Version',
    sortingField: 'version',
    cell: (e) => e.version,
  },
  {
    id: 'dynamicFields.context',
    field: 'dynamicFields.context',
    name: 'dynamicFields.context',
    header: 'dynamicFields.context',
    sortingField: 'dynamicFields.context',
    cell: (e) => (e.dynamicFields ? e.dynamicFields.context : null),
  },
  {
    id: 'dynamicFields.failure_reason',
    field: 'dynamicFields.failure_reason',
    name: 'dynamicFields.failure_reason',
    header: 'dynamicFields.failure_reason',
    sortingField: 'dynamicFields.failure_reason',
    cell: (e) => (e.dynamicFields ? e.dynamicFields.failure_reason : null),
  },
  {
    id: 'dynamicFields.input',
    field: 'dynamicFields.input',
    name: 'dynamicFields.input',
    header: 'dynamicFields.input',
    sortingField: 'dynamicFields.input',
    cell: (e) => (e.dynamicFields ? e.dynamicFields.input : null),
  },
  {
    id: 'dynamicFields.requestId',
    field: 'dynamicFields.requestId',
    name: 'dynamicFields.requestId',
    header: 'dynamicFields.requestId',
    sortingField: 'dynamicFields.requestId',
    cell: (e) => (e.dynamicFields ? e.dynamicFields.requestId : null),
  },
  {
    id: 'dynamicFields.status',
    field: 'dynamicFields.status',
    name: 'dynamicFields.status',
    header: 'dynamicFields.status',
    sortingField: 'dynamicFields.status',
    cell: (e) => (e.dynamicFields ? e.dynamicFields.status : null),
  },
  {
    id: 'dynamicFields.time_taken',
    field: 'dynamicFields.time_taken',
    name: 'dynamicFields.time_taken',
    header: 'dynamicFields.time_taken',
    sortingField: 'dynamicFields.time_taken',
    cell: (e) => (e.dynamicFields ? e.dynamicFields.time_taken : null),
  },
];

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Main distribution properties',
    options: [
      { id: 'event_time', label: 'event_time' },
      { id: 'source', label: 'source' },
      { id: 'action', label: 'action' },
      { id: 'action_state', label: 'action_state' },
      { id: 'host', label: 'host' },
      { id: 'domain_ident', label: 'domain_ident' },
      { id: 'id', label: 'id' },
      { id: 'tagging', label: 'tagging' },
      { id: 'version', label: 'version' },
      { id: 'dynamicFields.context', label: 'dynamicFields.context' },
      {
        id: 'dynamicFields.failure_reason',
        label: 'dynamicFields.failure_reason',
      },
      { id: 'dynamicFields.input', label: 'dynamicFields.input' },
      { id: 'dynamicFields.requestId', label: 'dynamicFields.requestId' },
      { id: 'dynamicFields.status', label: 'dynamicFields.status' },
      { id: 'dynamicFields.time_taken', label: 'dynamicFields.time_taken' },
    ],
  },
];

export const FILTERING_PROPERTIES: readonly PropertyFilterProperty[] = [
  {
    propertyLabel: 'Event Time',
    key: 'event_time',
    groupValuesLabel: 'Event Time values',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'Source',
    key: 'source',
    groupValuesLabel: 'Source Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Action',
    key: 'action',
    groupValuesLabel: 'Action values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Action State',
    key: 'action_state',
    groupValuesLabel: 'Action State values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Host',
    key: 'host',
    groupValuesLabel: 'Host Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Domain Ident',
    key: 'domain_ident',
    groupValuesLabel: 'Domain Ident Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'ID',
    key: 'id',
    groupValuesLabel: 'ID Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Tagging',
    key: 'tagging',
    groupValuesLabel: 'Tagging Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Version',
    key: 'version',
    groupValuesLabel: 'Version Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'DynamicFields context',
    key: 'dynamicFields.context',
    groupValuesLabel: 'DynamicFields context Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'DynamicFields failure reason',
    key: 'dynamicFields.failure_reason',
    groupValuesLabel: 'DynamicFields failure reason Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'DynamicFields input',
    key: 'dynamicFields.input',
    groupValuesLabel: 'DynamicFields input Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'DynamicFields requestId',
    key: 'dynamicFields.requestId',
    groupValuesLabel: 'DynamicFields requestId Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'DynamicFields status',
    key: 'dynamicFields.status',
    groupValuesLabel: 'DynamicFields status Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'DynamicFields time_taken',
    key: 'dynamicFields.time_taken',
    groupValuesLabel: 'DynamicFields time_taken Values',
    operators: [':', '!:', '=', '!='],
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 Key Events' },
  { value: 30, label: '30 Key Events' },
  { value: 50, label: '50 Key Events' },
  { value: 100, label: '100 Key Events' },
  { value: 500, label: '500 Key Events' },
  { value: 1000, label: '1000 Key Events' },
  { value: 10000, label: '10000 Key Events' },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 10000,
  visibleContent: ['event_time', 'source', 'action', 'action_state', 'host'],
  wraplines: true,
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: 'Page size',
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: 'Wrap lines',
      description: 'Check to see all the text and wrap the lines',
    }}
    visibleContentPreference={{
      title: 'Select visible columns',
      options: visibleContentOptions,
    }}
  />
);

export const getFilterCounterText = (count) =>
  `${count} ${count === 1 ? 'match' : 'matches'}`;

export const getTimzoneOffset = (inputTimezone: string) => {
  let localCurrentDate: any = new Date();
  let inputzoneCurrentDate: any = new Date(
    localCurrentDate.toLocaleString('en-US', { timeZone: inputTimezone })
  );
  console.log(inputzoneCurrentDate);
  let localInputzoneOffsetInMinutes = Math.round(
    (inputzoneCurrentDate - localCurrentDate) / (1000 * 60)
  );
  return Math.round(localInputzoneOffsetInMinutes * 60);
};

export const timezoneOptions = [
  {
    inputDisplay: 'Browser Timezone',
    label: 'Browser Timezone',
    value: 'Browser Timezone',
    code: '',
  },
  {
    inputDisplay: 'UTC',
    label: 'UTC',
    value: 'UTC',
    code: 'UTC',
  },
  {
    inputDisplay: 'US/Pacific (PDT)',
    label: 'US/Pacific (PDT)',
    value: 'US/Pacific',
    code: 'PDT',
  },
  {
    inputDisplay: 'America/Phoenix (MDT)',
    label: 'America/Phoenix (MDT)',
    value: 'America/Phoenix',
    code: 'MDT',
  },
  {
    inputDisplay: 'America/NewYork (EDT)',
    label: 'America/NewYork (EDT)',
    value: 'America/New_York',
    code: 'EDT',
  },
  {
    inputDisplay: 'America/Chicago (CDT)',
    label: 'America/Chicago (CDT)',
    value: 'America/Chicago',
    code: 'CDT',
  },
  {
    inputDisplay: 'Asia/Kolkata (IST)',
    label: 'Asia/Kolkata (IST)',
    value: 'Asia/Kolkata',
    code: 'IST',
  },
  {
    inputDisplay: 'Asia/Shanghai (CST)',
    label: 'Asia/Shanghai (CST)',
    value: 'Asia/Shanghai',
    code: 'CST',
  },
];

export const i18nStrings = {
  todayAriaLabel: 'Today',
  nextMonthAriaLabel: 'Next month',
  previousMonthAriaLabel: 'Previous month',
  customRelativeRangeDurationLabel: 'Duration',
  customRelativeRangeDurationPlaceholder: 'Enter duration',
  customRelativeRangeOptionLabel: 'Custom range',
  customRelativeRangeOptionDescription: 'Set a custom range in the past',
  customRelativeRangeUnitLabel: 'Unit of time',
  formatRelativeRange: (e) => {
    const timeKeyEvents = 1 === e.amount ? e.unit : e.unit + 's';
    return `Last ${e.amount} ${timeKeyEvents}`;
  },
  formatUnit: (e, timeKeyEvents) => (1 === timeKeyEvents ? e : e + 's'),
  dateTimeConstraintText:
    'Range must be between 6 - 30 days. Use 24 hour format.',
  relativeModeTitle: 'Relative range',
  absoluteModeTitle: 'Absolute range',
  relativeRangeSelectionHeading: 'Choose a range',
  startDateLabel: 'Start date',
  endDateLabel: 'End date',
  startTimeLabel: 'Start time',
  endTimeLabel: 'End time',
  clearButtonLabel: 'Clear',
  cancelButtonLabel: 'Cancel',
  applyButtonLabel: 'Apply',
};

export const relativeOptions: ReadonlyArray<DateRangePickerProps.RelativeOption> =
  [
    {
      key: 'previous-5-minutes',
      amount: 5,
      unit: 'minute',
      type: 'relative',
    },
    {
      key: 'previous-30-minutes',
      amount: 30,
      unit: 'minute',
      type: 'relative',
    },
    {
      key: 'previous-1-hour',
      amount: 1,
      unit: 'hour',
      type: 'relative',
    },
    {
      key: 'previous-6-hours',
      amount: 6,
      unit: 'hour',
      type: 'relative',
    },
  ];
