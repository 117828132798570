import {
  Box,
  Select,
  SpaceBetween,
  Toggle,
} from '@amzn/awsui-components-react';
import * as awsui from '@amzn/awsui-design-tokens';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { RegionSwitcher } from './../pages/Home/Navbar/RegionSwitch';
import { TumblerAvatar } from './../pages/Home/Navbar/TumblerAvatar';
import './../Styles/navbar.css';
import { AppContext } from './appContext';

interface NavbarProps {
  title: string;
  homeUrl: string;
  regionSwitcherActive: boolean;
  dropdownOptions: { label: string; value: string }[];
}

const StyledHeader = styled.header`
  background-color: ${awsui.colorBackgroundHomeHeader};
`;

export const Navbar = (props: NavbarProps) => {
  const appContext = useContext(AppContext);
  const [selectedOption, setSelectedOption] = React.useState(null);
  // This logic is appends stack name along Tumbler title and doesn't add anthing for OTS dashboard title.
  let title = props.title;
  if (props.title.toLocaleLowerCase().includes('tumbler')) {
    title = `${props.title}${
      appContext.stage === 'beta'
        ? ' (Staging)'
        : appContext.stage === 'gamma'
        ? ' (Integ)'
        : ''
    }`;
  }
  const handleChange = (event) => {
    setSelectedOption(null);
    window.open(event.detail.selectedOption.value, '_blank');
  };

  var defaultDarkMode;
  if (localStorage.getItem('darkMode')) {
    defaultDarkMode = JSON.parse(localStorage.getItem('darkMode'));
  } else {
    defaultDarkMode = false;
  }

  const [checked, setChecked] = React.useState(defaultDarkMode);

  const handleModeChange = (value) => {
    setChecked(value);
    localStorage.setItem('darkMode', JSON.stringify(value));
  };

  useEffect(() => {
    document.body.classList.add('awsui-visual-refresh');
    if (checked) {
      document.body.classList.add('awsui-polaris-dark-mode');
    } else {
      document.body.classList.remove('awsui-polaris-dark-mode');
    }
  }, [checked]);

  return (
    <StyledHeader id="navbar" className="topNavbar">
      <div
        style={{
          padding: '0 30px 0 30px',
        }}
      >
        <Box float="left">
          <div className="headerDiv">
            <a href={props.homeUrl} className="headerLink">
              <strong>{title}</strong>
            </a>
          </div>
        </Box>
        <Box float="right">
          <SpaceBetween direction="horizontal" size="s">
            <Toggle
              onChange={({ detail }) => handleModeChange(detail.checked)}
              checked={checked}
            >
              <span className="toggleText">Mode</span>
            </Toggle>
            {props.regionSwitcherActive && <RegionSwitcher />}
            <Select
              className="dropdown-nav"
              selectedOption={selectedOption}
              onChange={handleChange}
              options={props.dropdownOptions}
              ariaRequired
              selectedAriaLabel="Selected"
              placeholder="Feature/Bug/Guides"
            />
            <TumblerAvatar />
          </SpaceBetween>
        </Box>
      </div>
    </StyledHeader>
  );
};
