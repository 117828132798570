import { INDEXING_METRICS_DIMENSIONS } from "../../Collection/Dashboards/constants";

export interface GraphDefinition {
    title: string;
    paramSets: Array<{
        stats: string[];
        metricNames: string[];
        unit?: string;
        isRootAccount?: boolean;
    }>;
    mathFunctionParams?: Array<{
        id?: string;
        expression: string;
        label: string;
    }>;
}

export interface MetricDefinitions {
    namespace: string;
    dimensionNames: any;
    graphs: GraphDefinition[];
}

export enum OCU_METRICS_DIMENSIONS {
    AWS_ACCOUNT_ID = 'AwsAccountId',
}

export const OCU_METRICS: MetricDefinitions = {
    namespace: 'AOSSBeholder/KAM/CxMetrics',
    dimensionNames: OCU_METRICS_DIMENSIONS,
    graphs: [
        {
            title: 'Search OCU History',
            paramSets: [
                {
                    stats: ['Maximum'],
                    metricNames: ['SearchOCU'],
                    unit: 'Count',
                },
            ],
        },
        {
            title: 'Indexing OCU History',
            paramSets: [
                {
                    stats: ['Maximum'],
                    metricNames: ['IndexingOCU'],
                    unit: 'Count',
                },
            ],
        },
    ],
};

export enum SEARCH_NODESTATS_DIMENSIONS {
    AWS_ACCOUNT_ID = "aws_account_id",
    METRIC_TYPE = "metric_type",
};

export const SEARCH_WORKER_METRICS: MetricDefinitions = {
    namespace: 'JunoSearch/Worker',
    dimensionNames: SEARCH_NODESTATS_DIMENSIONS,
    graphs: [
        {
            title: 'Shard State',
            paramSets: [
                {
                    stats: ['Maximum'],
                    metricNames: ['SyncingShardCount', 'SyncedShardCount', 'UnassignedShardCount'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Disk Space',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['DiskTotalSpace', 'DiskAvailableSpace',
                        'DiskAssignmentLimitGb', 'DiskAssignmentAvailableGb',
                        'DiskOldSegmentDataGb', 'DiskFreeSpace'],
                    unit: 'Gigabytes',
                }
            ],
        },
        {
            title: 'Disk Utilization',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['DiskUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Average'],
                    metricNames: ['DiskUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['DiskUtilization'],
                    unit: 'Percent',
                }
            ]
        },
        {
            title: 'Memory Utilization',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['MemoryUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Average'],
                    metricNames: ['MemoryUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['MemoryUtilization'],
                    unit: 'Percent',
                }
            ]
        },
        {
            title: 'JVM Heap Utilization',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['JVMHeapUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Average'],
                    metricNames: ['JVMHeapUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['JVMHeapUtilization'],
                    unit: 'Percent',
                }
            ]
        },
        {
            title: 'Container CPU Utilization',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['ContainerCPUUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Average'],
                    metricNames: ['ContainerCPUUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['ContainerCPUUtilization'],
                    unit: 'Percent',
                }
            ]
        },
        {
            title: 'Task CPU Utilization',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['TaskCPUUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Average'],
                    metricNames: ['TaskCPUUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['TaskCPUUtilization'],
                    unit: 'Percent',
                }
            ]
        },
        {
             title: 'Search Thread Pool Queue',
             paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['ThreadPool.search.Queue'],
                    unit: 'Count',
                },
                {
                    stats: ['Average'],
                    metricNames: ['ThreadPool.search.Queue'],
                    unit: 'Count',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['ThreadPool.search.Queue'],
                    unit: 'Count',
                }                 
             ]
        },
        {
            title: 'JVM Threads',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['JVMThreadsCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Average'],
                    metricNames: ['JVMThreadsCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['JVMThreadsCount'],
                    unit: 'Count',
                }
            ]
        },
        {
            title: 'JVM GC Collection Counts',
            paramSets: [
                {
                    stats: ['Maximum'],
                    metricNames: ['JVMGCYoungCollectionCount', 'JVMGCOldCollectionCount'],
                    unit: 'Count',
                }
            ]
        },
        {
            title: 'JVM GC Collection Times',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['JVMGCYoungCollectionTime', 'JVMGCOldCollectionTime'],
                    unit: 'Milliseconds',
                }
            ]
        },
        {
            title: 'JVM Class Counts',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['JVMClassesCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Average'],
                    metricNames: ['JVMClassesCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['JVMClassesCount'],
                    unit: 'Count',
                }
            ]
        },
        {
            title: 'Open File Descriptors',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['OpenFileDescriptors'],
                    unit: 'Count',
                },
                {
                    stats: ['Average'],
                    metricNames: ['OpenFileDescriptors'],
                    unit: 'Count',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['OpenFileDescriptors'],
                    unit: 'Count',
                }
            ]
        },
        {
            title: 'HTTP Connections',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['HTTPConnectionsCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Average'],
                    metricNames: ['HTTPConnectionsCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['HTTPConnectionsCount'],
                    unit: 'Count',
                }
            ]
        },
        {
            title: 'Transport RX Count',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['TransportRXCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Average'],
                    metricNames: ['TransportRXCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['TransportRXCount'],
                    unit: 'Count',
                }
            ]
        },
        {
            title: 'Transport TX Size',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['TransportTXSize'],
                    unit: 'GB',
                },
                {
                    stats: ['Average'],
                    metricNames: ['TransportTXSize'],
                    unit: 'GB',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['TransportTXSize'],
                    unit: 'GB',
                }
            ]
        }
    ],
};

export const SEARCH_COORDINATOR_METRICS: MetricDefinitions = {
    namespace: 'JunoSearch/Coordinator',
    dimensionNames: SEARCH_NODESTATS_DIMENSIONS,
    graphs: [
        {
            title: 'Disk Space',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['DiskTotalSpace', 'DiskAvailableSpace'],
                    unit: 'Gigabytes',
                }
            ],
        },
        {
            title: 'Memory Utilization',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['MemoryUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Average'],
                    metricNames: ['MemoryUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['MemoryUtilization'],
                    unit: 'Percent',
                }
            ]
        },
        {
            title: 'JVM Heap Utilization',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['JVMHeapUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Average'],
                    metricNames: ['JVMHeapUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['JVMHeapUtilization'],
                    unit: 'Percent',
                }
            ]
        },
        {
            title: 'CPU Utilization',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['CPUUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Average'],
                    metricNames: ['CPUUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['CPUUtilization'],
                    unit: 'Percent',
                }
            ]
        },
        {
            title: 'JVM Threads',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['JVMThreadsCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Average'],
                    metricNames: ['JVMThreadsCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['JVMThreadsCount'],
                    unit: 'Count',
                }
            ]
        },
        {
            title: 'JVM GC Collection Counts',
            paramSets: [
                {
                    stats: ['Maximum'],
                    metricNames: ['JVMGCYoungCollectionCount', 'JVMGCOldCollectionCount'],
                    unit: 'Count',
                }
            ]
        },
        {
            title: 'JVM GC Collection Times',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['JVMGCYoungCollectionTime', 'JVMGCOldCollectionTime'],
                    unit: 'Milliseconds',
                }
            ]
        },
        {
            title: 'JVM Class Counts',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['JVMClassesCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Average'],
                    metricNames: ['JVMClassesCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['JVMClassesCount'],
                    unit: 'Count',
                }
            ]
        },
        {
            title: 'Open File Descriptors',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['OpenFileDescriptors'],
                    unit: 'Count',
                },
                {
                    stats: ['Average'],
                    metricNames: ['OpenFileDescriptors'],
                    unit: 'Count',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['OpenFileDescriptors'],
                    unit: 'Count',
                }
            ]
        },
        {
            title: 'HTTP Connections',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['HTTPConnectionsCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Average'],
                    metricNames: ['HTTPConnectionsCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['HTTPConnectionsCount'],
                    unit: 'Count',
                }
            ]
        },
        {
            title: 'Transport RX Count',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['TransportRXCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Average'],
                    metricNames: ['TransportRXCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['TransportRXCount'],
                    unit: 'Count',
                }
            ]
        },
        {
            title: 'Transport TX Size',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['TransportTXSize'],
                    unit: 'GB',
                },
                {
                    stats: ['Average'],
                    metricNames: ['TransportTXSize'],
                    unit: 'GB',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['TransportTXSize'],
                    unit: 'GB',
                }
            ]
        }
    ],
};


export enum INDEXING_DIMENSIONS {
    AWS_ACCOUNT_ID = "AwsAccountId",
    METHOD_NAME = "MethodName",
    SERVICE_NAME = "ServiceName"
}

export const BULK_INDEXING_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingCoordinator',
    dimensionNames: INDEXING_DIMENSIONS,
    graphs: [
        {
            title: 'Bulk Metrics',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['Success','Fault','Failure'],
                    unit: 'Count',
                },
            ],
        }
    ],
};

export const BULK_ITEM_INDEXING_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingCoordinator',
    dimensionNames: INDEXING_DIMENSIONS,
    graphs: [
        {
            title: 'BulkItem Metrics',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['BulkItemSuccess','BulkItemFailure','BulkItemFault'],
                    unit: 'Count',
                },
            ],
        }
    ],
};

export const BULK_SHARD_INDEXING_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingCoordinator',
    dimensionNames: INDEXING_DIMENSIONS,
    graphs: [
        {
            title: 'BulkShard Metrics',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['Success','Fault','Failure'],
                    unit: 'Count',
                },
            ],
        }
    ],
};

export const ROLLOVER_INDEXING_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingCoordinator',
    dimensionNames: INDEXING_DIMENSIONS,
    graphs: [
        {
            title: 'Rollover Metrics',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['Success','Fault','Failure'],
                    unit: 'Count',
                },
            ],
        }
    ],
};

export const METRIC_RETENTION_INFO =[
    "Data points with a period of less than 60 seconds are available for 3 hours",
    "Data points with a period of 60 seconds (1 minute) are available for 15 days",
    "Data points with a period of 300 seconds (5 minute) are available for 63 days",
    "Data points with a period of 3600 seconds (1 hour) are available for 455 days (15 months)"
]

const DAY_TO_HOURS = 24
const HOURS_TO_SECONDS = 3600
const MINUTE_TO_SECONDS = 60

export const HOURS_3 = 3 * HOURS_TO_SECONDS
export const DAYS_15 = 15 * DAY_TO_HOURS * HOURS_TO_SECONDS
export const DAYS_63 = 63 * DAY_TO_HOURS * HOURS_TO_SECONDS
export const DAYS_455 = 455 * DAY_TO_HOURS * HOURS_TO_SECONDS

export const SEC_1 = 1
export const MINUTE_1 = 1 * MINUTE_TO_SECONDS
export const MINUTE_5 = 5 * MINUTE_TO_SECONDS
export const MINUTE_15 = 15 * MINUTE_TO_SECONDS
export const HOUR_1 = 1 * HOURS_TO_SECONDS
export const DAY_1 = 1 * DAY_TO_HOURS * HOURS_TO_SECONDS
