import React, { Fragment } from 'react';
import { EuiToast, EuiTextColor, EuiSpacer } from '@elastic/eui';
export class ErrorToast extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { error, title, closer } = this.props;
    if (!error) {
      return null;
    }
    return (
      <Fragment>
        <EuiToast
          title={title}
          color="danger"
          iconType="alert"
          onClose={closer}
        >
          <EuiTextColor color="danger">{error}</EuiTextColor>
        </EuiToast>
        <EuiSpacer size="xl" />
      </Fragment>
    );
  }
}
