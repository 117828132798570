import { Link } from '@amzn/awsui-components-react';

const CardHeader = (props) => {
  if (props.display === true) {
    return <Link variant="info">{props.cardName}</Link>;
  }
  return <Link variant="info"></Link>;
};

export { CardHeader };
