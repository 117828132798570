import { AppLayout, BreadcrumbGroup } from '@amzn/awsui-components-react';
import { Switch, Route } from 'react-router-dom';
import React, { useState } from 'react';
import { AppContext, getInitialAppContext } from '../utils/appContext';
import { Navbar } from '../utils/Navbar';
import { Sidebar } from '../utils/sidebar';
import { Homepage } from './Components/Home/Homepage';
import { Details } from './Components/Event/Details';
import { History } from './Components/Report/History';
import * as constants from './Components/Common/utils/LseToolConstants';
import { LseFallback } from "../Components/Fallback";
import { ErrorBoundary } from 'react-error-boundary';

interface HomeProps {
  children: React.ReactNode;
}
const appContext = getInitialAppContext();

const LSETool = (props: HomeProps) => {
  const setTimezone = (timezone: string) => {
    setState({ ...state, timezone: timezone });
  };
  const setBreadcrumbs = (breadcrumbs: any) => {
    setState({ ...state, breadcrumbs });
  };

  const [state, setState] = useState({
    ...getInitialAppContext(),
    setTimezone: setTimezone,
    setBreadcrumbs: setBreadcrumbs,
  });
  const [navigationOpen, setNavigationOpen] = useState(false);
  return (
    <div>
      <Navbar
        title={constants.LSEToolNavbarTitle}
        homeUrl="/lse-tool"
        regionSwitcherActive={true}
        dropdownOptions={constants.LSEToolNavbarOptions}
      />
      <div
        style={{
          paddingTop: '60px',
        }}
      >
        <AppLayout
          disableContentPaddings={false}
          content={
            <AppContext.Provider value={state}>
              <Switch>
                <Route
                  exact
                  path={constants.HOME_PATH}
                   render={(props) => {
                      return (
                          <ErrorBoundary fallbackRender={props => <LseFallback failedComponent={"LseHomepagePage"}/>}>
                             <Homepage {...props} />
                          </ErrorBoundary>
                      )}
                  }
                />
                <Route
                  exact
                  path={constants.DETAILS_PATH}
                  render={(props) => {
                      return (
                          <ErrorBoundary fallbackRender={props => <LseFallback failedComponent={"LseDetailsPage"}/>}>
                             <Details {...props} />
                          </ErrorBoundary>
                      )}
                  }
                />
                <Route
                  exact
                  path={constants.HISTORY_PATH}
                  render={(props) => <History {...props} />}
                />
              </Switch>
            </AppContext.Provider>
          }
          toolsHide={true}
          navigation={<Sidebar />}
          navigationOpen={navigationOpen}
          onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
          headerSelector="#navbar"
          breadcrumbs={
            <BreadcrumbGroup
              items={appContext.breadcrumbs}
              ariaLabel="Breadcrumbs"
            />
          }
        />
      </div>
    </div>
  );
};

export { LSETool };
