import { BreadcrumbGroup, SpaceBetween, TextContent } from '@amzn/awsui-components-react';
import {useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { JunoPlacementConstraintOverviewParams } from '../../../models/types';
import { PlacementConstraintDetails } from './PlacementConstraintDetails';
import { AppContext } from './../../../utils/appContext';
import {ScalingAuditHistory} from "./AuditHistory/ScalingAuditHistory";
import {BREADCRUMBS} from "../../../utils/constants";
import {JUNO_BASE_PATH} from "../../commons/constants";

const PlacementConstraintOverview = (props) => {
    const location = useLocation();
    const appContext = useContext(AppContext);
    const history = useHistory();
    const { awsAccountId, placementConstraintId, namespace } = useParams<JunoPlacementConstraintOverviewParams>();

    useEffect(() => {
        appContext.setBreadcrumbs([
            {
                ...BREADCRUMBS.JUNO,
                href: `${JUNO_BASE_PATH}`,
                onClick: () => {
                    history.push(`${JUNO_BASE_PATH}`);
                },
            },
            {
                text: awsAccountId,
                href: `${JUNO_BASE_PATH}/${awsAccountId}`,
                onClick: () => {
                    history.push(`${JUNO_BASE_PATH}/${awsAccountId}`);
                },
            },
            { text: placementConstraintId, href: `/pci/${awsAccountId}/${placementConstraintId}` },
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);



    return (
        <SpaceBetween direction="vertical" size="l">
            <TextContent>
                <h1>PlacementConstraint Group Overview</h1>
            </TextContent>
            <PlacementConstraintDetails awsAccountId={awsAccountId} placementConstraintId={placementConstraintId} namespace={props.namespace ? props.namespace : 'default'}/>
            <ScalingAuditHistory awsAccountId={awsAccountId} placementConstraintId={placementConstraintId} dataPlaneType='Search' namespace={props.namespace ? props.namespace : 'default'}/>
            <ScalingAuditHistory awsAccountId={awsAccountId} placementConstraintId={placementConstraintId} dataPlaneType='Indexing' namespace={props.namespace ? props.namespace : 'default'}/>
        </SpaceBetween>
    );
};

export { PlacementConstraintOverview };
