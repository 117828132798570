import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../../models/types';
 

export interface GetAccountCollectionsDetailsVars {
    awsAccountId: String;
    collectionId: String;
    collectionName: String;
}

const GET_COLLECTION_DETAILS = gql`
    query GetCollectionDetails(
            $awsAccountId: String
            $collectionId: String
            $collectionName: String
        ){
        getCollectionDetails(
            awsAccountId: $awsAccountId
            collectionId: $collectionId
            collectionName: $collectionName
        )
    }
`;
 
export const useGetCollectionDetailsQuery = () => {
    return useLazyQuery<AnyObject, GetAccountCollectionsDetailsVars>(GET_COLLECTION_DETAILS, {
        errorPolicy: 'all',
    });
};
