// App context
import React from 'react';
import { getRegion } from './helpers';
import { RegionInfo } from './regionInfo';
import { ACCOUNTS_MAP, JUNO_BEHOLDERS_ACCOUNT_MAP } from './rootAccounts';

export enum Stages {
  ALPHA = 'alpha',
  BETA = 'beta',
  GAMMA = 'gamma',
  PROD = 'prod',
}

export interface App {
  region: RegionInfo;
  timezone: string;
  setTimezone: Function;
  partition: string;
  stage: Stages;
  rootAccountId: string;
  [key: string]: any;
}

export const getInitialAppContext = () => {
  // https://columbo.prod.search-services.aws.a2z.com/iad is
  const regionInfo = getRegion(
    window.location.href.split('//')[1].split('.')[0] || 'dub'
  );
  const stageInfo = window.location.href.split('.')[1] || '';
  let stage;
  switch (stageInfo.toUpperCase()) {
    case 'ALPHA':
      stage = Stages.ALPHA;
      break;
    case 'BETA':
      stage = Stages.BETA;
      break;
    case 'GAMMA':
      stage = Stages.GAMMA;
      break;
    case 'PROD':
      stage = Stages.PROD;
      break;
    default:
      stage = Stages.BETA;
  }
  return {
    region: regionInfo,
    timezone: 'utc',
    setTimezone: () => { },
    breadcrumbs: [],
    setBreadcrumbs: () => { },
    partition: 'aws',
    stage,
    rootAccountId: ACCOUNTS_MAP.aws[stage][regionInfo.publicRegion], // TODO:: Make partition dynamic
    junoBeholderAccountId: JUNO_BEHOLDERS_ACCOUNT_MAP.aws[stage][regionInfo.publicRegion], // TODO:: Make partition dynamic
    preferences: {
      humanFriendlyTime: true,
    },
  };
};

const AppContext = React.createContext<App>(getInitialAppContext());
export { AppContext };
