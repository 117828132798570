import { gql, useQuery } from '@apollo/client';

export interface CWMetricsVars {
  domainIdentifier: string;
  metrics: string;
  startTime: Date;
  endTime: Date;
  period: number;
}

export interface CWMetricsData {
  cwMetrics: {
    timeStamps: Date[];
    externalMetrics: {
      values: number[];

      label: string;
    };
  };
}
const GQL_CW_METRICS = gql`
  query getExternalCwMetrics(
    $domainIdentifier: String!
    $metrics: String!
    $startTime: Date!
    $endTime: Date!
    $period: Int!
  ) {
    cwMetrics(
      domainIdentifier: $domainIdentifier
      metrics: $metrics
      startTime: $startTime
      endTime: $endTime
      period: $period
    )
  }
`;

export const useGetExternalCwQuery = (vars: CWMetricsVars) => {
  return useQuery<CWMetricsData, CWMetricsVars>(GQL_CW_METRICS, {
    variables: { ...vars },
  });
};
