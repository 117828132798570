import {
  Box,
  Button,
  Cards,
  Container,
  Header,
  Link,
  Popover,
} from '@amzn/awsui-components-react/polaris';
import { impactedDomainPopoverContent } from './constants';

const EventSummary = (props) => {
  const lseStartTime = props.request['lseStartTime'] || 'LSE start time';
  const lseEndTime = props.request['lseEndTime'];
  let lastTenMinutesOfLse = '10 minute period before LSE end time';
  if (lseEndTime) {
    const endTime = new Date(lseEndTime);
    endTime.setMinutes(endTime.getMinutes() - 10);
    lastTenMinutesOfLse = endTime.toISOString().substring(0, 19) + 'Z';
  }
  let totalImpacted = props.totalImpacted;
  if (totalImpacted === -1) {
    totalImpacted = 'N/A';
  }
  let stillImpacted = props.stillImpacted;
  if (stillImpacted === -1) {
    stillImpacted = 'N/A';
  }
  return (
    <Container header={<Header variant="h2">Analysis Summary</Header>}>
      <Cards
        cardDefinition={{
          header: (item) => (
            <div>
              <Box color="text-status-info" fontSize="heading-l">
                {item.name}
              </Box>
              <Box color="text-body-secondary">{item.description}</Box>
            </div>
          ),
          sections: [
            {
              id: 'count',
              content: (item) => (
                <Popover
                  dismissButton={false}
                  position="right"
                  size="large"
                  triggerType="custom"
                  content={impactedDomainPopoverContent}
                >
                  <Link fontSize="display-l">{item.count}</Link>
                </Popover>
              ),
            },
          ],
        }}
        cardsPerRow={[{ cards: 1 }, { minWidth: 500, cards: 2 }]}
        items={[
          {
            name: 'Total Impacted Domains',
            alt: 'First',
            description: `The total number of impacted domains during LSE (i.e. ${lseStartTime} to ${lseEndTime}). Excludes all domains impacted before LSE start time.`,
            count: totalImpacted,
          },
          {
            name: 'Still Impacted Domains',
            alt: 'Second',
            description: `The total number of impacted domains in the last 10 minutes of an LSE (i.e. ${lastTenMinutesOfLse} to ${lseEndTime}). Also excludes all domains impacted before LSE start time.`,
            count: stillImpacted,
          },
        ]}
        loadingText="Loading resources"
        empty={
          <Box textAlign="center" color="inherit">
            <b>No resources</b>
            <Box padding={{ bottom: 's' }} variant="p" color="inherit">
              No resources to display.
            </Box>
            <Button>Create resource</Button>
          </Box>
        }
      />
    </Container>
  );
};
export { EventSummary };
