import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../../models/types';

export interface SnapshotsInfoVars {
  domainIdentifier: string;
  instanceId: string;
}

export interface SnapshotsInfoData {
  domain: {
    es60: {
      cat: {
        snapshots?: AnyObject;
      };
    };
  };
}

export const SNAPSHOTS_INFO = gql`
  query getSnapshotsInfo($domainIdentifier: String!, $instanceId: String!) {
    domain(domainIdentifier: $domainIdentifier, instanceId: $instanceId) {
      es60 {
        cat {
          snapshots
        }
      }
    }
  }
`;

export const useSnapshotsInfoQuery = () => {
  return useLazyQuery<SnapshotsInfoData, SnapshotsInfoVars>(SNAPSHOTS_INFO, {
    errorPolicy: 'all',
  });
};
