import { ColumnLayout, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';

interface TumblerStatProps {
  title: string;
  description: string | string[] | React.ReactNode;
  isLoading: boolean;
}

const TumblerStat = (props: TumblerStatProps) => {
  return (
    <ColumnLayout columns={1}>
      <SpaceBetween size="s">
        <div>
          <strong>{props.title}</strong>
        </div>
        <div>
          <p className="tumbler-stat">
            {props.isLoading ? '--' : props.description}
          </p>
        </div>
      </SpaceBetween>
    </ColumnLayout>
  );
};
export { TumblerStat };
