import {
  Alert,
  Box,
  Button,
  ButtonDropdown,
  Header,
  Modal,
  Popover,
  ProgressBar,
  SegmentedControl,
  SpaceBetween,
  Spinner,
  StatusIndicator,
  Toggle,
} from '@amzn/awsui-components-react';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getDetailPageS3ObjectKey,
  getInterval,
  getRoundedEndTimeUtc,
  getEndTimeUtc,
} from './../../utils';
import { AggregationTableView } from './AggregationTableView';
import { TIME_INTERVAL_OPTIONS, TIME_WINDOW_OPTIONS } from './constants';
import {
  useDetailPageDataFetchingQuery,
  useDetailPageDataRequestingQuery,
} from './detailpageAllQuery';
import { EventDetails } from './EventDetails';
import { EventEditor } from './EventEditor';
import { EventSummary } from './EventSummary';
import { ImpactedAccount } from './ImpactedAccount';
import { ImpactedDomain } from './ImpactedDomain';
import { IssueGraph } from './IssueGraph';
import { isError} from "../../../ErrorHandler/apiErrorHandler";
import { LseErrorMessageComponent } from "../../../ErrorHandler/errorMessageComponent";

const Details = (props) => {
  const url = new URLSearchParams(useLocation().search);
  const timeWindow = url.get('timeWindow') ? url.get('timeWindow') : '3days';
  const [selectedTimeWindow, setSelectedTimeWindow] = useState(timeWindow);
  const interval = url.get('interval') ? url.get('interval') : '5m';
  const [selectedInterval, setSelectedInterval] = useState(interval);
  const current = new Date();
  const endTimeUtc = url.get('endTimeUtc')
    ? url.get('endTimeUtc')
    : current.toISOString().substring(0, 19) + 'Z';
  const [selectedEndTimeUtc, setSelectedEndTimeUtc] = useState(
    getRoundedEndTimeUtc(new Date(endTimeUtc))
  );
  const [selectedIssueTypes] = useState(url.get('issueTypes'));
  const lseStartTimeUtc = url.get('lseStartTimeUtc')
    ? url.get('lseStartTimeUtc')
    : '';
  const [selectedLseStartTimeUtc, setSelectedLseStartTimeUtc] =
    useState(lseStartTimeUtc);
  const lseEndTimeUtc = url.get('lseEndTimeUtc')
    ? url.get('lseEndTimeUtc')
    : '';
  const [selectedLseEndTimeUtc, setSelectedLseEndTimeUtc] =
    useState(lseEndTimeUtc);
  const [s3Key, setS3Key] = useState(
    getDetailPageS3ObjectKey(
      selectedEndTimeUtc,
      selectedTimeWindow,
      selectedInterval,
      selectedIssueTypes,
      selectedLseStartTimeUtc,
      selectedLseEndTimeUtc
    )
  );
  const history = useHistory();
  const [getDetailPageObject, { loading: s3Loading, data: s3Response, error: reportFetchError }] =
    useDetailPageDataFetchingQuery();
  const [
    invokeReportRequestor,
    { loading: invokeRequestorLoading, data: invokeRequestorResponse, error: reportRequestError },
  ] = useDetailPageDataRequestingQuery();
  const [eventEditorVisible, setEventEditorVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [request, setRequest] = useState({});
  const [totalImpacted, setTotalImpacted] = useState(-1);
  const [stillImpacted, setStillImpacted] = useState(-1);
  const [issueGraph, setIssueGraph] = useState({});
  const [standByEnabledIssueGraph, setStandByEnabledIssueGraph] = useState({});
  const [aggregations, setAggregations] = useState({});
  const [impactedDomains, setImpactedDomains] = useState([]);
  const [impactedAccounts, setImpactedAccounts] = useState([]);
  const [tinyUrl, setTinyUrl] = useState('');
  const [alertVisible, setAlertVisible] = useState(true);
  const [autoRefreshPage, setAutoRefreshPage] = useState((url.get('autoRefresh') === 'true'));
  const [autoRefreshProgressValue, setAutoRefreshProgressValue] = useState(100);
  const [autoRefreshTimeoutId, setAutoRefreshTimeoutId] = useState(null);
  const [updateAutoRefreshProgressTimeoutId, setUpdateAutoRefreshProgressTimeoutId] = useState(null);
  const [hiddenAutoRefreshProgressBar, setHiddenAutoRefreshProgressBar] = useState((url.get('autoRefresh') === 'true'));
  const bigUrl = encodeURIComponent(window.location.href);
  const [errorMessage, setErrorMessage] = useState(null);
  const REQUEST_OPTIONS = {
    crossDomain: true,
    method: 'GET',
    headers: { Accept: 'application/json' },
  };

   const isReportRequestErrorPresent = isError(reportRequestError)
   const isReportFetchErrorPresent = isError(reportFetchError)
   useEffect(() => {
    if ( isReportRequestErrorPresent || isReportFetchErrorPresent) {
      setErrorMessage("Unable to get LSE details. Please refresh the page or try again later");
    }
  }, [isReportRequestErrorPresent, isReportFetchErrorPresent, reportRequestError, reportFetchError]);
   const handleRefreshData = async () => {
     window.location.reload(true);
  };

  const tinyify = () => {
    fetch(`https://tiny.amazon.com/submit/url?name=${bigUrl}`, REQUEST_OPTIONS)
      .then((res) => res.json())
      .then((result) => {
        const shortUrl = result.short_url.substring(
          0,
          result.short_url.lastIndexOf('/')
        );
        setTinyUrl(shortUrl);
        navigator.clipboard.writeText(shortUrl);
      });
  };
  const closeModal = (props) => {
    setEventEditorVisible(false);
    const lseStartTime =
      props && props['lse_start_time']
        ? props['lse_start_time'] + 'Z'
        : selectedLseStartTimeUtc;
    const lseEndTime =
      props && props['lse_end_time']
        ? props['lse_end_time'] + 'Z'
        : selectedLseEndTimeUtc;
    const issueGraphEndTime =
      props && props['issue_graph_end_time']
        ? props['issue_graph_end_time'] + 'Z'
        : endTimeUtc;
    setSelectedLseStartTimeUtc(lseStartTime);
    setSelectedLseEndTimeUtc(lseEndTime);
    setSelectedEndTimeUtc(issueGraphEndTime);
    if (props) {
      setModalVisible(true);
    }
    callDetailPageDataRequestingQuery(
      issueGraphEndTime,
      timeWindow,
      interval,
      selectedIssueTypes,
      lseStartTime,
      lseEndTime
    );
  };
  const cancelEventEditor = () => {
    setEventEditorVisible(false);
    setModalVisible(false);
  };
  // eslint-disable-next-line
  const callDetailPageDataFetchingQuery = (s3Key: String) => {
    getDetailPageObject({
      variables: {
        key: s3Key,
      },
    });
  };
  const callDetailPageDataRequestingQuery = (
    endTimeUtc: String,
    timeWindow: String,
    interval: String,
    issueTypes: String,
    lseStartTimeUtc: String,
    lseEndTimeUtc: String
  ) => {
    invokeReportRequestor({
      variables: {
        endTimeUtc: endTimeUtc,
        timeWindow: timeWindow,
        interval: interval,
        issueTypes: issueTypes,
        lseStartTimeUtc: lseStartTimeUtc,
        lseEndTimeUtc: lseEndTimeUtc,
      },
    });
  };
  const changeTimeWindow = (timeWindow, autoRefresh: boolean) => {
    setSelectedTimeWindow(timeWindow);
    const interval = getInterval(timeWindow);
    setSelectedInterval(interval);
    const endTimeUtc = getEndTimeUtc(new Date());
    setSelectedEndTimeUtc(endTimeUtc);
    setModalVisible(true);
    const lseEndTime = autoRefresh ? endTimeUtc : selectedLseEndTimeUtc;
    setSelectedLseEndTimeUtc(lseEndTime);
    callDetailPageDataRequestingQuery(
      endTimeUtc,
      timeWindow,
      interval,
      selectedIssueTypes,
      selectedLseStartTimeUtc,
      lseEndTime
    );
  };
  const updateAutoRefreshProgress = (timeLeft: number, timeTotal: number, startTime: number) => {
    const progressTime = timeLeft * 100 / timeTotal;
    setAutoRefreshProgressValue(progressTime);
    if (timeLeft > 0) {
      const timeoutId = setTimeout(function() {
        const elapsed = Date.now() - startTime;
        const remaining = timeTotal - elapsed / 1000;
        updateAutoRefreshProgress(remaining, timeTotal, startTime);
      }, 3000);
      setUpdateAutoRefreshProgressTimeoutId(timeoutId);
    }
  };

  useEffect(() => {
    if (s3Response && !s3Loading) {
      const response = get(s3Response, 'detailPageFetchingData.s3Response', {});
      let data = {};
      if (!Array.isArray(response) && JSON.stringify(response) !== '{}') {
        data = JSON.parse(response);
      }
      if (data && 'status' in data && data['status'] === 'pending') {
        console.log('data status is pending with s3 key: ', s3Key);
        setModalVisible(true);
        setTimeout(function () {
          window.location.reload();
        }, 20000);
      } else if (data && 'Error' in data) {
        setRequest({
          startTime: 'GQL get s3 object failed execution',
        });
      } else {
        setRequest(data['request']);
        setTotalImpacted(data['totalImpacted']);
        setStillImpacted(data['stillImpacted']);
        setIssueGraph(data['issueGraph']);
        setStandByEnabledIssueGraph(data['standByEnabledIssueGraph'])
        setAggregations(data['aggregations']);
        setImpactedDomains(data['impactedDomains']);
        setImpactedAccounts(data['impactedAccounts']);
        setModalVisible(false);
      }
      history.push(
        `/lse-tool/details?endTimeUtc=${selectedEndTimeUtc}&timeWindow=${selectedTimeWindow}&interval=${selectedInterval}&issueTypes=${selectedIssueTypes}&lseStartTimeUtc=${selectedLseStartTimeUtc}&lseEndTimeUtc=${selectedLseEndTimeUtc}&autoRefresh=${autoRefreshPage}`
      );
    }
    // On the initial page loading
    if (!s3Response && !s3Loading) {
      setModalVisible(true);
      callDetailPageDataRequestingQuery(
        selectedEndTimeUtc,
        selectedTimeWindow,
        selectedInterval,
        selectedIssueTypes,
        selectedLseStartTimeUtc,
        selectedLseEndTimeUtc
      );
    }
    // eslint-disable-next-line
  }, [s3Loading, s3Response]);

  useEffect(() => {
    if (
      invokeRequestorResponse &&
      'detailPageRequestingData' in invokeRequestorResponse
    ) {
      setS3Key(invokeRequestorResponse['detailPageRequestingData']);
      setTimeout(() => {
        callDetailPageDataFetchingQuery(
          invokeRequestorResponse['detailPageRequestingData']
        );
      }, 5000);
    }
    // eslint-disable-next-line
  }, [invokeRequestorLoading, invokeRequestorResponse]);

  useEffect(() => {
    history.push(
      `/lse-tool/details?endTimeUtc=${selectedEndTimeUtc}&timeWindow=${selectedTimeWindow}&interval=${selectedInterval}&issueTypes=${selectedIssueTypes}&lseStartTimeUtc=${selectedLseStartTimeUtc}&lseEndTimeUtc=${selectedLseEndTimeUtc}&autoRefresh=${autoRefreshPage}`
    );
    setHiddenAutoRefreshProgressBar(s => !s);
    if (autoRefreshPage) {
      updateAutoRefreshProgress(300, 300, Date.now());
      const timeoutId = setTimeout(function () {
        changeTimeWindow(selectedTimeWindow, true);
      }, 300000);
      setAutoRefreshTimeoutId(timeoutId);
    } else {
      clearTimeout(autoRefreshTimeoutId);
      clearTimeout(updateAutoRefreshProgressTimeoutId);
      setAutoRefreshProgressValue(100);
    }
    // eslint-disable-next-line
  }, [autoRefreshPage]);

  return (
    <SpaceBetween size="xs">
          <>
      <Alert
        onDismiss={() => setAlertVisible(false)}
        visible={alertVisible}
        header="TIPS:"
        dismissAriaLabel="Close alert"
        dismissible
      >
        <ol>
          <li>
            1. To view the specific domains that are still impacted, apply the
            "Still Impacted" filter to the impacted domains table below.
          </li>
          <li>
            2. Amazon OpenSearch Service LSE tool 2.0 Live is delayed by 15-20
            minutes. The delay does not apply to historical searches.
          </li>
          <li>
            3. All time windows 3 days or less utilize a 5-minute interval.
            7-day time windows use 15-minute intervals. 4-week time windows use
            1-hour intervals.
          </li>
          <li>
            4. The last data point in real time should not be treated as a
            complete data point. It only includes partial results.
          </li>
        </ol>
      </Alert>
      <Header
        variant="h2"
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Popover
              size="large"
              position="bottom"
              triggerType="custom"
              dismissButton={false}
              content={
                <StatusIndicator type="success">
                  Tiny URL {tinyUrl} copied
                </StatusIndicator>
              }
            >
              <Button iconName="copy" onClick={tinyify}>
                Get Tiny URL
              </Button>
            </Popover>
            <SegmentedControl
              selectedId={selectedTimeWindow}
              onChange={({ detail }) => changeTimeWindow(detail.selectedId, false)}
              label="Default segmented control"
              options={TIME_WINDOW_OPTIONS}
            />
            <ButtonDropdown
              onItemClick={({ detail }) => setSelectedInterval(detail.id)}
              items={TIME_INTERVAL_OPTIONS}
              disabled
            >
              Interval
            </ButtonDropdown>
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="primary"
                  onClick={() => setEventEditorVisible(true)}
                >
                  Edit
                </Button>
                <Button iconName="refresh" disabled={invokeRequestorLoading || s3Loading} onClick={handleRefreshData}/>
              </SpaceBetween>
            </Box>
          </SpaceBetween>
        }
      >
        <Toggle
          onChange={({ detail }) => setAutoRefreshPage(detail.checked)}
          checked={autoRefreshPage}
        >
          Auto refresh analysis (every 5 minutes)
        </Toggle>
        {
          !hiddenAutoRefreshProgressBar ?
          <ProgressBar
            value={autoRefreshProgressValue}
            description="Countdown to next refresh"
          /> : null
        }
      </Header>
            {errorMessage ? (
        <LseErrorMessageComponent errorMessage={errorMessage} />
      ) : (
          <>
          <EventSummary
        totalImpacted={totalImpacted}
        stillImpacted={stillImpacted}
        request={request}
      />
      <EventDetails request={request} />
      <IssueGraph data={issueGraph} request={request} headerValue={'Impacted Domains'}/>
      <IssueGraph data={standByEnabledIssueGraph} request={request} headerValue={'StandByEnabled Impacted Domains'}/>
      <AggregationTableView aggregations={aggregations} request={request} />
      <ImpactedDomain impactedDomains={impactedDomains} />
      <ImpactedAccount impactedAccounts={impactedAccounts} />
      <EventEditor
        visible={eventEditorVisible}
        closeEventEditor={closeModal}
        cancelEventEditor={cancelEventEditor}
        endTimeUtc={selectedEndTimeUtc}
        lseStartTimeUtc={selectedLseStartTimeUtc}
        lseEndTimeUtc={selectedLseEndTimeUtc}
      />
      <Modal visible={modalVisible} size="large">
        <h3>
          <Spinner size="normal" />
          &nbsp;Loading data... This may take a few moments.
        </h3>
      </Modal>
               </>
      )}
          </>
    </SpaceBetween>
  );
};

export { Details };
