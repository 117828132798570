import React from 'react';
import { EuiTextColor, EuiLoadingSpinner } from '@elastic/eui';

import { apiCall } from '../../utils';

export class ClusterNodes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRequestActive: true,
      nodes: [],
      exception: '',
    };
  }

  componentDidMount() {
    this.getClusterNodes();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.stackName !== this.props.stackName ||
      prevProps.clusterId !== this.props.clusterId
    ) {
      this.setState({ nodes: [] }, () => this.getClusterNodes());
    }
  }

  updateException(response) {
    this.setState({ exception: JSON.stringify(response.body) });
  }

  getClusterNodes = async (nextToken) => {
    const data = {
      cluster: {
        stackName: this.props.stackName,
        clusterId: this.props.clusterId,
      },
      maxNodes: 50,
      nextToken: nextToken,
    };
    const response = await apiCall('/cluster/node/list', data);
    this.updateResponse(response);
    if (response.body.nextToken) {
      await this.getClusterNodes(response.body.nextToken);
    } else {
      this.setState({ isRequestActive: false });
    }
  };

  updateResponse(response) {
    if (response.statusCode === 200) {
      const { nodes } = this.state;
      const { nodeIpList } = response.body;
      // eslint-disable-next-line guard-for-in
      nodeIpList.forEach((ip) => nodes.push(ip));
      this.setState({ nodes });
    } else {
      this.updateException(response);
    }
  }

  render() {
    const { nodes, isRequestActive, exception } = this.state;
    if (isRequestActive) {
      return <EuiLoadingSpinner size="s" id="loadingSpinner" />;
    } else if (exception) {
      return <EuiTextColor color="danger">{exception}</EuiTextColor>;
    } else {
      return <div>{nodes.join(' , ')}</div>;
    }
  }
}
