import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../models/types';

interface UseListExecutionsVars {
  domainId: string;
}

export const LIST_EXECUTIONS = gql`
  query ListExecutions(
    $domainId: String
  ) {
    listExecutions(
      domainId: $domainId
    )
  }
`;

export const useListExecutionsQuery = () => {
  return useLazyQuery<AnyObject, UseListExecutionsVars>(LIST_EXECUTIONS,
    {
      fetchPolicy: 'no-cache'
    });
};