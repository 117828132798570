import {
  Box,
  ColumnLayout,
  Header,
  SpaceBetween,
  Spinner,
  Toggle,
  Container,
} from '@amzn/awsui-components-react';
import { get } from 'lodash';
import React, { useState, useEffect } from 'react';
import { CodeViewer } from '../../Components/Common/CodeViewer';
import { AnyObject, CommonDomainProps } from '../../models/types';
import { ConfigViewer } from './ConfigViewer';
import { useDomainConfigQuery } from './hooks/useDomainConfigQuery';
import { isError } from "../../ErrorHandler/apiErrorHandler";
import { ErrorMessageComponent } from "../../ErrorHandler/errorMessageComponent"
import { ApiList } from "../../ErrorHandler/utils";

const DomainConfig = (props: CommonDomainProps) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const { loading, data, error } = useDomainConfigQuery({
    domainIdentifier: props.domainIdentifier,
  });


  const isErrorPresent = isError(error)
   useEffect(() => {
    if (isErrorPresent) {
      setErrorMessage("Unable to get domain configurations. Failed with " + error.message);
    }
  }, [isErrorPresent, error]);

  const config: AnyObject = get(data, 'domain.rawConfig', {});
  const renderConfig = () => {
    return showRaw ? (
      <CodeViewer
        codeData={JSON.stringify(config, null, 4)}
        language={{ label: 'json', value: 'json' }}
        theme={{ label: 'coy', value: 'coy' }}
      />
    ) : (
      <ColumnLayout borders="horizontal" columns={1}>
        {Object.keys(config).map((configName: string) => {
          const currentConfig = config[configName] || {};
          // FIXME:: Ignoring root level config for not
          return currentConfig.status ? (
            <ConfigViewer
              configName={configName}
              status={currentConfig.status}
              options={
                typeof currentConfig[configName] === 'object'
                  ? currentConfig[configName]
                  : {
                      [configName]: currentConfig[configName],
                    }
              }
            />
          ) : null;
        })}
      </ColumnLayout>
    );
  };
  const [showRaw, setShowRaw] = useState(false);
  return (
    <Container header={<Header variant="h2">Configuration</Header>}>
      {isErrorPresent ? (
          <ErrorMessageComponent errorMessage={errorMessage} apiName={ApiList.DOMAIN_CONFIG}/>
      ):(
      <SpaceBetween direction="vertical" size="xxl">
        <Box float="right">
          <Toggle
            onChange={({ detail }) => setShowRaw(detail.checked)}
            checked={showRaw}
          >
            Display Raw
          </Toggle>
        </Box>
        {loading ? (
          <Box textAlign="center">
            <Spinner size="large" />
          </Box>
        ) : (
          renderConfig()
        )}
      </SpaceBetween>
          )}
    </Container>
  );
};

export { DomainConfig };
