import { INDEXING_DIMENSIONS } from "../../Account/Dashboards/constants";

export interface GraphDefinition {
    title: string;
    paramSets: Array<{
        stats: string[];
        metricNames: string[];
        unit?: string;
        isRootAccount?: boolean;
    }>;
    mathFunctionParams?: Array<{
        id?: string;
        expression: string;
        label: string;
    }>;
}

export interface MetricDefinitions {
    namespace: string;
    dimensionNames: any;
    graphs: GraphDefinition[];
}

export enum INDEXING_METRICS_DIMENSIONS {
    AWS_ACCOUNT_ID = 'AwsAccountId',
    COLLECTION_ID = 'CollectionId',
    METHOD_NAME = 'MethodName',
    SERVICE_NAME = 'ServiceName'
}

export enum INDEXING_STORAGE_METRICS_DIMENSIONS {
    AWS_ACCOUNT_ID = 'AwsAccountId',
    COLLECTION_ID = 'CollectionId',
}

export enum SEARCH_METRICS_DIMENSION {
    AWS_ACCOUNT_ID = 'aws_account_id',
    COLLECTION_ID = 'collection_id',
    METRIC_TYPE = 'metric_type'
}

export const BULK_INDEXING_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingCoordinator',
    dimensionNames: INDEXING_METRICS_DIMENSIONS,
    graphs: [
        {
            title: 'Bulk Metrics',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['Success','Fault','Failure'],
                    unit: 'Count',
                },
            ],
        }
    ],
};

export const TIME_INDEXING_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingCoordinator',
    dimensionNames: INDEXING_METRICS_DIMENSIONS,
    graphs: [
        {
            title: 'Bulk latency Metrics',
            paramSets: [
                {
                    stats: ['Average', 'p99'],
                    metricNames: ['Time'],
                    unit: 'Count',
                },
            ],
        }
    ],
};

export const BULK_ITEM_INDEXING_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingCoordinator',
    dimensionNames: INDEXING_METRICS_DIMENSIONS,
    graphs: [
        {
            title: 'BulkItem Metrics',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['BulkItemSuccess','BulkItemFailure','BulkItemFault'],
                    unit: 'Count',
                },
            ],
        }
    ],
};

export const BULK_SHARD_INDEXING_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingCoordinator',
    dimensionNames: INDEXING_METRICS_DIMENSIONS,
    graphs: [
        {
            title: 'BulkShard Metrics',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['Success','Fault','Failure'],
                    unit: 'Count',
                },
            ],
        }
    ],
};

export const CREATE_INDEX_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingCoordinator',
    dimensionNames: INDEXING_METRICS_DIMENSIONS,
    graphs: [
        {
            title: "CreateIndex Metrics",
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['Success','Failure','Fault'],
                    unit: 'Count',
                },
            ],
        }
    ],
};

export const ROLLOVER_INDEXING_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingCoordinator',
    dimensionNames: INDEXING_METRICS_DIMENSIONS,
    graphs: [
        {
            title: 'Rollover Metrics',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['Success','Fault','Failure'],
                    unit: 'Count',
                },
            ],
        }
    ],
};

export const STORAGE_INDEXING_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingCoordinator',
    dimensionNames: INDEXING_STORAGE_METRICS_DIMENSIONS,
    graphs: [
        {
            title: 'Storage Used Metrics',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['StorageUsedInS3'],
                    unit: 'Bytes',
                },
            ],
        }
    ],
};

export const STORAGE_DOC_INDEXING_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingCoordinator',
    dimensionNames: INDEXING_STORAGE_METRICS_DIMENSIONS,
    graphs: [
        {
            title: 'Storage Doc Metrics',
            paramSets: [
                {
                    stats: ['Average', 'Minimum', 'Maximum'],
                    metricNames: ['SearchableDocuments', 'DeletedDocuments'],
                    unit: 'Count',
                },
            ],
        }
    ],
};

export const SHARD_UTILIZATION_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingWorker',
    dimensionNames: INDEXING_METRICS_DIMENSIONS,
    graphs: [
        {
            title: "Shard Utilization Metrics",
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['ShardDiskSpaceUtilization','InFlightJVMUtilization','MergeSizeInBytes',
                        'OngoingMergesSizeInBytes'],
                    unit: 'Bytes',
                },
            ],
        }
    ],
};

export const SHARD_ASSIGNMENT_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingWorker',
    dimensionNames: INDEXING_METRICS_DIMENSIONS,
    graphs: [
        {
            title: "Shard Assignment Metrics",
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['SHARD_ASSIGNMENT.Success','SHARD_ASSIGNMENT.Failure','SHARD_UNASSIGNMENT.Success',
                        'SHARD_UNASSIGNMENT.Failure'],
                    unit: 'Count',
                },
            ],
        }
    ],
};

export const SEARCH_RESPONSE_COUNT_METRICS: MetricDefinitions = {
    namespace: 'JunoSearch/Coordinator',
    dimensionNames: SEARCH_METRICS_DIMENSION,
    graphs: [
        {
            title: "Response Count Metrics",
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['2xx', '3xx', '4xx', '5xx'],
                    unit: 'Count',
                },
            ],
        }
    ],
};

export const TOOK_MILLIS_METRICS: MetricDefinitions = {
    namespace: 'JunoSearch/Coordinator',
    dimensionNames: SEARCH_METRICS_DIMENSION,
    graphs: [
        {
            title: "Request Latency Metrics",
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['took_millis'],
                    unit: 'Milliseconds',
                },
                {
                    stats: ['Average'],
                    metricNames: ['took_millis'],
                    unit: 'Milliseconds',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['took_millis'],
                    unit: 'Milliseconds',
                }
            ],
        }
    ],
};

export const METRIC_RETENTION_INFO =[
    "Data points with a period of less than 60 seconds are available for 3 hours",
    "Data points with a period of 60 seconds (1 minute) are available for 15 days",
    "Data points with a period of 300 seconds (5 minute) are available for 63 days",
    "Data points with a period of 3600 seconds (1 hour) are available for 455 days (15 months)"
]

const DAY_TO_HOURS = 24
const HOURS_TO_SECONDS = 3600
const MINUTE_TO_SECONDS = 60

export const HOURS_3 = 3 * HOURS_TO_SECONDS
export const DAYS_15 = 15 * DAY_TO_HOURS * HOURS_TO_SECONDS
export const DAYS_63 = 63 * DAY_TO_HOURS * HOURS_TO_SECONDS
export const DAYS_455 = 455 * DAY_TO_HOURS * HOURS_TO_SECONDS

export const SEC_1 = 1
export const MINUTE_1 = 1 * MINUTE_TO_SECONDS
export const MINUTE_5 = 5 * MINUTE_TO_SECONDS
export const MINUTE_15 = 15 * MINUTE_TO_SECONDS
export const HOUR_1 = 1 * HOURS_TO_SECONDS
export const DAY_1 = 1 * DAY_TO_HOURS * HOURS_TO_SECONDS
