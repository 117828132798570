import { Dashboard } from '@amzn/cloudwatchdashboards-inside';
import React, { useEffect, useRef, useState } from 'react';
import { JunoRegionNotSupported } from '../commons/components/JunoRegionNotSupported';
import { getInitialAppContext } from './../../utils/appContext';
import * as u from './../commons/utils';

const JunoOpsDashboard = () => {
    const appContext = getInitialAppContext();
    const dashboard = useRef<{ [key: string]: any }>({});
    const dashboardContainer = useRef(null);
    const [, setLoading] = useState(false);
    useEffect(() => {
        if (u.isJunoSupportedRegion()) {
            setLoading(true);
            dashboard.current = new Dashboard(
                dashboardContainer.current,
                'AOSSOpsDashboard',
                appContext.region.publicRegion,
                u.dashboardConfig(
                    appContext.region.publicRegion,
                    appContext.junoBeholderAccountId,
                    appContext.stage
                ),
                u.junoBeholderDashboardStyle
            );
            dashboard.current.load('AOSSOpsDashboard');
            dashboard.current.on('ready', () => {
                setLoading(false);
            });
        }
    }, [
        appContext.region.publicRegion,
        appContext.junoBeholderAccountId,
        appContext.stage,
    ]);

    return (
        <React.Fragment>
            {!u.isJunoSupportedRegion() ? (
                <JunoRegionNotSupported />
            ) : (
                <div ref={dashboardContainer} />
            )}
        </React.Fragment>
    );
};

export { JunoOpsDashboard };
