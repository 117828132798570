import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../models/types';

interface UseGetOnDemandDataVars {
  domainId: string;
}

export const GET_ON_DEMAND_DATA = gql`
  query GetOnDemandData(
    $executionId: String
  ) {
    getOnDemandData(
      executionId: $executionId
    )
  }
`;

export const useGetOnDemandDataQuery = () => {
  return useLazyQuery<AnyObject, UseGetOnDemandDataVars>(GET_ON_DEMAND_DATA,
    {
      fetchPolicy: 'no-cache'
    });
};