import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks/dist/cjs/interfaces';
import { DateRangePickerProps } from '@amzn/awsui-components-react';
import { TimezoneToggle } from '../../../Components/TimezoneToggle';
export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Main distribution properties',
    options: [
      {
        id: 'issueType',
        label: 'issueType',
      },
      { id: 'timestamp', label: 'timestamp' },
      { id: 'details', label: 'details' },
      { id: 'isResolved', label: 'isResolved' },
      { id: 'sop', label: 'sop' },
    ],
  },
];

export const columns = [
  {
    id: 'timestamp',
    sortingField: 'timestamp',
    name: 'Timestamp',
    header: 'Timestamp',
    cell: (e) => <TimezoneToggle date={new Date(e.timestamp)} />,
  },
  {
    id: 'details',
    sortingField: 'details',
    name: 'Details',
    header: 'Details',
    cell: (e) => e.details,
    truncateText: true,
  },
  {
    id: 'isResolved',
    sortingField: 'isResolved',
    name: 'isResolved',
    header: 'isResolved',
    cell: (e) => e.isResolved,
    truncateText: true,
  },
  {
    id: 'sop',
    sortingField: 'sop',
    name: 'Relevant SOP',
    header: 'Relevant SOP',
    cell: (e) => {
      if (e.sop === 'Not found')
        return (
          <a
            href="https://quip-amazon.com/RmQnA6iMF7Kw/Adding-SOP-for-an-Issue"
            target="_blank"
            rel="noopener noreferrer"
          >
            SOP to be added
          </a>
        );
      else
        return (
          <a href={e.sop} target="_blank" rel="noopener noreferrer">
            SOP
          </a>
        );
    },
    truncateText: true,
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 Issues' },
  { value: 30, label: '30 Issues' },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 30,
  visibleContent: ['issueType', 'timestamp', 'details', 'isResolved', 'sop'],
  wraplines: false,
};

export const RELATIVE_OPTIONS: ReadonlyArray<DateRangePickerProps.RelativeOption> =
  [
    {
      key: 'previous-5-minutes',
      amount: 5,
      unit: 'minute',
      type: 'relative',
    },
    {
      key: 'previous-30-minutes',
      amount: 30,
      unit: 'minute',
      type: 'relative',
    },
    {
      key: 'previous-1-hour',
      amount: 1,
      unit: 'hour',
      type: 'relative',
    },
    {
      key: 'previous-6-hours',
      amount: 6,
      unit: 'hour',
      type: 'relative',
    },
  ];

export const FILTERING_PROPERTIES: readonly PropertyFilterProperty[] = [
  {
    propertyLabel: 'isResolved',
    key: 'isResolved',
    groupValuesLabel: 'isResolved Values',
    operators: ['='],
  },
  {
    propertyLabel: 'IssueType',
    key: 'issueType',
    groupValuesLabel: 'IssueType Values',
    operators: ['='],
  },
  {
    propertyLabel: 'Timestamp',
    key: 'timestamp',
    groupValuesLabel: 'Timestamp Values',
    operators: ['='],
  },
  {
    propertyLabel: 'Details',
    key: 'details',
    groupValuesLabel: 'Details Values',
    operators: ['='],
  },
];

export const i18nStrings = {
  todayAriaLabel: 'Today',
  nextMonthAriaLabel: 'Next month',
  previousMonthAriaLabel: 'Previous month',
  customRelativeRangeDurationLabel: 'Duration',
  customRelativeRangeDurationPlaceholder: 'Enter duration',
  customRelativeRangeOptionLabel: 'Custom range',
  customRelativeRangeOptionDescription: 'Set a custom range in the past',
  customRelativeRangeUnitLabel: 'Unit of time',
  formatRelativeRange: (e) => {
    const timeTMS = 1 === e.amount ? e.unit : `${e.unit}s`;
    return `Last ${e.amount} ${timeTMS}`;
  },
  formatUnit: (e, timeTMS) => (1 === timeTMS ? e : `${e}s`),
  dateTimeConstraintText:
    'Range must be between 6 - 30 days. Use 24 hour format.',
  relativeModeTitle: 'Relative range',
  absoluteModeTitle: 'Absolute range',
  relativeRangeSelectionHeading: 'Choose a range',
  startDateLabel: 'Start date',
  endDateLabel: 'End date',
  startTimeLabel: 'Start time',
  endTimeLabel: 'End time',
  clearButtonLabel: 'Clear',
  cancelButtonLabel: 'Cancel',
  applyButtonLabel: 'Apply',
};
