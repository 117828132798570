import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../models/types';

interface UseGetAggregationVars {
    data: string;
    fieldSize: string;
    aggregationInterval: string;
    aggregationField: string;
    endTime: string;
    startTime: string;
    eventType: string;
    domainId: string;
}

export const GET_AGGREGATION = gql`
  query GetAggregation(
    $data: String
    $fieldSize: String
    $aggregationInterval: String
    $aggregationField: String
    $endTime: String
    $startTime: String
    $eventType: String
    $domainId: String
  ) {
    getAggregation(
      data: $data
      fieldSize: $fieldSize
      aggregationInterval: $aggregationInterval
      aggregationField: $aggregationField
      endTime: $endTime
      startTime: $startTime
      eventType: $eventType
      domainId: $domainId
    )
  }
`;

export const useGetAggregationQuery = () => {
    return useLazyQuery<AnyObject, UseGetAggregationVars>(GET_AGGREGATION,
        {
            fetchPolicy: 'no-cache'
        });
};