import { Stages } from '../../utils/appContext';

export const getStageForOpsDataLake = (region, stage) => {
  let datalakeStage = stage;
  if (
    region === 'us-west-2' &&
    (stage === Stages.GAMMA || stage === Stages.BETA)
  ) {
    datalakeStage = stage === Stages.GAMMA ? Stages.BETA : Stages.GAMMA;
  }
  return datalakeStage;
};
