export class Component {
  constructor() {
    this.componentName = '';
    this.majorVersion = '';
    this.minorVersion = '';
    this.componentScope = '';
    this.componentScopeValue = '';
  }

  setComponentDetails(
    componentName,
    majorVersion,
    minorVersion,
    componentScope,
    componentScopeValue
  ) {
    this.componentName = componentName;
    this.majorVersion = majorVersion;
    this.minorVersion = minorVersion;
    this.componentScope = componentScope;
    this.componentScopeValue = componentScopeValue;
  }

  getComponentDetails() {
    const componentDetails = {
      componentName: this.componentName,
      majorVersion: this.majorVersion,
      minorVersion: this.minorVersion,
      componentScope: this.componentScope,
      componentScopeValue: this.componentScopeValue,
    };
    return componentDetails;
  }
}
