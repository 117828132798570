import { App } from './utils/appContext';

export const cognitConfig = {
  alpha: {
    domain: 'tumbler-ui-alpha.auth.us-west-2.amazoncognito.com',
    userPoolId: 'us-west-2_DOxMJpvFV',
    userPoolWebClientId: '37e6f92v0a2ao4nva888qlg1lk',
  },
  beta: {
    domain: 'tumbler-ui-beta.auth.us-west-2.amazoncognito.com',
    userPoolId: 'us-west-2_pdJ8aUe4v',
    userPoolWebClientId: '2kne36teq55jaiv0v389g5cpdj',
  },
  gamma: {
    domain: 'tumbler-ui-gamma.auth.us-west-2.amazoncognito.com',
    userPoolId: 'us-west-2_KVQQo3mBL',
    userPoolWebClientId: '5fvjsd55t94sgkam8ciovvshd3',
  },
  prod: {
    domain: 'tumbler-ui-prod.auth.us-west-2.amazoncognito.com',
    userPoolId: 'us-west-2_kVdmjYg7I',
    userPoolWebClientId: '79h0u61rdqstm50ororu2esvd9',
  },
  prodGov: {
    domain: 'tumbler-ui-gov.auth-fips.us-gov-west-1.amazoncognito.com',
    userPoolId: 'us-gov-west-1_WAHM6EFyu',
    userPoolWebClientId: '57dfjcii3ko3cidj9t5clahg9h',
  },
};

export const oauth = (appContext: App) => {
  let stage = appContext.stage.toLowerCase();
  if(appContext.region.partition == 'aws-us-gov')
    stage = 'prodGov'
  return {
  domain: cognitConfig[stage].domain,
  scope: ['openid'],
  redirectSignIn: window.location.protocol + '//' + window.location.host + '/',
  redirectSignOut: window.location.protocol + '//' + window.location.host + '/',
  responseType: 'code',
  options: {
    AdvancedSecurityDataCollectionFlag: false,
  },
}};

export const amplify = (appContext: App) => {
  let stage = appContext.stage.toLowerCase();
  let cognitoRegion = 'us-west-2'
  if(appContext.region.partition == 'aws-us-gov') {
    stage = 'prodGov'
    cognitoRegion = 'us-gov-west-1'
  }

  return {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: cognitoRegion,
    userPoolId: cognitConfig[stage].userPoolId,
    userPoolWebClientId: cognitConfig[stage].userPoolWebClientId,
    mandatorySignIn: false,
    oauth: oauth(appContext),
  },
  cookieStorage: {
    domain: `${window.location.hostname}`,
    path: '/',
    expires: 365,
    secure: true,
  },
  Analytics: {
    disabled: true,
  },
}
};
