import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../../models/types';


export interface GetSecurityConfigVars {
    awsAccountId: String;
    id: String;
}

const GET_SECURITY_CONFIG = gql`
	     query GetSecurityConfig(
	             $awsAccountId: String,
	             $id: String
	         ){
	         getSecurityConfig(
	             awsAccountId: $awsAccountId,
	             id: $id
	         )
	     }
	 `;

export const useGetSecurityConfigQuery = () => {
    return useLazyQuery<AnyObject, GetSecurityConfigVars>(GET_SECURITY_CONFIG, {
        errorPolicy: 'all',
    });
};