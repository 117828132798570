import { apiCall } from '../../../../utils';

export class DeployUtils {
  getComponentVersionList(componentDetails) {
    const componentVersionList = [];
    let i;
    let temp;
    let isRemove;
    const length = componentDetails.length;

    for (i = 0; i < length; i++) {
      if (componentDetails[i].action === 'ADD') {
        isRemove = false;
      } else {
        isRemove = true;
      }
      temp = {
        componentVersion: {
          componentName: componentDetails[i].componentName,
          componentScopeDefinition: {
            componentScope: componentDetails[i].componentScope,
            componentScopeValue: componentDetails[i].componentScopeValue,
          },
          majorVersion: componentDetails[i].majorVersion,
          minorVersion: componentDetails[i].minorVersion,
        },
        isRemove: isRemove,
      };
      componentVersionList.push(temp);
    }
    return componentVersionList;
  }

  getClusterData(stackName, clusterId) {
    const cluster = {
      stackName: stackName,
      clusterId: clusterId,
    };
    return cluster;
  }

  getDeploymentPreferenceOverride(
    availabilityThreshold,
    maxConcurrentNodesPercent
  ) {
    const deploymentPreferenceOverride = {
      availabilityThreshold: availabilityThreshold,
      maxConcurrentNodesPercent: maxConcurrentNodesPercent,
    };
    return deploymentPreferenceOverride;
  }

  applyComponentVersions = async (stackName, clusterId, componentDetails) => {
    const availabilityThreshold = 3.33;
    const maxConcurrentNodesPercent = 3.33;
    const data = {
      componentVersionList: this.getComponentVersionList(componentDetails),
      cluster: this.getClusterData(stackName, clusterId),
      deploymentPreferenceOverride: this.getDeploymentPreferenceOverride(
        availabilityThreshold,
        maxConcurrentNodesPercent
      ),
    };
    const response = await apiCall('/cluster/apply/componentversion', data);
    return response;
  };
}
