import {
  Button,
  Container,
  DateRangePicker,
  FormField,
  Header,
  Input,
  Multiselect,
  Select,
  SpaceBetween,
  Textarea,
  Toggle,
  TokenGroup,
} from '@amzn/awsui-components-react';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  i18nStrings,
  isValidRequestRange,
  relativeOptions,
} from '../commons/constants';
import { useGetJunoLogGroupsQuery } from '../hooks/useGetJunoLogGroupsQuery';
import { useRequestJunoLogsMutation } from '../hooks/useRequestJunoLogsMutation';
import { useGetJunoCWInsightQueriesQuery } from './../hooks/useGetJunoCWInsightQueriesQuery';

const RequestLogs = (props) => {
  const [isCWInsightQuery, setIsCWInsightQuery] = useState(true);
  const [selectedCWInsightQuery, setSelectedCWInsightQuery] = useState(null);
  const [selectedCWInsightQueryString, setSelectedCWInsightQueryString] =
    useState(null);
  const [selectedCWInsightLogGroups, setSelectedCWInsightLogGroups] =
    useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLogGroups, setSelectedLogGroups] = useState([]);
  const [errorTextLogGroups, setErrorTextLogGroups] = useState('');
  const [errorSearchTerm, setErrorSearchTerm] = useState('');
  const [cwSeletcedLogGroups, setCwSeletcedLogGroups] = useState([]);
  const [requestDate, setRequestDate] = useState<any>({
    type: 'relative',
    amount: 9,
    unit: 'hour',
  });
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [cwInsightQueriesOptions, setCWInsightQueriesOptions] = useState([]);
  const [requestJunoLogs, { loading }] = useRequestJunoLogsMutation();

  const [
    getJunoLogGroups,
    { loading: junoLogGroupsLoading, data: junoLogGroups },
  ] = useGetJunoLogGroupsQuery();

  const [
    getJunoCWInsightQueries,
    { loading: junoCWInsightQueriesLoading, data: junoCWInsightQueries },
  ] = useGetJunoCWInsightQueriesQuery();

  const logGroupOptions = [{ label: 'Select all loggroups', value: '*' }];
  const logGroups = get(junoLogGroups, 'getJunoLogGroups.logGroups', []);
  for (let logGroup of logGroups) {
    logGroupOptions.push({ label: logGroup, value: logGroup });
  }
  const [storedCWInsightQueries, setStoredCWInsightQueries] = useState([]);
  useEffect(() => {
    if (junoCWInsightQueries && !junoCWInsightQueriesLoading) {
      let _cwInsightQueries = get(
        junoCWInsightQueries,
        'getJunoCWInsightQueries',
        []
      );

      let options = [];
      for (let cwInsightQuery of _cwInsightQueries) {
        const name: string = cwInsightQuery.name;
        options.push({
          label: name,
          value: name,
        } as any);
      }
      setStoredCWInsightQueries(_cwInsightQueries);
      setCWInsightQueriesOptions(options);
    }
  }, [junoCWInsightQueries]);

  useEffect(() => {
    getJunoLogGroups();
    getJunoCWInsightQueries();
  }, [getJunoLogGroups]);

  useEffect(() => {
    if (props.progressValue === 100) {
      setIsRequestSent(false);
    }
  }, [props.progressValue]);
  const validateRequest = () => {
    if (props.progressValue !== undefined || props.progressValue === 0) {
      setIsRequestSent(true);
      props.setProgressValue(0);
    }

    if (!isCWInsightQuery) {
      if (selectedLogGroups.length == 0) {
        setErrorTextLogGroups('Please select log group(s)');
        return;
      } else if (selectedLogGroups.length > 20) {
        setErrorTextLogGroups(
          'Currently we only support searching 20 LogGroups at a time. Please select fewer LogGroups'
        );
        return;
      } else {
        setErrorTextLogGroups('');
      }
      if (searchTerm === '') {
        setErrorSearchTerm('Please provide a search term');
        return;
      } else {
        setErrorSearchTerm('');
      }
    }
    handleRequestLogs();
  };

  useEffect(() => {
    props.sendS3KeysLoading(loading);
  }, [loading]);

  const handleRequestLogs = async () => {
    var startMoment, endMoment;

    if ('absolute' === requestDate.type) {
      startMoment = moment(requestDate.startDate);
      endMoment = moment(requestDate.endDate);
    } else if ('relative' === requestDate.type) {
      startMoment = moment().subtract(requestDate.amount, requestDate.unit);
      endMoment = moment();
    }

    const startTimeStamp = startMoment.utc().format();
    const endTimeStamp = endMoment.utc().format();

    try {
      let args = null;
      if (isCWInsightQuery) {
        let queryString = selectedCWInsightQueryString;
        let logGroups = cwSeletcedLogGroups.map((x) => x.label).join(', ');

        args = {
          logGroups: logGroups,
          startTime: startTimeStamp,
          endTime: endTimeStamp,
          queryString: queryString,
        };
        console.log(args);
      } else {
        let logGroups = selectedLogGroups.map((x) => x.value).join(', ');
        args = {
          logGroups: logGroups,
          startTime: startTimeStamp,
          endTime: endTimeStamp,
          searchTerm: searchTerm,
        };
      }
      const resp = await requestJunoLogs({
        variables: args,
      });
      if (resp?.data?.requestJunoLogs?.success) {
        const s3Keys = resp?.data?.requestJunoLogs?.data;
        props.sendS3Keys(s3Keys);
      }
    } catch (e) {
      console.log('Error:', e);
    }
  };

  const handleRequestDateChange = ({ detail }) => {
    setRequestDate(detail.value);
  };

  const handleCWInsightQueryChange = ({ detail }) => {
    setSelectedCWInsightQuery(detail.selectedOption);
    const selectedCWQuery = storedCWInsightQueries.filter((_cwInsightQuery) => {
      if (_cwInsightQuery.name === detail.selectedOption.label) {
        return _cwInsightQuery.queryString;
      }
    })[0];
    // let logGroups = selectedCWQuery.logGroupNames.map((x) => x).join(', ');

    let cwGroups = [];
    for (let logGroup of selectedCWQuery.logGroupNames) {
      cwGroups.push({
        label: logGroup,
        value: logGroup,
      } as any);
    }
    setCwSeletcedLogGroups(cwGroups);
    // setSelectedCWInsightLogGroups(logGroups);
    setSelectedCWInsightQueryString(selectedCWQuery.queryString);
  };
  return (
    <>
      <Container
        header={
          <Header variant="h2">
            <SpaceBetween size="l" direction="horizontal">
              <span>Request Logs</span>
              <Toggle
                onChange={({ detail }) => setIsCWInsightQuery(detail.checked)}
                checked={isCWInsightQuery}
              >
                CW Insight Saved Query
              </Toggle>
            </SpaceBetween>
          </Header>
        }
      >
        <SpaceBetween direction="vertical" size="l">
          {isCWInsightQuery ? (
            <React.Fragment>
              <FormField
                label={<strong>Select the cw insight query</strong>}
                errorText={errorTextLogGroups}
              >
                <Select
                  selectedOption={selectedCWInsightQuery}
                  onChange={handleCWInsightQueryChange}
                  options={cwInsightQueriesOptions as any}
                  selectedAriaLabel="Selected"
                />
              </FormField>
              <FormField
                label={<strong>CW Insight Query</strong>}
                errorText={errorSearchTerm}
              >
                <Textarea
                  onChange={({ detail }) =>
                    setSelectedCWInsightQueryString(detail.value)
                  }
                  disableBrowserAutocorrect
                  disableBrowserSpellcheck
                  value={selectedCWInsightQueryString}
                />
              </FormField>
              <TokenGroup
                i18nStrings={{
                  limitShowFewer: 'Show fewer chosen options',
                  limitShowMore: 'Show more chosen options',
                }}
                onDismiss={({ detail: { itemIndex } }) => {
                  setCwSeletcedLogGroups([
                    ...cwSeletcedLogGroups.slice(0, itemIndex),
                    ...cwSeletcedLogGroups.slice(itemIndex + 1),
                  ]);
                }}
                items={cwSeletcedLogGroups}
                limit={3}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <FormField
                label={<strong>Select the logs to be searched</strong>}
                errorText={errorTextLogGroups}
              >
                <Multiselect
                  selectedOptions={selectedLogGroups}
                  onChange={({ detail }) =>
                    setSelectedLogGroups(detail.selectedOptions as any)
                  }
                  options={logGroupOptions}
                  selectedAriaLabel="Selected"
                  filteringType="auto"
                  placeholder="Choose options"
                  disabled={junoLogGroupsLoading}
                  tokenLimit={5}
                />
              </FormField>
              <FormField
                label={<strong>Enter the Request or Collection ID</strong>}
                errorText={errorSearchTerm}
              >
                <Input
                  onChange={({ detail }) => setSearchTerm(detail.value)}
                  value={searchTerm}
                />
              </FormField>
            </React.Fragment>
          )}
          <FormField label={<strong>Date</strong>}>
            <DateRangePicker
              onChange={handleRequestDateChange}
              value={requestDate}
              relativeOptions={relativeOptions}
              i18nStrings={i18nStrings}
              placeholder="Filter by a date and time range"
              isValidRange={isValidRequestRange}
              showClearButton={false}
            />
          </FormField>

          <Button
            variant="normal"
            onClick={validateRequest}
            loading={isRequestSent}
          >
            Submit
          </Button>
        </SpaceBetween>
      </Container>
    </>
  );
};

export { RequestLogs };
