import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks/dist/cjs/interfaces';
import { DateRangePickerProps } from '@amzn/awsui-components-react';
import moment from 'moment';
import CopyText from '../../utils/copy-text';

export const i18nStrings = {
  todayAriaLabel: 'Today',
  nextMonthAriaLabel: 'Next month',
  previousMonthAriaLabel: 'Previous month',
  customRelativeRangeDurationLabel: 'Duration',
  customRelativeRangeDurationPlaceholder: 'Enter duration',
  customRelativeRangeOptionLabel: 'Custom range',
  customRelativeRangeOptionDescription: 'Set a custom range in the past',
  customRelativeRangeUnitLabel: 'Unit of time',
  formatRelativeRange: (e) => {
    const t = 1 === e.amount ? e.unit : e.unit + 's';
    return `Last ${e.amount} ${t}`;
  },
  formatUnit: (e, t) => (1 === t ? e : e + 's'),
  relativeModeTitle: 'Relative range',
  absoluteModeTitle: 'Absolute range',
  relativeRangeSelectionHeading: 'Choose a range',
  startDateLabel: 'Start date',
  endDateLabel: 'End date',
  startTimeLabel: 'Start time',
  endTimeLabel: 'End time',
  clearButtonLabel: 'Clear',
  cancelButtonLabel: 'Cancel',
  applyButtonLabel: 'Apply',
};

export const relativeOptions: readonly DateRangePickerProps.RelativeOption[] = [
  {
    key: 'previous-5-hours',
    amount: 5,
    unit: 'hour',
    type: 'relative',
  },
  {
    key: 'previous-20-hours',
    amount: 20,
    unit: 'hour',
    type: 'relative',
  },
  {
    key: 'previous-3-days',
    amount: 3,
    unit: 'day',
    type: 'relative',
  },
  {
    key: 'previous-1-week',
    amount: 1,
    unit: 'week',
    type: 'relative',
  },
  {
    key: 'previous-1-month',
    amount: 1,
    unit: 'month',
    type: 'relative',
  },
];

export const columnDefinitions = [
  {
    id: 'status',
    header: 'Status',
    cell: (e) =>
      (e.status === '3-Done' ? 'Done' : e.status || '-') +
      ' ' +
      (e.message || ''),
    sortingField: 'status',
  },
  {
    id: 'download',
    header: 'Download',
    cell: (e) => {
      if (e.status === '3-Done' && e.s3Path) {
        const path = 's3://' + e.s3Path[0].split('/').slice(0, -1).join('/');
        const command = `aws s3 cp ${path} . --recursive`;
        return (
          <CopyText
            copyText={command}
            copyButtonLabel="Logs"
            successText="CLI command copied"
            errorText="CLI command failed to copy"
          />
        );
      } else return '-';
    },
  },
  {
    id: 'latency_in_ms',
    header: 'Latency (in ms)',
    cell: (e) => parseInt(e.latency_in_ms) || '-',
    sortingField: 'latency_in_ms',
  },
  {
    id: 'requester',
    header: 'Requested By',
    cell: (e) => e.requester,
    sortingField: 'requester',
  },
];

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Options',
    options: [
      { id: 'log_hour', label: 'Log Hour' },
      { id: 'status', label: 'Status' },
      { id: 'log_group', label: 'Log Group' },
      { id: 'latency_in_ms', label: 'Latency' },
      { id: 'download', label: 'Download' },
      { id: 'requester', label: 'Requested By' },
    ],
  },
];

export const FILTERING_PROPERTIES: readonly PropertyFilterProperty[] = [
  {
    propertyLabel: 'Log Hour',
    key: 'log_hour',
    groupValuesLabel: 'Log Hour Values',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'Status',
    key: 'status',
    groupValuesLabel: 'Status Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Log Group',
    key: 'log_group',
    groupValuesLabel: 'Log Group Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Latency',
    key: 'latency_in_ms',
    groupValuesLabel: 'Latency Values',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'Requested By',
    key: 'requester',
    groupValuesLabel: 'Requested By Values',
    operators: [':', '!:', '=', '!='],
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 50, label: '50 Log Requests' },
  { value: 100, label: '100 Log Requests' },
  { value: 500, label: '500 Log Requests' },
  { value: 1000, label: '1000 Log Requests' },
  { value: 8000, label: '8000 Log Requests' },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 1000,
  visibleContent: [
    'log_hour',
    'log_group',
    'status',
    'latency_in_ms',
    'download',
    'requester',
  ],
  wraplines: true,
};

export const globalRequestColumnDefinitions = [
  {
    id: 'domain_id',
    header: 'Domain Id',
    cell: (e) => e.domain_id,
    sortingField: 'domain_id',
  },
  {
    id: 'log_group',
    header: 'Log Group',
    cell: (e) => e.hourly_timestamp.split(' ')[1] || '-',
    sortingField: 'log_group',
  },
  {
    id: 'requester',
    header: 'Requested By',
    cell: (e) => e.requester,
    sortingField: 'requester',
  },
  {
    id: 'status',
    header: 'Status',
    cell: (e) => e.status,
    sortingField: 'status',
  },
  {
    id: 'message',
    header: 'Message',
    cell: (e) => e.message || '-',
    sortingField: 'message',
  },
  {
    id: 'last_process_timestamp',
    header: 'Last Process Timestamp',
    cell: (e) => e.last_process_timestamp || '-',
    sortingField: 'last_process_timestamp',
  },
];

export const GLOBAL_QUEUE_VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Options',
    options: [
      { id: 'domain_id', label: 'domain_id' },
      { id: 'log_hour', label: 'Log Hour' },
      { id: 'log_group', label: 'Log Group' },
      { id: 'requester', label: 'requester' },
      { id: 'status', label: 'status' },
      { id: 'message', label: 'message' },
      { id: 'last_process_timestamp', label: 'Last Process Timestamp' },
    ],
  },
];

export const GLOBAL_QUEUE_FILTERING_PROPERTIES: readonly PropertyFilterProperty[] =
  [
    {
      propertyLabel: 'Domain Id',
      key: 'domain_id',
      groupValuesLabel: 'Domain Id Values',
      operators: [':', '!:', '=', '!='],
    },
    {
      propertyLabel: 'Log Hour',
      key: 'log_hour',
      groupValuesLabel: 'Log Hour Values',
      operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
    },
    {
      propertyLabel: 'Log Group',
      key: 'log_group',
      groupValuesLabel: 'Log Group Values',
      operators: [':', '!:', '=', '!='],
    },
    {
      propertyLabel: 'Requester',
      key: 'requester',
      groupValuesLabel: 'Requester Values',
      operators: [':', '!:', '=', '!='],
    },
    {
      propertyLabel: 'Status',
      key: 'status',
      groupValuesLabel: 'Status Values',
      operators: [':', '!:', '=', '!='],
    },
    {
      propertyLabel: 'Message',
      key: 'message',
      groupValuesLabel: 'Message Values',
      operators: [':', '!:', '=', '!='],
    },
    {
      propertyLabel: 'Last Process Timestamp',
      key: 'last_process_timestamp',
      groupValuesLabel: 'Last Process Timestamp Values',
      operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
    },
  ];

export const GLOBAL_QUEUE_PAGE_SIZE_OPTIONS = [
  { value: 50, label: '50 Log Requests' },
  { value: 100, label: '100 Log Requests' },
  { value: 500, label: '500 Log Requests' },
  { value: 1000, label: '1000 Log Requests' },
  { value: 8000, label: '8000 Log Requests' },
];

export const GLOBAL_DEFAULT_PREFERENCES = {
  pageSize: 1000,
  visibleContent: [
    'domain_id',
    'log_hour',
    'log_group',
    'requester',
    'status',
    'message',
    'last_process_timestamp',
  ],
  wraplines: true,
};

let d = new Date();
let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
const isDST = Math.max(jan, jul) !== d.getTimezoneOffset();
export const timezoneOptions = [
  {
    inputDisplay: 'Browser Timezone',
    label: 'Browser Timezone',
    value: 'Browser Timezone',
    code: new Date()
      .toLocaleTimeString('en-us', { timeZoneName: 'short' })
      .split(' ')[2],
    offset: '',
    mins: new Date().getTimezoneOffset() * -1,
  },
  {
    inputDisplay: 'UTC',
    label: 'UTC',
    value: 'UTC',
    code: 'UTC',
    offset: '0000',
    mins: 0,
  },
  ...(isDST
    ? [
        {
          inputDisplay: 'US/Pacific (PDT)',
          label: 'US/Pacific (PDT)',
          value: 'US/Pacific',
          code: 'PDT',
          offset: '-0700',
          mins: -420,
        },
        {
          inputDisplay: 'America/Phoenix (MDT)',
          label: 'America/Phoenix (MDT)',
          value: 'America/Phoenix',
          code: 'MDT',
          offset: '-0600',
          mins: -360,
        },
        {
          inputDisplay: 'America/NewYork (EDT)',
          label: 'America/NewYork (EDT)',
          value: 'America/New_York',
          code: 'EDT',
          offset: '-0400',
          mins: -240,
        },
        {
          inputDisplay: 'America/Chicago (CDT)',
          label: 'America/Chicago (CDT)',
          value: 'America/Chicago',
          code: 'CDT',
          offset: '-0500',
          mins: -300,
        },
      ]
    : [
        {
          inputDisplay: 'US/Pacific (PST)',
          label: 'US/Pacific (PST)',
          value: 'US/Pacific',
          code: 'PST',
          offset: '-0800',
          mins: -480,
        },
        {
          inputDisplay: 'America/Phoenix (MST)',
          label: 'America/Phoenix (MST)',
          value: 'America/Phoenix',
          code: 'MST',
          offset: '-0700',
          mins: -420,
        },
        {
          inputDisplay: 'America/NewYork (EST)',
          label: 'America/NewYork (EST)',
          value: 'America/New_York',
          code: 'EST',
          offset: '-0500',
          mins: -300,
        },
        {
          inputDisplay: 'America/Chicago (CST)',
          label: 'America/Chicago (CST)',
          value: 'America/Chicago',
          code: 'CST',
          offset: '-0600',
          mins: -360,
        },
      ]),
  {
    inputDisplay: 'Asia/Kolkata (IST)',
    label: 'Asia/Kolkata (IST)',
    value: 'Asia/Kolkata',
    code: 'IST',
    offset: '+0530',
    mins: 330,
  },
  {
    inputDisplay: 'Asia/Shanghai (CST)',
    label: 'Asia/Shanghai (CST)',
    value: 'Asia/Shanghai',
    code: 'CST',
    offset: '+0800',
    mins: 480,
  },
];

export const TIME_WINDOW_LIMIT = '3 days';

export const REQUEST_LOGS_INSTRUCTION_HEADER =
  'Instructions for requesting Logs';

export const REQUEST_LOGS_INSTRUCTIONS = [
  `Time window can be relative like '2 days ago' or absolute giving the start time and the end time.`,
  `For relative, you can select number of hours from the starttime for which you want to request the logs. e.g., 3 hours of log from time being 4 days ago.`,
  `For absolute, select the starttime and endtime to retreive the respective hourly logs that fall in that range.`,
  `NOTE: The maximum number of hourly logs that can be requested per request for a log group is of ${TIME_WINDOW_LIMIT}.`,
];

export const DOWNLOAD_LOGS_INSTRUCTION_HEADER = 'Download Logs';

export const isValidRequestRange = (e) => {
  const t = (start, end) => {
    const a = moment(end).isBefore(moment(start));
    return a;
  };

  if ('absolute' === e.type) {
    const [a] = e.startDate.split('T'),
      [n] = e.endDate.split('T');
    if (!a || !n)
      return {
        valid: !1,
        errorMessage:
          'The selected date range is incomplete. Select a start and end date for the date range.',
      };
    if (
      moment(e.startDate).isAfter(moment()) ||
      moment(e.endDate).isAfter(moment())
    )
      return {
        value: !1,
        errorMessage: 'Either of start date or end date cannot be future date.',
      };
    if (t(e.startDate, e.endDate))
      return {
        valid: !1,
        errorMessage: 'The end date must be greater than the start date.',
      };
  } else if ('relative' === e.type) {
    if (isNaN(e.amount))
      return {
        valid: !1,
        errorMessage:
          'The selected date range is incomplete. Specify a duration for the date range.',
      };
  }
  return { valid: !0 };
};

export const convertTZ = (timestamp, tzOption) => {
  timestamp = parseInt(timestamp) * 1000;
  return moment(timestamp)
    .utcOffset(tzOption.mins)
    .format()
    .replace(new RegExp('[+-][0-9]{2}:[0-9]{2}|Z'), ' ' + tzOption.code);
};

export const addOptionToTop = (arr, label) => {
  const index = arr.findIndex((option) => option.label === label);
  if (index > 0) {
    const option = arr.splice(index, 1)[0];
    arr.unshift(option);
  }
};
