import { SpaceBetween } from '@amzn/awsui-components-react';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator/internal';
import React, { useCallback } from 'react';
import ReactDOM from 'react-dom';
import '../../Styles/logs.css';

const FileViewer = (props) => {
  const queryStringParams = new URLSearchParams(window.location.search);
  let lineIds = queryStringParams.get('lines');
  let logLinesSelected;
  let firstLineID = -1;
  if (lineIds) {
    logLinesSelected = lineIds.split('.');
    firstLineID = logLinesSelected[0];
  } else {
    logLinesSelected = [];
  }

  const handleLogLineClicked = (event) => {
    event.stopPropagation();
    const isHighlited =
      event.currentTarget.nextElementSibling.classList.toggle(
        'clicked_log_line'
      );

    const parentId = ReactDOM.findDOMNode(
      event.currentTarget
    ).parentElement.getAttribute('id');

    if (isHighlited) {
      logLinesSelected.push(parentId);
    } else {
      var index = logLinesSelected.indexOf(parentId);
      if (index >= 0) {
        logLinesSelected.splice(index, 1);
      }
    }
    queryStringParams.set(
      'lines',
      logLinesSelected
        .sort(function (a, b) {
          return a - b;
        })
        .join('.')
    );
    window.history.replaceState(null, null, `?${queryStringParams.toString()}`);
  };

  const scroll = useCallback((node) => {
    if (node !== null) {
      node.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  }, []);

  return (
    <React.Fragment>
      <div
        style={{
          fontFamily: '"Roboto Mono",Consolas,Menlo,Courier,monospace',
        }}
      >
        {!props.content ? (
          <StatusIndicator type="loading">Loading Log File</StatusIndicator>
        ) : (
          <SpaceBetween size="l" direction="vertical">
            <table className="log_container">
              {props.content
                // .split('\n')
                .split('\n')
                .map((line, index) => (
                  <tr
                    id={`${index + 1}`}
                    ref={index + 1 === firstLineID ? scroll : null}
                  >
                    <td className="index_number" onClick={handleLogLineClicked}>
                      {index + 1}
                    </td>
                    <td
                      className={
                        'log_line' +
                        (logLinesSelected.indexOf((index + 1).toString()) > -1
                          ? ' clicked_log_line'
                          : '')
                      }
                    >
                      <code>{line}</code>
                    </td>
                  </tr>
                ))}
            </table>
          </SpaceBetween>
        )}
      </div>
    </React.Fragment>
  );
};

export { FileViewer };
