import React, { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  DateRangePicker,
  DatePicker,
  Modal,
  SpaceBetween,
  TimeInput,
  DateRangePickerProps,
} from '@amzn/awsui-components-react/polaris';
import { i18nStrings, relativeOptions } from './constants';
import moment from 'moment';

const EventEditor = (props) => {
  const [lseStartTime, setLseStartTime] = useState(props.lseStartTimeUtc);
  const [lseEndTime, setLseEndTime] = useState(props.lseEndTimeUtc);
  const [issueGraphEndTime, setIssueGraphEndTime] = useState(
    props.endTimeUtc.slice(0, -1)
  );
  const handleOk = () => {
    props.closeEventEditor({
      lse_start_time: lseStartTime,
      lse_end_time: lseEndTime,
      issue_graph_end_time: issueGraphEndTime,
    });
  };
  const handleCancel = () => {
    props.cancelEventEditor();
  };
  const handleLseTimeChange = ({ detail }) => {
    let startMoment, endMoment;
    if ('absolute' === detail.value.type) {
      startMoment = moment(detail.value.startDate);
      endMoment = moment(detail.value.endDate);
    } else if ('relative' === detail.value.type) {
      startMoment = moment(props.endTimeUtc).subtract(
        detail.value.amount,
        detail.value.unit
      );
      endMoment = moment(props.endTimeUtc);
    }
    setLseStartTime(startMoment.utc().format('YYYY-MM-DDTHH:mm:SS'));
    setLseEndTime(endMoment.utc().format('YYYY-MM-DDTHH:mm:SS'));
    setlseTime(detail.value);
  };
  const handleIssueGraphDateChange = ({ detail }) => {
    setIssueGraphDate(detail.value);
    setIssueGraphEndTime(detail.value + issueGraphEndTime.substr(10));
  };
  const handleIssueGraphTimeChange = ({ detail }) => {
    setIssueGraphTime(detail.value);
    setIssueGraphEndTime(issueGraphEndTime.substr(0, 11) + detail.value);
  };
  const isValidRange: DateRangePickerProps.ValidationFunction = (e) => {
    const t = (start, end) => {
      const a = moment(end).isBefore(moment(start));
      return a;
    };
    if ('absolute' === e.type) {
      const [a] = e.startDate.split('T'),
        [n] = e.endDate.split('T');
      if (!a || !n)
        return {
          valid: !1,
          errorMessage:
            'The selected date range is incomplete. Select a start and end date for the date range.',
        };
      if (
        moment.utc(e.startDate).isAfter(moment()) ||
        moment.utc(e.endDate).isAfter(moment())
      )
        return {
          valid: !1,
          errorMessage:
            'Either of start date or end date cannot be future date.',
        };
      if (t(e.startDate, e.endDate))
        return {
          valid: !1,
          errorMessage: 'The end date must be greater than the start date.',
        };
    } else if ('relative' === e.type) {
      if (isNaN(e.amount))
        return {
          valid: !1,
          errorMessage:
            'The selected date range is incomplete. Specify a duration for the date range.',
        };
    }
    return { valid: !0 };
  };
  const [lseTime, setlseTime] = useState<any>({
    type: 'absolute',
    startDate: lseStartTime,
    endDate: lseEndTime,
  });
  const [issueGraphTime, setIssueGraphTime] = useState(
    issueGraphEndTime.substr(11)
  );
  const [issueGraphDate, setIssueGraphDate] = useState(
    issueGraphEndTime.substr(0, 10)
  );

  return (
    <Modal
      onDismiss={() => props.closeEventEditor()}
      visible={props.visible}
      closeAriaLabel="Close modal"
      size="large"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleOk}>
              Ok
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Edit event details"
    >
      <SpaceBetween direction="vertical" size="l">
        <Alert visible={true} dismissAriaLabel="Close alert" header="TIPS:">
          <ol>
            <li>
              1. You can modify the LSE start and end times below. The start and
              end times cannot be outside of the analysis window.
            </li>
            <li>
              2. The analysis end time defaults to the "endTimeUTC" you set on
              the previous page. You can modify the analysis end time below.
            </li>
          </ol>
        </Alert>
        LSE start time and end time in UTC
        <DateRangePicker
          onChange={handleLseTimeChange}
          value={lseTime}
          relativeOptions={relativeOptions}
          isValidRange={isValidRange}
          i18nStrings={i18nStrings}
          placeholder="Filter by a date and time range"
          showClearButton={false}
          timeOffset={0}
        />
        Analysis end time in UTC
        <SpaceBetween direction="horizontal" size="l">
          <DatePicker
            onChange={handleIssueGraphDateChange}
            value={issueGraphDate}
            openCalendarAriaLabel={(selectedDate) =>
              'Choose Date' +
              (selectedDate ? `, selected date is ${selectedDate}` : '')
            }
            nextMonthAriaLabel="Next month"
            placeholder="YYYY/MM/DD"
            previousMonthAriaLabel="Previous month"
            todayAriaLabel="Today"
          />
          <TimeInput
            onChange={handleIssueGraphTimeChange}
            value={issueGraphTime}
            format="hh:mm:ss"
            placeholder="hh:mm:ss"
          />
        </SpaceBetween>
      </SpaceBetween>
    </Modal>
  );
};
export { EventEditor };
