import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Button,
  Link,
  Pagination,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { CommonDomainProps, LaunchConfigurations } from '../../../models/types';
import {
  EmptyState,
  getFilterCounterText,
  Preferences,
} from './../../../utils/tablePreferences';
import {
  columns,
  DEFAULT_PREFERENCES,
  PAGE_SIZE_OPTIONS,
  VISIBLE_CONTENT_OPTIONS,
  LaunchType,
} from './columns';
import { ErrorMessageComponent } from "../../../ErrorHandler/errorMessageComponent";
import { ApiList }from "../../../ErrorHandler/utils";

interface LaunchConfigurationProps extends CommonDomainProps {
  launchConfigs: LaunchConfigurations[];
  isLoading: boolean;
  errorMessage: string;
  launchType: LaunchType;
}

const LaunchConfiguration = (props: LaunchConfigurationProps) => {
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(props.isLoading ? [] : props.launchConfigs, {
    filtering: {
      empty: (
        <EmptyState
          title="No Launch configurations"
          subtitle="No launch configurations to display."
          action={<span></span>}
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can’t find a match."
          action={
            <Button onClick={() => actions.setFiltering('')}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
  });

  return (
  <div>
  {props.errorMessage ? (
    <ErrorMessageComponent errorMessage={props.errorMessage} apiName={ApiList.LAUNCH_CONFIGURATION}/>
  ) : (
    <Table
      {...collectionProps}
      columnDefinitions={[
        ...columns(props.launchType),
        {
          id: 'imageId',
          header: 'Image id',
          cell: (e) => {
            return (
              <Link
                href={`https://apollo.amazon.com/version_filter.html?versionInstance=${e.imageId}`}
                external
              >
                {e.imageId}
              </Link>
            );
          },
        },
      ]}
      stickyHeader={true}
      resizableColumns={true}
      wrapLines={preferences.wraplines}
      loading={props.isLoading}
      loadingText={
        props.isLoading ? 'Loading Security Groups' : 'No Security Groups found'
      }
      visibleColumns={preferences.visibleContent}
      items={items}
      pagination={
        <Pagination
          {...paginationProps}
          ariaLabels={{
            nextPageLabel: 'Next page',
            previousPageLabel: 'Previous page',
            pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
          }}
        />
      }
      filter={
        <TextFilter
          {...filterProps}
          countText={getFilterCounterText(filteredItemsCount)}
          filteringAriaLabel="Filter instances"
        />
      }
      preferences={
        <Preferences
          preferences={preferences}
          setPreferences={setPreferences}
          disabled={false}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          visibleContentOptions={VISIBLE_CONTENT_OPTIONS}
        />
      }
    />
  )}
  </div>
  );
};

export { LaunchConfiguration };
