import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../../models/types';


export interface GetDPCellNameVars {
    awsAccountId: String;
}

const GET_DP_CELL_NAME = gql`
    query GetDPCellName(
            $awsAccountId: String
        ){
        getDPCellName(
            awsAccountId: $awsAccountId
        )
    }
`;

export const useGetDPCellNameQuery = () => {
    return useLazyQuery<AnyObject, GetDPCellNameVars>(GET_DP_CELL_NAME, {
        errorPolicy: 'all',
    });
};
