// This component renders the Node shard count skew table
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Box,
  Button,
  ColumnLayout,
  ExpandableSection,
  Header,
  Pagination,
  SpaceBetween,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { AnyObject } from '../../../../../models/types';
import {
  EmptyState,
  getFilterCounterText,
  Preferences,
  ValueWithLabel,
} from '../../../../../utils/tablePreferences';
import {
  nodeShardCountColumns,
  NODE_DEFAULT_PREFERENCES,
  NODE_VISIBLE_CONTENT_OPTIONS,
  PAGE_SIZE_OPTIONS,
} from '../columns';

interface NodeShardCountSkewInfoProps {
  nodeShardCountSkew: Array<Array<object>>;
  dropdownsInfo: Array<Array<Array<boolean>>>;
  diNumber: number;
  setDropdownsInfo: (state: Array<Array<Array<boolean>>>) => void;
  isLoading: boolean;
}

const NodeShardCountSkewInfo = (props: NodeShardCountSkewInfoProps) => {
  const [preferences, setPreferences] = useState(NODE_DEFAULT_PREFERENCES);
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(
    props.nodeShardCountSkew[props.diNumber].length > 0
      ? props.nodeShardCountSkew[props.diNumber][0]['nodeValues']
      : [],
    {
      filtering: {
        empty: (
          <EmptyState
            title="No Skew"
            subtitle="No issues to display."
            action={<span></span>}
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={
              <Button onClick={() => actions.setFiltering('')}>
                Clear filter
              </Button>
            }
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
    }
  );
  return (
    <React.Fragment>
      {props.nodeShardCountSkew[props.diNumber].length > 0 && (
        <SpaceBetween direction="vertical" size="l">
          {props.nodeShardCountSkew[props.diNumber].map(
            (boxType: AnyObject, j) => (
              <ExpandableSection
                header={<Header variant="h2">Node shard count skew</Header>}
              >
                <SpaceBetween direction="vertical" size="l">
                  <ColumnLayout columns={2} variant="text-grid">
                    <SpaceBetween direction="vertical" size="l">
                      <ValueWithLabel label="Minimum">
                        <Box variant="h3" padding="n">
                          {boxType.minVal}
                        </Box>
                      </ValueWithLabel>

                      <ValueWithLabel label="Median">
                        <Box variant="h3" padding="n">
                          {boxType.median}
                        </Box>
                      </ValueWithLabel>

                      <ValueWithLabel label="Maximum">
                        <Box variant="h3" padding="n">
                          {' '}
                          {boxType.maxVal}
                        </Box>
                      </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween direction="vertical" size="l">
                      <ValueWithLabel label="Range">
                        <Box variant="h3" padding="n">
                          {' '}
                          {boxType.diff}
                        </Box>
                      </ValueWithLabel>

                      <ValueWithLabel label="Range %">
                        <Box variant="h3" padding="n">
                          {boxType.rangePercent}
                        </Box>
                      </ValueWithLabel>
                    </SpaceBetween>
                  </ColumnLayout>

                  <Table
                    header={
                      <Header variant="h3">
                        {
                          <span>
                            {boxType.boxTypeName.charAt(0).toUpperCase() +
                              boxType.boxTypeName.slice(1)}{' '}
                            {boxType.desc}
                          </span>
                        }
                      </Header>
                    }
                    {...collectionProps}
                    columnDefinitions={nodeShardCountColumns}
                    stickyHeader={true}
                    wrapLines={preferences.wraplines}
                    loading={props.isLoading}
                    loadingText={
                      props.isLoading
                        ? 'Loading Security Groups'
                        : 'No Security Groups found'
                    }
                    visibleColumns={preferences.visibleContent}
                    items={items}
                    pagination={
                      <Pagination
                        {...paginationProps}
                        ariaLabels={{
                          nextPageLabel: 'Next page',
                          previousPageLabel: 'Previous page',
                          pageLabel: (pageNumber) =>
                            `Page ${pageNumber} of all pages`,
                        }}
                      />
                    }
                    filter={
                      <TextFilter
                        {...filterProps}
                        countText={getFilterCounterText(filteredItemsCount)}
                        filteringAriaLabel="Filter instances"
                      />
                    }
                    preferences={
                      <Preferences
                        preferences={preferences}
                        setPreferences={setPreferences}
                        disabled={false}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        visibleContentOptions={NODE_VISIBLE_CONTENT_OPTIONS}
                      />
                    }
                  />
                </SpaceBetween>
              </ExpandableSection>
            )
          )}
        </SpaceBetween>
      )}
    </React.Fragment>
  );
};

export { NodeShardCountSkewInfo };
