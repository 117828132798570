import { gql, useMutation } from '@apollo/client';
import { AnyObject } from '../../models/types';

interface RequestLogsVars {
  domainId: string;
  logGroup: string;
  startTime: string;
  endTime?: string;
  hours?: string;
}

export const REQUEST_LOGS = gql`
  mutation RequestLogs(
    $domainId: String
    $logGroup: String
    $startTime: String
    $endTime: String
    $hours: String
  ) {
    requestLogs(
      domainId: $domainId
      logGroup: $logGroup
      startTime: $startTime
      endTime: $endTime
      hours: $hours
    )
  }
`;

export const useRequestLogsMutation = () => {
  return useMutation<AnyObject, RequestLogsVars>(REQUEST_LOGS,
    {
      fetchPolicy: 'no-cache'
    });
};
