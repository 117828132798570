import {
    Box,
    ColumnLayout,
    Container,
    Header,
    SpaceBetween,
} from '@amzn/awsui-components-react';

const ValueWithLabel = ({ label, children }) => (
    <div>
        <Box margin={{ bottom: 'xxxs' }} color="text-label">
            {label}
        </Box>
        <div>{children}</div>
    </div>
);

const PoliciesStats = (props) => {
    return (
        <Container header={<Header variant="h2">Policy Statistics</Header>}>
            <ColumnLayout columns={3} variant="text-grid">
                <SpaceBetween size="l">
                    <ValueWithLabel label="Total Policy Count">
                        {props.data
                            ? props.data.record.TotalPolicyCount
                            : '--'}
                    </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                    <ValueWithLabel label="Encryption Policy Count">
                        {props.data
                            ? props.data.record.SecurityPolicyCount.EncryptionPolicyCount
                            : '--'}
                    </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                    <ValueWithLabel label="Data Policy Count">
                        {props.data
                            ? props.data.record.AccessPolicyStats.DataPolicyCount
                            : '--'}
                    </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                    <ValueWithLabel label="Network Policy Count">
                        {props.data
                            ? props.data.record.SecurityPolicyCount.NetworkPolicyCount
                            : '--'}
                    </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                    <ValueWithLabel label="Saml Config Count">
                        {props.data
                            ? props.data.record.SecurityConfigCount.SamlConfigCount
                            : '--'}
                    </ValueWithLabel>
                </SpaceBetween>
            </ColumnLayout>
        </Container>
    );
};

export { PoliciesStats };
