import {
  BreadcrumbGroup,
  Button,
  Container,
  ExpandableSection,
  Flashbar,
  FormField,
  Grid,
  Header,
  Input,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator/internal';
import { get } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory, useParams } from 'react-router-dom';
import '../../Styles/logs.css';
import { AppContext } from '../../utils/appContext';
import { BREADCRUMBS } from '../../utils/constants';
import { CISPE_COMPLIANCE_MESSAGE } from '../../constants';
import CopyText from '../../utils/copy-text';
import {
  accountMapping,
  DOWNLOAD_LOGS_INSTRUCTION_MESSAGE,
  DOWNLOAD_LOGS_INSTRUCTION_WARNING,
} from './constants';
import { useViewLogQuery } from './useViewLogQuery';
import { getStageForOpsDataLake } from './utils';
import { LogRedactionFeedbackWrapper } from '../../Components/LogRedaction/LogRedactionFeedbackWrapper';

const LogViewer = (props) => {
  const queryStringParams = new URLSearchParams(window.location.search);
  let lineIds = queryStringParams.get('lines');
  let logLinesSelected;
  let firstLineID = -1;
  if (lineIds) {
    logLinesSelected = lineIds.split('.');
    firstLineID = logLinesSelected[0];
  } else {
    logLinesSelected = [];
  }

  const appContext = useContext(AppContext);
  const history = useHistory();

  const params = useParams();
  const urlLogPath = params['logId'];
  var [year, month, day, hour, logGroupName, logFileName] =
    urlLogPath.split('/');

  const clientId = params['clientId'];
  const domainName = params['domainName'];

  let stage = appContext.stage;
  let region = appContext.region.publicRegion;

  stage = getStageForOpsDataLake(region, stage);

  const account = get(accountMapping, `${stage}.${region}`, []);

  const logPath = [
    `swift-ops-datalake-${stage}-${region}-timber-on-tumbler-data`,
    clientId,
    domainName,
    `year=${year}`,
    `month=${month}`,
    `day=${day}`,
    `hour=${hour}`,
    logGroupName,
    logFileName,
  ];
  const logId = logPath.join('/');

  const path = logId.split('/');
  const s3Bucket = path.shift();
  const s3Key = path.join('/');

  const logDateTimeInfo = [year, month, day].join('-') + 'T' + hour + ':00:00Z';

  const [viewLogFile, { data: logFileResponse }] = useViewLogQuery();

  const callGetLogFileFetchingQuery = useCallback(
    (s3Bucket: String, s3Key: String, ticket: String, description: String) => {
      viewLogFile({
        variables: {
          bucket: s3Bucket,
          key: s3Key,
          ticket: ticket,
	        description: description,
        },
      });
    },
    [viewLogFile]
  );

  const [logContent, setLogContent] = useState({
    logContent: '',
    truncated: false,
  });
  const [ticket, setTicket] = useState('');
  const [description, setDescription] = useState('');
  const [emptyFieldMessage, setEmptyFieldMessage] = useState('');

  useEffect(() => {
    setLogContent(get(logFileResponse, 'viewLog.viewLogResponse.record', ''));
  }, [logFileResponse]);

  useEffect(() => {
    callGetLogFileFetchingQuery(s3Bucket, s3Key, ticket, description);
  }, [s3Bucket, s3Key, callGetLogFileFetchingQuery]);

  useEffect(() => {
    appContext.setBreadcrumbs([
      {
        ...BREADCRUMBS.TUMBLER,
        href: '/',
        onClick: () => {
          history.push('/');
        },
      },
      {
        text: clientId,
        href: `/${clientId}`,
        onClick: () => {
          history.push(`/${clientId}`);
        },
      },
      {
        text: domainName,
        href: `/${clientId}/${domainName}`,
        onClick: () => {
          history.push(`/${clientId}/${domainName}`);
        },
      },
      {
        text: logGroupName + ' -> ' + logFileName,
        href: `/${clientId}/${domainName}/log/${logId}`,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogLineClicked = (event) => {
    event.stopPropagation();
    const isHighlited =
      event.currentTarget.nextElementSibling.classList.toggle(
        'clicked_log_line'
      );

    const parentId = ReactDOM.findDOMNode(
      event.currentTarget
    ).parentElement.getAttribute('id');

    if (isHighlited) {
      logLinesSelected.push(parentId);
    } else {
      var index = logLinesSelected.indexOf(parentId);
      if (index >= 0) {
        logLinesSelected.splice(index, 1);
      }
    }
    queryStringParams.set(
      'lines',
      logLinesSelected
        .sort(function (a, b) {
          return a - b;
        })
        .join('.')
    );
    window.history.replaceState(null, null, `?${queryStringParams.toString()}`);
  };

  const scroll = useCallback((node) => {
    if (node !== null) {
      node.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  }, []);

  const handleLogFetch = (event) => {
    if (ticket && description) {
      callGetLogFileFetchingQuery(s3Bucket, s3Key, ticket, description)
      setEmptyFieldMessage("")
    } else {
      setEmptyFieldMessage("To view raw logs, ticket and description fields cannot be empty!")
    }
  }

  const s3Path = 's3://' + logId;
  // console.log("s3Path: ", s3Path)
  const command = `aws s3 cp ${s3Path} .`;
  return (
    <React.Fragment>
      <SpaceBetween size="m" direction="vertical">
        <BreadcrumbGroup items={appContext.breadcrumbs} />
        <ExpandableSection
          header={<Header variant="h2">Downloading Instructions</Header>}
          variant="container"
        >
          <SpaceBetween size="s" direction="vertical">
            <p>
              <b>
                {DOWNLOAD_LOGS_INSTRUCTION_WARNING}
              </b>
            </p>
            <p>
              <b>
                {DOWNLOAD_LOGS_INSTRUCTION_MESSAGE}
              </b>
            </p>
            <p>
              <b>1) </b>Export the account credentials{' '}
              <LogRedactionFeedbackWrapper
                  buttonContent="here"
                  isTot={true}
                  domainId={clientId + ':' + domainName}
                  account={account}
              />{' '}
              using ops-datalake-user role.
            </p>
            <p>
              <b>2) </b>Copy this command and run it to download this log File.
            </p>
            <code>
              <CopyText
                copyText={command}
                copyButtonLabel={command}
                successText="CLI command copied"
                errorText="CLI command failed to copy"
              />
            </code>
          </SpaceBetween>
        </ExpandableSection>
        <Container
          header={
            <>
              <SpaceBetween size="m" direction="horizontal">
                <Header variant="h2">{logGroupName}</Header>
                <Header variant="h3">{logFileName}</Header>
              </SpaceBetween>
              <p>{logDateTimeInfo}</p>
              <Flashbar items={[
                {
                  type: "error",
                  content: CISPE_COMPLIANCE_MESSAGE,
                }
              ]} />
              <Grid
                gridDefinition={[
                  { colspan: { xxs: 5 } },
                  { colspan: { xxs: 5 } },
                  { colspan: { xxs: 2 } },
                ]}
              >
                <FormField
                  constraintText="Provide customer consent SIM/TT"
                  errorText={emptyFieldMessage}
                  label={<strong>Ticket</strong>}
                >
                  <Input
                    onChange={({ detail }) => setTicket(detail.value)}
                    value={ticket}
                    placeholder="Ticket/Link/ID"
                  />
                </FormField>
                <FormField
                  constraintText="Provide access justification description"
                  errorText={emptyFieldMessage}
                  label={<strong>Description</strong>}
                >
                  <Input
                    onChange={async ({ detail }) => setDescription(detail.value)}
                    value={description}
                    placeholder="Description"
                  />
                </FormField>
                <div style={{ marginTop: "26px" }}>
                  <Button variant="normal" onClick={handleLogFetch}>
                    View raw logs
                  </Button>
                </div>
              </Grid>
            </>
          }
        >
          <div
            style={{
              fontFamily: '"Roboto Mono",Consolas,Menlo,Courier,monospace',
            }}
          >
            {!logContent.logContent ? (
              <StatusIndicator type="loading">Loading Log File</StatusIndicator>
            ) : (
              <SpaceBetween size="l" direction="vertical">
                <table className="log_container">
                  {logContent.logContent
                    .split('\n')
                    .slice(0, -1)
                    .map((line, index) => (
                      <tr
                        id={`${index + 1}`}
                        ref={index + 1 === firstLineID ? scroll : null}
                      >
                        <td
                          className="index_number"
                          onClick={handleLogLineClicked}
                        >
                          {index + 1}
                        </td>
                        <td
                          className={
                            'log_line' +
                            (logLinesSelected.indexOf((index + 1).toString()) >
                            -1
                              ? ' clicked_log_line'
                              : '')
                          }
                        >
                          <code>{line}</code>
                        </td>
                      </tr>
                    ))}
                </table>
                {logContent.truncated && (
                  <p>
                    This log file is truncated to be shown here. Please use the
                    CLI command mentioned in the Downloading Instructions{' '}
                    <span
                      className="link"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      here
                    </span>{' '}
                    to download this log file.
                  </p>
                )}
              </SpaceBetween>
            )}
          </div>
        </Container>
      </SpaceBetween>
    </React.Fragment>
  );
};

export { LogViewer };
