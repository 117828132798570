import { Container, SpaceBetween } from '@amzn/awsui-components-react';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator/internal';
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FileViewer } from '../../Components/Common/FileViewer';
import '../../Styles/logs.css';
import { useGetJunoLogRecordQuery } from '../hooks/useGetJunoLogRecordQuery';

const LogRecord = (props) => {
  const params = useParams();
  const pointer = params['recordID'];
  const [
    getJunoLogRecord,
    { loading: junoLogRecordLoading, data: junoLoadRecord },
  ] = useGetJunoLogRecordQuery();

  const callGetJunoLogRecordFetchingQuery = useCallback(
    (pointer: String) => {
      getJunoLogRecord({
        variables: {
          pointer: pointer,
        },
      });
    },
    [getJunoLogRecord]
  );

  const [logRecord, setLogRecord] = useState({
    content: '',
    truncated: false,
  });

  useEffect(() => {
    setLogRecord(get(junoLoadRecord, 'getJunoLogRecord', ''));
  }, [junoLoadRecord]);

  useEffect(() => {
    callGetJunoLogRecordFetchingQuery(pointer);
  }, [callGetJunoLogRecordFetchingQuery, pointer]);

  return (
    <React.Fragment>
      <SpaceBetween size="m" direction="vertical">
        <Container header={<>Log Record</>}>
          {junoLogRecordLoading ? (
            <StatusIndicator type="loading">Loading Log File</StatusIndicator>
          ) : (
            // {/* <p style={{whiteSpace:"pre-wrap"}}><pre>{JSON.stringify(logRecord,null,2)}</pre></p> */}
            <FileViewer
              content={JSON.stringify(logRecord, null, 2).replace(/\\"/g, '"')}
            />
          )}
        </Container>
      </SpaceBetween>
    </React.Fragment>
  );
};

export { LogRecord };
