import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks/dist/cjs/interfaces';
import { TimezoneToggle } from '../../../Components/TimezoneToggle';

export const columns = [
  {
    sortingField: 'instanceId',
    id: 'instanceId',
    name: 'Instance Id',
    header: 'Instance Id',
    width: '15%',
    sortable: false,
    cell: (e) => <span>{e.instanceId}</span>,
  },
  {
    sortingField: 'description',
    id: 'description',
    name: 'Description',
    header: 'Description',
    width: '15%',
    sortable: false,
    cell: (e) => <span>{e.description}</span>,
  },
  {
    sortingField: 'details',
    id: 'details',
    name: 'Details',
    header: 'Details',
    cell: (e) => <span>{e.details}</span>,
  },
  {
    sortingField: 'cause',
    id: 'cause',
    name: 'Cause',
    header: 'Cause',
    width: '30%',
    sortable: false,
    cell: (e) => <span>{e.cause}</span>,
  },
  {
    sortingField: 'statusMessage',
    id: 'statusMessage',
    name: 'Message',
    header: 'Message',
    sortable: false,
    cell: (e) => <span>{e.statusMessage}</span>,
  },
  {
    sortingField: 'statusCode',
    id: 'statusCode',
    name: 'Code',
    header: 'Code',
    sortable: false,
    cell: (e) => <span>{e.statusCode}</span>,
  },
  {
    sortingField: 'progress',
    id: 'progress',
    name: 'Progress',
    header: 'Progress',
    cell: (e) => <span>{e.progress}</span>,
  },
  {
    sortingField: 'startTime',
    id: 'startTime',
    name: 'Start time',
    header: 'Start time',
    cell: (e) => <TimezoneToggle date={new Date(e.startTime)} />,
  },
  {
    sortingField: 'endTime',
    id: 'endTime',
    name: 'End time',
    header: 'End time',
    cell: (e) => <TimezoneToggle date={new Date(e.endTime)} />,
  },
];

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Main distribution properties',
    options: [
      { id: 'instanceId', label: 'instanceId' },
      { id: 'details', label: 'details' },
      { id: 'description', label: 'description' },
      { id: 'cause', label: 'cause' },
      { id: 'statusMessage', label: 'statusMessage' },
      { id: 'statusCode', label: 'statusCode' },
      { id: 'progress', label: 'progress' },
      { id: 'startTime', label: 'startTime' },
      { id: 'endTime', label: 'endTime' },
    ],
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 Scaling Groups' },
  { value: 30, label: '30 Scaling Groups' },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 10,
  visibleContent: [
    'instanceId',
    'details',
    'description',
    'cause',
    'statusMessage',
    'statusCode',
    'progress',
    'startTime',
    'endTime',
  ],
  wraplines: true,
};

export const FILTERING_PROPERTIES: readonly PropertyFilterProperty[] = [
  {
    propertyLabel: 'Instance Id',
    key: 'instanceId',
    groupValuesLabel: 'Instance Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Progress',
    key: 'progress',
    groupValuesLabel: 'Progress values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Status Code',
    key: 'statusCode',
    groupValuesLabel: 'Status Code Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Start Time',
    key: 'startTime',
    groupValuesLabel: 'Start Time Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'End Time',
    key: 'endTime',
    groupValuesLabel: 'End Time Values',
    operators: [':', '!:', '=', '!='],
  },
];
