import { useContext, useEffect, useState } from 'react';
// import chrome from 'ui/chrome';
// @ts-ignore
import { RouteComponentProps, useHistory } from 'react-router-dom';
// import ContentPanel from '../../Components/ContentPanel/ContentPanel';
import {
  BreadcrumbGroup,
  Button,
  ColumnLayout,
  Container,
  FormField,
  Header,
  Input,
  SpaceBetween,
  Alert,
} from '@amzn/awsui-components-react/polaris';
import { AppContext } from '../../utils/appContext';
import { BREADCRUMBS } from '../../utils/constants';
import { validation } from "../../utils/domainValidation";

const HomePage = (props: RouteComponentProps) => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const [hasError, setHasError] = useState(false);
  const [domainIdentifier, setDomainIdentifier] = useState('');
  const handleSubmit = () => {
    validation(domainIdentifier, history, setHasError);
  };
  useEffect(() => {
    appContext.setBreadcrumbs([BREADCRUMBS.TUMBLER]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <SpaceBetween size="m">
      <BreadcrumbGroup items={appContext.breadcrumbs} />
      <Alert header="Note" statusIconAriaLabel="Warning" type="warning">
        Few OpsTumbler commands can have access to customer data, please exercise caution while using commands that has access to customer data.
      </Alert>
      <Container header={<Header variant="h2">Tumbler</Header>}>
        <SpaceBetween direction="vertical" size="s">
          <ColumnLayout columns={4}>
            <FormField
              constraintText="Provide domain identifier (clientId:domain-name) or client
                id (12 digit AWS AccountID)"
              label="Domain Identifier"
              errorText={
                hasError ? 'Provide valid domain identifier or clientId' : ''
              }
            >
              <Input
                onChange={(event) => setDomainIdentifier(event.detail.value.trim())}
                value={domainIdentifier}
                autoFocus
                disableBrowserAutocorrect
              />
            </FormField>
          </ColumnLayout>
          <Button variant="primary" onClick={() => handleSubmit()}>
            Domain Details
          </Button>
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
};
export { HomePage };
