import React from 'react';
import { convertEpochToDate, apiCall } from '../../../../utils';
import {
  EuiHeader,
  EuiHeaderSectionItem,
  EuiInMemoryTable,
  EuiButton,
} from '@elastic/eui';
import { DescribeTask } from './DescribeTask';
import { DeployUtils } from './DeployUtils';

export class ComponentVersionTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRequestActive: true,
      isInputsSet: false,
      isResponseUpdated: false,
      statusCode: '',
      exception: '',
      items: [],
      componentDetails: [],
    };
    this.selection = [];
    this.componentDetails = [];
  }

  componentDidMount() {
    this.listComponentVersion();
  }

  updateResult(response) {
    let i;
    let rowData;
    let majorVersion;
    let minorVersion;
    const data = [];
    const componentDetail = response.body;
    const length = componentDetail.componentVersions.length;
    for (i = 0; i < length; i++) {
      majorVersion =
        componentDetail.componentVersions[i].componentVersion.majorVersion;
      minorVersion =
        componentDetail.componentVersions[i].componentVersion.minorVersion;
      rowData = {
        id: i + 1,
        componentVersion:
          componentDetail.componentVersions[i].componentVersion.componentName +
          '-' +
          majorVersion +
          '-' +
          minorVersion,
        componentScope:
          componentDetail.componentVersions[i].componentVersion
            .componentScopeDefinition.componentScope,
        componentScopeValue:
          componentDetail.componentVersions[i].componentVersion
            .componentScopeDefinition.componentScopeValue,
        createdBy:
          componentDetail.componentVersions[i].auditProperties.createdBy,
        creationTime: convertEpochToDate(
          componentDetail.componentVersions[i].auditProperties.creationTime
        ),
        lastModificationTime: convertEpochToDate(
          componentDetail.componentVersions[i].auditProperties
            .lastModificationTime
        ),
        lastModifiedBy:
          componentDetail.componentVersions[i].auditProperties.lastModifiedBy,
      };
      data.push(rowData);
    }
    this.setState({ items: data });
  }

  updateException(response) {
    this.setState({ exception: response.body });
  }

  updateResponse(response) {
    this.setState({ statusCode: response.statusCode });
    if (response.statusCode === 200) {
      this.updateResult(response);
    } else {
      this.updateException(response);
    }
  }

  listComponentVersion = async () => {
    const data = {
      stackName: this.props.stackName,
      componentName: this.props.componentName,
      componentScopeDefinition: {
        componentScope: this.props.componentScope,
        componentScopeValue: this.props.componentValue,
      },
      maxResults: 100,
    };
    const response = await apiCall('/component/version/list', data);
    this.setState({ isRequestActive: false });
    this.updateResponse(response);
    this.setState({ isResponseUpdated: true });
  };

  onSelectionChange(selection) {
    this.selection = selection;
  }

  setComponentDetails(component, componentScope, componentScopeValue) {
    const componentVersion = component.split('-');
    const componentName = componentVersion[0];
    const majorVersion = componentVersion[1];
    const minorVersion = componentVersion[2];
    const temp = {
      componentName: componentName,
      majorVersion: majorVersion,
      minorVersion: minorVersion,
      componentScope: componentScope,
      componentScopeValue: componentScopeValue,
      action: 'ADD',
    };
    this.componentDetails.push(temp);
  }

  applyComponentVersion = async () => {
    let i;
    const componentVersion = new DeployUtils();
    const length = this.selection.length;
    this.componentDetails = [];
    for (i = 0; i < length; i++) {
      this.setComponentDetails(
        this.selection[i].componentVersion,
        this.selection[i].componentScope,
        this.selection[i].componentScopeValue
      );
    }
    const response = await componentVersion.applyComponentVersions(
      this.props.stackName,
      this.props.clusterId,
      this.componentDetails,
      this.props.region,
      this.props.stage,
      this.props.credentials
    );
    this.updateDeploymentResponse(response);
  };

  updateDeploymentResponse(response) {
    if (response.statusCode === 200) {
      const taskID = response.body.taskID;
      this.setState({ taskID: taskID });
      this.setState({ isInputsSet: true });
      this.setState({ hasNewTaskAdded: true });
    } else {
      alert(JSON.stringify(response.body));
    }
  }

  updateTask() {
    this.setState({ hasNewTaskAdded: false });
  }

  render() {
    const error = '';
    const selection = {
      onSelectionChange: (selection) => this.onSelectionChange(selection),
    };
    const columns = [
      {
        field: 'componentVersion',
        name: 'Component Version',
      },
      {
        field: 'createdBy',
        name: 'created By',
      },
      {
        field: 'creationTime',
        name: 'Creation Time',
      },
      {
        field: 'lastModificationTime',
        name: 'Last Modification Time',
      },
      {
        field: 'lastModifiedBy',
        name: 'Last Modified By',
      },
    ];

    const pagination = {
      initialPageSize: 10,
      pageSizeOptions: [5, 10, 15, 20],
    };
    const isSelectable = true;

    return (
      <div id="deploymenttable">
        <br />
        <EuiHeader id="tableheader2">
          <EuiHeaderSectionItem id="headingcontent4" border="none">
            Component Details
          </EuiHeaderSectionItem>
        </EuiHeader>
        <br />

        <EuiInMemoryTable
          isSelectable={isSelectable}
          items={this.state.items}
          columns={columns}
          pagination={pagination}
          loading={!this.state.isResponseUpdated}
          error={error}
          selection={selection}
          itemId="id"
        />
        <EuiButton
          id="deploycomponentButton"
          onClick={() => {
            this.applyComponentVersion();
          }}
        >
          Deploy Component
        </EuiButton>
        <DescribeTask
          hasNewTaskAdded={this.state.hasNewTaskAdded}
          isInputsSet={this.state.isInputsSet}
          taskID={this.state.taskID}
          updateTask={() => {
            this.updateTask();
          }}
          region={this.props.region}
          stage={this.props.stage}
          credentials={this.props.credentials}
        />
      </div>
    );
  }
}
