import {ErrorCodes} from "../../../ErrorHandler/apiErrorHandler";


export async function refreshData(refetch, setIsFetching, setErrorMessage, isFetching, isErrorPresent, error, component) {
  if (!isFetching) {
    setIsFetching(true); // Set loading state to true when starting a refetch
    try {
      await refetch(); // Trigger the refetch
    } catch (e) {
      if (isErrorPresent) {
        if (component === 'lse') {
          setErrorMessage("Unable to get LSE details. Please refresh the page or try again later")
        }
        else if (component === 'cloudwatchGraphs') {
          setErrorMessage("Unable to get Cloudwatch Metrics. Please refresh or try again later")
        }
        else {
          const errorMessage = error.message;
          if (
              errorMessage.includes("unexpected character") ||
              errorMessage.includes("Unexpected token")
          ) {
            setErrorMessage(
                "Unable to get domain details. Failed with " +
                ErrorCodes.BAD_GATEWAY +
                " Timeout. Please reload the page or try again later"
            );
          } else {
            setErrorMessage(
                "Unable to get domain details. Failed with " + error.message
            );
          }
        }
      }
    } finally {
      setIsFetching(false); // Set loading state to false when the refetch is complete or encounters an error
    }
  }
}