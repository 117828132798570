import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Button,
  Link,
  Pagination,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react';
import React, { useContext, useState } from 'react';
import { useFlyout } from '../../../hooks/useFlyout';
import {
  AutoScalingEvents,
  AutoScalingGroups,
  CommonDomainProps,
  Elb,
} from '../../../models/types';
import { AutoScalingEvent } from '../AutoScalingEvents/AutoScalingEvents';
import {
  EmptyState,
  getFilterCounterText,
  Preferences,
} from './../../../utils/tablePreferences';
import {
  columns,
  DEFAULT_PREFERENCES,
  PAGE_SIZE_OPTIONS,
  VISIBLE_CONTENT_OPTIONS,
} from './columns';
import { AppContext } from '../../../utils/appContext';
import { ErrorMessageComponent } from "../../../ErrorHandler/errorMessageComponent";
import { ApiList }from "../../../ErrorHandler/utils";
import {getElbLink} from "../NodeList/utils";

interface ASGProps extends CommonDomainProps {
  asgs: AutoScalingGroups[];
  events: AutoScalingEvents[];
  isLoading: boolean;
  elb: Elb;
  errorMessage: string;
}

const AutoScalingGroup = (props: ASGProps) => {
  const setFlyout = useFlyout();
  const appContext = useContext(AppContext);
  const hostAirportCode = appContext.region.airportCode.toLowerCase();
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(props.isLoading ? [] : props.asgs, {
    filtering: {
      empty: (
        <EmptyState
          title="No Autoscaling Groups"
          subtitle="No autoscaling groups to display."
          action={<span></span>}
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can’t find a match."
          action={
            <Button onClick={() => actions.setFiltering('')}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
  });
  return (
      <div>
      {props.errorMessage ? (
        <ErrorMessageComponent errorMessage={props.errorMessage} apiName={ApiList.AUTOSCALING_GROUP}/>
      ) : (
    <Table
      {...collectionProps}
      columnDefinitions={[
        {
          id: 'groupName',
          header: 'Group Name',
          cell: (e) => {
            return (
              <Button
                variant="link"
                onClick={() =>
                  setFlyout({
                    title: 'Autoscaling events',
                    size: 'l',
                    content: (
                      <AutoScalingEvent
                        domainIdentifier={props.domainIdentifier}
                        autoScalingEvents={props.events}
                        isLoading={props.isLoading}
                      />
                    ),
                  })
                }
              >
                <span style={{ color: '#00a1c9' }}>{e.groupName}</span>
              </Button>
            );
          },
        },
        {
          id: 'loadbalancerUrl',
          header: 'Loadbalancer Url',
          cell: (e) => (
            <Link
              external
              externalIconAriaLabel="Opens in a new tab"
              href={getElbLink(hostAirportCode, props.elb.loadBalancerName)}
            >
              {props.elb.loadBalancerName}
            </Link>
          ),
        },
        ...columns,
      ]}
      stickyHeader={true}
      resizableColumns={true}
      wrapLines={preferences.wraplines}
      loading={props.isLoading}
      loadingText={
        props.isLoading ? 'Loading Security Groups' : 'No Security Groups found'
      }
      visibleColumns={preferences.visibleContent}
      items={items}
      pagination={
        <Pagination
          {...paginationProps}
          ariaLabels={{
            nextPageLabel: 'Next page',
            previousPageLabel: 'Previous page',
            pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
          }}
        />
      }
      filter={
        <TextFilter
          {...filterProps}
          countText={getFilterCounterText(filteredItemsCount)}
          filteringAriaLabel="Filter instances"
        />
      }
      preferences={
        <Preferences
          preferences={preferences}
          setPreferences={setPreferences}
          disabled={false}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          visibleContentOptions={VISIBLE_CONTENT_OPTIONS}
        />
      }
    />
      )}
      </div>
    // </React.Fragment>
  );
};

export { AutoScalingGroup };
