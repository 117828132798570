import {
  Button,
  Container,
  DateRangePicker,
  DateRangePickerProps,
  FormField,
  Header,
  Input,
  Select,
  SpaceBetween,
  Spinner,
  TextContent,
} from '@amzn/awsui-components-react';
import moment from 'moment';
import * as React from 'react';
import { AppContext } from './../../utils/appContext';
import {
  i18nStrings,
  isValidRequestRange,
  relativeOptions,
  timezoneOptions,
} from './constants';
import { useTriggerHawkeyeAnalysisQuery } from './useTriggerHawkeyeAnalysisQuery';

const TriggerAnalysis = (props) => {
  const appContext = React.useContext(AppContext);
  const [requestDate, setRequestDate] = React.useState(undefined);
  const [ticketId, setTicketId] = React.useState('');
  const [output, setOutput] = React.useState('');
  const [caseType, setCaseType] = React.useState({
    label: 'Node Drop',
    value: 'node_drop',
  });
  const [
    triggerHawkeyeAnalysis,
    {
      loading: triggerHawkeyeAnalysisLoading,
      data: triggerHawkeyeAnalysisResponse,
    },
  ] = useTriggerHawkeyeAnalysisQuery();

  const [selectedRequestTimeZoneOption, setSelectedRequestTimeZoneOption] =
    React.useState({
      inputDisplay: 'UTC',
      label: 'UTC',
      value: 'UTC',
      code: 'UTC',
      offset: '0000',
      mins: 0,
    });

  const handleRequestTimezoneChange = (selectedOption) => {
    setSelectedRequestTimeZoneOption(selectedOption);
  };

  const triggerAnalysis = () => {
    var startMoment, endMoment;
    if ('absolute' === requestDate.type) {
      startMoment = moment(requestDate.startDate);
      endMoment = moment(requestDate.endDate);
    } else if ('relative' === requestDate.type) {
      startMoment = moment().subtract(requestDate.amount, requestDate.unit);
      endMoment = moment();
    }
    const startTimeStamp = startMoment.utc().format();
    const endTimeStamp = endMoment.utc().format();
    console.log(startTimeStamp, endTimeStamp, ticketId, caseType.value);
    triggerHawkeyeAnalysis({
      variables: {
        analysisType: caseType.value,
        domainIdentifier: props.domainIdentifier,
        startTime: startTimeStamp,
        endTime: endTimeStamp,
        ticketId: ticketId,
      },
    });
  };

  const command = `tumbler ${appContext.region.airportCode.toLowerCase()} aes hawkeye start-analysis -D ${
    props.domainIdentifier
  } --ST 2022-02-16T07:00:00Z --ET 2022-02-16T10:00:00Z -a node_drop -t D39549998`;

  React.useEffect(() => {
    if (
      triggerHawkeyeAnalysisResponse &&
      triggerHawkeyeAnalysisResponse.triggerHawkeyeAnalysis &&
        triggerHawkeyeAnalysisResponse.triggerHawkeyeAnalysis.data
    ) {
      setOutput(
        JSON.stringify(
          triggerHawkeyeAnalysisResponse.triggerHawkeyeAnalysis.data.record
        )
      );
    } else if (
      triggerHawkeyeAnalysisResponse &&
      !triggerHawkeyeAnalysisLoading
    ) {
      setOutput(`Input data is not valid.`);
    }
  }, [triggerHawkeyeAnalysisLoading, triggerHawkeyeAnalysisResponse]);

  return (
    <Container
      header={
        <Header variant="h2" description={command}>
          Trigger Analysis
        </Header>
      }
    >
      <SpaceBetween direction="vertical" size="l">
        <SpaceBetween direction="horizontal" size="l">
          <FormField label={<strong>Case</strong>}>
            <Select
              selectedOption={caseType}
              onChange={({ detail }) =>
                setCaseType(detail.selectedOption as any)
              }
              options={[{ label: 'Node Drop', value: 'node_drop' }, { label: 'Node Hotspot', value: 'node_hotspot' }, { label: '429 Rejections', value: 'analysis_429' }]}
              selectedAriaLabel="Selected"
            />
          </FormField>
          <FormField stretch label={<strong>Ticket ID</strong>}>
            <Input
              onChange={({ detail }) => setTicketId(detail.value)}
              value={ticketId}
              inputMode="text"
              placeholder="Enter Ticket Id for this domain's RCA"
            />
          </FormField>
          <FormField label={<strong>Date</strong>}>
            <DateRangePicker
              onChange={({ detail }) => setRequestDate(detail.value)}
              value={requestDate}
              relativeOptions={relativeOptions}
              i18nStrings={i18nStrings}
              placeholder="Filter by a date and time range"
              isValidRange={
                isValidRequestRange as DateRangePickerProps.ValidationFunction
              }
              showClearButton={false}
              timeOffset={selectedRequestTimeZoneOption.mins}
            />
          </FormField>
          <FormField label={<strong>Time Zone</strong>}>
            <Select
              selectedOption={selectedRequestTimeZoneOption}
              onChange={({ detail }) =>
                handleRequestTimezoneChange(detail.selectedOption)
              }
              options={timezoneOptions}
              selectedAriaLabel="Selected"
            />
          </FormField>
          <FormField label={'-'}>
            <Button variant="primary" onClick={triggerAnalysis}>
              Submit
            </Button>
          </FormField>
        </SpaceBetween>
        {triggerHawkeyeAnalysisLoading && <Spinner size="large" />}
        {!triggerHawkeyeAnalysisLoading && triggerHawkeyeAnalysisResponse && (
          <TextContent>
            <span
              className="custom-wrapping"
              style={{
                fontFamily: 'courier, monospace',
                backgroundColor: '#eee',
                borderRadius: '3px',
                padding: '0 3px',
              }}
            >
              {output}
            </span>
          </TextContent>
        )}
      </SpaceBetween>
    </Container>
  );
};

export { TriggerAnalysis };
