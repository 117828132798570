import React from 'react';
import { EuiFormRow, EuiText, EuiLoadingSpinner } from '@elastic/eui';
import TextErrorBoundary from './ErrorBoundary/TextErrorBoundary';

class FunctionRenderer extends React.Component {
  render() {
    return this.props.renderer();
  }
}

// eslint-disable-next-line react/prefer-stateless-function
export class FlexItemContent extends React.Component {
  render() {
    const { title, description, isLoading, isError } = this.props;
    return (
      <EuiFormRow style={{ width: '250px' }} label={title}>
        <TextErrorBoundary key={'text-boundry-outer'}>
          <EuiText
            size={'xs'}
            className={this.props.isLoading ? 'euiStat__title-isLoading' : ''}
          >
            <div className="tumbler-stat">
              {(() => {
                if (isLoading) {
                  return <EuiLoadingSpinner size={'l'} />;
                } else if (isError) {
                  return <p style={{ color: 'red' }}>{'ERROR'}</p>;
                } else {
                  if (typeof description === 'function') {
                    return (
                      <TextErrorBoundary key={'text-boundry-inner'}>
                        <FunctionRenderer renderer={description} />
                      </TextErrorBoundary>
                    );
                  } else {
                    return description;
                  }
                }
              })()}
            </div>
          </EuiText>
        </TextErrorBoundary>
      </EuiFormRow>
    );
  }
}
