import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../../models/types';


export interface GetAccountSettingsVars {
    awsAccountId: String;
}

const GET_ACCOUNT_SETTINGS = gql`
    query GetAccountSettings(
            $awsAccountId: String
        ){
        getAccountSettings(
            awsAccountId: $awsAccountId
        )
    }
`;

export const useGetAccountSettingsQuery = () => {
    return useLazyQuery<AnyObject, GetAccountSettingsVars>(GET_ACCOUNT_SETTINGS, {
        errorPolicy: 'all',
    });
};
