import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks/dist/cjs/interfaces';
import {
  CollectionPreferences,
  DateRangePickerProps,
} from '@amzn/awsui-components-react';

export const columns = [
  {
    id: 'event_metadata',
    header: 'Event Metadata',
    sortingField: 'event_metadata',
    cell: (e) => JSON.stringify(e.event_metadata),
  },
  {
    id: 'client_id',
    header: 'Client Id',
    sortingField: 'client_id',
    cell: (e) => e.client_id,
  },
  {
    id: 'last_updated_timestamp',
    header: 'Last Updated Timestamp',
    sortingField: 'last_updated_timestamp',
    cell: (e) => e.last_updated_timestamp,
  },
  {
    id: 'ttl',
    header: 'Ttl',
    sortingField: 'ttl',
    cell: (e) => e.ttl,
  },
  {
    id: 'creation_timestamp',
    header: 'Creation Timestamp',
    sortingField: 'creation_timestamp',
    cell: (e) => e.creation_timestamp,
  },
  {
    id: 'resource_id',
    header: 'Resource Id',
    sortingField: 'resource_id',
    cell: (e) => e.resource_id,
  },
  {
    id: 'event_source',
    header: 'Event Source',
    sortingField: 'event_source',
    cell: (e) => e.event_source,
  },
  {
    id: 'event_type',
    header: 'Event Type',
    sortingField: 'event_type',
    cell: (e) => e.event_type,
  },
  {
    id: 'identuple',
    header: 'Identuple',
    sortingField: 'identuple',
    cell: (e) => e.identuple,
  },
  {
    id: 'identuple_event_resource_id',
    header: 'Identuple Event Resource Id',
    sortingField: 'identuple_event_resource_id',
    cell: (e) => e.identuple_event_resource_id,
  },
  {
    id: 'event_date',
    header: 'Event Date',
    sortingField: 'event_date',
    cell: (e) => e.event_date,
  },
  // Metadata fields
  // autoscaling metadata fields
  {
    id: 'metadata_start_time',
    header: 'AS Start Time',
    sortingField: 'metadata_start_time',
    cell: (e) => e.metadata_start_time,
  },
  {
    id: 'metadata_status_code',
    header: 'AS Status Code',
    sortingField: 'metadata_status_code',
    cell: (e) => e.metadata_status_code,
  },
  {
    id: 'metadata_cw_event_id',
    header: 'AS CW Event Id',
    sortingField: 'metadata_cw_event_id',
    cell: (e) => e.metadata_cw_event_id,
  },
  {
    id: 'metadata_activity_id',
    header: 'AS Activity Id',
    sortingField: 'metadata_activity_id',
    cell: (e) => e.metadata_activity_id,
  },
  {
    id: 'metadata_end_time',
    header: 'AS End Time',
    sortingField: 'metadata_end_time',
    cell: (e) => e.metadata_end_time,
  },
  {
    id: 'metadata_cause',
    header: 'AS Cause',
    sortingField: 'metadata_cause',
    cell: (e) => e.metadata_cause,
  },
  {
    id: 'metadata_asg_name',
    header: 'AS Asg Name',
    sortingField: 'metadata_asg_name',
    cell: (e) => e.metadata_asg_name,
  },
  {
    id: 'metadata_request_id',
    header: 'AS Request Id',
    sortingField: 'metadata_request_id',
    cell: (e) => e.metadata_request_id,
  },
  // selfhealing metadata fields
  {
    id: 'metadata_event_name',
    header: 'SH Event Name',
    sortingField: 'metadata_event_name',
    cell: (e) => e.metadata_event_name,
  },
  {
    id: 'metadata_execution_id',
    header: 'SH Execution Id',
    sortingField: 'metadata_execution_id',
    cell: (e) => e.metadata_execution_id,
  },
  {
    id: 'metadata_whitelist',
    header: 'SH Whitelist',
    sortingField: 'metadata_whitelist',
    cell: (e) => e.metadata_whitelist,
  },
  {
    id: 'metadata_user',
    header: 'SH User',
    sortingField: 'metadata_user',
    cell: (e) => e.metadata_user,
  },
  {
    id: 'metadata_action_name',
    header: 'SH Action Name',
    sortingField: 'metadata_action_name',
    cell: (e) => e.metadata_action_name,
  },
  {
    id: 'metadata_safe_call',
    header: 'SH Safe Call',
    sortingField: 'metadata_safe_call',
    cell: (e) => e.metadata_safe_call,
  },
  {
    id: 'status',
    header: 'SH Status',
    sortingField: 'status',
    cell: (e) => e.status,
  },
];

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Main distribution properties',
    options: [
      { id: 'event_metadata', label: 'event_metadata' },
      { id: 'client_id', label: 'client_id' },
      { id: 'last_updated_timestamp', label: 'last_updated_timestamp' },
      { id: 'ttl', label: 'ttl' },
      { id: 'creation_timestamp', label: 'creation_timestamp' },
      { id: 'resource_id', label: 'resource_id' },
      { id: 'event_source', label: 'event_source' },
      { id: 'event_type', label: 'event_type' },
      { id: 'identuple', label: 'identuple' },
      {
        id: 'identuple_event_resource_id',
        label: 'identuple_event_resource_id',
      },
      { id: 'event_date', label: 'event_date' },
      { id: 'event_timestamp', label: 'event_timestamp' },
    ],
  },
  {
    label: 'AutoScaling Metadata fields',
    options: [
      { id: 'metadata_start_time', label: 'start_time' },
      { id: 'metadata_status_code', label: 'status_code' },
      { id: 'metadata_cw_event_id', label: 'cw_event_id' },
      { id: 'metadata_activity_id', label: 'activity_id' },
      { id: 'metadata_end_time', label: 'end_time' },
      { id: 'metadata_cause', label: 'cause' },
      { id: 'metadata_asg_name', label: 'asg_name' },
      { id: 'metadata_request_id', label: 'request_id' },
    ],
  },
  {
    label: 'SelfHealing Metadata fields',
    options: [
      { id: 'metadata_event_name', label: 'event_name' },
      { id: 'metadata_execution_id', label: 'execution_id' },
      { id: 'metadata_whitelist', label: 'whitelist' },
      { id: 'metadata_metadata_user', label: 'user' },
      { id: 'metadata_action_name', label: 'action_name' },
      { id: 'metadata_safe_call', label: 'safe_call' },
      { id: 'status', label: 'status' },
    ],
  },
];

export const FILTERING_PROPERTIES: readonly PropertyFilterProperty[] = [
  {
    propertyLabel: 'Event Metadata',
    key: 'event_metadata',
    groupValuesLabel: 'Event Metadata values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Client Id',
    key: 'client_id',
    groupValuesLabel: 'Client Id Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Last Updated Timestamp',
    key: 'last_updated_timestamp',
    groupValuesLabel: 'Last Updated Timestamp values',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'Ttl',
    key: 'ttl',
    groupValuesLabel: 'Ttl values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Creation Timestamp',
    key: 'creation_timestamp',
    groupValuesLabel: 'Creation Timestamp Values',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'Resource Id',
    key: 'resource_id',
    groupValuesLabel: 'Resource Id Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Event Source',
    key: 'event_source',
    groupValuesLabel: 'Event Source Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Event Type',
    key: 'event_type',
    groupValuesLabel: 'Event Type Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Identuple Event Resource Id',
    key: 'identuple_event_resource_id',
    groupValuesLabel: 'Identuple Event Resource Id Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Event Date',
    key: 'event_date',
    groupValuesLabel: 'Event Date Values',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'Event Timestamp',
    key: 'event_timestamp',
    groupValuesLabel: 'Event Timestamp Values',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  // Metadata fields
  {
    propertyLabel: 'AS Start Time',
    key: 'metadata_start_time',
    groupValuesLabel: 'Start Time Values',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'AS Status Code',
    key: 'metadata_status_code',
    groupValuesLabel: 'Status Code Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'AS CW Event Id',
    key: 'metadata_cw_event_id',
    groupValuesLabel: 'CW Event Id Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'AS Identuple',
    key: 'metadata_identuple',
    groupValuesLabel: 'Identuple Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'AS Activity Id',
    key: 'metadata_activity_id',
    groupValuesLabel: 'Activity Id Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'AS End Time',
    key: 'metadata_end_time',
    groupValuesLabel: 'End Time Values',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'AS Cause',
    key: 'metadata_cause',
    groupValuesLabel: 'Cause Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'AS Asg Name',
    key: 'metadata_asg_name',
    groupValuesLabel: 'Asg Name Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'AS Request Id',
    key: 'metadata_request_id',
    groupValuesLabel: 'Request Id Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'SH Event Name',
    key: 'metadata_event_name',
    groupValuesLabel: 'Event Name Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'SH Execution Id',
    key: 'metadata_execution_id',
    groupValuesLabel: 'Execution Id Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'SH Whitelist',
    key: 'metadata_whitelist',
    groupValuesLabel: 'Whitelist Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'SH User',
    key: 'metadata_user',
    groupValuesLabel: 'User Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'SH Action Name',
    key: 'metadata_action_name',
    groupValuesLabel: 'Action Name Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'SH Safe Call',
    key: 'metadata_safe_call',
    groupValuesLabel: 'Safe Call Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'SH Status',
    key: 'status',
    groupValuesLabel: 'Status Values',
    operators: [':', '!:', '=', '!='],
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 Domain Events' },
  { value: 30, label: '30 Domain Events' },
  { value: 50, label: '50 Domain Events' },
  { value: 100, label: '100 Domain Events' },
  { value: 500, label: '500 Domain Events' },
  { value: 1000, label: '1000 Domain Events' },
  { value: 8000, label: '8000 Domain Events' },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 8000,
  visibleContent: [
    'event_timestamp',
    'event_source',
    'event_type',
    'resource_id',
    'status',
  ],
  wraplines: true,
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: 'Page size',
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: 'Wrap lines',
      description: 'Check to see all the text and wrap the lines',
    }}
    visibleContentPreference={{
      title: 'Select visible columns',
      options: visibleContentOptions,
    }}
  />
);

export const getFilterCounterText = (count) =>
  `${count} ${count === 1 ? 'match' : 'matches'}`;

export const getTimzoneOffset = (inputTimezone: string) => {
  let localCurrentDate: any = new Date();
  let inputzoneCurrentDate: any = new Date(
    localCurrentDate.toLocaleString('en-US', { timeZone: inputTimezone })
  );
  console.log(inputzoneCurrentDate);
  let localInputzoneOffsetInMinutes = Math.round(
    (inputzoneCurrentDate - localCurrentDate) / (1000 * 60)
  );
  return Math.round(localInputzoneOffsetInMinutes * 60);
};

export const timezoneOptions = [
  {
    inputDisplay: 'Browser Timezone',
    label: 'Browser Timezone',
    value: 'Browser Timezone',
    code: '',
  },
  {
    inputDisplay: 'UTC',
    label: 'UTC',
    value: 'UTC',
    code: 'UTC',
  },
  {
    inputDisplay: 'US/Pacific (PDT)',
    label: 'US/Pacific (PDT)',
    value: 'US/Pacific',
    code: 'PDT',
  },
  {
    inputDisplay: 'America/Phoenix (MDT)',
    label: 'America/Phoenix (MDT)',
    value: 'America/Phoenix',
    code: 'MDT',
  },
  {
    inputDisplay: 'America/NewYork (EDT)',
    label: 'America/NewYork (EDT)',
    value: 'America/New_York',
    code: 'EDT',
  },
  {
    inputDisplay: 'America/Chicago (CDT)',
    label: 'America/Chicago (CDT)',
    value: 'America/Chicago',
    code: 'CDT',
  },
  {
    inputDisplay: 'Asia/Kolkata (IST)',
    label: 'Asia/Kolkata (IST)',
    value: 'Asia/Kolkata',
    code: 'IST',
  },
  {
    inputDisplay: 'Asia/Shanghai (CST)',
    label: 'Asia/Shanghai (CST)',
    value: 'Asia/Shanghai',
    code: 'CST',
  },
];

export const i18nStrings = {
  todayAriaLabel: 'Today',
  nextMonthAriaLabel: 'Next month',
  previousMonthAriaLabel: 'Previous month',
  customRelativeRangeDurationLabel: 'Duration',
  customRelativeRangeDurationPlaceholder: 'Enter duration',
  customRelativeRangeOptionLabel: 'Custom range',
  customRelativeRangeOptionDescription: 'Set a custom range in the past',
  customRelativeRangeUnitLabel: 'Unit of time',
  formatRelativeRange: (e) => {
    const timeDomainEvents = 1 === e.amount ? e.unit : e.unit + 's';
    return `Last ${e.amount} ${timeDomainEvents}`;
  },
  formatUnit: (e, timeDomainEvents) => (1 === timeDomainEvents ? e : e + 's'),
  dateTimeConstraintText:
    'Range must be between 6 - 30 days. Use 24 hour format.',
  relativeModeTitle: 'Relative range',
  absoluteModeTitle: 'Absolute range',
  relativeRangeSelectionHeading: 'Choose a range',
  startDateLabel: 'Start date',
  endDateLabel: 'End date',
  startTimeLabel: 'Start time',
  endTimeLabel: 'End time',
  clearButtonLabel: 'Clear',
  cancelButtonLabel: 'Cancel',
  applyButtonLabel: 'Apply',
};

export const relativeOptions: ReadonlyArray<DateRangePickerProps.RelativeOption> =
  [
    {
      key: 'previous-5-minutes',
      amount: 5,
      unit: 'minute',
      type: 'relative',
    },
    {
      key: 'previous-30-minutes',
      amount: 30,
      unit: 'minute',
      type: 'relative',
    },
    {
      key: 'previous-1-hour',
      amount: 1,
      unit: 'hour',
      type: 'relative',
    },
    {
      key: 'previous-6-hours',
      amount: 6,
      unit: 'hour',
      type: 'relative',
    },
  ];
