import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Button,
  Header,
  Pagination,
  PropertyFilter,
  Table,
  Container
} from '@amzn/awsui-components-react';
import { get } from 'lodash';
import { useState, useEffect } from 'react';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from '../../../utils/constants';
import {
  EmptyState,
  getFilterCounterText,
  Preferences,
} from '../../../utils/tablePreferences';
import {
  columnDefinitions,
  DEFAULT_PREFERENCES,
  FILTERING_PROPERTIES,
  PAGE_SIZE_OPTIONS,
  VISIBLE_CONTENT_OPTIONS,
} from './constants';
import { useGetTicketsQuery } from './useGetTicketsQuery';
import { isError } from "../../../ErrorHandler/apiErrorHandler";
import { ErrorMessageComponent } from "../../../ErrorHandler/errorMessageComponent";
import { ApiList }from "../../../ErrorHandler/utils";

interface TicketsProps {
  domainIdentifier: string;
}

const Tickets = (props: TicketsProps) => {
  const { loading, data, error } = useGetTicketsQuery({
    domainId: props.domainIdentifier,
  });

  const [errorMessage, setErrorMessage] = useState(null)
  const isErrorPresent = isError(error)
  useEffect(() => {
    if (isErrorPresent) {
      setErrorMessage("Unable to get Tickets. Failed with " + error.message);
    }
  }, [isErrorPresent, error]);

  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);

  const tickets = get(data, 'getTickets', []);

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(loading ? [] : tickets, {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES,
      empty: (
        <EmptyState
          title="No Tickets"
          subtitle="No tickets to display."
          action={<span></span>}
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can’t find a match."
          action={
            <Button onClick={() => actions.setFiltering('')}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {
      defaultState: {
        sortingColumn: { sortingField: 'create_date' },
        isDescending: true,
      },
    },
    selection: {},
  });

  return (
      <>
      {errorMessage ? (
        <Container header={<Header variant="h2">Tickets</Header>}>
        <ErrorMessageComponent errorMessage={errorMessage} apiName={ApiList.TICKETS}/>
        </Container>
       ) : (
    <Table
      {...collectionProps}
      header={<Header counter={`(${items.length})`}>Tickets</Header>}
      columnDefinitions={columnDefinitions}
      stickyHeader={true}
      resizableColumns={false}
      wrapLines={preferences.wraplines}
      loading={loading}
      loadingText={loading ? 'Loading Tickets...' : 'There are no Tickets.'}
      visibleColumns={preferences.visibleContent}
      items={items}
      pagination={
        <Pagination
          {...paginationProps}
          ariaLabels={{
            nextPageLabel: 'Next page',
            previousPageLabel: 'Previous page',
            pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
          }}
        />
      }
      filter={
        <div className="input-container">
          <PropertyFilter
            className="input-filter"
            i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
            {...propertyFilterProps}
            countText={getFilterCounterText(filteredItemsCount)}
          />
        </div>
      }
      preferences={
        <Preferences
          preferences={preferences}
          setPreferences={setPreferences}
          disabled={false}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          visibleContentOptions={VISIBLE_CONTENT_OPTIONS}
        />
      }
    />
    )}
  </>
  );
};

export { Tickets };
