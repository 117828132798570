import React from 'react';
import { CodeViewer } from '../../../../Components/Common/CodeViewer';

interface ClusterAllocationExplainProps {
  data: object;
}

const ClusterAllocationExplain = (props: ClusterAllocationExplainProps) => {
  return (
    <CodeViewer
      codeData={JSON.stringify(props.data, null, 2)}
      language={{ label: 'json', value: 'json' }}
      theme={{ label: 'coy', value: 'coy' }}
    />
  );
};

export { ClusterAllocationExplain };
