import {
  Button,
  FormField,
  Grid,
  Multiselect,
  SelectProps,
} from '@amzn/awsui-components-react';
import React, { useCallback, useEffect, useState } from 'react';
import { CloudWatch, CloudwatchWidget } from '../../../Domain/Cloudwatch/CloudWatch';
import './../../../Styles/cloudwatch-dasboard.css';
import { PROCESS_METRICS } from './constants';
import { getProcessMetrics } from './utils';

const statsOptions: SelectProps.Options = [
  { label: 'Maximum', value: 'Maximum' },
  { label: 'Minimum', value: 'Minimum' },
  { label: 'Average', value: 'Average' },
  { label: 'Sum', value: 'Sum' },
];

interface ProcessMetricsForm {
  selectedMetrics: SelectProps.Options;
  nodeIds: SelectProps.Options;
  stats: SelectProps.Options;
  isDirty: boolean;
}

interface  ProcessMetricsProps {
  instancesIds: string[];
  isOptInRegion: boolean;
  region: string;
  clientId: string;
  domainName: string;
  rootAccountId: string;
  // metricRange: any;
  initialValues?:  ProcessMetricsForm;
  // utcOffset: number;
  esVersion: string;
}

const ProcessMetrics = (props: ProcessMetricsProps) => {
  const instanceIdOptions = props.instancesIds.map((nodeId) => ({ label: nodeId, value: nodeId }));
  
  var metricOptions = [...PROCESS_METRICS.ES, ...PROCESS_METRICS.Common];
  if(props.esVersion === null || props.esVersion === undefined) {
    metricOptions = [...PROCESS_METRICS.ES, ...PROCESS_METRICS.Common];
  } else if(props.esVersion.startsWith("OS")) {
    metricOptions = [...PROCESS_METRICS.OS, ...PROCESS_METRICS.Common];
  }

  const initialValues = props.initialValues
    ? props.initialValues
    : {
      selectedMetrics: [...PROCESS_METRICS.OS, ...PROCESS_METRICS.Common],
      nodeIds: [],
      stats: [{ label: 'Maximum', value: 'Maximum' }],
      splitByNodeId: false,
      isDirty: false,
    };
  const [formValues, setFormValues] = useState<ProcessMetricsForm>(initialValues);

  const [widgets, setWidgets] = useState<CloudwatchWidget[]>([]);
  const isFormInvalid = () => {
    return (
      formValues.stats.length === 0 ||
      formValues.selectedMetrics.length === 0
    );
  };

  const handleSubmit = useCallback(() => {
    setFormValues((prevFormValues: ProcessMetricsForm) => ({
      ...prevFormValues,
      isDirty: false,
    }));
    const stats = formValues.stats
      ? formValues.stats.map((stat: SelectProps.Option) =>
        stat ? stat.label : ''
      )
      : ['Average'];

    const metricNames = formValues.selectedMetrics ? formValues.selectedMetrics: [];
    const instancesIds = formValues.nodeIds && formValues.nodeIds.length > 0 ? formValues.nodeIds.map((nodeId: SelectProps.Option) => {
      return (nodeId ? nodeId.label : '')!
    }) : props.instancesIds;
    const updatedWidgets = getProcessMetrics(
      props.domainName,
      props.clientId,
      instancesIds,
      metricNames,
      stats,
      props.rootAccountId,
      props.region,
      true
    ) as CloudwatchWidget[]
    setWidgets(updatedWidgets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  // When it is initial autosubmit
  useEffect(() => {
    if (props.initialValues) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialValues]);

  return (
    <React.Fragment>
      <Grid
        className="dasboard-explorer"
        gridDefinition={[
          { colspan: 3 },
          { colspan: 3 },
          { colspan: 3 },
          { colspan: 1 },
        ]}
      >
        <FormField description="Choose metrics from the list." label="Metric">
          <Multiselect
            selectedOptions={formValues.selectedMetrics}
            deselectAriaLabel={(e) => 'Remove ' + e.label}
            options={metricOptions}
            placeholder="Choose options"
            selectedAriaLabel="Selected"
            filteringType="auto"
            onChange={({ detail }) =>
              setFormValues((prevFormValues: ProcessMetricsForm) => ({
                ...prevFormValues,
                selectedMetrics: detail.selectedOptions,
                isDirty: true,
              }))
            }
          />
        </FormField>
        <FormField description="Choose stats for the metric." label="Stats">
          <Multiselect
            selectedOptions={formValues.stats}
            deselectAriaLabel={(e) => 'Remove ' + e.label}
            options={statsOptions}
            placeholder="Choose options"
            selectedAriaLabel="Selected"
            onChange={({ detail }) =>
              setFormValues((prevFormValues: ProcessMetricsForm) => ({
                ...prevFormValues,
                stats: detail.selectedOptions,
                isDirty: true,
              }))
            }
          />
        </FormField>
        <FormField
          description="Choose Instance IDs to look for metrics."
          label="Instance ID"
        >
          <Multiselect
            disabled={instanceIdOptions.length === 0}
            invalid={formValues.stats.length === 0}
            selectedOptions={formValues.nodeIds}
            deselectAriaLabel={(e) => 'Remove ' + e.label}
            options={instanceIdOptions}
            placeholder="Choose options"
            selectedAriaLabel="Selected"
            filteringType="auto"
            onChange={({ detail }) =>
              setFormValues((prevFormValues: ProcessMetricsForm) => ({
                ...prevFormValues,
                nodeIds: detail.selectedOptions,
                isDirty: true,
              }))
            }
          />
        </FormField>
        <Button
          className="explorer-button-checkbox"
          variant="primary"
          disabled={isFormInvalid()}
          onClick={handleSubmit}
        >
          Fetch
        </Button>
      </Grid>
      {widgets && widgets.length > 0 ? (
        <CloudWatch
          region={props.region}
          rootAccountId={props.rootAccountId}
          isOptInRegion={props.isOptInRegion}
          widgets={widgets}
        />
      ) : null}
    </React.Fragment>
  );
};

export { ProcessMetrics };
