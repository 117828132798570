import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks/dist/cjs/interfaces';
import {
    CollectionPreferences,
    DateRangePickerProps,
} from '@amzn/awsui-components-react';

export const columns = [
    {
        id: 'ActionId',
        header: 'Action Id',
        cell: (e) => (e['ActionId'] ? e['ActionId'] : ''),
        sortingField: 'ActionId',
    },
    {
        id: 'action',
        header: 'Action',
        cell: (e) => (e['action'] ? e['action'] : ''),
        sortingField: 'action',
    },
    {
        id: 'status',
            header: 'Status',
        cell: (e) => (e['status'] ? e['status'] : ''),
        sortingField: 'status',
    },
    {
        id: 'explanation',
            header: 'Explanation',
        cell: (e) =>
        e['explanation']
            ? Object.keys(e['explanation']).length === 0
                ? ''
                : e['explanation']
            : '',
        sortingField: 'explanation',
    },
];

const scalingAuditHistoryVisibleContent = [
    'ActionId',
    'action',
    'status',
    'explanation',
];

export const VISIBLE_CONTENT_OPTIONS = [
    {
        label: 'Options',
        options: scalingAuditHistoryVisibleContent.map((c) => {
            return { id: c, label: c };
        }),
    },
];

export const FILTERING_PROPERTIES: any =
    scalingAuditHistoryVisibleContent.map((c) => {
        return {
            propertyLabel: c,
            key: c,
            groupValuesLabel: `${c} Values`,
            operators:
               c === 'ActionId'
                    ? [':', '!:', '=', '!=', '>', '>=', '<', '<=']
                    : [':', '!:', '=', '!='],
        };
    });

export const PAGE_SIZE_OPTIONS = [
    { value: 10, label: '10 Scaling Actions' },
    { value: 30, label: '30 Scaling Actions' },
    { value: 50, label: '50 Scaling Actions' },
    { value: 100, label: '100 Scaling Actions' },
    { value: 500, label: '500 Scaling Actions' },
    { value: 1000, label: '1000 Scaling Actions' },
    { value: 8000, label: '8000 Scaling Actions' },
];

export const DEFAULT_PREFERENCES = {
    pageSize: 10,
    visibleContent: scalingAuditHistoryVisibleContent,
    wraplines: true,
};

export const Preferences = ({
                                preferences,
                                setPreferences,
                                disabled,
                                pageSizeOptions = PAGE_SIZE_OPTIONS,
                                visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
                            }) => (
    <CollectionPreferences
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        disabled={disabled}
        preferences={preferences}
        onConfirm={({ detail }) => setPreferences(detail)}
        pageSizePreference={{
            title: 'Page size',
            options: pageSizeOptions,
        }}
        wrapLinesPreference={{
            label: 'Wrap lines',
            description: 'Check to see all the text and wrap the lines',
        }}
        visibleContentPreference={{
            title: 'Select visible columns',
            options: visibleContentOptions,
        }}
    />
);

export const getFilterCounterText = (count) =>
    `${count} ${count === 1 ? 'match' : 'matches'}`;

export const getTimzoneOffset = (inputTimezone: string) => {
    let localCurrentDate: any = new Date();
    let inputzoneCurrentDate: any = new Date(
        localCurrentDate.toLocaleString('en-US', { timeZone: inputTimezone })
    );
    console.log(inputzoneCurrentDate);
    let localInputzoneOffsetInMinutes = Math.round(
        (inputzoneCurrentDate - localCurrentDate) / (1000 * 60)
    );
    return Math.round(localInputzoneOffsetInMinutes * 60);
};

export const timezoneOptions = [
    {
        inputDisplay: 'Browser Timezone',
        label: 'Browser Timezone',
        value: 'Browser Timezone',
        code: '',
    },
    {
        inputDisplay: 'UTC',
        label: 'UTC',
        value: 'UTC',
        code: 'UTC',
    },
    {
        inputDisplay: 'US/Pacific (PDT)',
        label: 'US/Pacific (PDT)',
        value: 'US/Pacific',
        code: 'PDT',
    },
    {
        inputDisplay: 'America/Phoenix (MDT)',
        label: 'America/Phoenix (MDT)',
        value: 'America/Phoenix',
        code: 'MDT',
    },
    {
        inputDisplay: 'America/NewYork (EDT)',
        label: 'America/NewYork (EDT)',
        value: 'America/New_York',
        code: 'EDT',
    },
    {
        inputDisplay: 'America/Chicago (CDT)',
        label: 'America/Chicago (CDT)',
        value: 'America/Chicago',
        code: 'CDT',
    },
    {
        inputDisplay: 'Asia/Kolkata (IST)',
        label: 'Asia/Kolkata (IST)',
        value: 'Asia/Kolkata',
        code: 'IST',
    },
    {
        inputDisplay: 'Asia/Shanghai (CST)',
        label: 'Asia/Shanghai (CST)',
        value: 'Asia/Shanghai',
        code: 'CST',
    },
];

export const i18nStrings = {
    todayAriaLabel: 'Today',
    nextMonthAriaLabel: 'Next month',
    previousMonthAriaLabel: 'Previous month',
    customRelativeRangeDurationLabel: 'Duration',
    customRelativeRangeDurationPlaceholder: 'Enter duration',
    customRelativeRangeOptionLabel: 'Custom range',
    customRelativeRangeOptionDescription: 'Set a custom range in the past',
    customRelativeRangeUnitLabel: 'Unit of time',
    formatRelativeRange: (e) => {
        const t = 1 === e.amount ? e.unit : e.unit + 's';
        return `Last ${e.amount} ${t}`;
    },
    formatUnit: (e, t) => (1 === t ? e : e + 's'),
    dateTimeConstraintText:
        'Range must be between 0 - 24 hours. Use 24 hour format.',
    relativeModeTitle: 'Relative range',
    absoluteModeTitle: 'Absolute range',
    relativeRangeSelectionHeading: 'Choose a range',
    startDateLabel: 'Start date',
    endDateLabel: 'End date',
    startTimeLabel: 'Start time',
    endTimeLabel: 'End time',
    clearButtonLabel: 'Clear',
    cancelButtonLabel: 'Cancel',
    applyButtonLabel: 'Apply',
};

export const relativeOptions: ReadonlyArray<DateRangePickerProps.RelativeOption> =
    [
        {
            key: 'previous-5-minutes',
            amount: 5,
            unit: 'minute',
            type: 'relative',
        },
        {
            key: 'previous-30-minutes',
            amount: 30,
            unit: 'minute',
            type: 'relative',
        },
        {
            key: 'previous-1-hour',
            amount: 1,
            unit: 'hour',
            type: 'relative',
        },
        {
            key: 'previous-6-hours',
            amount: 6,
            unit: 'hour',
            type: 'relative',
        },
    ];
