import { gql, useQuery, useLazyQuery } from '@apollo/client';
import {AnyObject} from "../../../../models/types";

export interface CWMetricsVars {
  namespace: string;
  metric_name: string;
  dimensions: string;
  startTime: Date;
  endTime: Date;
  period: number;
  statistics: string;
}

export interface CWMetricsData {
  cwMetrics: {
    timeStamps: Date[];
    externalMetrics: {
      values: number[];

      label: string;
    };
  };
}
const GQL_CW_METRICS = gql`
  query getExternalCwMetrics(
    $namespace: String!
    $metric_name: String!
    $dimensions: String!
    $startTime: Date!
    $endTime: Date!
    $period: Int!
    $statistics: String!
  ) {
    getJunoCwMetrics(
      namespace: $namespace
      metricName: $metric_name
      dimensions: $dimensions
      startTime: $startTime
      endTime: $endTime
      period: $period
      statistics: $statistics
    )
  }
`;

export const useGetExternalCwQuery = (vars: CWMetricsVars) => {
  return useQuery<AnyObject, CWMetricsVars>(GQL_CW_METRICS, {
    variables: { ...vars },
  });
};

export const useGetLazyExternalCwQuery = () => {
  return useLazyQuery<AnyObject, CWMetricsVars>(GQL_CW_METRICS, {
    errorPolicy: 'all',
  });
};
