import { Box, Header, Spinner } from '@amzn/awsui-components-react';
import Container from '@amzn/awsui-components-react/polaris/container';
import { get } from 'lodash';
import { useSopQuery } from './hooks/useSopQuery';

const SearchResult = (props) => {
  const { loading, data } = useSopQuery({ query: props.query });
  const sopdata = get(data, 'getSopData', []);

  function OtherState({ title, subtitle }) {
    return (
      <Box textAlign="center" color="inherit">
        <Box variant="strong" textAlign="center" color="inherit">
          {title}
        </Box>
        <Box variant="p" padding={{ bottom: 's' }} color="inherit">
          {subtitle}
        </Box>
      </Box>
    );
  }
  return (
    <>
      {loading && <Spinner size="large" />}
      {!loading &&
        sopdata.length !== 0 &&
        sopdata.map((s) => (
          <Container
            header={
              <Header variant="h3">
                <a href={s.Link} target="_blank" rel="noopener noreferrer">
                  {s.Link}
                </a>
              </Header>
            }
            key={s.id}
          >
            <div
              style={{ overflow: 'scroll' }}
              dangerouslySetInnerHTML={{ __html: s.Content }}
            ></div>
          </Container>
        ))}
      {!loading && sopdata.length === 0 && (
        <OtherState title="No matches" subtitle="No SOP Links Found" />
      )}
    </>
  );
};

export { SearchResult };
