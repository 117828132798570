import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Button,
  Flashbar,
  Header,
  Pagination,
  PropertyFilter,
  SpaceBetween,
  Table,
} from '@amzn/awsui-components-react';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from '../../../utils/constants';
import { ExportCSV } from '../../../utils/exportCSV';
import { InfoLink } from '../Common/InfoLink';
import {
  EmptyState,
  getFilterCounterText,
  Preferences,
} from './../../../utils/tablePreferences';
import {
  DEFAULT_PREFERENCES,
  FILTERING_PROPERTIES,
  otsTableColumns,
  PAGE_SIZE_OPTIONS,
  VISIBLE_CONTENT_OPTIONS,
} from './columns';
import { useOtsAllQuery } from './otsAllQuery';

interface OnboardedProps {
  updateTools: () => void;
  // eslint-disable-next-line
  updatePanel: ([]) => void;
}

const Onboarded = (props: OnboardedProps) => {
  const { loading, data } = useOtsAllQuery();
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const otsAll = get(data, 'otsAll.otsItems', []);
  const [message, setMessage] = useState([]);
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      setMessage([
        {
          type: 'success',
          content: location.state['detail'],
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setMessage([]),
        },
      ]);
    }
  }, [location]);

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(otsAll, {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES,
      empty: (
        <EmptyState
          title="No Accounts"
          subtitle="No Accounts to display."
          action=""
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can’t find a match."
          action={
            <Button onClick={() => actions.setFiltering('')}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
  });
  return (
    <SpaceBetween direction="vertical" size="l">
      <Flashbar items={message} />

      <Table
        {...collectionProps}
        header={
          <div>
            <Header
              counter={`(${items.length})`}
              info={
                props.updateTools && <InfoLink onFollow={props.updateTools} />
              }
              actions={
                <SpaceBetween direction="horizontal" size="s">
                  <ExportCSV
                    data={items}
                    filename={'OTS_all_onboarded.csv'}
                    loading={loading}
                  />
                </SpaceBetween>
              }
            >
              OTS accounts
            </Header>
            <p>A list of all onboarded accounts across all the regions.</p>
          </div>
        }
        columnDefinitions={otsTableColumns}
        stickyHeader={true}
        resizableColumns={false}
        wrapLines={preferences.wraplines}
        loading={loading}
        loadingText={loading ? 'Loading OTS Data' : 'No OTS domains found'}
        visibleColumns={preferences.visibleContent}
        items={items}
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
          />
        }
        filter={
          <PropertyFilter
            className="input-filter"
            i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
            {...propertyFilterProps}
            countText={getFilterCounterText(filteredItemsCount)}
          />
        }
        preferences={
          <Preferences
            preferences={preferences}
            setPreferences={setPreferences}
            disabled={false}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            visibleContentOptions={VISIBLE_CONTENT_OPTIONS}
          />
        }
      />
    </SpaceBetween>
  );
};

export { Onboarded };
