import React from 'react';
import { TimezoneToggle } from '../../../Components/TimezoneToggle';

export enum LaunchType {
  Template,
  Config
}

export function columns(launchType: LaunchType) {
  const launchTypeStr = launchType == LaunchType.Template ? "template" : "configuration";
  return [
    {
      id: 'launchConfigurationName',
      sortingField: 'launchConfigurationName',
      name: `Launch ${launchTypeStr} name`,
      header: `Launch ${launchTypeStr} name`,
      sortable: true,
      textAlign: 'center',
      cell: (e) => e.launchConfigurationName,
    },
    {
      id: 'nodeType',
      sortingField: 'nodeType',
      name: 'Node type',
      header: 'Node type',
      sortable: false,
      cell: (e) => e.nodeType,
    },
    {
      id: 'instanceType',
      sortingField: 'instanceType',
      name: 'Instance type',
      header: 'Instance type',
      cell: (e) => e.instanceType,
    },
    {
      id: 'keyName',
      sortingField: 'keyName',
      name: 'Key name',
      header: 'Key name',
      cell: (e) => e.keyName,
    },
    {
      id: 'dateTime',
      sortingField: 'dateTime',
      name: 'Date time',
      header: 'Date time',
      cell: (e) => <TimezoneToggle date={new Date(e.dateTime)} />,
    },
  ];
}

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Options',
    options: [
      {
        id: 'launchConfigurationName',
        label: 'Launch Configuration Name',
      },
      { id: 'nodeType', label: 'Node Type' },
      { id: 'instanceType', label: 'Instance Type' },
      { id: 'keyName', label: 'Key Name' },
      { id: 'dateTime', label: 'DateTime' },
      { id: 'imageId', label: 'Image Id' },
    ],
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10' },
  { value: 30, label: '30' },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 10,
  visibleContent: [
    'launchConfigurationName',
    'nodeType',
    'instanceType',
    'keyName',
    'dateTime',
    'imageId',
  ],
  wraplines: true,
};
