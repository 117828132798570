import { gql, useMutation } from '@apollo/client';
import { AnyObject } from '../models/types';

interface ExecuteApiVars {
  url: string;
  domainIdentifier: string;
  queryParams: string;
  instanceId: string;
}

export const EXECUTE_GET_API = gql`
  mutation ExecuteGetApi(
    $url: String!
    $domainIdentifier: String
    $queryParams: String
    $instanceId: String
  ) {
    executeGetAPI(
      url: $url
      domainIdentifier: $domainIdentifier
      queryParams: $queryParams
      instanceId: $instanceId
    )
  }
`;

export const useExecuteApiMutation = () => {
  return useMutation<AnyObject, ExecuteApiVars>(EXECUTE_GET_API);
};
