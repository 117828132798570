export const languages = [
  { label: 'neon', value: 'neon' },
  { label: 'javascript', value: 'javascript' },
  { label: 'json', value: 'json' },
  { label: 'powershell', value: 'powershell' },
];

export const themes = [
  { label: 'coy', value: 'coy' },
  { label: 'prism', value: 'prism', disabled: true },
  { label: 'duotoneLight', value: 'duotoneLight', disabled: true },
  { label: 'dracula', value: 'dracula', disabled: true },
];
