import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Button,
  DateRangePicker,
  DateRangePickerProps,
  Header,
  Pagination,
  PropertyFilter,
  Select,
  SpaceBetween,
  Table,
  TextContent,
} from '@amzn/awsui-components-react';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CommonDomainProps } from '../../models/types';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from '../../utils/constants';
import { EmptyState } from '../../utils/tablePreferences';
import { useDomainEventsQuery } from './domainEventsAllQuery';
import {
  columns,
  DEFAULT_PREFERENCES,
  FILTERING_PROPERTIES,
  getFilterCounterText,
  i18nStrings,
  PAGE_SIZE_OPTIONS,
  Preferences,
  relativeOptions,
  timezoneOptions,
  VISIBLE_CONTENT_OPTIONS,
} from './utils';
import { isError } from "../../ErrorHandler/apiErrorHandler";
import { ErrorMessageComponent } from "../../ErrorHandler/errorMessageComponent";
import { ApiList } from "../../ErrorHandler/utils";

const DomainEvents = (props: CommonDomainProps) => {
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const [currentDate, setCurrentDate] = React.useState<any>({
    type: 'relative',
    amount: 1,
    unit: 'week',
  });

  const [selectedTimeZoneOption, setSelectedTimeZoneOption] = React.useState({
    inputDisplay: 'UTC',
    label: 'UTC',
    value: 'UTC',
    code: 'UTC',
  });

  const domainId = props.domainIdentifier;

  var [getDomainEvents, { loading, data: domainEvents, error: error }] =
    useDomainEventsQuery();

  const [errorMessage, setErrorMessage] = useState(null)
  const isErrorPresent = isError(error)
  useEffect(() => {
    if (isErrorPresent) {
      setErrorMessage("Unable to get Domain Events. Failed with " + error.message);
    }
  }, [isErrorPresent, error]);

  useEffect(() => {
    getDomainEvents({
      variables: {
        domainId: domainId,
        startTime: '7 days ago',
        endTime: 'now',
      },
    });
  }, [domainId, getDomainEvents]);

  const convertTZ = (timestamp, tzOption) => {
    const tz = tzOption.value;
    timestamp = parseInt(timestamp);
    if (tz === 'Browser Timezone') {
      return new Date(timestamp).toLocaleString('en-US', {
        hour12: false,
        timeZoneName: 'short',
      });
    }
    return (
      new Date(timestamp).toLocaleString('en-US', {
        timeZone: tz,
        hour12: false,
      }) +
      ' ' +
      tzOption.code
    );
  };

  const handleTimezoneChange = (selectedOption) => {
    setSelectedTimeZoneOption(selectedOption);
  };

  const handleDateChange = ({ detail }) => {
    var startMoment, endMoment;

    if ('absolute' === detail.value.type) {
      startMoment = moment(detail.value.startDate);
      endMoment = moment(detail.value.endDate);
    } else if ('relative' === detail.value.type) {
      startMoment = moment().subtract(detail.value.amount, detail.value.unit);
      endMoment = moment();
    }

    getDomainEvents({
      variables: {
        domainId: domainId,
        startTime: startMoment.toISOString(),
        endTime: endMoment.toISOString(),
      },
    });

    setCurrentDate(detail.value);
  };

  const isValidRange: DateRangePickerProps.ValidationFunction = (e) => {
    const timeDomainEvents = (start, end) => {
      const a = moment(end).isBefore(moment(start));
      return a;
    };
    if ('absolute' === e.type) {
      const [a] = e.startDate.split('T'),
        [n] = e.endDate.split('T');
      if (!a || !n)
        return {
          valid: !1,
          errorMessage:
            'The selected date range is incomplete. Select a start and end date for the date range.',
        };
      if (
        moment(e.startDate).isAfter(moment()) ||
        moment(e.endDate).isAfter(moment())
      )
        return {
          valid: !1,
          errorMessage:
            'Either of start date or end date cannot be future date.',
        };
      if (timeDomainEvents(e.startDate, e.endDate))
        return {
          valid: !1,
          errorMessage: 'The end date must be greater than the start date.',
        };
    } else if ('relative' === e.type) {
      if (isNaN(e.amount))
        return {
          valid: !1,
          errorMessage:
            'The selected date range is incomplete. Specify a duration for the date range.',
        };
    }
    return { valid: !0 };
  };

  // console.log(domainEvents)
  const events = get(domainEvents, 'domainEvents.events', []);

  // console.log(events[events.length-1])
  let showLimitMessage = false;
  if (events && events.length > 0) {
    showLimitMessage = events[events.length - 1]['resultTruncated']
      ? true
      : false;
  }

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(loading ? [] : events, {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES,
      empty: (
        <EmptyState
          title="No Domain Events"
          subtitle="No Domain Events to display."
          action={<span></span>}
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can’t find a match."
          action={
            <Button onClick={() => actions.setFiltering('')}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
  });

  return (
    <>
      {errorMessage ? (
      <ErrorMessageComponent errorMessage={errorMessage} apiName={ApiList.DOMAIN_EVENTS}/>
    ) : (
      <div>
        {/* <Container>
          Below is the table containing domain events fetched from DDB.
        </Container> */}
        <div>
          <Table
            {...collectionProps}
            header={
              <div>
                <Header
                  counter={`(${items.length})`}
                  actions={
                    <SpaceBetween direction="horizontal" size="s">
                      <DateRangePicker
                        onChange={handleDateChange}
                        value={currentDate}
                        relativeOptions={relativeOptions}
                        isValidRange={isValidRange}
                        i18nStrings={i18nStrings}
                        placeholder="Filter by a date and time range"
                      />
                      <TextContent>
                        <strong>Timezone :</strong>
                      </TextContent>
                      <Select
                        selectedOption={selectedTimeZoneOption}
                        onChange={({ detail }) =>
                          handleTimezoneChange(detail.selectedOption)
                        }
                        options={timezoneOptions}
                        selectedAriaLabel="Selected"
                      />
                    </SpaceBetween>
                  }
                >
                  Domain Events
                </Header>
                {showLimitMessage && (
                  <p>
                    There are more than {events.length - 1} Domain events and we
                    are displaying the latest {events.length - 1} events
                  </p>
                )}
              </div>
            }
            columnDefinitions={[
              {
                id: 'event_timestamp',
                header: 'Event Timestamp',
                sortingField: 'event_timestamp',
                cell: (e) => {
                  var timestamp = e.event_timestamp;
                  return convertTZ(timestamp, selectedTimeZoneOption);
                },
              },
              ...columns,
            ]}
            stickyHeader={true}
            resizableColumns={true}
            wrapLines={preferences.wraplines}
            loading={loading}
            loadingText={
              loading
                ? 'Loading domain events...'
                : 'There were no domain events.'
            }
            visibleColumns={preferences.visibleContent}
            items={items}
            pagination={
              <Pagination
                {...paginationProps}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
                }}
              />
            }
            filter={
              <div className="input-container">
                <PropertyFilter
                  className="input-filter"
                  i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                  {...propertyFilterProps}
                  countText={getFilterCounterText(filteredItemsCount)}
                />
              </div>
            }
            preferences={
              <Preferences
                preferences={preferences}
                setPreferences={setPreferences}
                disabled={false}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                visibleContentOptions={VISIBLE_CONTENT_OPTIONS}
              />
            }
          />
        </div>
      </div>
      )}
    </>
  );
};

export { DomainEvents };
