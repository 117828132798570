import { CollectionPreferences } from '@amzn/awsui-components-react';
import React from 'react';
import { TimezoneToggle } from '../../Components/TimezoneToggle';
import { UpgradeStep } from './model';

export const columns = [
  {
    id: 'name',
    field: 'name',
    name: 'Name',
    header: 'Name',
    sortingField: 'end_epoch',
    cell: (e) => e.name,
  },
  {
    id: 'status',
    field: 'status',
    name: 'Status',
    header: 'Status',
    sortingField: 'end_epoch',
    cell: (e) => e.status,
  },
  {
    id: 'startTime',
    field: 'startTime',
    dataType: 'date',
    name: 'Start time',
    header: 'Start time',
    sortingField: 'end_epoch',
    cell: (e) => e.startTime,
    render: (startTime: Date) => {
      return <TimezoneToggle date={new Date(startTime)} />;
    },
  },
  {
    id: 'steps',
    field: 'steps',
    name: 'Steps',
    header: 'Steps',
    sortingField: 'end_epoch',
    cell: (e) => e.steps,
    render: (steps: UpgradeStep[]) => (
      <div style={{ display: 'block', whiteSpace: 'pre-line' }}>{steps}</div>
    ),
  },
  {
    id: 'stepsStatus',
    field: 'stepsStatus',
    name: 'Status',
    header: 'Status',
    sortingField: 'end_epoch',
    cell: (e) => e.stepsStatus,
    render: (stepsStatus: string) => (
      <div style={{ display: 'block', whiteSpace: 'pre-line' }}>
        {stepsStatus}
      </div>
    ),
  },
  {
    id: 'stepsProgress',
    field: 'stepsProgress',
    name: 'Progress',
    header: 'Progress',
    sortingField: 'end_epoch',
    cell: (e) => e.stepsProgress,
    render: (stepsProgress: string) => (
      <div style={{ display: 'block', whiteSpace: 'pre-line' }}>
        {stepsProgress}
      </div>
    ),
  },
];

const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Main distribution properties',
    options: [
      {
        id: 'name',
        label: 'name',
      },
      { id: 'status', label: 'status' },
      { id: 'startTime', label: 'startTime' },
      {
        id: 'steps',
        label: 'steps',
      },
      {
        id: 'stepsStatus',
        label: 'stepsStatus',
      },
      {
        id: 'stepsProgress',
        label: 'stepsProgress',
      },
    ],
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 Upgrades' },
  { value: 30, label: '30 Upgrades' },
  { value: 50, label: '50 Upgrades' },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 10,
  visibleContent: [
    'name',
    'status',
    'startTime',
    'steps',
    'stepsStatus',
    'stepsProgress',
    // 'successful_shards',
    // 'failed_shards',
    // 'total_shards',
  ],
  wraplines: true,
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: 'Page size',
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: 'Wrap lines',
      description: 'Check to see all the text and wrap the lines',
    }}
    visibleContentPreference={{
      title: 'Select visible columns',
      options: visibleContentOptions,
    }}
  />
);

export const getFilterCounterText = (count) =>
  `${count} ${count === 1 ? 'match' : 'matches'}`;
