import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../models/types';

interface useGetFilteredDataVars {
    data: string;
    fieldSize: string;
    endTime: string;
    startTime: string;
    eventType: string;
    domainId: string;
}

export const GET_FILTERED_DATA = gql`
  query GetFilteredData(
    $data: String
    $fieldSize: String
    $endTime: String
    $startTime: String
    $eventType: String
    $domainId: String
  ) {
    getFilteredData(
      data: $data
      fieldSize: $fieldSize
      endTime: $endTime
      startTime: $startTime
      eventType: $eventType
      domainId: $domainId
    )
  }
`;

export const useGetFilteredDataQuery = () => {
    return useLazyQuery<AnyObject, useGetFilteredDataVars>(GET_FILTERED_DATA,
        {
            fetchPolicy: 'no-cache'
        });
};