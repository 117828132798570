import { gql, useMutation } from '@apollo/client';
import { AnyObject } from '../../models/types';
 
interface RequestJunoLogsVars {
  logGroups?: string;
  startTime: string;
  endTime: string;
  searchTerm?: string;
  queryString?: string;
}
 
export const REQUEST_JUNO_LOGS = gql`
  mutation RequestJunoLogs(
    $logGroups: String
    $startTime: String
    $endTime: String
    $searchTerm: String
    $queryString: String
  ) {
    requestJunoLogs(
      logGroups: $logGroups
      startTime: $startTime
      endTime: $endTime
      searchTerm: $searchTerm
      queryString: $queryString
    )
  }
`;
 
export const useRequestJunoLogsMutation = () => {
  return useMutation<AnyObject, RequestJunoLogsVars>(REQUEST_JUNO_LOGS,
    {
      fetchPolicy: 'no-cache'
    });
};