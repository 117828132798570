import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Box,
  Button,
  Header,
  Pagination,
  ProgressBar,
  PropertyFilter,
  SpaceBetween,
  Table,
} from '@amzn/awsui-components-react';
import { get } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from '../../utils/constants';
import {
  EmptyState,
  getFilterCounterText,
  Preferences,
} from '../../utils/tablePreferences';
import {
  columnDefinitions,
  DEFAULT_PREFERENCES,
  FILTERING_PROPERTIES,
  PAGE_SIZE_OPTIONS,
  VISIBLE_CONTENT_OPTIONS,
} from '../commons/constants';
import { useGetJunoLogsQuery } from '../hooks/useGetJunoLogsQuery';

const BrowseLogs = (props) => {
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);

  const [getJunoLogs, { loading: junoLogsLoading, data: junoLogs }] =
    useGetJunoLogsQuery();
  const intervalRef = useRef(null);

  useEffect(() => {
    if (props.s3Keys.s3_keys) {
      const s3Keys = props.s3Keys.s3_keys.join(', ');
      intervalRef.current = setInterval(() => {
        getJunoLogs({
          variables: {
            s3Keys: s3Keys,
          },
        });
      }, 15000);
    }
  }, [props]);

  useEffect(() => {
    var results = get(junoLogs, 'getJunoLogs.results', []);
    if (results.length > 0) {
      DEFAULT_PREFERENCES['visibleContent'] = Object.keys(results[0]);
      setPreferences(DEFAULT_PREFERENCES);
    }
    var statuses = get(junoLogs, 'getJunoLogs.statuses', []);
    if (statuses && statuses.length) {
      var total = statuses.length;
      var completed = statuses.filter((x) => x === 'Complete').length;
      var percentLoaded = (completed / total) * 100;
      props.setProgressValue(percentLoaded);
      if (percentLoaded == 100) {
        clearTimeout(intervalRef.current);
      }
    }
  }, [junoLogs]);

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(junoLogs ? get(junoLogs, 'getJunoLogs.results') : [], {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES,
      empty: (
        <EmptyState
          title="No Log Records Found"
          subtitle="No log records to display."
          action={<span></span>}
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can’t find a match."
          action={
            <Button onClick={() => actions.setFiltering('')}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  return (
    <SpaceBetween size="l" direction="vertical">
      <Table
        {...collectionProps}
        header={
          <Box>
            <Header counter={`(${items.length})`}>Browse Logs</Header>
            <div style={{ margin: 'auto', textAlign: 'center' }}>
              {props.s3Keys.s3_keys && (
                <ProgressBar
                  status={
                    props.progressValue == 100 ? 'success' : 'in-progress'
                  }
                  value={props.progressValue}
                  label={
                    props.progressValue == 100
                      ? 'Logs are ready'
                      : 'Downloading logs........'
                  }
                />
              )}
            </div>
          </Box>
        }
        columnDefinitions={columnDefinitions}
        stickyHeader={true}
        resizableColumns={false}
        wrapLines={preferences.wraplines}
        visibleColumns={preferences.visibleContent}
        items={items}
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
          />
        }
        filter={
          <div className="input-container">
            <PropertyFilter
              className="input-filter"
              i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
              {...propertyFilterProps}
              countText={getFilterCounterText(filteredItemsCount)}
            />
          </div>
        }
        preferences={
          <Preferences
            preferences={preferences}
            setPreferences={setPreferences}
            disabled={false}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            visibleContentOptions={VISIBLE_CONTENT_OPTIONS}
          />
        }
      />
    </SpaceBetween>
  );
};

export { BrowseLogs };
