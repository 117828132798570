import {
  BreadcrumbGroup,
  Container,
  Header,
  SpaceBetween,
  Table,
  Flashbar,
  Pagination,
  Button,
  ButtonDropdown,
  PropertyFilter
} from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator/internal';
import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { cloneDeep, get } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import '../../Styles/logs.css';
import { AppContext } from '../../utils/appContext';
import {BREADCRUMBS, PROPERTY_FILTERING_I18N_CONSTANTS} from '../../utils/constants';
import {useGetOnDemandDataQuery} from "./useGetOnDemandDataQuery";
import {useGetOnDemandDomainQuery} from "./useGetOnDemandDomainQuery";
import {
  LIST_EXECUTION_FILTERING_PROPERTIES,
  LOG_VIEWER_DEFAULT_PREFERENCES,
  LOG_VIEWER_FILTERING_PROPERTIES,
  LOG_VIEWER_VISIBLE_CONTENT_OPTIONS,
  logViewerColumnDefintions,
  PAGE_SIZE_OPTIONS,
  statusFilterItems
} from "./constants";
import {EmptyState, getFilterCounterText, Preferences} from "../../utils/tablePreferences";
import {ListExecutions} from "./ListExecutions";

const OnDemandLogViewer = (props) => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const params = useParams();
  const executionId = params['executionId']
  const clientId = params['clientId'];
  const domainName = params['domainName'];
  const domainId = `${clientId}:${domainName}`
  const [getOnDemandData, { loading: logFileLoading, data: logFileResponse, error: error }] = useGetOnDemandDataQuery()
  const logFileResponseItems = get(logFileResponse, 'getOnDemandData.record.Data', [])
  const [isDataTruncated, setIsDataTruncated] = useState(false)
  const preSignedUrl = get(logFileResponse, 'getOnDemandData.record.SignedURL', '')
  const [getOnDemandDomain, { loading: getDomainLoading, data: getDomainResponse, error: getDomainError }] = useGetOnDemandDomainQuery()
  const [domainQuery, setDomainQuery] = useState(false)
  const domainResponse = get(getDomainResponse, 'getOnDemandDomain', '')
  const dashboardEndpoint = `https://${domainResponse}/_dashboards`
  const [preferences, setPreferences] = useState(LOG_VIEWER_DEFAULT_PREFERENCES);
  const [statusFilter, setStatusFilter] = useState(null);
  const [flashbarItems, setFlashbarItems] = useState([]);

  const flattened_items = useMemo(() => {
    const flattened_list = []
    for(let i=0 ; i< logFileResponseItems.length; i++)
    {
      flattened_list.push(logFileResponseItems[i]._source)
    }
    return flattened_list
  }, [logFileResponseItems]);

  useEffect(() => {
    const isDataTruncated = get(logFileResponse, 'getOnDemandData.record.IsDataTruncated', false)
    if (isDataTruncated) {
      setIsDataTruncated(true)
      setFlashbarItems([
        {
          type: 'error',
          content: 'These are not complete logs,UI shows logs upto 35k due to memory limit. For complete logs go to Kibana Dashboard - ELB Table or download CSV upto 200000 log records',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setFlashbarItems([]),
        },
      ]);
    }

  }, [logFileResponse, logFileResponseItems]);

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(logFileLoading ? [] : flattened_items, {
    propertyFiltering: {
      filteringProperties: LOG_VIEWER_FILTERING_PROPERTIES,
      empty: (
        <EmptyState
          title="No logs"
          subtitle="No logs to display."
          action={<span></span>}
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can’t find a match."
          action={
            <Button onClick={() => actions.setFiltering('')}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  const filteredItems = useMemo(() => {
    if (statusFilter === '4xx') {
      return items.filter(item => String(item.elbStatusCode).startsWith('4'));
    } else if (statusFilter === '5xx') {
      return items.filter(item => String(item.elbStatusCode).startsWith('5'));
    }
    return items;
  }, [items, statusFilter]);

  useEffect(() => {
    appContext.setBreadcrumbs([
      {
        ...BREADCRUMBS.TUMBLER,
        href: '/',
        onClick: () => {
          history.push('/');
        },
      },
      {
        text: clientId,
        href: `/${clientId}`,
        onClick: () => {
          history.push(`/${clientId}`);
        },
      },
      {
        text: domainName,
        href: `/${clientId}/${domainName}`,
        onClick: () => {
          history.push(`/${clientId}/${domainName}`);
        },
      },
      {
        text: executionId,
        href: `/${clientId}/${domainName}/ondemand/${executionId}`,
      },
    ]);
    getOnDemandData({ variables: {
        executionId: executionId
      }
    });
    setDomainQuery(true)
  }, []);

  useEffect(() => {
    getOnDemandDomain({ variables: {
      domainId: domainId
    }
  });
  },[domainQuery])

  const [logContent, setLogContent] = useState('');

  useEffect(() => {
    setLogContent(logFileResponseItems);
  }, [logFileResponseItems]);

  const downloadCSV = () => {
    window.location.href = preSignedUrl;
  };

  const scroll = useCallback((node) => {
    if (node !== null) {
      node.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  }, []);

  return (
    <React.Fragment>
      <SpaceBetween size="m" direction="vertical">
        <BreadcrumbGroup items={appContext.breadcrumbs} />
        <Container
          header={
            <>
              <SpaceBetween size="m" direction="horizontal">
                <div>
                  <Header variant="h3">
                    Kibana Dashboard Link: {/* This is your added heading */}
                    <a href={dashboardEndpoint} target="_blank" rel="noopener noreferrer">
                      ELB Logs
                    </a>
                  </Header>
                  <p>The above dashboard contains aggregations around ELB logs.Login to the dashboard and follow the below steps:</p> {/* This is your added paragraph */}
                  <ul>
                    <li>1: Authenticate yourself by signing with your corporate ID i.e by clicking on 'Amazon'.</li>
                    <li>2: Click on the sidebar. Click on Dashboard and open 'ELB Analysis dashboard' for aggregations and graphs</li>
                    <li>2: Open 'ELB Table' Dashboard for ELB logs full records.</li>
                    <li>3: Add the domain ident in the filter for which you want to see the aggregations.</li>
                    <li>4: Choose the timestamp between which you requested the ELB Logs.</li>
                  </ul>
              </div>
              </SpaceBetween>
            </>
          }
        >
          <div
            style={{
              fontFamily: '"Roboto Mono",Consolas,Menlo,Courier,monospace',
            }}
          >
            {logContent === '' ? (
              <StatusIndicator type="loading">Loading Log File</StatusIndicator>
            ) : (
              <SpaceBetween size="l" direction="vertical">
                {isDataTruncated &&
                    (<Flashbar items={flashbarItems} />)
                }
                <Table
                  header={
                    <Header
                        counter={`(${logContent.length})`}
                        actions={
                          <SpaceBetween direction="horizontal" size="l">
                            <Button onClick={downloadCSV}>
                              Download Upto 200000 CSV
                            </Button>
                          </SpaceBetween>
                        }
                    >
                      Logs
                    </Header>
                  }
                  columnDefinitions={logViewerColumnDefintions}
                  stickyHeader={true}
                  resizableColumns={false}
                  loading={logFileLoading}
                  visibleColumns={preferences.visibleContent}
                  items={filteredItems}
                  pagination={
                    <Pagination
                      {...paginationProps}
                      ariaLabels={{
                        nextPageLabel: 'Next page',
                        previousPageLabel: 'Previous page',
                        pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
                      }}
                    />
                  }
                  filter={
                    <div className="input-container">
                      <PropertyFilter
                        className="input-filter"
                        i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                        {...propertyFilterProps}
                        countText={getFilterCounterText(filteredItemsCount)}
                      />
                      <ButtonDropdown
                        items={statusFilterItems}
                        expandableGroups={false}
                        onItemClick={(d) => setStatusFilter(d.detail.id)}
                      >
                        Status Code
                      </ButtonDropdown>
                    </div>
                  }
                  preferences={
                    <Preferences
                      preferences={preferences}
                      setPreferences={setPreferences}
                      disabled={false}
                      pageSizeOptions={PAGE_SIZE_OPTIONS}
                      visibleContentOptions={LOG_VIEWER_VISIBLE_CONTENT_OPTIONS}
                    />
                  }
                />
              </SpaceBetween>
            )}
          </div>
        </Container>
      </SpaceBetween>
    </React.Fragment>
  );
};

export { OnDemandLogViewer };
