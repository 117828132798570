import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Box,
  Button,
  Header,
  Pagination,
  PropertyFilter,
  SpaceBetween,
  Table,
} from '@amzn/awsui-components-react';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import {AnyObject, CommonDomainProps, ESEC2} from '../../../../models/types';
import { ExportCSV } from '../../../../utils/exportCSV';
import { getHealthyInstance } from '../../../../utils/helpers';
import { DomainResourcesData } from '../../hooks/useResourceInfoQuery';
import { useSnapshotsInfoQuery } from '../../hooks/useSnapshotsInfoQuery';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from './../../../../utils/constants';
import {
  DEFAULT_PREFERENCES,
  FILTERING_PROPERTIES,
  getFilterCounterText,
  Preferences,
  snapshotColumns,
} from './columns';
import { isError } from "../../../../ErrorHandler/apiErrorHandler";
import { ErrorMessageComponent } from "../../../../ErrorHandler/errorMessageComponent";
import { ApiList }from "../../../../ErrorHandler/utils";

interface SnapshotsInfoProps extends CommonDomainProps {
  domainData: DomainResourcesData | undefined;
  resourceLoading: boolean;
}

const SnapshotsInfo = (props: SnapshotsInfoProps) => {
  const [getSnapshotsInfo, { loading: loadingSnapshots, data: snapshotsData, error: error }] =
    useSnapshotsInfoQuery();
  const [errorMessage, setErrorMessage] = useState(null)
  const isErrorPresent = isError(error)
  useEffect(() => {
    if (isErrorPresent) {
      setErrorMessage("Unable to get Snapshots data. Failed with " + error.message);
    }
  }, [isErrorPresent, error]);

  const ec2Instances = get<ESEC2[]>(
    props.domainData as any,
    'domain.resources.ALL.ec2Instances',
    []
  );
  useEffect(() => {
    const healthyInstance = getHealthyInstance(ec2Instances as any);
    if (!props.resourceLoading && healthyInstance) {
      getSnapshotsInfo({
        variables: {
          domainIdentifier: props.domainIdentifier,
          instanceId: healthyInstance.instanceId,
        },
      });
    }
  }, [
    props.resourceLoading,
    props.domainData,
    props.domainIdentifier,
    ec2Instances,
    getSnapshotsInfo,
  ]);

  const snapshotItems =
      get<Array<AnyObject>>(
          snapshotsData as any,
          'domain.es60.cat.snapshots',
          []
      ) || [];

  let snapshotsItems = [];
  if (snapshotItems.length !== 0) {
    try {
      snapshotsItems = JSON.parse(snapshotItems);
    } catch (error) {
      console.log('Failed to parse snapshotItems. Please check the data format.')
    }
  }


  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const {
    items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps,} =
      useCollection( snapshotsItems, {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES,
      empty: (
        <EmptyState
          title="No Snapshots"
          subtitle="No Snapshots to display."
          action={<span></span>}
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can’t find a match."
          action={
            <Button onClick={() => actions.setFiltering('')}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
  });
  function EmptyState({ title, subtitle, action }) {
    return (
      <Box textAlign="center" color="inherit">
        <Box variant="strong" textAlign="center" color="inherit">
          {title}
        </Box>
        <Box variant="p" padding={{ bottom: 's' }} color="inherit">
          {subtitle}
        </Box>
        {action}
      </Box>
    );
  }

  return (
    <div>
      {errorMessage ? (
        <ErrorMessageComponent errorMessage={errorMessage} apiName={ApiList.SNAPSHOTS_INFO}/>
      ) : (
      <Table
        {...collectionProps}
        header={
          <Header
            counter={`(${items.length})`}
            actions={
              <SpaceBetween direction="horizontal" size="s">
                <ExportCSV
                  data={items}
                  filename={'EC2_instances.csv'}
                  loading={loadingSnapshots}
                />
              </SpaceBetween>
            }
          >
            Snapshots Info
          </Header>
        }
        columnDefinitions={snapshotColumns}
        stickyHeader={true}
        resizableColumns={true}
        wrapLines={preferences.wraplines}
        loading={props.resourceLoading || loadingSnapshots}
        loadingText={
          props.resourceLoading || loadingSnapshots
            ? 'Loading Snapshots Info'
            : 'No Snapshots found'
        }
        visibleColumns={preferences.visibleContent}
        items={items}
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
          />
        }
        filter={
          <PropertyFilter
            i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
            {...propertyFilterProps}
            countText={getFilterCounterText(filteredItemsCount)}
          />
        }
        preferences={
          <Preferences
            preferences={preferences}
            setPreferences={setPreferences}
            disabled={false}
          />
        }
      />
      )}
    </div>
  );
};

export { SnapshotsInfo };
