import * as React from 'react';
import { CSVLink } from 'react-csv';
import { Button } from '@amzn/awsui-components-react';

interface ExportCSVProps{
    data: {};
    filename: string;
    loading: boolean;
}

const ExportCSV = (props:ExportCSVProps) => {

    return (
        <Button loading={props.loading}>
            <CSVLink
            data={props.data}
            filename={props.filename}
            >
            Export
            </CSVLink>
        </Button>
    );
}

export { ExportCSV };