import { gql, useQuery } from '@apollo/client';
import { AnyObject } from '../../../models/types';
 
export interface GetTicketsVars {
    domainId: string;
}
 
const GET_TICKETS = gql`
    query GetTickets(
      $domainId: String!
    ) {
        getTickets(
          domainId: $domainId
        )
    }
`;
 
export const useGetTicketsQuery = (vars: GetTicketsVars) => {
    return useQuery<AnyObject, GetTicketsVars>(GET_TICKETS, {
      variables: { ...vars },
    });
};