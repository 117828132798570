import {
  EuiCodeBlock,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
} from '@elastic/eui';
import React from 'react';

interface TmsDetailsProps {
  description: string;
}

const TmsDetails = (props: TmsDetailsProps) => {
  return (
    <EuiFlexGroup gutterSize={'xs'}>
      <EuiFlexItem>
        <strong>Issue details: </strong>
        <EuiSpacer />
        <EuiCodeBlock isCopyable={true} language={'shell'}>
          {props.description}
        </EuiCodeBlock>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export { TmsDetails };
