import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../models/types';
 
const GET_JUNO_CW_INSIGHT_QUERIES = gql`
    query GetJunoCWInsightQueries{
        getJunoCWInsightQueries
    }
`;
 
export const useGetJunoCWInsightQueriesQuery = () => {
    return useLazyQuery<AnyObject>(GET_JUNO_CW_INSIGHT_QUERIES, {
        errorPolicy: 'all',
    });
};
