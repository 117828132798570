import React from 'react';
import { DeployUtils } from './Utilities/DeployUtils';
import { DescribeTask } from './Utilities/DescribeTask';
import {
  EuiModal,
  EuiModalHeader,
  EuiOverlayMask,
  EuiModalBody,
  EuiIcon,
  EuiModalHeaderTitle,
  EuiModalFooter,
  EuiButton,
} from '@elastic/eui';
export class RemoveComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: true,
      isInputsSet: false,
      hasNewTaskAdded: false,
      taskID: '',
    };
  }

  deleteComponent = async () => {
    const componentVersion = new DeployUtils();
    const componentDetails = [];
    componentDetails.push(this.props.componentDetails);
    const response = await componentVersion.applyComponentVersions(
      this.props.stackName,
      this.props.clusterId,
      componentDetails,
      this.props.region,
      this.props.stage,
      this.props.credentials
    );
    this.setState({ taskID: response.body.taskID });
    this.setState({ isInputsSet: true });
    this.setState({ hasNewTaskAdded: true });
  };

  closeModal = () => {
    this.props.updateDeletedComponent();
    this.setState({ hasNewTaskAdded: false });
    this.setState({ isInputsSet: true });
  };

  updateTask() {
    this.setState({ hasNewTaskAdded: false });
  }

  render() {
    let message = '';
    let button = '';
    let heading = '';
    if (this.state.isInputsSet) {
      message = '';
      button = '';
      heading = 'Remove Component Status';
    } else {
      message =
        'Are you sure you want to delete the ' +
        this.props.componentDetails.componentName +
        ' ?';
      button = (
        <EuiButton
          onClick={() => {
            this.deleteComponent();
          }}
        >
          ok
        </EuiButton>
      );
      heading = 'Warning';
    }

    return (
      <EuiOverlayMask>
        <EuiModal onClose={this.closeModal}>
          <EuiModalHeader>
            <EuiModalHeaderTitle side="left">
              <EuiIcon type="alert" color="danger" size="xl" />
              {heading}
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            {message}
            <DescribeTask
              hasNewTaskAdded={this.state.hasNewTaskAdded}
              isInputsSet={this.state.isInputsSet}
              taskID={this.state.taskID}
              accessKeyId={this.props.accessKeyId}
              secretAccessKey={this.props.secretAccessKey}
              sessionToken={this.props.sessionToken}
              updateTask={() => {
                this.updateTask();
              }}
              region={this.props.region}
              stage={this.props.stage}
              credentials={this.props.credentials}
            />
          </EuiModalBody>
          <EuiModalFooter>{button}</EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    );
  }
}
