import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText } from '@elastic/eui';
import React from 'react';
import { ClusterDetails } from '../../../sdpds/application/Cluster/V2/ClusterDetails';
import { apiCall, getDefaultStack } from '../../../sdpds/utils';
class SdpdsClusterDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isError: false,
      content: null,
    };
    this.getClusterDetails();
  }

  getClusterDetails = async () => {
    const config = await apiCall('/getConfig', {});
    if (config.statusCode === 200) {
      const stack = getDefaultStack(config.body);
      const cluster = this.props.domainIdentifier;
      const content = (
        <div>
          <ClusterDetails
            hideDeleteComponent={true}
            hideDeployComponent={true}
            stackName={stack}
            clusterId={cluster}
          />
        </div>
      );
      this.setState({ content, isLoading: false });
    } else {
      const content = (
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiIcon color="red" size="xl" type="alert" />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText>
              <h3>{'An unexpected error has occured.'}</h3>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      );
      this.setState({ content, isLoading: false });
    }
  };

  render() {
    const { isLoading, isError, content } = this.state;

    if (isError) {
      return <p>Error</p>;
    } else if (isLoading) {
      return <p>Loading</p>;
    } else {
      return content;
    }
  }
}

export { SdpdsClusterDetails };
