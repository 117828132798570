import { gql, useQuery } from '@apollo/client';
import { AnyObject } from '../../../models/types';

interface DomainConfigVars {
  domainIdentifier: string;
}
interface DomainConfigData {
  domain: {
    rawConfig: AnyObject;
  };
}
const GQL_DOMAIN_CONFIG = gql`
  query getDomainConfig($domainIdentifier: String!) {
    domain(domainIdentifier: $domainIdentifier) {
      rawConfig
    }
  }
`;

export const useDomainConfigQuery = (vars: DomainConfigVars) => {
  return useQuery<DomainConfigData, DomainConfigVars>(GQL_DOMAIN_CONFIG, {
    variables: { ...vars },
  });
};
