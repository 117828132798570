import {
  Alert,
  Box,
  Button,
  ColumnLayout,
  Container,
  Grid,
  Header,
  Popover,
  SpaceBetween
} from '@amzn/awsui-components-react';
import { TimezoneToggle } from '../../../Components/TimezoneToggle';
import CopyText from '../../../utils/copy-text';
import {useRetryCollectionFailure} from '../../hooks/Collection/useRetryCollectionFailureQuery';
import {useEffect, useState} from "react";

// interface CollectionDetails {
//   CollectionName: boolean;
//   CollectionCreatedAt: string;
//   ec2Instances: ESEC2[];
//   esVersion: string;
//   cellId: string;
//   serverType: string;
//   pinnedVFIDomain: string;
//   pinnedVFIClient: string;
//   IsSensitiveDomain: string;
//   IsSensitiveClient: string;
//   IsDomainIsolated: string;
//   elasticResponse: ElasticResponse;
//   kibanaResponse: KibanaResponse;
//   HAProxyVersion: string;
//   HAProxyType: string;
//   carbonDomain: string;
// }

const ValueWithLabel = ({ label, children }) => (
  <div>
    <Box margin={{ bottom: 'xxxs' }} color="text-label">
      {label}
    </Box>
    <div>{children}</div>
  </div>
);

const CollectionDetails = (props) => {
  const [seconds, setSeconds] = useState(0);
  const [refresh, setRefresh] = useState(true);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [
    retryCollectionFailureQuery,
    {
      loading: retryCollectionFailureLoading,
      data: retryCollectionFailureResponse,
    },
  ] = useRetryCollectionFailure();

  const retryCollectionFailure = () => {
    retryCollectionFailureQuery({
      variables: {
        awsAccountId: props.awsAccountId,
        collectionId: props.collectionDetails.id
      },
    });
    setSeconds(Date.now());
    setRefresh(false);
  };

  useEffect(() => {
    const time = Math.floor((Date.now()-seconds / 1000 / 60) % 60)
    setTimeElapsed(time)
  })
  return (
    <Container header={<Header variant="h2">Collection Details</Header>}>
      <ColumnLayout columns={3} variant="text-grid">
        <SpaceBetween size="l">
          <ValueWithLabel label="Id">
            {props.collectionDetails ? (
              <CopyText
                copyText={props.collectionDetails.id}
                copyButtonLabel={props.collectionDetails.id}
                successText="Collection Id copied"
                errorText="Error while copying Collection id"
              />
            ) : (
              '--'
            )}
          </ValueWithLabel>
          <ValueWithLabel label="Created At">
            {props.collectionDetails ? (
              <TimezoneToggle
                date={new Date(props.collectionDetails.created_date)}
              />
            ) : (
              '--'
            )}
          </ValueWithLabel>
          <ValueWithLabel label="Last Modified At">
            {props.collectionDetails ? (
              <TimezoneToggle
                date={new Date(props.collectionDetails.last_modified_date)}
              />
            ) : (
              '--'
            )}
          </ValueWithLabel>
        </SpaceBetween>

        <SpaceBetween size="l">
          <ValueWithLabel label="Name">
            {props.collectionDetails ? (
              <CopyText
                copyText={props.collectionDetails.name}
                copyButtonLabel={props.collectionDetails.name}
                successText="Collection name copied"
                errorText="Error while copying Collection name"
              />
            ) : (
              '--'
            )}
          </ValueWithLabel>
          <ValueWithLabel label="Collection Type">
            {props.collectionDetails ? props.collectionDetails.type : '--'}
          </ValueWithLabel>
          <ValueWithLabel label="Placement Constraint Id">
            {props.collectionDetails ? (
              <CopyText
                copyText={props.collectionDetails.placement_constraint_id}
                copyButtonLabel={
                  props.collectionDetails.placement_constraint_id
                }
                successText="placement constraint id copied"
                errorText="Error while copying placement constraint id"
              />
            ) : (
              '--'
            )}
          </ValueWithLabel>
        </SpaceBetween>

        <SpaceBetween size="l">
          <ValueWithLabel label="KMS Key Type">
            {props.collectionDetails
              ? props.collectionDetails.kms_key_arn
              : '--'}
          </ValueWithLabel>
          <ValueWithLabel label="Status">
            {props.collectionDetails ? props.collectionDetails.status : '--'}
          </ValueWithLabel>
          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]} >
          <ValueWithLabel label="Failure Reason">
            <Popover
              dismissButton={false}
              position="top"
              size="large"
              triggerType="custom"
              content={
                props.collectionDetails
                  ? props.collectionDetails.failure_reason !== null
                    ? JSON.stringify(
                        JSON.parse(
                          props.collectionDetails
                            ? props.collectionDetails.failure_reason
                            : ''
                        ),
                        null,
                        2
                      ).replace(/\\/g, '')
                    : 'None'
                  : '--'
              }
            >
              {props.collectionDetails ? (
                props.collectionDetails.failure_reason !== null ? (
                  <Button iconName="bug" variant="icon"></Button>
                ) : (
                  'None'
                )
              ) : (
                '--'
              )}
            </Popover>
          </ValueWithLabel>
          {props.collectionDetails ? (
                  props.collectionDetails.failure_reason !== null && refresh && props.collectionDetails.status.toLocaleLowerCase() === "failed" ?
                      <ValueWithLabel label="Retry Failed Collection">
                        <Popover
                            dismissButton={false}
                            position="top"
                            size="large"
                            triggerType="custom"
                            renderWithPortal={true}
                            content={"Retry Collection Failure"}
                        >
                          {props.collectionDetails ? (
                              props.collectionDetails.failure_reason !== null && refresh && props.collectionDetails.status.toLocaleLowerCase() === "failed" ? (
                                  <Button iconName="refresh" variant="icon" onClick={retryCollectionFailure}></Button>
                              ) : (
                                  ''
                              )
                          ) : (
                              '--'
                          )}
                        </Popover>
                      </ValueWithLabel> :
                      <div/> ) :
              <div/>
          }
        </Grid>
        {retryCollectionFailureResponse && !retryCollectionFailureResponse.retryCollectionFailure.isEmpty ?
            <div>
              <Alert
                  statusIconAriaLabel="Info"
                  header="Your request to retry the failed collection is in progress "
              >
                The queue size of the retry is {retryCollectionFailureResponse.retryCollectionFailure.approximate_position_in_queue} and
                the wait time for the retry to complete is
                {retryCollectionFailureResponse.retryCollectionFailure.estimated_wait_time_in_minutes}minutes.
                So, please refresh the page after {retryCollectionFailureResponse.retryCollectionFailure.estimated_wait_time_in_minutes}minutes.
              </Alert>
            </div> :
            <div></div>
        }
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
};

export { CollectionDetails };
