import React, { useState } from 'react';
import {
  Box,
  Button,
  StatusIndicator,
  Popover,
} from '@amzn/awsui-components-react';

const SUCCESS_STATUS = 'success';
const ERROR_STATUS = 'error';

function copyToClipboard(text) {
  try {
    // Try to use the modern clipboard API.
    // Some browsers only allow this API in response to a user initiated event.
    return navigator.clipboard.writeText(text);
  } catch {
    // Fall back to using a textarea. Making it asynchronous to align with clipboard API
    // https://stackoverflow.com/a/30810322/898577
    return new Promise((resolve, reject) => {
      const activeElement = document.activeElement;
      const textArea = document.createElement('textarea');
      textArea.value = text;
      textArea.style.position = 'fixed';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        resolve();
      } catch {
        reject();
      } finally {
        document.body.removeChild(textArea);
        activeElement.focus();
      }
    });
  }
}

export default function CopyText({
  copyText,
  copyButtonLabel,
  successText,
  errorText,
}) {
  const [status, setStatus] = useState(SUCCESS_STATUS);
  const [message, setMessage] = useState(successText);

  return (
    <div className="custom-wrapping">
      <Box margin={{ right: 'xxs' }} display="inline-block">
        <Popover
          size="small"
          position="top"
          dismissButton={false}
          triggerType="custom"
          content={<StatusIndicator type={status}>{message}</StatusIndicator>}
        >
          <Button
            variant="inline-icon"
            iconName="copy"
            ariaLabel={copyButtonLabel}
            onClick={() => {
              copyToClipboard(copyText).then(
                () => {
                  setStatus(SUCCESS_STATUS);
                  setMessage(successText);
                },
                () => {
                  setStatus(ERROR_STATUS);
                  setMessage(errorText);
                }
              );
            }}
          />
        </Popover>
      </Box>
      {copyButtonLabel}
    </div>
  );
}
