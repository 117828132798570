import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiPanel,
  EuiTitle,
  EuiTitleSize,
} from '@elastic/eui';
import { get } from 'lodash';
import React from 'react';

interface ContentPanelProps {
  title: string;
  titleSize?: EuiTitleSize;
  bodyStyles?: React.CSSProperties;
  panelStyles?: React.CSSProperties;
  horizontalRuleClassName?: string;
  contentPanelClassName?: string;
  titleClassName?: string;
  titleContainerStyles?: React.CSSProperties;
  actions?: React.ReactNode | React.ReactNode[];
  children: React.ReactNode | React.ReactNode[];
}

// CSS width for datepicker flex group.
const datePickerFlexGroupGrowWidthDimensions: {[key: number]: number} = { 0: 9, 1: 3, 2: 6, 3: 1 };

const ContentPanel = (props: ContentPanelProps) => (
  <EuiPanel
    style={{
      paddingLeft: '0px',
      paddingRight: '0px',
      ...get(props, 'panelStyles', {}),
    }}
    className={props.contentPanelClassName}
  >
    <EuiFlexGroup
      style={{
        padding: '0px 10px',
        ...get(props, 'titleContainerStyles', {}),
      }}
      justifyContent="spaceBetween"
      alignItems="center"
    >
      <EuiFlexItem grow={2}>
        <EuiTitle
          size={props.titleSize || 'xs'}
          className={props.titleClassName || ''}
        >
          <h3>{props.title}</h3>
        </EuiTitle>
      </EuiFlexItem>
      <EuiFlexItem grow={3}>
        <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
          {Array.isArray(props.actions) ? (
            props.actions.map((action: React.ReactNode, idx: number) => (
              <EuiFlexItem
                key={idx}
                grow={datePickerFlexGroupGrowWidthDimensions[idx] as any}
              >
                {action}
              </EuiFlexItem>
            ))
          ) : (
            <EuiFlexItem>{props.actions}</EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
    {props.title !== '' && (
      <EuiHorizontalRule
        margin="xs"
        className={props.horizontalRuleClassName}
      />
    )}
    <div style={{ padding: '0px 10px', ...props.bodyStyles }}>
      {props.children}
    </div>
  </EuiPanel>
);

export default ContentPanel;
