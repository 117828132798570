import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../models/types';
 
export interface GetJunoLogVars {
    pointer: String;
}
 
const GET_JUNO_LOG_RECORD = gql`
    query GetJunoLogRecord(
      $pointer: String
    ) {
        getJunoLogRecord(
          pointer: $pointer
        )
    }
`;
 
export const useGetJunoLogRecordQuery = () => {
    return useLazyQuery<AnyObject, GetJunoLogVars>(GET_JUNO_LOG_RECORD, {
        errorPolicy: 'all',
    });
};