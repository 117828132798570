import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../../models/types';


export interface ListSecurityConfigsVars {
    awsAccountId: String;
    type: String;
}

const LIST_SECURITY_CONFIGS = gql`
	     query ListSecurityConfigs(
	             $awsAccountId: String,
	             $type: String,
	         ){
	         listSecurityConfigs(
	             awsAccountId: $awsAccountId,
	             type: $type
	         )
	     }
	 `;

export const useListSecurityConfigsQuery = () => {
    return useLazyQuery<AnyObject, ListSecurityConfigsVars>(LIST_SECURITY_CONFIGS, {
        errorPolicy: 'all',
    });
};