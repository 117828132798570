import React from 'react';
import { EuiInMemoryTable, EuiButton } from '@elastic/eui';
import { apiCall } from '../../../../utils';

export class DescribeTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      isRequestActive: true,
      isLoading: true,
      statusCode: '',
      exception: '',
    };
    this.taskStatus = '';
    this.creationTime = '';
    this.lastModifiedTime = '';
    this.lastModifiedBy = '';
    this.tableId = 1;
    this.tableItems = [];
    this.taskList = [];
    this.hasIntervalSet = false;
    this.intervalID = '';
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  componentDidUpdate() {
    if (this.props.hasNewTaskAdded) {
      this.addNewTask(this.props.taskID);
      this.getDescribeTask();
      this.intervalID = setInterval(this.getDescribeTask, 5000);
    }
  }

  componentDidMount() {
    if (this.props.hasNewTaskAdded) {
      this.addNewTask(this.props.taskID);
      this.getDescribeTask();
    }
  }

  getDescribeTask = async () => {
    this.setState({ isLoading: true });
    this.tableItems = [];
    this.tableId = [];
    const data = { taskID: this.taskID };
    const response = await apiCall('/task/describe', data);
    this.setState({ statusCode: response.statusCode });
    if (response.statusCode === 200) {
      this.updateResponse(response);
    } else {
      this.updateException(response);
    }
    this.setState({ items: this.tableItems });
    this.setState({ isLoading: false });
  };

  updateException(response) {
    this.setState(this.setState({ exception: response.body }));
  }

  updateResponse(response) {
    this.tableItems = [];
    response = response.body;
    const taskID = response.taskDetails.taskID;
    const taskStatus = response.taskDetails.taskStatus;
    const creationTime = response.auditProperties.creationTime;
    const lastModificationTime = response.auditProperties.lastModificationTime;
    const lastModifiedBy = response.auditProperties.lastModifiedBy;
    const temp = {
      id: this.tableId,
      taskID: taskID,
      taskStatus: taskStatus,
      creationTime: creationTime,
      lastModificationTime: lastModificationTime,
      lastModifiedBy: lastModifiedBy,
    };
    this.tableItems.push(temp);
  }

  addNewTask() {
    this.taskID = this.props.taskID;
    this.props.updateTask();
  }

  renderToolsRight() {
    return (
      <EuiButton
        isLoading={this.state.isLoading}
        onClick={this.getDescribeTask}
      >
        Refresh
      </EuiButton>
    );
  }

  render() {
    let error = '';

    if (this.props.isInputsSet === false) {
      return null;
    }
    const columns = [
      {
        field: 'taskID',
        name: 'Task id',
      },
      {
        field: 'taskStatus',
        name: 'Task Status',
      },
      {
        field: 'creationTime',
        name: 'Creation Time',
      },
      {
        field: 'lastModificationTime',
        name: 'Last Modification Time',
      },
      {
        field: 'lastModifiedBy',
        name: 'Last Modified By',
      },
    ];
    const search = {
      toolsRight: this.renderToolsRight(),
    };
    const sorting = {
      sort: {
        field: 'id',
        direction: 'desc',
      },
    };
    const pagination = {
      initialPageSize: 10,
      pageSizeOptions: [5, 10, 15, 20],
    };
    if (this.state.isLoading === false && this.state.statusCode !== 200) {
      error = 'error: body ' + JSON.stringify(this.state.exception);
    }

    return (
      <EuiInMemoryTable
        items={this.state.items}
        columns={columns}
        sorting={sorting}
        loading={this.state.isLoading}
        pagination={pagination}
        search={search}
        error={error}
      />
    );
  }
}
