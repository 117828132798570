import { gql, useLazyQuery } from '@apollo/client';

interface OtsOnboardData {
  otsOnboardResponse: JSON;
}

export interface OtsAllVars {
  awsAccountId: string;
  regions: string[];
  isPartOfAwsOrg: string;
}

const OTS_ONBOARD = gql`
  query otsOnboard(
    $awsAccountId: String!
    $regions: [String]
    $isPartOfAwsOrg: String!
  ) {
    otsOnboardResponse(
      awsAccountId: $awsAccountId
      regions: $regions
      isPartOfAwsOrg: $isPartOfAwsOrg
    )
  }
`;

// export const useOtsItemsQuery = (vars: OtsAllVars) => {
//   return useQuery<OtsAllData, OtsAllVars>(OTS_ONBOARD, {
//     variables: { ...vars },
//   });
// };

export const useOtsOnboardQuery = () => {
  return useLazyQuery<OtsOnboardData, OtsAllVars>(OTS_ONBOARD, {
    errorPolicy: 'all',
  });
};
