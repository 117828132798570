// Contains the function used for setting the index skews
import React from 'react';
import { AnyObject } from '../../../../models/types';
import { setIndexTypes } from './IndexTypesUtil';

export const setIndexSkewData = (
  shardsResp: Array<AnyObject>,
  nodeBoxTypeMapping: { [key: string]: string },
  diNodeMapping: { [key: string]: Set<string> },
  di: string,
  indexTypesShardCount: { [key: string]: AnyObject },
  indexTypesShardStorage: { [key: string]: AnyObject },
  indexShardCount: Array<AnyObject>,
  indexShardStorage: Array<AnyObject>,
  dropdowns: Array<Array<boolean>>
) => {
  const indexShardStorageThreshold = 15;
  const indexShardCountThreshold = 0;

  // Calling method that assigns indices to appropriate type - warm,hot or mixed
  const numberOfNodesPerIndexType = setIndexTypes(
    shardsResp,
    diNodeMapping,
    di,
    nodeBoxTypeMapping,
    indexTypesShardCount,
    indexTypesShardStorage,
    indexShardStorageThreshold,
    indexShardCountThreshold
  );

  // Setting the index skews with appropriate messages
  // INDEX-SHARD-COUNT SKEW
  // Dropdown array to hold booleans indicating if respective accordion should be collapsed or not
  const indexShardCountDropDown: Array<boolean> = [];
  for (let indexType in indexTypesShardCount) {
    indexShardCountDropDown.push(false);
    let countMessage;
    let nodeType = indexType !== 'undefined' ? ' ' + indexType : '';
    if (indexTypesShardCount[indexType].skewIndices > 0) {
      countMessage = (
        <>
          <b> {indexTypesShardCount[indexType].skewIndices} </b>
          out of <b>{indexTypesShardCount[indexType].rows.length} </b>
          indices{' '}
          {indexTypesShardCount[indexType].skewIndices === 1 ? 'has ' : 'have '}
          number of shards unevenly distributed across{' '}
          {numberOfNodesPerIndexType[indexType].size}
          {nodeType}
          {numberOfNodesPerIndexType[indexType].size > 1 ? ' nodes' : ' node'}.
        </>
      );
    } else {
      countMessage = (
        <>
          <b>No issues. </b>
          {indexTypesShardCount[indexType].rows.length}
          {indexTypesShardCount[indexType].rows.length > 1
            ? ' indices '
            : ' index '}
          across {numberOfNodesPerIndexType[indexType].size}
          {nodeType}
          {numberOfNodesPerIndexType[indexType].size > 1 ? ' nodes' : ' node'}
          {indexTypesShardCount[indexType].rows.length > 1 ? ' are ' : ' is '}
          checked.
        </>
      );
    }
    indexShardCount.push({
      type: indexType !== 'undefined' ? indexType : '',
      desc: countMessage,
      valuesForTable: indexTypesShardCount[indexType].rows,
      skews: indexTypesShardCount[indexType].skewIndices,
    });
  }
  dropdowns.push(indexShardCountDropDown);

  // INDEX-SHARD-STORAGE SKEW
  // Dropdown array to hold booleans indicating if respective accordion should be collapsed or not
  const indexShardStorageDropDown: Array<boolean> = [];
  for (let indexType in indexTypesShardStorage) {
    indexShardStorageDropDown.push(false);
    let storageMessage;
    let nodeType = indexType !== 'undefined' ? ' ' + indexType : '';
    if (indexTypesShardStorage[indexType].skewIndices > 0) {
      storageMessage = (
        <>
          <b>{indexTypesShardStorage[indexType].skewIndices} </b>
          out of <b>{indexTypesShardStorage[indexType].rows.length} </b>
          indices{' '}
          {indexTypesShardStorage[indexType].skewIndices === 1
            ? 'has '
            : 'have '}
          shard storage unevenly distributed across{' '}
          {numberOfNodesPerIndexType[indexType].size}
          {nodeType}
          {numberOfNodesPerIndexType[indexType].size > 1 ? ' nodes ' : ' node '}
          (crossed the {indexShardStorageThreshold.toString()}% threshold).
        </>
      );
    } else {
      storageMessage = (
        <>
          <b>No issues. </b>
          {indexTypesShardStorage[indexType].rows.length}
          {indexTypesShardStorage[indexType].rows.length > 1
            ? ' indices '
            : ' index '}
          across {numberOfNodesPerIndexType[indexType].size}
          {nodeType}
          {numberOfNodesPerIndexType[indexType].size > 1 ? ' nodes ' : ' node '}
          {indexTypesShardStorage[indexType].rows.length > 1 ? ' are ' : ' is '}
          checked.
        </>
      );
    }
    indexShardStorage.push({
      type: indexType !== 'undefined' ? indexType : '',
      desc: storageMessage,
      valuesForTable: indexTypesShardStorage[indexType].rows,
      skews: indexTypesShardStorage[indexType].skewIndices,
    });
  }
  dropdowns.push(indexShardStorageDropDown);
};
