import {
  Box,
  ColumnLayout,
  Container,
  Header,
} from '@amzn/awsui-components-react';

const ValueWithLabel = ({ label, children }) => (
  <div>
    <Box margin={{ bottom: 'xxxs' }} color="text-label">
      {label}
    </Box>
    <div>{children}</div>
  </div>
);

const WorkerDetails = (props) => {
  return (
    <Container header={<Header variant="h2">Worker Details</Header>}>
      <ColumnLayout columns={3} variant="text-grid">
        <ValueWithLabel label="Worker Id">
          {props.workerDetails ? props.workerDetails.workerId : '--'}
        </ValueWithLabel>

        <ValueWithLabel label="Account Id">
          {props.workerDetails ? props.workerDetails.accountId : '--'}
        </ValueWithLabel>

        <ValueWithLabel label="Placement Constraint Id">
          {props.workerDetails ? props.workerDetails.placementConstraint : '--'}
        </ValueWithLabel>

        <ValueWithLabel label="Namespace">
          {props.workerDetails ? props.workerDetails.namespace : '--'}
        </ValueWithLabel>

        <ValueWithLabel label="Data Plane Type">
          {props.workerDetails ? props.workerDetails.dataPlaneType : '--'}
        </ValueWithLabel>

        <ValueWithLabel label="Availability Zone">
          {props.workerDetails && props.workerDetails.workerMetadata
            ? props.workerDetails.workerMetadata.availabilityZone
            : '--'}
        </ValueWithLabel>

        <ValueWithLabel label="Generation">
          {props.workerDetails ? props.workerDetails.generation : '--'}
        </ValueWithLabel>
      </ColumnLayout>
    </Container>
  );
};

export { WorkerDetails };
