import { SpaceBetween } from '@amzn/awsui-components-react';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
// @ts-ignore
import { useParams } from 'react-router-dom';
import { CommonDomainProps, Elb, ESEC2 } from '../../../models/types';
import { getHealthyInstance } from '../../../utils/helpers';
import { ExplorerLinks } from '../ExplorerLinks/ExplorerLinks'
import { ClusterOverview } from '../ClusterOverview';
import { Dashboard } from '../Dashboards/Dashboard';
import { DomainInspector } from '../DomainInspector/DomainInspector';
import {
  ElasticResponse,
  useClusterInfoQuery,
} from '../hooks/useClusterInfoQuery';
import { DomainResourcesData } from '../hooks/useResourceInfoQuery';
import { NodeList } from '../NodeList/NodeList';
import { ClusterInfo } from '../ClusterInfo';
import { ZoneWiseInfo } from '../ZoneWiseShardsInfo';
import { Sop } from '../SOPSearchability/Sop';
import { Tickets } from '../Tickets/Tickets';
import { ErrorBoundary } from 'react-error-boundary';
import { Fallback } from "../../../Components/Fallback";
import { ErrorCodes, isError } from "../../../ErrorHandler/apiErrorHandler";
import { refreshData } from "../utils/refreshData";

interface OverviewProps extends CommonDomainProps {
  domainData: DomainResourcesData | undefined;
  ec2Instances: ESEC2[];
  elb: Elb;
  esVersion: string;
  cellId: string;
  pinnedVFIDomain: string;
  pinnedVFIClient: string;
  IsSensitiveDomain: string;
  IsSensitiveClient: string;
  IsDomainIsolated: string;
  refreshResources: Function;
  resourceLoading: boolean;
  HAProxyVersion: string;
  HAProxyType: string;
  carbonDomain: string;
  errorMessage: string;
  handleRefreshData: Function;
  isFetching:boolean;
  dragonstoneDomain:string;
  DomainCreateDateTime: string;
}

const Overview = (props: OverviewProps) => {
  const { domainName, clientId } = useParams() as any;
  const [errorMessage, setErrorMessage] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [
    getClusterInfo,
    { loading: loadingCluster, data: clusterResourceData, error: error, refetch },
  ] = useClusterInfoQuery();



  const isErrorPresent = isError(error)
   useEffect(() => {
    if ( isErrorPresent ) {
       const errorMessage = error.message
       if (errorMessage.includes('unexpected character') || errorMessage.includes('Unexpected token')) {
         setErrorMessage("Unable to get domain details. Failed with " + ErrorCodes.BAD_GATEWAY + " Timeout. Please reload the page or try again later");  //add logging later
       } else {
         setErrorMessage("Unable to get domain details. Failed with " + error.message);
       }
    }
  }, [isErrorPresent, error]);

    const handleRefreshData = async () => {
        await refreshData(refetch, setIsFetching, setErrorMessage, isFetching, isErrorPresent, error, 'overview');
    };

  const ec2Instances = props.ec2Instances;
  const esVersion = props.esVersion;
  const pinnedVFIDomain = props.pinnedVFIDomain;
  const pinnedVFIClient = props.pinnedVFIClient;
  const IsSensitiveDomain = props.IsSensitiveDomain;
  const IsSensitiveClient = props.IsSensitiveClient;
  const IsDomainIsolated = props.IsDomainIsolated;
  const elb = props.elb;
  const DomainCreateDateTime = props.DomainCreateDateTime;

  useEffect(() => {
    const healthyInstance = getHealthyInstance(ec2Instances);
    if (!props.resourceLoading && healthyInstance) {
      getClusterInfo({
        variables: {
          domainIdentifier: props.domainIdentifier,
          instanceId: healthyInstance.instanceId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.resourceLoading,
    props.ec2Instances,
    props.domainIdentifier,
    props.elb,
    props.esVersion,
    props.pinnedVFIDomain,
    props.pinnedVFIClient,
    props.IsSensitiveDomain,
    props.IsSensitiveClient,
    props.IsDomainIsolated,
    props.DomainCreateDateTime,
  ]);

  const elasticResponse: ElasticResponse = get(
    clusterResourceData,
    'domain.es60',
    {
      cluster: {},
      cat: {},
    }
  );
  const serverType = get(
      clusterResourceData,
      'domain.clusterData.serverType',
      'N/A'
  )
    const discoveryMode = get(
      clusterResourceData,
      'domain.clusterData.discoveryMode',
      'N/A'
  )
  return (
    <SpaceBetween direction="vertical" size="l">
        <ExplorerLinks/>
      <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"DomainOverview"}/>}>
       <ClusterOverview
        isLoading={props.resourceLoading || props.isFetching}
        ec2Instances={ec2Instances}
        domainIdentifier={props.domainIdentifier}
        esVersion={esVersion}
        cellId={props.cellId}
        pinnedVFIDomain={pinnedVFIDomain}
        pinnedVFIClient={pinnedVFIClient}
        IsSensitiveDomain={IsSensitiveDomain}
        IsSensitiveClient={IsSensitiveClient}
        IsDomainIsolated={IsDomainIsolated}
        HAProxyVersion={props.HAProxyVersion}
        HAProxyType={props.HAProxyType}
        carbonDomain={props.carbonDomain}
        errorMessage={props.errorMessage}
        handleRefreshData={props.handleRefreshData}
        isFetching={props.isFetching}
        dragonstoneDomain={props.dragonstoneDomain}
        DomainCreateDateTime={DomainCreateDateTime}
      />
      </ErrorBoundary>

      <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"ClusterInfo"}/>}>
      <ClusterInfo
        elasticResponse={elasticResponse}
        kibanaResponse={get(clusterResourceData, 'domain.kibana', {
          health: 'unknown',
        })}
        isLoading={loadingCluster || props.resourceLoading || isFetching}
        ec2Instances={ec2Instances}
        domainIdentifier={props.domainIdentifier}
        errorMessage={errorMessage}
        discoveryMode={discoveryMode || 'N/A'}
        serverType={serverType || 'N/A'}
        handleRefreshData={handleRefreshData}
        isFetching={isFetching}
      />
      </ErrorBoundary>

      <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"ZoneWiseInfo"}/>}>
      <ZoneWiseInfo
        elasticResponse={elasticResponse}
        isLoading={loadingCluster || isFetching}
        isCarbonDomain={props.carbonDomain}
      />
      </ErrorBoundary>

      <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"NodeList"}/>}>
      <NodeList
        ec2Instances={ec2Instances ? ec2Instances : []}
        domainIdentifier={props.domainIdentifier}
        isLoading={props.resourceLoading || props.isFetching}
        refreshResources={props.refreshResources}
        errorMessage={props.errorMessage}
      />
      </ErrorBoundary>

      <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"DomainInspector"}/>}>
      <DomainInspector
        domainIdentifier={props.domainIdentifier}
        elb={props.elb}
      />
      </ErrorBoundary>

      <Sop />

      <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"MetricDashboard"}/>}>
      <Dashboard
        domainName={domainName}
        clientId={clientId}
        elb={elb}
        ec2Instances={ec2Instances}
        cellId={props.cellId}
        HAProxyType={props.HAProxyType}
        dragonstoneDomain={props.dragonstoneDomain}
      />
      </ErrorBoundary>

      <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"TicketList"}/>}>
      <Tickets domainIdentifier={props.domainIdentifier} />
      </ErrorBoundary>
    </SpaceBetween>
  );
};

export { Overview };
