import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks/dist/cjs/interfaces';
import Link from '@amzn/awsui-components-react/polaris/link';
export const otsVerifyAllTableColumns = [
  {
    id: 'account_id',
    cell: (e) => (
      <Link href={`/ops-transparency/onboarding/${e.account_id}`}>
        {e.account_id}
      </Link>
    ),
    sortingfield: 'account_id',
    header: 'Account Id',
  },
  {
    id: 'domain_id',
    cell: (e) => e.domain_id,
    sortingfield: 'domain_id',
    header: 'Domain Id',
  },
  {
    id: 'action_required',
    cell: (e) => e.action_required,
    sortingfield: 'action_required',
    header: 'Action Required',
  },
  {
    id: 'current_beta_tokens_attribute',
    cell: (e) => e.current_beta_tokens_attribute,
    sortingfield: 'current_beta_tokens_attribute',
    header: 'Current Beta Tokens Attribute',
  },
  {
    id: 'current_hostclass',
    cell: (e) => e.current_hostclass,
    sortingfield: 'current_hostclass',
    header: 'Current Hostclass',
  },
  {
    id: 'current_ots_state',
    cell: (e) => e.current_ots_state,
    sortingfield: 'current_ots_state',
    header: 'Current Ots State',
  },
  {
    id: 'current_spie_tags',
    cell: (e) => e.current_spie_tags,
    sortingfield: 'current_spie_tags',
    header: 'Current Spie Tags',
  },

  {
    id: 'domain_name',
    cell: (e) => e.domain_name,
    sortingfield: 'domain_name',
    header: 'Domain Name',
  },
  {
    id: 'expected_ots_state',
    cell: (e) => e.expected_ots_state,
    sortingfield: 'expected_ots_state',
    header: 'Expected Ots State',
  },
  {
    id: 'last_update_time',
    cell: (e) => new Date(parseInt(e.last_update_time) * 1000).toISOString(),
    sortingfield: 'last_update_time',
    header: 'Last Update Time',
  },
  {
    id: 'region',
    cell: (e) => e.region,
    sortingfield: 'region',
    header: 'Region',
  },
];

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Main distribution properties',
    options: [
      { id: 'account_id', label: 'account_id' },

      { id: 'action_required', label: 'action_required' },

      {
        id: 'current_beta_tokens_attribute',
        label: 'current_beta_tokens_attribute',
      },
      { id: 'current_hostclass', label: 'current_hostclass' },

      { id: 'current_ots_state', label: 'current_ots_state' },

      { id: 'current_spie_tags', label: 'current_spie_tags' },
      { id: 'domain_id', label: 'domain_id' },

      { id: 'domain_name', label: 'domain_name' },

      { id: 'expected_ots_state', label: 'expected_ots_state' },
      { id: 'last_update_time', label: 'last_update_time' },
      { id: 'region', label: 'region' },
    ],
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10' },
  { value: 30, label: '30' },
  { value: 50, label: '50' },
  { value: 500, label: '500' },
  { value: 1000, label: '1000' },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 10,
  visibleContent: [
    'account_id',
    'action_required',
    'current_beta_tokens_attribute',
    'current_hostclass',
    'current_ots_state',
    'current_spie_tags',
    'domain_id',
    'expected_ots_state',
    'last_update_time',
    'region',
  ],
  wraplines: false,
};

export const FILTERING_PROPERTIES: readonly PropertyFilterProperty[] = [
  {
    propertyLabel: 'Action Required',
    key: 'action_required',
    groupValuesLabel: 'action_required values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Current Beta Tokens Attribute',
    key: 'current_beta_tokens_attribute',
    groupValuesLabel: 'current_beta_tokens_attribute values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Current Hostclass',
    key: 'current_hostclass',
    groupValuesLabel: 'current_hostclass values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Current Ots State',
    key: 'current_ots_state',
    groupValuesLabel: 'current_ots_state Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Current Spie Tags',
    key: 'current_spie_tags',
    groupValuesLabel: 'current_spie_tags values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Expected Ots State',
    key: 'expected_ots_state',
    groupValuesLabel: 'expected_ots_state values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Region',
    key: 'region',
    groupValuesLabel: 'region Values',
    operators: [':', '!:', '=', '!='],
  },
];
