import {
    Button,
    Container,
    DateRangePicker,
    DateRangePickerProps,
    ExpandableSection,
    Header,
    Icon,
    Select,
    SpaceBetween,
    Tabs,
    TextContent
} from '@amzn/awsui-components-react';
import {
    EuiCallOut,
    EuiEmptyPrompt,
    EuiLink
} from '@elastic/eui';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ExternalDashboard } from '../../../Components/Cloudwatch/ExternalDashboard';
import { ExternalWidget } from '../../../Components/Cloudwatch/model';
import { useToastNotifications } from '../../../../hooks/useToastList';
import { AppContext } from '../../../../utils/appContext';
import {
    DAYS_15,
    DAYS_455,
    DAYS_63,
    DAY_1,
    HOURS_3,
    HOUR_1,
    METRIC_RETENTION_INFO,
    MINUTE_1,
    MINUTE_15,
    MINUTE_5,
    SEC_1,
} from './constants';
import {
    getBulkIndexingMetrics,
    getBulkShardIndexingMetrics,
    getCreateIndexMetrics,
    getShardAssignmentMetrics,
    getShardUtilizationMetrics,
    getBulkItemIndexingMetrics,
    getResponseCountMetrics,
    getResponseTimeMetrics,
    i18nStrings,
    periodOptions as periodDropdownOptions,
    relativeOptions,
    timezoneOptions, getIndexingLatency, getRolloverMetrics, getStorageMetrics
} from "./utils";

const LoadGraphs = (props: any) => (
    <EuiEmptyPrompt
        title={<h4>Load cloud watch graphs ?</h4>}
        actions={[
            <Button variant="normal" onClick={props.onClick}>
                Display graphs
            </Button>,
        ]}
    />
);

interface DashboardProps {
    awsAccountId: string;
    collectionId: string;
    shared?: {
        endTime: number; // Epoch time
        startTime: number; // Epoch time
        title: string;
        metricName?: string;
        stats?: string;
        splitByResource?: string;
        dim?: string;
    };
}

const JunoCollectionDashboards = (props: DashboardProps) => {
    const appContext = useContext(AppContext);
    const toastNotifications = useToastNotifications();
    const cloudWatchProps = {
        region: appContext.region.publicRegion,
        rootAccountId: appContext.rootAccountId,
        isOptInRegion: appContext.region.isOptInRegion || false,
    };

    const [loadGraphs, setLoadGraphs] = useState(false);
    const [, setShowFullScreen] = useState<boolean>(false);

    const [date, setDate] = useState<any>({
        amount: 1,
        unit: 'week',
        type: 'relative',
    });

    const [periodOptions, setPeriodOptions] = useState(periodDropdownOptions);

    const [selectedPeriod, setSelectedPeriod] = useState({
        label: '1h',
        value: '3600',
        text: 3600,
    });

    // Valid parsed date from moment
    const [metricRange, setMetricRange] = useState<any>({
        startTime: props.shared
            ? moment(props.shared?.startTime).unix()
            : moment().subtract(1, 'weeks').unix(),
        endTime: props.shared
            ? moment(props.shared?.endTime).unix()
            : moment().unix(),
        period: selectedPeriod.text,
    });

    // Defaults to browser time and not to UTC
    const [utcOffset, setUtcOffset] = useState<number>(0);

    // const [timezoneValue, setTimezoneValue] = useState('Browser Timezone');
    const [selectedOption, setSelectedOption] = React.useState({
        inputDisplay: 'Browser Timezone',
        label: 'Browser Timezone',
        value: 'Browser Timezone',
        text: 0,
    });

    const handleDateChange = useCallback(
        ({ detail }) => {
            var startMoment, endMoment;

            if ('absolute' === detail.value.type) {
                startMoment = moment(detail.value.startDate);
                endMoment = moment(detail.value.endDate);
            } else if ('relative' === detail.value.type) {
                startMoment = moment().subtract(detail.value.amount, detail.value.unit);
                endMoment = moment();
            }

            if (
                !startMoment ||
                !startMoment.isValid() ||
                startMoment.isBefore(moment().subtract(14, 'months'))
            ) {
                toastNotifications.addDanger(
                    'Invalid start date, it can not be older than 14 months'
                );
                return;
            }

            if (!endMoment || !endMoment.isValid() || endMoment.isAfter(moment())) {
                toastNotifications.addDanger(
                    'Invalid end date, it can not be future date'
                );
                return;
            }

            var duration = moment.duration(endMoment.diff(startMoment)).asSeconds();
            var newPeriodOptions = periodDropdownOptions.map((a) => {
                return { ...a };
            });

            let disableOption = (optionToBeDisabled) => {
                // eslint-disable-next-line
                newPeriodOptions.find((option, i) => {
                    if (option.text === optionToBeDisabled) {
                        newPeriodOptions[i]['disabled'] = true;
                        return true;
                    }
                });
            };

            let setPeriod = (periodToBeSet) => {
                setSelectedPeriod(
                    periodOptions.find((option) => option.text === periodToBeSet)
                );
            };

            // metric retention conditions to disable period options
            if (duration <= HOURS_3) {
                // setPeriod(MINUTE_1);
            }
            if (duration > HOURS_3 && duration <= DAYS_15) {
                disableOption(SEC_1);
                // setPeriod(MINUTE_1);
            }
            if (duration > DAYS_15 && duration <= DAYS_63) {
                disableOption(SEC_1);
                disableOption(MINUTE_1);
                // setPeriod(MINUTE_5);
            }
            if (duration > DAYS_63 && duration <= DAYS_455) {
                disableOption(SEC_1);
                disableOption(MINUTE_1);
                disableOption(MINUTE_5);
                // setPeriod(HOUR_1);
            }
            if (duration > DAYS_455) {
                disableOption(SEC_1);
                disableOption(MINUTE_1);
                disableOption(MINUTE_5);
                disableOption(MINUTE_15);
                disableOption(HOUR_1);
                // setPeriod(DAY_1);
            }

            // disabling period options greater than the selected duration
            if (duration < DAY_1) {
                disableOption(DAY_1);
            }
            if (duration < HOUR_1) {
                disableOption(HOUR_1);
            }
            if (duration < MINUTE_15) {
                disableOption(MINUTE_15);
            }
            if (duration < MINUTE_5) {
                disableOption(MINUTE_5);
            }
            if (duration < MINUTE_1) {
                disableOption(MINUTE_1);
            }

            // setting period for default relative date options
            if ('relative' === detail.value.type) {
                let val = detail.value.amount + ' ' + detail.value.unit;
                if (val === '6 hour' || val === '1 day') {
                    setPeriod(MINUTE_1);
                }
                if (val === '3 day' || val === '1 week') {
                    setPeriod(MINUTE_5);
                }
                if (val === '3 week') {
                    setPeriod(MINUTE_15);
                }
            }

            setPeriodOptions(newPeriodOptions);
            setDate(detail.value);
            setMetricRange({
                startTime: startMoment.unix(),
                endTime: endMoment.unix(),
                period: selectedPeriod.text,
            });
        },
        [selectedPeriod, periodOptions, toastNotifications]
    );

    const isValidRange: DateRangePickerProps.ValidationFunction = (e) => {
        const t = (start, end) => {
            const a = moment(end).isBefore(moment(start));
            return a;
        };
        if ('absolute' === e.type) {
            const [a] = e.startDate.split('T'),
                [n] = e.endDate.split('T');
            if (!a || !n)
                return {
                    valid: !1,
                    errorMessage:
                        'The selected date range is incomplete. Select a start and end date for the date range.',
                };
            if (t(e.startDate, e.endDate))
                return {
                    valid: !1,
                    errorMessage: 'The end date must be greater than the start date.',
                };
        } else if ('relative' === e.type) {
            if (isNaN(e.amount))
                return {
                    valid: !1,
                    errorMessage:
                        'The selected date range is incomplete. Specify a duration for the date range.',
                };
        }
        return { valid: !0 };
    };

    const handleTimezoneChange = (selectedOption) => {
        setUtcOffset(selectedOption.text);
        setSelectedOption(selectedOption);
    };

    const handlePeriodChange = (selectedPeriodOption) => {
        setSelectedPeriod(selectedPeriodOption);
    };

    useEffect(() => {
        var startTime = metricRange.startTime;
        var endTime = metricRange.endTime;
        var period = selectedPeriod.text;
        var newMetric = { startTime: startTime, endTime: endTime, period: period };
        setMetricRange(newMetric);
    }, [selectedPeriod, metricRange.startTime, metricRange.endTime]);

    const getAllIndexingMetrics = () => [
        ...(getBulkIndexingMetrics(
            props.awsAccountId,
            props.collectionId,
            appContext.region.publicRegion,
            false
        ) as ExternalWidget[]),
        ...(getBulkItemIndexingMetrics(
            props.awsAccountId,
            props.collectionId,
            appContext.region.publicRegion,
            false
        ) as ExternalWidget[]),
        ...(getBulkShardIndexingMetrics(
            props.awsAccountId,
            props.collectionId,
            appContext.region.publicRegion,
            false
        ) as ExternalWidget[]),
        ...(getCreateIndexMetrics(
            props.awsAccountId,
            props.collectionId,
            appContext.region.publicRegion,
            false
        ) as ExternalWidget[]),
        ...(getShardUtilizationMetrics(
            props.awsAccountId,
            props.collectionId,
            appContext.region.publicRegion,
            false
        ) as ExternalWidget[]),
        ...(getShardAssignmentMetrics(
            props.awsAccountId,
            props.collectionId,
            appContext.region.publicRegion,
            false
        ) as ExternalWidget[]),
    ];

    const getAllSearchMetrics = () => [
        ...(getResponseCountMetrics(
            props.awsAccountId,
            props.collectionId,
            appContext.region.publicRegion,
            false
        ) as ExternalWidget[]),
        ...(getResponseTimeMetrics(
            props.awsAccountId,
            props.collectionId,
            appContext.region.publicRegion,
            false
        ) as ExternalWidget[]),
    ];

    const getAllMetrics = () => [
        ...(getBulkIndexingMetrics(
            props.awsAccountId,
            props.collectionId,
            appContext.region.publicRegion,
            false
        ) as ExternalWidget[]),
        ,
        ...(getBulkItemIndexingMetrics(
            props.awsAccountId,
            props.collectionId,
            appContext.region.publicRegion,
            false
        ) as ExternalWidget[]),
        ...(getBulkShardIndexingMetrics(
            props.awsAccountId,
            props.collectionId,
            appContext.region.publicRegion,
            false
        ) as ExternalWidget[]),
        ...(getIndexingLatency(
            props.awsAccountId,
            props.collectionId,
            appContext.region.publicRegion,
            false
        ) as ExternalWidget[]),
        ...(getCreateIndexMetrics(
            props.awsAccountId,
            props.collectionId,
            appContext.region.publicRegion,
            false
        ) as ExternalWidget[]),
        ...(getRolloverMetrics(
            props.awsAccountId,
            props.collectionId,
            appContext.region.publicRegion,
            false
        ) as ExternalWidget[]),
        ...(getStorageMetrics(
            props.awsAccountId,
            props.collectionId,
            appContext.region.publicRegion,
            false
        ) as ExternalWidget[]),
        ...(getShardUtilizationMetrics(
            props.awsAccountId,
            props.collectionId,
            appContext.region.publicRegion,
            false
        ) as ExternalWidget[]),
        ...(getShardAssignmentMetrics(
            props.awsAccountId,
            props.collectionId,
            appContext.region.publicRegion,
            false
        ) as ExternalWidget[]),
        ...(getResponseCountMetrics(
            props.awsAccountId,
            props.collectionId,
            appContext.region.publicRegion,
            false
        ) as ExternalWidget[]),
    ];
    const tabs = [
        {
            id: 'indexing',
            name: 'Indexing',
            label: 'Indexing',
            content: loadGraphs ? (
                <div>
                    <ExternalDashboard
                        widgets={getAllIndexingMetrics()}
                        metricRange={metricRange}
                        utcOffset={utcOffset}
                    />
                </div>
            ) : (
                <LoadGraphs onClick={() => setLoadGraphs(true)} />
            ),
            disabled: false,
        },
        {
            id: 'search',
            name: 'Search',
            label: 'Search',
            content: loadGraphs ? (
                <div>
                    <ExternalDashboard
                        widgets={getAllSearchMetrics()}
                        metricRange={metricRange}
                        utcOffset={utcOffset}
                    />
                </div>
            ) : (
                <LoadGraphs onClick={() => setLoadGraphs(true)} />
            ),
            disabled: false,
        },
    ];

    const renderSharedMetrics = () => {
        const selectedMetric = getAllMetrics().find(
            (metric) => metric.title === props.shared?.title.replace(/_/g, ' ')
        );
        if (selectedMetric) {
            return (
                <ExternalDashboard
                    widgets={[selectedMetric]}
                    metricRange={metricRange}
                    utcOffset={utcOffset}
                />
            );
        } else {
            return (
                <EuiCallOut
                    title={
                        <span>
                          Metric <b>{props.shared?.title}</b> is not valid, ensure this
                          metric is supported by Tumbler
                        </span>
                    }
                    color="warning"
                    iconType="alert"
                    size="m"
                >
                    <p>
                        {' '}
                        Navigate to Account overview{' '}
                        <EuiLink href={`#/${props.awsAccountId}`}>
                            {props.awsAccountId}
                        </EuiLink>{' '}
                        for more details.
                    </p>
                </EuiCallOut>
            );
        }
    };
    return (
        <Container
            disableContentPaddings
            header={
                <Header
                    variant="h2"
                    actions={
                        <SpaceBetween direction="horizontal" size="s">
                            <DateRangePicker
                                value={date}
                                i18nStrings={i18nStrings}
                                placeholder="Filter by a date and time range"
                                onChange={handleDateChange}
                                relativeOptions={relativeOptions}
                                isValidRange={isValidRange}
                                showClearButton={false}
                            />
                            <TextContent>
                                <strong>Period :</strong>
                            </TextContent>
                            <Select
                                selectedOption={selectedPeriod}
                                onChange={({ detail }) =>
                                    handlePeriodChange(detail.selectedOption)
                                }
                                options={periodOptions}
                                selectedAriaLabel="Selected"
                            />
                            <TextContent>
                                <strong>Timezone :</strong>
                            </TextContent>
                            <Select
                                selectedOption={selectedOption}
                                onChange={({ detail }) =>
                                    handleTimezoneChange(detail.selectedOption)
                                }
                                options={timezoneOptions}
                                selectedAriaLabel="Selected"
                            />
                            <Button
                                variant="link"
                                onClick={() =>
                                    setShowFullScreen(
                                        (latestFullScreen: boolean) => !latestFullScreen
                                    )
                                }
                            >
                                <Icon name="view-full" size="normal" variant="normal" />
                            </Button>
                        </SpaceBetween>
                    }
                >
                    JunoAccountDashboards
                </Header>
            }
        >
            <ExpandableSection
                header="Retention period of all metrics"
                className="expandableSection"
            >
                {METRIC_RETENTION_INFO.map((row) => (
                    <>
                        {row}
                        <br />
                    </>
                ))}
            </ExpandableSection>
            {props.shared ? renderSharedMetrics() : <Tabs tabs={tabs} />}
        </Container>
    );
};

export { JunoCollectionDashboards };
