import {
  Button,
  Header,
  Pagination,
  PropertyFilter,
  SpaceBetween,
  Table,
} from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import React, { useState } from 'react';
import {
  EmptyState,
  getFilterCounterText,
  Preferences,
  PAGE_SIZE_OPTIONS,
} from './../../utils/tablePreferences';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from '../../utils/constants';
import {
  auditHistoryColumns,
  FILTERING_PROPERTIES,
  VISIBLE_CONTENT_OPTIONS,
  DEFAULT_PREFERENCES,
} from './../constants';
import { ExportCSV } from '../../utils/exportCSV';

const AuditHistoryViewer = (props) => {
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(
    props.commandHistoryRecords.fetchCommandHistory === ''
      ? []
      : props.commandHistoryRecords.fetchCommandHistory,
    {
      propertyFiltering: {
        filteringProperties: FILTERING_PROPERTIES,
        empty: (
          <EmptyState
            title="No history found"
            subtitle=""
            action={<span></span>}
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={
              <Button onClick={() => actions.setFiltering('')}>
                Clear filter
              </Button>
            }
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
    }
  );

  return (
    <Table
      {...collectionProps}
      header={
        <div>
          <Header
            counter={`(${items.length})`}
            actions={
              <SpaceBetween direction="horizontal" size="s">
                <ExportCSV
                  data={items}
                  filename={'audit_history.csv'}
                  loading={false}
                />
              </SpaceBetween>
            }
          >
            Command History
          </Header>
          {/* <p>A list of all onboarded accounts across all the regions.</p> */}
        </div>
      }
      columnDefinitions={auditHistoryColumns}
      stickyHeader={true}
      resizableColumns={true}
      wrapLines={preferences.wraplines}
      visibleColumns={preferences.visibleContent}
      items={items}
      pagination={
        <Pagination
          {...paginationProps}
          ariaLabels={{
            nextPageLabel: 'Next page',
            previousPageLabel: 'Previous page',
            pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
          }}
        />
      }
      filter={
        <PropertyFilter
          className="input-filter"
          i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
          {...propertyFilterProps}
          countText={getFilterCounterText(filteredItemsCount)}
        />
      }
      preferences={
        <Preferences
          preferences={preferences}
          setPreferences={setPreferences}
          disabled={false}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          visibleContentOptions={VISIBLE_CONTENT_OPTIONS}
        />
      }
    />
  );
};

export { AuditHistoryViewer };
