import React from 'react';
import { TimezoneToggle } from '../../../Components/TimezoneToggle';

export const columns = [
  {
    id: 'loadBalancers',
    field: 'loadBalancers',
    name: 'Loadbalancers',
    header: 'Loadbalancers',
    cell: (e) => e.loadBalancers.join('\n'),
  },
  {
    id: 'availabilityZones',
    field: 'availabilityZones',
    name: 'Availability zones',
    header: 'Availability zones',
    cell: (e) => (
      <div>
        {e.availabilityZones.map((az) => (
          <span>{az}</span>
        ))}
      </div>
    ),
  },
  {
    id: 'instanceCount',
    field: 'instanceCount',
    name: 'Instance count',
    header: 'Instance count',
    cell: (e) => e.instanceCount,
  },
  {
    id: 'nodeType',
    field: 'nodeType',
    name: 'Group type',
    header: 'Group type',
    cell: (e) => e.nodeType,
  },
  {
    id: 'dateTime',
    field: 'dateTime',
    name: 'Created time',
    header: 'Created time',
    cell: (e) => <TimezoneToggle date={new Date(e.dateTime)} />,
  },
];

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Options',
    options: [
      { id: 'groupName', label: 'groupName' },
      {
        id: 'loadbalancerUrl',
        label: 'Loadbalancer Url',
      },
      {
        id: 'loadBalancers',
        label: 'loadBalancers',
      },
      { id: 'availabilityZones', label: 'availabilityZones' },
      { id: 'instanceCount', label: 'instanceCount' },
      { id: 'nodeType', label: 'nodeType' },
      { id: 'dateTime', label: 'dateTime' },
    ],
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 Scaling Groups' },
  { value: 30, label: '30 Scaling Groups' },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 30,
  visibleContent: [
    'groupName',
    'loadbalancerUrl',
    'loadBalancers',
    'availabilityZones',
    'instanceCount',
    'nodeType',
    'dateTime',
  ],
  wraplines: true,
};
