import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks/dist/cjs/interfaces';
import {
  CollectionPreferences,
  StatusIndicator,
} from '@amzn/awsui-components-react';

// Columns for Snapshots Info table
export const snapshotColumns = [
  {
    field: 'id',
    id: 'id',
    name: 'id',
    header: 'ID',
    sortable: true,
    cell: (e) => e.id,
    minWidth: 200,
    sortingField: 'id',
  },
  {
    field: 'status',
    id: 'status',
    name: 'status',
    header: 'Status',
    sortable: true,
    cell: (e) => (
      <StatusIndicator type={e.status === 'SUCCESS' ? 'success' : 'error'}>
        {e.status}
      </StatusIndicator>
    ),
    sortingField: 'status',
  },
  {
    field: 'start_epoch',
    id: 'start_epoch',
    name: 'Start time',
    header: 'Start time',
    sortable: true,
    render: (startEpoch: string) => {
      let date = new Date(0);
      date.setUTCSeconds(Number(startEpoch));
      return date.toString();
    },
    cell: (e) => {
      let date = new Date(0);
      date.setUTCSeconds(Number(e.start_epoch));
      return date.toString();
    },
    sortingField: 'start_epoch',
  },
  {
    field: 'end_epoch',
    id: 'end_epoch',
    name: 'End time',
    header: 'End time',
    sortable: true,
    render: (endEpoch: string) => {
      let date = new Date(0);
      date.setUTCSeconds(Number(endEpoch));
      return date.toString();
    },
    cell: (e) => {
      let date = new Date(0);
      date.setUTCSeconds(Number(e.end_epoch));
      return date.toString();
    },
    sortingField: 'end_epoch',
  },
  {
    field: 'duration',
    id: 'duration',
    name: 'duration',
    header: 'Duration',
    cell: (e) => e.duration,
    sortingField: 'duration',
  },
  {
    field: 'indices',
    id: 'indices',
    name: 'indices',
    header: 'Indices',
    sortable: true,
    cell: (e) => e.indices,
    sortingField: 'indices',
  },
  {
    field: 'successful_shards',
    id: 'successful_shards',
    name: 'successful_shards',
    header: 'Successful Shards',
    sortable: true,
    cell: (e) => e.successful_shards,
    sortingField: 'successful_shards',
  },
  {
    field: 'failed_shards',
    id: 'failed_shards',
    name: 'failed_shards',
    header: 'Failed Shards',
    sortable: true,
    cell: (e) => e.failed_shards,
    sortingField: 'failed_shards',
  },
  {
    field: 'total_shards',
    id: 'total_shards',
    name: 'total_shards',
    header: 'Total Shards',
    sortable: true,
    cell: (e) => e.total_shards,
    sortingField: 'total_shards',
  },
];

const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Main distribution properties',
    options: [
      {
        id: 'id',
        label: 'id',
      },
      { id: 'status', label: 'status' },
      { id: 'start_epoch', label: 'start_epoch' },
      {
        id: 'end_epoch',
        label: 'end_epoch',
      },
      {
        id: 'duration',
        label: 'duration',
      },
      {
        id: 'indices',
        label: 'indices',
      },
      {
        id: 'successful_shards',
        label: 'successful_shards',
      },
      {
        id: 'failed_shards',
        label: 'failed_shards',
      },
      {
        id: 'total_shards',
        label: 'total_shards',
      },
    ],
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 Snapshots' },
  { value: 30, label: '30 Snapshots' },
  { value: 50, label: '50 Snapshots' },
  { value: 100, label: '100 Snapshots' },
  { value: 500, label: '500 Snapshots' },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 30,
  visibleContent: [
    'id',
    'status',
    'start_epoch',
    'end_epoch',
    'duration',
    'indices',
    // 'successful_shards',
    // 'failed_shards',
    // 'total_shards',
  ],
  wraplines: true,
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: 'Page size',
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: 'Wrap lines',
      description: 'Check to see all the text and wrap the lines',
    }}
    visibleContentPreference={{
      title: 'Select visible columns',
      options: visibleContentOptions,
    }}
  />
);

export const getFilterCounterText = (count) =>
  `${count} ${count === 1 ? 'match' : 'matches'}`;

export const FILTERING_PROPERTIES: readonly PropertyFilterProperty[] = [
  {
    propertyLabel: 'Status',
    key: 'status',
    groupValuesLabel: 'status Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'ID',
    key: 'id',
    groupValuesLabel: 'ID values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Duration',
    key: 'duration',
    groupValuesLabel: 'Duration Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Start Time',
    key: 'start_epoch',
    groupValuesLabel: 'Start Time Values',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'End Time',
    key: 'end_epoch',
    groupValuesLabel: 'End Time Values',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
];
