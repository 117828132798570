import { gql, useQuery } from '@apollo/client';
import { AnyObject } from '../../../../models/types';

export interface GetSopDataVars {
  query: string;
}

const GET_TICKETS = gql`
  query GetSopData($query: String!) {
    getSopData(query: $query)
  }
`;

export const useSopQuery = (vars: GetSopDataVars) => {
  return useQuery<AnyObject, GetSopDataVars>(GET_TICKETS, {
    variables: { ...vars },
  });
};
