import React, {useEffect} from "react";
import {useGetAccessPolicyQuery} from "../../hooks/Account/useGetAccessPolicyQuery";
import {useParams} from "react-router-dom";
import {JunoPolicyDetailsParams} from "../../../models/types";
import {Box, ColumnLayout, Container, Header, SpaceBetween} from "@amzn/awsui-components-react";
import {TimezoneToggle} from "../../../Components/TimezoneToggle";
import ReactJson from "react-json-view";

const ValueWithLabel = ({ label, children }) => (
    <div>
        <Box margin={{ bottom: 'xxxs' }} color="text-label">
            {label}
        </Box>
        <div>{children}</div>
    </div>
);

const AccessPolicyDetails = (props) => {
    const { awsAccountId, policyType, policyName} = useParams<JunoPolicyDetailsParams>();

    const [
        getAccessPolicy,
        { data: AccessPolicyData },
    ] = useGetAccessPolicyQuery();

    useEffect(() => {
        getAccessPolicy({
            variables: {
                awsAccountId: awsAccountId,
                type: policyType,
                name: policyName
            },
        });

    }, [awsAccountId, policyType, policyName]);

    const isLoaded = () => {
        if (AccessPolicyData &&
            AccessPolicyData.getAccessPolicy &&
            AccessPolicyData.getAccessPolicy.accessPolicyDetail)
        {
            return true;
        }
        return false;
    }

    return (
        <SpaceBetween direction="vertical" size="l">
            <Container header={<Header variant="h2">Policy Information</Header>}>
                <ColumnLayout columns={3} variant="text-grid">
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Name">
                            {
                                isLoaded() && AccessPolicyData.getAccessPolicy.accessPolicyDetail.name ?
                                    AccessPolicyData.getAccessPolicy.accessPolicyDetail.name
                                    :
                                    '--'
                            }
                        </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Description">
                            {
                                isLoaded() && AccessPolicyData.getAccessPolicy.accessPolicyDetail.description ?
                                    AccessPolicyData.getAccessPolicy.accessPolicyDetail.description
                                    :
                                    '--'
                            }
                        </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Creation Date">
                            {
                                isLoaded() && AccessPolicyData.getAccessPolicy.accessPolicyDetail.createdDate ?
                                    <TimezoneToggle
                                        date={new Date(AccessPolicyData.getAccessPolicy.accessPolicyDetail.createdDate)}/>
                                    :
                                    '--'
                            }
                        </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Last Modified Date">
                            {
                                isLoaded() && AccessPolicyData.getAccessPolicy.accessPolicyDetail.lastModifiedDate ?
                                    <TimezoneToggle
                                        date={new Date(AccessPolicyData.getAccessPolicy.accessPolicyDetail.lastModifiedDate)}/>
                                    :
                                    '--'
                            }
                        </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Version">
                            {
                                isLoaded() && AccessPolicyData.getAccessPolicy.accessPolicyDetail.policyVersion ?
                                    AccessPolicyData.getAccessPolicy.accessPolicyDetail.policyVersion
                                    :
                                    '--'
                            }
                        </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Type">
                            {
                                isLoaded() ?
                                    'data'
                                    :
                                    '--'
                            }
                        </ValueWithLabel>
                    </SpaceBetween>
                </ColumnLayout>
            </Container>


            {
                isLoaded() && AccessPolicyData.getAccessPolicy.accessPolicyDetail ?
                    <Container header={<Header variant="h2">Policy Document</Header>}>
                        <ReactJson
                            src={AccessPolicyData.getAccessPolicy}
                            name={false}
                            displayDataTypes={false}
                            displayObjectSize={false}
                            enableClipboard={false}
                        />
                    </Container>
                    :
                    ''
            }

        </SpaceBetween>
    )

};

export { AccessPolicyDetails };
