import { useCollection } from '@amzn/awsui-collection-hooks';
import {
    Button,
    DateRangePicker,
    DateRangePickerProps,
    Header,
    Pagination,
    PropertyFilter,
    Select,
    SpaceBetween,
    Table,
    TextContent,
} from '@amzn/awsui-components-react';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from '../../../../utils/constants';
import { EmptyState } from '../../../../utils/tablePreferences';
import { useGetScalingAuditHistoryQuery } from '../../../hooks/Worker/useGetScalingAuditHistoryQuery';
import {
    columns,
    DEFAULT_PREFERENCES,
    FILTERING_PROPERTIES,
    getFilterCounterText,
    i18nStrings,
    PAGE_SIZE_OPTIONS,
    Preferences,
    relativeOptions,
    timezoneOptions,
    VISIBLE_CONTENT_OPTIONS,
} from './utils';
import {ExportCSV} from "../../../../utils/exportCSV";

const ScalingAuditHistory = (props) => {
    const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
    const [currentDate, setCurrentDate] = React.useState<any>({
        type: 'relative',
        amount: 1,
        unit: 'day',
    });

    const [selectedTimeZoneOption, setSelectedTimeZoneOption] = React.useState({
        inputDisplay: 'Browser Timezone',
        label: 'Browser Timezone',
        value: 'Browser Timezone',
        code: '',
    });

    const currTime = moment().valueOf().toString();
    const dayAgo = moment().subtract(1, 'days').valueOf().toString();
    const awsAccountId = props.awsAccountId;
    const placementConstraintId = props.placementConstraintId;
    const dataPlaneType = props.dataPlaneType.toLowerCase();
    const namespace = props.namespace;

    var [getScalingAuditHistory, { loading, data: scalingAuditHistory }] =
        useGetScalingAuditHistoryQuery();

    useEffect(() => {
        getScalingAuditHistory({
            variables: {
                awsAccountId: awsAccountId,
                placementConstraintId: placementConstraintId,
                dataPlaneType: dataPlaneType,
                namespace: namespace,
                startTime: dayAgo,
                endTime: currTime,
            },
        });
    }, [getScalingAuditHistory, ]);

    const convertTZ = (timestamp, tzOption) => {
        const tz = tzOption.value;
        timestamp = parseInt(timestamp);
        if (tz === 'Browser Timezone') {
            return new Date(timestamp).toLocaleString('en-US', {
                hour12: false,
                timeZoneName: 'short',
            });
        }
        return (
            new Date(timestamp).toLocaleString('en-US', {
                timeZone: tz,
                hour12: false,
            }) +
            ' ' +
            tzOption.code
        );
    };

    const handleTimezoneChange = (selectedOption) => {
        setSelectedTimeZoneOption(selectedOption);
    };

    const handleDateChange = ({ detail }) => {
        var startMoment, endMoment;

        if ('absolute' === detail.value.type) {
            startMoment = moment(detail.value.startDate);
            endMoment = moment(detail.value.endDate);
        } else if ('relative' === detail.value.type) {
            startMoment = moment().subtract(detail.value.amount, detail.value.unit);
            endMoment = moment();
        }

        getScalingAuditHistory({
            variables: {
                awsAccountId: awsAccountId,
                placementConstraintId: placementConstraintId,
                dataPlaneType: dataPlaneType,
                namespace: namespace,
                startTime: startMoment.valueOf().toString(),
                endTime: endMoment.valueOf().toString(),
            },
        });

        setCurrentDate(detail.value);
    };

    const isValidRange: DateRangePickerProps.ValidationFunction = (e) => {
        const t = (start, end) => {
            const a = moment(end).isBefore(moment(start));
            return a;
        };
        if ('absolute' === e.type) {
            const [a] = e.startDate.split('T'),
                [n] = e.endDate.split('T');
            if (!a || !n)
                return {
                    valid: !1,
                    errorMessage:
                        'The selected date range is incomplete. Select a start and end date for the date range.',
                };
            if (
                moment(e.startDate).isAfter(moment()) ||
                moment(e.endDate).isAfter(moment())
            )
                return {
                    valid: !1,
                    errorMessage:
                        'Either of start date or end date cannot be future date.',
                };
            if (t(e.startDate, e.endDate))
                return {
                    valid: !1,
                    errorMessage: 'The end date must be greater than the start date.',
                };
        } else if ('relative' === e.type) {
            if (isNaN(e.amount))
                return {
                    valid: !1,
                    errorMessage:
                        'The selected date range is incomplete. Specify a duration for the date range.',
                };
        }
        return { valid: !0 };
    };

    let events = get(scalingAuditHistory, 'getScalingAuditHistory', []);
    events = events ? events: [];

    // console.log(events[events.length-1])
    let showLimitMessage = false;
    if (events.length > 0) {
        showLimitMessage = events[events.length - 1]['resultTruncated']
            ? true
            : false;
    }

    const {
        items,
        actions,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
    } = useCollection(loading ? [] : events, {
        propertyFiltering: {
            filteringProperties: FILTERING_PROPERTIES,
            empty: (
                <EmptyState
                    title="No records"
                    subtitle="No records to display."
                    action={<span></span>}
                />
            ),
            noMatch: (
                <EmptyState
                    title="No matches"
                    subtitle="We can’t find a match."
                    action={
                        <Button onClick={() => actions.setFiltering('')}>
                            Clear filter
                        </Button>
                    }
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {},
    });

    const table_header = `${props.dataPlaneType} Scaling Audit History`

    return (
        <>
            <div>
                {/* <Container>
          Below is the table containing domain events fetched from DDB.
        </Container> */}
                <div>
                    <Table
                        {...collectionProps}
                        header={
                            <div>
                                <Header
                                    counter={`(${items.length} of ${filteredItemsCount})`}
                                    actions={
                                        <SpaceBetween direction="horizontal" size="s">
                                            <DateRangePicker
                                                onChange={handleDateChange}
                                                value={currentDate}
                                                expandToViewport={true}
                                                relativeOptions={relativeOptions}
                                                isValidRange={isValidRange}
                                                i18nStrings={i18nStrings}
                                                placeholder="Filter by a date and time range"
                                            />
                                            <TextContent>
                                                <strong>Timezone :</strong>
                                            </TextContent>
                                            <Select
                                                selectedOption={selectedTimeZoneOption}
                                                onChange={({ detail }) =>
                                                    handleTimezoneChange(detail.selectedOption)
                                                }
                                                options={timezoneOptions}
                                                selectedAriaLabel="Selected"
                                            />
                                            <ExportCSV
                                                data={items}
                                                filename={props.csvExportFileName}
                                                loading={false}
                                            />
                                        </SpaceBetween>
                                    }
                                >
                                {table_header}
                                </Header>
                                {showLimitMessage && (
                                    <p>
                                        There are more than {events.length - 1} actions and we
                                        are displaying the latest {events.length - 1} actions
                                    </p>
                                )}
                            </div>
                        }
                        columnDefinitions={[
                            {
                                id: 'ActionId',
                                header: 'Event Timestamp',
                                sortingField: 'ActionId',
                                cell: (e) => {
                                    var actionId = e['ActionId'];
                                    var timestamp = parseInt(actionId.substring(0,13));
                                    return convertTZ(timestamp, selectedTimeZoneOption);
                                },
                            },
                            ...columns,
                        ]}
                        stickyHeader={true}
                        resizableColumns={true}
                        wrapLines={preferences.wraplines}
                        loading={loading}
                        loadingText={
                            loading
                                ? 'Loading records...'
                                : 'There were no records.'
                        }
                        visibleColumns={preferences.visibleContent}
                        items={items}
                        pagination={
                            <Pagination
                                {...paginationProps}
                                ariaLabels={{
                                    nextPageLabel: 'Next page',
                                    previousPageLabel: 'Previous page',
                                    pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
                                }}
                            />
                        }
                        filter={
                            <div className="input-container">
                                <PropertyFilter
                                    className="input-filter"
                                    expandToViewport={true}
                                    i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                                    {...propertyFilterProps}
                                    countText={getFilterCounterText(filteredItemsCount)}
                                />
                            </div>
                        }
                        preferences={
                            <Preferences
                                preferences={preferences}
                                setPreferences={setPreferences}
                                disabled={false}
                                pageSizeOptions={PAGE_SIZE_OPTIONS}
                                visibleContentOptions={VISIBLE_CONTENT_OPTIONS}
                            />
                        }
                    />
                </div>
            </div>
        </>
    );
};

export { ScalingAuditHistory };
