import { Box } from '@amzn/awsui-components-react';
import React from 'react';

const JunoRegionNotSupported = (props) => {
  return (
    <Box variant="h3" margin="m" color="text-status-info">
      Juno logs not supported in this stage and/or region!
    </Box>
  );
};

export { JunoRegionNotSupported };
