import { EuiAvatar, EuiHeaderSectionItemButton } from '@elastic/eui';
import { Hub } from 'aws-amplify';
import { useContext, useEffect, useState } from 'react';
import { cognitConfig } from '../../../amplify';
import { AppContext } from '../../../utils/appContext';
import { getCookie } from '../../../utils/helpers';
export const TumblerAvatar = () => {
  const appContext = useContext(AppContext);
  let stage = appContext.stage
  if(appContext.region.publicRegion == "us-gov-west-1" || appContext.region.publicRegion == "us-gov-east-1")
  {
    stage = "prodGov"
  }
  const cookieName = `CognitoIdentityServiceProvider.${
    cognitConfig[stage].userPoolWebClientId
  }.LastAuthUser`;
  const [userName, setUsername] = useState(
    (getCookie(cookieName) || '').split('_')[1] || 'anyonymus'
  );
  useEffect(() => {
    Hub.listen('auth', (data) => {
      console.log('hmmmm?');
      if (data.payload.data.username) {
        setUsername(data.payload.data.username.split('_')[1] || '');
      }
    });
  }, []);
  return (
    <div style={{ position: 'relative', top: '-15%' }}>
      <EuiHeaderSectionItemButton aria-label="Search">
        <EuiAvatar
          size="m"
          name={userName}
          imageUrl={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${userName}`}
        />
      </EuiHeaderSectionItemButton>
    </div>
  );
};
