import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks/dist/cjs/interfaces';
import { EuiHealth } from '@elastic/eui';
import { TimezoneToggle } from '../../../Components/TimezoneToggle';
import CopyText from './../../../utils/copy-text';

export interface dateValidator {
  valid: boolean;
  errorMessage?: string;
}

const ec2State = {
  running: {
    color: 'success',
    text: 'Healthy',
  },
  terminated: {
    color: 'danger',
    text: 'Terminated',
  },
  rebooting: {
    color: 'warning',
    text: 'Rebooting',
  },
  pending: {
    color: 'warning',
    text: 'Pending',
  },
  stopping: {
    color: 'warning',
    text: 'Stopping',
  },
  'shutting-down': {
    color: 'danger',
    text: 'Shutting down',
  },
  stopped: {
    color: 'danger',
    text: 'Stopped',
  },
  unknown: {
    color: 'subdued',
    text: 'N/A',
  },
} as { [key: string]: any };

const elbState = {
  healthy: {
    color: 'success',
    text: 'Healthy',
  },
  unhealthy: {
    color: 'danger',
    text: 'Unhealthy',
  },
  initial: {
    color: 'warning',
    text: 'Initial',
  },
  draining: {
    color: 'warning',
    text: 'Draining',
  },
  unused: {
    color: 'warning',
    text: 'Unused',
  },
  unavailable: {
    color: 'danger',
    text: 'Unavailable',
  },
  unknown: {
    color: 'subdued',
    text: 'N/A',
  },
} as { [key: string]: any };

const azState = {
  '1.0': {
    color: 'success',
    text: 'active',
  },
  '0.0': {
    color: 'warning',
    text: 'standby',
  },
} as { [key: string]: any };

export const columns = [
  {
    name: 'DI No.',
    header: 'DI',
    id: 'diNumber',
    sortingField: 'diNumber',
    cell: (e) => <span>{`${e.diNumber}`} </span>,
  },
  {
    id: 'createdTime',
    sortingField: 'createdTime',
    name: 'Created Time',
    header: 'Created Time',
    cell: (e) => <TimezoneToggle date={new Date(e.createdTime)} />,
  },
  {
    id: 'instanceType',
    sortingField: 'instanceType',
    name: 'Instance type',
    header: 'Instance type',
    cell: (e) => e.instanceType,
  },
  {
    name: 'Node type',
    header: 'Node type',
    id: 'nodeType',
    sortingField: 'nodeType',
    cell: (e) => <span>{e.nodeType} </span>,
  },
  {
    name: 'Public IP',
    header: 'Public IP',
    id: 'publicIp',
    sortingField: 'publicIp',
    cell: (e) => (
      <CopyText
        copyText={e.publicIp}
        copyButtonLabel={e.publicIp}
        successText="publicIp copied"
        errorText="publicIp failed to copy"
      />
    ),
  },
  {
    name: 'Private IP',
    header: 'Private IP',
    id: 'privateIp',
    sortingField: 'privateIp',
    cell: (e) => (
      <CopyText
        copyText={e.privateIp}
        copyButtonLabel={e.privateIp}
        successText="privateIp copied"
        errorText="privateIp failed to copy"
      />
    ),
  },
  {
    id: 'state',
    sortingField: 'state',
    name: 'EC2 State',
    header: 'EC2 State',
    cell: (e) => (
      <span>
        <EuiHealth color={`${ec2State[e.state].color}`}></EuiHealth>
        {ec2State[e.state].text}{' '}
      </span>
    ),
  },
  {
    id: 'elbHealth',
    sortingField: 'elbHealth',
    name: 'ELB',
    header: 'ELB',
    cell: (e) => (
      <span>
        <EuiHealth color={`${elbState[e.elbHealth].color}`}></EuiHealth>
        {elbState[e.elbHealth].text}{' '}
      </span>
    ),
  },
  {
    id: 'asg',
    sortingField: 'asg',
    name: 'ASG',
    header: 'ASG',
    textAlign: 'center',
    cell: (e) => <span>{e.asg.split('/')[3]}</span>,
  },
  {
    id: 'azState',
    sortingField: 'azState',
    name: 'AZ State',
    header: 'AZ State',
    cell: (e) => (
      <span>
        <EuiHealth color={`${azState[e.azState].color}`}></EuiHealth>
        {azState[e.azState].text}{' '}
      </span>
    ),
  },
  {
    id: 'az',
    sortingField: 'az',
    name: 'AZ',
    header: 'AZ',
    cell: (e) => <span>{e.az}</span>,
  },
];

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Options',
    options: [
      { id: 'instanceId', label: 'InstanceId' },
      { id: 'createdTime', label: 'Created Time' },
      { id: 'diNumber', label: 'DI Number' },
      { id: 'instanceType', label: 'Instance Type' },
      { id: 'nodeType', label: 'Node Type' },
      { id: 'publicIp', label: 'Public Ip' },
      { id: 'privateIp', label: 'Private Ip' },
      { id: 'bastion', label: 'Bastion' },
      { id: 'state', label: 'State' },
      { id: 'elbHealth', label: 'ELB Health' },
      { id: 'asg', label: 'ASG' },
      { id: 'az', label: 'AZ' },
      { id: 'azState', label: 'AZ State' },
    ],
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10' },
  { value: 30, label: '30' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 500, label: '500' },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 10,
  visibleContent: [
    'instanceId',
    'createdTime',
    'diNumber',
    'instanceType',
    'nodeType',
    'publicIp',
    'privateIp',
    'bastion',
    'state',
    'elbHealth',
    'az',
    'azState',
  ],
  wraplines: false,
};

export const SEARCHABLE_COLUMNS = [
  'instanceId',
  'asg',
  'diNumber',
  'az',
  'instanceType',
  'nodeType',
  'publicIp',
  'privateIp',
  'bastion',
  'state',
  'elbHealth',
  'createdTime',
  'azState',
];

export const FILTERING_PROPERTIES: readonly PropertyFilterProperty[] = [
  {
    propertyLabel: 'ASG',
    key: 'asg',
    groupValuesLabel: 'ASG values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'AZ',
    key: 'az',
    groupValuesLabel: 'AZ values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Created Time',
    key: 'createdTime',
    groupValuesLabel: 'created Time values',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'DI',
    key: 'diNumber',
    groupValuesLabel: 'DI Number Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'ELB Health',
    key: 'elbHealth',
    groupValuesLabel: 'ELB Health values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Instance Id',
    key: 'instanceId',
    groupValuesLabel: 'Instance Id values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Node Type',
    key: 'nodeType',
    groupValuesLabel: 'Node Type Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Instance Type',
    key: 'instanceType',
    groupValuesLabel: 'Instance Type Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Private IP',
    key: 'privateIp',
    groupValuesLabel: 'private IP values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Public IP',
    key: 'publicIp',
    groupValuesLabel: 'Public IP Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'AZ State',
    key: 'azState',
    groupValuesLabel: 'AZ State values',
    operators: [':', '!:', '=', '!='],
  },
];
