import { AppLayout, BreadcrumbGroup, HelpPanel } from '@amzn/awsui-components-react';
import { Switch, Route } from 'react-router-dom';
import React, { useState } from 'react';
import { AppContext, getInitialAppContext } from '../utils/appContext';
import { Sidebar } from '../utils/sidebar';
import { Navbar } from '../utils/Navbar';
import { Homepage } from './Components/Home/Homepage';
import { Onboarded } from './Components/Onboarded/Onboarded';
import { All } from './Components/Verify/All';
import { Onboarding } from './Components/Onboarding/Onboarding';
import { All as ActionableAll } from './Components/Verify/Actionable/All';
import  * as constants  from './Components/Common/utils/OpsTransparencyConstants';

interface HomeProps {
  children: React.ReactNode;
}
const appContext = getInitialAppContext();

const OpsTransparency = (props: HomeProps) => {
  const setTimezone = (timezone: string) => {
    setState({ ...state, timezone: timezone });
  };
  const setBreadcrumbs = (breadcrumbs: any) => {
    setState({ ...state, breadcrumbs });
  };

  const [state, setState] = useState({
    ...getInitialAppContext(),
    setTimezone: setTimezone,
    setBreadcrumbs: setBreadcrumbs,
  });

  const [panelContent, setPanelContent] = useState([]);
  const [toolsOpen, setToolsOpen] = useState(false);
  const [navigationOpen, setNavigationOpen] = useState(false);

  console.log(toolsOpen);

  return (
    <div>
      <Navbar 
        title={constants.OpsTransparencyNavbarTitle}
        homeUrl="/ops-transparency"
        regionSwitcherActive={false}
        dropdownOptions={constants.OpsTransparencyNavbarOptions}
      />
      <div
        style={{
          paddingTop: '60px',
        }}
      >
        <AppLayout
          disableContentPaddings={false}
          content={
            <AppContext.Provider value={state}>
              <Switch>
                <Route exact path={constants.HOME_PATH} render={(props) => <Homepage {...props}/>}/>
                <Route 
                  exact 
                  path={constants.ONBOARDED_PATH}
                  render={(props) => 
                    <Onboarded 
                      {...props} 
                      updateTools={() => setToolsOpen(true)} 
                      updatePanel={content =>setPanelContent(content)}
                    />
                  }
                />
                <Route 
                  exact 
                  path={constants.VERIFY_ALL_PATH}
                  render={(props) => 
                    <All 
                      {...props}
                      updateTools={() => setToolsOpen(true)} 
                      updatePanel={content =>setPanelContent(content)}
                    />
                  }
                />
                <Route 
                  exact 
                  path={constants.VERIFY_ACTIONABLE_ALL_PATH}
                  render={(props) => 
                    <ActionableAll 
                      {...props}
                      updateTools={() => setToolsOpen(true)} 
                      updatePanel={content =>setPanelContent(content)}
                    />
                  }
                />
                <Route exact path={constants.ONBOARDING_PATH} render={(props) => <Onboarding {...props}/>}/>
              </Switch>
            </AppContext.Provider>
          }
          toolsOpen={toolsOpen}
          onToolsChange={({ detail }) => setToolsOpen(detail.open)}
          navigation={<Sidebar />}
          navigationOpen={navigationOpen}
          onNavigationChange={({detail}) => setNavigationOpen(detail.open)}
          headerSelector="#navbar"
          breadcrumbs={
            <BreadcrumbGroup
              items={appContext.breadcrumbs}
              ariaLabel="Breadcrumbs"
            />
          }
          tools={
            <HelpPanel header={<h2>Info</h2>}>
              <div>
                <p>
                  For further details, please refer to <a href="https://w.amazon.com/bin/view/AWS/SearchServices/InfrastructureEngineering/OperatorTransparency/Procedures/SOPforOperatorTransparencySystem" target="_blank" rel="noopener noreferrer">SOP</a>
                </p>
                <p>
                  <strong>889499477124</strong> is canary account.
                </p>
              </div>
              {panelContent.map(message => (
                <p>{message}</p>
              ))}
            </HelpPanel>
          }
        />
      </div>
    </div>
  );
};
export { OpsTransparency };
