import { DateRangePickerProps } from '@amzn/awsui-components-react';
import { get } from 'lodash';
import { CloudwatchWidget } from '../../../Components/Cloudwatch/CloudWatch';
import {
    ExternalWidget,
    MetricDimension,
} from '../../../Components/Cloudwatch/model';
import {
    MetricDefinitions,
    SEARCH_WORKER_METRICS,
    SEARCH_COORDINATOR_METRICS,
    OCU_METRICS,
    INDEXING_DIMENSIONS,
    BULK_INDEXING_METRICS,
    BULK_ITEM_INDEXING_METRICS, BULK_SHARD_INDEXING_METRICS, ROLLOVER_INDEXING_METRICS
} from "./constants";
import { INDEXING_METRICS_DIMENSIONS, SEARCH_RESPONSE_COUNT_METRICS } from "../../Collection/Dashboards/constants";

export const getCommonRanges = () => [
    { start: 'now-1h', end: 'now', label: '1 hour' },
    { start: 'now-3h', end: 'now', label: '3 hours' },
    { start: 'now-12h', end: 'now', label: '12 hours' },
    { start: 'now-1d', end: 'now', label: '24 hours' },
    { start: 'now-3d', end: 'now', label: '3 days' },
    { start: 'now-1w', end: 'now', label: '1 week' },
    { start: 'now-2w', end: 'now', label: '2 weeks' },
    { start: 'now-1M', end: 'now', label: '1 month' },
    { start: 'now-3M', end: 'now', label: '3 months' },
];
export const getPeriod = (diffDays: number) => {
    let period = 300;
    switch (true) {
        case diffDays <= 30:
            period = 300; //5 mins data points
            break;
        case diffDays <= 60:
            period = 180; // half an hour data points
            break;
        case diffDays <= 90:
            period = 3600; // 1 hour data points
            break;
        case diffDays <= 120:
            period = 21600; // 6 hour data points
            break;
        case diffDays <= 180:
            period = 43200; // 12 hour data points
            break;
        default:
            period = 86400; // 1 day data;
            break;
    }
    return period;
};

export const getMetricsOptions = (metrics: any) => {
    if (metrics) {
        let metricOptions = Object.keys(metrics).map((metrc) => ({
            label: metrc,
            value: metrc,
        }));
        return metricOptions.sort((a, b) =>
            a.label > b.label ? 1 : b.label > a.label ? -1 : 0
        );
    }
    return [{ label: '', value: '' }];
};

export const getOCUMetrics = (
    awsAccountId: string,
    region: string,
    cwDef: boolean = true
): CloudwatchWidget[] | ExternalWidget[] => {
    const OCU_Dimensions = `[{"Name":"${OCU_METRICS.dimensionNames.AWS_ACCOUNT_ID}","Value":"${awsAccountId}"}]`;
    return buildExternalWidgets(
            OCU_METRICS,
            OCU_Dimensions,
        ).filter((widget: ExternalWidget) => widget.metrics.length > 0);
};

export const getSearchWorkerMetrics = (
    awsAccountId: string,
    region: String,
    cwDef: boolean = true
): CloudwatchWidget[] | ExternalWidget[] => {
    const dimensions =
        `[{"Name":"${SEARCH_WORKER_METRICS.dimensionNames.AWS_ACCOUNT_ID}","Value":"${awsAccountId}"},
        {"Name":"${SEARCH_WORKER_METRICS.dimensionNames.METRIC_TYPE}","Value":"JunoSearchWorker/NodeStats"}]`;
    return buildExternalWidgets(
            SEARCH_WORKER_METRICS,
            dimensions
        ).filter((widget: ExternalWidget) => widget.metrics.length > 0);
};

export const getSearchCoordinatorMetrics = (
    awsAccountId: string,
    region: String,
    cwDef: boolean = true
): CloudwatchWidget[] | ExternalWidget[] => {
    const dimensions =
        `[{"Name":"${SEARCH_COORDINATOR_METRICS.dimensionNames.AWS_ACCOUNT_ID}","Value":"${awsAccountId}"},
        {"Name":"${SEARCH_COORDINATOR_METRICS.dimensionNames.METRIC_TYPE}","Value":"JunoSearchCoordinator/NodeStats"}]`;
    return buildExternalWidgets(
            SEARCH_COORDINATOR_METRICS,
            dimensions
        ).filter((widget: ExternalWidget) => widget.metrics.length > 0);
};

export const getIndexingCoordinatorMetrics = (
  awsAccountId: string,
  region: string
) => {
    const dimensions_bulk = `[{"Name":"${BULK_INDEXING_METRICS.dimensionNames.AWS_ACCOUNT_ID}","Value":"${awsAccountId}"},
        {"Name":"${BULK_INDEXING_METRICS.dimensionNames.METHOD_NAME}","Value":"BULK"},
        {"Name":"${BULK_INDEXING_METRICS.dimensionNames.SERVICE_NAME}","Value":"JunoIndexingCoordinator"}]`;
    const dimensions_bulk_item = `[{"Name":"${BULK_ITEM_INDEXING_METRICS.dimensionNames.AWS_ACCOUNT_ID}","Value":"${awsAccountId}"},
        {"Name":"${BULK_ITEM_INDEXING_METRICS.dimensionNames.METHOD_NAME}","Value":"BULK_ITEM_METRICS"},
        {"Name":"${BULK_ITEM_INDEXING_METRICS.dimensionNames.SERVICE_NAME}","Value":"JunoIndexingCoordinator"}]`;
    const dimensions_bulk_shard = `[{"Name":"${BULK_SHARD_INDEXING_METRICS.dimensionNames.AWS_ACCOUNT_ID}","Value":"${awsAccountId}"},
        {"Name":"${BULK_SHARD_INDEXING_METRICS.dimensionNames.METHOD_NAME}","Value":"BULK_SHARD"},
        {"Name":"${BULK_SHARD_INDEXING_METRICS.dimensionNames.SERVICE_NAME}","Value":"JunoIndexingCoordinator"}]`;
    const dimensions_rollover = `[{"Name":"${ROLLOVER_INDEXING_METRICS.dimensionNames.AWS_ACCOUNT_ID}","Value":"${awsAccountId}"},
        {"Name":"${ROLLOVER_INDEXING_METRICS.dimensionNames.METHOD_NAME}","Value":"ROLLOVER"},
        {"Name":"${ROLLOVER_INDEXING_METRICS.dimensionNames.SERVICE_NAME}","Value":"JunoIndexingCoordinator"}]`;
    return [
      ...(buildExternalWidgets(
      BULK_INDEXING_METRICS,
      dimensions_bulk
    ).filter((widget: ExternalWidget) => widget.metrics.length > 0) as ExternalWidget[]),
      ...(buildExternalWidgets(
      BULK_ITEM_INDEXING_METRICS,
      dimensions_bulk_item
    ).filter((widget: ExternalWidget) => widget.metrics.length > 0) as ExternalWidget[]),
        ...(buildExternalWidgets(
      BULK_SHARD_INDEXING_METRICS,
      dimensions_bulk_shard
    ).filter((widget: ExternalWidget) => widget.metrics.length > 0) as ExternalWidget[]),
        ...(buildExternalWidgets(
      ROLLOVER_INDEXING_METRICS,
      dimensions_rollover
    ).filter((widget: ExternalWidget) => widget.metrics.length > 0) as ExternalWidget[])
    ];
}

const buildExternalWidgets = (
    cwMetricsSet: MetricDefinitions,
    dimensions: string,
): ExternalWidget[] => {
    return cwMetricsSet.graphs.map((graph: any) => {
        return {
            title: graph.title.replace(/_/g, ' '),
            metrics: graph.paramSets.reduce((acc: any[], param: any) => {
                const metrics: any[] = [];
                if (graph.mathFunctionParams) {
                    graph.mathFunctionParams.forEach((mathFunctionParam) => {
                        metrics.push(mathFunctionParam);
                    });
                }
                param.metricNames.forEach((metricName: string) => {
                    console.log(metricName);
                    metrics.push({
                        alias: 'm0',
                        namespace: cwMetricsSet.namespace,
                        dimensions: dimensions,
                        metric_name: metricName,
                        statistics: param.stats[0],
                    });
                });
                return [...acc, ...metrics];
            }, []),
        };
    });
};


export const getTimzoneOffset = (inputTimezone: string) => {
    let localCurrentDate: any = new Date();
    let inputzoneCurrentDate: any = new Date(
        localCurrentDate.toLocaleString('en-US', { timeZone: inputTimezone })
    );
    let localInputzoneOffsetInMinutes = Math.round(
        (inputzoneCurrentDate - localCurrentDate) / (1000 * 60)
    );
    return Math.round(localInputzoneOffsetInMinutes * 60);
};

export const timezoneOptions = [
    {
        inputDisplay: 'Browser Timezone',
        label: 'Browser Timezone',
        value: 'Browser Timezone',
        text: 0,
    },
    {
        inputDisplay: 'UTC',
        label: 'UTC',
        value: 'UTC',
        text: new Date().getTimezoneOffset() * 60,
    },
    {
        inputDisplay: 'US/Pacific (PDT)',
        label: 'US/Pacific (PDT)',
        value: 'US/Pacific',
        text: getTimzoneOffset('US/Pacific'),
    },
    {
        inputDisplay: 'America/Phoenix (MDT)',
        label: 'America/Phoenix (MDT)',
        value: 'America/Phoenix',
        text: getTimzoneOffset('America/Phoenix'),
    },
    {
        inputDisplay: 'America/NewYork (EDT)',
        label: 'America/NewYork (EDT)',
        value: 'America/New_York',
        text: getTimzoneOffset('America/New_York'),
    },
    {
        inputDisplay: 'America/Chicago (CDT)',
        label: 'America/Chicago (CDT)',
        value: 'America/Chicago',
        text: getTimzoneOffset('America/Chicago'),
    },
    {
        inputDisplay: 'Asia/Kolkata',
        label: 'Asia/Kolkata',
        value: 'Asia/Kolkata',
        text: getTimzoneOffset('Asia/Kolkata'),
    },
    {
        inputDisplay: 'Asia/Shanghai',
        label: 'Asia/Shanghai',
        value: 'Asia/Shanghai',
        text: getTimzoneOffset('Asia/Shanghai'),
    },
];

export const periodOptions = [
    {
        label: '1s',
        value: '1',
        text: 1,
    },
    {
        label: '1m',
        value: '60',
        text: 60,
    },
    {
        label: '5m',
        value: '300',
        text: 300,
    },
    {
        label: '15m',
        value: '900',
        text: 900,
    },
    {
        label: '1hr',
        value: '3600',
        text: 3600,
    },
    {
        label: '1day',
        value: '86400',
        text: 86400,
    },
];

export const i18nStrings = {
    todayAriaLabel: 'Today',
    nextMonthAriaLabel: 'Next month',
    previousMonthAriaLabel: 'Previous month',
    customRelativeRangeDurationLabel: 'Duration',
    customRelativeRangeDurationPlaceholder: 'Enter duration',
    customRelativeRangeOptionLabel: 'Custom range',
    customRelativeRangeOptionDescription: 'Set a custom range in the past',
    customRelativeRangeUnitLabel: 'Unit of time',
    formatRelativeRange: (e) => {
        const t = 1 === e.amount ? e.unit : e.unit + 's';
        return `Last ${e.amount} ${t}`;
    },
    formatUnit: (e, t) => (1 === t ? e : e + 's'),
    dateTimeConstraintText:
        'Range must be between 6 - 30 days. Use 24 hour format.',
    relativeModeTitle: 'Relative range',
    absoluteModeTitle: 'Absolute range',
    relativeRangeSelectionHeading: 'Choose a range',
    startDateLabel: 'Start date',
    endDateLabel: 'End date',
    startTimeLabel: 'Start time',
    endTimeLabel: 'End time',
    clearButtonLabel: 'Clear',
    cancelButtonLabel: 'Cancel',
    applyButtonLabel: 'Apply',
};

export const relativeOptions: ReadonlyArray<DateRangePickerProps.RelativeOption> =
    [
        {
            key: 'previous-6-hours',
            amount: 6,
            unit: 'hour',
            type: 'relative',
        },
        {
            key: 'previous-1-day',
            amount: 1,
            unit: 'day',
            type: 'relative',
        },
        {
            key: 'previous-3-days',
            amount: 3,
            unit: 'day',
            type: 'relative',
        },
        {
            key: 'previous-1-week',
            amount: 1,
            unit: 'week',
            type: 'relative',
        },
        {
            key: 'previous-3-weeks',
            amount: 3,
            unit: 'week',
            type: 'relative',
        },
    ];
