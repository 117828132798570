import { AppLayout, BreadcrumbGroup } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppContext, getInitialAppContext } from '../utils/appContext';
import { Navbar } from '../utils/Navbar';
import { Sidebar } from '../utils/sidebar';
import * as constants from './commons/constants';
import { LogRecord } from './Components/LogRecord';
import { Logs } from './Components/Logs';
import { JunoAccountOverview } from './Pages/Account/JunoAccountOverview';
import { CollectionOverview } from './Pages/Collection/CollectionOverview';
import { JunoHomePage } from './Pages/Home/JunoHomePage';
import { WorkerOverview } from './Pages/Worker/WorkerOverview';
import {PlacementConstraintOverview} from "./Pages/PlacementConstraint/PlacementConstraintOverview";
import {JunoOpsDashboard} from "./Dashboards/JunoOpsDashboard";
import {AccessPolicyDetails} from "./Pages/Policy/AccessPolicyDetails";
import {
  JUNO_ACCESS_POLICY_DETAILS_PATH,
  JUNO_SECURITY_CONFIG_DETAILS_PATH,
  JUNO_SECURITY_POLICY_DETAILS_PATH
} from "./commons/constants";
import {SecurityPolicyDetails} from "./Pages/Policy/SecurityPolicyDetails";
import {SecurityConfigDetails} from "./Pages/Policy/SecurityConfigDetails";

interface HomeProps {
  children: React.ReactNode;
}
const appContext = getInitialAppContext();

const Juno = (props: HomeProps) => {
  const setTimezone = (timezone: string) => {
    setState({ ...state, timezone: timezone });
  };
  const setBreadcrumbs = (breadcrumbs: any) => {
    setState({ ...state, breadcrumbs });
  };

  const [state, setState] = useState({
    ...getInitialAppContext(),
    setTimezone: setTimezone,
    setBreadcrumbs: setBreadcrumbs,
  });
  const [navigationOpen, setNavigationOpen] = useState(false);
  return (
    <div>
      <Navbar
        title={constants.JunoNavbarTitle}
        homeUrl="/juno/log"
        regionSwitcherActive={true}
        dropdownOptions={constants.JunoNavbarOptions}
      />
      <div
        style={{
          paddingTop: '60px',
        }}
      >
        <AppLayout
          disableContentPaddings={false}
          content={
            <AppContext.Provider value={state}>
              <Switch>
                <Route
                  exact
                  path={constants.JUNO_BASE_PATH}
                  render={(props) => <JunoHomePage {...props} />}
                />
                <Route
                  exact
                  path={constants.JUNO_LOG_PATH}
                  render={(props) => <Logs {...props} />}
                />
                <Route
                  path={constants.JUNO_DETAIL_LOG_RECORD_PATH}
                  render={(props) => <LogRecord {...props} />}
                />
                <Route
                  path={constants.JUNO_OPS_DASHBOARD_PATH}
                  component={JunoOpsDashboard}
                />
                <Route
                  exact
                  path={constants.JUNO_ACCOUNT_OVERVIEW_PATH}
                  render={(props) => <JunoAccountOverview {...props} />}
                />
                <Route
                  exact
                  path={constants.JUNO_WORKER_OVERVIEW_PATH}
                  render={(props) => <WorkerOverview {...props} />}
                />
                <Route
                  exact
                  path={constants.JUNO_COLLECTION_OVERVIEW_PATH}
                  render={(props) => <CollectionOverview {...props} />}
                />
                <Route
                    exact
                    path={constants.JUNO_PLACEMENTCONSTRAINT_OVERVIEW_PATH}
                    render={(props) => <PlacementConstraintOverview {...props} />}
                />
                <Route
                    exact
                    path={constants.JUNO_ACCESS_POLICY_DETAILS_PATH}
                    render={(props) => <AccessPolicyDetails {...props} />}
                />
                <Route
                    exact
                    path={constants.JUNO_SECURITY_POLICY_DETAILS_PATH}
                    render={(props) => <SecurityPolicyDetails {...props} />}
                />
                <Route
                    exact
                    path={constants.JUNO_SECURITY_CONFIG_DETAILS_PATH}
                    render={(props) => <SecurityConfigDetails {...props} />}
                />
              </Switch>
            </AppContext.Provider>
          }
          toolsHide={true}
          navigation={<Sidebar />}
          navigationOpen={navigationOpen}
          onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
          headerSelector="#navbar"
          breadcrumbs={
            <BreadcrumbGroup
              items={appContext.breadcrumbs}
              ariaLabel="Breadcrumbs"
            />
          }
        />
      </div>
    </div>
  );
};

export { Juno };
