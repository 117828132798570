
export const validation = (domainIdentifier, history, setHasError) => {
  if (domainIdentifier == null || domainIdentifier === '') {
    setHasError(true);
  } else {
    if (domainIdentifier.includes(":")) {
      const [clientId, domainName] = domainIdentifier.split(":", 2);
      if (
        clientId &&
        clientId.length === 12 &&
        !Number.isNaN(Number(clientId)) &&
        domainName &&
        /^[a-z][a-z0-9\\-]+$/.test(domainName) &&
        domainName.length > 2 &&
        domainName.length <= 28
      ) {
        history.push(`/${clientId}/${domainName}/`);
      } else {
        setHasError(true);
      }
    } else {
      if (domainIdentifier.length === 12 && !Number.isNaN(Number(domainIdentifier))) {
        history.push(`/${domainIdentifier}/`);
      } else {
        setHasError(true);
      }
    }
  }
};
