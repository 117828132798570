import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../models/types';

export interface TriggerHawkeyeAnalysisVars {
    analysisType: string,
    domainIdentifier: string,
    startTime: string,
    endTime: string,
    ticketId: string
}

const TRIGGER_HAWKEYE_ANALYSIS_GQL_QUERY = gql`
    query TriggerHawkeyeAnalysis(
        $analysisType: String!,
        $domainIdentifier: String!,
        $startTime: String!,
        $endTime: String!,
        $ticketId: String!
    ) {
        triggerHawkeyeAnalysis(
            analysisType: $analysisType,
            domainIdentifier: $domainIdentifier,
            startTime: $startTime,
            endTime: $endTime,
            ticketId: $ticketId
        )
    }
`;
 
export const useTriggerHawkeyeAnalysisQuery = () => {
    return useLazyQuery<AnyObject, TriggerHawkeyeAnalysisVars>(TRIGGER_HAWKEYE_ANALYSIS_GQL_QUERY, {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
    });
};