import React from 'react';
import { EuiTextColor, EuiBadge, EuiLoadingSpinner } from '@elastic/eui';

import { apiCall } from '../../utils';

export class ClusterFeature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRequestActive: true,
      features: [],
      exception: '',
    };
  }

  componentDidMount() {
    this.getClusterFeatures();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.stackName !== this.props.stackName ||
      prevProps.clusterId !== this.props.clusterId
    ) {
      this.setState({ features: [] }, () => this.getClusterFeatures());
    }
  }

  updateException(response) {
    this.setState({ exception: JSON.stringify(response.body) });
  }

  getClusterFeatures = async (nextToken) => {
    const data = {
      cluster: {
        stackName: this.props.stackName,
        clusterId: this.props.clusterId,
      },
      maxNodes: 100,
      nextToken: nextToken,
    };
    const response = await apiCall('/cluster/features', data);
    this.updateResponse(response);
    if (response.body.nextToken) {
      await this.getClusterNodes(response.body.nextToken);
    } else {
      this.setState({ isRequestActive: false });
    }
  };

  updateResponse(response) {
    if (response.statusCode === 200) {
      const { features } = this.state;
      // eslint-disable-next-line guard-for-in
      response.body.featureList.forEach((feature) =>
        features.push(feature.featureTag)
      );
      this.setState({ features });
    } else {
      this.updateException(response);
    }
  }

  render() {
    const { features, isRequestActive, exception } = this.state;
    if (isRequestActive) {
      return <EuiLoadingSpinner size="l" id="loadingSpinner" />;
    } else if (exception) {
      return <EuiTextColor color="danger">{exception}</EuiTextColor>;
    } else {
      return (
        <div>
          {features.map((feature) => {
            return (
              <EuiBadge key={feature} color={'primary'}>
                {feature}
              </EuiBadge>
            );
          })}
        </div>
      );
    }
  }
}
