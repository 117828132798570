import { gql, useQuery } from '@apollo/client';
import { AnyObject } from '../../models/types';

export interface PutMetricsVars {
    failureType: string;
}

const GQL_PUT_METRICS = gql`
    query PutMetrics($failureType: String) {
        putMetrics(failureType: $failureType)
    }
`;

export const usePutCwMetrics = (vars: PutMetricsVars) => {
    return useQuery<AnyObject, PutMetricsVars>(GQL_PUT_METRICS, {
        variables: { ...vars },
    });
};