import {
  Box,
  Button,
  Flashbar,
  FormField,
  Input,
  Modal,
  RadioGroup,
  SpaceBetween,
  Toggle,
} from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';
import { CISPE_COMPLIANCE_MESSAGE } from '../../constants';
import { DOWNLOAD_LOGS_INSTRUCTION_MESSAGE } from '../../Domain/Logs/constants';
import { SSHBastionDetails } from './../Common/SSHBastionDetails';
import { useLogRedactionFeedbackQuery } from './LogRedactionFeedbackQuery';

const LogRedactionFeedbackModal = (props) => {
  const [
    storeLogRedactionFeedbackQuery,
    {
      loading: logRedactionFeedbackloading,
      data: logRedactionFeedbackResponse,
    },
  ] = useLogRedactionFeedbackQuery();
  const afterOnDismiss = () => {
    props.closeLogRedactionModal(false);
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [ticket, setTicket] = useState('');
  const [emptyTicketMessage, setEmptyTicketMessage] = useState('');

  const [customerJustification, setCustomerJustification] = useState('');
  const [emptyDescriptionMessage, setEmptyDescriptionMessage] = useState('');

  const [breakglassJustification, setBreakglassJustification] = useState('');
  const [emptyBreakglassMessage, setEmptyBreakglassMessage] = useState('');

  const [isOperatorCheckingLogs, setIsOperatorCheckingLogs] = useState(true);
  const [showSSHDetails, setShowSSHDetails] = useState(false);

  const [breakglassSelectedValue, setBreakglassSelectedValue] = useState('');

  const breakglassRadioOptions = [
    { value: 'first', label: 'Tooling Gap' },
    { value: 'second', label: 'Tool/command not working' },
    { value: 'other', label: 'Other' },
  ];
  const callStoreLogRedactionFeedbackQuery = () => {
    let finalBreakglassJustification = '';
    if (breakglassSelectedValue) {
      const selectedOption = breakglassRadioOptions.filter(
        (i) => i.value === breakglassSelectedValue
      )[0];
      finalBreakglassJustification = `${selectedOption.label}. ${breakglassJustification}`;
    }
    storeLogRedactionFeedbackQuery({
      variables: {
        domainId: props.domainId,
        ticket: ticket,
        description: customerJustification,
        breakglassJustification:
          finalBreakglassJustification === ''
            ? breakglassJustification
            : finalBreakglassJustification,
      },
    });
  };
  const handleOperatorFeedback = () => {
    // If get creds has been clicked.
    if (props.isTot) {
      if (ticket === '' || customerJustification === '') {
        ticket === ''
          ? setEmptyTicketMessage('This is required field.')
          : setEmptyDescriptionMessage('This is required field.');
      } else {
        callStoreLogRedactionFeedbackQuery();
      }
    } else {
      // If get ssh details has been clicked.
      if (isOperatorCheckingLogs) {
        // Operator seeing raw logs so store TT, justification and why breakglass data after validation.
        if (
          breakglassSelectedValue === '' ||
          ticket === '' ||
          customerJustification === ''
        ) {
          breakglassSelectedValue === ''
            ? setEmptyBreakglassMessage('This is required field.')
            : ticket === ''
            ? setEmptyTicketMessage('This is required field.')
            : setEmptyDescriptionMessage('This is required field.');
        } else {
          callStoreLogRedactionFeedbackQuery();
        }
      } else {
        if (breakglassSelectedValue === '') {
          setEmptyBreakglassMessage('This is required field.');
        } else {
          callStoreLogRedactionFeedbackQuery();
        }
      }
    }
  };
  useEffect(() => {
    if (logRedactionFeedbackResponse && !logRedactionFeedbackloading) {
      const feedbackResponse =
        logRedactionFeedbackResponse.storeLogRedactionFeedback.validation;
      if (feedbackResponse === 'success') {
        if (props.isTot) {
          window.open(
            `https://isengard.amazon.com/console-access?filter=${props.account}`,
            '_blank',
            'noopener,noreferrer'
          );
        }
        setShowSSHDetails(true);
      } else {
        console.log(
          `Validation command failed and response is ${JSON.stringify(
            logRedactionFeedbackResponse
          )}`
        );
      }
    }
  }, [logRedactionFeedbackResponse, logRedactionFeedbackloading]);

  useEffect(() => {
    if (props.isTot) {
      if (ticket !== '' && customerJustification !== '') {
        setIsButtonDisabled(false);
      }
    } else {
      if (isOperatorCheckingLogs) {
        if (
          ticket !== '' &&
          customerJustification !== '' &&
          breakglassSelectedValue !== ''
        ) {
          setIsButtonDisabled(false);
        }
      } else {
        if (breakglassSelectedValue !== '') {
          setIsButtonDisabled(false);
        }
      }
    }
  }, [ticket, customerJustification, breakglassSelectedValue]);
  const [items, setItems] = useState([
    {
      type: 'success',
      content: 'Thanks for the justification.',
      id: 'message_1',
    },
  ]);
  return (
    <Modal
      onDismiss={afterOnDismiss}
      visible={props.visible}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={afterOnDismiss}>
              Cancel
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Cusomter Data Protection"
    >
      <SpaceBetween size="l" direction="vertical">
        {isOperatorCheckingLogs || props.isTot ? (
          <Flashbar
            items={[
              {
                type: 'error',
                content: CISPE_COMPLIANCE_MESSAGE,
              },
            ]}
          />
        ) : (
          ''
        )}

        {props.isTot ? (
          <SpaceBetween size="s" direction="vertical">
            <p>
              <b>{DOWNLOAD_LOGS_INSTRUCTION_MESSAGE}</b>
            </p>
            <div>
              <p>
                <b>1) </b>Copy the ops-datalake-user role credentials from
                Isengard after filling out below form and paste to the terminal.
              </p>
              <p>When on Timber on Tumbler main page:</p>
              <p>
                <b>2) </b>Click the "Logs" square icon under the "Download"
                column to copy the CLI command.
              </p>
              <p>
                <b>3) </b>Paste and execute the command in the terminal to
                download logs.
              </p>
            </div>
          </SpaceBetween>
        ) : (
          ''
        )}

        {!props.isTot ? (
          <Toggle
            onChange={({ detail }) => setIsOperatorCheckingLogs(detail.checked)}
            checked={isOperatorCheckingLogs}
          >
            Are you going to view logs by breaking glass?
          </Toggle>
        ) : (
          ''
        )}
        {isOperatorCheckingLogs && !showSSHDetails ? (
          <SpaceBetween size="l" direction="horizontal">
            <FormField
              constraintText="Provide customer consent SIM/TT"
              errorText={emptyTicketMessage}
              label={<strong>Ticket</strong>}
            >
              <Input
                onChange={({ detail }) => setTicket(detail.value)}
                value={ticket}
                placeholder="Ticket/Link/ID"
              />
            </FormField>
            <FormField
              constraintText="Provide access justification description"
              errorText={emptyDescriptionMessage}
              label={<strong>Description</strong>}
            >
              <Input
                onChange={async ({ detail }) =>
                  setCustomerJustification(detail.value)
                }
                value={customerJustification}
                placeholder="Description"
              />
            </FormField>
          </SpaceBetween>
        ) : (
          ''
        )}
        {!showSSHDetails ? (
          <SpaceBetween size="l" direction="vertical">
            {!props.isTot ? (
              <SpaceBetween size="xxl" direction="vertical">
                <FormField
                  constraintText="Which command/tool not working?"
                  errorText={emptyBreakglassMessage}
                  label={<strong>Why Breakglass?</strong>}
                  stretch
                >
                  <RadioGroup
                    onChange={({ detail }) =>
                      setBreakglassSelectedValue(detail.value)
                    }
                    value={breakglassSelectedValue}
                    items={breakglassRadioOptions}
                  />
                  <span>Please add more details:</span>
                  <Input
                    onChange={async ({ detail }) =>
                      setBreakglassJustification(detail.value)
                    }
                    value={breakglassJustification}
                    placeholder="What is not working for you? Please give us a feedback."
                    inputMode="text"
                    ariaRequired
                  />
                </FormField>
              </SpaceBetween>
            ) : (
              ''
            )}
            <Button
              disabled={isButtonDisabled}
              variant="primary"
              onClick={handleOperatorFeedback}
            >
              {props.isTot ? 'Get Creds' : 'Get SSH Details'}
            </Button>
          </SpaceBetween>
        ) : (
          ''
        )}
        {showSSHDetails ? (
          <SpaceBetween direction="vertical" size="l">
            <Flashbar items={items as any} />
            {props.isTot ? '' : <SSHBastionDetails e={props.e} />}
          </SpaceBetween>
        ) : (
          ''
        )}
      </SpaceBetween>
    </Modal>
  );
};

export { LogRedactionFeedbackModal };
