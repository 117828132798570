// Utility function that converts the input size in bytes to respective unit - b, kb, mb, gb or tb and returns it
export const getHumanReadableSize = (bytes: number) => {
  const kb = bytes / 1024;
  const mb = kb / 1024;
  const gb = mb / 1024;
  const tb = gb / 1024;
  if (tb > 1) {
    return tb.toFixed(2).concat('tb');
  }
  if (gb > 1) {
    return gb.toFixed(2).concat('gb');
  }
  if (mb > 1) {
    return mb.toFixed(2).concat('mb');
  }
  if (kb > 1) {
    return kb.toFixed(2).concat('kb');
  }
  return bytes.toString().concat('b');
};

// Sorting paramters for default sorting of tables by column "size" in descending order
export const sizeSorting = {
  sort: {
    field: 'size',
    direction: 'desc',
  },
};

// Search parameters for the Skew Analysis tables
export const searchParameters = {
  box: {
    incremental: true,
  },
};

export const getMedianAndPercent = (
  inputArray: Array<number>,
  diff: number
) => {
  inputArray.sort((a, b) => a - b);
  const mid = Math.floor(inputArray.length / 2);
  let median;
  let rangePercent;
  if (inputArray.length % 2) {
    median = inputArray[mid];
  } else {
    median = (inputArray[mid - 1] + inputArray[mid]) / 2;
  }
  rangePercent = ((diff / median) * 100).toFixed(2);
  return { median: median, rangePercent: rangePercent };
};

// Converts the unit of input size to byte and returns the equivalent number of bytes
export const getDataInBytes = (space: string) => {
  let idx = -1;
  for (let i = 0; i < space.length; i++) {
    if (space.charAt(i).match(/[a-z]/i)) {
      idx = i;
      break;
    }
  }

  const digit = parseFloat(space.substring(0, idx));
  const unit = space.substring(idx);

  var bytes;

  if (unit === 'b') {
    bytes = digit;
  } else if (unit === 'kb') {
    bytes = digit * 1024;
  } else if (unit === 'mb') {
    bytes = digit * 1024 * 1024;
  } else if (unit === 'gb') {
    bytes = digit * 1024 * 1024 * 1024;
  } else {
    bytes = digit * 1024 * 1024 * 1024 * 1024;
  }
  return bytes;
};

export enum shardStates {
  STARTED = 'STARTED',
  INITIALIZING = 'INITIALIZING',
  RELOCATING = 'RELOCATING',
  UNASSIGNED = 'UNASSIGNED',
}
