import {
  Button,
  Container,
  DateRangePicker,
  DateRangePickerProps,
  FormField,
  Header,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { useState } from 'react';
import {
  i18nStrings,
  isValidRequestRange,
  relativeOptions,
  timezoneOptions,
} from './../../Domain/HawkEye/constants';

const RequestHistory = (props) => {
  const [requestDate, setRequestDate] = useState(undefined);
  const [selectedRequestTimeZoneOption, setSelectedRequestTimeZoneOption] =
    useState({
      inputDisplay: 'UTC',
      label: 'UTC',
      value: 'UTC',
      code: 'UTC',
      offset: '0000',
      mins: 0,
    });

  const handleRequestTimezoneChange = (selectedOption) => {
    setSelectedRequestTimeZoneOption(selectedOption);
  };
  const [selectedOption, setSelectedOption] = useState({
    label: '',
    value: '',
  });
  const handleRequestHistory = () => {
    props.fetchHistory(requestDate, selectedOption.value);
  };

  return (
    <Container header={<Header variant="h2">Fetch History</Header>}>
      <SpaceBetween direction="horizontal" size="l">
        <FormField label={<strong>Command and Subcommand</strong>}>
          <Select
            selectedOption={selectedOption}
            onChange={({ detail }) =>
              setSelectedOption(detail.selectedOption as any)
            }
            options={props.commandList}
            selectedAriaLabel="Selected"
          />
        </FormField>
        <FormField label={<strong>Date</strong>}>
          <DateRangePicker
            onChange={({ detail }) => setRequestDate(detail.value)}
            value={requestDate}
            relativeOptions={relativeOptions}
            i18nStrings={i18nStrings}
            placeholder="Filter by a date and time range"
            isValidRange={
              isValidRequestRange as DateRangePickerProps.ValidationFunction
            }
            showClearButton={false}
            timeOffset={selectedRequestTimeZoneOption.mins}
          />
        </FormField>
        <FormField label={<strong>Time Zone</strong>}>
          <Select
            selectedOption={selectedRequestTimeZoneOption}
            onChange={({ detail }) =>
              handleRequestTimezoneChange(detail.selectedOption)
            }
            options={timezoneOptions}
            selectedAriaLabel="Selected"
          />
        </FormField>
        <FormField label={'-'}>
          <Button variant="primary" onClick={handleRequestHistory}>
            Submit
          </Button>
        </FormField>
      </SpaceBetween>
    </Container>
  );
};

export { RequestHistory };
