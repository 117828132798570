import { Link } from 'react-router-dom';

export const tableSearchParameters = {
  box: {
    incremental: true,
  },
  filters: [
    {
      type: 'is',
      field: 'processing',
      name: 'Processing',
      negatedName: 'Not processing',
    },
    {
      type: 'is',
      field: 'deleted',
      name: 'Deleted',
      negatedName: 'Not deleted',
    },
    {
      type: 'is',
      field: 'created',
      name: 'Created',
      negatedName: 'Not created',
    },
  ],
};

export const domainColumns = [
  {
    id: 'domainIdentifier',
    field: 'domainIdentifier',
    name: 'domainIdentifier',
    header: 'Domain name',
    cell: (e) => (
      <Link to={`/${e.domainIdentifier.split(':')[0]}/${e.name}`}>
        {e.name}
      </Link>
    ),
    sortingField: 'domainIdentifier',
  },
  {
    id: 'created',
    field: 'created',
    name: 'Created',
    sortingField: 'created',
    header: 'Created',
    cell: (e) => (e.created ? 'Yes' : 'No'),
  },
  {
    id: 'processing',
    field: 'processing',
    name: 'Processing',
    sortingField: 'processing',
    header: 'Processing',
    cell: (e) => (e.processing ? 'Yes' : 'No'),
  },
  {
    id: 'deleted',
    field: 'deleted',
    name: 'Deleted',
    sortingField: 'deleted',
    header: 'Deleted',
    cell: (e) => (e.deleted ? 'Yes' : 'No'),
  },
  {
    id: 'carbon',
    field: 'carbon',
    name: 'Carbon',
    sortingField: 'carbonDomain',
    header: 'Carbon',
    cell: (e) => (e.carbonDomain == "true" ? 'Yes' : 'No'),
  },
];

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Options',
    options: [
      { id: 'created', label: 'created' },
      { id: 'processing', label: 'processing' },
      { id: 'deleted', label: 'deleted' },
      { id: 'carbon', label: 'carbon' },
      { id: 'actions', label: 'actions' },
    ],
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10' },
  { value: 30, label: '30' },
  { value: 50, label: '50' },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 10,
  visibleContent: [
    'domainIdentifier',
    'created',
    'processing',
    'deleted',
    'carbon',
    'actions',
  ],
  wraplines: false,
};
