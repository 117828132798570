import React from 'react';
import { EuiLoadingSpinner, EuiBasicTable } from '@elastic/eui';
import { apiCall, convertEpochToDate } from '../../../utils';
import { ErrorDisplay } from '../../../components/ErrorDisplay';
import { renderCreatorIdentityServicePrinciple } from '../../../utils/JsxUtils';

const columns = [
  {
    field: 'property',
    name: 'property',
  },
  {
    field: 'value',
    name: 'value',
    render: (item) => {
      if (typeof item === 'function') return item();
      return item;
    },
  },
];

export class ComponentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isError: null,
      items: [],
    };
  }

  componentDidMount() {
    this.getComponentDetails();
  }

  getComponentDetails = async () => {
    const data = {
      stackName: this.props.stackName,
      componentName: this.props.componentName,
      componentScopeDefinition: {
        componentScope: this.props.componentScope,
        componentScopeValue: this.props.componentScopeValue,
      },
    };
    const response = await apiCall('/component/describe', data);
    if (response.statusCode === 200) {
      this.updateResponse(response.body);
    } else {
      this.setState({ isError: response.body });
    }
    this.setState({ isLoading: false });
  };

  updateResponse = (response) => {
    const { items } = this.state;
    const {
      component: {
        auditProperties: {
          createdBy,
          creationTime,
          lastModificationTime,
          lastModifiedBy,
        },
        componentName,
        componentProperties: { parentComponent, requiresRestart },
        componentScopeDefinition: { componentScope, componentScopeValue },
        componentType,
        isDeprecated,
        stackName,
      },
    } = response;
    items.push({ property: 'Stack', value: stackName });
    items.push({ property: 'Component Name', value: componentName });
    items.push({ property: 'Component Type', value: componentType });
    items.push({ property: 'Component Scope', value: componentScope || '-' });
    items.push({
      property: 'Component Scope Value',
      value: componentScopeValue || '-',
    });
    items.push({
      property: 'Created By',
      value: renderCreatorIdentityServicePrinciple(createdBy),
    });
    items.push({
      property: 'Creation Time',
      value: convertEpochToDate(creationTime),
    });
    items.push({ property: 'Parent Component', value: parentComponent || '-' });
    items.push({
      property: 'Requires Restart',
      value: String(requiresRestart),
    });
    items.push({ property: 'Is Deprecated', value: String(isDeprecated) });
    items.push({ property: 'Last Modified By', value: lastModifiedBy });
    items.push({
      property: 'Last Modified Time',
      value: convertEpochToDate(lastModificationTime),
    });
    this.setState({ items });
  };

  render() {
    const { isLoading, isError, items } = this.state;
    if (isLoading) {
      return <EuiLoadingSpinner size="l" id="loadingSpinner" />;
    } else if (isError) {
      return <ErrorDisplay message={isError} />;
    }
    return <EuiBasicTable columns={columns} items={items} />;
  }
}
