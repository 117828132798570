import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Button,
  Pagination,
  PropertyFilter,
  Table,
} from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { AutoScalingEvents, CommonDomainProps } from '../../../models/types';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from './../../../utils/constants';
import {
  EmptyState,
  getFilterCounterText,
  Preferences,
} from './../../../utils/tablePreferences';
import {
  columns,
  DEFAULT_PREFERENCES,
  FILTERING_PROPERTIES,
  PAGE_SIZE_OPTIONS,
  VISIBLE_CONTENT_OPTIONS,
} from './columns';

interface AutoScalingEventProps extends CommonDomainProps {
  autoScalingEvents: AutoScalingEvents[];
  isLoading: boolean;
}

const AutoScalingEvent = (props: AutoScalingEventProps) => {
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(props.isLoading ? [] : props.autoScalingEvents, {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES,
      empty: (
        <EmptyState
          title="No Nodes"
          subtitle="No nodes to display."
          action={<span></span>}
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can’t find a match."
          action={
            <Button onClick={() => actions.setFiltering('')}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
  });
  return (
    <React.Fragment>
      <Table
        {...collectionProps}
        columnDefinitions={[...columns]}
        stickyHeader={true}
        resizableColumns={true}
        wrapLines={preferences.wraplines}
        loading={props.isLoading}
        loadingText={
          props.isLoading
            ? 'Loading Security Groups'
            : 'No Security Groups found'
        }
        visibleColumns={preferences.visibleContent}
        items={items}
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
          />
        }
        filter={
          <div className="input-container">
            <PropertyFilter
              className="input-filter"
              i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
              {...propertyFilterProps}
              countText={getFilterCounterText(filteredItemsCount)}
            />
          </div>
        }
        preferences={
          <Preferences
            preferences={preferences}
            setPreferences={setPreferences}
            disabled={false}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            visibleContentOptions={VISIBLE_CONTENT_OPTIONS}
          />
        }
      />

      {/* <EuiInMemoryTable
        pagination={true}
        sorting={true}
        loading={props.isLoading}
        compressed={true}
        items={(props.isLoading ? [] : props.autoScalingEvents) as any}
        message={
          props.isLoading
            ? 'Loading Autoscaling Events'
            : 'No Autoscaling Events found'
        }
        columns={[...columns]}
        hasActions={false}
      /> */}
    </React.Fragment>
  );
};

export { AutoScalingEvent };
