import { BreadcrumbGroup, SpaceBetween } from '@amzn/awsui-components-react';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { JunoCollectionOverviewParams } from '../../../models/types';
import { BREADCRUMBS } from '../../../utils/constants';
import { JUNO_BASE_PATH } from '../../commons/constants';
import { useGetWorkersListForCollectionQuery } from '../../hooks/Collection/useGetWorkersListForCollectionQuery';
import { useGetCollectionDetailsQuery } from '../../hooks/Collection/useGetCollectionDetailsQuery';
import { WorkersListForAccount } from '../Account/WorkersListForAccount';
import { AppContext } from './../../../utils/appContext';
import { CollectionDetails } from './CollectionDetails';
import {JunoCollectionDashboards} from "./Dashboards/JunoCollectionDashboards";
import {useListAccessPoliciesQuery} from "../../hooks/Account/useListAccessPoliciesQuery";
import {useListSecurityPoliciesQuery} from "../../hooks/Account/useListSecurityPoliciesQuery";
import {Policies} from "../Account/Policies";

const CollectionOverview = (props) => {
  const location = useLocation();
  const appContext = useContext(AppContext);
  const history = useHistory();
  const { awsAccountId, collectionId } =
    useParams<JunoCollectionOverviewParams>();

  const [collectionInformation, setCollectionInformation] = useState();

  const [
    getSearchWorkersListForCollection,
    {
      loading: searchWorkersListForCollectionLoading,
      data: searchWorkersListForCollection,
    },
  ] = useGetWorkersListForCollectionQuery();

  const [
    getIndexingWorkersListForCollection,
    {
      loading: indexingWorkersListForCollectionLoading,
      data: indexingWorkersListForCollection,
    },
  ] = useGetWorkersListForCollectionQuery();

  const [
    getCollectionDetails,
    { loading: collectionDetailsLoading, data: collectionDetails },
  ] = useGetCollectionDetailsQuery();

  const [
    listAccessPolicies,
    { loading: AccessPoliciesLoading, data: AccessPoliciesData },
  ] = useListAccessPoliciesQuery();

  const [
    listSecurityNetworkPolicies,
    { loading: SecurityNetworkPoliciesLoading, data: SecurityNetworkPoliciesData },
  ] = useListSecurityPoliciesQuery();

  const [
    listSecurityEncryptionPolicies,
    { loading: SecurityEncryptionPoliciesLoading, data: SecurityEncryptionPoliciesData },
  ] = useListSecurityPoliciesQuery();

  useEffect(() => {
    if (collectionDetails) {
      let collectionInfo =
        collectionDetails.getCollectionDetails.collection_details;
      if (collectionInfo && collectionInfo.length > 0) {
        setCollectionInformation(collectionInfo[0]);

        getIndexingWorkersListForCollection({
          variables: {
            awsAccountId: awsAccountId,
            dataPlaneType: 'indexing',
            placementConstraintId: collectionInfo[0].placement_constraint_id,
          },
        });

        getSearchWorkersListForCollection({
          variables: {
            awsAccountId: awsAccountId,
            dataPlaneType: 'search',
            placementConstraintId: collectionInfo[0].placement_constraint_id,
          },
        });

        const resource = "collection/" + collectionInfo[0].name;

        listAccessPolicies({
          variables: {
            awsAccountId: awsAccountId,
            type: "data",
            resource: resource
          },
        });

        listSecurityNetworkPolicies({
          variables: {
            awsAccountId: awsAccountId,
            type: "network",
            resource: resource
          },
        });

        listSecurityEncryptionPolicies({
          variables: {
            awsAccountId: awsAccountId,
            type: "encryption",
            resource: resource
          },
        });
      }
    }
  }, [collectionDetailsLoading, collectionDetails]);

  useEffect(() => {
    getCollectionDetails({
      variables: {
        awsAccountId: awsAccountId,
        collectionId: collectionId,
        collectionName: '',
      },
    });
  }, [awsAccountId]);

  useEffect(() => {
    appContext.setBreadcrumbs([
      {
        ...BREADCRUMBS.JUNO,
        href: `${JUNO_BASE_PATH}`,
        onClick: () => {
          history.push(`${JUNO_BASE_PATH}`);
        },
      },
      {
        text: awsAccountId,
        href: `${JUNO_BASE_PATH}/${awsAccountId}`,
        onClick: () => {
          history.push(`${JUNO_BASE_PATH}/${awsAccountId}`);
        },
      },
      { text: collectionId, href: `/${awsAccountId}/${collectionId}` },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <SpaceBetween direction="vertical" size="l">
      <BreadcrumbGroup items={appContext.breadcrumbs} />
      <CollectionDetails collectionDetails={collectionInformation} awsAccountId={awsAccountId}/>
      <WorkersListForAccount
        workersListForAccountLoading={searchWorkersListForCollectionLoading}
        workersListForAccount={
          searchWorkersListForCollection
            ? searchWorkersListForCollection.getWorkersListForCollection
            : []
        }
        dataPlaneType="Search"
        awsAccountId={awsAccountId}
      />
      <WorkersListForAccount
        workersListForAccountLoading={indexingWorkersListForCollectionLoading}
        workersListForAccount={
          indexingWorkersListForCollection
            ? indexingWorkersListForCollection.getWorkersListForCollection
            : []
        }
        dataPlaneType="Indexing"
        awsAccountId={awsAccountId}
      />
      <JunoCollectionDashboards
          awsAccountId={awsAccountId}
          collectionId={collectionId}
      />
      <Policies
          awsAccountId={awsAccountId}
          accessDataPoliciesLoading={AccessPoliciesLoading}
          accessDataPoliciesData = {AccessPoliciesData}

          securityNetworkPoliciesLoading={SecurityNetworkPoliciesLoading}
          securityNetworkPoliciesData = {SecurityNetworkPoliciesData}

          securityEncryptionPoliciesLoading={SecurityEncryptionPoliciesLoading}
          securityEncryptionPoliciesData = {SecurityEncryptionPoliciesData}
      />
    </SpaceBetween>
  );
};

export { CollectionOverview };
