import React from 'react';
import { ClusterDetails } from './ClusterDetails';
import { ClusterAuditHistory } from './ClusterAuditHistory';

import { EuiButton, EuiFormRow, EuiFieldText } from '@elastic/eui';

export class ClusterTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clusterName: '',
      clusterDisplay: false,
    };
  }

  onChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
  };

  submitHandler = () => {
    this.setState({ content: '' }, () => {
      this.setState({ content: this.getClusterContent() });
    });
  };

  getClusterContent() {
    return (
      <div>
        <ClusterDetails
          stackName={this.props.stackName}
          clusterId={this.state.clusterName}
        />
        <br />
        <ClusterAuditHistory
          stackName={this.props.stackName}
          clusterId={this.state.clusterName}
        />
      </div>
    );
  }

  render() {
    return (
      <div>
        <br />
        <br />
        <EuiFormRow
          id="clusterName"
          onChange={this.onChange}
          label="Cluster Name"
          helpText="Enter the cluster name."
        >
          <EuiFieldText name="clusterName" />
        </EuiFormRow>
        <EuiFormRow>
          <EuiButton onClick={this.submitHandler} id="submit">
            {' '}
            submit{' '}
          </EuiButton>
        </EuiFormRow>
        <br />
        <br />
        {this.state.content}
      </div>
    );
  }
}
