import { ButtonDropdown } from '@amzn/awsui-components-react';
import { useMutation } from '@apollo/client';
import { EuiConfirmModal, EuiOverlayMask } from '@elastic/eui';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import React, { useCallback, useState } from 'react';
import { useToastNotifications } from '../../hooks/useToastList';
import { CommonDomainProps, ESEC2 } from '../../models/types';
import { EXECUTE_INSTANCE_COMMAND } from './NodeList/hooks/useExecuteCommandMutation';

const ShowConfirmModal = (props: any) => (
  <EuiOverlayMask>
    <EuiConfirmModal
      title={props.title}
      onCancel={props.onClose}
      onConfirm={props.onConfirm}
      cancelButtonText={props.cancelText}
      confirmButtonText={props.confirmText}
      buttonColor={props.color}
      defaultFocusedButton="cancel"
    >
      {props.decription}
    </EuiConfirmModal>
  </EuiOverlayMask>
);

const actions = [
  {
    text: 'Recover domain',
    label: 'Recover domain',
    mutating: true,
    disabled: false,
    key: 'recoverDomain',
    id: 'recoverDomain',
  },
  {
    text: 'Bounce Kibana',
    label: 'Bounce Kibana',
    mutating: true,
    disabled: false,
    key: 'bounceKibana',
    id: 'bounceKibana',
  },
];

const actionMapping = {
  bounceKibana: {
    title: 'Are you sure you want to bounce Kibana?',
    executeCommand: async (
      executeCommand: any,
      domainIdentifier: string,
      toastNotifications: any
    ) => {
      let currentNotification: Toast = {
        id: '',
      };
      try {
        currentNotification = toastNotifications.addWarning({
          title: 'Deactivating Kibana env',
          color: 'warning',
          toastLifeTimeMs: 3000000,
          iconType: 'loading',
        });
        await executeCommand({
          variables: {
            url: 'aes/domain/dp/deactivate-kibana',
            args: {
              domain_identifier: domainIdentifier,
            },
            approval: {
              mode: 'bypass',
            },
            confirm: {
              is_confirmed: true,
            },
          },
        });
        toastNotifications.remove(currentNotification);
        currentNotification = toastNotifications.addWarning({
          title: 'Activating Kibana',
          color: 'warning',
          toastLifeTimeMs: 3000000,
          iconType: 'loading',
        });
        await executeCommand({
          variables: {
            url: 'aes/domain/dp/activate-kibana',
            args: {
              domain_identifier: domainIdentifier,
            },
            approval: {
              mode: 'bypass',
            },
            confirm: {
              is_confirmed: true,
            },
          },
        });
        toastNotifications.remove(currentNotification);
      } catch (e) {
        toastNotifications.remove(currentNotification);
        toastNotifications.addDanger(
          'Something went wrong unable to bounce Kibana'
        );
      }
    },
  },
  recoverDomain: {
    title: 'Are you sure you want to recover domain?',
    executeCommand: async (
      executeCommand: any,
      domainIdentifier: string,
      toastNotifications: any
    ) => {
      let currentNotification: Toast = {
        id: '',
      };
      try {
        currentNotification = toastNotifications.addWarning({
          title: 'Recovering domain',
          color: 'warning',
          toastLifeTimeMs: 3000000,
          iconType: 'loading',
        });
        await executeCommand({
          variables: {
            url: 'aes/domain/cp/recover-domain',
            args: {
              domain_identifier: domainIdentifier,
            },
          },
        });
        toastNotifications.remove(currentNotification);
      } catch (e) {
        toastNotifications.remove(currentNotification);
        toastNotifications.addDanger(
          'Something went wrong unable to recover domain'
        );
      }
    },
  },
};

interface DomainActionsProps extends CommonDomainProps {
  ec2Instances: ESEC2[];
}

const DomainActions = (props: DomainActionsProps) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const toastNotifications = useToastNotifications();
  const [selectedAction, setSelectedAction] = useState(
    '' as keyof typeof actionMapping
  );
  const [executeCommand] = useMutation(EXECUTE_INSTANCE_COMMAND);
  const handleDomainActionsClick = (key: any) => {
    setSelectedAction(key);
    setShowConfirmModal(true);
  };

  const handleExecuteCommand = useCallback(async () => {
    try {
      setShowConfirmModal(false);
      actionMapping[selectedAction].executeCommand(
        executeCommand,
        props.domainIdentifier,
        toastNotifications
      );
    } catch (e) {
      // handle
    }
  }, [
    selectedAction,
    executeCommand,
    props.domainIdentifier,
    toastNotifications,
  ]);
  const handleCancel = () => {
    setShowConfirmModal(false);
  };

  return (
    <React.Fragment>
      {showConfirmModal ? (
        <ShowConfirmModal
          onClose={handleCancel}
          onConfirm={handleExecuteCommand}
          title={actionMapping[selectedAction].title}
          color={'danger'}
          cancelText={'Cancel'}
          confirmText={'Confirm'}
        />
      ) : null}
      <ButtonDropdown
        items={actions}
        onItemClick={(d) => handleDomainActionsClick(d.detail.id)}
      >
        Actions
      </ButtonDropdown>
    </React.Fragment>
  );
};

export { DomainActions };
