import { BreadcrumbGroup, SpaceBetween, TextContent } from '@amzn/awsui-components-react';
import {useContext, useEffect} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { get } from 'lodash';
import { JunoAccountOverviewParams } from '../../../models/types';
import { BREADCRUMBS } from '../../../utils/constants';
import { JUNO_BASE_PATH } from '../../commons/constants';
import { useGetAccountCollectionsListQuery } from '../../hooks/Account/useGetAccountCollectionsListQuery';
import { useGetWorkersListForAccountQuery } from '../../hooks/Account/useGetWorkersListForAccountQuery';
import { useGetCPCellNameQuery } from '../../hooks/Account/useGetCPCellNameQuery';
import { useGetDPCellNameQuery } from '../../hooks/Account/useGetDPCellNameQuery';
import { useGetPoliciesStatsQuery } from '../../hooks/Account/useGetPoliciesStatsQuery';
import { useListAccessPoliciesQuery } from '../../hooks/Account/useListAccessPoliciesQuery';
import { useListSecurityPoliciesQuery } from "../../hooks/Account/useListSecurityPoliciesQuery";
import { useListSecurityConfigsQuery } from "../../hooks/Account/useListSecurityConfigsQuery";
import { AppContext } from './../../../utils/appContext';
import { CollectionsList } from './CollectionsList';
import { WorkersListForAccount } from './WorkersListForAccount';
import { AccountDetails } from './AccountDetails';
import { PoliciesStats } from "./PoliciesStats";
import { Policies } from "./Policies";
import { JunoAccountDashboards } from './Dashboards/JunoAccountDashboards'
import {useGetLazyExternalCwQuery} from "../../Components/Cloudwatch/hooks/useGetExternalCwQuery";
import {OCU_METRICS} from "./Dashboards/constants";
import { useGetAccountSettingsQuery } from '../../hooks/Account/useGetAccountSettingsQuery';

const JunoAccountOverview = (props) => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const { awsAccountId } = useParams<JunoAccountOverviewParams>();
  const [
    getCPCellName,
    { loading: CPCellNameLoading, data: CPCellName },
  ] = useGetCPCellNameQuery();

  const [
    getDPCellName,
    { loading: DPCellNameLoading, data: DPCellName },
  ] = useGetDPCellNameQuery();

  const [
    getSearchOCUCount,
    { loading: SearchOCUsLoading, data: searchOCUs },
  ] = useGetLazyExternalCwQuery();

  const [
    getIndexingOCUCount,
    { loading: IndexingOCUsLoading, data: indexingOCUs },
  ] = useGetLazyExternalCwQuery();

  const [
    getAccountCollectionsList,
    { loading: accountCollectionListLoading, data: accountCollectionList },
  ] = useGetAccountCollectionsListQuery();

  const [
    getSearchWorkersListForAccount,
    { loading: searchWorkersListForAccountLoading, data: searchWorkersListForAccount },
  ] = useGetWorkersListForAccountQuery();

  const [
    getIndexingWorkersListForAccount,
    { loading: indexingWorkersListForAccountLoading, data: indexingWorkersListForAccount },
  ] = useGetWorkersListForAccountQuery();

  const [
    getPoliciesStats,
    { loading: PoliciesStatsLoading, data: PoliciesStatsData },
  ] = useGetPoliciesStatsQuery();

  const [
    listAccessPolicies,
    { loading: AccessPoliciesLoading, data: AccessPoliciesData },
  ] = useListAccessPoliciesQuery();

  const [
    listSecurityNetworkPolicies,
    { loading: SecurityNetworkPoliciesLoading, data: SecurityNetworkPoliciesData },
  ] = useListSecurityPoliciesQuery();

  const [
    listSecurityEncryptionPolicies,
    { loading: SecurityEncryptionPoliciesLoading, data: SecurityEncryptionPoliciesData },
  ] = useListSecurityPoliciesQuery();

  const [
    listSecurityConfigs,
    { loading: SecurityConfigsLoading, data: SecurityConfigsData },
  ] = useListSecurityConfigsQuery();

  const [
    getAccountSettings,
    { loading: AccountSettingsLoading, data: AccountSettingsData },
  ] = useGetAccountSettingsQuery();

  useEffect(() => {
    appContext.setBreadcrumbs([
      {
        ...BREADCRUMBS.JUNO,
        href: `${JUNO_BASE_PATH}`,
        onClick: () => {
          history.push(`${JUNO_BASE_PATH}`);
        },
      },
      { href: `${JUNO_BASE_PATH}/${awsAccountId}`, text: awsAccountId },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAccountCollectionsList({
      variables: {
        awsAccountId: awsAccountId,
      },
    });
    getCPCellName({
      variables: {
        awsAccountId: awsAccountId,
      },
    })
    getDPCellName({
      variables: {
        awsAccountId: awsAccountId,
      },
    })
    var curr_time = new Date();
    var start_time = new Date();
    start_time.setHours(curr_time.getHours() - 2);
    getSearchOCUCount({
      variables: {
        namespace: 'AOSSBeholder/KAM/CxMetrics',
        metric_name: 'SearchOCU',
        dimensions: `[{"Name":"${OCU_METRICS.dimensionNames.AWS_ACCOUNT_ID}","Value":"${awsAccountId}"}]`,
        startTime: start_time,
        endTime: curr_time,
        period: 3600,
        statistics: 'Maximum',
      },
    });
    getIndexingOCUCount({
      variables: {
        namespace: 'AOSSBeholder/KAM/CxMetrics',
        metric_name: 'IndexingOCU',
        dimensions: `[{"Name":"${OCU_METRICS.dimensionNames.AWS_ACCOUNT_ID}","Value":"${awsAccountId}"}]`,
        startTime: start_time,
        endTime: curr_time,
        period: 3600,
        statistics: 'Maximum',
      },
    });
    getSearchWorkersListForAccount({
      variables: {
        awsAccountId: awsAccountId,
        dataPlaneType: "search"
      },
    });
    getIndexingWorkersListForAccount({
      variables: {
        awsAccountId: awsAccountId,
        dataPlaneType: "indexing"
      },
    });
    getPoliciesStats({
      variables: {
        awsAccountId: awsAccountId,
      },
    });
    listAccessPolicies({
      variables: {
        awsAccountId: awsAccountId,
        type: "data",
        resource: null
      },
    });
    listSecurityNetworkPolicies({
      variables: {
        awsAccountId: awsAccountId,
        type: "network",
        resource: null
      },
    });
    listSecurityEncryptionPolicies({
      variables: {
        awsAccountId: awsAccountId,
        type: "encryption",
        resource: null
      },
    });
    listSecurityConfigs({
      variables: {
        awsAccountId: awsAccountId,
        type: "saml"
      },
    });
    getAccountSettings({
      variables: {
        awsAccountId: awsAccountId,
      },
    });
  }, [awsAccountId]);

  const accountCollectionsCount = accountCollectionList ? accountCollectionList.getAccountCollectionsList.length : "--" ;

  return (
    <SpaceBetween direction="vertical" size="l">
      <BreadcrumbGroup items={appContext.breadcrumbs} />
      <TextContent>
        <h1>Account Overview</h1>
      </TextContent>
      <AccountDetails
          CPCellName={CPCellName}
          DPCellName={DPCellName}
          accountCollectionsCount={accountCollectionsCount}
          searchOCUsCount = {get(searchOCUs, 'getJunoCwMetrics.externalMetrics.values[0]', undefined)}
          indexingOCUsCount = {get(indexingOCUs, 'getJunoCwMetrics.externalMetrics.values[0]', undefined)}
          maxSearchOCUs = {get(AccountSettingsData, 'getAccountSettings.accountSettingsDetail.capacityLimits.maxSearchCapacityInOCU', undefined)}
          maxIndexingOCUs = {get(AccountSettingsData, 'getAccountSettings.accountSettingsDetail.capacityLimits.maxIndexingCapacityInOCU', undefined)}
      />
      <CollectionsList
        accountCollectionListLoading={accountCollectionListLoading}
        accountCollectionList={accountCollectionList}
        searchWorkersListForAccount={searchWorkersListForAccount ? searchWorkersListForAccount : []}
        indexingWorkersListForAccount={indexingWorkersListForAccount ? indexingWorkersListForAccount : []}
        awsAccountId={awsAccountId}
      />
      <WorkersListForAccount
        workersListForAccountLoading={searchWorkersListForAccountLoading}
        workersListForAccount={searchWorkersListForAccount ? searchWorkersListForAccount : []}
        dataPlaneType={"Search"}
        awsAccountId={awsAccountId}
      />
      <WorkersListForAccount
        workersListForAccountLoading={indexingWorkersListForAccountLoading}
        workersListForAccount={indexingWorkersListForAccount ? indexingWorkersListForAccount : []}
        dataPlaneType={"Indexing"}
        awsAccountId={awsAccountId}
      />
      <Policies
          awsAccountId={awsAccountId}

          accessDataPoliciesLoading = {AccessPoliciesLoading}
          accessDataPoliciesData = {AccessPoliciesData}

          securityNetworkPoliciesLoading = {SecurityNetworkPoliciesLoading}
          securityNetworkPoliciesData = {SecurityNetworkPoliciesData}

          securityEncryptionPoliciesLoading = {SecurityEncryptionPoliciesLoading}
          securityEncryptionPoliciesData = {SecurityEncryptionPoliciesData}

          includeSAML = {true}

          securityConfigsLoading = {SecurityConfigsLoading}
          securityConfigsData = {SecurityConfigsData}
      />
      <PoliciesStats
          data = {PoliciesStatsData ? PoliciesStatsData.getPoliciesStats : undefined}
      />
      <JunoAccountDashboards
          awsAccountId={awsAccountId}
      />
    </SpaceBetween>
  );
};

export { JunoAccountOverview };
