import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../../models/types';


export interface ListAccessPoliciesVars {
    awsAccountId: String;
    type: String;
    resource: String;
}

const LIST_ACCESS_POLICIES = gql`
	     query ListAccessPolicies(
	             $awsAccountId: String,
	             $type: String,
	             $resource: String
	         ){
	         listAccessPolicies(
	             awsAccountId: $awsAccountId,
	             type: $type,
	             resource: $resource
	         )
	     }
	 `;

export const useListAccessPoliciesQuery = () => {
    return useLazyQuery<AnyObject, ListAccessPoliciesVars>(LIST_ACCESS_POLICIES, {
        errorPolicy: 'all',
    });
};