import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Alert,
  Box,
  Button,
  ButtonDropdown,
  Cards,
  Header,
  Modal,
  PropertyFilter,
  SegmentedControl,
  SpaceBetween,
  Toggle,
} from '@amzn/awsui-components-react';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  EmptyState,
  getFilterCounterText,
} from './../../../utils/cardPreferences';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from './../../../utils/constants';
import {
  getEndTimeUtc,
  getInterval,
  getS3ObjectKey,
  getSelectedStartTimeUtc,
  getTmsItems,
  issueTypesToString,
} from './../../utils';
import { CardHeader } from './CardHeader';
import {
  CARD_FILTERING_PROPERTIES,
  CUSTOMER_IMPACT_CP_TMS_ISSUES,
  CUSTOMER_IMPACT_DP_TMS_ISSUES,
  TIME_INTERVAL_OPTIONS,
  TIME_WINDOW_OPTIONS,
} from './constants';
import { useTmsSvgDataFetchingQuery } from './homepageAllQuery';
import { isError } from "../../../ErrorHandler/apiErrorHandler";
import { LseErrorMessageComponent } from "../../../ErrorHandler/errorMessageComponent";
import { refreshData } from "../../../pages/DomainDetails/utils/refreshData";

const Homepage = (props) => {
  const history = useHistory();
  const [getTmsSvgObject, { loading: s3SvgLoading, data: s3SvgResponse, error: error, refetch }] =
    useTmsSvgDataFetchingQuery();
  const url = new URLSearchParams(useLocation().search);
  const timeWindow = url.get('timeWindow') ? url.get('timeWindow') : '3days';
  const [selectedTimeWindow, setSelectedTimeWindow] = useState(timeWindow);
  const [changedTimeWindow, setChangedTimeWindow] = useState('');
  const interval = url.get('interval') ? url.get('interval') : '5m';
  const [selectedInterval, setSelectedInterval] = useState(interval);
  const current = new Date();
  const endTimeUtc = url.get('endTimeUtc')
    ? url.get('endTimeUtc')
    : current.toISOString().substring(0, 19) + 'Z';
  const [selectedEndTimeUtc, setSelectedEndTimeUtc] = useState(
    getEndTimeUtc(new Date(endTimeUtc))
  );
  const [selectedIssueTypes, setSelectedIssueTypes] = useState([]);
  const [TMSChartItems, setTMSChartItems] = useState([]);
  const [filteredTMSChartItems, setFilteredTMSChartItems] = useState([]);
  const [compactView, setCompactView] = useState(false);
  const [showTMSIssueName, setShowTMSIssueName] = useState(true);
  const [customerImpactCPTmsSelector, setCustomerImpactCPTmsSelector] =
    useState(false);
  const [customerImpactDPTmsSelector, setCustomerImpactDPTmsSelector] =
    useState(false);
  const [showDpTmsIssues, setShowDpTmsIssues] = useState(false);
  const [showCpTmsIssues, setShowCpTmsIssues] = useState(false);
  const [showCarbonRelatedTmsIssues, setCarbonRelatedTmsIssues] = useState(false);
  const [cardPerRow, setCardPerRow] = useState([{ minWidth: 500, cards: 6 }]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [alertVisible, setAlertVisible] = React.useState(true);

  const callTmsSvgDataFetchingQuery = (s3Key: String) => {
    getTmsSvgObject({
      variables: {
        key: s3Key,
      },
    });
  };

  const isErrorPresent = isError(error)
   useEffect(() => {
    if ( isErrorPresent ) {
         setErrorMessage("Unable to get LSE details. Please refresh the page or try again later");
    }
  }, [isErrorPresent, error]);
   const handleRefreshData = async () => {
    await refreshData(refetch, setIsFetching, setErrorMessage, isFetching, isErrorPresent, error, 'lse');
  };

  const getDetails = () => {
    if (selectedIssueTypes.length === 0) {
      setNextButtonModalVisible(true);
    } else if (selectedIssueTypes.length > 15) {
      setFilterCountRestrictionModalVisible(true)
    } else {
      const selectedItems = issueTypesToString(selectedIssueTypes);
      const selectedStartTimeUtc = getSelectedStartTimeUtc(
        selectedEndTimeUtc,
        selectedTimeWindow
      );
      history.push(
        `/lse-tool/details?endTimeUtc=${selectedEndTimeUtc}&timeWindow=${selectedTimeWindow}&interval=${selectedInterval}&issueTypes=${selectedItems}&lseStartTimeUtc=${selectedStartTimeUtc}&lseEndTimeUtc=${selectedEndTimeUtc}`
      );
    }
  };

  useEffect(() => {
    if (s3SvgResponse && !s3SvgLoading) {
      const interval = getInterval(selectedTimeWindow);
      const s3Response = get(s3SvgResponse, 'tmsSvgData.s3Response', {});
      let data = {};
      if (!Array.isArray(s3Response) && JSON.stringify(s3Response) !== '{}') {
        data = JSON.parse(s3Response);
      }
      const newTmsItems = getTmsItems(data);
      setTMSChartItems(newTmsItems);
      setSelectedIssueTypes([]);
      history.push(
        `/lse-tool?endTimeUtc=${selectedEndTimeUtc}&timeWindow=${selectedTimeWindow}&interval=${interval}`
      );
    }
    // On the initial page loading, s3SvgResponse is undefined and s3SvgLoading is false.
    if (!s3SvgResponse && !s3SvgLoading) {
      const defaultS3Key = getS3ObjectKey(
        selectedEndTimeUtc,
        selectedTimeWindow,
        selectedInterval
      );
      callTmsSvgDataFetchingQuery(defaultS3Key);
    }
    // eslint-disable-next-line
  }, [s3SvgLoading, s3SvgResponse]);

  const changeTimeWindow = (timeWindow) => {
    setTimeWindowModalVisible(true);
    setChangedTimeWindow(timeWindow);
  };

  const changeCompactView = (checked) => {
    setCompactView(checked);
    setShowTMSIssueName(!checked);
    if (checked === true) {
      setCardPerRow([{ minWidth: 500, cards: 12 }]);
    } else {
      setCardPerRow([{ minWidth: 500, cards: 6 }]);
    }
  };
  const toggleCustomerImpactTms = (checked: boolean, plane: string) => {
    let customerImpacts: any[];
    if (plane === 'cp') {
      setCustomerImpactCPTmsSelector(checked);
      customerImpacts = items.filter((tms) =>
        CUSTOMER_IMPACT_CP_TMS_ISSUES.includes(tms.name)
      );
    } else if (plane === 'dp') {
      setCustomerImpactDPTmsSelector(checked);
      customerImpacts = items.filter((tms) =>
        CUSTOMER_IMPACT_DP_TMS_ISSUES.includes(tms.name)
      );
    } else {
      console.error(`Invalid plane name "${plane}"`);
    }
    if (checked === true) {
      customerImpacts.forEach((issue) =>
        setSelectedIssueTypes((current) => [...current, issue])
      );
    } else {
      setSelectedIssueTypes((current) =>
        current.filter(
          (issue) =>
            !customerImpacts.map((value) => value.name).includes(issue.name)
        )
      );
    }
  };

  const updateFilteredTMSChartItems = (filterString, checked, setShowIssues) => {
    if (checked) {
      const filteredItems = TMSChartItems.filter((tms) => tms.name.startsWith(filterString));
      setFilteredTMSChartItems((prevItems) => [...prevItems, ...filteredItems]);
      setShowIssues(true);
    } else {
      setFilteredTMSChartItems((prevItems) =>
        prevItems.filter((tms) => !tms.name.startsWith(filterString))
      );
      setShowIssues(false);
    }
    setFilteredTMSChartItems((prevItems) => Array.from(new Set(prevItems)));
  };

  let { items, actions, filteredItemsCount, propertyFilterProps } =
    useCollection(props.isLoading ? [] : filteredTMSChartItems.length === 0 ? TMSChartItems: filteredTMSChartItems , {
      propertyFiltering: {
        filteringProperties: CARD_FILTERING_PROPERTIES,
        empty: (
          <EmptyState
            title="No issue types"
            subtitle="No issue types to display."
            action={<span></span>}
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={
              <Button onClick={() => actions.setFiltering('')}>
                Clear filter
              </Button>
            }
          />
        ),
      },
      sorting: {},
      selection: {},
    });
  const [timeWindowModalVisible, setTimeWindowModalVisible] =
    React.useState(false);
  const [nextButtonModalVisible, setNextButtonModalVisible] =
    React.useState(false);
  const [filterCountRestrictionModalVisible, setFilterCountRestrictionModalVisible] = React.useState(false);
  const handleTimeWindowModalOk = () => {
    setSelectedTimeWindow(changedTimeWindow);
    const interval = getInterval(changedTimeWindow);
    setSelectedInterval(interval);
    const endTimeUtc = getEndTimeUtc(new Date());
    setSelectedEndTimeUtc(endTimeUtc);
    const s3Key = getS3ObjectKey(endTimeUtc, changedTimeWindow, interval);
    callTmsSvgDataFetchingQuery(s3Key);
    setTimeWindowModalVisible(false);
  };
  const handleNextButtonModalOk = () => {
    setNextButtonModalVisible(false);
  };
  const filterCountRestrictionModalVisibleOk = () => {
    setFilterCountRestrictionModalVisible(false)
  }
  const handleTimeWindowModalCancel = () => {
    setTimeWindowModalVisible(false);
  };
  return (
    <SpaceBetween size="xs">
      <Alert
        onDismiss={() => setAlertVisible(false)}
        visible={alertVisible}
        header="TIPS:"
        dismissAriaLabel="Close alert"
        dismissible
      >
        <ol>
          <li>
            1. To view any graphs in more detail, please select them below and
            hit "Next."
          </li>
           <li>
            2. You can select maximum upto 15 filters at once.
          </li>
          <li>
            3. By default, the graphs depict a 3-day time window ending on the
            current day. You can modify the end time directly by editing the
            "endTimeUTC" parameter in the URI.
          </li>
          <li>
            4. Amazon OpenSearch Service LSE tool 2.0 Live is delayed by 15-20
            minutes. The delay does not apply to historical searches.
          </li>
          <li>
            5. TMS issues are reported every 5 minutes and HEAT/Alpine rules are
            reported every 1 minute.
          </li>
        </ol>
      </Alert>
      <Header
        variant="h2"
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <PropertyFilter
              className="input-filter"
              i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
              {...propertyFilterProps}
              countText={getFilterCounterText(filteredItemsCount)}
            />
            <SegmentedControl
              selectedId={selectedTimeWindow}
              onChange={({ detail }) => changeTimeWindow(detail.selectedId)}
              label="Default segmented control"
              options={TIME_WINDOW_OPTIONS}
            />
            <ButtonDropdown
              onItemClick={({ detail }) => setSelectedInterval(detail.id)}
              items={TIME_INTERVAL_OPTIONS}
              disabled
            >
              Interval
            </ButtonDropdown>
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="primary" onClick={getDetails}>
                  Next
                </Button>
               <Button iconName="refresh" disabled={isFetching} onClick={handleRefreshData}/>
              </SpaceBetween>
          </SpaceBetween>
        }
      >
        <SpaceBetween direction="horizontal" size="xs">
          <h3>TMS Issues and HEAT/Alpine Rules</h3>
          <Toggle
            onChange={({ detail }) =>
              toggleCustomerImpactTms(detail.checked, 'cp')
            }
            checked={customerImpactCPTmsSelector}
          >
            Select customer impact CP TMS issues
          </Toggle>
          <Toggle
            onChange={({ detail }) =>
              toggleCustomerImpactTms(detail.checked, 'dp')
            }
            checked={customerImpactDPTmsSelector}
          >
            Select customer impact DP TMS issues
          </Toggle>
          <Toggle
            onChange={({ detail }) =>
              updateFilteredTMSChartItems('dp', detail.checked, setShowDpTmsIssues)
            }
            checked={showDpTmsIssues}
          >
            Show DP TMS issues
          </Toggle>
           <Toggle
            onChange={({ detail }) =>
              updateFilteredTMSChartItems('cp', detail.checked, setShowCpTmsIssues)
            }
            checked={showCpTmsIssues}
          >
            Show CP TMS issues
          </Toggle>
           <Toggle
            onChange={({ detail }) =>
              updateFilteredTMSChartItems('dp:ha:', detail.checked, setCarbonRelatedTmsIssues)
            }
            checked={showCarbonRelatedTmsIssues}
          >
            Show Carbon TMS issues
          </Toggle>
        </SpaceBetween>
      </Header>
      {errorMessage ? (
        <LseErrorMessageComponent errorMessage={errorMessage} />
      ) : (
          <>
            {isFetching ? (<div style={{ textAlign: 'center' }}>Fetching resources...</div>) :(
              <Cards
        trackBy="name"
        cardDefinition={{
          header: (e) => (
            <CardHeader cardName={e.name} display={showTMSIssueName} />
          ),
          sections: [
            {
              id: 'description',
              content: (e) => e.description,
            },
            {
              id: 'name',
              content: (e) => e.name,
            },
          ],
        }}
        onSelectionChange={({ detail }) =>
          setSelectedIssueTypes(detail.selectedItems)
        }
        selectedItems={selectedIssueTypes}
        cardsPerRow={cardPerRow}
        items={items}
        loadingText="Loading resources"
        selectionType="multi"
        visibleSections={['description']}
        empty={
          <Box textAlign="center" color="inherit">
            <b>No resources</b>
            <Box variant="p" color="inherit">
              No resources to display.
            </Box>
          </Box>
        }
      />
            )}
      <Modal
        onDismiss={() => setTimeWindowModalVisible(false)}
        visible={timeWindowModalVisible}
        size="large"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={handleTimeWindowModalCancel}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleTimeWindowModalOk}>
                Ok
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <h3>
          Note: changing the time window will remove any graph selections you
          have made. Do you want to continue?
        </h3>
      </Modal>
      <Modal
        onDismiss={() => setNextButtonModalVisible(false)}
        visible={nextButtonModalVisible}
        size="large"
        footer={
          <Box float="right">
            <Button variant="primary" onClick={handleNextButtonModalOk}>
              Ok
            </Button>
          </Box>
        }
      >
        <h3>
          Note: To view in more detail, please select at least one of the graphs
          and hit "Next."
        </h3>
      </Modal>
      <Modal
        onDismiss={() => setFilterCountRestrictionModalVisible(false)}
        visible={filterCountRestrictionModalVisible}
        size="large"
        footer={
          <Box float="right">
            <Button variant="primary" onClick={filterCountRestrictionModalVisibleOk}>
              Ok
            </Button>
          </Box>
        }
      >
        <h3>
          You can select maximum upto 15 filters at once.
        </h3>
      </Modal>
      </>
       )}
    </SpaceBetween>
  );
};

export { Homepage };
