import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../../models/types';


export interface ListSecurityPoliciesVars {
    awsAccountId: String;
    type: String;
    resource: String;
}

const LIST_SECURITY_POLICIES = gql`
	     query ListSecurityPolicies(
	             $awsAccountId: String,
	             $type: String,
	             $resource: String,
	         ){
	         listSecurityPolicies(
	             awsAccountId: $awsAccountId,
	             type: $type,
	             resource: $resource
	         )
	     }
	 `;

export const useListSecurityPoliciesQuery = () => {
    return useLazyQuery<AnyObject, ListSecurityPoliciesVars>(LIST_SECURITY_POLICIES, {
        errorPolicy: 'all',
    });
};