import { React } from 'react';
import Box from "@amzn/awsui-components-react/polaris/box";
import { usePutCwMetrics } from './hooks/usePutCwMetrics';
import { usePutCwLogs } from "./hooks/usePutCwLogs";
import getDomainIdentifierFromParams from "../ErrorHandler/utils";

interface fallbackProps {
    failedComponent: string,
    error?: Error,
}

export const Fallback: React.FC<{ error: Error, resetErrorBoundary: () => void }> = (props: fallbackProps) => {

    usePutCwLogs({
        logsMessage: " Error caught by ErrorBoundary: " + props.error.stack ,domainIdent: getDomainIdentifierFromParams(), apiName: props.failedComponent
    })

    //Publishing CW metric for UI failure.
    usePutCwMetrics({
        failureType: props.failedComponent,
    });

    function EmptyState({ title, subtitle, action }) {
        return (
            <Box textAlign="center" color="inherit">
                <Box variant="strong" textAlign="center" color="inherit">
                    {title}
                </Box>
                <Box variant="p" padding={{ bottom: 's' }} color="inherit">
                    {subtitle}
                </Box>
                {action}
            </Box>
        );
    }

    return (
        <EmptyState
            title={"UIComponentCrash: " + props.failedComponent}
            subtitle={"Error occurred while fetching information to " + props.failedComponent}
            action={<span></span>}
        />
    );
}

export const LseFallback: React.FC<{ resetErrorBoundary: () => void }> = (props: fallbackProps) => {

    usePutCwMetrics({
        failureType: props.failedComponent,
    });
    function EmptyState({ title, subtitle, action }) {
        return (
            <Box textAlign="center" color="inherit">
                <Box variant="strong" textAlign="center" color="inherit">
                    {title}
                </Box>
                <Box variant="p" padding={{ bottom: 's' }} color="inherit">
                    {subtitle}
                </Box>
                {action}
            </Box>
        );
    }

    return (
        <EmptyState
            title={"UIComponentCrash: " + props.failedComponent}
            subtitle={"Error occurred while fetching information to " + props.failedComponent}
            action={<span></span>}
        />
    );
}

