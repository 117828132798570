import { Container, Grid, Header } from '@amzn/awsui-components-react/polaris';
import { AggregationTable } from './AggregationTable';
import {
  AZ_COLUMN_DEFINITIONS,
  ES_VERSION_COLUMN_DEFINITIONS,
  INSTANCE_COUNT_COLUMN_DEFINITIONS,
  INSTANCE_TYPE_COLUMN_DEFINITIONS,
  IS_VPC_COLUMN_DEFINITIONS,
  NUM_OF_AZ_COLUMN_DEFINITIONS,
  SLA_COLUMN_DEFINITIONS,
  VFI_COLUMN_DEFINITIONS,
  STANDBY_COLUMN_DEFINITIONS,
} from './constants';

function getAggregationTable(field, data) {
  const ret = [];
  let rank = 1;
  let sum = 0;
  for (const prop in data) {
    sum = sum + data[prop];
  }
  for (const prop in data) {
    const barWidth = (data[prop] / sum) * 100;
    const tmp = {
      rank: rank,
      data: (
        <div className="counts">
          <div style={{ float: 'left', width: '50px', marginRight: '10px' }}>
            {data[prop]}
          </div>
          <div
            className="table-value-bar"
            style={{ display: 'inline-block', width: 'calc(100% - 50px)' }}
          >
            <div
              style={{
                width: barWidth.toFixed(1) + '%',
                backgroundColor: '#2ea597',
                borderRadius: '4px',
                position: 'relative',
              }}
            >
              {barWidth.toFixed(1) + '%'}
            </div>
          </div>
        </div>
      ),
    };
    tmp[field] = prop;
    ret.push(tmp);
    rank = rank + 1;
    if (rank === 6) {
      break;
    }
  }
  return ret;
}

const AggregationTableView = (props) => {
  // es version
  const esVersion = getAggregationTable(
    'esVersion',
    props.aggregations.esVersion
  );
  // vfi
  const vfi = getAggregationTable('vfi', props.aggregations.vfi);
  // instance type
  const instanceType = getAggregationTable(
    'instanceType',
    props.aggregations.instanceType
  );
  // instance count
  const instanceCount = getAggregationTable(
    'instanceCount',
    props.aggregations.instanceCount
  );
  // is vpc
  const isVpc = getAggregationTable('isVpc', props.aggregations.isVpc);
  // number of az
  const numOfAz = getAggregationTable('numOfAz', props.aggregations.numOfAz);
  // az
  const az = getAggregationTable('az', props.aggregations.az);
  // sla
  const sla = getAggregationTable('sla', props.aggregations.sla);
  // standbyEnabled
  const standByEnabled = getAggregationTable('standByEnabled', props.aggregations.standByEnabled);

  return (
    <Container
      header={
        <Header
          variant="h2"
          description={`Time window from ${props.request.lseStartTime} to ${props.request.lseEndTime}`}
        >
          Impacted Domains Config Aggregations
        </Header>
      }
    >
      <Grid gridDefinition={[{ colspan: 4 }, { colspan: 8 }]}>
        {/* ES Version */}
        <AggregationTable
          columnDefinitions={ES_VERSION_COLUMN_DEFINITIONS}
          items={esVersion}
        />
        {/* standByEnabled */}
        <AggregationTable
          columnDefinitions={STANDBY_COLUMN_DEFINITIONS}
          items={standByEnabled}
        />
      </Grid>
      <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
        {/* AZ */}
        <AggregationTable
          columnDefinitions={AZ_COLUMN_DEFINITIONS}
          items={az}
        />
        {/* Instance Type */}
        <AggregationTable
          columnDefinitions={INSTANCE_TYPE_COLUMN_DEFINITIONS}
          items={instanceType}
        />
        {/* Instance Count */}
        <AggregationTable
          columnDefinitions={INSTANCE_COUNT_COLUMN_DEFINITIONS}
          items={instanceCount}
        />
      </Grid>
      <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
        {/* # of AZ */}
        <AggregationTable
          columnDefinitions={NUM_OF_AZ_COLUMN_DEFINITIONS}
          items={numOfAz}
        />
        {/* Is Vpc */}
        <AggregationTable
          columnDefinitions={IS_VPC_COLUMN_DEFINITIONS}
          items={isVpc}
        />
        {/* SLA */}
        <AggregationTable
          columnDefinitions={SLA_COLUMN_DEFINITIONS}
          items={sla}
        />
      </Grid>
      <Grid gridDefinition={[{ colspan: 12 }]}>
        {/* VFI */}
        <AggregationTable
          columnDefinitions={VFI_COLUMN_DEFINITIONS}
          items={vfi}
        />
      </Grid>
    </Container>
  );
};

export { AggregationTableView };
