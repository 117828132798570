// This info extracted form ripcli.
export interface RegionInfo {
  airportCode: string;
  regionName: string;
  publicRegion: string;
  partition?: string;
  clientId?: string; // TODO Take this from EC2 user data or stanza file
  isOptInRegion?: boolean;
  isBorderRegion?: boolean;
}

export const regionInfo: RegionInfo[] = [
  {
    airportCode: 'APA',
    regionName: 'US ISO WEST',
    publicRegion: 'us-iso-west-1',
    partition: 'aws-iso',
    isBorderRegion: true
  },
  {
    airportCode: 'ARN',
    regionName: 'Europe (Stockholm)',
    publicRegion: 'eu-north-1',
    clientId: '436472792970',
    partition: 'aws',
    isBorderRegion: true
  },
  {
    airportCode: 'BAH',
    regionName: 'Middle East (Bahrain)',
    publicRegion: 'me-south-1',
    clientId: '423649034381',
    isOptInRegion: true,
    partition: 'aws',
    isBorderRegion: true
  },
  {
    airportCode: 'BJS',
    regionName: 'China (Beijing)',
    publicRegion: 'cn-north-1',
    partition: 'aws-cn',
    isBorderRegion: true
  },
  {
    airportCode: 'BOM',
    regionName: 'Asia Pacific (Mumbai)',
    publicRegion: 'ap-south-1',
    clientId: '436472792970',
    partition: 'aws',
    isBorderRegion: true
  },
  {
    airportCode: 'CDG',
    regionName: 'Europe (Paris)',
    publicRegion: 'eu-west-3',
    clientId: '436472792970',
    partition: 'aws',
    isBorderRegion: true
  },
  {
    airportCode: 'CGK',
    regionName: 'Asia Pacific (Jakarta)',
    publicRegion: 'ap-southeast-3',
    clientId: '436472792970',
    partition: 'aws',
    isBorderRegion: true
  },
  {
    airportCode: 'CMH',
    regionName: 'US East (Ohio)',
    publicRegion: 'us-east-2',
    clientId: '436472792970',
    partition: 'aws',
    isBorderRegion: true
  },
  {
    airportCode: 'CPT',
    regionName: 'Africa (Cape Town)',
    publicRegion: 'af-south-1',
    clientId: '694393327865',
    isOptInRegion: true,
    partition: 'aws',
    isBorderRegion: true
  },
  {
    airportCode: 'DCA',
    regionName: 'US ISO East',
    publicRegion: 'us-iso-east,-1',
    partition: 'aws-iso',
    isBorderRegion: true
  },
  {
    airportCode: 'DUB',
    regionName: 'Europe (Ireland)',
    publicRegion: 'eu-west-1',
    clientId: '436472792970',
    partition: 'aws'
  },
  {
    airportCode: 'DXB',
    regionName: 'Middle East (UAE)',
    publicRegion: 'me-central-1',
    partition: 'aws',
    isBorderRegion: true
  },
  {
    airportCode: 'FRA',
    regionName: 'Europe (Frankfurt)',
    publicRegion: 'eu-central-1',
    clientId: '436472792970',
    partition: 'aws',
    isBorderRegion: true
  },
  {
    airportCode: 'GRU',
    regionName: 'South America (Sao Paulo)',
    publicRegion: 'sa-east-1',
    clientId: '436472792970',
    partition: 'aws'
  },
  {
    airportCode: 'HEF',
    regionName: 'US ISOD East',
    publicRegion: 'us-isod-east,-1',
    partition: 'aws-iso-d',
    isBorderRegion: true
  },
  {
    airportCode: 'HKG',
    regionName: 'Asia Pacific (Hong Kong)',
    publicRegion: 'ap-east-1',
    clientId: '313171305434',
    isOptInRegion: true,
    partition: 'aws',
    isBorderRegion: true
  },
  {
    airportCode: 'HYD',
    regionName: 'Asia Pacific (Hyderabad)',
    publicRegion: 'ap-south-2',
    partition: 'aws',
    isBorderRegion: true
  },
  {
    airportCode: 'IAD',
    regionName: 'US East (N. Virginia)',
    publicRegion: 'us-east-1',
    clientId: '436472792970',
    partition: 'aws'
  },
  {
    airportCode: 'ICN',
    regionName: 'Asia Pacific (Seoul)',
    publicRegion: 'ap-northeast-2',
    clientId: '436472792970',
    partition: 'aws',
    isBorderRegion: true
  },
  {
    airportCode: 'JYO',
    regionName: 'US ISOC East',
    publicRegion: 'us-isoc-east,-1',
    partition: 'aws-iso-c',
    isBorderRegion: true
  },
  {
    airportCode: 'KIX',
    regionName: 'Asia Pacific (Osaka-Local)',
    publicRegion: 'ap-northeast-3',
    partition: 'aws',
    isBorderRegion: true
  },
  {
    airportCode: 'LCK',
    regionName: 'US ISOB East (Ohio)',
    publicRegion: 'us-isob-east-1',
    partition: 'aws-iso-b',
    isBorderRegion: true
  },
  {
    airportCode: 'LHR',
    regionName: 'Europe (London)',
    publicRegion: 'eu-west-2',
    clientId: '436472792970',
    partition: 'aws',
    isBorderRegion: true
  },
  {
    airportCode: 'MEL',
    regionName: 'Asia Pacific (Melbourne)',
    publicRegion: 'ap-southeast-4',
    partition: 'aws',
    isBorderRegion: true
  },
  {
    airportCode: 'MXP',
    regionName: 'Europe (Milan)',
    publicRegion: 'eu-south-1',
    clientId: '154748883681',
    isOptInRegion: true,
    partition: 'aws',
    isBorderRegion: true
  },
  {
    airportCode: 'NRT',
    regionName: 'Asia Pacific (Tokyo)',
    publicRegion: 'ap-northeast-1',
    clientId: '436472792970',
    partition: 'aws'
  },
  {
    airportCode: 'OSU',
    regionName: 'AWS GovCloud (US-East)',
    publicRegion: 'us-gov-east-1',
    partition: 'aws-us-gov',
    isBorderRegion: true
  },
  {
    airportCode: 'PDT',
    regionName: 'AWS GovCloud (US-West)',
    publicRegion: 'us-gov-west-1',
    partition: 'aws-us-gov',
    isBorderRegion: true
  },
  {
    airportCode: 'PDX',
    regionName: 'US West (Oregon)',
    publicRegion: 'us-west-2',
    clientId: '436472792970',
    partition: 'aws'
  },
  {
    airportCode: 'SFO',
    regionName: 'US West (N. California)',
    publicRegion: 'us-west-1',
    clientId: '436472792970',
    partition: 'aws'
  },
  {
    airportCode: 'SIN',
    regionName: 'Asia Pacific (Singapore)',
    publicRegion: 'ap-southeast-1',
    clientId: '436472792970',
    partition: 'aws'
  },
  {
    airportCode: 'SYD',
    regionName: 'Asia Pacific (Sydney)',
    publicRegion: 'ap-southeast-2',
    clientId: '436472792970',
    partition: 'aws'
  },
  {
    airportCode: 'TLV',
    regionName: 'Middle East (Tel Aviv)',
    publicRegion: 'il-central-1',
    clientId: '436472792970',
    partition: 'aws',
    isBorderRegion: true
  },
  {
    airportCode: 'YUL',
    regionName: 'Canada (Central)',
    publicRegion: 'ca-central-1',
    clientId: '436472792970',
    partition: 'aws',
    isBorderRegion: true
  },
  {
    airportCode: 'ZAZ',
    regionName: 'Europe (Spain)',
    publicRegion: 'eu-south-2',
    partition: 'aws',
    isBorderRegion: true
  },
  {
    airportCode: 'ZHY',
    regionName: 'China (Ningxia)',
    publicRegion: 'cn-northwest-1',
    partition: 'aws-cn',
    isBorderRegion: true
  },
  {
    airportCode: 'ZRH',
    regionName: 'Europe (Zurich)',
    publicRegion: 'eu-central-2',
    clientId: '436472792970',
    partition: 'aws',
    isBorderRegion: true
  },
  {
    airportCode: 'YYC',
    regionName : 'Canada West (Calgary)',
    publicRegion: 'ca-west-1',
    partition: 'aws',
    isBorderRegion: true
  },
];
