import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Button,
  ButtonDropdown,
  Header,
  Pagination,
  Table,
  TextFilter,
  Container
} from '@amzn/awsui-components-react';
import { useCallback, useState } from 'react';
import { DomainConfig } from '../../../Domain/DomainConfig/DomainConfig';
import { DomainLimits } from '../../../Domain/DomainLimits/DomainLimits';
import { UpgradeDetails } from '../../../Domain/UpgradeDetails/UpgradeDetails';
import { useFlyout } from '../../../hooks/useFlyout';
import { CommonClientProps, Domain } from '../../../models/types';
import { getDomainIdentifier } from '../../../utils/helpers';
import {
  EmptyState,
  getFilterCounterText,
  Preferences,
} from '../../../utils/tablePreferences';
import {
  DEFAULT_PREFERENCES,
  domainColumns,
  PAGE_SIZE_OPTIONS,
  VISIBLE_CONTENT_OPTIONS,
} from './constants';
import { ErrorMessageComponent } from "../../../ErrorHandler/errorMessageComponent";
import { ApiList }from "../../../ErrorHandler/utils";

enum DomainActionsEnum {
  DOMAIN_LIMITS = 'domainLimits',
  VIEW_CONFIG = 'viewConfig',
  UPGRADE_HISTORY = 'upgradeHistory',
  RECOVER_DOMAIN = 'recoverDomain',
}

interface DomainListProps extends CommonClientProps {
  domains: Domain[];
  loading: boolean;
  errorMessage: string;
}

const DomainList = (props: DomainListProps) => {
  const setFlyout = useFlyout();

  // eslint-disable-next-line
  const domainActions = [
    {
      text: 'Limits',
      id: DomainActionsEnum.DOMAIN_LIMITS,
      description: 'View / Update domain limits',
      onItemClick: (item: Domain) => {
        setFlyout({
          title: `Limits: ${getDomainIdentifier(props.clientId, item.name)}`,
          size: 'm',
          content: (
            <DomainLimits
              domainIdentifier={getDomainIdentifier(props.clientId, item.name)}
            />
          ),
        });
      },
    },
    {
      text: 'Configurations',
      id: DomainActionsEnum.VIEW_CONFIG,
      description: 'View configuration',
      onItemClick: (item: Domain) => {
        setFlyout({
          title: `Configurations: ${getDomainIdentifier(
            props.clientId,
            item.name
          )}`,
          size: 'm',
          content: (
            <DomainConfig
              domainIdentifier={getDomainIdentifier(props.clientId, item.name)}
            />
          ),
        });
      },
    },
    {
      text: 'Upgrade history',
      id: DomainActionsEnum.UPGRADE_HISTORY,
      description: 'Upgrade history on domain',
      onItemClick: (item: Domain) => {
        setFlyout({
          title: `Configurations: ${getDomainIdentifier(
            props.clientId,
            item.name
          )}`,
          size: 'm',
          content: (
            <UpgradeDetails
              domainIdentifier={getDomainIdentifier(props.clientId, item.name)}
            />
          ),
        });
      },
    },
    {
      text: 'Recover domain',
      description: 'Recover domain',
      id: DomainActionsEnum.RECOVER_DOMAIN,
      enabled: () => false,
      onItemClick: () => console.log('Recover domain, we are working on this'),
      disabled: true,
    },
    {
      text: 'Retry failed activities',
      description: 'Retry failed activities',
      id: 'Retry failed activities',
      enabled: () => false,
      onItemClick: () => alert('Retry failed activities'),
      disabled: true,
    },
  ];

  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(props.domains, {
    filtering: {
      empty: (
        <EmptyState
          title="No Domains"
          subtitle="No domains to display."
          action={<span></span>}
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can’t find a match."
          action={
            <Button onClick={() => actions.setFiltering('')}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  const handleActionClick = useCallback(
    (actionName) => {
      const currentRow = collectionProps.selectedItems[0];
      const domainAction = domainActions.filter(
        (action) => action.id === actionName
      );
      domainAction[0].onItemClick(currentRow);
    },
    [collectionProps.selectedItems, domainActions]
  );

  return (
     <>
      {props.errorMessage ? (
        <Container header={<Header variant="h2">Client domains</Header>}>
        <ErrorMessageComponent errorMessage={props.errorMessage} apiName={ApiList.DOMAIN_LIST}/>
        </Container>
       ) : (
    <div>
      <Table
        header={
          <Header
            actions={
              <ButtonDropdown
                expandableGroups={true}
                expandToViewport={true}
                items={domainActions}
                disabled={collectionProps.selectedItems.length === 0}
                onItemClick={(action) => handleActionClick(action.detail.id)}
              >
                Actions
              </ButtonDropdown>
            }
          >
            Client domains ({items.length})
          </Header>
        }
        {...collectionProps}
        columnDefinitions={domainColumns}
        selectionType="single"
        stickyHeader={true}
        wrapLines={preferences.wraplines}
        resizableColumns
        loadingText={'Loading Domains'}
        visibleColumns={preferences.visibleContent}
        items={items}
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
          />
        }
        filter={
          <TextFilter
            {...filterProps}
            countText={getFilterCounterText(filteredItemsCount)}
            filteringAriaLabel="Filter instances"
          />
        }
        preferences={
          <Preferences
            preferences={preferences}
            setPreferences={setPreferences}
            disabled={false}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            visibleContentOptions={VISIBLE_CONTENT_OPTIONS}
          />
        }
      />
    </div>
      )}
     </>
  );
};

export { DomainList };
