import { Button } from '@amzn/awsui-components-react';
import { get } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router';
import { TableComponent } from '../../../Components/Common/TableComponent';
import { TimezoneToggle } from '../../../Components/TimezoneToggle';
import CopyText from '../../../utils/copy-text';

const CollectionsList = (props) => {
  const history = useHistory();
  const collectionListVisibleContent = [
    'created_date',
    'id',
    'name',
    'status',
    'type',
    'placement_constraint_id',
  ];

  const COLLECTION_LIST_VISIBLE_CONTENT_OPTIONS = [
    {
      label: 'Options',
      options: collectionListVisibleContent.map((c) => {
        return { id: c, label: c };
      }),
    },
  ];

  const COLLECTION_LIST_FILTERING_PROPERTIES: any =
    collectionListVisibleContent.map((c) => {
      return {
        propertyLabel: c,
        key: c,
        groupValuesLabel: `${c} Values`,
        operators:
          c === 'CollectionCreatedAt'
            ? [':', '!:', '=', '!=', '>', '>=', '<', '<=']
            : [':', '!:', '=', '!='],
      };
    });

  const COLLECTION_LIST_DEFAULT_PREFERENCES = {
    pageSize: 10,
    visibleContent: collectionListVisibleContent,
    wraplines: false,
  };

  const collectionListColumnDefinitions = [
    {
      id: 'id',
      header: 'Collection Id',
      cell: (e) => (
        <React.Fragment>
          <CopyText
            copyText={e.id}
            copyButtonLabel={''}
            successText="Collection Id copied"
            errorText="Collection Id copied"
          />
          <Button
            href={`/juno/${props.awsAccountId}/${e.id}`}
            variant="link"
            onFollow={(event) => {
              event.preventDefault();
              history.push({
                pathname: `/juno/${props.awsAccountId}/${e.id}`,
                state: {
                  collectionDetails: e,
                  indexingWorkersListForAccount:
                    props.indexingWorkersListForAccount,
                  searchWorkersListForAccount:
                    props.searchWorkersListForAccount,
                },
              }); // Add your state here...
            }}
          >
            <span style={{ color: '#00a1c9' }}>{e['id'] ? e['id'] : ''}</span>
          </Button>
        </React.Fragment>
      ),
      sortingField: 'id',
    },
    {
      id: 'name',
      header: 'Collection Name',
      cell: (e) =>
        e['name'] ? (
          <CopyText
            copyText={e.name}
            copyButtonLabel={e['name']}
            successText="Collection Name copied"
            errorText="Collection Name copied"
          />
        ) : (
          ''
        ),
      sortingField: 'name',
    },
    {
      id: 'placement_constraint_id',
      header: 'Placement Constraint Id',
      cell: (e) => (
          <React.Fragment>
            <CopyText
                copyText={e.placement_constraint_id}
                copyButtonLabel={''}
                successText="Placement Constraint Id copied"
                errorText="Placement Constraint copied"
            />
            <Button
                href={`/juno/pci/${props.awsAccountId}/${e.placement_constraint_id}`}
                variant="link"
                onFollow={(event) => {
                  event.preventDefault();
                  history.push({
                    pathname: `/juno/pci/${props.awsAccountId}/${e.placement_constraint_id}`,
                    state: {
                      awsAccountId: props.awsAccountId,
                      placementConstraintId: e.placement_constraint_id,
                      namespace: 'default',
                    },
                  }); // Add your state here...
                }}
            >
            <span style={{ color: '#00a1c9' }}>
              {e['placement_constraint_id'] ? e['placement_constraint_id'] : ''}
            </span>
            </Button>
          </React.Fragment>
      ),
      sortingField: 'placement_constraint_id',
    },
    {
      id: 'created_date',
      header: 'Created Date',
      cell: (e) =>
        e['created_date'] ? (
          <TimezoneToggle date={new Date(e.created_date)} />
        ) : (
          ''
        ),
      sortingField: 'created_date',
    },
    {
      id: 'arn',
      header: 'Arn',
      cell: (e) => (e['arn'] ? e['arn'] : ''),
      sortingField: 'arn',
    },
    {
      id: 'status',
      header: 'Status',
      cell: (e) => (e['status'] ? e['status'] : ''),
      sortingField: 'status',
    },
    {
      id: 'type',
      header: 'Type',
      cell: (e) => (e['type'] ? e['type'] : ''),
      sortingField: 'type',
    },
  ];
  return (
    <TableComponent
      tableDefaultPreferences={COLLECTION_LIST_DEFAULT_PREFERENCES}
      dataLoading={props.accountCollectionListLoading}
      data={get(props.accountCollectionList, 'getAccountCollectionsList', [])}
      filteringProperties={COLLECTION_LIST_FILTERING_PROPERTIES}
      csvExportFileName={'collectionList.csv'}
      columnDefinitions={collectionListColumnDefinitions}
      visibleContentOptions={COLLECTION_LIST_VISIBLE_CONTENT_OPTIONS}
      tableHeaderName={'Collections'}
    />
  );
};

export { CollectionsList };
