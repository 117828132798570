import {
  Alert,
  Box,
  SpaceBetween,
  Spinner,
} from '@amzn/awsui-components-react';
import '@amzn/awsui-global-styles/polaris.css';
import { ApolloProvider } from '@apollo/client';
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client/core';
import '@elastic/eui/dist/eui_theme_light.css';
import Amplify, { Auth } from 'aws-amplify';
import { createBrowserHistory } from 'history';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AuditHistory } from './../src/AuditHistory/AuditHistory';
import { Juno } from './../src/Juno/Juno';
import { LSETool } from './../src/LSETool/LSETool';
import { OpsTransparency } from './../src/OpsTransparency/OpsTransparency';
import { amplify, oauth } from './amplify';
import reportWebVitals from './reportWebVitals';
import { Main } from './sdpds/application/Main';
import { TumblerHome } from './TumblerHome';
import { AppContext, getInitialAppContext } from './utils/appContext';
import { Flyout } from './utils/FlyoutContext';

const appContext = getInitialAppContext();

// Amplify.Logger.LOG_LEVEL = IS_DEVELOPMENT_ENV ? 'DEBUG' : undefined;

Amplify.configure(amplify(appContext));
Auth.configure({ oauth: oauth(appContext) });

const httpLink = new HttpLink({ uri: '/graphql' });

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink,
});

function App() {
  let history = createBrowserHistory();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (!user) {
          Auth.federatedSignIn({ customProvider: 'Amazon' });
        }
        if (sessionStorage.getItem('url')) {
          let url = sessionStorage.getItem('url');
          sessionStorage.removeItem('url');
          history.push(url);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (!sessionStorage.getItem('url')) {
          sessionStorage.setItem(
            'url',
            window.location.href.split(window.location.host)[1]
          );
        }
        Auth.federatedSignIn({ customProvider: 'Amazon' });
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="awsui">
      {loading ? (
        <Box margin={{ top: 'l' }} textAlign="center">
          <SpaceBetween size="l" direction="vertical">
            <Alert visible={loading} type="info">
              Cognito Authentication in process. Please wait.
            </Alert>
            <Spinner size="large" />
          </SpaceBetween>
        </Box>
      ) : (
        <ApolloProvider client={client}>
          <AppContext.Provider value={appContext}>
            <Flyout>
              <Router history={history}>
                <Switch>
                  <Route
                    path="/sdpds"
                    render={(props) => <Main {...props} />}
                  />
                  <Route path="/ops-transparency" component={OpsTransparency} />
                  <Route path="/lse-tool" component={LSETool} />
                  <Route path="/juno" component={Juno} />
                  <Route path="/audit-history" component={AuditHistory} />
                  <Route component={TumblerHome} />
                </Switch>
              </Router>
            </Flyout>
          </AppContext.Provider>
        </ApolloProvider>
      )}
    </div>
  );
}

const AppWrapper = () => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
};

ReactDOM.render(<AppWrapper />, document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
