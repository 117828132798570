import { gql, useLazyQuery } from '@apollo/client';

interface TmsSvgData {
  lseTool: JSON;
}

interface DetailPageRequestingData {
  lseTool: String;
}

export interface tmsSvgVars {
  key: String;
}

export interface detailPageRequestingVars {
  endTimeUtc: String;
  timeWindow: String;
  interval: String;
  issueTypes: String;
  lseStartTimeUtc: String;
  lseEndTimeUtc: String;
}

const TMS_SVG_DATA = gql`
  query getTmsSvgData($key: String) {
    tmsSvgData(key: $key)
  }
`;

const DETAIL_PAGE_REQUESTING_DATA = gql`
  query sendDetailPageReqeust(
    $endTimeUtc: String
    $timeWindow: String
    $interval: String
    $issueTypes: String
    $lseStartTimeUtc: String
    $lseEndTimeUtc: String
  ) {
    detailPageRequestingData(
      endTimeUtc: $endTimeUtc
      timeWindow: $timeWindow
      interval: $interval
      issueTypes: $issueTypes
      lseStartTimeUtc: $lseStartTimeUtc
      lseEndTimeUtc: $lseEndTimeUtc
    )
  }
`;

export const useTmsSvgDataFetchingQuery = () => {
  return useLazyQuery<TmsSvgData, tmsSvgVars>(TMS_SVG_DATA, {
    errorPolicy: 'all',
  });
};

export const useDetailPageDataRequestingQuery = () => {
  return useLazyQuery<DetailPageRequestingData, detailPageRequestingVars>(
    DETAIL_PAGE_REQUESTING_DATA,
    {
      errorPolicy: 'all',
    }
  );
};
