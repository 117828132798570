import { gql, useQuery } from '@apollo/client';
import { OtsItem } from '../../../models/types';

interface OtsAllData {
  otsAll: { otsItems: OtsItem[] };
}

// export interface OtsAllVars {
//   awsAccountId: string;
// }

const OTS_ALL = gql`
  query otsGetAll {
    otsAll {
      otsItems {
        account_id
        domain_name
        onboard_time
        ots_acc_onboarded_flag
        ots_acc_present_in_aws_org
        ots_item_class
        ots_item_parent
        ots_item_region
        ots_item_status
        ots_item_type
        ots_uuid
        trusted_entity_external_id
        aws_org_read_role_arn
      }
    }
  }
`;

// export const useOtsAllQuery = () => {
//   return useLazyQuery<OtsAllData, OtsAllVars>(OTS_ALL, {
//     errorPolicy: 'all',
//   });
// };

export const useOtsAllQuery = () => {
  return useQuery<OtsAllData>(OTS_ALL);
};
