import { AppLayout, BreadcrumbGroup } from '@amzn/awsui-components-react';
import React, {useEffect, useState} from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { LogViewer } from './Domain/Logs/LogViewer';
import { ClientOverviewParams, DomainDetailsParams } from './models/types';
import { AccountOverview } from './pages/AccountOverview/AccountOverview';
import { DomainDetails } from './pages/DomainDetails/DomainDetails';
import { HomePage } from './pages/Home/HomePage';
import { tumblerNavbarOptions, tumblerNavbarTitle } from './tumblerConstants';
import { AppContext, getInitialAppContext } from './utils/appContext';
import { Flyout } from './utils/FlyoutContext';
import { getDomainIdentifier } from './utils/helpers';
import { Navbar } from './utils/Navbar';
import { Sidebar } from './utils/sidebar';
import { ToastList } from './utils/ToastListContext';
import { ErrorBoundary } from 'react-error-boundary';
import { Fallback } from "./Components/Fallback";
import {OnDemandLogViewer} from "./Domain/OnDemand/OnDemandLogViewer";
import {OnDemandAggregationsViewer} from "./Domain/OnDemand/OnDemandAggregationsViewer"

interface HomeProps {
  children: React.ReactNode;
}
const appContext = getInitialAppContext();

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ClientRouterProps
  extends RouteComponentProps<ClientOverviewParams> {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DomainRouterProps
  extends RouteComponentProps<DomainDetailsParams> {}

const TumblerHome = (props: HomeProps) => {
  const setTimezone = (timezone: string) => {
    setState({ ...state, timezone: timezone });
  };
  const setBreadcrumbs = (breadcrumbs: any) => {
    setState({ ...state, breadcrumbs });
  };
  const [state, setState] = useState({
    ...getInitialAppContext(),
    setTimezone: setTimezone,
    setBreadcrumbs: setBreadcrumbs,
  });
  
  const [isAuthUser, setIsAuthUser] = useState(false)
  const authorizedUsers = ['mittall', 'kasundra', 'nishcha', 'shashaa', 'varunsv', 'ksreddy', 'parvuku'];
  useEffect(() => {
    const cookies = document.cookie.toString().split(';');
    let lastAuthUser = null;
    for (let i=0; i<cookies.length; i++) {
      if (cookies[i].includes('LastAuthUser')) {
        lastAuthUser = cookies[i];
        break;
      }
    }
    if (lastAuthUser) {
      const parts = lastAuthUser.split('=');
      let AuthUser = parts[1];
      const username = AuthUser.replace('amazon_', '');
      if (authorizedUsers.includes(username)) {
        setIsAuthUser(true)
      } else {
        setIsAuthUser(false)
      }
    } else {
      setIsAuthUser(false);
    }
  }, [])

  const [navigationOpen, setNavigationOpen] = useState(false);

  return (
    <div className="awsui">
      <Navbar
        title={tumblerNavbarTitle}
        homeUrl="/"
        regionSwitcherActive={true}
        dropdownOptions={tumblerNavbarOptions}
      />
      <div
        style={{
          paddingTop: '60px',
        }}
      >
        <AppLayout
          disableContentPaddings={false}
          content={
            <AppContext.Provider value={state}>
              <Flyout>
                <ToastList>
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={(routerProps: RouteComponentProps) => {
                        return <HomePage {...routerProps} />;
                      }}
                    />
                    <Route
                      exact
                      path="/:clientId/:domainName/:tab?"
                      render={(routerProps) => {
                        return (
                          <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"DomainDetailsHomePage"}/>}>
                          <DomainDetails
                            key={routerProps.match.params.tab}
                            {...routerProps}
                            domainIdentifier={getDomainIdentifier(
                              routerProps.match.params.clientId,
                              routerProps.match.params.domainName
                            )}
                            authUser = {isAuthUser}
                          />
                          </ErrorBoundary>
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/:clientId"
                      render={(routerProps: ClientRouterProps) => {
                        return (
                          <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"AccountOverviewPage"}/>}>
                          <AccountOverview {...routerProps} />
                          </ErrorBoundary>
                        );
                      }}
                    />
                    <Route
                      path="/:clientId/:domainName/log/:logId+"
                      render={(routerProps) => {
                        return <LogViewer {...routerProps} />
                      }}
                    />
                    <Route
                      path="/:clientId/:domainName/ondemand/:executionId"
                      render={(routerProps) => {
                        return <OnDemandLogViewer {...routerProps} />
                      }}
                    />
                    <Route
                        path="/:clientId/:domainName/elb/aggregations"
                        render={(routerProps) => {
                          return (
                              <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"OnDemandAggregationPage"}/>}>
                              <OnDemandAggregationsViewer {...routerProps} />
                              </ErrorBoundary>
                          )
                        }}
                    />
                  </Switch>
                </ToastList>
              </Flyout>
            </AppContext.Provider>
          }
          toolsHide={true}
          navigation={<Sidebar />}
          navigationOpen={navigationOpen}
          onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
          headerSelector="#navbar"
          breadcrumbs={
            <BreadcrumbGroup
              items={appContext.breadcrumbs}
              ariaLabel="Breadcrumbs"
            />
          }
        />
      </div>
    </div>
  );
};
export { TumblerHome };
