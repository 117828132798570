import {
  EuiButtonEmpty,
  EuiDescribedFormGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiLoadingContent,
} from '@elastic/eui';
import { get, startCase } from 'lodash';
import React, { Fragment, useState } from 'react';
import { useToastNotifications } from '../../hooks/useToastList';
import { AnyObject, CommonDomainProps } from '../../models/types';
import { COMPONENT_MAPPING } from './FormFieldsMapping';
import { useDomainLimitsQuery } from './hooks/useDomainLimitsQuery';
import { Field, FormField, getFormFields } from './utils';

const DomainLimits = (props: CommonDomainProps) => {
  const { loading, data, error } = useDomainLimitsQuery({
    domainIdentifier: props.domainIdentifier,
  });
  const toastNotifications = useToastNotifications();

  if (error) {
    toastNotifications.addDanger(
      `Something went wrong. Unable to fetch limits for ${props.domainIdentifier}`
    );
  }

  const [formDisabled, setFormDisabled] = useState<boolean>(true);
  const limits: AnyObject = get(data, 'domain.limits', {});
  const finalFormFields = getFormFields(limits);
  return (
    <React.Fragment>
      <EuiFlexGroup alignItems={'flexEnd'} justifyContent={'flexEnd'}>
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty onClick={() => setFormDisabled(false)}>
            Edit limits
          </EuiButtonEmpty>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty onClick={() => setFormDisabled(false)}>
            Update limits
          </EuiButtonEmpty>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiForm>
        {Object.values(finalFormFields).map((formCategory: FormField) => (
          <EuiDescribedFormGroup
            title={<h3>{formCategory.title}</h3>}
            description={formCategory.description}
          >
            {loading ? (
              <EuiLoadingContent lines={5} />
            ) : (
              <Fragment>
                {formCategory.fields
                  .filter(
                    (formField: Field) => formField.dataType !== 'boolean'
                  )
                  .map((formField: Field) => {
                    return (
                      <EuiFormRow label={startCase(formField.label)}>
                        {COMPONENT_MAPPING[formField.dataType]({
                          value: formField.value,
                          disabled: formDisabled,
                        })}
                      </EuiFormRow>
                    );
                  })}
                {formCategory.fields
                  .filter(
                    (formField: Field) => formField.dataType === 'boolean'
                  )
                  .map((config: Field) => {
                    return (
                      <EuiFormRow label={startCase(config.label)}>
                        {COMPONENT_MAPPING[config.dataType]({
                          value: config.value,
                          disabled: formDisabled,
                        })}
                      </EuiFormRow>
                    );
                  })}
              </Fragment>
            )}
          </EuiDescribedFormGroup>
        ))}
      </EuiForm>
    </React.Fragment>
  );
};

export { DomainLimits };
