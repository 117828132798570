import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks/dist/cjs/interfaces';
import {
  DateRangePickerProps,
  Link,
} from '@amzn/awsui-components-react/polaris';

export const DEFAULT_PREFERENCES = {
  pageSize: 30,
  visibleContent: [
    'stillImpacted',
    'domainId',
    'issueType',
    'esVersion',
    'numOfAz',
    'az',
    'isVpc',
    'instanceType',
    'instanceCount',
    'sla',
    'vfi',
    'standByEnabled'
  ],
  wraplines: false,
};

export const TIME_WINDOW_OPTIONS = [
  { text: '3h', id: '3hours' },
  { text: '12h', id: '12hours' },
  { text: '1d', id: '1days' },
  { text: '3d', id: '3days' },
  { text: '7d', id: '7days' },
  { text: '4w', id: '4weeks' },
];

export const TIME_INTERVAL_OPTIONS = [
  { text: '5m', id: '5m' },
  { text: '15m', id: '15m', disabled: false },
  { text: '1h', id: '1h', disabled: false },
];

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Options',
    options: [
      {
        id: 'stillImpacted',
        label: 'Still Impacted',
      },
      {
        id: 'accountId',
        label: 'Account ID',
      },
      {
        id: 'domainName',
        label: 'Domain Name',
      },
      {
        id: 'domainId',
        label: 'Domain ID',
      },
      { id: 'issueType', label: 'Issue Type' },
      { id: 'failedTime', label: 'Failed Time' },
      { id: 'esVersion', label: 'ES Version' },
      { id: 'numOfAz', label: '# of AZs' },
      { id: 'az', label: 'az' },
      { id: 'isVpc', label: 'Is VPC' },
      { id: 'instanceType', label: 'Instance Type' },
      { id: 'instanceCount', label: 'Instance Count' },
      { id: 'sla', label: 'SLA' },
      { id: 'vfi', label: 'VFI' },
      { id: 'standByEnabled', label: 'standByEnabled' },
    ],
  },
];

export const PAGE_SIZE_PREFERENCE = {
  title: 'Select page size',
  options: [
    { value: 5, label: '5 resources' },
    { value: 10, label: '10 resources' },
  ],
};

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10' },
  { value: 30, label: '30' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 500, label: '500' },
];

const domainIdColumnDefinitions = (item) => {
  const href = window.location.href;
  const tumblerUrl = href.substring(0, href.indexOf('/lse-tool'));
  const domainIdSplit = item.domainId[0].split(':');
  return (
    (
      <Link
        externalIconAriaLabel="Opens in a new tab"
        href={`${tumblerUrl}/${domainIdSplit[0]}/${domainIdSplit[1]}/overview`}
        target="_blank"
      >
        {item.domainId}
      </Link>
    ) || '-'
  );
};

export const IMPACTED_DOMAINS_COLUMN_DEFINITIONS = [
  {
    name: 'stillImpacted',
    id: 'stillImpacted',
    header: 'Still Impacted',
    sortingField: 'stillImpacted',
    cell: (item) => item.stillImpacted.toString() || '-',
  },
  {
    name: 'accountId',
    id: 'accountId',
    header: 'Account ID',
    sortingField: 'accountId',
    cell: (item) => item.accountId || '-',
  },
  {
    name: 'domainName',
    id: 'domainName',
    header: 'Domain Name',
    sortingField: 'domainName',
    cell: (item) => item.domainName || '-',
  },
  {
    name: 'domainId',
    id: 'domainId',
    header: 'Domain ID',
    sortingField: 'domainId',
    cell: (item) => domainIdColumnDefinitions(item),
  },
  {
    name: 'issueType',
    id: 'issueType',
    header: 'Issue Type',
    sortingField: 'issueType',
    cell: (item) => item.issueType.toString() || '-',
  },
  {
    name: 'failedTime',
    id: 'failedTime',
    header: 'Failed Time',
    sortingField: 'failedTime',
    cell: (item) => item.failedTime.toString() || '-',
  },
  {
    name: 'esVersion',
    id: 'esVersion',
    header: 'ES Version',
    sortingField: 'esVersion',
    cell: (item) => item.esVersion.toString() || '-',
  },
  {
    name: 'numOfAz',
    id: 'numOfAz',
    header: '# of AZs',
    sortingField: 'numOfAz',
    cell: (item) => item.numOfAz.toString() || '-',
  },
  {
    name: 'az',
    id: 'az',
    header: 'AZ',
    sortingField: 'az',
    cell: (item) => item.az.toString() || '-',
  },
  {
    name: 'isVpc',
    id: 'isVpc',
    header: 'Is VPC',
    sortingField: 'isVpc',
    cell: (item) => item.isVpc.toString() || '-',
  },
  {
    name: 'instanceType',
    id: 'instanceType',
    header: 'Instance Type',
    sortingField: 'instanceType',
    cell: (item) => item.instanceType.toString() || '-',
  },
  {
    name: 'instanceCount',
    id: 'instanceCount',
    header: 'Instance Count',
    sortingField: 'instanceCount',
    cell: (item) => item.instanceCount.toString() || '-',
  },
  {
    name: 'sla',
    id: 'sla',
    header: 'SLA',
    sortingField: 'sla',
    cell: (item) => item.sla || '-',
  },
  {
    name: 'vfi',
    id: 'vfi',
    header: 'VFI',
    sortingField: 'vfi',
    cell: (item) => item.vfi.toString() || '-',
  },
  {
    name: 'standByEnabled',
    id: 'standByEnabled',
    header: 'StandByEnabled',
    sortingField: 'standByEnabled',
    cell: (item) => (item.standByEnabled !== undefined) ? item.standByEnabled.toString() : 'false' || '-',
  },
];

export const ES_VERSION_COLUMN_DEFINITIONS = [
  {
    id: 'rank',
    header: '#',
    cell: (item) => item.rank || '-',
  },
  {
    id: 'esVersion',
    header: 'ES Version',
    cell: (item) => item.esVersion || '-',
  },
  {
    id: 'data',
    header: 'Counts',
    cell: (item) => item.data || '-',
  },
];

export const VFI_COLUMN_DEFINITIONS = [
  {
    id: 'rank',
    header: '#',
    cell: (item) => item.rank || '-',
  },
  {
    id: 'vfi',
    header: 'VFI',
    cell: (item) => item.vfi || '-',
  },
  {
    id: 'data',
    header: 'Counts',
    cell: (item) => item.data || '-',
  },
];

export const NUM_OF_AZ_COLUMN_DEFINITIONS = [
  {
    id: 'rank',
    header: '#',
    cell: (item) => item.rank || '-',
  },
  {
    id: 'num-of-az',
    header: '#OfAZ',
    cell: (item) => item.numOfAz || '-',
  },
  {
    id: 'data',
    header: 'Counts',
    cell: (item) => item.data || '-',
  },
];

export const AZ_COLUMN_DEFINITIONS = [
  {
    id: 'rank',
    header: '#',
    cell: (item) => item.rank || '-',
  },
  {
    id: 'az',
    header: 'AZ',
    cell: (item) => item.az || '-',
  },
  {
    id: 'data',
    header: 'Counts',
    cell: (item) => item.data || '-',
  },
];

export const INSTANCE_TYPE_COLUMN_DEFINITIONS = [
  {
    id: 'rank',
    header: '#',
    cell: (item) => item.rank || '-',
  },
  {
    id: 'instanceType',
    header: 'Instance Type',
    cell: (item) => item.instanceType || '-',
  },
  {
    id: 'data',
    header: 'Counts',
    cell: (item) => item.data || '-',
  },
];

export const INSTANCE_COUNT_COLUMN_DEFINITIONS = [
  {
    id: 'rank',
    header: '#',
    cell: (item) => item.rank || '-',
  },
  {
    id: 'instanceCount',
    header: 'Instance Count',
    cell: (item) => item.instanceCount || '-',
  },
  {
    id: 'data',
    header: 'Counts',
    cell: (item) => item.data || '-',
  },
];

export const IS_VPC_COLUMN_DEFINITIONS = [
  {
    id: 'rank',
    header: '#',
    cell: (item) => item.rank || '-',
  },
  {
    id: 'isVpc',
    header: 'Is Vpc',
    cell: (item) => item.isVpc || '-',
  },
  {
    id: 'data',
    header: 'Counts',
    cell: (item) => item.data || '-',
  },
];

export const SLA_COLUMN_DEFINITIONS = [
  {
    id: 'rank',
    header: '#',
    cell: (item) => item.rank || '-',
  },
  {
    id: 'sla',
    header: 'SLA',
    cell: (item) => item.sla || '-',
  },
  {
    id: 'data',
    header: 'Counts',
    cell: (item) => item.data || '-',
  },
];

export const STANDBY_COLUMN_DEFINITIONS = [
  {
    id: 'rank',
    header: '#',
    cell: (item) => item.rank || '-',
  },
  {
    id: 'standByEnabled',
    header: 'standByEnabled',
    cell: (item) => item.standByEnabled || '-',
  },
  {
    id: 'data',
    header: 'Count',
    cell: (item) => item.data || '-',
  },
];

export const DATE_RANGE_PICKER_I18N_STRINGS = {
  todayAriaLabel: 'Today',
  nextMonthAriaLabel: 'Next month',
  previousMonthAriaLabel: 'Previous month',
  customRelativeRangeDurationLabel: 'Duration',
  customRelativeRangeDurationPlaceholder: 'Enter duration',
  customRelativeRangeOptionLabel: 'Custom range',
  customRelativeRangeOptionDescription: 'Set a custom range in the past',
  customRelativeRangeUnitLabel: 'Unit of time',
  formatRelativeRange: (e) => {
    const t = 1 === e.amount ? e.unit : e.unit + 's';
    return `Last ${e.amount} ${t}`;
  },
  formatUnit: (e, t) => (1 === t ? e : e + 's'),
  dateTimeConstraintText:
    'Range must be between 6 - 30 days. Use 24 hour format.',
  relativeModeTitle: 'Relative range',
  absoluteModeTitle: 'Absolute range',
  relativeRangeSelectionHeading: 'Choose a range',
  startDateLabel: 'Start date',
  endDateLabel: 'End date',
  startTimeLabel: 'Start time',
  endTimeLabel: 'End time',
  clearButtonLabel: 'Clear',
  cancelButtonLabel: 'Cancel',
  applyButtonLabel: 'Apply',
};

export const DATE_RANGE_PICKER_RELATIVE_OPTIONS = [
  {
    key: 'previous-5-minutes',
    amount: 5,
    unit: 'minute',
    type: 'relative',
  },
  {
    key: 'previous-30-minutes',
    amount: 30,
    unit: 'minute',
    type: 'relative',
  },
  {
    key: 'previous-1-hour',
    amount: 1,
    unit: 'hour',
    type: 'relative',
  },
  {
    key: 'previous-6-hours',
    amount: 6,
    unit: 'hour',
    type: 'relative',
  },
];

export const LINE_CHART_I18N_STRINGS = {
  filterLabel: 'Filter displayed data',
  filterPlaceholder: 'Filter data',
  filterSelectedAriaLabel: 'selected',
  legendAriaLabel: 'Legend',
  chartAriaRoleDescription: 'line chart',
  xTickFormatter: (t) =>
    t
      .toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: !1,
      })
      .split(',')
      .join('\n'),
  yTickFormatter: (t) =>
    Math.abs(t) < 1e3 ? t + '' : (t / 1e3).toFixed() + 'k',
};

export const FILTERING_PROPERTIES: readonly PropertyFilterProperty[] = [
  {
    propertyLabel: 'Still Impacted',
    key: 'stillImpacted',
    groupValuesLabel: 'still impacted values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Domain ID',
    key: 'domainId',
    groupValuesLabel: 'domain id values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Account ID',
    key: 'accountId',
    groupValuesLabel: 'account id values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Domain Name',
    key: 'domainName',
    groupValuesLabel: 'domain name values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Issue Type',
    key: 'issueType',
    groupValuesLabel: 'issue type values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Failed Time',
    key: 'failedTime',
    groupValuesLabel: 'failed time values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'ES Version',
    key: 'esVersion',
    groupValuesLabel: 'es version values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: '#ofAZ',
    key: 'numOfAz',
    groupValuesLabel: 'num of AZ values',
    operators: ['=', '!=', '<', '<=', '>', '>='],
  },
  {
    propertyLabel: 'AZ',
    key: 'az',
    groupValuesLabel: 'AZ values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Is VPC',
    key: 'isVpc',
    groupValuesLabel: 'is VPC values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Instance Type',
    key: 'instanceType',
    groupValuesLabel: 'instance type values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Instance Count',
    key: 'instanceCount',
    groupValuesLabel: 'instance count values',
    operators: ['=', '!=', '<', '<=', '>', '>='],
  },
  {
    propertyLabel: 'SLA',
    key: 'sla',
    groupValuesLabel: 'sla values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'VFI',
    key: 'vfi',
    groupValuesLabel: 'vfi values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'StandByEnabled',
    key: 'standByEnabled',
    groupValuesLabel: 'standByEnabled values',
    operators: [':', '!:', '=', '!='],
  }
];

export const relativeOptions: ReadonlyArray<DateRangePickerProps.RelativeOption> =
  [
    {
      key: 'previous-5-minutes',
      amount: 5,
      unit: 'minute',
      type: 'relative',
    },
    {
      key: 'previous-30-minutes',
      amount: 30,
      unit: 'minute',
      type: 'relative',
    },
    {
      key: 'previous-1-hour',
      amount: 1,
      unit: 'hour',
      type: 'relative',
    },
    {
      key: 'previous-6-hours',
      amount: 6,
      unit: 'hour',
      type: 'relative',
    },
  ];

export const i18nStrings = {
  todayAriaLabel: 'Today',
  nextMonthAriaLabel: 'Next month',
  previousMonthAriaLabel: 'Previous month',
  customRelativeRangeDurationLabel: 'Duration',
  customRelativeRangeDurationPlaceholder: 'Enter duration',
  customRelativeRangeOptionLabel: 'Custom range',
  customRelativeRangeOptionDescription: 'Set a custom range in the past',
  customRelativeRangeUnitLabel: 'Unit of time',
  formatRelativeRange: (e) => {
    const t = 1 === e.amount ? e.unit : e.unit + 's';
    return `Last ${e.amount} ${t}`;
  },
  formatUnit: (e, t) => (1 === t ? e : e + 's'),
  dateTimeConstraintText: 'Use 24 hour format in UTC.',
  relativeModeTitle: 'Relative range',
  absoluteModeTitle: 'Absolute range',
  relativeRangeSelectionHeading: 'Choose a range',
  startDateLabel: 'Start date',
  endDateLabel: 'End date',
  startTimeLabel: 'Start time',
  endTimeLabel: 'End time',
  clearButtonLabel: 'Clear',
  cancelButtonLabel: 'Cancel',
  applyButtonLabel: 'Apply',
};

export const impactedDomainPopoverContent =
  'TIP: To view the specific domains that are still impacted, apply the "Still Impacted" filter to the impacted domains table below.';
