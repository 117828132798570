import { gql, useQuery } from '@apollo/client';
import { GroupResources } from '../../../models/types';

export interface GroupResourceVars {
  domainIdentifier: string;
}

export interface GroupResourcesData {
  domain: {
    domainIdentifier: string;
    esVersion: string;
    resources: {
      GROUP: GroupResources;
    };
  };
}

const GROUP_INFO = gql`
  query getDomainDetails($domainIdentifier: String!) {
    domain(domainIdentifier: $domainIdentifier) {
      domainIdentifier
      esVersion
      resources {
        GROUP
      }
    }
  }
`;

export const useGroupInfoQuery = (vars: GroupResourceVars) => {
  return useQuery<GroupResourcesData, GroupResourceVars>(GROUP_INFO, {
    variables: { ...vars },
    errorPolicy: 'all',
  });
};
