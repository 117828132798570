import { gql, useQuery } from '@apollo/client';

export interface  ListRootMetricsVars {
  domainIdentifier: string;
  accountId: string;
}

export interface ListRootMetricsData {
  listMetrics: {
    metrics: String[];
  };
}

const GQL_LIST_ROOT_METRICS = gql`
  query getRootCwMetrics($domainIdentifier: String!, $accountId: String!) {
    listRootMetrics(domainIdentifier: $domainIdentifier, accountId: $accountId)
  }
`;

export const useListCwRootMetrics = (vars: ListRootMetricsVars) => {
  return useQuery<ListRootMetricsData, ListRootMetricsVars>(GQL_LIST_ROOT_METRICS, {
    variables: { ...vars },
  });
};