import { useParams } from 'react-router-dom';
import { DomainDetailsParams } from '../models/types';
import { getDomainIdentifier } from '../utils/helpers';

const GetDomainIdentifierFromParams = (): string | null => {
  const { domainName, clientId } = useParams<DomainDetailsParams>();
  if (!domainName || domainName.length === 0) {
    return clientId
  }
  return getDomainIdentifier(clientId, domainName);
};

export const ApiList = {
  DOMAIN_CONFIG:        'getDomainConfig',
  DOMAIN_EVENTS:        'getDomainEvents',
  KEY_EVENTS:           'getKeyEvents',
  BROWSE_LOGS:          'getLogs',
  PERFORMANCE_ANALYSER: 'PerformanceAnalyser',
  UPGRADE_DETAILS:      'getUpgradeHistory',
  ACCOUNT_OVERVIEW:     'getDomainList',
  DOMAIN_LIST:          'getDomainList',
  AUTOSCALING_GROUP:    'groupInfoDomainDetails',
  CLUSTER_INFO:         'getClusterInfo',
  CLUSTER_OVERVIEW:     'getDomainDetails',
  LAUNCH_CONFIGURATION: 'groupInfoDomainDetails',
  NODE_LIST:            'getDomainDetails',
  SECURITY_GROUPS:      'groupInfoDomainDetails',
  SKEW_ANALYSIS:        'getSkewAnalysis',
  SNAPSHOTS_INFO:       'getSnapshotsInfo',
  TICKETS:              'getTickets',
  TMS_ISSUES:           'getTMSIssue',
  TMS_ISSUES_ARCHIVE:   'getTMSissueArchive',
  LIST_EXECUTIONS:      'listExecutions',
  LSE_TMS_SVG_DATA:     'getTmsSvgData',
  LSE_TMS_REQUEST_DATA: 'requestLseDetailPageData',
  LSE_TMS_FETCH_DATA:   'fetchLseDetailPageData',
  EXTERNAL_CW_WIDGET:   'getExternalCwMetrics',
  EXPLORER_CW_METRICS:  'getExplorerCwMetrics'
};
export default GetDomainIdentifierFromParams;
