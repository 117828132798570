import React from 'react';
import { apiCall, convertEpochToDate } from '../../utils';
import {
  EuiDatePicker,
  EuiInMemoryTable,
  EuiButtonEmpty,
  EuiButton,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiDatePickerRange,
  EuiFlexGroup,
  EuiFlexItem,
} from '@elastic/eui';

import moment from 'moment';
import ContentPanel from '../../../Components/ContentPanel/ContentPanel';
import { pagination } from './V2/constants';

const HOURS_TO_MILLIS_MULTIPLIER = 60 * 60 * 1000;

export class ClusterAuditHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().add(-1, 'days'),
      endDate: moment(),
      items: [],
      date: '',
      isResponseUpdated: false,
      isModalVisible: false,
      totalDeployments: 0,
    };
    this.startTime = '';
    this.endTime = '';
    this.exception = '';
    this.items = [];
    this.rowId = 1;
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.setDuration = this.setDuration.bind(this);
  }

  componentDidMount() {
    this.setDuration();
  }

  updateResult(response) {
    let i;
    let j;
    let length;
    let data;
    let componentScope;
    let deploymentDetails;
    let componentName;
    let majorVersion;
    let minorVersion;
    let componentAction;
    response = response.body;
    length = response.clusterUpdateList.length;

    for (i = 0; i < length; i++) {
      deploymentDetails = '';
      for (
        j = 0;
        j < response.clusterUpdateList[i].componentVersionUpdateList.length;
        j++
      ) {
        componentName =
          response.clusterUpdateList[i].componentVersionUpdateList[j]
            .componentVersion.componentName;
        majorVersion =
          response.clusterUpdateList[i].componentVersionUpdateList[j]
            .componentVersion.majorVersion;
        minorVersion =
          response.clusterUpdateList[i].componentVersionUpdateList[j]
            .componentVersion.minorVersion;
        componentScope =
          response.clusterUpdateList[i].componentVersionUpdateList[j]
            .componentVersion.componentScopeDefinition.componentScope;
        componentAction =
          response.clusterUpdateList[i].componentVersionUpdateList[j]
            .componentAction;
        deploymentDetails =
          deploymentDetails +
          ' ' +
          componentAction +
          '/' +
          componentScope +
          '/' +
          componentName +
          '-' +
          majorVersion +
          '-' +
          minorVersion;
      }
      data = {
        id: this.rowId,
        deployment: this.rowId,
        deploymentdetails: deploymentDetails,
        updatestatus: response.clusterUpdateList[i].updateStatus,
        creationTime: response.clusterUpdateList[i].creationTime,
      };

      this.rowId++;
      this.items.push(data);
    }
    this.setState({ items: this.items });
  }

  updateException(response) {
    this.exception = JSON.stringify(response.body);
  }

  updateResponse(response) {
    this.setState({ statusCode: response.statusCode });
    if (response.statusCode === 200) {
      this.updateResult(response);
    } else {
      this.updateException(response);
    }
  }

  getClusterAuditHistory = async (nextToken) => {
    if (!nextToken) {
      this.items = [];
      this.rowId = 1;
    }
    const data = {
      cluster: {
        stackName: this.props.stackName,
        clusterId: this.props.clusterId,
      },
      startTime: parseInt(this.startTime / 1000),
      endTime: parseInt(this.endTime / 1000),
      maxResults: 50,
      nextToken: nextToken,
    };
    const response = await apiCall('/cluster/auditHistory', data);
    this.updateResponse(response);
    if (response.body.nextToken) {
      await this.getClusterAuditHistory(response.body.nextToken);
    } else {
      this.setState({ isResponseUpdated: true });
    }
  };

  setDuration(duration) {
    this.endTime = Date.now();
    this.startTime =
      this.endTime - HOURS_TO_MILLIS_MULTIPLIER * (duration || 750);
    this.setState({ isResponseUpdated: false }, () =>
      this.getClusterAuditHistory()
    );
  }

  setDateRange = () => {
    let date;
    date = new Date(this.state.startDate._d);
    this.startTime = date.getTime();
    date = new Date(this.state.endDate._d);
    this.endTime = date.getTime();
    this.setState({ isResponseUpdated: false }, () =>
      this.getClusterAuditHistory()
    );
  };

  closeModal() {
    this.setState({ isModalVisible: false });
  }

  showModal() {
    this.setState({ isModalVisible: true });
  }

  startDateHandler = (date) => {
    this.setState({ startDate: date });
  };

  endDateHandler = (date) => {
    this.setState({ endDate: date });
  };

  getDatePicker() {
    let modal = '';
    if (this.state.isModalVisible) {
      modal = (
        <EuiOverlayMask>
          <EuiModal
            style={{ minHeight: '450px', minWidth: '650px' }}
            onClose={this.closeModal}
            id="datepicker"
          >
            <EuiModalHeader>
              <EuiModalHeaderTitle>Set Date</EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiDatePickerRange
                    style={{ float: 'left' }}
                    id="datepickerrange"
                    startDateControl={
                      <EuiDatePicker
                        selected={this.state.startDate}
                        onChange={this.startDateHandler}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        isInvalid={this.state.startDate > this.state.endDate}
                        aria-label="Start date"
                        showTimeSelect
                      />
                    }
                    endDateControl={
                      <EuiDatePicker
                        selected={this.state.endDate}
                        onChange={this.endDateHandler}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        isInvalid={this.state.startDate > this.state.endDate}
                        aria-label="End date"
                        showTimeSelect
                      />
                    }
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton
                    id="datebutton"
                    textProps={{ id: 'rangesearch' }}
                    onClick={this.setDateRange}
                  >
                    Update
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiModalBody>
          </EuiModal>
        </EuiOverlayMask>
      );
    }
    return modal;
  }

  renderToolsRight() {
    return [
      ...[
        { label: '1h', value: 1 },
        { label: '3h', value: 3 },
        { label: '12h', value: 12 },
        { label: '1d', value: 24 },
        { label: '3d', value: 72 },
        { label: '1w', value: 168 },
      ].map((opt) => (
        <EuiButtonEmpty onClick={() => this.setDuration(opt.value)}>
          {opt.label}
        </EuiButtonEmpty>
      )),
      <EuiButtonEmpty
        id="custom"
        iconType="arrowDown"
        iconSide="right"
        onClick={this.showModal}
      >
        {'custom'}
      </EuiButtonEmpty>,
      this.getDatePicker(),
    ];
  }

  render() {
    let error = '';
    const columns = [
      {
        field: 'deployment',
        name: 'sno',
        width: '10%',
      },
      {
        field: 'creationTime',
        name: 'Update Time',
        render: (time) => {
          return convertEpochToDate(time);
        },
        sortable: true,
      },
      {
        field: 'updatestatus',
        name: 'Update Status',
      },
      {
        field: 'deploymentdetails',
        name: 'Action/ComponentScope/ComponentVersion',
      },
    ];
    const search = {
      toolsRight: this.renderToolsRight(),
      box: {
        incremental: true,
      },
    };

    const sorting = {
      sort: {
        field: 'creationTime',
        direction: 'desc',
      },
    };

    if (this.state.isResponseUpdated && this.state.statusCode !== 200) {
      error =
        'statuscode: ' +
        this.state.statusCode +
        '\n' +
        'body ' +
        this.exception;
    }

    return (
      <ContentPanel title={'Deployment History'}>
        <EuiInMemoryTable
          items={this.state.items}
          columns={columns}
          search={search}
          loading={!this.state.isResponseUpdated}
          error={error}
          pagination={pagination}
          sorting={sorting}
        />
      </ContentPanel>
    );
  }
}
