import {
  Button,
  DateRangePicker,
  ExpandableSection,
  Flashbar,
  FormField,
  Grid,
  Header,
  Link,
  Multiselect,
  Popover,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { cloneDeep, get } from 'lodash';
import moment from 'moment';
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import { useResourceInfoQuery } from '../../pages/DomainDetails/hooks/useResourceInfoQuery';
import {
  addOptionToTop,
  esOsVersionOptions,
  i18nStrings,
  isValidRequestRange,
  isValidRequestRangeForOnDemand,
  logGroupOptions,
  relativeOptions,
  REQUEST_LOGS_INSTRUCTIONS,
  REQUEST_LOGS_INSTRUCTION_HEADER,
  timezoneOptions,
} from './constants';
import { useRequestLogsMutation } from './useRequestLogsMutation';
import { useRequestOnDemandDataMutation } from '../OnDemand/useRequestOnDemandData'
import {ListExecutions} from "../OnDemand/ListExecutions";

const RequestLogs = (props) => {
  const params = useParams();
  const clientId = params['clientId'];
  const domainName = params['domainName'];
  const { data } = useResourceInfoQuery({
    domainIdentifier: clientId + ':' + domainName,
  });
  const esOsVersion: string = get(data, 'domain.esVersion', undefined);

  let defaultVersion = esOsVersionOptions[0]['options'][0];
  if (esOsVersion) {
    if (esOsVersion.includes('OS')) {
      defaultVersion = {
        label: esOsVersion.replace('OS_', ''),
        value: esOsVersion.replace('.', '_'),
      };
    } else {
      defaultVersion = {
        label: esOsVersion,
        value: 'ES_' + esOsVersion.replace('.', '_'),
      };
    }
  }
  var defaultVersionString = JSON.stringify(defaultVersion);

  useEffect(() => {
    setSelectedEsOsVersion(defaultVersion);
  }, [defaultVersionString]);

  const [selectedEsOsVersion, setSelectedEsOsVersion] =
    useState(defaultVersion);

  const [selectedLogGroups, setSelectedLogGroups] = useState([]);

  const [isOnDemandLogGroups, setIsOnDemandLogGroups] = useState(false)


  useEffect(() => {
    const isOnDemand = selectedLogGroups.some(logGroup => logGroup.value === 'ELB');
    if (isOnDemand){
      setIsOnDemandLogGroups(true);
      props.refreshData(true)
    } else {
      setIsOnDemandLogGroups(false);
      props.refreshData(false)
    }
  }, [selectedLogGroups]);


  const [hours, setHours] = useState('3');
  const [isHoursDisabled, setIsHoursDisabled] = useState(false);

  const [filteredLogGroupsOptions, setFilteredLogGroupsOptions] = useState([]);

  useEffect(() => {
    let logGroups = cloneDeep(logGroupOptions);
    for (let option of logGroups) {
      const label = option.label;
      const options = option.options;
      if (label === 'ElasticSearch' || label === 'Kibana') {
        let filteredOptions;
        if (label === 'ElasticSearch') {
          // e.g. selectedEsOsVersion.value = ES_7_10 or OS_1_0
          filteredOptions = options.filter((o) =>
            o.value.includes(selectedEsOsVersion.value + 'AMI')
          );
          addOptionToTop(filteredOptions, 'auth.general.log');
          addOptionToTop(filteredOptions, 'elasticsearch.log');
        } else {
          let toBeFiltered;
          if (selectedEsOsVersion.value.includes('OS')) {
            toBeFiltered =
              selectedEsOsVersion.value.replace('OS', 'OSD') + 'AMI';
            // e.g. toBeFiltered OSD_1_0AMI
          } else {
            toBeFiltered =
              selectedEsOsVersion.value.replace('ES', 'Kibana') + 'AMI';
            // e.g. toBeFiltered Kibana_7_10AMI
          }
          filteredOptions = options.filter((o) =>
            o.value.includes(toBeFiltered)
          );
        }
        option.options = filteredOptions;
      }
    }
    logGroups = logGroups.filter((group) => group.options.length > 0);
    setFilteredLogGroupsOptions(logGroups);
  }, [selectedEsOsVersion]);

  // const [customLog, setCustomLog] = useState("");

  const [requestDate, setRequestDate] = useState<any>({
    type: 'relative',
    amount: 9,
    unit: 'hour',
  });

  useEffect(() => {
    if (isOnDemandLogGroups) {
      setRequestDate(prevState => ({
        ...prevState,
        amount: 6,
      }));
    }
  }, [isOnDemandLogGroups]);

  const [selectedRequestTimeZoneOption, setSelectedRequestTimeZoneOption] =
    useState({
      inputDisplay: 'UTC',
      label: 'UTC',
      value: 'UTC',
      code: 'UTC',
      offset: '0000',
      mins: 0,
    });

  const handleRequestTimezoneChange = (selectedOption) => {
    setSelectedRequestTimeZoneOption(selectedOption);
  };

  const handleRequestDateChange = ({ detail }) => {
    if ('relative' === detail.value.type) {
      setIsHoursDisabled(false);
    } else {
      setIsHoursDisabled(true);
    }
    setRequestDate(detail.value);
  };

  const [errorTextLogGroups, setErrorTextLogGroups] = useState('');
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')

  const validateRequest = () => {
    if (selectedLogGroups.length === 0) {
      setErrorTextLogGroups('Please select log group(s)');
    } else {
      handleRequestLogs();
      setErrorTextLogGroups('');
    }
  };

  const [requestLogs] = useRequestLogsMutation();
  const [requestOnDemandLogs] = useRequestOnDemandDataMutation();
  const [elbLogSubmit, setElbLogSubmit] = useState(false)
  const ListExecutionsRef = useRef();

  const handleRequestLogs = async () => {
    var startMoment, endMoment;

    if ('absolute' === requestDate.type) {
      startMoment = moment(requestDate.startDate);
      endMoment = moment(requestDate.endDate);
    } else if ('relative' === requestDate.type) {
      startMoment = moment().subtract(requestDate.amount, requestDate.unit);
      endMoment = moment();
    }

    const startTimeStamp = startMoment.utc().format();
    const endTimeStamp = endMoment.utc().format();
    setStartTime(startTimeStamp)
    setEndTime(endTimeStamp)

    let allStatus = [];
    if (isOnDemandLogGroups) {
      for (const logGroup of selectedLogGroups) {
      try{
        var resp;
          const formattedStartTimeStamp = moment.utc(startTimeStamp).format('YYYYMMDDTHHmm') + 'Z';
          const formattedEndTimeStamp = moment.utc(endTimeStamp).format('YYYYMMDDTHHmm') + 'Z';
          resp = await requestOnDemandLogs({
            variables: {
              domainId: clientId + ':' + domainName,
              eventType: logGroup.value,
              startTime: formattedStartTimeStamp,
              endTime: formattedEndTimeStamp,
              reBackfill: 'false'
            },
          });
          setElbLogSubmit(true)
          if (resp?.data?.requestOnDemandData?.success) {
            const status = resp?.data?.requestOnDemandData?.data;
            setFlashbarItems([
              {
                type: 'success',
                content: status,
                dismissible: true,
                dismissLabel: 'Dismiss message',
                onDismiss: () => setFlashbarItems([]),
              },
            ]);
          } else {
            const errorMessage = resp?.data?.requestOnDemandData?.error;
            setFlashbarItems([
              {
                type: 'error',
                content: `Error occured while requesting backfill. ${errorMessage}`,
                dismissible: true,
                dismissLabel: 'Dismiss message',
                onDismiss: () =>
                    setFlashbarItems([]),
                id: 'on_demand_error',
              },
            ]);
          }
      } catch (e) {
          // TODO
        }
    }

      props.refreshData(true)
    } else {
      for (const logGroup of selectedLogGroups) {
        try {
          var resp;
          if ('absolute' === requestDate.type) {
            resp = await requestLogs({
              variables: {
                domainId: clientId + ':' + domainName,
                logGroup: logGroup.value,
                startTime: startTimeStamp,
                endTime: endTimeStamp,
              },
            });
          } else if ('relative' === requestDate.type) {
            resp = await requestLogs({
              variables: {
                domainId: clientId + ':' + domainName,
                logGroup: logGroup.value,
                startTime: startTimeStamp,
                hours: hours,
              },
            });
          }
          if (resp?.data?.requestLogs?.success) {
            const status = resp?.data?.requestLogs?.data;
            allStatus.push(...status);
          } else {
            setFlashbarItems([
              {
                type: 'error',
                content: `Error occured while requesting the logs for loggroup ${logGroup}`,
                dismissible: true,
                dismissLabel: 'Dismiss message',
                onDismiss: () =>
                    setFlashbarItems((flashbarItems) =>
                        flashbarItems.filter((item) => item.id !== 'loggroup_error')
                    ),
                id: 'loggroup_error',
              },
            ]);
          }
        } catch (e) {
          // TODO
        }
      }
      var statusLogMapping = {};
      for (let statusRequest of allStatus) {
        let logFile = statusRequest.log;

        if ('status' in statusRequest) {
          let status = statusRequest.status;
          if (!(status in statusLogMapping)) {
            statusLogMapping[status] = [logFile];
          } else {
            statusLogMapping[status].push(logFile);
          }
        } else {
          let error = statusRequest.error;
          if (!(error in statusLogMapping)) {
            statusLogMapping[error] = [logFile];
          } else {
            statusLogMapping[error].push(logFile);
          }
        }
      }

      const totalKeys = Object.keys(statusLogMapping);
      if (totalKeys.length === 1 && totalKeys[0] === '200') {
        setFlashbarItems([
          {
            type: 'success',
            content: 'Logs are requested successfully',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashbarItems([]),
          },
        ]);
      } else {
        let message = [];
        for (var key of Object.keys(statusLogMapping)) {
          if (key !== '200') {
            message.push('* ' + key + ':\t' + statusLogMapping[key].join(', '));
          }
        }

        var flashbarItems = [];
        flashbarItems.push({
          type: 'info',
          header: 'Requests for these logs were not successful -',
          // content: ,
          content: (
              <div style={{whiteSpace: 'pre-line'}}>{message.join('\n')}</div>
          ),
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () =>
              setFlashbarItems((flashbarItems) =>
                  flashbarItems.filter((item) => item.id !== 'error')
              ),
          id: 'error',
        });

        if ('200' in statusLogMapping) {
          flashbarItems.push({
            type: 'success',
            content: 'All other logs are requested successfully',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () =>
                setFlashbarItems((flashbarItems) =>
                    flashbarItems.filter((item) => item.id !== 'success')
                ),
            id: 'success',
          });
        }
        setFlashbarItems(flashbarItems);
      }
      props.refreshData(false)
    }
  };

  const [flashbarItems, setFlashbarItems] = useState([]);

  return (
    <>
      <Flashbar items={flashbarItems} />
      <ExpandableSection
        header={
          <Header
            variant="h2"
            info={
              <div
                style={{ display: 'inline-block' }}
                onClick={(e) => e.stopPropagation()}
              >
                <Popover
                  dismissAriaLabel="Close"
                  fixedWidth
                  header={REQUEST_LOGS_INSTRUCTION_HEADER}
                  position="right"
                  size="large"
                  triggerType="text"
                  renderWithPortal={true}
                  content={REQUEST_LOGS_INSTRUCTIONS.map((line) => (
                    <p>{line}</p>
                  ))}
                >
                  <Link variant="info">Info</Link>
                </Popover>
              </div>
            }
          >
            Request Logs
          </Header>
        }
        variant="container"
      >
        <SpaceBetween direction="vertical" size="l">
          <Grid
            gridDefinition={[
              { colspan: { default: 12, xxs: 1 } },
              { colspan: { default: 12, xxs: 3 } },
              { colspan: { default: 12, xxs: 3 } },
              { colspan: { default: 12, xxs: 2 } },
              { colspan: { default: 12, xxs: 2 } },
              { colspan: { default: 12, xxs: 1 } },
            ]}
          >
            <FormField label={<strong>ES/OS Version</strong>}>
              <Select
                selectedOption={selectedEsOsVersion}
                onChange={({ detail }) => {
                  setSelectedEsOsVersion(detail.selectedOption as any);
                  setSelectedLogGroups([]);
                }}
                options={esOsVersionOptions}
                selectedAriaLabel="Selected"
                placeholder="Choose options"
              />
            </FormField>
            <FormField
              label={<strong>Select the logs to be retrieved</strong>}
              errorText={errorTextLogGroups}
            >
              <Multiselect
                selectedOptions={selectedLogGroups}
                onChange={({ detail }) =>{
                  const newSelectedOptions = detail.selectedOptions as any;
                  if (newSelectedOptions.some(group => group.value === 'ELB')) {
                    // If 'elb' is selected, clear all other selections.
                    setSelectedLogGroups(newSelectedOptions.filter(group => group.value === 'ELB'));
                    setIsOnDemandLogGroups(true);
                  } else {
                    // If 'elb' is not selected, keep the new selections as-it-is.
                    setSelectedLogGroups(newSelectedOptions);
                    setIsOnDemandLogGroups(false);
                  }
                }
                }
                options={filteredLogGroupsOptions}
                selectedAriaLabel="Selected"
                placeholder="Choose options"
              />
            </FormField>
            <FormField label={<strong>Date</strong>}>
              <DateRangePicker
                onChange={handleRequestDateChange}
                value={requestDate}
                relativeOptions={relativeOptions}
                i18nStrings={i18nStrings}
                placeholder="Filter by a date and time range"
                isValidRange={isOnDemandLogGroups ? isValidRequestRangeForOnDemand : isValidRequestRange}
                showClearButton={false}
                timeOffset={selectedRequestTimeZoneOption.mins}
              />
            </FormField>
            <FormField label={<strong>Time Zone</strong>}>
              <Select
                selectedOption={selectedRequestTimeZoneOption}
                onChange={({ detail }) =>
                  handleRequestTimezoneChange(detail.selectedOption)
                }
                options={timezoneOptions}
                selectedAriaLabel="Selected"
              />
            </FormField>
            {!isHoursDisabled && (
              <FormField label={<strong>Hours</strong>}>
                <input
                  style={{ height: '32px' }}
                  type="number"
                  value={hours}
                  min={1}
                  max={isOnDemandLogGroups ? 48 : 72}
                  onChange={(event) => setHours(event.target.value)}
                />
              </FormField>
            )}
            <div style={{ marginTop: '26px' }}>
              <Button variant="normal" onClick={validateRequest}>
                Submit
              </Button>
            </div>
          </Grid>

          {/* <FormField
            label={<strong>Custom Log Group</strong>}
          >
            <Autosuggest
              onChange={({ detail }) => setCustomLog(detail.value)}
              value={customLog}
              options={logGroupOptions}
              placeholder="Enter custom log group"
              enteredTextLabel={value  => `Use: "${value}"`}
              empty="No Matches Found"
              virtualScroll={true}
              disabled={true}
            />
          </FormField> */}
          {elbLogSubmit && (
              <ListExecutions type='ELB' startTime={startTime} endTime={endTime} ref={ListExecutionsRef}/>
          )}
        </SpaceBetween>
      </ExpandableSection>
    </>
  );
};

export { RequestLogs };
