import React from 'react';
import { TumblerStat } from '../../Components/TumblerStat/TumblerStat';
import { ColumnLayout } from '@amzn/awsui-components-react';

interface CustomerDetailsProps {
  loading: boolean;
  totalDomains: number;
}
const CustomerDetails = (props: CustomerDetailsProps) => {
  return (
    <ColumnLayout columns={3}>
      <TumblerStat
        isLoading={props.loading}
        description={`${props.totalDomains}`}
        title="Total domains"
      />
      <TumblerStat
        isLoading={props.loading}
        description={'N/A'}
        title="Whiteglove ?"
      />
      <TumblerStat
        isLoading={props.loading}
        description={'N/A'}
        title="Account Status"
      />
    </ColumnLayout>
  );
};

export { CustomerDetails };
