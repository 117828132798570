import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks/dist/cjs/interfaces';

const convertTimestamp = (timestamp: number): string => {
  // Create a Date object from the Unix timestamp
  const date = new Date(timestamp * 1000);

  // Extract individual date and time components
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  // Format the string as per your requirement
  return `${year}${month}${day}T${hours}${minutes}Z`;
};

const stringToTimestamp = (str: string): number => {
  const dateStr = str.slice(0, 8);
  const timeStr = str.slice(9, 13);
  const year = parseInt(dateStr.slice(0, 4), 10);
  const month = parseInt(dateStr.slice(4, 6), 10) - 1; // month is 0-indexed in JS
  const day = parseInt(dateStr.slice(6, 8), 10);
  const hour = parseInt(timeStr.slice(0, 2), 10);
  const minute = parseInt(timeStr.slice(2, 4), 10);

  return Date.UTC(year, month, day, hour, minute) / 1000;
};

function convertTimeFormat(endTime) {
  // Convert the string to a format that can be parsed by Date
  const year = endTime.slice(0, 4);
  const month = endTime.slice(4, 6);
  const day = endTime.slice(6, 8);
  const hour = endTime.slice(9, 11);
  const minute = endTime.slice(11, 13);
  const second = '00'; // Add seconds if available

  const formattedEndTime = `${year}-${month}-${day}T${hour}:${minute}:${second}Z`;

  // Parse the string to a Date object
  const dateObj = new Date(formattedEndTime);

  // Format the date object to your desired output format
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'GMT',
    timeZoneName: 'short'
  }).format(dateObj);

  return formattedDate.replace(/\//g, '-');
}

export const LOG_VIEWER_FILTERING_PROPERTIES: readonly PropertyFilterProperty[] = [
  {
    propertyLabel: 'Date Time',
    key: 'date',
    groupValuesLabel: 'Date Time Values',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'Duration',
    key: 'duration',
    groupValuesLabel: 'Duration Values',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'Status Code',
    key: 'elbStatusCode',
    groupValuesLabel: 'Status Code Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Received Bytes',
    key: 'receivedBytes',
    groupValuesLabel: 'Received Bytes Values',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'Sent Bytes',
    key: 'sentBytes',
    groupValuesLabel: 'Sent Bytes Values',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'Uri',
    key: 'uri',
    groupValuesLabel: 'Uri Values',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'Client Ip',
    key: 'clientIp',
    groupValuesLabel: 'Client Ip Values',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'Target Ip',
    key: 'targetIp',
    groupValuesLabel: 'Target Ip Values',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'User Agent',
    key: 'userAgent',
    groupValuesLabel: 'User Agent Values',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
];

export const statusFilterItems = [
  {
    id: 'all',
    text: 'All',
  },
  {
    id: '4xx',
    text: '4xx',
  },
  {
    id: '5xx',
    text: '5xx',
  }
];

export const LIST_EXECUTION_FILTERING_PROPERTIES: readonly PropertyFilterProperty[] = [
  {
    propertyLabel: 'Log Group',
    key: 'Type',
    groupValuesLabel: 'Log Group Values',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'Status',
    key: 'BackfillStatus',
    groupValuesLabel: 'Status Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Requested By',
    key: 'User',
    groupValuesLabel: 'Requester Values',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  }
];

export const columnDefinitions = [
  {
    id: 'Type',
    header: 'Log Group',
    cell: (e) => e.Type
  },
  {
    id: 'RequestTime',
    header: 'Request Time',
    cell: (e) => convertTimeFormat(convertTimestamp(e.RequestTime)),
    sortingField: 'RequestTime',
  },
  {
    id: 'StartTime',
    header: 'Start Time',
    cell: (e) => convertTimeFormat(e.StartTime),
    sortingField: 'StartTime',
  },
  {
    id: 'EndTime',
    header: 'End Time',
    cell: (e) => convertTimeFormat(e.EndTime),
    sortingField: 'EndTime',
  },
  {
    id: 'FilesDetected',
    header: 'Files Detected',
    cell: (e) => e.FilesDetected,
    sortingField: 'FilesDetected',
  },
  {
    id: 'FilesIndexed',
    header: 'Files Indexed',
    cell: (e) => e.FilesIndexed,
    sortingField: 'FilesIndexed',
  },
  {
    id: 'BackfillStatus',
    header: 'Status',
    cell: (e) => e.BackfillStatus.toString(),
    sortingField: 'BackfillStatus'
  },
  {
    id: 'ErrorMessage',
    header: 'Error Message',
    cell: (e) => e.ErrorMessage || '-'
  },
  {
    id: 'FilesSize',
    header: 'Files Size',
    cell: (e) => e.FilesSize || '-'
  },
  {
    id: 'User',
    header: 'Requested By',
    cell: (e) => e.User,
    sortingField: 'User'
  }
];

export const logViewerColumnDefintions = [
  {
    id: 'date',
    header: 'Date Time',
    cell: (e) => e.date,
    sortingField: 'date',
  },
  {
    id: 'duration',
    header: 'Duration',
    cell: (e) => e.duration,
    sortingField: 'duration',
  },
  {
    id: 'elbStatusCode',
    header: 'ELB Status Code',
    cell: (e) => e.elbStatusCode,
  },
  {
    id: 'targetStatusCode',
    header: 'Target Status Code',
    cell: (e) => e.targetStatusCode,
  },
  {
    id: 'method',
      header: 'Method',
    cell: (e) => e.method,
  },
  {
    id: 'receivedBytes',
    header: 'Received Bytes',
    cell: (e) => e.receivedBytes,
    sortingField: 'receivedBytes',
  },
  {
    id: 'sentBytes',
    header: 'Sent Bytes',
    cell: (e) => e.sentBytes,
    sortingField: 'sentBytes',
  },
  {
    id: 'uri',
    header: 'URI',
    cell: (e) => (
        <div style={{ maxWidth: '150px', overflowX: 'auto', whiteSpace: 'nowrap' }}>
          {e.uri}
        </div>
    )
  },
  {
    id: 'clientIp',
    header: 'Client IP',
    cell: (e) => e.clientIp,
    sortingField: 'clientIp',
  },
  {
    id: 'targetIp',
    header: 'Target IP',
    cell: (e) => e.targetIp,
    sortingField: 'targetIp',
  },
  {
    id: 'userAgent',
    header: 'User Agent',
    cell: (e) => e.userAgent,
    sortingField: 'userAgent',
  }
]

export const AGGREGATION_TYPE = "aggregation"

export const TABLE_TYPE = "table"

export const summaryTableColumns = [
  {
    id: 'title',
    header: 'Title',
    cell: item => item.title || 'Other'
  },
  {
    id: 'sumY',
    header: 'Total Count',
    cell: item => item.sumY
  }
];

export const PAGE_SIZE_OPTIONS = [
  { value: 50, label: '50 Log Requests' },
  { value: 100, label: '100 Log Requests' },
  { value: 500, label: '500 Log Requests' },
  { value: 1000, label: '1000 Log Requests' },
  { value: 8000, label: '8000 Log Requests' },
];

export const DEFAULT_ELB_PREFERENCES = {
  pageSize: 15,
  visibleContent: [
    'Type',
    'RequestTime',
    'StartTime',
    'EndTime',
    'FilesDetected',
    'FilesIndexed',
    'BackfillStatus',
    'ErrorMessage',
    'FilesSize',
    'User',
    'logs',
    'aggregations',
    're_backfill',
    'KibanaLink',
  ],
  wraplines: false,
};

export const DEFAULT_PA_PREFERENCES = {
  pageSize: 15,
  visibleContent: [
    'Type',
    'RequestTime',
    'StartTime',
    'EndTime',
    'FilesDetected',
    'FilesIndexed',
    'BackfillStatus',
    'ErrorMessage',
    'FilesSize',
    'User',
    're_backfill',
  ],
  wraplines: false,
};

export const LIST_EXECUTION_VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Options',
    options: [
      { id: 'log_group', label: 'Log Group' },
      { id: 'request_time', label: 'Request Time' },
      { id: 'start_time', label: 'Start Time' },
      { id: 'end_time', label: 'End Time' },
      { id: 'files_detected', label: 'Files Detected' },
      { id: 'files_indexed', label: 'Files Indexed' },
      { id: 'status', label: 'Status' },
      { id: 'error_message', label: 'Error Message'},
      { id: 'file_size', label: 'Files Size' },
      { id: 'requester', label: 'Requested By' },
      { id: 're_backfill', label: 'Re Backfill' }
    ],
  },
];

export const LOG_VIEWER_VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Options',
    options: [
      { id: 'date_time', label: 'Date Time' },
      { id: 'duration', label: 'Duration' },
      { id: 'status_code', label: 'Status Code' },
      { id: 'received_bytes', label: 'Received Bytes' },
      { id: 'sent_bytes', label: 'Sent Bytes' },
      { id: 'uri', label: 'Uri' },
      { id: 'client_ip', label: 'Client Ip' },
      { id: 'target_ip', label: 'Target Ip' },
      { id: 'user_agent', label: 'User Agent' },
    ],
  },
];

export const aggregationFieldOptions = [
  {
    label: 'Term Aggregation',
    options: [
      {value: 'api' },
      {value: 'clientIp' },
      {value: 'endpoint' },
      {value: 'elb' },
      {value: 'elbStatusCode' },
      {value: 'method' },
      {value: 'targetIp' },
      {value: 'targetStatusCode' },
      {value: 'userAgent' },
    ],
  },
  {
    label: 'p100',
    options: [
      {value: 'p100:duration' },
      {value: 'p100:receivedBytes' },
      {value: 'p100:sentBytes' },
    ],
  },
  {
    label: 'p99.9',
    options: [
      {value: 'p99.9:duration' },
      {value: 'p99.9:receivedBytes' },
      {value: 'p99.9:sentBytes' },
    ],
  },
  {
    label: 'p90',
    options: [
      {value: 'p90:duration' },
      {value: 'p90:receivedBytes' },
      {value: 'p90:sentBytes' },
    ],
  },
  {
    label: 'p50',
    options: [
      {value: 'p50:duration' },
      {value: 'p50:receivedBytes' },
      {value: 'p50:sentBytes' },
    ],
  },
  {
    label: 'p0',
    options: [
      {value: 'p0:duration' },
      {value: 'p0:receivedBytes' },
      {value: 'p0:sentBytes' },
    ],
  },
  {
    label: 'Sum Aggregation',
    options: [
      {value: 'sum:duration' },
      {value: 'sum:receivedBytes' },
      {value: 'sum:sentBytes' },
    ],
  },
];

export const aggregationIntervalOptions = [
  {
    label: 'Aggregation Intervals',
    options: [
      { label: '1s', value: '1s' },
      { label: '10s', value: '10s' },
      { label: '1m', value: '1m' },
      { label: '5m', value: '5m' },
      { label: '10m', value: '10m' },
      { label: '1h', value: '1h' },
      { label: '1d', value: '1d' },
      { label: '1w', value: '1w' },
      { label: '1M', value: '1M' },
    ],
  }
]

export const LOG_VIEWER_DEFAULT_PREFERENCES = {
  pageSize: 500,
  visibleContent: [
    'date',
    'duration',
    'domain_id',
    'elbStatusCode',
    'targetStatusCode',
    'method',
    'receivedBytes',
    'sentBytes',
    'uri',
    'clientIp',
    'targetIp',
    'userAgent'
  ],
  wraplines: true,
};
