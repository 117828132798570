import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../../models/types';


export interface GetSecurityPolicyVars {
    awsAccountId: String;
    type: String;
    name: String;
}

const GET_SECURITY_POLICY = gql`
	     query GetSecurityPolicy(
	             $awsAccountId: String,
	             $type: String,
	             $name: String
	         ){
	         getSecurityPolicy(
	             awsAccountId: $awsAccountId,
	             type: $type,
	             name: $name
	         )
	     }
	 `;

export const useGetSecurityPolicyQuery = () => {
    return useLazyQuery<AnyObject, GetSecurityPolicyVars>(GET_SECURITY_POLICY, {
        errorPolicy: 'all',
    });
};