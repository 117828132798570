import SideNavigation, {
  SideNavigationProps,
} from '@amzn/awsui-components-react/polaris/side-navigation';
import * as React from 'react';

export const navItems: ReadonlyArray<SideNavigationProps.Item> = [
  {
    type: 'link',
    text: 'Ops Tumbler',
    href: '/',
  },
  {
    type: 'divider',
  },
  {
    type: 'section',
    text: 'OpenSearch Serverless',
    items: [
      { type: 'link', text: 'OpenSearch Serverless', href: '/juno' },
      { type: 'link', text: 'Logs', href: '/juno/log' },
    ],
  },
  {
    type: 'divider',
  },
  {
    type: 'section',
    text: 'LSE Tool',
    items: [
      { type: 'link', text: 'Create New Impact Analysis', href: '/lse-tool' },
    ],
  },
  {
    type: 'divider',
  },
  {
    type: 'section',
    text: 'Ops Transparency',
    items: [
      { type: 'link', text: 'Onboarding', href: '/ops-transparency' },
      { type: 'link', text: 'Onboarded', href: '/ops-transparency/onboarded' },
      {
        type: 'link',
        text: 'Verify All',
        href: '/ops-transparency/verify/all',
      },
      {
        type: 'link',
        text: 'Verify Actionable All',
        href: '/ops-transparency/verify/actionable/all',
      },
    ],
  },
  {
    type: 'divider',
  },
  {
    type: 'link',
    text: 'Audit History',
    href: '/audit-history',
  },
];

const Sidebar = (props) => {
  const [activeHref, setActiveHref] = React.useState('#/');
  return (
    <SideNavigation
      activeHref={activeHref}
      header={{ href: '', text: 'Ops Tools' }}
      onFollow={(event) => {
        if (!event.detail.external) {
          setActiveHref(event.detail.href);
        }
      }}
      items={navItems}
    />
  );
};

export { Sidebar };
