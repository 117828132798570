import React from 'react';
import { TableComponent } from '../../../Components/Common/TableComponent';

const WorkerShardsDetails = (props) => {
  const workerShardsListVisibleContent = [
    'replicaId',
    'physicalIndex',
    'state',
    'size',
    'mergeMeasurements',
  ];

  const WORKER_SHARDS_LIST_VISIBLE_CONTENT_OPTIONS = [
    {
      label: 'Options',
      options: workerShardsListVisibleContent.map((c) => {
        return { id: c, label: c };
      }),
    },
  ];

  const WORKER_SHARDS_LIST_FILTERING_PROPERTIES: any =
    workerShardsListVisibleContent.map((c) => {
      return {
        propertyLabel: c,
        key: c,
        groupValuesLabel: `${c} Values`,
        operators:
          c === 'CreatedAt' || c === 'UpdatedAt'
            ? [':', '!:', '=', '!=', '>', '>=', '<', '<=']
            : [':', '!:', '=', '!='],
      };
    });

  const WORKER_SHARDS_LIST_DEFAULT_PREFERENCES = {
    pageSize: 10,
    visibleContent: workerShardsListVisibleContent,
    wraplines: false,
  };

  const workerShardsListColumnDefinitions = [
    {
      id: 'replicaId',
      header: 'Replica Id',
      cell: (e) => (e['replicaId'] ? e['replicaId'] : ''),
      sortingField: 'replicaId',
    },
    {
      id: 'physicalIndex',
      header: 'Physical Index',
      cell: (e) => (e['physicalIndex'] ? e['physicalIndex'] : ''),
      sortingField: 'physicalIndex',
    },
    {
      id: 'state',
      header: 'State',
      cell: (e) => (e['state'] ? e['state'] : ''),
      sortingField: 'state',
    },
    {
      id: 'size',
      header: 'Size',
      cell: (e) => (e['size'] ? e['size'] : ''),
      sortingField: 'size',
    },
    {
      id: 'mergeMeasurements',
      header: 'Merge Measurements',
      cell: (e) =>
        e['mergeMeasurements']
          ? Object.keys(e['mergeMeasurements']).length === 0
            ? ''
            : e['mergeMeasurements']
          : '',
      sortingField: 'mergeMeasurements',
    },
  ];

  return (
    <TableComponent
      dataLoading={false}
      tableDefaultPreferences={WORKER_SHARDS_LIST_DEFAULT_PREFERENCES}
      data={props.shardDetails ? props.shardDetails : []}
      filteringProperties={WORKER_SHARDS_LIST_FILTERING_PROPERTIES}
      csvExportFileName="shardDetails.csv"
      columnDefinitions={workerShardsListColumnDefinitions}
      visibleContentOptions={WORKER_SHARDS_LIST_VISIBLE_CONTENT_OPTIONS}
      tableHeaderName="Shard Details"
    />
  );
};

export { WorkerShardsDetails };
