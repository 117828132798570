import { gql, useQuery } from '@apollo/client';

export interface ListMetricsVars {
  domainIdentifier: string;
}

export interface ListMetricsData {
  listMetrics: {
    metrics: String[];
  };
}
const GQL_LIST_METRICS = gql`
  query getExternalCwMetrics($domainIdentifier: String!) {
    listMetrics(domainIdentifier: $domainIdentifier)
  }
`;

export const useListCwMetrics = (vars: ListMetricsVars) => {
  return useQuery<ListMetricsData, ListMetricsVars>(GQL_LIST_METRICS, {
    variables: { ...vars },
  });
};
