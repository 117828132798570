import { SpaceBetween } from '@amzn/awsui-components-react';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useFetchCommandHistoryQuery } from '../hooks/fetchCommandHistoryQuery';

import { AuditHistoryViewer } from './AuditHistoryViewer';
import { RequestHistory } from './RequestHistory';

const FetchAuditHistory = (props) => {
  const [fetchCommandHistory, { data: commandHistory }] =
    useFetchCommandHistoryQuery();
  const [commandList, setCommandList] = React.useState([]);
  useEffect(() => {
    let tempCommandList = [];
    const temp_commands = get(props.commands, 'commands.commands', []);
    temp_commands.forEach((command) => {
      let output = [];
      if (command.sub_commands.length > 0) {
        let parent = '';
        if (command.parent) {
          let tempParent = command.parent.includes('.es')
            ? command.parent.replace('.es', '.node.es')
            : command.parent;
          parent = tempParent
            .split('.')
            .slice(2, -2)
            .join('/')
            .replace(',', '/');
        }
        // const name = command.name === 'es' ? 'node/es' : command.name;
        output.push({
          label: command.name,
          value: `${parent}/${command.name}`,
        });
        command.sub_commands.forEach((subCommand) => {
          output.push({
            label: `${command.name}/${subCommand.name}`,
            value: `${parent}/${command.name}/${subCommand.name}`,
          });
        });
        tempCommandList.push({
          label: `${parent}/${command.name}`,
          options: output,
        });
      }
    });
    setCommandList(tempCommandList);
  }, [props.commands]);

  const fetchHistory = (requestDate, path) => {
    var startMoment, endMoment;
    if ('absolute' === requestDate.type) {
      startMoment = moment(requestDate.startDate);
      endMoment = moment(requestDate.endDate);
    } else if ('relative' === requestDate.type) {
      startMoment = moment().subtract(requestDate.amount, requestDate.unit);
      endMoment = moment();
    }
    const startTimeStamp = startMoment.utc().format();
    const endTimeStamp = endMoment.utc().format();
    fetchCommandHistory({
      variables: {
        path: path,
        start_time: startTimeStamp,
        end_time: endTimeStamp,
        state: 'complete',
      },
    });
  };

  return (
    <SpaceBetween size="l" direction="vertical">
      <RequestHistory commandList={commandList} fetchHistory={fetchHistory} />
      {commandHistory ? (
        <AuditHistoryViewer commandHistoryRecords={commandHistory} />
      ) : (
        ''
      )}
    </SpaceBetween>
  );
};

export { FetchAuditHistory };
