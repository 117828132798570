import React from 'react';
import { EuiTextColor, EuiLoadingSpinner } from '@elastic/eui';

import { apiCall } from '../../utils';

export class ClusterInSync extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: '',
      isRequestActive: true,
      unSyncNodes: [],
    };
  }

  componentDidMount() {
    this.isClusterInSync().then(() => {});
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.stackName !== this.props.stackName ||
      prevProps.clusterId !== this.props.clusterId
    ) {
      this.setState({ unSyncNodes: [] });
      this.isClusterInSync().then(() => {});
    }
  }

  isClusterInSync = async (nextToken) => {
    const data = {
      cluster: {
        stackName: this.props.stackName,
        clusterId: this.props.clusterId,
      },
      maxNodes: 50,
      nextToken: nextToken,
    };
    const response = await apiCall('/cluster/syncStatus', data);
    this.updateResponse(response);
    if (response.body.nextToken) {
      await this.isClusterInSync(response.body.nextToken);
    } else if (response.statusCode === 200) {
      let status;
      if (this.state.unSyncNodes.length > 0) {
        status = 'ClusterNotInSync';
      } else {
        status = 'ClusterInSync';
      }
      this.setState({ isRequestActive: false, status });
    }
  };

  updateResponse(response) {
    if (response.statusCode === 200) {
      const nodesSyncState = response.body.nodesSyncState;
      let ip;
      for (ip in nodesSyncState) {
        if (!nodesSyncState[ip]) {
          this.state.unSyncNodes.push(ip);
        }
      }
    }
  }

  render() {
    const { status, isRequestActive } = this.state;
    if (isRequestActive) {
      return <EuiLoadingSpinner size="m" id="loadingSpinner" />;
    } else {
      const color = status === 'ClusterNotInSync' ? 'danger' : 'secondary';
      return <EuiTextColor color={color}>{status}</EuiTextColor>;
    }
  }
}
