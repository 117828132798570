export const ACCOUNTS_MAP = {
  aws: {
    prod: {
      'af-south-1': '903658014619',
      'ap-east-1': '903266545404',
      'ap-northeast-1': '304149726183',
      'ap-northeast-2': '832035452523',
      'ap-northeast-3': '623748463287',
      'ap-south-1': '795201284850',
      'ap-south-2': '914585166992',
      'ap-southeast-1': '837541681674',
      'ap-southeast-2': '608435581677',
      'ap-southeast-3': '480756163420',
      'ap-southeast-4': '951351363206',
      'ca-central-1': '555366574136',
      'ca-west-1': '065035117620',
      'eu-central-1': '444726143907',
      'eu-central-2': '287431578315',
      'eu-north-1': '707856417819',
      'eu-south-1': '559142271170',
      'eu-south-2': '102172935192',
      'eu-west-1': '671402871606',
      'eu-west-2': '551049295776',
      'eu-west-3': '934199854901',
      'il-central-1': '233463105128',
      'me-central-1': '496233138651',
      'me-south-1': '174563248922',
      'sa-east-1': '199930418999',
      'us-east-1': '828221060841',
      'us-east-2': '114931820050',
      'us-gov-east-1': '546180908232',
      'us-gov-west-1': '541372111424',
      'us-west-1': '941774024466',
      'us-west-2': '332315457451',
    },
    beta: {
      'af-south-1': '225010013390',
      'eu-south-1': '083125079871',
      'eu-west-1': '173482010194',
      'us-east-1': '098747032487',
      'us-gov-west-1': '630012887245',
      'us-west-2': '597285144728',
    },
    gamma: {
      'us-east-1': '678040537084',
      'us-west-2': '565622004581',
    },
    nexus: {
      'us-east-1': '004731450533',
    },
    dev: {
      'us-east-1': '636896061231',
    },
  },
  'aws-cn': {
    prod: {
      'cn-north-1': '000596701845',
      'cn-northwest-1': '917508982897',
    },
    beta: {
      'cn-north-1': '385388896143',
      'cn-northwest-1': '672794841506',
    },
  },
  'aws-iso': {
    prod: {
      'us-iso-east-1': '934948004131',
    },
    beta: {
      'us-iso-east-1': '306861554101',
    },
  },
  'aws-iso-b': {
    prod: {
      'us-isob-east-1': '754556727237',
    },
    beta: {
      'us-isob-east-1': '075344994531',
    },
  },
} as { [key: string]: any };

export const JUNO_BEHOLDERS_ACCOUNT_MAP = {
  aws: {
    alpha: {
      'us-east-1': '315473823901'
    },
    beta: {
      'us-east-1': '235735636826'
    },
    gamma: {
      'us-east-1': '372537475017'
    },
    prod: {
      'us-east-1': '508951005578',
      'ap-northeast-1': '135816256265',
      'us-west-2': '789553820115',
      'eu-west-1':'761067167649'
    },
  }
} as { [key: string]: any };
