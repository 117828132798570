import {
  Box,
  Button,
  Checkbox,
  Container,
  Flashbar,
  Form,
  FormField,
  Grid,
  Header,
  Input,
  SegmentedControl,
  SpaceBetween,
  Spinner,
} from '@amzn/awsui-components-react';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CopyText from '../../../utils/copy-text';
import { useOtsItemsQuery } from './otsItemsQuery';
import { useOtsOnboardQuery } from './otsOnboardQuery';
import { useUpdateRoleArnQuery } from './updateRoleArnQuery';

const Onboarding = (props) => {
  const awsAccountId = props.match.params.aws_account_id;
  const [isUsEast1Checked, setIsUsEast1Checked] = useState(false);
  const [isUsEast2Checked, setIsUsEast2Checked] = useState(false);
  const [isUsWest1Checked, setIsUsWest1Checked] = useState(false);
  const [isUsWest2Checked, setIsUsWest2Checked] = useState(false);
  const [isUsEast1Disabled, setIsUsEast1Disabled] = useState(false);
  const [isUsEast2Disabled, setIsUsEast2Disabled] = useState(false);
  const [isUsWest1Disabled, setIsUsWest1Disabled] = useState(false);
  const [isUsWest2Disabled, setIsUsWest2Disabled] = useState(false);
  const [isNewAccount, setIsNewAccount] = useState(false);
  const [roleArn, setRoleArn] = useState('');
  const [isUpdateRoleDisabled, setIsUpdateRoleDisabled] = useState(false);
  const [isAwsOrganization, setIsAwsOrganization] = useState('No');
  const [otsOnboarding, { loading: onboardLoading, data: onboardingResponse }] =
    useOtsOnboardQuery();
  const [updateRoleArnQuery, { data: updateRoleArnResponse }] =
    useUpdateRoleArnQuery();

  const { data, error } = useOtsItemsQuery({ awsAccountId });
  const [message, setMessage] = useState([]);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      setMessage([
        {
          type: 'success',
          content: location.state['detail'],
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setMessage([]),
        },
      ]);
    }

    if (error) {
      console.log(error);
      setIsNewAccount(true);
    }
    if (onboardingResponse && !onboardLoading) {
      console.log(
        get(onboardingResponse, 'otsOnboardResponse.onboardResponse', '')
      );
      history.push({
        pathname: '/ops-transparency/onboarded',
        state: {
          detail: get(
            onboardingResponse,
            'otsOnboardResponse.onboardResponse',
            ''
          ) as string,
        },
      });
    }
    if (data) {
      const otsItem = data.otsDomain.otsItem;
      if (otsItem !== null) {
        console.log(otsItem.onboarded_regions);
        // If role is assigned only then enable button
        if (otsItem.aws_org_read_role_arn !== null) {
          setRoleArn(otsItem.aws_org_read_role_arn);
          setIsUpdateRoleDisabled(true);
        }
        setIsNewAccount(false);
        if (otsItem.ots_acc_present_in_aws_org === 'TRUE') {
          setIsAwsOrganization('Yes');
        }

        otsItem.onboarded_regions.forEach((region) => {
          console.log(region);
          switch (region) {
            case 'us-east-1':
              setIsUsEast1Checked(true);
              setIsUsEast1Disabled(true);
              break;
            case 'us-east-2':
              setIsUsEast2Checked(true);
              setIsUsEast2Disabled(true);
              break;
            case 'us-west-1':
              setIsUsWest1Checked(true);
              setIsUsWest1Disabled(true);
              break;
            case 'us-west-2':
              setIsUsWest2Checked(true);
              setIsUsWest2Disabled(true);
              break;
            default:
              console.log('No other region exits!');
              break;
          }
        });
      }
    }
    if (updateRoleArnResponse) {
      history.push({
        pathname: `/ops-transparency/onboarding/${awsAccountId}`,
        state: {
          detail: get(
            updateRoleArnResponse,
            'updateRoleArnResponse.onUpdateRoleArnResponse',
            ''
          ),
        },
      });
      // TODO: This can be removed.
      history.go(0);
    }
  }, [
    data,
    onboardLoading,
    error,
    updateRoleArnResponse,
    awsAccountId,
    history,
    location.state,
    onboardingResponse,
  ]);

  const onboardAccount = (regions) => {
    otsOnboarding({
      variables: {
        awsAccountId: awsAccountId,
        isPartOfAwsOrg: isAwsOrganization === 'Yes' ? 'TRUE' : 'FALSE',
        regions: regions,
      },
    });
  };

  const handleOnboard = (e) => {
    let regions = [];
    if (!isUsEast1Disabled && isUsEast1Checked) {
      regions.push('us-east-1');
    }
    if (!isUsEast2Disabled && isUsEast2Checked) {
      regions.push('us-east-2');
    }
    if (!isUsWest1Disabled && isUsWest1Checked) {
      regions.push('us-west-1');
    }
    if (!isUsWest2Disabled && isUsWest2Checked) {
      regions.push('us-west-2');
    }
    console.log(regions);
    onboardAccount(regions);
  };

  const updateRoleArn = (regions) => {
    console.log('registering role');
    updateRoleArnQuery({
      variables: {
        awsAccountId: awsAccountId,
        regions: regions,
        roleArn: roleArn,
      },
    });
  };

  const handleUpdateRole = () => {
    let regions = [];
    if (isUsEast1Disabled && isUsEast1Checked) {
      regions.push('us-east-1');
    }
    console.log(onboardingResponse);
    if (isUsEast2Disabled && isUsEast2Checked) {
      regions.push('us-east-2');
    }
    console.log(onboardingResponse);
    if (isUsWest1Disabled && isUsWest1Checked) {
      regions.push('us-west-1');
    }
    console.log(onboardingResponse);
    if (isUsWest2Disabled && isUsWest2Checked) {
      regions.push('us-west-2');
    }
    updateRoleArn(regions);
  };
  return (
    <React.Fragment>
      {data || isNewAccount ? (
        <SpaceBetween direction="vertical" size="l">
          <Header variant="h1">{awsAccountId}</Header>
          <Flashbar items={message} />
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="primary" onClick={(e) => handleOnboard(e)}>
                  Onboard
                </Button>
              </SpaceBetween>
            }
          >
            <Container header={<Header variant="h2">Register</Header>}>
              <SpaceBetween direction="vertical" size="l">
                <FormField>
                  <SpaceBetween direction="horizontal" size="s">
                    <strong>Regions To Onboard:</strong>
                    <Checkbox
                      onChange={({ detail }) =>
                        setIsUsEast1Checked(detail.checked)
                      }
                      checked={isUsEast1Checked}
                      disabled={isUsEast1Disabled}
                    >
                      us-east-1
                    </Checkbox>
                    <Checkbox
                      onChange={({ detail }) =>
                        setIsUsEast2Checked(detail.checked)
                      }
                      checked={isUsEast2Checked}
                      disabled={isUsEast2Disabled}
                    >
                      us-east-2
                    </Checkbox>
                    <Checkbox
                      onChange={({ detail }) =>
                        setIsUsWest1Checked(detail.checked)
                      }
                      checked={isUsWest1Checked}
                      disabled={isUsWest1Disabled}
                    >
                      us-west-1
                    </Checkbox>
                    <Checkbox
                      onChange={({ detail }) =>
                        setIsUsWest2Checked(detail.checked)
                      }
                      checked={isUsWest2Checked}
                      disabled={isUsWest2Disabled}
                    >
                      us-west-2
                    </Checkbox>
                  </SpaceBetween>
                </FormField>
                <FormField>
                  <SpaceBetween direction="horizontal" size="s">
                    <strong>Present in AWS organizations:</strong>
                    <SegmentedControl
                      selectedId={isAwsOrganization}
                      onChange={({ detail }) =>
                        setIsAwsOrganization(detail.selectedId)
                      }
                      label="Segmented control with a disabled segment"
                      options={[
                        { text: 'Yes', id: 'Yes', disabled: !isNewAccount },
                        {
                          disabled: !isNewAccount,
                          text: 'No',
                          id: 'No',
                        },
                      ]}
                    />
                  </SpaceBetween>
                </FormField>
                {data && !isNewAccount ? (
                  data.otsDomain.otsItem.ots_acc_present_in_aws_org ===
                    'TRUE' && (
                    <Grid
                      gridDefinition={[{ colspan: 11, offset: { xxs: 1 } }]}
                    >
                      <SpaceBetween direction="vertical" size="l">
                        <SpaceBetween direction="horizontal" size="s">
                          <strong>Trusted Entity External Id: </strong>
                          <CopyText
                            copyText={
                              data.otsDomain.otsItem.trusted_entity_external_id
                            }
                            copyButtonLabel={
                              <code>
                                {
                                  data.otsDomain.otsItem
                                    .trusted_entity_external_id
                                }
                              </code>
                            }
                            successText="Trusted Entity External Id copied"
                            errorText="Trusted Entity External Id failed to copy"
                          />
                        </SpaceBetween>
                        <Grid
                          gridDefinition={[
                            { colspan: 0.5 },
                            { colspan: 4 },
                            { colspan: 1.5 },
                            { colspan: 1 },
                          ]}
                        >
                          <strong>Role Arn: </strong>
                          <Input
                            onChange={({ detail }) => setRoleArn(detail.value)}
                            value={roleArn}
                            placeholder="Enter role arn"
                            disabled={isUpdateRoleDisabled}
                          />
                          <Button
                            onClick={handleUpdateRole}
                            disabled={isUpdateRoleDisabled}
                          >
                            Update Role
                          </Button>
                          <Button
                            onClick={(e) => setIsUpdateRoleDisabled(false)}
                            disabled={!isUpdateRoleDisabled}
                          >
                            Edit
                          </Button>
                        </Grid>
                      </SpaceBetween>
                    </Grid>
                  )
                ) : (
                  <p></p>
                )}
              </SpaceBetween>
            </Container>
          </Form>
        </SpaceBetween>
      ) : (
        <Box textAlign="center">
          <Spinner size="large" />
        </Box>
      )}
    </React.Fragment>
  );
};

export { Onboarding };
