import { Container, Header, Tabs } from '@amzn/awsui-components-react';
import { get } from 'lodash';
import React, { useState, useEffect } from 'react';
import {
  AutoScalingEvents,
  AutoScalingGroups,
  Elb,
  LaunchConfigurations,
  SecurityGroups,
} from '../../../models/types';
import { AutoScalingGroup } from '../AutoScalingGroups/AutoScalingGroups';
import { useGroupInfoQuery } from '../hooks/useGroupInfoQuery';
import { LaunchConfiguration } from '../LaunchConfigurations/LaunchConfigurations';
import { LaunchType } from '../LaunchConfigurations/columns';
import { SecurityGroup } from '../SecurityGroups/SecurityGroups';
import { TMSIssues } from '../TMSIssues/TMSIssues';
import { TMSIssuesArchive } from '../TMSIssues/TMSIssuesArchive';
import { ErrorBoundary } from 'react-error-boundary';
import { Fallback } from "../../../Components/Fallback";
import { isError } from "../../../ErrorHandler/apiErrorHandler";

interface DashboardProps {
  domainIdentifier: string;
  elb: Elb;
}

const DomainInspector = (props: DashboardProps) => {
  const [loadGraphs] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const {
    loading: resourceLoading,
    data,
    error,
  } = useGroupInfoQuery({
    domainIdentifier: props.domainIdentifier,
  });
  const isErrorPresent = isError(error)
   useEffect(() => {
    if (isErrorPresent) {
        setErrorMessage("Unable to get Group Resources. Failed with " + error.message);
    }
  }, [isErrorPresent, error]);
  const asgs: AutoScalingGroups[] = get(
    data,
    'domain.resources.GROUP.autoScalingGroups',
    []
  );
  const securityGroups: SecurityGroups[] = get(
    data,
    'domain.resources.GROUP.securityGroups',
    []
  );
  const asgEvents: AutoScalingEvents[] = get(
    data,
    'domain.resources.GROUP.autoScalingEvents',
    []
  );
  const launchConfigs: LaunchConfigurations[] = get(
    data,
    'domain.resources.GROUP.launchConfigurations',
    []
  );
  const launchTemplates: LaunchConfigurations[] = get(
    data,
    'domain.resources.GROUP.launchTemplates',
    []
  )
  const tabs = [
    {
      id: 'tmsIssues',
      name: 'TMS issues',
      label: 'TMS issues',
      content: !loadGraphs ? (
        <div style={{ margin: '-20px 0 -20px 0' }}>
          <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"TMSIssuesComponent"}/>}>
          <TMSIssues domainIdentifier={props.domainIdentifier} />
          </ErrorBoundary>
        </div>
      ) : (
        <div>Loading..</div>
      ),
      disabled: false,
    },
    {
      id: 'tmsIssuesArchive',
      name: 'TMS Archive issues',
      label: 'TMS Archive issues',
      content: !loadGraphs ? (
        <div style={{ margin: '-20px 0 -20px 0' }}>
          <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"ArchivedTMSIssuesComponent"}/>}>
          <TMSIssuesArchive domainIdentifier={props.domainIdentifier} />
          </ErrorBoundary>
        </div>
      ) : (
        <div>Loading..</div>
      ),
      disabled: false,
    },
    {
      id: 'securityGroups',
      name: 'Security groups',
      label: 'Security groups',
      content: !loadGraphs ? (
        <div style={{ margin: '-20px 0 -20px 0' }}>
          <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"SecurityGroupsComponent"}/>}>
          <SecurityGroup
            domainIdentifier={props.domainIdentifier}
            securityGroups={securityGroups}
            isLoading={resourceLoading}
            errorMessage={errorMessage}
          />
          </ErrorBoundary>
        </div>
      ) : (
        <div>Loading..</div>
      ),
      disabled: false,
    },
  ];
  
  if (launchConfigs.length > 0) {
    tabs.push({
      id: 'launchConfigurations',
      name: 'Launch configurations',
      label: 'Launch configurations',
      content: !loadGraphs ? (
        <div style={{ margin: '-20px 0 -20px 0' }}>
          <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"LaunchConfigurationsComponent"}/>}>
          <LaunchConfiguration
            domainIdentifier={props.domainIdentifier}
            launchConfigs={launchConfigs as any}
            isLoading={resourceLoading}
            errorMessage={errorMessage}
            launchType={LaunchType.Config}
          />
          </ErrorBoundary>
        </div>
      ) : (
        <div>Loading..</div>
      ),
      disabled: false,
    });
  }

  if (launchTemplates.length > 0) {
    tabs.push({
      id: 'launchTemplates',
      name: 'Launch templates',
      label: 'Launch templates',
      content: !loadGraphs ? (
        <div style={{ margin: '-20px 0 -20px 0' }}>
          <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"LaunchConfigurationsComponent"}/>}>
          <LaunchConfiguration
            domainIdentifier={props.domainIdentifier}
            launchConfigs={launchTemplates as any}
            isLoading={resourceLoading}
            errorMessage={errorMessage}
            launchType={LaunchType.Template}
          />
          </ErrorBoundary>
        </div>
      ) : (
        <div>Loading..</div>
      ),
      disabled: false,
    });
  }

  tabs.push({
    id: 'asgs',
    name: 'Autoscaling groups',
    label: 'Autoscaling groups',
    content: !loadGraphs ? (
      <div style={{ margin: '-20px 0 -20px 0' }}>
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"ASGComponent"}/>}>
        <AutoScalingGroup
          domainIdentifier={props.domainIdentifier}
          events={asgEvents}
          asgs={asgs}
          elb={props.elb}
          isLoading={resourceLoading}
          errorMessage={errorMessage}
        />
        </ErrorBoundary>
      </div>
    ) : (
      <div>Loading..</div>
    ),
    disabled: false,
  });
  return (
    <Container
      disableContentPaddings
      header={<Header variant="h2">Other information</Header>}
    >
      <Tabs tabs={tabs} />
    </Container>
  );
};

export { DomainInspector };
