import {
  Button,
  ColumnLayout,
  Header,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import Container from '@amzn/awsui-components-react/polaris/container';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import * as React from 'react';
import { useFlyout } from '../../../hooks/useFlyout';
import { SearchResult } from './SearchResult';

const Sop = () => {
  const [filteringText, setFilteringText] = React.useState('');
  const setFlyout = useFlyout();
  return (
    <Container
      header={
        <Header
          variant="h2"
          description="Search for relevant sop for TMS issues here"
        >
          Search for SOP's
        </Header>
      }
    >
      <ColumnLayout columns={2}>
        <SpaceBetween size="l">
          <TextFilter
            filteringText={filteringText}
            filteringPlaceholder="Search by providing issue or keyword of issue for e.g. dp:es-elb-unhealthy-node"
            filteringAriaLabel="Filter instances"
            onChange={({ detail }) => setFilteringText(detail.filteringText)}
          />
        </SpaceBetween>
        <SpaceBetween size="l">
          <Button
            variant="primary"
            onClick={() =>
              setFlyout({
                title: 'SOP Search Result',
                size: 'm',
                content: <SearchResult query={filteringText} />,
              })
            }
          >
            Search
          </Button>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
};

export { Sop };
