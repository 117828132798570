import React from 'react';
import { TimezoneToggle } from '../../../Components/TimezoneToggle';

export const columns = [
  {
    id: 'groupName',
    sortingField: 'groupName',
    name: 'Group name',
    header: 'Group name',
    sortable: true,
    textAlign: 'center',
    cell: (e) => e.groupName,
  },
  {
    id: 'groupId',
    sortingField: 'groupId',
    name: 'Group Id',
    header: 'Group Id',
    sortable: false,
    cell: (e) => e.groupId,
  },
  {
    id: 'partitions',
    sortingField: 'partitions',
    name: 'Partitions',
    header: 'Partitions',
    cell: (e) => e.partitions,
  },
  {
    id: 'dateTime',
    sortingField: 'dateTime',
    name: 'Created time',
    header: 'Created time',
    cell: (e) => <TimezoneToggle date={new Date(e.dateTime)} />,
  },
];

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Options',
    options: [
      {
        id: 'groupName',
        label: 'Group Name',
      },
      { id: 'groupId', label: 'Group Id' },
      { id: 'partitions', label: 'Partitions' },
      { id: 'dateTime', label: 'Datetime' },
      { id: 'softwareVersion', label: 'Software Version' },
    ],
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10' },
  { value: 30, label: '30' },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 10,
  visibleContent: [
    'groupName',
    'groupId',
    'partitions',
    'dateTime',
    'softwareVersion',
  ],
  wraplines: true,
};
