import {
    SpaceBetween,
    StatusIndicator} from '@amzn/awsui-components-react';
import { get } from 'lodash';
import React from 'react';
import { AnyObject } from '../../models/types';
import { ElasticResponse } from './hooks/useClusterInfoQuery';

interface DecommissionInfoProps {
    isLoading: boolean;
    elasticResponse: ElasticResponse;
    isCarbonDomain: string;
}

const DecommissionStatusIndicator  = (status) => {
    switch (status.toLowerCase()) {
        case 'init': {
            // @ts-ignore
            return <StatusIndicator type="loading"> {status} </StatusIndicator>;
        }
        case 'in_progress': {
            return <StatusIndicator type="in-progress"> {status} </StatusIndicator>;
        }
        case 'draining': {
            return <StatusIndicator type="pending"> {status} </StatusIndicator>;
        }
        case 'successful': {
            return <StatusIndicator> {status} </StatusIndicator>;
        }
        case 'failed': {
            return <StatusIndicator type="error"> {status} </StatusIndicator>;
        }
        default :{
            return <StatusIndicator> {status} </StatusIndicator>;
        }
    }
};

const DecommissionInfo = (props: DecommissionInfoProps) => {
    const decommissionStatus: AnyObject =
        get(props, 'elasticResponse.cluster.decommission', {}) || {};

    let decommission = [];
    for (let az in decommissionStatus) {
        decommission.push(
            props.isLoading ? '--' :
                <SpaceBetween direction="horizontal" size="l">
                    <strong>{az}</strong>
                    <strong>State: {DecommissionStatusIndicator(decommissionStatus[az])}</strong>
                </SpaceBetween>
        );
    }

    return (props.isCarbonDomain === 'true') ? (
        <SpaceBetween direction="horizontal" size="l">
            <strong> Decommissioned Zone : </strong>
            {props.isLoading ? '--' : ((decommission.length !== 0) ? (
                <div> {decommission} </div>) :
                (<b>There is no decommissioned zone as of now.</b>))
            }
        </SpaceBetween>
    ) : (<></>);
};

export { DecommissionInfo };
