import {
  Box,
  ColumnLayout,
  ExpandableSection,
  Grid,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { startCase } from 'lodash';
import React from 'react';
import { a11yLight, CopyBlock } from 'react-code-blocks';
import { TimezoneToggle } from '../../Components/TimezoneToggle';
import { AnyObject } from '../../models/types';

interface ConfigViewerProps {
  configName: string;
  status: AnyObject;
  options: AnyObject;
}

const formatDescription = (content: any) =>
  typeof content === 'object' ? JSON.stringify(content, null, 4) : content;

const ConfigViewer = ({ configName, status, options }: ConfigViewerProps) => {
  let optionsData: any[] = [];
  if (options) {
    optionsData = Object.keys(options).map((option: string) => ({
      title: `${startCase(option)}`,
      description: `${formatDescription(
        option === 'accessPolicies' && options[option] != null
          ? JSON.parse(options[option])
          : options[option]
      )}`,
    }));
  }

  const ValueWithLabel = ({ label, children }) => (
    <div>
      <Box margin={{ bottom: 'xxxs' }} color="text-label">
        {label}
      </Box>
      <div>{children}</div>
    </div>
  );
  return (
    <Grid
      gridDefinition={[
        { colspan: { default: 3, xxs: 8 } },
        { colspan: { default: 9, xxs: 4 } },
      ]}
    >
      <ExpandableSection
        variant="navigation"
        header={
          <p>
            {startCase(configName)} - ({configName})
          </p>
        }
        defaultExpanded
      >
        <SpaceBetween direction="vertical" size="xs">
          {optionsData.map(function (option, index) {
            return (
              <div>
                <ColumnLayout
                  borders="horizontal"
                  columns={2}
                  variant="text-grid"
                >
                  <div>
                    <strong>{option['title']}</strong>
                  </div>
                  <div
                    style={{
                      fontFamily:
                        '"Roboto Mono",Consolas,Menlo,Courier,monospace',
                    }}
                  >
                    <CopyBlock
                      text={
                        typeof option['description'] === 'string'
                          ? option['description']
                          : JSON.stringify(option['description'], null, 4)
                      }
                      overflowHeight={1000}
                      language={
                        typeof option['description'] === 'string'
                          ? 'powershell'
                          : 'json'
                      }
                      theme={a11yLight}
                      wrapLines
                      codeBlock
                      showLineNumbers={false}
                    />
                  </div>
                  {/* <div>{option['description']}</div> */}
                </ColumnLayout>
              </div>
            );
          })}
        </SpaceBetween>
      </ExpandableSection>

      <SpaceBetween size="l">
        <ValueWithLabel label="State:"> {status.state}</ValueWithLabel>
        <ValueWithLabel label="Update Date:">
          <TimezoneToggle date={new Date(status.updateDate * 1000)} />
        </ValueWithLabel>
      </SpaceBetween>
    </Grid>
  );
};

export { ConfigViewer };
