import { get } from 'lodash';
import moment from 'moment';
import { PlotData } from 'plotly.js';

export const dashboardConfig = (region: string, rootAccountId: string) => {
  return {
    displayMode: 'full',
    widgetMarginX: 10,
    widgetMarginY: 10,
    style: 'polaris',
    internal: {
      accountId: rootAccountId,
      stage: 'prod',
    },
    region,
  };
};

export const METRICS_DURATION = {
  '1h': () => ({
    startTime: moment().subtract(1, 'hours').unix(),
    endTime: moment().unix(),
    period: 300,
  }),
  '3h': () => ({
    endTime: moment().unix(),
    startTime: moment().subtract(3, 'hours').unix(),
    period: 300,
  }),
  '12h': () => ({
    endTime: moment().unix(),
    startTime: moment().subtract(12, 'hours').unix(),
    period: 300,
  }),
  '1d': () => ({
    endTime: moment().unix(),
    startTime: moment().subtract(1, 'days').unix(),
    period: 300,
  }),
  '3d': () => ({
    endTime: moment().unix(),
    startTime: moment().subtract(3, 'days').unix(),
    period: 300,
  }),
  '1w': () => ({
    endTime: moment().unix(),
    startTime: moment().subtract(1, 'weeks').unix(),
    period: 300,
  }),
  '2w': () => ({
    endTime: moment().unix(),
    startTime: moment().subtract(2, 'weeks').unix(),
    period: 300,
  }),
  '1m': () => ({
    endTime: moment().unix(),
    startTime: moment().subtract(1, 'months').unix(),
    period: 3600,
  }),
  '3m': () => ({
    endTime: moment().unix(),
    startTime: moment().subtract(3, 'months').unix(),
    period: 3600,
  }),
} as { [key: string]: any };

// If unit is Megabytes then converting that into Gigabytes. Added unit parameter on plotdata.
export const getPlotData = (cwMetricsData: any, utcOffset: number) => {
  const timeStamps = get(cwMetricsData[0], 'getJunoCwMetrics.timeStamps', [0, 0]).map(
    (timestamp: number) => new Date((timestamp + utcOffset) * 1000)
  );
  let metricDataList = []
  cwMetricsData.forEach(data => {
    const metricData = get(data, 'getJunoCwMetrics.externalMetrics', [
      { values: [0, 0], label: 'Loading...' },
    ])
    metricDataList.push(metricData)
  })
  return metricDataList.map((metricData: any) => ({
    x: timeStamps,
    y:
      metricData.unit === 'Megabytes'
        ? metricData.values.map((v) => v / 1000)
        : metricData.values,
    type: 'scatter',
    mode: 'lines',
    name: metricData.label,
    unit:
      metricData.unit !== 'None'
        ? metricData.unit === 'Megabytes'
          ? 'GB'
          : metricData.unit
        : '',
  })) as PlotData[];
};

export const cw_metric_map = new Map(
  [
    ['OCU History', 'OCU History: Number of OCUs used by an account over time'],
  ]
);
