import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks/dist/cjs/interfaces';

export const TIME_WINDOW_OPTIONS = [
  { text: '3h', id: '3hours' },
  { text: '12h', id: '12hours' },
  { text: '1d', id: '1days' },
  { text: '3d', id: '3days' },
  { text: '7d', id: '7days' },
  { text: '4w', id: '4weeks' },
];

export const TIME_INTERVAL_OPTIONS = [
  { text: '5m', id: '5m' },
  { text: '15m', id: '15m', disabled: false },
  { text: '1h', id: '1h', disabled: false },
];

export const CARD_FILTERING_PROPERTIES: readonly PropertyFilterProperty[] = [
  {
    propertyLabel: 'Issue Name',
    key: 'name',
    groupValuesLabel: 'card title value',
    operators: [':', '!:', '=', '!='],
  },
];

export const CUSTOMER_IMPACT_CP_TMS_ISSUES = [
  'cp_event:CreateDomainInstance:MaximumCallRetryTimeExceededError',
  'cp_event:CreateDomainInstance:InternalException',
  'cp_event:CreateDomainInstance:timeout',
  'cp_event:CreateDomainInstance:LimitExceededException',
  'cp_event:CreateDomainInstance:ActivityTimeoutError',
  'cp_event:CreateDomainInstance:AssertionError',
  'cp_event:CreateDomainInstance:LegosCreateVPCException',
  'cp_event:DeleteDomainInstance:BotoServerError',
  'cp_event:DeleteDomainInstance:gaierror',
  'cp_event:DeleteDomainInstance:Timeout',
];

export const CUSTOMER_IMPACT_DP_TMS_ISSUES = [
  'dp:high-failure-rate',
  'dp:unhealthy-elb-nodes',
  'dp:es-master-quorum-down-multi-node',
  'dp:es-master-quorum-down',
  'dp:es-red-cluster',
  'dp:es-no-master-found',
  'dp:es-red-cluster-with-replica',
  'dp:es-cluster-write-blocked',
];
