import {
  Badge,
  Box,
  ColumnLayout,
  Container,
  Header,
  Popover,
  SpaceBetween,
  StatusIndicator,
} from '@amzn/awsui-components-react/polaris';

const ValueWithLabel = ({ label, children }) => {
  return (
    <div>
      <Box margin={{ bottom: 'xxxs' }} color="text-status-info">
        {label}
      </Box>
      <Box margin={{ bottom: 'xxxs' }} color="text-status-inactive">
        {children}
      </Box>
    </div>
  );
};

const ValueWithBadge = ({ label, children }) => {
  let items = [];
  for (let i = 0; i < children.length; i++) {
    items.push(<Badge color="grey">{children[i]}</Badge>);
  }
  return (
    <div>
      <Box margin={{ bottom: 'xxxs' }} color="text-status-info">
        {label}{' '}
        <Popover
          dismissButton={false}
          position="top"
          size="small"
          triggerType="custom"
          content="These are the issues you selected from the previous page."
        >
          <StatusIndicator type="info"></StatusIndicator>
        </Popover>
      </Box>
      {items}
    </div>
  );
};

const EventDetails = (props) => {
  const startTime = props.request['startTime'] || '';
  const endTime = props.request['endTime'] || '';
  const timeWindow = props.request['timeWindow'] || '';
  const interval = props.request['interval'] || '';
  const lseStartTime = props.request['lseStartTime'] || '';
  const lseEndTime = props.request['lseEndTime'] || '';
  const issueTypes = props.request['issueTypes'] || '';
  // const requestTime = props.request['requestTime'] || '';
  return (
    <Container header={<Header variant="h2">Analysis Details</Header>}>
      <ColumnLayout columns={4} variant="text-grid">
        <SpaceBetween size="l">
          <ValueWithBadge label="Issue types">{issueTypes}</ValueWithBadge>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Analysis start time">
            <StatusIndicator type="pending">{startTime}</StatusIndicator>
          </ValueWithLabel>
          <ValueWithLabel label="Analysis end time">
            <StatusIndicator type="pending">{endTime}</StatusIndicator>
          </ValueWithLabel>{' '}
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="LSE start time">
            <StatusIndicator type="pending">{lseStartTime}</StatusIndicator>
          </ValueWithLabel>
          <ValueWithLabel label="LSE end time">
            <StatusIndicator type="pending">{lseEndTime}</StatusIndicator>
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Time window">{timeWindow}</ValueWithLabel>
          <ValueWithLabel label="Interval">{interval}</ValueWithLabel>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
};
export { EventDetails };
