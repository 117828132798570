import { gql, useQuery } from '@apollo/client';

export interface TmsIssuesVars {
  domainIdentifier: string;
}

export interface TmsIssue {
  issueType: string;
  tmsUpdateTimestamp: Date;
  tmsCreateTimestamp: Date,
  details: string;
}

export interface TmsIssuesData {
  domain: {
    tmsIssues: TmsIssue[];
  };
}
const GQL_DOMAIN_TMS_ISSUES = gql`
  query getDomainTMSIssues($domainIdentifier: String!) {
    domain(domainIdentifier: $domainIdentifier) {
      tmsIssues
    }
  }
`;

export const useTmsQuery = (vars: TmsIssuesVars) => {
  return useQuery<TmsIssuesData, TmsIssuesVars>(GQL_DOMAIN_TMS_ISSUES, {
    variables: { ...vars },
  });
};
