import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks/dist/cjs/interfaces';

export const auditHistoryColumns = [
  {
    id: 'domain_id',
    header: 'Domain ID',
    cell: (e) => e.domain_id,
    sortingField: 'domain_id',
  },
  {
    id: 'time',
    sortingField: 'time',
    header: 'Time',
    cell: (e) =>
      new Date(parseInt(e.time.___MECH_VALUE___) * 1000).toISOString(),
  },
  {
    id: 'request_command',
    sortingField: 'request_command',
    header: 'Command Path',
    cell: (e) => e.request_command,
  },
  {
    id: 'source_host',
    sortingField: 'source_host',
    header: 'Source Host',
    cell: (e) => e.source_host,
  },
  {
    id: 'user',
    sortingField: 'user',
    header: 'User',
    cell: (e) => e.user,
  },
  {
    id: 'request_client_id',
    sortingField: 'request_client_id',
    header: 'Requester',
    cell: (e) => e.request_client_id,
  },
  {
    id: 'cli_command',
    sortingField: 'cli_command',
    header: 'CLI Command',
    cell: (e) => e.cli_command,
  },
  {
    id: 'request_id',
    sortingField: 'request_id',
    header: 'Command ID',
    cell: (e) => e.request_id,
  },
];

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Main Fields',
    options: [
      { id: 'domain_id', label: 'Domain ID' },
      { id: 'time', label: 'Time' },
      { id: 'request_command', label: 'Command Path' },
      { id: 'source_host', label: 'Source Host' },
      { id: 'user', label: 'User' },
      { id: 'request_client_id', label: 'Request Client Id' },
      { id: 'cli_command', label: 'CLI Command' },
      { id: 'request_id', label: 'Request Id' },
    ],
  },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 1000,
  visibleContent: [
    'domain_id',
    'time',
    'request_command',
    'source_host',
    'user',
    'request_client_id',
  ],
  wraplines: false,
};

export const FILTERING_PROPERTIES: readonly PropertyFilterProperty[] = [
  {
    propertyLabel: 'Domain Id',
    key: 'domain_id',
    groupValuesLabel: 'domain_id values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Request Command',
    key: 'request_command',
    groupValuesLabel: 'request command values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Source Host',
    key: 'source_host',
    groupValuesLabel: 'Source Host values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'User',
    key: 'user',
    groupValuesLabel: 'User values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Request Client ID',
    key: 'request_client_id',
    groupValuesLabel: 'request_client_id Values',
    operators: [':', '!:', '=', '!='],
  },
];
