import React from 'react';
import {
  EuiTabs,
  EuiTab,
  EuiButtonIcon,
  EuiHealth,
  EuiLoadingSpinner,
  EuiInMemoryTable,
  EuiFlexGroup,
  EuiFlexItem,
} from '@elastic/eui';
import { apiCall } from '../../../utils';
import ContentPanel from '../../../../Components/ContentPanel/ContentPanel';
import { pagination } from './constants';
import { ErrorDisplay } from '../../../components/ErrorDisplay';
import { Console } from './Console/Console';
import { CommandRunner } from './Console/CommandRunner';
import ElementErrorBoundary from '../../../components/ErrorBoundary/ElementErrorBoundary';

const actions = [
  {
    name: 'Ping Agent',
    description: 'Ping SDPDAgent',
    icon: 'heartbeatApp',
    type: 'icon',
    color: 'danger',
    onClick: (item) => {},
  },
  {
    name: 'Update Agent',
    description: 'Update SDPDAgent',
    icon: 'refresh',
    type: 'icon',
    color: 'success',
    onClick: (item) => {},
  },
  {
    name: 'Activate Agent',
    description: 'Activate SDPDAgent',
    icon: 'play',
    type: 'icon',
    color: 'success',
    onClick: (item) => {},
  },
  {
    name: 'Deactivate Agent',
    description: 'Deactivate SDPDAgent',
    icon: 'stop',
    type: 'icon',
    color: 'danger',
    onClick: (item) => {},
  },
  /*{
    name: 'Explore Node',
    description: 'Explore Node',
    icon: 'folderOpen',
    type: 'icon',
    color: 'primary',
    onClick: item => {},
  },*/
  {
    name: 'Disk Space',
    description: 'Check disk space',
    icon: 'storage',
    type: 'icon',
    color: 'primary',
    onClick: (item) => {},
  },
  {
    name: 'Install Docker',
    description: 'Install Docker',
    icon: 'package',
    type: 'icon',
    color: 'primary',
    onClick: (item) => {},
  },
  {
    name: 'Console',
    description: 'Console',
    icon: 'console',
    type: 'icon',
    color: 'primary',
    onClick: (item) => {},
  },
];

const NodeTableColumns = [
  {
    field: 'ip',
    dataType: 'string',
    name: 'Node IP',
  },
  {
    field: 'sync',
    name: 'Sync Status',
    dataType: 'boolean',
    sortable: true,
    render: (item) => {
      return (
        <EuiHealth color={item ? 'green' : 'red'}>{`Node${
          item ? '' : ' Not'
        } In Sync`}</EuiHealth>
      );
    },
  },
  {
    name: 'Actions',
    actions,
  },
];
export class NodeDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unSyncNodes: [],
      tableItems: [],
      activeConsoleTab: null,
      consoleTabs: {},
    };
    actions[0].onClick = this.pingAgent;
    actions[1].onClick = this.updateAgent;
    actions[2].onClick = this.activateAgent;
    actions[3].onClick = this.deactivateAgent;
    actions[4].onClick = this.checkStorage;
    actions[5].onClick = this.installDocker;
    actions[6].onClick = this.addConsoleTab;
  }

  addConsoleTab = (node) => {
    const { consoleTabs } = this.state;
    const nodeString = typeof node === 'string' ? node : node.ip;
    if (Array.isArray(consoleTabs[nodeString])) {
      return;
    } else {
      consoleTabs[nodeString] = [];
      this.setState({ consoleTabs, activeConsoleTab: nodeString });
    }
  };

  closeConsoleTab = (node) => {
    const { consoleTabs } = this.state;
    delete consoleTabs[node];
    this.setState({ consoleTabs });
  };

  clearConsoleTab = (node) => {
    const { consoleTabs } = this.state;
    consoleTabs[node] = [];
    this.setState({ consoleTabs });
  };

  selectTab = (node) => {
    this.setState({ activeConsoleTab: node });
  };

  addConsoleOutput = (node, type, command, args) => {
    const { clusterId } = this.props;
    const { consoleTabs } = this.state;
    const time = new Date().getTime();
    const random = Math.random();
    this.addConsoleTab(node);
    const key = `${node}_${time}_${type}_${command}_${JSON.stringify(
      args
    )}_${random}`;
    consoleTabs[node].push(
      <EuiFlexItem key={key} grow={false}>
        <Console
          key={key}
          type={type}
          cmd={command}
          cluster={clusterId}
          node={node}
          args={args}
        />
      </EuiFlexItem>
    );
    this.setState({ consoleTabs, activeConsoleTab: node });
  };

  checkStorage = (item) => {
    this.addConsoleOutput(item.ip, 'shell', 'df', ' -i');
    this.addConsoleOutput(item.ip, 'shell', 'df', ' -h');
  };

  pingAgent = (item) => {
    this.addConsoleOutput(
      item.ip,
      'tumbler',
      'aes/domain/dp/node/sdpdagent/ping-agent',
      {}
    );
  };

  updateAgent = (item) => {
    this.addConsoleOutput(
      item.ip,
      'tumbler',
      'aes/domain/dp/node/sdpdagent/update-agent',
      {}
    );
  };

  activateAgent = (item) => {
    this.addConsoleOutput(
      item.ip,
      'tumbler',
      'aes/domain/dp/node/sdpdagent/activate-agent',
      {}
    );
  };

  deactivateAgent = (item) => {
    this.addConsoleOutput(
      item.ip,
      'tumbler',
      'aes/domain/dp/node/sdpdagent/deactivate-agent',
      {}
    );
  };

  installDocker = (item) => {
    this.addConsoleOutput(
      item.ip,
      'tumbler',
      'aes/domain/dp/node/sdpdagent/install-docker',
      {}
    );
  };

  componentDidMount() {
    this.refreshClusterNodes();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.stackName !== this.props.stackName ||
      prevProps.clusterId !== this.props.clusterId
    ) {
      this.refreshClusterNodes();
    }
  }

  rootActionButtons = () => {
    return (
      <EuiFlexGroup>
        <EuiFlexItem>
          <div />
        </EuiFlexItem>
        <EuiFlexItem grow={false} key="extras">
          <EuiButtonIcon size="m" onClick={() => {}} iconType="boxesHorizontal">
            {'a'}
          </EuiButtonIcon>
        </EuiFlexItem>
        <EuiFlexItem grow={false} key={'refresh'}>
          <EuiButtonIcon
            size="m"
            onClick={this.refreshClusterNodes}
            iconType="refresh"
          >
            {'a'}
          </EuiButtonIcon>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  };

  refreshClusterNodes = () => {
    const { isClusterInSync } = this;
    this.setState({ unSyncNodes: [], tableItems: [] }, () => {
      isClusterInSync().then(() => {});
    });
  };

  isClusterInSync = async (nextToken) => {
    const { setClusterSyncStatus, stackName, clusterId } = this.props;
    if (!nextToken) {
      setClusterSyncStatus('loading');
    }
    const data = {
      cluster: {
        stackName: stackName,
        clusterId: clusterId,
      },
      maxNodes: 50,
      nextToken: nextToken,
    };
    const response = await apiCall('/cluster/syncStatus', data);
    if (response.statusCode === 200) {
      this.updateResponse(response);
      if (response.body.nextToken) {
        await this.isClusterInSync(response.body.nextToken);
      } else {
        if (this.state.unSyncNodes.length > 0) {
          setClusterSyncStatus(false);
        } else {
          setClusterSyncStatus(true);
        }
      }
    } else {
      setClusterSyncStatus('error');
    }
  };

  updateResponse(response) {
    const { nodesSyncState } = response.body;
    const { unSyncNodes, tableItems } = this.state;
    Object.keys(nodesSyncState).forEach((ip) => {
      tableItems.push({
        ip: ip,
        sync: nodesSyncState[ip],
      });
      if (!nodesSyncState[ip]) {
        unSyncNodes.push(ip);
      }
    });
    this.setState({ unSyncNodes, tableItems });
  }

  renderConsole = () => {
    const { activeConsoleTab, consoleTabs } = this.state;
    return (
      <ContentPanel title={'Console'}>
        <EuiTabs size="xs">
          {Object.keys(consoleTabs).map((node) => {
            return (
              <EuiTab
                isSelected={node === activeConsoleTab ? true : false}
                onClick={() => this.selectTab(node)}
                key={node}
              >
                {node}
                <EuiButtonIcon
                  iconType={'cross'}
                  onClick={() => {
                    this.closeConsoleTab(node);
                  }}
                />
              </EuiTab>
            );
          })}
        </EuiTabs>
        <br />
        <div key={'command-runner'}>
          <ElementErrorBoundary key={'command-runner'}>
            <CommandRunner
              node={activeConsoleTab}
              clearConsole={this.clearConsoleTab}
              runner={this.addConsoleOutput}
            />
          </ElementErrorBoundary>
        </div>
        <br />
        <div
          key={'Console Log'}
          style={{ overflow: 'auto', maxHeight: '150vh' }}
        >
          <div style={{ overflow: 'scroll' }}>
            {Object.keys(consoleTabs).map((node) => (
              <div
                key={node}
                style={{
                  display: activeConsoleTab !== node ? 'none' : 'block',
                }}
              >
                <EuiFlexGroup key={node} direction="columnReverse">
                  {consoleTabs[node]}
                </EuiFlexGroup>
              </div>
            ))}
          </div>
        </div>
      </ContentPanel>
    );
  };

  render() {
    const { clusterSyncStatus } = this.props;
    const { tableItems } = this.state;
    let content;
    if (clusterSyncStatus === 'loading') {
      content = <EuiLoadingSpinner size="xl" />;
    } else if (clusterSyncStatus === 'error') {
      content = <ErrorDisplay />;
    } else {
      content = (
        <ElementErrorBoundary key={'node-table'}>
          <EuiInMemoryTable
            sorting={{
              sort: {
                field: 'sync',
                direction: 'asc',
              },
            }}
            pagination={pagination}
            columns={NodeTableColumns}
            items={tableItems}
          />
        </ElementErrorBoundary>
      );
    }
    return (
      <React.Fragment>
        <ElementErrorBoundary key={'node-details'}>
          <ElementErrorBoundary key={'content-panel'}>
            <ContentPanel
              actions={this.rootActionButtons()}
              title={'Node Details'}
            >
              {content}
            </ContentPanel>
          </ElementErrorBoundary>
          <br />
          <ElementErrorBoundary key={'console'}>
            {this.renderConsole()}
          </ElementErrorBoundary>
        </ElementErrorBoundary>
      </React.Fragment>
    );
  }
}
