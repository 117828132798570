import React from 'react';
import {
  EuiInMemoryTable,
  EuiButtonIcon,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiHealth,
  EuiButton,
  EuiLink,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
} from '@elastic/eui';

import ContentPanel from '../../../../Components/ContentPanel/ContentPanel';
import { ComponentTableColumnDefinition } from './Component/ComponentTableColumnDefinition';
import { FlexItemContent } from '../../../components/FlexItemContent';
import { NodeDetails } from './NodeDetails';
import { ClusterAuditHistory } from '../ClusterAuditHistory';
import { apiCall, convertEpochToDate } from '../../../utils';
import { ClusterFeature } from '../ClusterFeature';
import { pagination } from './constants';
import { ErrorDisplay } from '../../../components/ErrorDisplay';
import { StackDetails } from '../../Stack/StackDetails';
import { ComponentVersionDetails } from '../../ComponentVersion/ComponentVersionList';
import { ComponentDetails } from '../../Component/V2/ComponentDetails';
import ElementErrorBoundary from '../../../components/ErrorBoundary/ElementErrorBoundary';
// eslint-disable-next-line react/prefer-stateless-function
export class ClusterDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isError: false,
      clusterSyncStatus: 'loading',
      showModal: false,
      modalContent: null,
      modalTitle: '',
    };
    ComponentTableColumnDefinition[1].render = this.renderComponentVersion;
    ComponentTableColumnDefinition[0].render = this.renderComponentName;
  }

  componentDidMount() {
    this.refreshClusterOverview();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { stackName, clusterId } = this.props;
    if (
      prevProps.stackName !== stackName ||
      prevProps.clusterId !== clusterId
    ) {
    }
  }

  closeModal = () => {
    this.setState({
      modalContent: null,
      showModal: false,
      modalTitle: '',
    });
  };

  showStackDetailsModal = () => {
    this.setState({
      modalContent: <StackDetails stackName={this.props.stackName} />,
      showModal: true,
      modalTitle: 'Stack Details',
    });
  };

  showComponentVersionListModal = (component) => {
    this.setState({
      modalContent: (
        <ComponentVersionDetails
          stackName={this.props.stackName}
          componentName={component.componentName}
          componentScope={component.componentScopeDefinition.componentScope}
          componentScopeValue={
            component.componentScopeDefinition.componentScopeValue
          }
        />
      ),
      showModal: true,
      modalTitle: 'Component Version List',
    });
  };

  showComponentDetailsModal = (component) => {
    this.setState({
      modalContent: (
        <ComponentDetails
          stackName={this.props.stackName}
          componentName={component.componentName}
          componentScope={component.componentScopeDefinition.componentScope}
          componentScopeValue={
            component.componentScopeDefinition.componentScopeValue
          }
        />
      ),
      showModal: true,
      modalTitle: 'Component Details',
    });
  };

  setClusterSyncStatus = (status) => {
    this.setState({ clusterSyncStatus: status });
  };

  refreshClusterOverview = async () => {
    this.setState({ clusterDetails: {}, isLoading: true });
    const data = {
      cluster: {
        stackName: this.props.stackName,
        clusterId: this.props.clusterId,
      },
    };
    const clusterDetailResponse = await apiCall('/cluster/describe', data);
    this.updateClusterOverview(clusterDetailResponse);
  };

  updateClusterOverview = (clusterDetails) => {
    if (clusterDetails.statusCode === 200) {
      const { clusterDescription } = clusterDetails.body;
      const { auditProperties } = clusterDescription;
      const { createdBy, creationTime, lastModificationTime } = auditProperties;
      try {
        const tempCreator = JSON.parse(createdBy);
        if (typeof tempCreator !== 'object') throw new Error('Not Object');
        auditProperties.createdBy = tempCreator;
      } catch (e) {
        auditProperties.createdBy = { id: createdBy };
      }
      auditProperties.creationTime = convertEpochToDate(creationTime);
      auditProperties.lastModificationTime =
        convertEpochToDate(lastModificationTime);
      this.setState({
        clusterDetails: clusterDescription,
        isLoading: false,
        isError: false,
      });
    } else {
      this.setState({ isLoading: false, isError: true });
    }
  };

  clusterOverviewActions = () => {
    return (
      <EuiFlexGroup>
        <EuiFlexItem>
          <div />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            size="m"
            onClick={this.refreshClusterOverview}
            iconType="refresh"
          >
            {'a'}
          </EuiButtonIcon>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  };

  renderStack = () => {
    const { state, showStackDetailsModal } = this;
    const {
      clusterDetails: {
        cluster: { stackName },
      },
    } = state;
    return <EuiLink onClick={showStackDetailsModal}>{stackName}</EuiLink>;
  };

  renderCluster = () => {
    const { state } = this;
    const {
      clusterDetails: {
        cluster: { clusterId },
      },
    } = state;
    return clusterId;
  };

  renderCreationTime = () => {
    const { state } = this;
    const {
      clusterDetails: {
        auditProperties: { creationTime },
      },
    } = state;
    return creationTime;
  };

  renderCreatedByAccount = () => {
    const { state } = this;
    const {
      clusterDetails: {
        auditProperties: {
          createdBy: { id },
        },
      },
    } = state;
    return id;
  };

  renderLastModifiedBy = () => {
    const { state } = this;
    const {
      clusterDetails: {
        auditProperties: { lastModifiedBy },
      },
    } = state;
    return lastModifiedBy;
  };

  renderLastModificationTime = () => {
    const { state } = this;
    const {
      clusterDetails: {
        auditProperties: { lastModificationTime },
      },
    } = state;
    return lastModificationTime;
  };

  renderCreatedByServicePrinciple = () => {
    const { state } = this;
    const {
      clusterDetails: {
        auditProperties: {
          createdBy: { sp },
        },
      },
    } = state;
    if (!sp || sp.length === 0) {
      return '--';
    }
    return (
      <React.Fragment>
        {sp.map((item) => (
          <div key={item}>{item}</div>
        ))}
        <br />
      </React.Fragment>
    );
  };

  renderComponentVersion = (major, component) => {
    return (
      <EuiLink onClick={() => this.showComponentVersionListModal(component)}>
        {`${major}.${component.minorVersion}`}
      </EuiLink>
    );
  };

  renderComponentName = (componentName, component) => {
    return (
      <EuiLink onClick={() => this.showComponentDetailsModal(component)}>
        {componentName}
      </EuiLink>
    );
  };

  renderClusterSyncStatus = () => {
    const { clusterSyncStatus } = this.state;
    if (clusterSyncStatus === true) {
      return <EuiHealth color={'green'}>{'Cluster In Sync'}</EuiHealth>;
    } else {
      return <EuiHealth color={'red'}>{'Cluster Not In Sync'}</EuiHealth>;
    }
  };

  renderClusterOverview() {
    const {
      state,
      clusterOverviewActions,
      renderStack,
      renderCluster,
      renderCreationTime,
      renderCreatedByAccount,
      renderCreatedByServicePrinciple,
      renderLastModifiedBy,
      renderLastModificationTime,
      renderClusterSyncStatus,
    } = this;
    const { isError, isLoading, clusterSyncStatus } = state;
    const { stackName, clusterId } = this.props;
    let content;
    if (isError) {
      content = <ErrorDisplay />;
    } else {
      content = (
        <React.Fragment>
          <ElementErrorBoundary key={'cluster-overview-inner-content'}>
            <EuiFlexGrid
              columns={4}
              gutterSize="l"
              style={{ border: 'none' }}
              direction="row"
            >
              <EuiFlexItem key="1">
                <FlexItemContent
                  title={'Stack Name'}
                  description={renderStack}
                  isLoading={isLoading}
                />
              </EuiFlexItem>
              <EuiFlexItem key="2">
                <FlexItemContent
                  title={'Cluster ID'}
                  description={renderCluster}
                  isLoading={isLoading}
                />
              </EuiFlexItem>
              <EuiFlexItem key="3">
                <FlexItemContent
                  title={'Creation Time'}
                  description={renderCreationTime}
                  isLoading={isLoading}
                />
              </EuiFlexItem>
              <EuiFlexItem key="4">
                <FlexItemContent
                  title={'Created By (AWS Account)'}
                  description={renderCreatedByAccount}
                  isLoading={isLoading}
                />
              </EuiFlexItem>
              <EuiFlexItem key="5">
                <FlexItemContent
                  title={'Created By (Service Principle)'}
                  description={renderCreatedByServicePrinciple}
                  isLoading={isLoading}
                />
              </EuiFlexItem>
              <EuiFlexItem key="6">
                <FlexItemContent
                  title={'Last Modification Time'}
                  description={renderLastModificationTime}
                  isLoading={isLoading}
                />
              </EuiFlexItem>
              <EuiFlexItem key="7">
                <FlexItemContent
                  title={'Last Modified By'}
                  description={renderLastModifiedBy}
                  isLoading={isLoading}
                />
              </EuiFlexItem>
              <EuiFlexItem key="8">
                <FlexItemContent
                  title={'Cluster Sync Status'}
                  description={renderClusterSyncStatus}
                  isLoading={clusterSyncStatus === 'loading'}
                  isError={clusterSyncStatus === 'error'}
                />
              </EuiFlexItem>
              <EuiFlexItem key="9">
                <FlexItemContent
                  title={'Feature Tags'}
                  description={
                    <ClusterFeature
                      stackName={stackName}
                      clusterId={clusterId}
                    />
                  }
                />
              </EuiFlexItem>
            </EuiFlexGrid>
            <br />
          </ElementErrorBoundary>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <ContentPanel
          actions={clusterOverviewActions()}
          title={'Cluster Overview'}
        >
          {content}
        </ContentPanel>
      </React.Fragment>
    );
  }

  renderNodeDetailsTable = () => {
    const { clusterSyncStatus } = this.state;
    const { stackName, clusterId } = this.props;
    return (
      <NodeDetails
        setClusterSyncStatus={this.setClusterSyncStatus}
        clusterSyncStatus={clusterSyncStatus}
        stackName={stackName}
        clusterId={clusterId}
      />
    );
  };

  renderComponentDetails = () => {
    const { clusterOverviewActions, state } = this;
    const { isLoading, isError } = state;
    let content;
    if (isLoading) {
      content = <EuiLoadingSpinner size="xl" />;
    } else if (isError) {
      content = <ErrorDisplay />;
    } else {
      const {
        clusterDetails: { componentVersionsList },
      } = this.state;
      content = (
        <EuiInMemoryTable
          pagination={pagination}
          columns={ComponentTableColumnDefinition}
          items={componentVersionsList}
          sorting={{
            sort: {
              field: 'componentName',
              direction: 'asc',
            },
          }}
        />
      );
    }
    return (
      <ContentPanel
        actions={clusterOverviewActions()}
        title={'Component Details'}
      >
        {content}
      </ContentPanel>
    );
  };

  renderModal = () => {
    const { state, closeModal } = this;
    const { showModal, modalContent, modalTitle } = state;
    if (showModal && modalContent) {
      return (
        <EuiOverlayMask>
          <EuiModal key={'modal'} onClose={closeModal}>
            <ElementErrorBoundary key={'modal-error-boundary'}>
              <EuiModalHeader>
                <EuiModalHeaderTitle>
                  <h2>{modalTitle}</h2>
                </EuiModalHeaderTitle>
              </EuiModalHeader>
              <EuiModalBody>{modalContent}</EuiModalBody>
              <EuiModalFooter>
                <EuiButton onClick={closeModal} fill>
                  Close
                </EuiButton>
              </EuiModalFooter>
            </ElementErrorBoundary>
          </EuiModal>
        </EuiOverlayMask>
      );
    }
    return null;
  };

  render() {
    const { stackName, clusterId } = this.props;

    return (
      <ElementErrorBoundary key={'tumbler-cluster-details'}>
        {this.renderModal()}
        <ElementErrorBoundary key={'clusterOverview'}>
          {this.renderClusterOverview()}
        </ElementErrorBoundary>
        <br />
        <ElementErrorBoundary key={'componentDetails'}>
          {this.renderComponentDetails()}
        </ElementErrorBoundary>
        <br />
        <ElementErrorBoundary key={'node-details'}>
          {this.renderNodeDetailsTable()}
        </ElementErrorBoundary>
        <br />
        <ElementErrorBoundary key={'audit-history'}>
          <ClusterAuditHistory stackName={stackName} clusterId={clusterId} />
        </ElementErrorBoundary>
      </ElementErrorBoundary>
    );
  }
}
