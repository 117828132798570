import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../../models/types';

export interface RetryCollectionFailureVars {
    awsAccountId: String;
    collectionId: String;
}

const RETRY_COLLECTION_FAILURE = gql`
    query RetryCollectionFailure(
            $awsAccountId: String
            $collectionId: String
        ){
        retryCollectionFailure(
            awsAccountId: $awsAccountId
            collectionId: $collectionId
        )
    }
`;

export const useRetryCollectionFailure = () => {
    return useLazyQuery<AnyObject, RetryCollectionFailureVars>(RETRY_COLLECTION_FAILURE, {
        errorPolicy: 'all',
    });
};
