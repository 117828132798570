import React from 'react';
import {
  EuiFlexGrid,
  EuiFlexItem,
  EuiFieldText,
  EuiSelect,
  EuiButton,
  EuiGlobalToastList,
} from '@elastic/eui';

// eslint-disable-next-line react/prefer-stateless-function
export class CommandRunner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { commandType: 'shell', command: '', args: '', toasts: [] };
  }

  runCommand = () => {
    const { node, runner } = this.props;
    const { commandType, args, command } = this.state;
    let finalArgs = args;
    if (!command) {
      this.addToast({
        iconType: 'alert',
        color: 'danger',
        title: 'An error occured!!!',
        text: 'Please enter a command name.',
      });
      return;
    }
    if (commandType === 'tumbler' && args) {
      try {
        finalArgs = JSON.parse(args);
      } catch (e) {
        this.addToast({
          iconType: 'alert',
          color: 'danger',
          title: 'An error occured!!!',
          text: 'Ensure you pass a correct JSON in arguments.',
        });
        return;
      }
    } else if (commandType === 'tumbler') {
      finalArgs = {};
    }
    runner(node, commandType, command, finalArgs);
  };

  removeToast = (removedToast) => {
    const { toasts } = this.state;
    this.setState({
      toasts: toasts.filter((toast) => toast.id !== removedToast.id),
    });
  };

  addToast = (toast) => {
    const { toasts } = this.state;
    toasts.push({
      id: `${new Date().getTime()}_${Math.ceil(Math.random() * 100000)}`,
      ...toast,
    });
    this.setState({ toasts });
  };

  render() {
    const { node, clearConsole } = this.props;
    const { commandType, command, args, toasts } = this.state;
    if (!node) {
      return null;
    } else {
      return (
        <EuiFlexGrid>
          <EuiFlexItem key={'node'}>
            <EuiFieldText value={node} prepend={'Node'} />
          </EuiFlexItem>
          <EuiFlexItem key={'command-type'}>
            <EuiSelect
              options={[
                {
                  value: 'tumbler',
                  text: 'tumbler',
                },
                {
                  value: 'shell',
                  text: 'shell',
                },
              ]}
              value={commandType}
              onChange={(e) => {
                this.setState({
                  commandType: e.target.value,
                });
              }}
            />
          </EuiFlexItem>
          <EuiFlexItem key={'command'}>
            <EuiFieldText
              value={command}
              onChange={(e) => {
                this.setState({ command: e.target.value });
              }}
              prepend={'Command'}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={true} key={'args'}>
            <EuiFieldText
              fullWidth={true}
              value={args}
              onChange={(e) => {
                this.setState({ args: e.target.value });
              }}
              prepend={'Arguments'}
            />
          </EuiFlexItem>
          <EuiFlexItem key={'run'}>
            <EuiButton fill onClick={this.runCommand}>
              {'Run'}
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem key={'clear-console'}>
            <EuiButton
              fill
              color={'danger'}
              iconType="brush"
              onClick={() => {
                clearConsole(node);
              }}
            >
              {'Clear Console'}
            </EuiButton>
          </EuiFlexItem>
          <EuiGlobalToastList
            toasts={toasts}
            dismissToast={this.removeToast}
            toastLifeTimeMs={6000}
          />
        </EuiFlexGrid>
      );
    }
  }
}
