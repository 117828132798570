import { SpaceBetween, TextContent } from '@amzn/awsui-components-react';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { JunoWorkerOverviewParams } from '../../../models/types';
import { WorkerDetails } from './WorkerDetails';
import { WorkerShardsDetails } from './WorkerShardsDetails';
import { get } from 'lodash';
import {BREADCRUMBS} from "../../../utils/constants";
import {JUNO_BASE_PATH} from "../../commons/constants";
import {AppContext} from "../../../utils/appContext";
import {useGetWorkerDetailsQuery} from "../../hooks/Worker/useGetWorkerDetailsQuery";

const WorkerOverview = (props) => {
  const { awsAccountId, workerId, dataPlaneType } = useParams<JunoWorkerOverviewParams>();
  const [workerInformation, setWorkerInformation] = useState();
  const [
    getWorkerDetails,
    { loading: workerDetailsLoading, data: workerDetails },
  ] = useGetWorkerDetailsQuery();
  const [shardDetails, setShardDetails] = useState([]);
  const location = useLocation();
  const appContext = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    if(workerDetails){
      const details = workerDetails.getWorkerDetails[0]
      setWorkerInformation(details);
      const shards = get(details, 'shardMeasurements', []);
      setShardDetails(shards);
    }
  }, [workerDetailsLoading, workerDetails]);
  useEffect(() => {
    getWorkerDetails({
      variables: {
        awsAccountId: awsAccountId,
        workerId: workerId,
        dataPlaneType: dataPlaneType.toLowerCase(),
      },
    });
  }, [awsAccountId, workerId, dataPlaneType]);

  useEffect(() => {
    appContext.setBreadcrumbs([
      {
        ...BREADCRUMBS.JUNO,
        href: `${JUNO_BASE_PATH}`,
        onClick: () => {
          history.push(`${JUNO_BASE_PATH}`);
        },
      },
      {
        text: awsAccountId,
        href: `${JUNO_BASE_PATH}/${awsAccountId}`,
        onClick: () => {
          history.push(`${JUNO_BASE_PATH}/${awsAccountId}`);
        },
      },
      { text: workerId, dataPlaneType, href: `/worker/${awsAccountId}/${dataPlaneType}/${workerId}` },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);


  return (
    <SpaceBetween direction="vertical" size="l">
      {/* <BreadcrumbGroup items={appContext.breadcrumbs} /> */}
      <TextContent>
        <h1>Worker Overview</h1>
      </TextContent>
      <WorkerDetails workerDetails={workerInformation} />
      <WorkerShardsDetails shardDetails={shardDetails} />
    </SpaceBetween>
  );
};

export { WorkerOverview };
