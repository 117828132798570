import {
  Button,
  Container,
  Header,
  Input,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

const Homepage = (props) => {
  const [awsAccountId, setAwsAccountId] = useState('');
  const history = useHistory();

  const getAccountDetails = async () => {
    history.push(`/ops-transparency/onboarding/${awsAccountId}`);
  };
  return (
    <SpaceBetween direction="vertical" size="l">
      <Container 
        header={
          <div>
            <Header variant="h2">Onboarding</Header>
            <p>
              Starting point for customer onboarding.
              Operator must enter the account id for the respective onboarding request 
              and follow the onboarding <a href="https://w.amazon.com/bin/view/AWS/SearchServices/InfrastructureEngineering/OperatorTransparency/Procedures/SOPforOperatorTransparencySystem" target="_blank" rel="noopener noreferrer">SOP
              </a>
            </p>
          </div>
        }
      >
        <SpaceBetween direction="horizontal" size="l">
          <h3>AWS account id: </h3>
          <Input
            onChange={({ detail }) => setAwsAccountId(detail.value)}
            value={awsAccountId}
          />
          <Button variant="primary" onClick={getAccountDetails}>
            Check
          </Button>
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
};

export { Homepage };
