import { AppLayout, BreadcrumbGroup } from '@amzn/awsui-components-react';
import * as React from 'react';
import { AppContext, getInitialAppContext } from '../utils/appContext';
import { Navbar } from '../utils/Navbar';
import { Sidebar } from '../utils/sidebar';
import * as c from './../tumblerConstants';
import { FetchAuditHistory } from './Components/FetchAuditHistory';
import { useGetCommandsQuery } from './hooks/getCommandsQuery';

const AuditHistory = (props) => {
  const appContext = getInitialAppContext();
  const [navigationOpen, setNavigationOpen] = React.useState(false);
  const [commandList, setCommandList] = React.useState([]);
  const setTimezone = (timezone: string) => {
    setState({ ...state, timezone: timezone });
  };
  const setBreadcrumbs = (breadcrumbs: any) => {
    setState({ ...state, breadcrumbs });
  };

  const [state, setState] = React.useState({
    ...getInitialAppContext(),
    setTimezone: setTimezone,
    setBreadcrumbs: setBreadcrumbs,
  });

  const [getCommands, { loading: commandsLoading, data: commands }] =
    useGetCommandsQuery();

  React.useEffect(() => {
    if (commandsLoading === false) {
      getCommands();
    }
    if (commands) {
      setCommandList(commands as []);
    }
  }, [commands, getCommands, commandsLoading]);

  return (
    <div>
      <Navbar
        title={'Audit History'}
        homeUrl="/audit-history"
        regionSwitcherActive={true}
        dropdownOptions={c.tumblerNavbarOptions}
      />
      <div
        style={{
          paddingTop: '60px',
        }}
      >
        <AppLayout
          disableContentPaddings={false}
          content={
            <AppContext.Provider value={state}>
              <FetchAuditHistory commands={commandList} />
            </AppContext.Provider>
          }
          toolsHide={true}
          navigation={<Sidebar />}
          navigationOpen={navigationOpen}
          onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
          headerSelector="#navbar"
          breadcrumbs={
            <BreadcrumbGroup
              items={appContext.breadcrumbs}
              ariaLabel="Breadcrumbs"
            />
          }
        />
      </div>
    </div>
  );
};

export { AuditHistory };
