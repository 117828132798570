import { Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { EuiHealth, EuiLoadingSpinner } from '@elastic/eui';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { AnyObject, CommonDomainProps, ESEC2 } from '../../../../models/types';
import { getHealthyInstance } from '../../../../utils/helpers';
import { DomainResourcesData } from '../../hooks/useResourceInfoQuery';
import { useSkewAnalysisInfoQuery } from '../../hooks/useSkewAnalysisInfoQuery';
import { setIndexSkewData } from './IndexSkewUtil';
import { setNodeSkewData } from './NodeSkewUtil';
import { IndexShardCountSkewInfo } from './SkewComponents/IndexShardCountSkewInfo';
import { IndexShardStorageSkewInfo } from './SkewComponents/IndexShardStorageSkewInfo';
import { NodeShardCountSkewInfo } from './SkewComponents/NodeShardCountSkewInfo';
import { NodeShardStorageSkewInfo } from './SkewComponents/NodeShardStorageSkewInfo';
import { isError } from "../../../../ErrorHandler/apiErrorHandler";
import { ErrorMessageComponent } from "../../../../ErrorHandler/errorMessageComponent";
import { ApiList }from "../../../../ErrorHandler/utils";

interface SkewAnalysisProps extends CommonDomainProps {
  domainData: DomainResourcesData | undefined;
  resourceLoading: boolean;
}

export const getDiNodeMapping = (nodeAttrsResp: Array<AnyObject>) => {
  // Mapping the di number with the nodes present in that DI.
    const diNodeMapping: { [key: string]: Set<string> } = {};
  // Sample object in nodeAttrsResp
  //   {
  //     "node": "L7SwM4u",
  //     "ip": "172.16.30.225",
  //     "host": "172.16.30.225",
  //     "attr": "box_type",
  //     "value": "warm"
  //   }
    for (let obj of nodeAttrsResp) {
      let [attr, ip, value] = [obj.attr, obj.ip, obj.value];
      if (attr === 'di_number') {
        if (obj.value in diNodeMapping) {
          diNodeMapping[value].add(ip);
        } else {
          diNodeMapping[value] = new Set([ip]);
        }
      }
    }
    return diNodeMapping;
};

export const getNodeBoxTypeMapping = (nodeAttrsResp: Array<AnyObject>) => {
    // Mapping the node with its boxtype.
    const nodeBoxTypeMapping: { [key: string]: string } = {};
    // Sample object in nodeAttrsResp
    //   {
    //     "node": "L7SwM4u",
    //     "ip": "172.16.30.225",
    //     "host": "172.16.30.225",
    //     "attr": "box_type",
    //     "value": "warm"
    //   }
    for (let obj of nodeAttrsResp) {
      let [attr, ip, value] = [obj.attr, obj.ip, obj.value];
      if (attr === 'box_type' && !(ip in nodeBoxTypeMapping)) {
        nodeBoxTypeMapping[ip] = value;
      }
    }
    return nodeBoxTypeMapping;
};

const SkewAnalysis = (props: SkewAnalysisProps) => {
  const [indexShardCountSkew, setIndexShardCountSkew] = useState<
    Array<Array<object>>
  >([]);
  const [indexShardStorageSkew, setIndexShardStorageSkew] = useState<
    Array<Array<object>>
  >([]);
  const [nodeShardCountSkew, setNodeShardCountSkew] = useState<
    Array<Array<object>>
  >([]);
  const [nodeShardStorageSkew, setNodeShardStorageSkew] = useState<
    Array<Array<object>>
  >([]);
  const [, setSkewInfoMessage] = useState<Array<string>>([]);
  const [DIInfo, setDIInfo] = useState<Array<string>>([]);
  const [dropdownsInfo, setDropdownsInfo] = useState<
    Array<Array<Array<boolean>>>
  >([]);
  const [, setDIDropdownsInfo] = useState<Array<boolean>>([]);
  const [unhandledErrors, setUnhandledErrors] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null)

  const [
    getSkewAnalysisInfo,
    { loading: loadingSkewAnalysis, data: skewAnalysisData, error: error },
  ] = useSkewAnalysisInfoQuery();

  const ec2Instances = get<ESEC2[]>(
    props.domainData as any,
    'domain.resources.ALL.ec2Instances',
    []
  );

  const isErrorPresent = isError(error)
  useEffect(() => {
    if (isErrorPresent) {
      setErrorMessage("Unable to get Skew Analysis Data. Failed with " + error.message);
    }
  }, [isErrorPresent, error]);

  useEffect(() => {
    const healthyInstance = getHealthyInstance(ec2Instances as any);
    if (!props.resourceLoading && healthyInstance) {
      getSkewAnalysisInfo({
        variables: {
          domainIdentifier: props.domainIdentifier,
          instanceId: healthyInstance.instanceId,
        },
      });
    }
  }, [
    props.resourceLoading,
    props.domainData,
    props.domainIdentifier,
    ec2Instances,
    getSkewAnalysisInfo,
  ]);

  useEffect(() => {
    try {
      const nodeAttrsResp =
        get<Array<AnyObject>>(
          skewAnalysisData as any,
          'domain.es60.cat.nodeattrs',
          []
        ) || [];
      const shardsResp =
        get<Array<AnyObject>>(
          skewAnalysisData as any,
          'domain.es60.cat.shards',
          []
        ) || [];
      const allocationResp =
        get<Array<AnyObject>>(
          skewAnalysisData as any,
          'domain.es60.cat.allocation',
          []
        ) || [];

      // Parsing the Node Attribute File to map DI numbers with respective nodes that belong to that DI.
      const diNodeMapping = getDiNodeMapping(nodeAttrsResp as any);

      // Storing data for all DIs here in array
      const finalIndexShardCount: Array<Array<AnyObject>> = [];
      const finalIndexShardStorage: Array<Array<AnyObject>> = [];
      const finalNodeShardCount: Array<Array<AnyObject>> = [];
      const finalNodeShardStorage: Array<Array<AnyObject>> = [];
      const finalOverallSkewInfo: Array<string> = [];
      const totalDIs: Array<string> = Object.keys(diNodeMapping);
      const finalDropDowns: Array<Array<Array<boolean>>> = [];
      const finalDIDropDowns: Array<boolean> = [];


      // Iterating over each DI
      for (let di in diNodeMapping) {
        // Storing current DI info here
        finalDIDropDowns.push(false);
        const indexShardCount: Array<AnyObject> = [];
        const indexShardStorage: Array<AnyObject> = [];
        const nodeShardCount: Array<AnyObject> = [];
        const nodeShardStorage: Array<AnyObject> = [];
        let overallSkewInfo: string;
        const indexTypesShardCount: { [key: string]: AnyObject } = {};
        const indexTypesShardStorage: { [key: string]: AnyObject } = {};
        const dropdowns: Array<Array<boolean>> = [];

        // Sample object in the allocation resp
        // {
        //   "node": "egWT5Xh",
        //   "host": "172.16.27.79",
        //   "disk.percent": "50",
        //   "ip": "172.16.27.79",
        //   "disk.total": "1.4tb",
        //   "nodisk.availde": "752.4gb",
        //   "shards": "59",
        //   "disk.indices": "754.7gb",
        //   "disk.used": "759.4gb"
        // }
        let totalShards = 0;
        let totalNodes = 0;
        for (let obj of allocationResp) {
          let len = Object.keys(obj).length;
          let [ip, shards] = [obj.ip, obj.shards];
          if (len > 2 && diNodeMapping[di].has(ip)) {
            totalNodes += 1;
            totalShards += parseInt(shards);
          }
        }
        // Checking if skew is present or not?
        if (totalShards < totalNodes) {
          overallSkewInfo =
            'No Skew is present as the total number of shards are lesser than the total number of nodes';
        } else if (totalNodes === 1) {
          overallSkewInfo = 'No skew is present as only one node is present';
        } else {
          // Skew is present. Checking for all the skews.
          const nodeBoxTypeMapping = getNodeBoxTypeMapping(nodeAttrsResp);

          // Setting the Index-Shard-Count Skew info and Index-Shard-Storge Skew info for current DI
          setIndexSkewData(
            shardsResp,
            nodeBoxTypeMapping,
            diNodeMapping,
            di,
            indexTypesShardCount,
            indexTypesShardStorage,
            indexShardCount,
            indexShardStorage,
            dropdowns
          );
          // Index skews are done
          // Starting with node skews
          // Setting the Node-Shard-Count Skew info and Node-Shard-Storge Skew info for current DI
          setNodeSkewData(
            allocationResp,
            diNodeMapping,
            di,
            nodeBoxTypeMapping,
            nodeShardCount,
            nodeShardStorage,
            dropdowns
          );
          if (
            indexShardCount.length +
              indexShardStorage.length +
              nodeShardCount.length +
              nodeShardStorage.length >
            0
          ) {
            overallSkewInfo = '';
          } else {
            overallSkewInfo = 'No Skew Detected';
          }
        }
        // Setting all the skew infos. Adding them to the final arrays which holds skews for all DIs.
        finalIndexShardCount.push(indexShardCount);
        finalIndexShardStorage.push(indexShardStorage);
        finalNodeShardCount.push(nodeShardCount);
        finalNodeShardStorage.push(nodeShardStorage);
        finalOverallSkewInfo.push(overallSkewInfo);
        finalDropDowns.push(dropdowns);
      }
      // Setting the states that holds the final Skew infos respectively and are used while rendering the UI components.
      setIndexShardCountSkew(finalIndexShardCount);
      setIndexShardStorageSkew(finalIndexShardStorage);
      setNodeShardCountSkew(finalNodeShardCount);
      setNodeShardStorageSkew(finalNodeShardStorage);
      setSkewInfoMessage(finalOverallSkewInfo);
      setDIInfo(totalDIs);
      setDropdownsInfo(finalDropDowns);
      setDIDropdownsInfo(finalDIDropDowns);
    } catch (error) {
        setUnhandledErrors(error)
    }
  }, [skewAnalysisData]);

  // const handleOnChange = (
  //   e: React.ChangeEvent<HTMLInputElement>,
  //   diNumber: number
  // ) => {
  //   let newDiState = [...diDropdownsInfo];
  //   newDiState[diNumber] = e.target.checked;
  //   setDIDropdownsInfo(newDiState);

  //   //Changing accordions' state of current DI
  //   changeAccordionStates(`diIndexCount${diNumber}`, e, diNumber, 0);
  //   changeAccordionStates(`diIndexStorage${diNumber}`, e, diNumber, 1);
  //   changeAccordionStates(`diNodeCount${diNumber}`, e, diNumber, 2);
  //   changeAccordionStates(`diNodeStorage${diNumber}`, e, diNumber, 3);
  // };

  // const changeAccordionStates = (
  //   className: string,
  //   e: React.ChangeEvent<HTMLInputElement>,
  //   diNumber: number,
  //   skewType: number
  // ) => {
  //   let accordions = document.getElementsByClassName(className);
  //   for (let i = 0; i < accordions.length; i++) {
  //     let button: HTMLElement = accordions
  //       .item(i)!
  //       .getElementsByTagName('button')[0] as HTMLElement;
  //     if (e.target.checked) {
  //       if (!dropdownsInfo[diNumber][skewType][i]) {
  //         button.click();
  //       }
  //     } else {
  //       if (dropdownsInfo[diNumber][skewType][i]) {
  //         button.click();
  //       }
  //     }
  //   }
  // };

    const getDiHealth = (di: number) => {
      let skewsPresent = 0;
      let currentDiIndexCount: Array<AnyObject> = indexShardCountSkew[di];
      let currentDiIndexStorage: Array<AnyObject> = indexShardStorageSkew[di];
      let currentDiNodeCount: Array<AnyObject> = nodeShardCountSkew[di];
      let currentDiNodeStorage: Array<AnyObject> = nodeShardStorageSkew[di];
      let currentDiSkews = [
        currentDiIndexCount,
        currentDiIndexStorage,
        currentDiNodeCount,
        currentDiNodeStorage,
      ];
      try {
        for (let skewType of currentDiSkews) {
          for (let subSkewType of skewType) {
            skewsPresent += subSkewType.skews;
          }
        }
      } catch (error) {
         setUnhandledErrors(error)
      }
      if (skewsPresent > 0) {
        //yello
        return '#F5A700';
      } else {
        //grey
        return '#D9D9D9';
      }
    };

  return (
    <React.Fragment>
      {errorMessage ? (
      <ErrorMessageComponent errorMessage={errorMessage} apiName={ApiList.SKEW_ANALYSIS}/>
    ) : (
      <>
      {loadingSkewAnalysis ? (
        <div style={{ position: 'fixed', top: '50%', left: '50%' }}>
          <EuiLoadingSpinner size={'xl'} />
        </div>
      ) : (
      <>
        { unhandledErrors ? (
          <div style={{ textAlign: 'center' }}>
            Not able to fetch Skew Analysis. Something went wrong.
          </div>
        ) : (
        <>
          {DIInfo.map((di, i) => (
            <React.Fragment key={i}>
              {/* <EuiPanel paddingSize="l">
                <EuiHealth color={getDiHealth(i)}>
                  <EuiTitle size="s">
                    <h3
                      style={{
                        display: 'inline',
                        paddingRight: '10px',
                      }}
                    >
                      DI-{di}
                    </h3>
                  </EuiTitle>
                </EuiHealth> */}

              <Container
                // disableContentPaddings
                header={
                  <Header variant="h2">
                    <EuiHealth color={getDiHealth(i)}></EuiHealth>DI-{di}
                  </Header>
                }
              >
                {indexShardCountSkew[i].length +
                  indexShardStorageSkew[i].length +
                  nodeShardCountSkew[i].length +
                  nodeShardStorageSkew[i].length >
                0 ? (
                  <SpaceBetween direction="vertical" size="l">
                    <IndexShardCountSkewInfo
                      indexShardCountSkew={indexShardCountSkew}
                      dropdownsInfo={dropdownsInfo}
                      diNumber={i}
                      setDropdownsInfo={setDropdownsInfo}
                      isLoading={loadingSkewAnalysis || props.resourceLoading}
                    />
                    <IndexShardStorageSkewInfo
                      indexShardStorageSkew={indexShardStorageSkew}
                      dropdownsInfo={dropdownsInfo}
                      diNumber={i}
                      setDropdownsInfo={setDropdownsInfo}
                      isLoading={loadingSkewAnalysis || props.resourceLoading}
                    />
                    <NodeShardCountSkewInfo
                      nodeShardCountSkew={nodeShardCountSkew}
                      dropdownsInfo={dropdownsInfo}
                      diNumber={i}
                      setDropdownsInfo={setDropdownsInfo}
                      isLoading={loadingSkewAnalysis || props.resourceLoading}
                    />
                    <NodeShardStorageSkewInfo
                      nodeShardStorageSkew={nodeShardStorageSkew}
                      dropdownsInfo={dropdownsInfo}
                      diNumber={i}
                      setDropdownsInfo={setDropdownsInfo}
                      isLoading={loadingSkewAnalysis || props.resourceLoading}
                    />
                  </SpaceBetween>
                ) : null}
              </Container>
              {/* </EuiPanel>
              <EuiSpacer /> */}
            </React.Fragment>
          ))}
        </>
      )}
      </>
    )}
      </>
    )}
    </React.Fragment>
  );
};

export { SkewAnalysis };
