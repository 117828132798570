import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../models/types';
 
const GET_COMMANDS = gql`
    query getCommands{
        commands
    }
`;
 
export const useGetCommandsQuery = () => {
    return useLazyQuery<AnyObject>(GET_COMMANDS, {
        errorPolicy: 'all',
    });
};