export const tumblerNavbarTitle = "Ops Tumbler"

export const tumblerNavbarOptions = [
    {
        label: 'Report a bug',
        value: 'https://tiny.amazon.com/r2102p8o/issuamazissucrea',
    },
    {
        label: 'New Feature Request',
        value: 'https://tiny.amazon.com/1563wef8c',
    },
    {
        label: 'Release History',
        value:
        'https://w.amazon.com/bin/view/AWS/SearchServices/OpsTumbler/Release',
    },
    {
        label: 'Developer Guide',
        value:
        'https://w.amazon.com/bin/view/AWS/SearchServices/OpsTumbler/DeveloperGuide',
    },
    {
        label: 'User Guide',
        value:
        'https://w.amazon.com/bin/view/AWS/SearchServices/OpsTumbler/UserGuide',
    },
]

export const tumblerContactTeam = "https://oncall.corp.amazon.com/#/view/searchservices-infra-eng/schedule"