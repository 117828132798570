import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../models/types';
 
const GET_JUNO_LOG_GROUPS = gql`
    query GetJunoLogGroups{
        getJunoLogGroups
    }
`;
 
export const useGetJunoLogGroupsQuery = () => {
    return useLazyQuery<AnyObject>(GET_JUNO_LOG_GROUPS, {
        errorPolicy: 'all',
    });
};