import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import { get } from 'lodash';
import { ESEC2 } from '../../../models/types';
import {
  ES_LOGS,
  KBN_LOGS,
  SDPD_AGENT_LOGS,
  SERVICE_PROXY_LOGS,
} from './constants';
import { regionInfo } from './../../../utils/regionInfo';

export const getActions = (onClick: Function, type: string) => {
  if (type === 'logs') {
    return [
      {
        name: 'Explore ES log',
        text: 'Explore ES log',
        id: 'esLogs',
        icon: 'filebeatApp',
        enabled: () => true,
        description: 'Get ES log from node',
        onClick: (currentRow: ESEC2) => {
          onClick({
            logFile: ES_LOGS.options[0],
            currentAction: 'viewLog',
            instancesId: [currentRow.instanceId],
          });
        },
      },
      {
        name: 'Explore HAProxy log',
        text: 'Explore HAProxy log',
        id: 'haProxyLogs',
        icon: 'filebeatApp',
        enabled: () => true,
        description: 'Get HAProxy log from node',
        onClick: (currentRow: ESEC2) => {
          onClick({
            logFile: SERVICE_PROXY_LOGS.options[0],
            currentAction: 'viewLog',
            instancesId: [currentRow.instanceId],
          });
        },
      },
      {
        name: 'Explore SDPDS log',
        text: 'Explore SDPDS log',
        id: 'sdpdsLogs',
        icon: 'filebeatApp',
        enabled: () => true,
        description: 'Get SDPDS logs from node',
        onClick: (currentRow: ESEC2) => {
          onClick({
            logFile: SDPD_AGENT_LOGS.options[0],
            currentAction: 'viewLog',
            instancesId: [currentRow.instanceId],
          });
        },
      },
      {
        id: 'viewLog',
        name: 'Explore Kibana log',
        text: 'Explore Kibana log',
        description: 'Get Kibana log',
        icon: 'filebeatApp',
        enabled: () => true,
        onClick: (currentRow: ESEC2) => {
          onClick({
            logFile: KBN_LOGS.options[0],
            currentAction: 'viewLog',
            instancesId: [currentRow.instanceId],
          });
        },
      },
    ];
  } else {
    return [
      {
        id: 'bounceES',
        color: 'danger',
        enabled: () => false,
        name: 'Bounce ES',
        text: 'Bounce ES',
        description: 'Bounce Elasticsearch on node',
        icon: 'logoElasticsearch',
        onClick: (currentRow: ESEC2) => {
          onClick({
            showConfirm: true,
            currentAction: 'bounceES',
            instancesId: [currentRow.instanceId],
          });
        },
      },
      {
        id: 'bounceKibana',
        name: 'Bounce Kibana',
        text: 'Bounce Kibana',
        color: 'danger',
        enabled: () => true,
        description: 'Bounce kibana on node',
        icon: 'logoKibana',
        onClick: (currentRow: ESEC2) => {
          onClick({
            showConfirm: true,
            currentAction: 'bounceKibana',
            instancesId: [currentRow.instanceId],
          });
        },
      },
      {
        id: 'terminateEC2',
        name: 'Terminate node',
        text: 'Terminate node',
        color: 'danger',
        icon: 'stopFilled',
        enabled: () => true,
        description: 'Terminate this node',
        onClick: (currentRow: ESEC2) => {
          onClick({
            showConfirm: true,
            currentAction: 'terminateEC2',
            instancesId: [currentRow.instanceId],
          });
        },
      },
      {
        id: 'rebootEC2',
        name: 'Reboot node',
        text: 'Reboot node',
        color: 'danger',
        icon: 'refresh',
        enabled: () => true,
        description: 'Reboot this node',
        onClick: (currentRow: ESEC2) => {
          onClick({
            showConfirm: true,
            currentAction: 'rebootEC2',
            instancesId: [currentRow.instanceId],
          });
        },
      },
    ];
  }
};

export const confirmDialogMapping = {
  bounceKibana: {
    title: 'Are yu sure you want to bounce Kibana?',
    cancelText: 'No',
    confirmText: 'Yes, Bounce Kibana',
    executeCommand: async (
      executeCommand: any,
      currentRow: any,
      domainIdentifier: string,
      toastNotifications: any
    ) => {
      let currentNotification: Toast = {
        id: '',
      };
      try {
        currentNotification = toastNotifications.addWarning({
          title: 'Deactivating Kibana env',
          color: 'warning',
          toastLifeTimeMs: 3000000,
          iconType: 'loading',
        });
        await executeCommand({
          variables: {
            url: 'aes/domain/dp/node/kibana/deactivate',
            args: {
              domain_identifier: domainIdentifier,
              instance_id: currentRow.instancesId[0],
              truncate_output: false,
            },
            approval: {
              mode: 'bypass',
            },
            confirm: {
              is_confirmed: true,
            },
          },
        });
        toastNotifications.remove(currentNotification);
        currentNotification = toastNotifications.addWarning({
          title: 'Activating Kibana',
          color: 'warning',
          toastLifeTimeMs: 3000000,
          iconType: 'loading',
        });
        await executeCommand({
          variables: {
            url: 'aes/domain/dp/node/kibana/activate',
            args: {
              domain_identifier: domainIdentifier,
              instance_id: currentRow.instancesId[0],
              truncate_output: false,
            },
            approval: {
              mode: 'bypass',
            },
            confirm: {
              is_confirmed: true,
            },
          },
        });
        toastNotifications.remove(currentNotification);
      } catch (e) {
        toastNotifications.remove(currentNotification);
        toastNotifications.addDanger(
          'Something went wrong unable to bounce Kibana'
        );
      }
    },
  },
  bounceES: {
    title: 'Do you want to bounce Elasticsearch ?',
    cancelText: 'No',
    confirmText: 'Yes, Bounce Elasticsearch',
    executeCommand: async (
      executeCommand: any,
      currentRow: any,
      domainIdentifier: string,
      toastNotifications: any
    ) => {
      let currentNotification: Toast = {
        id: '',
      };
      try {
        currentNotification = toastNotifications.addWarning({
          title: `Deactivating ES env on instance ${currentRow.instancesId[0]}`,
          color: 'warning',
          toastLifeTimeMs: 3000000,
          iconType: 'loading',
        });
        await executeCommand({
          variables: {
            url: 'aes/domain/dp/node/es/deactivate',
            args: {
              domain_identifier: domainIdentifier,
              instance_id: currentRow.instancesId[0],
              truncate_output: false,
            },
            approval: {
              mode: 'bypass',
            },
            confirm: {
              is_confirmed: true,
            },
          },
        });
        toastNotifications.remove(currentNotification);
        currentNotification = toastNotifications.addWarning({
          title: `Activating ES env on instance ${currentRow.instancesId[0]}`,
          color: 'warning',
          toastLifeTimeMs: 3000000,
          iconType: 'loading',
        });
        await executeCommand({
          variables: {
            url: 'aes/domain/dp/node/es/activate',
            args: {
              domain_identifier: domainIdentifier,
              instance_id: currentRow.instancesId[0],
              truncate_output: false,
            },
            approval: {
              mode: 'bypass',
            },
            confirm: {
              is_confirmed: true,
            },
          },
        });
        toastNotifications.remove(currentNotification);
      } catch (e) {
        toastNotifications.remove(currentNotification);
        toastNotifications.addDanger(
          'Something went wrong unable to bounce ES Env'
        );
      }
    },
  },
  terminateEC2: {
    title: 'Are you sure you want to terminate instance ?',
    cancelText: 'No',
    confirmText: 'Yes, Terminate',
    executeCommand: async (
      executeCommand: any,
      currentRow: any,
      domainIdentifier: string,
      toastNotifications: any
    ) => {
      let currentNotification: Toast = {
        id: '',
      };
      try {
        currentNotification = toastNotifications.addWarning({
          title: `Terminating EC2 instance ${currentRow.instancesId[0]}`,
          color: 'warning',
          toastLifeTimeMs: 3000000,
          iconType: 'loading',
        });
        const resp = await executeCommand({
          variables: {
            url: 'aes/domain/dp/node/terminate-instance',
            args: {
              domain_identifier: domainIdentifier,
              instance_id: currentRow.instancesId[0],
            },
            approval: {
              mode: 'bypass',
            },
            confirm: {
              is_confirmed: true,
            },
          },
        });
        toastNotifications.remove(currentNotification);
        if (get(resp, 'data.executeCommand.success', false)) {
          toastNotifications.addSuccess(
            `Terminated instances ${currentRow.instancesId[0]} successfully.`
          );
        } else {
          toastNotifications.addDanger({
            title: 'Unable to terminate instance',
            text: get(
              resp,
              'data.executeCommand.error.extensions.response.body.exc_value',
              ''
            ),
          });
        }
      } catch (e) {
        toastNotifications.remove(currentNotification);
        toastNotifications.addDanger(
          'Something went wrong while terminating instance'
        );
      }
    },
  },
  rebootEC2: {
    title: `Are you sure you want to reboot the instances ?`,
    cancelText: 'No',
    confirmText: 'Yes, Reboot',
    executeCommand: async (
      executeCommand: any,
      currentRow: any,
      domainIdentifier: string,
      toastNotifications: any
    ) => {
      let currentNotification: Toast = {
        id: '',
      };
      try {
        currentNotification = toastNotifications.addWarning({
          title: 'Terminating EC2 node',
          color: 'warning',
          toastLifeTimeMs: 3000000,
          iconType: 'loading',
        });
        const resp = await executeCommand({
          variables: {
            url: 'aes/domain/dp/node/reboot-instance',
            args: {
              domain_identifier: domainIdentifier,
              instance_id: currentRow.instancesId[0],
            },
          },
        });
        toastNotifications.remove(currentNotification);
        if (get(resp, 'data.executeCommand.success', false)) {
          toastNotifications.addSuccess('EC2 node rebooted successfully.');
        } else {
          toastNotifications.addDanger(
            `Unable to reboot EC2. ${get(
              resp,
              'data.executeCommand.error.extensions.response.body.exc_value',
              ''
            )}`
          );
        }
      } catch (e) {
        toastNotifications.remove(currentNotification);
        toastNotifications.addDanger(
          'Something went wrong unable to bounce ES Env'
        );
      }
    },
  },
} as { [key: string]: any };

export const getAdmiralLink = (currentRegion :string, instanceId:string):string => {
  const regionFound = regionInfo.find(
      (region: any) => region.airportCode.toLowerCase() === currentRegion.toLowerCase()
  )
  if (regionFound && regionFound.isBorderRegion) {
    return `https://admiral.${currentRegion}.aws-border.com/instance/${instanceId}`
  }
  return `https://admiral-${currentRegion}.ec2.amazon.com/instance/${instanceId}`
}

export const getElbLink = (currentRegion :string, loadBalancerName:string):string => {
  const regionFound = regionInfo.find(
      (region: any) => region.airportCode.toLowerCase() === currentRegion.toLowerCase()
  )
  if (regionFound && regionFound.isBorderRegion) {
    return `https://elb-atlas.${currentRegion}.aws-border.com/accesspoints/search?state=ACTIVE&search_term=${loadBalancerName.replaceAll(
                '/',
                '%2F'
              )}`
  }
  return `https://elb-atlas-${currentRegion}.amazon.com/accesspoints/search?state=ACTIVE&search_term=${loadBalancerName.replaceAll(
                '/',
                '%2F'
              )}`
}