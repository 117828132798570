import { gql, useQuery } from '@apollo/client';
import { Domain } from '../../../models/types';

interface DomainListData {
  account: {
    domains: Domain[];
  };
}

interface DomainListVars {
  clientId: string;
}

const DOMAIN_LIST = gql`
  query getDomainList($clientId: String!) {
    account(clientId: $clientId) {
      domains {
        domainIdentifier
        name
        created
        deleted
        processing
        carbonDomain
      }
    }
  }
`;

export const useDomainListQuery = (vars: DomainListVars) => {
  return useQuery<DomainListData, DomainListVars>(DOMAIN_LIST, {
    variables: { ...vars },
  });
};
