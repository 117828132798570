import React from 'react';
import { EuiText } from '@elastic/eui';

export const renderCreatorIdentityServicePrinciple = (creatorString) => {
  const result = [
    <div key={'AwsAccountHeader'}>
      <b>{'AWS Account'}</b>
    </div>,
  ];
  console.log(`creatorString: ${creatorString}`);
  try {
    const creator = JSON.parse(creatorString);
    if (typeof creator !== 'object') throw new Error('Not an object');
    result.push(<div key={'AwsAccount'}>{creator.id}</div>);
    if (Array.isArray(creator.sp) && creator.sp.length !== 0) {
      result.push(
        <div key={'AwsServiceHeader'}>
          <b>{'Service Principle'}</b>
        </div>
      );
      creator.sp.forEach((sp, index) => {
        result.push(<div key={`${index}`}>{sp}</div>);
      });
    }
  } catch (e) {
    result.push(<div key={'AwsAccount'}>{creatorString}</div>);
  }
  return () => <EuiText size={'xs'}>{result}</EuiText>;
};
