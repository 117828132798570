import React, { Fragment } from 'react';
import { apiCall, convertEpochToDate } from '../../utils';

import {
  EuiTextColor,
  EuiFlexItem,
  EuiPanel,
  EuiCopy,
  EuiButton,
  EuiText,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiFlexGroup,
  EuiBasicTable,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiSelect,
  EuiToast,
  EuiSpacer,
  EuiIcon,
  EuiInMemoryTable,
} from '@elastic/eui';

export class ComponentVersionTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      UploadURL: '',
      uploadurlerror: '',
      uploadurlstate: '',
      componentNameUpload: '',
      componentScopeUpload: '',
      componentScopeValueUpload: '',
      componentName: '',
      componentScope: '',
      componentScopeValue: '',
      majorVersion: '',
      minorVersion: '',
      Items: [],
      isModalVisible: false,
      componentversionlistdisplay: false,
      UploadPackageKey: '',
      packageMD5Hash: '',
      eligibleForNewDomains: '',
      featureTags: '',
      componentversionresult: '',
      componentversionlisterror: '',
      loading: false,
      no_of_selections: 'select an item',
      downloadurlerror: '',
      uploadglobalcverror: '',
    };
    this.componentversionlist = [];
    this.majorVersion = -1;
    this.minorVersion = -1;
    this.componentName = '';
    this.componentScope = '';
    this.componentScopeValue = '';
    this.submitted_major_version = -1;
    this.submitted_minor_version = -1;
  }

  onChangeHandler = (event) => {
    if (event.target.name === 'componentScopeUpload') {
      this.setState({ componentScopeValueUpload: '' });
    }
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
  };

  submitHandler = () => {
    this.componentName = this.state.componentNameUpload;
    this.componentScope = this.state.componentScopeUpload;
    this.componentScopeValue = this.state.componentScopeValueUpload;
    this.setState({ loading: true });
    this.getComponentVersionList();
    this.setState({ uploadglobalcverror: '' });
    this.setState({ downloadurlerror: '' });
    this.componentversionlist = [];
    this.setState({ uploadurlstate: '' });
    this.setState({ componentversionresult: '' });
  };

  UploadUrlHandler = () => {
    this.setState({ uploadurlstate: '' });
    this.setState({ componentversionresult: '' });
    this.getUploadLink();
  };

  AddHandler = () => {
    const item = this.state.Items;
    item.push({
      id:
        this.state.componentName +
        '$' +
        this.state.minorVersion +
        '$' +
        this.state.majorVersion,
      ComponentName: this.state.componentName,
      ComponentScope: this.state.componentScope,
      ComponentScopeValue: this.state.componentScopeValue,
      ComponentMinorVersion: this.state.minorVersion,
      ComponentMajorVersion: this.state.majorVersion,
    });
    this.setState({ Items: item });
    this.setState({ isModalVisible: false });
    this.setState({ componentName: '' });
    this.setState({ componentScope: '' });
    this.setState({ componentScopeValue: '' });
    this.setState({ minorVersion: '' });
    this.setState({ majorVersion: '' });
  };

  getComponentVersionTable(response) {
    const items = response.componentVersions;
    const length = items.length;
    const itemlist = [];
    for (let i = 0; i < length; i++) {
      const creationDate = convertEpochToDate(
        items[i].auditProperties.creationTime
      );
      const lastModifiedDate = convertEpochToDate(
        items[i].auditProperties.lastModificationTime
      );

      itemlist.push({
        id:
          'id' +
          '$' +
          this.componentName +
          '$' +
          items[i].componentVersion.majorVersion +
          '$' +
          items[i].componentVersion.minorVersion,
        name: items[i].componentVersion.componentName,
        scope:
          items[i].componentVersion.componentScopeDefinition.componentScope,
        scopeValue:
          items[i].componentVersion.componentScopeDefinition
            .componentScopeValue,
        majorVersion: items[i].componentVersion.majorVersion,
        minorVersion: items[i].componentVersion.minorVersion,
        createdBy: items[i].auditProperties.createdBy,
        creationTime: creationDate,
        lastModificationTime: lastModifiedDate,
        lastModifiedBy: items[i].auditProperties.lastModifiedBy,
        featuretags: items[i].componentVersionProperties.featureTags,
      });
    }
    this.componentversionlist = itemlist;
    this.setState({ componentversionlistdisplay: true });
  }

  createComponentVersion = async () => {
    this.majorVersion = this.state.componentMajorVersionUpload;
    this.minorVersion = this.state.componentMinorVersionUpload;
    const data = {
      stackName: this.props.stackName,
      componentVersion: {
        componentName: this.state.componentNameUpload,
        componentScopeDefinition: {
          componentScope: this.state.componentScopeUpload,
        },
        majorVersion: this.state.componentMajorVersionUpload,
        minorVersion: this.state.componentMinorVersionUpload,
      },
    };
    if (
      this.state.componentScopeValueUpload !== '' &&
      this.state.componentScopeUpload === 'ACCOUNT'
    ) {
      data.componentVersion.componentScopeDefinition.componentScopeValue =
        this.state.componentScopeValueUpload;
    }
    if (this.state.UploadPackageKey !== '') {
      data.packageKey = this.state.UploadPackageKey;
    }
    if (this.state.packageMD5Hash !== '') {
      data.packageMD5Hash = this.state.packageMD5Hash;
    }
    if (
      this.state.eligibleForNewDomains !== '' ||
      this.state.featureTags !== '' ||
      this.state.Items.length > 0
    ) {
      data.componentVersionProperties = {};
      if (this.state.eligibleForNewDomains !== '') {
        data.componentVersionProperties.eligibleForNewDomains =
          this.state.eligibleForNewDomains;
      }
      if (this.state.featureTags !== '') {
        data.componentVersionProperties.featureTags =
          this.state.featureTags.split(',');
      }
      if (this.state.Items.length > 0) {
        data.componentVersionProperties.prerequisites = [];
        const length = this.state.Items.length;
        const items = this.state.Items;
        for (let i = 0; i < length; i++) {
          const prerequisite = {
            componentName: items[i].ComponentName,
            componentScopeDefinition: {
              componentScope: items[i].ComponentScope,
            },
            majorVersion: items[i].ComponentMajorVersion,
            minorVersion: items[i].ComponentMinorVersion,
          };
          if (items[i].ComponentScopeValue !== '') {
            prerequisite.componentScopeDefinition.componentScopeValue =
              items[i].ComponentScopeValue;
          }
          data.componentVersionProperties.prerequisites.push(prerequisite);
        }
      }
    }
    const response = await apiCall('/component/version/create', data);
    if (response.statusCode === 200) {
      this.setState({ componentversionresult: 'success' });
    } else {
      this.setState({ componentversionresult: JSON.stringify(response.body) });
    }
  };

  getComponentVersionList = async () => {
    const data = {
      stackName: this.props.stackName,
      componentName: this.state.componentNameUpload,
      componentScopeDefinition: {
        componentScope: this.state.componentScopeUpload,
      },
      maxResults: 100,
    };

    if (
      this.state.componentScopeValueUpload !== '' &&
      this.state.componentScopeUpload === 'ACCOUNT'
    ) {
      data.componentScopeDefinition.componentScopeValue =
        this.state.componentScopeValueUpload;
    }

    const response = await apiCall('/component/version/list', data);
    if (response.statusCode === 200) {
      this.getComponentVersionTable(response.body);
      this.setState({ componentversionlisterror: '' });
      this.setState({ loading: false });
    } else {
      this.setState({
        componentversionlisterror: JSON.stringify(response.body),
      });
      this.setState({ loading: false });
    }
  };

  getDownloadLink = async (
    componentName,
    componentScope,
    componentScopeValue,
    MjVersion,
    MnVersion
  ) => {
    const data = {
      stackName: this.props.stackName,
      componentVersion: {
        componentName: componentName,
        componentScopeDefinition: { componentScope: componentScope },
        majorVersion: MjVersion,
        minorVersion: MnVersion,
      },
    };
    if (componentScopeValue !== '' && componentScope === 'ACCOUNT') {
      data.componentVersion.componentScopeDefinition.componentScopeValue =
        componentScopeValue;
    }

    const response = await apiCall('/files/download', data);
    if (response.statusCode === 200) {
      window.open(response.body.url, '_blank');
    } else {
      this.setState({
        downloadurlerror:
          JSON.stringify(response.body) +
          '---' +
          componentName +
          '-' +
          MjVersion +
          '-' +
          MnVersion,
      });
    }
  };

  getUploadLink = async () => {
    const data = {
      stackName: this.props.stackName,
      componentVersion: {
        componentName: this.state.componentNameUpload,
        componentScopeDefinition: {
          componentScope: this.state.componentScopeUpload,
        },
        majorVersion: this.state.componentMajorVersionUpload,
        minorVersion: this.state.componentMinorVersionUpload,
      },
    };

    if (
      this.state.componentScopeValueUpload !== '' &&
      this.state.componentScopeUpload === 'ACCOUNT'
    ) {
      data.componentVersion.componentScopeDefinition.componentScopeValue =
        this.state.componentScopeValueUpload;
    }
    const response = await apiCall('/files/upload/location', data);
    if (response.statusCode === 200) {
      this.setState({ UploadURL: response.body.url });
      this.setState({ UploadPackageKey: response.body.packageKey });
      this.setState({ uploadurlstate: 'success' });
    } else {
      this.setState({ uploadurlstate: JSON.stringify(response.body) });
    }
  };

  updateUploadResponse(response) {
    if (response.statusCode === 200) {
      this.setState({ uploadglobalcverror: 'success' });
    } else {
      this.setState({ uploadglobalcverror: JSON.stringify(response.body) });
    }
  }

  uploadlatestversion = async () => {
    const data = {
      stackName: this.props.stackName,
      componentVersion: {
        componentName: this.componentName,
        componentScopeDefinition: {
          componentScope: 'GLOBAL',
        },
        majorVersion: this.submitted_major_version,
        minorVersion: this.submitted_minor_version,
      },
    };
    const response = await apiCall('/files/upload/global', data);
    this.updateUploadResponse(response);
  };

  onDeleteClick = (item) => {
    const itemarr = this.state.Items;
    const length = itemarr.length;
    let index = -1;
    for (let i = 0; i < length; i++) {
      if (
        itemarr[i].ComponentName +
          '$' +
          itemarr[i].ComponentMinorVersion +
          '$' +
          itemarr[i].ComponentMajorVersion ===
        item.id
      ) {
        index = i;
        break;
      }
    }
    itemarr.splice(index, 1);
    this.setState({ Items: itemarr });
  };

  AddPreHandler = () => {
    this.setState({ isModalVisible: true });
  };

  closeModal = () => {
    this.setState({ isModalVisible: false });
  };

  closeDownloadModal = () => {
    this.setState({ downloadurlerror: '' });
  };

  closeUploadModal = () => {
    this.setState({ uploadurlstate: '' });
  };

  closecreatecv = () => {
    this.setState({ componentversionresult: '' });
  };

  onClose = () => {
    this.setState({ uploadglobalcverror: '' });
  };

  getDownloadUrl = (item) => {
    this.setState({ downloadurlerror: '' });
    const versions = item.id.split('$');
    this.getDownloadLink(
      item.name,
      item.scope,
      item.scopeValue,
      versions[2],
      versions[3]
    );
  };

  uploadLatestHandler = (item) => {
    this.submitted_major_version = item.majorVersion;
    this.submitted_minor_version = item.minorVersion;
    this.setState({ uploadglobalcverror: '' });
    this.uploadlatestversion();
  };

  getComponentVersionResult() {
    if (this.state.componentversionresult === 'success') {
      return (
        <div>
          <EuiToast
            title={this.state.componentversionresult}
            color="success"
            iconType="check"
            onClose={() => this.closecreatecv()}
            id="Correct"
          >
            <EuiText>
              successfully created component version {this.componentName}-
              {this.majorVersion}-{this.minorVersion}
            </EuiText>
          </EuiToast>
          <EuiSpacer size="xl" />
        </div>
      );
    } else if (
      this.state.componentversionresult !== 'success' &&
      this.state.componentversionresult !== ''
    ) {
      return (
        <div>
          <EuiToast
            title={this.state.componentversionresult}
            color="danger"
            iconType="alert"
            onClose={() => this.closecreatecv()}
            id="Wrong"
          />
          <EuiSpacer size="xl" />
        </div>
      );
    }
  }

  getComponentScopeValue() {
    if (this.state.componentScopeUpload === 'ACCOUNT') {
      return (
        <EuiFormRow
          id="componentScopeValueUpload"
          onChange={this.onChangeHandler}
          label="component Scope Value"
          helpText="Enter component scope value"
        >
          <EuiFieldText
            name="componentScopeValueUpload"
            value={this.state.componentScopeValueUpload}
          />
        </EuiFormRow>
      );
    }
  }

  render() {
    const uploadrequest =
      "curl '" + this.state.UploadURL + "' --upload-file <filename>";
    const componentversionresult = this.getComponentVersionResult();

    const actions = [
      {
        name: 'Delete',
        description: 'Delete this prerequisite',
        icon: 'trash',
        type: 'icon',
        color: 'danger',
        onClick: this.onDeleteClick,
      },
    ];

    const pagination = {
      initialPageSize: 30,
      pageSizeOptions: [10, 20, 30, 40],
    };

    const columns = [
      {
        field: 'ComponentName',
        name: 'Name',
      },
      {
        field: 'ComponentScope',
        name: 'Scope',
      },
      {
        field: 'ComponentScopeValue',
        name: 'Scope value',
      },
      {
        field: 'ComponentMajorVersion',
        name: 'Major Version',
      },
      {
        field: 'ComponentMinorVersion',
        name: 'Minor Version',
      },
      {
        name: 'Actions',
        actions,
      },
    ];

    const componentversioncolumns = [
      {
        field: 'name',
        name: 'Name',
        width: '80px',
        align: 'center',
      },
      {
        field: 'scope',
        name: 'Scope',
        width: '80px',
        align: 'center',
      },
      {
        field: 'scopeValue',
        name: 'Scope Value',
        width: '80px',
        align: 'center',
      },
      {
        field: 'majorVersion',
        name: 'Major Version',
        width: '80px',
        align: 'center',
      },
      {
        field: 'minorVersion',
        name: 'Minor Version',
        width: '50px',
        align: 'center',
      },
      {
        field: 'createdBy',
        name: 'Created By',
        width: '80px',
        align: 'center',
      },
      {
        field: 'creationTime',
        name: 'Creation Time',
        width: '80px',
        align: 'center',
      },
      {
        field: 'lastModificationTime',
        name: 'Last Modification Time',
        width: '80px',
        align: 'center',
      },
      {
        field: 'lastModifiedBy',
        name: 'Last ModifiedBy',
        width: '80px',
        align: 'center',
      },
      {
        field: 'featuretags',
        name: 'Feature Tags',
        width: '80px',
        align: 'center',
      },
      {
        name: 'Actions',
        width: '150px',
        align: 'center',
        actions: [
          {
            name: 'Download',
            description: 'Get Download url',
            icon: 'download',
            iconType: 'download',
            type: 'icon',
            color: 'success',
            onClick: this.getDownloadUrl,
          },
          ...(this.componentScope === 'GLOBAL'
            ? [
                {
                  render: (item) => {
                    return (
                      <EuiButton
                        size="s"
                        onClick={() => this.uploadLatestHandler(item)}
                        textProps={{
                          id:
                            item.majorVersion +
                            '$' +
                            item.minorVersion +
                            '$' +
                            'button',
                        }}
                        id={
                          item.majorVersion +
                          '$' +
                          item.minorVersion +
                          '$' +
                          'button'
                        }
                      >
                        {' '}
                        MARK-GLOBAL
                      </EuiButton>
                    );
                  },
                },
              ]
            : []),
        ],
      },
    ];

    const scopeOptions = [
      { value: '', text: 'Select Scope' },
      { value: 'GLOBAL', text: 'GLOBAL' },
      { value: 'ACCOUNT', text: 'ACCOUNT' },
      { value: 'COLLECTION', text: 'COLLECTION' },
    ];

    const eligibilityOptions = [
      { value: '', text: 'Select Option' },
      { value: 'READY', text: 'READY' },
      { value: 'ELIGIBLE', text: 'ELIGIBLE' },
      { value: 'PENDING', text: 'PENDING' },
      { value: 'INELIGIBLE', text: 'INELIGIBLE' },
      { value: 'FAILED', text: 'FAILED' },
    ];

    const csv = this.getComponentScopeValue();

    return (
      <div>
        <EuiForm>
          <EuiFlexGroup>
            <EuiFlexItem grow={1}>
              <EuiFormRow
                id="componentNameUpload"
                onChange={this.onChangeHandler}
                label="Component Name"
                helpText="Enter the component name."
              >
                <EuiFieldText
                  name="componentNameUpload"
                  value={this.state.componentNameUpload}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={1}>
              <EuiFormRow
                label="component Scope"
                onChange={this.onChangeHandler}
                id="componentScopeUpload"
                helpText="Enter the component scope."
              >
                <EuiSelect
                  placeholder="componentScope"
                  options={scopeOptions}
                  value={this.state.componentScopeUpload}
                  name="componentScopeUpload"
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={1}>{csv}</EuiFlexItem>
            <EuiFlexItem grow={1}>
              <EuiSpacer size="l" />
              <EuiFlexGroup>
                <EuiFlexItem grow={2} />
                <EuiFlexItem grow={2}>
                  <EuiButton
                    onClick={this.submitHandler}
                    textProps={{ id: 'getExistingVersions' }}
                    id="getExistingVersions"
                  >
                    {' '}
                    Existing Versions{' '}
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiForm>

        <EuiSpacer size="xl" />
        <EuiFlexGroup>
          <EuiFlexItem grow={1}>
            <EuiPanel>
              {this.state.uploadglobalcverror === 'success' && (
                <Fragment>
                  <EuiToast
                    title="success"
                    color="success"
                    iconType="check"
                    onClose={() => this.onClose()}
                  >
                    <EuiText>
                      successfully uploaded global component version{' '}
                      {this.componentName}-{this.submitted_major_version}-
                      {this.submitted_minor_version}
                    </EuiText>
                  </EuiToast>
                  <EuiSpacer size="xl" />
                </Fragment>
              )}
              {this.state.uploadglobalcverror !== 'success' &&
                this.state.uploadglobalcverror !== '' && (
                  <Fragment>
                    <EuiToast
                      title="upload failed !!"
                      color="danger"
                      iconType="alert"
                      onClose={() => this.onClose()}
                    >
                      <EuiText color="danger">
                        {this.state.uploadglobalcverror} on uploading the
                        component {this.componentName}-
                        {this.submitted_major_version}-
                        {this.submitted_minor_version}{' '}
                      </EuiText>
                    </EuiToast>
                    <EuiSpacer size="xl" />
                  </Fragment>
                )}
              {this.state.downloadurlerror !== '' && (
                <Fragment>
                  <EuiSpacer size="xs" />
                  <EuiToast
                    title="Download failed!!"
                    color="danger"
                    iconType="alert"
                    onClose={() => this.closeDownloadModal()}
                  >
                    <EuiTextColor color="danger">
                      {this.state.downloadurlerror}
                    </EuiTextColor>
                  </EuiToast>
                  <EuiSpacer size="xl" />
                </Fragment>
              )}
              <EuiInMemoryTable
                items={this.componentversionlist}
                columns={componentversioncolumns}
                loading={this.state.loading}
                error={this.state.componentversionlisterror}
                pagination={pagination}
                itemId={(item) => item.id}
              />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
        <br />
        <br />

        <EuiSpacer size="xl" />
        <EuiFlexGroup>
          <EuiFlexItem grow={1}>
            <EuiForm>
              <EuiFlexGroup>
                <EuiFlexItem style={{ maxWidth: 186 }}>
                  <EuiFormRow
                    id="componentMajorVersionUpload"
                    onChange={this.onChangeHandler}
                    label="Major Version"
                    helpText="Enter the major version."
                  >
                    <EuiFieldText
                      name="componentMajorVersionUpload"
                      value={this.state.componentMajorVersionUpload}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem style={{ maxWidth: 186 }}>
                  <EuiFormRow
                    label="Minor Version"
                    onChange={this.onChangeHandler}
                    id="componentMinorVersionUpload"
                    helpText="Enter the minor version."
                  >
                    <EuiFieldText
                      name="componentMinorVersionUpload"
                      value={this.state.componentMinorVersionUpload}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiSpacer size="l" />
              <EuiFormRow
                id="packageMD5Hash"
                onChange={this.onChangeHandler}
                label="Package MD5 Hash"
                helpText="Enter package MD5 Hash"
              >
                <EuiFieldText
                  name="packageMD5Hash"
                  value={this.state.packageMD5Hash}
                />
              </EuiFormRow>
            </EuiForm>
          </EuiFlexItem>
          <EuiFlexItem grow={1}>
            <EuiForm>
              <EuiFormRow
                id="eligibleForNewDomains"
                onChange={this.onChangeHandler}
                label="Eligible For New Domains"
                helpText="Enter Eligible For New Domains"
              >
                <EuiSelect
                  placeholder="eligibleForNewDomains"
                  options={eligibilityOptions}
                  value={this.state.eligibleForNewDomains}
                  name="eligibleForNewDomains"
                />
              </EuiFormRow>
              <EuiSpacer size="l" />
              <EuiFormRow
                id="featureTags"
                onChange={this.onChangeHandler}
                label="Feature Tags"
                helpText="Enter comma separated Feature Tags"
              >
                <EuiFieldText
                  name="featureTags"
                  value={this.state.featureTags}
                />
              </EuiFormRow>
            </EuiForm>
          </EuiFlexItem>
          <EuiFlexItem grow={2}>
            <div>
              <EuiPanel>
                <EuiFlexGroup>
                  <EuiFlexItem grow={4}>
                    <h1 align="center">PRE-REQUISITE TABLE</h1>
                  </EuiFlexItem>
                  <EuiFlexItem grow={1}>
                    <EuiButton
                      onClick={this.AddPreHandler}
                      textProps={{ id: 'addbtn' }}
                      id="addbtn"
                    >
                      Add Pre-requisite
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer size="l" />
                <EuiBasicTable
                  items={this.state.Items}
                  itemId={(item) => item.id}
                  columns={columns}
                  hasActions={true}
                />
                <br />
                <br />
              </EuiPanel>
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size="xl" />
        <EuiPanel>
          {componentversionresult}
          <EuiButton onClick={this.UploadUrlHandler} id="UploadUrlSubmit">
            {' '}
            Upload url{' '}
          </EuiButton>
          <br />
          <br />
          {this.state.isModalVisible && (
            <EuiOverlayMask>
              <EuiModal onClose={this.closeModal} id="componentversion">
                <EuiModalHeader>
                  <EuiModalHeaderTitle>Component Details</EuiModalHeaderTitle>
                </EuiModalHeader>
                <EuiModalBody>
                  <EuiFormRow
                    id="componentName"
                    onChange={this.onChangeHandler}
                    label="componentName"
                    helpText="Enter componentName"
                  >
                    <EuiFieldText
                      name="componentName"
                      value={this.state.componentName}
                    />
                  </EuiFormRow>
                  <EuiFormRow
                    id="componentScope"
                    onChange={this.onChangeHandler}
                    label="componentScope"
                    helpText="Enter componentScope"
                  >
                    <EuiSelect
                      placeholder="componentScope"
                      options={scopeOptions}
                      value={this.state.componentScope}
                      name="componentScope"
                    />
                  </EuiFormRow>
                  {this.state.componentScope === 'ACCOUNT' && (
                    <EuiFormRow
                      id="componentScopeValue"
                      onChange={this.onChangeHandler}
                      label="componentScopeValue"
                      helpText="Enter componentScopeValue"
                    >
                      <EuiFieldText
                        name="componentScopeValue"
                        value={this.state.componentScopeValue}
                      />
                    </EuiFormRow>
                  )}
                  <EuiFormRow
                    id="majorVersion"
                    onChange={this.onChangeHandler}
                    label="majorVersion"
                    helpText="Enter majorVersion"
                  >
                    <EuiFieldText
                      name="majorVersion"
                      value={this.state.majorVersion}
                    />
                  </EuiFormRow>
                  <EuiFormRow
                    id="minorVersion"
                    onChange={this.onChangeHandler}
                    label="minorVersion"
                    helpText="Enter minorVersion"
                  >
                    <EuiFieldText
                      name="minorVersion"
                      value={this.state.minorVersion}
                    />
                  </EuiFormRow>
                  <EuiButton
                    onClick={this.AddHandler}
                    textProps={{ id: 'addbtn' }}
                    id="addbtn"
                  >
                    Add
                  </EuiButton>
                  <br />
                  <br />
                </EuiModalBody>
              </EuiModal>
            </EuiOverlayMask>
          )}
          <br />
          <br />

          {this.state.uploadurlstate === 'success' && (
            <div>
              <EuiIcon type="alert" />{' '}
              <EuiTextColor color="primary">
                Atomic step, do both together
              </EuiTextColor>
              <EuiSpacer size="xs" />
              <EuiText>UPLOAD COMPONENT VERSION </EuiText>
              <EuiSpacer size="s" />
              <EuiCopy textToCopy={uploadrequest}>
                {(copy) => (
                  <EuiButton onClick={copy} id="cpybtn">
                    Click to copy request
                  </EuiButton>
                )}
              </EuiCopy>
              <br />
              <br />
              <EuiText>
                After executing the curl request,click the below button
              </EuiText>
              <br />
              <EuiButton
                onClick={this.createComponentVersion}
                textProps={{ id: 'newcomponent' }}
                id="newcomponent"
              >
                CREATE A NEW COMPONENT VERSION
              </EuiButton>
            </div>
          )}
          {this.state.uploadurlstate !== 'success' &&
            this.state.uploadurlstate !== '' && (
              <EuiToast
                title="Fetching upload url failed!!"
                color="danger"
                iconType="alert"
                onClose={() => this.closeUploadModal()}
              >
                <EuiTextColor color="danger">
                  {this.state.uploadurlstate}
                </EuiTextColor>
              </EuiToast>
            )}
        </EuiPanel>
      </div>
    );
  }
}
