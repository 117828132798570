import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../../models/types';

export interface SkewAnalysisInfoVars {
  domainIdentifier: string;
  instanceId: string;
}

export interface SkewAnalysisInfoData {
  domain: {
    es60: {
      cat: {
        nodeattrs?: AnyObject;
        shards?: AnyObject;
        allocation?: AnyObject;
      };
    };
  };
}

export const SKEW_ANALYSIS_INFO = gql`
  query getSkewAnalysisInfo($domainIdentifier: String!, $instanceId: String!) {
    domain(domainIdentifier: $domainIdentifier, instanceId: $instanceId) {
      es60 {
        cat {
          nodeattrs
          shards
          allocation
        }
      }
    }
  }
`;

export const useSkewAnalysisInfoQuery = () => {
  return useLazyQuery<SkewAnalysisInfoData, SkewAnalysisInfoVars>(
    SKEW_ANALYSIS_INFO,
    {
      errorPolicy: 'all',
    }
  );
};
