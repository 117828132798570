import { Button, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter } from '@elastic/eui';
import * as React from 'react';

interface FlyoutOptins {
  title: string;
  size: 's' | 'm' | 'l';
  content: React.ReactNode;
  footer?: React.ReactNode;
}

export const FlyoutContext = React.createContext<
  (options: FlyoutOptins) => void
>(Promise.reject);

interface FlyoutProps {
  children: React.ReactNode;
}
export const Flyout = (props: FlyoutProps) => {
  const [flyoutState, setFlyoutState] = React.useState<FlyoutOptins | null>(
    null
  );

  const openConfirmation = (options: FlyoutOptins) => {
    setFlyoutState(options);
  };

  return (
    <>
      <FlyoutContext.Provider
        value={openConfirmation}
        children={props.children}
      />
      {Boolean(flyoutState) && flyoutState ? (
        <div>
          <EuiFlyout
            onClose={() => setFlyoutState(null)}
            size={flyoutState.size}
          >
            <div style={{ height: '70px', width: '100%', clear: 'both' }}></div>
            <div style={{ padding: '10px 30px 0 30px' }}>
              <Header
                variant="h2"
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      iconName="close"
                      variant="icon"
                      onClick={() => setFlyoutState(null)}
                    >
                      Close
                    </Button>
                  </SpaceBetween>
                }
              >
                {flyoutState.title}
              </Header>
            </div>
            <EuiFlyoutBody>{flyoutState.content}</EuiFlyoutBody>
            <EuiFlyoutFooter>{flyoutState.footer}</EuiFlyoutFooter>
          </EuiFlyout>
        </div>
      ) : null}{' '}
    </>
  );
};
