import { gql, useQuery } from '@apollo/client';
import { AnyObject } from '../../models/types';

export interface PutLogsVars {
    logsMessage: string,domainIdent: string, apiName: string
}

const GQL_PUT_LOGS = gql`
    query PutLogs($logsMessage: String, $domainIdent: String, $apiName: String) {
        putLogs(logsMessage: $logsMessage,domainIdent: $domainIdent, apiName: $apiName)
    }
`;

export const usePutCwLogs = (vars: PutLogsVars) => {
    return useQuery<AnyObject, PutLogsVars>(GQL_PUT_LOGS, {
        variables: { ...vars },
    });
};