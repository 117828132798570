import React from 'react';
import {
  EuiLoadingSpinner,
  EuiPanel,
  EuiTextColor,
  EuiBasicTable,
} from '@elastic/eui';
import { apiCall, convertEpochToDate } from '../../utils';
import { renderCreatorIdentityServicePrinciple } from '../../utils/JsxUtils';

const columns = [
  {
    field: 'property',
    name: 'property',
  },
  {
    field: 'value',
    name: 'value',
    render: (item) => {
      if (typeof item === 'function') return item();
      return item;
    },
  },
];

export class StackDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRequestActive: true,
      exception: null,
      items: [],
    };
  }

  componentDidMount() {
    this.getStackDetails();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.stackName !== this.props.stackName) {
      this.setState({ items: [] }, () => this.getStackDetails());
    }
  }

  getStackDetails = async () => {
    const data = {
      name: this.props.stackName,
    };
    const response = await apiCall('/stack/describe', data);
    if (response.statusCode === 200) {
      this.updateResponse(response);
    } else {
      this.setState({ exception: response.body });
    }
    this.setState({ isRequestActive: false });
  };

  updateResponse = (response) => {
    const { items } = this.state;
    const {
      body: {
        stack: {
          auditProperties: {
            createdBy,
            creationTime,
            lastModificationTime,
            lastModifiedBy,
          },
          name,
          serviceName,
          configuration: { role_arn },
          stackSettings: {
            maxConcurrentNodesPercent,
            availabilityThreshold,
            maxConcurrentDomains,
            maxFailedDeployment,
          },
        },
      },
    } = response;
    items.push({ property: 'Stack Name', value: name });
    items.push({
      property: 'Created By',
      value: renderCreatorIdentityServicePrinciple(createdBy),
    });
    items.push({
      property: 'Creation Time',
      value: convertEpochToDate(creationTime),
    });
    items.push({ property: 'Role ARN', value: role_arn });
    items.push({ property: 'Last Modified By', value: lastModifiedBy });
    items.push({
      property: 'Last Modified Time',
      value: convertEpochToDate(lastModificationTime),
    });
    items.push({ property: 'Service Name', value: serviceName });
    items.push({
      property: 'Availability Threshold',
      value: availabilityThreshold,
    });
    items.push({
      property: 'Max Concurrent Domains',
      value: maxConcurrentDomains,
    });
    items.push({
      property: 'Max Concurrent Nodes Percent',
      value: maxConcurrentNodesPercent,
    });
    items.push({
      property: 'Max Failed Deployment',
      value: maxFailedDeployment,
    });
    this.setState({ items });
  };

  render() {
    const { isRequestActive, exception, items } = this.state;

    if (isRequestActive) {
      return <EuiLoadingSpinner size="l" id="loadingSpinner" />;
    } else if (exception) {
      return (
        <EuiPanel>
          <EuiTextColor color={'danger'}>{exception}</EuiTextColor>
        </EuiPanel>
      );
    }
    return <EuiBasicTable columns={columns} items={items} />;
  }
}
