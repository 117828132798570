import { SpaceBetween } from '@amzn/awsui-components-react';
import { useState } from 'react';
import { JunoRegionNotSupported } from './../commons/components/JunoRegionNotSupported';
import * as u from './../commons/utils';
import { BrowseLogs } from './BrowseLogs';
import { RequestLogs } from './RequestLogs';

const Logs = (props) => {
  const [s3Keys, setS3Keys] = useState([]);
  const [s3KeysLoading, setS3KeysLoading] = useState(true);
  const [progressValue, setProgressValue] = useState(0);
  const sendS3Keys = (s3Keys) => {
    setS3Keys(s3Keys);
  };

  const sendS3KeysLoading = (s3KeysLoading) => {
    setS3KeysLoading(s3KeysLoading);
  };

  return (
    <>
      {!u.isJunoSupportedRegion() ? (
        <JunoRegionNotSupported />
      ) : (
        <SpaceBetween direction="vertical" size="l">
          <RequestLogs
            sendS3Keys={sendS3Keys}
            sendS3KeysLoading={sendS3KeysLoading}
            progressValue={progressValue}
            setProgressValue={setProgressValue}
          />
          <BrowseLogs
            s3Keys={s3Keys}
            s3KeysLoading={s3KeysLoading}
            progressValue={progressValue}
            setProgressValue={setProgressValue}
          />
        </SpaceBetween>
      )}
    </>
  );
};

export { Logs };
