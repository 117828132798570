import { gql, useMutation } from '@apollo/client';
import { AnyObject } from '../../../../models/types';

export interface InstanceCommandVars {
  url: string;
  args: AnyObject;
  approval?: JSON;
  confirm?: JSON;
}

export const EXECUTE_INSTANCE_COMMAND = gql`
  mutation ExecuteInstanceCommand(
    $url: String!
    $args: JSON
    $approval: JSON
    $confirm: JSON
  ) {
    executeCommand(
      url: $url
      args: $args
      approval: $approval
      confirm: $confirm
    )
  }
`;

export const useExecuteCommandMutation = () => {
  return useMutation<AnyObject, InstanceCommandVars>(EXECUTE_INSTANCE_COMMAND);
};
