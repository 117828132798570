import * as React from 'react';
import { Container, Header } from '@amzn/awsui-components-react';

const HistoricalAnalysis = (props) => {
  return (
    <Container
      header={
        <Header
          variant="h2"
          description="Historical Analysis performed on the domain"
        >
          Historical Analysis
        </Header>
      }
    >
      Historical Analysis will be added.
    </Container>
  );
};

export { HistoricalAnalysis };
