import {
  Button,
  Container,
  DateRangePicker,
  DateRangePickerProps,
  ExpandableSection,
  Header,
  Icon,
  Select,
  SpaceBetween,
  Tabs,
  TextContent,
  Alert
} from '@amzn/awsui-components-react';
import {
  EuiCallOut,
  EuiEmptyPrompt,
  EuiLink,
  EuiListGroup,
  EuiText,
} from '@elastic/eui';
import { get } from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  CloudWatch,
  CloudwatchWidget,
} from '../../../Domain/Cloudwatch/CloudWatch';
import { ExternalDashboard } from '../../../Domain/Cloudwatch/ExternalDashboard';
import { ExternalWidget } from '../../../Domain/Cloudwatch/model';
import { useToastNotifications } from '../../../hooks/useToastList';
import { Elb, ESEC2, InstanceVolumes } from '../../../models/types';
import { AppContext } from '../../../utils/appContext';
import { getDomainIdentifier } from '../../../utils/helpers';
import {
  DAYS_15,
  DAYS_455,
  DAYS_63,
  DAY_1,
  HOURS_3,
  HOUR_1,
  METRIC_RETENTION_INFO,
  MINUTE_1,
  MINUTE_15,
  MINUTE_5,
  SEC_1,
} from './constants';
import { Explorer } from './Explorer';
import { HAProxy } from './HaProxy';
import { ProcessMetrics } from './ProcessMetrics';
import './style.css';
import {
  getBeaglePoundDashbord,
  getEBSMetrics,
  getEC2Metrics,
  getElasticsearchKrakenMetrics,
  getESCarbonMetrics,
  getElasticsearchMetrics,
  getLoadBalancersMetrics,
  getPaMetrics,
  i18nStrings,
  isBeaglePoundEpAvailable,
  periodOptions as periodDropdownOptions,
  relativeOptions,
  timezoneOptions,
  getS3DiscoveryMetrics, getDragonStoneMetrics, getIndexingMetrics,
} from './utils';
import { ErrorBoundary } from 'react-error-boundary';
import { Fallback } from "../../../Components/Fallback";
import { useListCwRootMetrics } from "./hooks/useListCwRootMetrics";

const LoadGraphs = (props: any) => (
  <EuiEmptyPrompt
    title={<h4>Load cloud watch graphs ?</h4>}
    actions={[
      <Button variant="normal" onClick={props.onClick}>
        Display graphs
      </Button>,
    ]}
  />
);

interface DashboardProps {
  domainName: string;
  clientId: string;
  ec2Instances: ESEC2[];
  elb: Elb;
  shared?: {
    endTime: number; // Epoch time
    startTime: number; // Epoch time
    title: string;
    metricName?: string;
    stats?: string;
    nodeIds?: string;
    splitByResource?: string;
    dim?: string;
  };
  cellId: string;
  HAProxyType: string;
  esVersion: string;
  dragonstoneDomain: string;
}

const Dashboard = (props: DashboardProps) => {
  const appContext = useContext(AppContext);
  const toastNotifications = useToastNotifications();
  const cloudWatchProps = {
    region: appContext.region.publicRegion,
    rootAccountId: appContext.rootAccountId,
    isOptInRegion: appContext.region.isOptInRegion || false,
  };

  const [loadGraphs, setLoadGraphs] = useState(false);
  const [, setShowFullScreen] = useState<boolean>(false);

  const [date, setDate] = useState<any>({
    amount: 3,
    unit: 'week',
    type: 'relative',
  });

  const [periodOptions, setPeriodOptions] = useState(periodDropdownOptions);

  const [selectedPeriod, setSelectedPeriod] = useState({
    label: '15m',
    value: '900',
    text: 900,
  });

  // Valid parsed date from moment
  const [metricRange, setMetricRange] = useState<any>({
    startTime: props.shared
      ? moment(props.shared?.startTime).unix()
      : moment().subtract(3, 'weeks').unix(),
    endTime: props.shared
      ? moment(props.shared?.endTime).unix()
      : moment().unix(),
    period: selectedPeriod.text,
  });

  // Defaults to browser time and not to UTC
  const [utcOffset, setUtcOffset] = useState<number>(new Date().getTimezoneOffset() * 60);

  // const [timezoneValue, setTimezoneValue] = useState('Browser Timezone');
  const [selectedOption, setSelectedOption] = React.useState({
    inputDisplay: 'UTC',
    label: 'UTC',
    value: 'UTC',
    text: new Date().getTimezoneOffset() * 60,
  });

  // Exract InstanceIds for EC2 metrics
  const instancesIds = props.ec2Instances.map(
    (instance: ESEC2) => instance.instanceId
  );

  // Exract dataInstancesIds for IOPS and Throughput metrics
  const dataInstancesIds = props.ec2Instances.filter((instance: ESEC2) => instance.nodeType === 'data').map(
    instance => instance.instanceId
  );

  //Extract VolumeIds for EBS metrics
  const volumeIds = props.ec2Instances
    .map((instance: ESEC2) =>
      get(instance, 'volumes', []).map((volume: InstanceVolumes) =>
        volume.ebs ? volume.ebs.volumeId : ''
      )
    )
    .flat()
    .filter(Boolean);

  const handleDateChange = useCallback(
    ({ detail }) => {
      var startMoment, endMoment;

      if ('absolute' === detail.value.type) {
        startMoment = moment(detail.value.startDate);
        endMoment = moment(detail.value.endDate);
      } else if ('relative' === detail.value.type) {
        startMoment = moment().subtract(detail.value.amount, detail.value.unit);
        endMoment = moment();
      }

      if (
        !startMoment ||
        !startMoment.isValid() ||
        startMoment.isBefore(moment().subtract(14, 'months'))
      ) {
        toastNotifications.addDanger(
          'Invalid start date, it can not be older than 14 months'
        );
        return;
      }

      if (!endMoment || !endMoment.isValid() || endMoment.isAfter(moment())) {
        toastNotifications.addDanger(
          'Invalid end date, it can not be future date'
        );
        return;
      }

      var duration = moment.duration(endMoment.diff(startMoment)).asSeconds();
      var newPeriodOptions = periodDropdownOptions.map((a) => {
        return { ...a };
      });

      let disableOption = (optionToBeDisabled) => {
        // eslint-disable-next-line
        newPeriodOptions.find((option, i) => {
          if (option.text === optionToBeDisabled) {
            newPeriodOptions[i]['disabled'] = true;
            return true;
          }
        });
      };

      let setPeriod = (periodToBeSet) => {
        setSelectedPeriod(
          periodOptions.find((option) => option.text === periodToBeSet)
        );
      };

      // metric retention conditions to disable period options
      if (duration <= HOURS_3) {
        // setPeriod(MINUTE_1);
      }
      if (duration > HOURS_3 && duration <= DAYS_15) {
        disableOption(SEC_1);
        // setPeriod(MINUTE_1);
      }
      if (duration > DAYS_15 && duration <= DAYS_63) {
        disableOption(SEC_1);
        disableOption(MINUTE_1);
        // setPeriod(MINUTE_5);
      }
      if (duration > DAYS_63 && duration <= DAYS_455) {
        disableOption(SEC_1);
        disableOption(MINUTE_1);
        disableOption(MINUTE_5);
        // setPeriod(HOUR_1);
      }
      if (duration > DAYS_455) {
        disableOption(SEC_1);
        disableOption(MINUTE_1);
        disableOption(MINUTE_5);
        disableOption(MINUTE_15);
        disableOption(HOUR_1);
        // setPeriod(DAY_1);
      }

      // disabling period options greater than the selected duration
      if (duration < DAY_1) {
        disableOption(DAY_1);
      }
      if (duration < HOUR_1) {
        disableOption(HOUR_1);
      }
      if (duration < MINUTE_15) {
        disableOption(MINUTE_15);
      }
      if (duration < MINUTE_5) {
        disableOption(MINUTE_5);
      }
      if (duration < MINUTE_1) {
        disableOption(MINUTE_1);
      }

      // setting period for default relative date options
      if ('relative' === detail.value.type) {
        let val = detail.value.amount + ' ' + detail.value.unit;
        if (val === '6 hour' || val === '1 day') {
          setPeriod(MINUTE_1);
        }
        if (val === '3 day' || val === '1 week') {
          setPeriod(MINUTE_5);
        }
        if (val === '3 week') {
          setPeriod(MINUTE_15);
        }
      }

      setPeriodOptions(newPeriodOptions);
      setDate(detail.value);
      setMetricRange({
        startTime: startMoment.unix(),
        endTime: endMoment.unix(),
        period: selectedPeriod.text,
      });
    },
    [selectedPeriod, periodOptions, toastNotifications]
  );

  const isValidRange: DateRangePickerProps.ValidationFunction = (e) => {
    const timeDashboard = (start, end) => {
      const a = moment(end).isBefore(moment(start));
      return a;
    };
    if ('absolute' === e.type) {
      const [a] = e.startDate.split('T'),
        [n] = e.endDate.split('T');
      if (!a || !n)
        return {
          valid: !1,
          errorMessage:
            'The selected date range is incomplete. Select a start and end date for the date range.',
        };
      if (timeDashboard(e.startDate, e.endDate))
        return {
          valid: !1,
          errorMessage: 'The end date must be greater than the start date.',
        };
    } else if ('relative' === e.type) {
      if (isNaN(e.amount))
        return {
          valid: !1,
          errorMessage:
            'The selected date range is incomplete. Specify a duration for the date range.',
        };
    }
    return { valid: !0 };
  };

  const handleTimezoneChange = (selectedOption) => {
    setUtcOffset(selectedOption.text);
    setSelectedOption(selectedOption);
  };

  const handlePeriodChange = (selectedPeriodOption) => {
    setSelectedPeriod(selectedPeriodOption);
  };

  useEffect(() => {
    var startTime = metricRange.startTime;
    var endTime = metricRange.endTime;
    var period = selectedPeriod.text;
    var newMetric = { startTime: startTime, endTime: endTime, period: period };
    setMetricRange(newMetric);
  }, [selectedPeriod, metricRange.startTime, metricRange.endTime]);

  const getAllMetrics = () => [
    ...(getElasticsearchMetrics(
      props.domainName,
      props.clientId,
      appContext.rootAccountId,
      appContext.region.publicRegion,
      false
    ) as ExternalWidget[]),
    ...(getElasticsearchKrakenMetrics(
      props.domainName,
      props.clientId,
      appContext.rootAccountId,
      appContext.region.publicRegion,
      false
    ) as ExternalWidget[]),
    ...(getLoadBalancersMetrics(
      props.domainName,
      props.clientId,
      props.elb.loadBalancerName,
      props.elb.targetGroupName,
      props.cellId,
      appContext.region.publicRegion,
      false
    ) as ExternalWidget[]),
    ...(getEC2Metrics(
      props.domainName,
      props.clientId,
      instancesIds,
      props.cellId,
      appContext.region.publicRegion,
      false
    ) as ExternalWidget[]),
  ];
  const tabs = [
    {
      id: 'external',
      name: 'Elasticsearch (Customer metrics)',
      label: 'Elasticsearch (Customer metrics)',
      content: loadGraphs ? (
        <div>
          <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"ESCustomerMetricsDashboard"}/>}>
          <ExternalDashboard
            widgets={[
              ...(getElasticsearchMetrics(
                props.domainName,
                props.clientId,
                props.cellId,
                appContext.region.publicRegion,
                false
              ) as ExternalWidget[]),
            ]}
            domainIdentifier={getDomainIdentifier(
              props.clientId,
              props.domainName
            )}
            metricRange={metricRange}
            utcOffset={utcOffset}
          />
          </ErrorBoundary>
        </div>
      ) : (
        <LoadGraphs onClick={() => setLoadGraphs(true)} />
      ),
      disabled: false,
    },
    {
      id: 'carbon',
      name: 'Active/StandBy (Customer metrics)',
      label: 'Active/StandBy (Customer metrics)',
      content: loadGraphs ? (
        <div>
          <Alert header="Metric Labels:">
            0 and 1 in metric labels represent StandbyAZ and ActiveAZ respectively. Example: 
            "0 ReadLatency Average" represents the average ReadLatency metric for StandbyAZ.
          </Alert>
          <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"CarbonMetricsDashboard"}/>}>
          <ExternalDashboard
            widgets={[
              ...(getESCarbonMetrics(
                props.domainName,
                props.clientId,
                props.cellId,
                appContext.region.publicRegion,
                false
              ) as ExternalWidget[]),
            ]}
            domainIdentifier={getDomainIdentifier(
              props.clientId,
              props.domainName
            )}
            metricRange={metricRange}
            utcOffset={utcOffset}
          />
          </ErrorBoundary>
        </div>
      ) : (
        <LoadGraphs onClick={() => setLoadGraphs(true)} />
      ),
      disabled: false,
    },
    {
      id: 'elasticsearch',
      name: 'Elasticsearch (Internal)',
      label: 'Elasticsearch (Internal)',
      content: loadGraphs ? (
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"ESInternalMetricsDashboard"}/>}>
        <CloudWatch
          {...cloudWatchProps}
          widgets={
            getElasticsearchMetrics(
              props.domainName,
              props.clientId,
              props.cellId,
              appContext.region.publicRegion,
              true
            ) as CloudwatchWidget[]
          }
        />
        </ErrorBoundary>
      ) : (
        <LoadGraphs onClick={() => setLoadGraphs(true)} />
      ),
      disabled: false,
    },
    {
      id: 'processMetrics',
      name: 'Process Metrics (Internal)',
      label: 'Process Metrics (Internal)',
      content: (
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"ProcessMetricsDashboard"}/>}>
        <ProcessMetrics
          clientId={props.clientId}
          domainName={props.domainName}
          instancesIds={instancesIds}
          isOptInRegion={cloudWatchProps.isOptInRegion}
          region={cloudWatchProps.region}
          rootAccountId={props.cellId}
          esVersion={props.esVersion}
        />
        </ErrorBoundary>
      ),
    },
    {
      id: 'pa',
      name: 'Performance Analyzer (Internal)',
      label: 'Performance Analyzer (Internal)',
      content: loadGraphs ? (
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"PAMetricsDashboard"}/>}>
        <CloudWatch
          {...cloudWatchProps}
          widgets={
            getPaMetrics(
              props.domainName,
              props.clientId,
              props.cellId,
              appContext.region.publicRegion,
            ) as CloudwatchWidget[]
          }
        />
        </ErrorBoundary>
      ) : (
        <LoadGraphs onClick={() => setLoadGraphs(true)} />
      ),
      disabled: false,
    },
    {
      id: 'ec2',
      name: 'EC2',
      label: 'EC2',
      content: loadGraphs ? (
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"EC2MetricsDashboard"}/>}>
        <CloudWatch
          {...cloudWatchProps}
          widgets={
            getEC2Metrics(
              props.domainName,
              props.clientId,
              instancesIds,
              props.cellId,
              appContext.region.publicRegion,
              true
            ) as CloudwatchWidget[]
          }
        />
        </ErrorBoundary>
      ) : (
        <LoadGraphs onClick={() => setLoadGraphs(true)} />
      ),
      disabled: false,
    },
    {
      id: 'kraken',
      name: 'Kraken',
      label: 'Kraken',
      content: loadGraphs ? (
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"KrakenMetricsDashboard"}/>}>
        <CloudWatch
          {...cloudWatchProps}
          widgets={
            getElasticsearchKrakenMetrics(
              props.domainName,
              props.clientId,
              props.cellId,
              appContext.region.publicRegion,
              true
            ) as CloudwatchWidget[]
          }
        />
        </ErrorBoundary>
      ) : (
        <LoadGraphs onClick={() => setLoadGraphs(true)} />
      ),
      disabled: false,
    },
    {
      id: 'lb',
      name: 'Load balancer (ALB)',
      label: 'Load balancer (ALB)',
      content: loadGraphs ? (
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"ALBMetricsDashboard"}/>}>
        <CloudWatch
          {...cloudWatchProps}
          widgets={
            getLoadBalancersMetrics(
              props.domainName,
              props.clientId,
              props.elb.loadBalancerName,
              props.elb.targetGroupName,
              props.cellId,
              appContext.region.publicRegion,
              true
            ) as CloudwatchWidget[]
          }
        />
        </ErrorBoundary>
      ) : (
        <LoadGraphs onClick={() => setLoadGraphs(true)} />
      ),
      disabled: false,
    },
    {
      id: 'ebs',
      name: 'EBS',
      label: 'EBS',
      content: loadGraphs ? (
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"EBSMetricsDashboard"}/>}>
        <CloudWatch
          {...cloudWatchProps}
          widgets={
            getEBSMetrics(
              props.domainName,
              props.clientId,
              volumeIds,
              dataInstancesIds,
              props.cellId,
              appContext.region.publicRegion,
              true
            ) as CloudwatchWidget[]
          }
        />
        </ErrorBoundary>
      ) : (
        <LoadGraphs onClick={() => setLoadGraphs(true)} />
      ),
      disabled: false,
    },
    {
      id: 's3Discovery',
      name: 'S3 Discovery (Internal)',
      label: 'S3 Discovery (Internal)',
      content: loadGraphs ? (
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"S3DiscoveryMetricsDashboard"}/>}>
        <CloudWatch
          {...cloudWatchProps}
          widgets={
            getS3DiscoveryMetrics(
              props.domainName,
              props.clientId,
              props.cellId,
              appContext.region.publicRegion,
            ) as CloudwatchWidget[]
          }
        />
        </ErrorBoundary>
      ) : (
        <LoadGraphs onClick={() => setLoadGraphs(true)} />
      ),
      disabled: false,
    },
    {
      id: 'hap',
      name: 'HAProxy (Internal)',
      label: 'HAProxy (Internal)',
      content: (
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"HAProxyMetricsDashboard"}/>}>
        <HAProxy
          clientId={props.clientId}
          domainName={props.domainName}
          instancesIds={instancesIds}
          isOptInRegion={cloudWatchProps.isOptInRegion}
          region={cloudWatchProps.region}
          rootAccountId={props.cellId}
          HAProxyType={props.HAProxyType}
        />
        </ErrorBoundary>
      ),
    },
    {
      id: 'all',
      name: 'All',
      label: 'All',
      content: loadGraphs ? (
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"AllMetricsDashboard"}/>}>
        <ExternalDashboard
          widgets={[
            ...(getElasticsearchMetrics(
              props.domainName,
              props.clientId,
              props.cellId,
              appContext.region.publicRegion,
              false
            ) as ExternalWidget[]),
            ...(getElasticsearchKrakenMetrics(
              props.domainName,
              props.clientId,
              props.cellId,
              appContext.region.publicRegion,
              false
            ) as ExternalWidget[]),
            ...(getLoadBalancersMetrics(
              props.domainName,
              props.clientId,
              props.elb.loadBalancerName,
              props.elb.targetGroupName,
              props.cellId,
              appContext.region.publicRegion,
              false
            ) as ExternalWidget[]),
            ...(getEC2Metrics(
              props.domainName,
              props.clientId,
              instancesIds,
              props.cellId,
              appContext.region.publicRegion,
              false
            ) as ExternalWidget[]),
          ]}
          domainIdentifier={getDomainIdentifier(
            props.clientId,
            props.domainName
          )}
          metricRange={metricRange}
          utcOffset={utcOffset}
        />
        </ErrorBoundary>
      ) : (
        <LoadGraphs onClick={() => setLoadGraphs(true)} />
      ),
      disabled: false,
    },
    {
      id: 'explorer',
      name: 'Explorer',
      label: 'Explorer',
      content: (
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"ExplorerMetricsDashboard"}/>}>
        <Explorer
          utcOffset={utcOffset}
          domainIdentifier={getDomainIdentifier(
            props.clientId,
            props.domainName
          )}
          cellId={props.cellId}
          metricRange={metricRange}
          domainName={props.domainName}
          clientId={props.clientId}
          rootAccountId={appContext.rootAccountId}
        />
        </ErrorBoundary>
      ),
    },
    {
      id: 'indexing-metrics',
      name: 'Indexing Metrics',
      label: 'Indexing Metrics',
      content: loadGraphs ? (
        <div>
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"IndexingMetricsDashboard"}/>}>
          <ExternalDashboard
            widgets={[
            ...(getIndexingMetrics(
                props.domainName,
                props.clientId,
                props.cellId,
              ) as ExternalWidget[])
            ]}
            domainIdentifier={getDomainIdentifier(
              props.clientId,
              props.domainName
            )}
            metricRange={metricRange}
            utcOffset={utcOffset}
          />
        </ErrorBoundary>
        </div>
      ) : (
        <LoadGraphs onClick={() => setLoadGraphs(true)} />
      ),
      disabled: false,
    },
    {
      id: 'sifi',
      name: 'SiFi',
      label: 'SiFi',
      content: isBeaglePoundEpAvailable(appContext.region.publicRegion) ? (
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"SifiMetricsDashboard"}/>}>
        <EuiListGroup
          listItems={
            getBeaglePoundDashbord(
              getDomainIdentifier(props.clientId, props.domainName),
              appContext.region.publicRegion
            ) as any
          }
        />
        </ErrorBoundary>
      ) : (
        <div>
          <EuiText grow={false}>
            <h4>
              {' '}
              BeaglePound dashboards not available in region{' '}
              {appContext.region.publicRegion}{' '}
            </h4>
          </EuiText>
        </div>
      ),
      disabled: false,
    },
  ];

  const dragonstoneMetricTab = {
    id: 'dragonStone',
    name: 'Remote Store (Internal)',
    label: 'Remote Store (Internal)',
    content: loadGraphs ? (
        <ErrorBoundary fallbackRender={props => <Fallback failedComponent={"DragonStoneMetricsDashboard"}/>}>
          <CloudWatch
              {...cloudWatchProps}
              widgets={
                getDragonStoneMetrics(
                    props.domainName,
                    props.clientId,
                    props.cellId,
                    appContext.region.publicRegion,
                ) as CloudwatchWidget[]
              }
          />
        </ErrorBoundary>
    ) : (
        <LoadGraphs onClick={() => setLoadGraphs(true)} />
    ),
    disabled: false,
  }

  const renderDomainMetrics = (dragonstoneEnabled) => {
    if (dragonstoneEnabled === "true") {
      // Add the Dragonstone metric tab to the list 
      // iff the domain is dragonstone enabled
      tabs.splice(10, 0, dragonstoneMetricTab);
      return <Tabs tabs={tabs} />
    }
    return <Tabs tabs={tabs} />
  }

  const renderSharedMetrics = () => {
    const selectedMetric = getAllMetrics().find(
      (metric) => metric.title === props.shared?.title.replace(/_/g, ' ')
    );
    if (selectedMetric) {
      return (
        <ExternalDashboard
          widgets={[selectedMetric]}
          domainIdentifier={getDomainIdentifier(
            props.clientId,
            props.domainName
          )}
          metricRange={metricRange}
          utcOffset={utcOffset}
        />
      );
    } else if (props.shared?.metricName) {
      // Shared from explorer, use explorer
      return (
        <Explorer
          clientId={props.clientId}
          domainName={props.domainName}
          rootAccountId={appContext.rootAccountId}
          cellId={props.cellId}
          metricRange={metricRange}
          domainIdentifier={getDomainIdentifier(
            props.clientId,
            props.domainName
          )}
          initialValues={{
            selectedMetric: {
              label: props.shared?.metricName,
              value: props.shared?.metricName,
            },
            stats: props.shared?.stats
              ? props.shared.stats
                  .split(',')
                  .map((stat) => ({ label: stat, value: stat }))
              : [{ label: 'Average', value: 'Average' }],
            nodeIds: props.shared.nodeIds
              ? props.shared.nodeIds
                  .split(',')
                  .map((nodeId) => ({ label: nodeId, value: nodeId }))
              : [],
            splitByNodeId: props.shared.splitByResource === 'true',
            isDirty: true,
          }}
          utcOffset={utcOffset}
        />
      );
    } else {
      return (
        <EuiCallOut
          title={
            <span>
              Metirc <b>{props.shared?.title}</b> is not valid, ensure this
              metirc is supported by Tumbler
            </span>
          }
          color="warning"
          iconType="alert"
          size="m"
        >
          <p>
            {' '}
            Navigate to domain overview{' '}
            <EuiLink href={`#/${props.clientId}/${props.domainName}`}>
              {props.clientId}:{props.domainName}
            </EuiLink>{' '}
            for more details.
          </p>
        </EuiCallOut>
      );
    }
  };
  return (
    <Container
      disableContentPaddings
      header={
        <Header
          variant="h2"
          actions={
            <SpaceBetween direction="horizontal" size="s">
              <DateRangePicker
                value={date}
                i18nStrings={i18nStrings}
                placeholder="Filter by a date and time range"
                onChange={handleDateChange}
                relativeOptions={relativeOptions}
                isValidRange={isValidRange}
                showClearButton={false}
                timeOffset={selectedOption.mins}
              />
              <TextContent>
                <strong>Period :</strong>
              </TextContent>
              <Select
                selectedOption={selectedPeriod}
                onChange={({ detail }) =>
                  handlePeriodChange(detail.selectedOption)
                }
                options={periodOptions}
                selectedAriaLabel="Selected"
              />
              <TextContent>
                <strong>Timezone :</strong>
              </TextContent>
              <Select
                selectedOption={selectedOption}
                onChange={({ detail }) =>
                  handleTimezoneChange(detail.selectedOption)
                }
                options={timezoneOptions}
                selectedAriaLabel="Selected"
              />
              <Button
                variant="link"
                onClick={() =>
                  setShowFullScreen(
                    (latestFullScreen: boolean) => !latestFullScreen
                  )
                }
              >
                <Icon name="view-full" size="normal" variant="normal" />
              </Button>
            </SpaceBetween>
          }
        >
          Dashboard
        </Header>
      }
    >
      <ExpandableSection
        header="Retention period of all metrics"
        className="expandableSection"
      >
        {METRIC_RETENTION_INFO.map((row) => (
          <>
            {row}
            <br />
          </>
        ))}
      </ExpandableSection>
      {props.shared ? renderSharedMetrics() : renderDomainMetrics(props.dragonstoneDomain)}
    </Container>
  );
};

export { Dashboard };
