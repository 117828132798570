import {
  Badge,
  Box,
  Button,
  CollectionPreferences,
  Header,
  Link,
  Pagination,
  StatusIndicator,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react';
import { EuiAvatar } from '@elastic/eui';
import { useLocation } from 'react-router-dom';
import historyData from './../Data/history.json';
import {
  COLUMN_DEFINITIONS,
  PAGE_SIZE_PREFERENCE,
  VISIBLE_COLUMNS,
  VISIBLE_CONTENT,
  VISIBLE_CONTENT_OPTIONS,
} from './constants';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const itemsGenerator = (historyData) => {
  for (let item in historyData) {
    let analysis = historyData[item]['analysis'];
    let status = historyData[item]['status'];
    let startTime = historyData[item]['startTime'];
    let endTime = historyData[item]['endTime'];
    let createdBy = historyData[item]['createdBy'];
    let creator =
      'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' +
      createdBy;
    console.log(creator);
    historyData[item]['analysis'] = (
      <Link href="/lse-tool/details?timeWindow=1w&interval=1h&tms=dp:es-grey-disk,dp:stuck-translog-recovery">
        {analysis}
      </Link>
    );
    if (status === 'Completed') {
      historyData[item]['status'] = <Badge color="green">{status}</Badge>;
    } else if (status === 'In Progress') {
      historyData[item]['status'] = <Badge color="red">{status}</Badge>;
    }
    historyData[item]['startTime'] = (
      <StatusIndicator type="pending">{startTime}</StatusIndicator>
    );
    historyData[item]['endTime'] = (
      <StatusIndicator type="pending">{endTime}</StatusIndicator>
    );
    historyData[item]['createdBy'] = (
      <div>
        <EuiAvatar size="m" name={createdBy} imageUrl={creator} />
        <p style={{ float: 'right', paddingTop: '3px' }}> {createdBy}</p>
      </div>
    );
  }
  return historyData;
};

const History = (props) => {
  useQuery();
  return (
    <div>
      <Table
        columnDefinitions={COLUMN_DEFINITIONS}
        items={itemsGenerator(historyData)}
        loadingText="Loading resources"
        visibleColumns={VISIBLE_COLUMNS}
        empty={
          <Box textAlign="center" color="inherit">
            <b>No resources</b>
            <Box padding={{ bottom: 's' }} variant="p" color="inherit">
              No resources to display.
            </Box>
            <Button>Create resource</Button>
          </Box>
        }
        filter={<TextFilter filteringText="" />}
        header={<Header> AES LSE/Spike Analysis Report History</Header>}
        pagination={
          <Pagination
            currentPageIndex={1}
            pagesCount={2}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
          />
        }
        preferences={
          <CollectionPreferences
            title="Table Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={{
              pageSize: 5,
              visibleContent: VISIBLE_CONTENT,
            }}
            pageSizePreference={PAGE_SIZE_PREFERENCE}
            visibleContentPreference={VISIBLE_CONTENT_OPTIONS}
          />
        }
      />
    </div>
  );
};

export { History };
