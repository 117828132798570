import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../../models/types';


export interface GetAccessPolicyVars {
    awsAccountId: String;
    type: String;
    name: String;
}

const GET_ACCESS_POLICY = gql`
	     query GetAccessPolicy(
	             $awsAccountId: String,
	             $type: String,
	             $name: String
	         ){
	         getAccessPolicy(
	             awsAccountId: $awsAccountId,
	             type: $type,
	             name: $name
	         )
	     }
	 `;

export const useGetAccessPolicyQuery = () => {
    return useLazyQuery<AnyObject, GetAccessPolicyVars>(GET_ACCESS_POLICY, {
        errorPolicy: 'all',
    });
};