import { Table } from '@amzn/awsui-components-react/polaris';

const AggregationTable = (props) => {
  return (
    <Table
      columnDefinitions={props.columnDefinitions}
      items={props.items}
      // filter={props.filter}
    />
  );
};

export { AggregationTable };
