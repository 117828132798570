import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks/dist/cjs/interfaces';
import Link from '@amzn/awsui-components-react/polaris/link';

export const otsTableColumns = [
  {
    id: 'account_id',
    sortingField: 'account_id',
    header: 'Account Id',
    cell: (e) => (
      <Link href={`/ops-transparency/onboarding/${e.account_id}`}>
        {e.account_id}
      </Link>
    ),
  },
  {
    id: 'domain_name',
    sortingField: 'domain_name',
    header: 'Domain Name',
    cell: (e) => e.domain_name,
  },
  {
    id: 'onboard_time',
    sortingField: 'onboard_time',
    header: 'Onboard Time',
    cell: (e) => new Date(parseInt(e.onboard_time) * 1000).toISOString(),
    // ariaLabel: (LabelData) => 'descending',
  },
  {
    id: 'ots_acc_onboarded_flag',
    sortingField: 'ots_acc_onboarded_flag',
    header: 'Onboarded Flag',
    cell: (e) => e.ots_acc_onboarded_flag,
  },
  {
    id: 'ots_acc_present_in_aws_org',
    sortingField: 'ots_acc_present_in_aws_org',
    header: 'Present in AWS org',
    cell: (e) => e.ots_acc_present_in_aws_org,
  },
  {
    id: 'ots_item_class',
    sortingField: 'ots_item_class',
    header: 'Class',
    cell: (e) => e.ots_item_class,
  },
  {
    id: 'ots_item_parent',
    sortingField: 'ots_item_parent',
    header: 'Parent',
    cell: (e) => e.ots_item_parent,
  },
  {
    id: 'ots_item_region',
    sortingField: 'ots_item_region',
    header: 'Region',
    cell: (e) => e.ots_item_region,
  },
  {
    id: 'ots_item_status',
    sortingField: 'ots_item_status',
    header: 'Status',
    cell: (e) => e.ots_item_status,
  },
  {
    id: 'ots_item_type',
    sortingField: 'ots_item_type',
    header: 'Type',
    cell: (e) => e.ots_item_type,
  },
  {
    id: 'ots_uuid',
    sortingField: 'ots_uuid',
    header: 'ots_uuid',
    cell: (e) => e.ots_uuid,
  },
  {
    id: 'trusted_entity_external_id',
    sortingField: 'trusted_entity_external_id',
    header: 'Trusted Entity External Id',
    cell: (e) => e.trusted_entity_external_id,
  },
  {
    id: 'aws_org_read_role_arn',
    sortingField: 'aws_org_read_role_arn',
    header: 'Role Arn',
    cell: (e) => e.aws_org_read_role_arn,
  },
];

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Main distribution properties',
    options: [
      { id: 'account_id', label: 'account_id' },
      { id: 'domain_name', label: 'domain_name' },
      { id: 'onboard_time', label: 'onboard_time' },
      { id: 'ots_acc_onboarded_flag', label: 'ots_acc_onboarded_flag' },
      { id: 'ots_acc_present_in_aws_org', label: 'ots_acc_present_in_aws_org' },
      { id: 'ots_item_class', label: 'ots_item_class' },
      { id: 'ots_item_parent', label: 'ots_item_parent' },
      { id: 'ots_item_region', label: 'ots_item_region' },
      { id: 'ots_item_status', label: 'ots_item_status' },
      { id: 'ots_item_type', label: 'ots_item_type' },
      { id: 'ots_uuid', label: 'ots_uuid' },
      { id: 'trusted_entity_external_id', label: 'trusted_entity_external_id' },
      { id: 'aws_org_read_role_arn', label: 'aws_org_read_role_arn' },
    ],
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10' },
  { value: 30, label: '30' },
  { value: 50, label: '50' },
  { value: 500, label: '500' },
  { value: 1000, label: '1000' },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 10,
  visibleContent: [
    'account_id',
    'onboard_time',
    'ots_acc_onboarded_flag',
    'ots_acc_present_in_aws_org',
    'ots_item_parent',
    'ots_item_region',
    'ots_item_status',
  ],
  wraplines: false,
};

export const FILTERING_PROPERTIES: readonly PropertyFilterProperty[] = [
  {
    propertyLabel: 'Onboarded Flag',
    key: 'ots_acc_onboarded_flag',
    groupValuesLabel: 'ots_acc_onboarded_flag values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Present in AWS org',
    key: 'ots_acc_present_in_aws_org',
    groupValuesLabel: 'ots_acc_present_in_aws_org values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Class',
    key: 'ots_item_class',
    groupValuesLabel: 'ots_item_class values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Region',
    key: 'ots_item_region',
    groupValuesLabel: 'ots_item_region Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Status',
    key: 'ots_item_status',
    groupValuesLabel: 'ots_item_status values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Type',
    key: 'ots_item_type',
    groupValuesLabel: 'ots_item_type values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Trusted Entity External Id',
    key: 'trusted_entity_external_id',
    groupValuesLabel: 'trusted_entity_external_id Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Role Arn',
    key: 'aws_org_read_role_arn',
    groupValuesLabel: 'aws_org_read_role_arn Values',
    operators: [':', '!:', '=', '!='],
  },
];
