import { Box, Button, Grid, Header } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
// @ts-ignore
import { useHistory } from 'react-router-dom';
import {
  FormField,
  Input,
} from '@amzn/awsui-components-react/polaris';
import { validation } from "../../utils/domainValidation";
interface DomainIdentifierProps {
  domainIdentifier: string;
}

const DomainIdentifier = (props: DomainIdentifierProps) => {
  const history = useHistory();
  const [domainIdentifier, setDomainIdentifier] = useState<string>(
    props.domainIdentifier
  );
  const [hasError, setHasError] = useState(false);
  const handleSubmit = () => {
    validation(domainIdentifier, history, setHasError);
  };
  return (
    <div>
      <Box float="left">
        <Header variant="h1">Domain details</Header>
      </Box>
      <Grid
        gridDefinition={[
          { colspan: { xxs: 3 }, offset: { xxs: 8 } },
          { colspan: { xxs: 1 } },
        ]}
        disableGutters
      >
          <FormField
              errorText={
                hasError ? 'Provide valid domain identifier or clientId' : ''
              }
            >
            <Input
              onChange={({ detail }) => setDomainIdentifier(detail.value)}
              value={domainIdentifier}
              disableBrowserAutocorrect
              placeholder="Domain Identifier"
              style={{ width: '100px' }}
            />
          </FormField>
        <Button iconName="refresh" onClick={handleSubmit} />
      </Grid>
    </div>
  );
};
export { DomainIdentifier };
