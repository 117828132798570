import { gql, useLazyQuery } from '@apollo/client';
import { DomainEvent } from '../../models/types';

interface DomainEventsData {
    domainEvents: { events: DomainEvent} ;
}

export interface DomainEventsVars {
    domainId: string;
    startTime: string;
    endTime: string;
}

const DOMAIN_EVENTS = gql`
    query getDomainEvents($domainId: String!, $startTime: String, $endTime: String) {
        domainEvents(domainId: $domainId, startTime: $startTime, endTime: $endTime) {
            events {
                client_id
                last_updated_timestamp
                ttl
                creation_timestamp
                resource_id
                event_source
                event_type
                identuple
                identuple_event_resource_id
                event_date
                event_timestamp
                event_metadata
                metadata_start_time
                metadata_status_code
                metadata_cw_event_id
                metadata_activity_id
                metadata_end_time
                metadata_cause
                metadata_asg_name
                metadata_request_id
                metadata_event_name
                metadata_execution_id
                metadata_whitelist
                metadata_user
                metadata_action_name
                metadata_safe_call
                status
                resultTruncated
            }
        }
    }
`;

export const useDomainEventsQuery = () => {
    return useLazyQuery<DomainEventsData, DomainEventsVars>(DOMAIN_EVENTS, {
        errorPolicy: 'all',
    });
};