import React from 'react';
import { apiCall } from '../../utils';

import {
  EuiFlexItem,
  EuiButton,
  EuiText,
  EuiFormRow,
  EuiFieldText,
  EuiFlexGroup,
  EuiCheckbox,
  EuiSelect,
  EuiToast,
  EuiSpacer,
} from '@elastic/eui';

const scopeOptions = [
  { value: '', text: 'Select Scope' },
  { value: 'GLOBAL', text: 'GLOBAL' },
  { value: 'ACCOUNT', text: 'ACCOUNT' },
  { value: 'COLLECTION', text: 'COLLECTION' },
];

const componentTypeOptions = [
  { value: '', text: 'Select Type' },
  { value: 'FILE', text: 'FILE' },
  { value: 'CONTAINER', text: 'CONTAINER' },
  { value: 'CUSTOM', text: 'CUSTOM' },
  { value: 'PLUGIN', text: 'PLUGIN' },
];

export class CreateComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      componentName: '',
      componentScope: '',
      componentScopeValue: '',
      componentType: '',
      parentComponent: '',
      requiresRestart: false,
      status: null,
      message: null,
    };
  }

  createNewComponent = async () => {
    const { stackName } = this.props;
    const {
      componentName,
      componentScope,
      componentScopeValue,
      componentType,
      parentComponent,
      requiresRestart,
    } = this.state;
    const data = {
      stackName,
      componentName,
      componentScopeDefinition: { componentScope },
      componentProperties: {},
    };

    if (componentScope === 'ACCOUNT' && componentScopeValue) {
      data.componentScopeDefinition.componentScopeValue = componentScopeValue;
    }

    if (componentType) {
      data.componentType = componentType;
    }

    if (parentComponent !== '') {
      data.componentProperties.parentComponent = parentComponent;
    }

    if (requiresRestart) {
      data.componentProperties.requiresRestart = requiresRestart;
    }

    const response = await apiCall('/component/create', data);
    if (response.statusCode === 200) {
      this.setState({
        status: 'success',
        message: `Component ${data.componentName} was created with scope as ${
          data.componentScopeDefinition.componentScope
        }${
          data.componentScopeDefinition.componentScope === 'ACCOUNT'
            ? ` ${data.componentScopeDefinition.componentScopeValue}`
            : ''
        }`,
      });
    } else {
      this.setState({
        status: 'error',
        message: JSON.stringify(response.body),
      });
    }
  };

  onChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
  };

  onCheckHandler = (propertyName) => {
    return (event) => {
      const value = event.target.checked;
      this.setState({ [propertyName]: value });
    };
  };

  onClose = () => {
    this.setState({ status: null });
  };

  render() {
    const {
      state,
      onClose,
      onChangeHandler,
      onCheckHandler,
      createNewComponent,
    } = this;
    const {
      status,
      requiresRestart,
      componentScope,
      componentScopeValue,
      parentComponent,
      componentType,
      componentName,
      message,
    } = state;
    return (
      <div>
        <EuiFlexGroup>
          <EuiFlexItem>
            {status && (
              <EuiToast
                title={`Component Creation ${
                  status === 'success' ? 'Suceeded' : 'Failed'
                }`}
                color={status === 'success' ? 'success' : 'danger'}
                iconType={status === 'success' ? 'check' : 'alert'}
                onClose={onClose}
              >
                <EuiText>{message}</EuiText>
              </EuiToast>
            )}
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup>
          <EuiFlexItem grow={5}>
            <EuiFormRow
              id="componentName"
              onChange={onChangeHandler}
              label="Component Name"
              helpText="Enter the component name."
            >
              <EuiFieldText name="componentName" value={componentName} />
            </EuiFormRow>
            <EuiFormRow
              label="componentType"
              onChange={onChangeHandler}
              id="componentType"
              helpText="Enter the component type."
            >
              <EuiSelect
                placeholder="componentType"
                options={componentTypeOptions}
                value={componentType}
                name="componentType"
              />
            </EuiFormRow>
            <EuiFormRow
              label="parentComponent"
              onChange={onChangeHandler}
              id="parentComponent"
              helpText="Enter the parent component."
            >
              <EuiFieldText name="parentComponent" value={parentComponent} />
            </EuiFormRow>
          </EuiFlexItem>

          <EuiFlexItem grow={5}>
            <EuiFormRow
              label="componentScope"
              onChange={onChangeHandler}
              id="componentScope"
              helpText="Enter the component scope."
            >
              <EuiSelect
                placeholder="componentScope"
                options={scopeOptions}
                value={componentScope}
                name="componentScope"
              />
            </EuiFormRow>
            {componentScope === 'ACCOUNT' && (
              <EuiFormRow
                id="componentScopeValue"
                onChange={onChangeHandler}
                label="component Scope Value"
                helpText="Enter the component scope value."
              >
                <EuiFieldText
                  name="componentScopeValue"
                  value={componentScopeValue}
                />
              </EuiFormRow>
            )}
            <EuiSpacer size="xl" />
            <EuiCheckbox
              id="req_res_id"
              label={'Requires Restart'}
              checked={requiresRestart}
              onChange={onCheckHandler('requiresRestart')}
            />
            <EuiSpacer size="xl" />
            <div>
              <EuiButton onClick={createNewComponent}>{'submit'}</EuiButton>
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    );
  }
}
