import {
  BreadcrumbGroup,
  Container,
  Header,
  SpaceBetween,
  Alert,
} from '@amzn/awsui-components-react';
import { get } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
// @ts-ignore
import { useHistory, useParams } from 'react-router-dom';
import { ClientOverviewParams } from '../../models/types';
import { ClientRouterProps } from '../../TumblerHome';
import { AppContext } from '../../utils/appContext';
import { BREADCRUMBS } from '../../utils/constants';
import { CustomerDetails } from './CustomerDetails';
import { DomainList } from './DomainList/DomainList';
import { useDomainListQuery } from './hooks/useDomainListsQuery';
import { ErrorBoundary } from 'react-error-boundary';
import { Fallback } from "../../Components/Fallback";
import { isError } from "../../ErrorHandler/apiErrorHandler";
import { ErrorMessageComponent } from "../../ErrorHandler/errorMessageComponent";
import { ApiList }from "../../ErrorHandler/utils";

const AccountOverview = (props: ClientRouterProps) => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const { clientId } = useParams<ClientOverviewParams>();
  const [errorMessage, setErrorMessage] = useState(null)
  const { loading, data, error } = useDomainListQuery({ clientId });
  const isErrorPresent = isError(error)
   useEffect(() => {
     if ( isErrorPresent ) {
      setErrorMessage("Unable to get Domain List. Failed with " + error.message);
    }
  }, [isErrorPresent, error]);

  useEffect(() => {
    appContext.setBreadcrumbs([
      {
        ...BREADCRUMBS.TUMBLER,
        href: '/',
        onClick: () => {
          history.push('/');
        },
      },
      { href: `OpsTumbler/${clientId}`, text: clientId },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <SpaceBetween size="l">
      <BreadcrumbGroup items={appContext.breadcrumbs} />
      <Alert header="Note" statusIconAriaLabel="Warning" type="warning">
        Few OpsTumbler commands can have access to customer data, please exercise caution while using commands that has access to customer data.
      </Alert>
      <Container header={<Header variant="h2">{'Account details'}</Header>}>
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"AccountDetails"}/>}>
         {errorMessage ? (
             <ErrorMessageComponent errorMessage={errorMessage} apiName={ApiList.ACCOUNT_OVERVIEW}/>
             ) : (
          <CustomerDetails
            totalDomains={get(data, 'account.domains', []).length}
            loading={loading}
          />
        )}
        </ErrorBoundary>
      </Container>

      <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"DomainList"}/>}>
      <DomainList
        clientId={clientId}
        loading={loading}
        domains={get(data, 'account.domains', [])}
        errorMessage={errorMessage}
      />
      </ErrorBoundary>
    </SpaceBetween>
  );
};
export { AccountOverview };
