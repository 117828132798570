import {
    BreadcrumbGroup,
    Container,
    Header,
    SpaceBetween,
    Table,
    Flashbar,
    Pagination,
    Button,
    ButtonDropdown,
    PropertyFilter,
    DateRangePicker,
    FormField,
    Select,
    Autosuggest,
    Tiles,
    Grid,
} from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import BarChart from "@amzn/awsui-components-react/polaris/bar-chart";
import Box from "@amzn/awsui-components-react/polaris/box";
import Input from "@amzn/awsui-components-react/polaris/input";
import Checkbox from "@amzn/awsui-components-react/polaris/checkbox"
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator/internal';
import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { cloneDeep, get } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import '../../Styles/logs.css';
import { AppContext } from '../../utils/appContext';
import {BREADCRUMBS, PROPERTY_FILTERING_I18N_CONSTANTS} from '../../utils/constants';
import {useGetFilteredDataQuery} from "./useGetFilteredDataQuery"
import {
    aggregationFieldOptions, aggregationIntervalOptions,
    PAGE_SIZE_OPTIONS,
    summaryTableColumns,
    statusFilterItems,
    LIST_EXECUTION_FILTERING_PROPERTIES,
    LOG_VIEWER_DEFAULT_PREFERENCES,
    LOG_VIEWER_FILTERING_PROPERTIES,
    LOG_VIEWER_VISIBLE_CONTENT_OPTIONS,
    logViewerColumnDefintions,
    AGGREGATION_TYPE,
    TABLE_TYPE,
} from "./constants";
import moment from 'moment';
import {
    addOptionToTop,
    esOsVersionOptions,
    i18nStrings,
    isValidRequestRange,
    isValidRequestRangeForOnDemand,
    relativeOptions,
    timezoneOptions,
} from '../Logs/constants'
import { useGetAggregationQuery } from './useGetAggregationQuery';
import { isError } from "../../ErrorHandler/apiErrorHandler";
import {EmptyState, getFilterCounterText, Preferences} from "../../utils/tablePreferences";

const OnDemandAggregationsViewer = (props) => {
    const appContext = useContext(AppContext);
    const history = useHistory();
    const params = useParams();
    const clientId = params['clientId'];
    const domainName = params['domainName'];
    const domainId = `${clientId}:${domainName}`
    const defaultFieldOption = aggregationFieldOptions[0].options.find(option => option.value === 'api');
    const [selectAggregationField, setSelectAggregationField] = useState("api")
    const [statusFilter, setStatusFilter] = useState(null);
    const [flashbarItems, setFlashbarItems] = useState([]);
    const defaultIntervalOption = aggregationIntervalOptions[0].options.find(option => option.value === '1h');
    const [selectAggregationInterval, setSelectAggregationInterval] = useState(defaultIntervalOption)
    const [aggregationSize, setAggregationSize] = useState('0')
    const [tableSize, setTableSize] = useState('1000')
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    const [preferences, setPreferences] = useState(LOG_VIEWER_DEFAULT_PREFERENCES);
    const [barChartflashbarItems, setBarChartFlashbarItems] = useState([
        {
            type: 'info',
            content: 'If you see the below graph not loading, then please check the drop-down if filters are checked or not. Or you can simply reload and query again. This is an issue from Polaris end, we are trying to fix this',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashbarItems([]),
        },
    ])
    const [errorMessage, setErrorMessage] = useState(null);
    const [getAggregation, { loading: getAggregationLoading, data: getAggregationResponse, error: error }] = useGetAggregationQuery();
    const [getFilteredData, { loading: getFilteredDataLoading, data: getFilteredDataResponse, error: errorFilteredData }] = useGetFilteredDataQuery();
    const isErrorPresent = isError(error)
    useEffect(() => {
        if (isErrorPresent) {
            setErrorMessage("Unable to get graph Aggregations. Failed with " + error.message);
        }
    }, [isErrorPresent, error]);
    const aggregationBuckets = get(getAggregationResponse, 'getAggregation.record.aggregations.aggregated_data', {})
    const filteredTableData = get(getFilteredDataResponse, 'getFilteredData.record.hits.hits', {})
    const [filters, setFilters] = useState({
        elbStatusCode: '',
        targetStatusCode: '',
        userAgent: '',
        clientIp: '',
        targetIp: '',
        duration: '',
        receivedBytes: '',
        sentBytes: '',
        method: '',
        endpoint: '',
        uri: '',
        api: ''
    });
    const filterConfig = {
        elbStatusCode: { checked: false, description: "200,500,2xx,4xx,5xx etc" },
        targetStatusCode: { checked: false, description: "200,500,2xx,4xx,5xx etc" },
        userAgent: { checked: false, description: "User Agent" },
        clientIp: { checked: false, description: "Enter Client IP Address" },
        targetIp: { checked: false, description: "Enter Target IP address, which is target node IP" },
        duration: { checked: false, description: "Duration in ms, '>N' means greater than N ms, 'N-M' means between N and M ms, '<N' means less than N ms" },
        receivedBytes: { checked: false, description: "in bytes, '>N' means greater than N bytes, 'N-M' means between N and M bytes, '<N' means less than N bytes" },
        sentBytes: { checked: false, description: "in bytes, '>N' means greater than N bytes, 'N-M' means between N and M bytes, '<N' means less than N bytes" },
        method: { checked: false, description: "example - POST, GET" },
        endpoint: { checked: false, description: "eg - https://search-prod-iad-sample.us-east-1.es.amazonaws.com:443" },
        uri: { checked: false, description: "/sample_index/mytype" },
        api: { checked: false, description: "example - _count" },
    };

    const [filterStates, setFilterStates] = useState(filterConfig);
    const [series, setSeries] = useState([]);
    const [loadBarChart, setLoadBarChart] = useState(false)
    const [xDomains, setXDomains] = useState([])
    const [yDomains, setYDomains] = useState([])
    const [chartData, setChartData] = useState([])
    const [summaryTableData, setSummaryTableData] = useState([])
    const [type, setType] = useState(AGGREGATION_TYPE)

    const [inputValue, setInputValue] = useState('');
    const [isInputSelected, setIsInputSelected] = useState(false);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleSelectChange = (selectedOption) => {
        setInputValue(selectedOption.label);
    };

    const getIntervalInSeconds = (interval) => {
        const unit = interval.value.slice(-1);
        const amount = parseInt(interval.value.slice(0, -1));

        switch (unit) {
            case 'M': return amount * 30 * 24 * 60 * 60; // months to seconds
            case 'w': return amount * 7 * 24 * 60 * 60; // weeks to seconds
            case 'd': return amount * 24 * 60 * 60; // days to seconds
            case 'h': return amount * 60 * 60; // hours to seconds
            case 'm': return amount * 60; // minutes to seconds
            case 's': return amount; // seconds
            default: throw new Error("Invalid interval unit: " + unit);
        }
    };

    const [selectedRequestTimeZoneOption, setSelectedRequestTimeZoneOption] =
        useState({
            inputDisplay: 'UTC',
            label: 'UTC',
            value: 'UTC',
            code: 'UTC',
            offset: '0000',
            mins: 0,
        });

    const handleRequestTimezoneChange = (selectedOption) => {
        setSelectedRequestTimeZoneOption(selectedOption);
    };

    const [requestDate, setRequestDate] = useState<any>({
        type: 'relative',
        amount: 9,
        unit: 'hour',
    });
    const handleRequestDateChange = ({ detail }) => {
        setRequestDate(detail.value);
    };

    const getAggregationType = (fieldValue) => {
        if (fieldValue.startsWith('p')) {
            return 'percentile';
        } else if (fieldValue.startsWith('sum:')) {
            return 'sum';
        } else {
            return 'term';
        }
    };

    const getPercentileAndFieldValue = (fieldValue) => {
        const match = fieldValue.match(/p(\d+(\.\d+)?):(.+)/);
        if (match) {
            const percentile = parseFloat(match[1]);
            const fieldName = match[3]; // match[3] will contain the field name after the colon
            return { percentile, fieldName };
        }
        return { percentile: null, fieldName: null };
    }
    const transformDataForTermAggregation = (aggregationBuckets) => {
        const seriesMap = {}
        aggregationBuckets.buckets.forEach(bucket => {
            const time = bucket.key_as_string;
            bucket.field.buckets.forEach(subBucket => {
                if (!seriesMap[subBucket.key]) {
                    seriesMap[subBucket.key] = { title: subBucket.key, data: [] };
                }
                seriesMap[subBucket.key].data.push({ x: time, y: subBucket.doc_count });
            })
        })

        // Sort each series' data by the timestamp (x value)
        Object.keys(seriesMap).forEach(key => {
            seriesMap[key].data.sort((a, b) => new Date(a.x * 1000) - new Date(b.x * 1000));
        });

        const modifiedData = Object.keys(seriesMap).map(key => {
            const sum = seriesMap[key].data.reduce((acc, obj) => acc + obj.y, 0);
            return {
                title: key,
                sumY: sum
            };
        });
        setSummaryTableData(modifiedData)
        return Object.values(seriesMap);
    }

    const transformDataForPercentileAggregation = (aggregationBuckets, selectAggregationField) => {
        const { percentile, fieldName} = getPercentileAndFieldValue(selectAggregationField);
        const formattedPercentile = Number.isInteger(Number(percentile)) ? `${percentile}.0` : percentile;
        const percentileSeries = {
            title: `p${percentile}: ${fieldName}`,
            data: aggregationBuckets.buckets.map(bucket => ({
                x: bucket.key_as_string,
                y: bucket.field.values[`${formattedPercentile}`],
            })),
        };
        percentileSeries.data.sort((a, b) => {
            // Converting Unix timestamp (seconds) to milliseconds for the Date object
            return new Date(a.x * 1000) - new Date(b.x * 1000);
        });
        const sum = percentileSeries.data.reduce((acc, obj) => acc + obj.y, 0);
        const modifiedData= [
            {
                title: percentileSeries.title,
                sumY: sum
            },];
        setSummaryTableData(modifiedData)
        return [percentileSeries];
    };

    const flattened_items = useMemo(() => {
        const flattened_list = []
        for(let i=0 ; i< filteredTableData.length; i++)
        {
            flattened_list.push(filteredTableData[i]._source)
        }
        return flattened_list
    }, [filteredTableData]);

    const {
        items,
        actions,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
    } = useCollection(getFilteredDataLoading ? [] : flattened_items, {
        propertyFiltering: {
            filteringProperties: LOG_VIEWER_FILTERING_PROPERTIES,
            empty: (
                <EmptyState
                    title="No logs"
                    subtitle="No logs to display."
                    action={<span></span>}
                />
            ),
            noMatch: (
                <EmptyState
                    title="No matches"
                    subtitle="We can’t find a match."
                    action={
                        <Button onClick={() => actions.setFiltering('')}>
                            Clear filter
                        </Button>
                    }
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {},
        selection: {},
    });

    const transformDataForSumAggregation = (aggregationBuckets) => {
        const sumSeries = {
            title: 'Sum',
            data: aggregationBuckets.buckets.map((bucket) => ({
                x: bucket.key_as_string,
                y: bucket.field.value,
            })),
        };
        sumSeries.data.sort((a, b) => {
            // Convert Unix timestamp (seconds) to milliseconds for the Date object
            return new Date(a.x * 1000) - new Date(b.x * 1000);
        });
        const sum = sumSeries.data.reduce((acc, obj) => acc + obj.y, 0);
        const modifiedData= [
            {
            title: sumSeries.title,
            sumY: sum
        },];
        setSummaryTableData(modifiedData)
        return [sumSeries];
    };

    useEffect(() => {
        if (Object.keys(aggregationBuckets).length > 0) {
            const aggregation_type = getAggregationType(selectAggregationField)
            let transformedSeries = []
            if(aggregation_type === 'term'){
                transformedSeries = transformDataForTermAggregation(aggregationBuckets);
            }
            else if(aggregation_type === 'sum'){
                transformedSeries = transformDataForSumAggregation(aggregationBuckets)
            }
            else{
                transformedSeries = transformDataForPercentileAggregation(aggregationBuckets, selectAggregationField)
            }
            setChartData(transformedSeries);
            const chartSeries = transformedSeries.map((serie) => ({
                type: 'bar',
                title: serie.title,
                data: serie.data,
            }));
            setSeries(chartSeries);
            const xDomain = transformedSeries
                .flatMap((serie) => serie.data.map((d) => d.x))
                .filter((value, index, self) => self.indexOf(value) === index);
            setXDomains(xDomain);
            const yDomain = [
                0,
                Math.max(...transformedSeries.map(serie =>
                    Math.max(...serie.data.map(point => point.y))
                )) * 1.3,
            ];
            setYDomains(yDomain);
            setLoadBarChart(true)
        }
    }, [aggregationBuckets, getAggregationResponse]);


    const validateRequest = () => {
        var startMoment, endMoment;

        if ('absolute' === requestDate.type) {
            startMoment = moment(requestDate.startDate);
            endMoment = moment(requestDate.endDate);
        } else if ('relative' === requestDate.type) {
            startMoment = moment().subtract(requestDate.amount, requestDate.unit);
            endMoment = moment();
        }

        const startTimeStamp = startMoment.utc().format();
        const endTimeStamp = endMoment.utc().format();
        setStartTime(startTimeStamp)
        setEndTime(endTimeStamp)

        const intervalInSeconds = getIntervalInSeconds(selectAggregationInterval);
        const durationInSeconds = endMoment.diff(startMoment, 'seconds');
        const buckets = Math.ceil(durationInSeconds / intervalInSeconds);

        if (buckets > 1000 || aggregationSize > 10000 || tableSize > 10000) {
            let error = ""
            if (buckets > 1000){
                error = "Cannot aggregate more than 1000 interval buckets. Number of time buckets by your query: " + buckets +
                    ", query window = " + durationInSeconds + " seconds, interval=" + selectAggregationInterval + ". Reduce the query window or increase the query interval to fix this error."
            } else {
                error = "Field size cannot be more than 10000"
            }

            setFlashbarItems([
                {
                    type: 'error',
                    content: `Error: ${error}`,
                    dismissible: true,
                    dismissLabel: 'Dismiss message',
                    onDismiss: () =>
                        setFlashbarItems([]),
                    id: 'aggregation_error',
                },
            ]);
        } else{
            const formattedStartTimeStamp = moment.utc(startTimeStamp).format('YYYYMMDDTHHmm') + 'Z';
            const formattedEndTimeStamp = moment.utc(endTimeStamp).format('YYYYMMDDTHHmm') + 'Z';
            handleSubmit(formattedStartTimeStamp, formattedEndTimeStamp)
        }
        

    };

    const handleCheckboxChange = (key, isChecked) => {
        setFilterStates(prevStates => ({ ...prevStates, [key]: { ...prevStates[key], checked: isChecked } }));
        setFilters(prevFilters => {
            if (!isChecked) {
                const {[key]: removed, ...rest} = prevFilters;
                return rest;
            }
            return { ...prevFilters, [key]: '' }; // Initialize with an empty string or default value
        });

    };

    const handleFilterChange = (key, value) => {
        setFilters(prevFilters => ({ ...prevFilters, [key]: value }));
    };

    const toggleInputType = () => {
        setIsInputSelected(!isInputSelected);
    };

    const [value, setValue] = useState("");


    const handleSubmit = async(startTime, endTime) => {
        const query_filters = JSON.stringify(
            Object.entries(filters).reduce((acc, [key, value]) => {
                if (value) { // If the value is not an empty string
                    acc[key] = value;
                }
                return acc;
            }, {}));
        if (type === AGGREGATION_TYPE) {
            var resp = await getAggregation({
                variables: {
                    data: query_filters,
                    fieldSize: aggregationSize,
                    aggregationInterval: selectAggregationInterval.value,
                    aggregationField: selectAggregationField,
                    endTime: endTime,
                    startTime: startTime,
                    eventType: 'ELB',
                    domainId: `${clientId}:${domainName}`
                },
            });
        }
        else {
            var response = await getFilteredData({
                variables: {
                    data: query_filters,
                    fieldSize: tableSize,
                    endTime: endTime,
                    startTime: startTime,
                    eventType: 'ELB',
                    domainId: `${clientId}:${domainName}`
                },
            });

        }

    };



        return (
        <React.Fragment>
            <Flashbar items={flashbarItems} />
            <SpaceBetween size="l" direction="vertical">
                <BreadcrumbGroup items={appContext.breadcrumbs} />
                <Container
                    header={
                        <>
                            <SpaceBetween size="m" direction="horizontal">
                                <div>
                                    <Header variant="h3">
                                        ELB Logs Aggregations
                                    </Header>
                                </div>
                            </SpaceBetween>
                        </>
                    }
                >
                <SpaceBetween direction="vertical" size="l">
                    <div>
                        <Header variant="h3">
                            Filters
                        </Header>
                        <Grid gridDefinition={[
                            { colspan: { default: 3, s: 3, m: 3, l: 3, xl: 3 } },
                            { colspan: { default: 3, s: 3, m: 3, l: 3, xl: 3 } },
                            { colspan: { default: 3, s: 3, m: 3, l: 3, xl: 3 } },
                            { colspan: { default: 3, s: 3, m: 3, l: 3, xl: 3 } },
                            { colspan: { default: 3, s: 3, m: 3, l: 3, xl: 3 } },
                            { colspan: { default: 3, s: 3, m: 3, l: 3, xl: 3 } },
                            { colspan: { default: 3, s: 3, m: 3, l: 3, xl: 3 } },
                            { colspan: { default: 3, s: 3, m: 3, l: 3, xl: 3 } },
                            { colspan: { default: 3, s: 3, m: 3, l: 3, xl: 3 } },
                            { colspan: { default: 3, s: 3, m: 3, l: 3, xl: 3 } },
                            { colspan: { default: 3, s: 3, m: 3, l: 3, xl: 3 } },
                            { colspan: { default: 3, s: 3, m: 3, l: 3, xl: 3 } }
                            ]}>
                            {Object.entries(filterStates).map(([key, { checked, description }]) => (
                                <div key={key}>
                                    <Checkbox
                                        checked={checked}
                                        onChange={e => handleCheckboxChange(key, e.detail.checked)}
                                    >
                                        {key}
                                    </Checkbox>
                                    {checked && (
                                        <FormField label={key} description={description}>
                                            <Input
                                                value={filters[key] || ''}
                                                onChange={e => handleFilterChange(key, e.detail.value)}
                                            />
                                        </FormField>
                                    )}
                                </div>
                            ))}
                        </Grid>
                    </div>
                    </SpaceBetween>
                    <Grid gridDefinition={[
                        { colspan: { default: 6 } }, // This part of the grid contains your content
                        { colspan: { default: 6 } }  // This is an empty spacer part of the grid
                    ]}>
                        <div>
                            <Header variant="h3">
                                Select Type:
                            </Header>
                        <Tiles
                            onChange={({ detail }) => setType(detail.value)}
                            value={type}
                            items={[
                                { label: "Aggregation", value: AGGREGATION_TYPE },
                                { label: "Table", value: TABLE_TYPE },
                            ]}
                        />
                        </div>
                        <div> {/* This div acts as an empty second column or spacer */}
                            {/* Left empty */}
                        </div>
                    </Grid>
                    {type === AGGREGATION_TYPE && (
                        <Grid gridDefinition={[
                            { colspan: { default: 6 } }, // This part of the grid contains your content
                            { colspan: { default: 6 } }  // This is an empty spacer part of the grid
                        ]}>
                            <div> {/* Content goes in this div, which acts as the first column */}
                                <Header variant="h3">
                                    Aggregation Field
                                </Header>
                                <Autosuggest
                                    onChange={({ detail }) => setSelectAggregationField(detail.value)}
                                    value={selectAggregationField}
                                    options={aggregationFieldOptions}
                                    ariaLabel="Autosuggest example with suggestions groups"
                                    placeholder="Enter value"
                                    empty="No matches found"
                                />
                            </div>
                            <div> {/* This div acts as an empty second column or spacer */}
                                {/* Left empty */}
                            </div>
                        </Grid>
                    )}

                    <Grid gridDefinition={[
                        { colspan: { default: 3, s: 3, m: 3, l: 3, xl: 3 } },
                        { colspan: { default: 3, s: 3, m: 3, l: 3, xl: 3 } },
                        { colspan: { default: 3, s: 3, m: 3, l: 3, xl: 3 } },
                        { colspan: { default: 3, s: 3, m: 3, l: 3, xl: 3 } }
                    ]}>
                    <FormField label={<strong>Date</strong>}>
                        <DateRangePicker
                            onChange={handleRequestDateChange}
                            value={requestDate}
                            relativeOptions={relativeOptions}
                            i18nStrings={i18nStrings}
                            placeholder="Filter by a date and time range"
                            showClearButton={false}
                            timeOffset={selectedRequestTimeZoneOption.mins}
                        />
                    </FormField>
                    <FormField label={<strong>Time Zone</strong>}>
                        <Select
                            selectedOption={selectedRequestTimeZoneOption}
                            onChange={({ detail }) =>
                                handleRequestTimezoneChange(detail.selectedOption)
                            }
                            options={timezoneOptions}
                            selectedAriaLabel="Selected"
                        />
                    </FormField>
                        {type === AGGREGATION_TYPE && (
                            <FormField label="Interval">
                                <Select
                                    selectedOption={selectAggregationInterval}
                                    onChange={({ detail }) => {
                                        setSelectAggregationInterval(detail.selectedOption as any);
                                    }}
                                    options={aggregationIntervalOptions}
                                    selectedAriaLabel="Selected"
                                    placeholder="Choose options"
                                    filteringType="auto"
                                />
                            </FormField>
                        )}
                        {type === AGGREGATION_TYPE && (
                            <FormField label="Size">
                                <Input value={aggregationSize} onChange={(e) => setAggregationSize(e.detail.value)} />
                            </FormField>
                        )}
                        {type === "table" && (
                            <FormField label="Size">
                                <Input value={tableSize} onChange={(e) => setTableSize(e.detail.value)} />
                            </FormField>
                        )}
                    </Grid>
                    <div style={{ marginTop: '26px' }}>
                        <Button variant="normal" onClick={validateRequest}>
                            Submit
                        </Button>
                    </div>
                </Container>
                <Container>
                    <SpaceBetween direction="vertical" size="l">
                {loadBarChart && type === AGGREGATION_TYPE && (
                        <div>
                        <Flashbar items={barChartflashbarItems} />
                        <BarChart
                            series={series.map(s => ({
                                ...s,
                                data: s.data.map(d => ({
                                    ...d,
                                    x: new Date(d.x * 1000).toLocaleString(),
                                })),
                            }))}
                            xDomain={xDomains.map(d => new Date(d * 1000).toLocaleString())}
                            yDomain={yDomains}
                            xTitle="Time"
                            yTitle=""
                            stackedBars
                        />
                            <Table
                                header={
                                    <Header>
                                       Summary Table
                                    </Header>
                                }
                                columnDefinitions={summaryTableColumns}
                                items={summaryTableData}
                                header="Data Summary"
                                ariaLabels={{
                                    selectionGroupLabel: 'Item Selection',
                                    allItemsSelectionLabel: ({ selectedItems }) =>
                                        `${selectedItems.length} Item selected`,
                                    itemSelectionLabel: ({ selectedItems }, item) =>
                                        `${selectedItems.includes(item) ? 'Deselect' : 'Select'} ${item.title}`,
                                }}
                            />
                        </div>
                            )}
                            {type === TABLE_TYPE && (
                                <div>
                                    <Table
                                        header={
                                            <Header>
                                                Logs
                                            </Header>
                                        }
                                        columnDefinitions={logViewerColumnDefintions}
                                        stickyHeader={true}
                                        resizableColumns={false}
                                        loading={getFilteredDataLoading}
                                        visibleColumns={preferences.visibleContent}
                                        items={items}
                                        pagination={
                                            <Pagination
                                                {...paginationProps}
                                                ariaLabels={{
                                                    nextPageLabel: 'Next page',
                                                    previousPageLabel: 'Previous page',
                                                    pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
                                                }}
                                            />
                                        }
                                        preferences={
                                            <Preferences
                                                preferences={preferences}
                                                setPreferences={setPreferences}
                                                disabled={false}
                                                pageSizeOptions={PAGE_SIZE_OPTIONS}
                                                visibleContentOptions={LOG_VIEWER_VISIBLE_CONTENT_OPTIONS}
                                            />
                                        }
                                    />
                                </div>
                            )}
                        </SpaceBetween>
                    </Container>
            </SpaceBetween>
        </React.Fragment>
    );
};

export { OnDemandAggregationsViewer };
