import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks/dist/cjs/interfaces';

export const columnDefinitions = [
  {
    id: 'create_date',
    header: 'Creation Date',
    cell: (e) => e.create_date,
    sortingField: 'create_date',
  },
  {
    id: 'short_description',
    header: 'Short Description',
    cell: (e) => (
      <a
        href={'https://t.corp.amazon.com/' + e.id}
        target="_blank"
        rel="noopener noreferrer"
      >
        {' '}
        {e.short_description}{' '}
      </a>
    ),
    sortingField: 'short_description',
  },
  {
    id: 'status',
    header: 'Status',
    cell: (e) => e.status,
    sortingField: 'status',
  },
];

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Options',
    options: [
      { id: 'create_date', label: 'Creation Date' },
      { id: 'short_description', label: 'Short Description' },
      { id: 'status', label: 'Status' },
    ],
  },
];

export const FILTERING_PROPERTIES: readonly PropertyFilterProperty[] = [
  {
    propertyLabel: 'Creation Date',
    key: 'create_date',
    groupValuesLabel: 'Creation Date Values',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'Short Description',
    key: 'short_description',
    groupValuesLabel: 'Short Description Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Status',
    key: 'status',
    groupValuesLabel: 'Status Values',
    operators: [':', '!:', '=', '!='],
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 50, label: '50 Tickets' },
  { value: 100, label: '100 Tickets' },
  { value: 500, label: '500 Tickets' },
  { value: 1000, label: '1000 Tickets' },
  { value: 8000, label: '8000 Tickets' },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 10,
  visibleContent: ['create_date', 'short_description', 'status'],
  wraplines: true,
};
