import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../models/types';
 
export interface GetLogsVars {
    domainId: string;
    startTime: string;
    endTime: string;
}

const GET_LOGS = gql`
    query GetLogs(
      $domainId: String!,
      $startTime: String,
      $endTime: String
    ) {
        getLogs(
          domainId: $domainId, 
          startTime: $startTime,
          endTime: $endTime
        )
    }
`;
 
export const useGetLogsQuery = () => {
    return useLazyQuery<AnyObject, GetLogsVars>(GET_LOGS, {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
    });
};