import { useState } from 'react';
import {
  convertTZ,
  globalRequestColumnDefinitions,
  GLOBAL_DEFAULT_PREFERENCES,
  GLOBAL_QUEUE_FILTERING_PROPERTIES,
  GLOBAL_QUEUE_PAGE_SIZE_OPTIONS,
  GLOBAL_QUEUE_VISIBLE_CONTENT_OPTIONS
} from './constants'
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  EmptyState,
  getFilterCounterText,
  Preferences,
} from '../../utils/tablePreferences';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from '../../utils/constants';
import { Button, ExpandableSection, Header, Pagination, PropertyFilter, SpaceBetween, Table } from '@amzn/awsui-components-react';
import { get } from 'lodash';
import moment from 'moment';

const GlobalQueue = (props) => {

  const [preferences, setPreferences] = useState(GLOBAL_DEFAULT_PREFERENCES);

  const logsItems = get(props?.globalLogRequests,'getLogs.globalPendingRequests.requests',[])

  const queueNumber = get(props?.globalLogRequests,'getLogs.currentDomainPendingRequestStatus.highestNumberInRequestQueue','-')

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(props.isLoading ? [] : logsItems, {
    propertyFiltering: {
      filteringProperties: GLOBAL_QUEUE_FILTERING_PROPERTIES,
      empty: (
        <EmptyState
          title="No Logs Requested"
          subtitle="No logs to display."
          action={<span></span>}
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can’t find a match."
          action={
            <Button onClick={() => actions.setFiltering('')}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  if(!(globalRequestColumnDefinitions.filter(c => c.id==='log_hour').length > 0))
  {
    globalRequestColumnDefinitions.splice(1,0,
      {
        id: "log_hour",
        header: "Log Hour",
        cell: (e) => {
          var timestamp = e.hourly_timestamp.split(" ")[0] || "-"
          var unix_timestamp = moment(timestamp).unix()
          return convertTZ(unix_timestamp, props.selectedBrowseTimeZoneOption)
        },
        sortingField: "log_hour"
      },
    )
  }

  const handleRefreshData = (event) => {
    event.stopPropagation()
    props.refreshData()
    setIsExpanded(isExpanded)
  } 

  const [isExpanded, setIsExpanded] = useState(false)



  return (
    <ExpandableSection
      header={
        <Header 
          counter={`(${items.length})`}
          variant="h2" 
          description={`Highest rank of Log request for current domain in global request queue: ${queueNumber}`}
          actions={
            <SpaceBetween direction="horizontal" size="l">
              <Button iconName="refresh" onClick={handleRefreshData} />
            </SpaceBetween>
          }
        >
          Global Log Requests
        </Header> } 
      variant="container"
      expanded={isExpanded}
      onChange={ ({detail}) => setIsExpanded(detail.expanded)}
    >
      <Table
        {...collectionProps}
        columnDefinitions={globalRequestColumnDefinitions}
        stickyHeader={true}
        resizableColumns={false}
        wrapLines={preferences.wraplines}
        loading={props.isLoading}
        loadingText={
          props.isLoading ? 'Loading logs...' : 'There are no logs requested.'
        }
        visibleColumns={preferences.visibleContent}
        items={items}
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
          />
        }
        filter={
          <div className="input-container">
            <PropertyFilter
              className="input-filter"
              i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
              {...propertyFilterProps}
              countText={getFilterCounterText(filteredItemsCount)}
            />
          </div>
        }
        preferences={
          <Preferences
            preferences={preferences}
            setPreferences={setPreferences}
            disabled={false}
            pageSizeOptions={GLOBAL_QUEUE_PAGE_SIZE_OPTIONS}
            visibleContentOptions={GLOBAL_QUEUE_VISIBLE_CONTENT_OPTIONS}
          />
        }
      />
    </ExpandableSection>
  )
}

export { GlobalQueue };