import { Link, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import CopyText from '../../utils/copy-text';

const SSHBastionDetails = (props) => {
  return (
    <SpaceBetween direction="horizontal" size="xxxs">
      <Link
        externalIconAriaLabel="Opens in a new tab"
        href={props.e.bastion.sshWeb}
        target="_blank"
      >
        Web
      </Link>
      {' | '}
      <CopyText
        copyText={props.e.bastion.sshCommand}
        copyButtonLabel="CLI"
        successText="CLI command copied"
        errorText="CLI command failed to copy"
      />
      {' | '}
      <CopyText
        copyText={props.e.bastion.scpCommand}
        copyButtonLabel="SCP"
        successText="CLI command copied"
        errorText="CLI command failed to copy"
      />
    </SpaceBetween>
  );
};

export { SSHBastionDetails };
