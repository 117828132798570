import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Button,
  DateRangePicker,
  Header,
  Pagination,
  Popover,
  PropertyFilter,
  Select,
  SpaceBetween,
  Table,
} from '@amzn/awsui-components-react';
import { cloneDeep, get } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { LogRedactionFeedbackWrapper } from '../../Components/LogRedaction/LogRedactionFeedbackWrapper';
import { AppContext } from '../../utils/appContext';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from '../../utils/constants';
import {
  EmptyState,
  getFilterCounterText,
  Preferences,
} from '../../utils/tablePreferences';
import {
  accountMapping,
  columnDefinitions,
  convertTZ,
  DEFAULT_PREFERENCES,
  FILTERING_PROPERTIES,
  i18nStrings,
  isValidRequestRange,
  PAGE_SIZE_OPTIONS,
  relativeOptions,
  timezoneOptions,
  VISIBLE_CONTENT_OPTIONS,
} from './constants';
import { GlobalQueue } from './GlobalQueue';
import { useGetLogsQuery } from './useGetLogsQuery';
import { isError } from "../../ErrorHandler/apiErrorHandler";
import { ErrorMessageComponent } from "../../ErrorHandler/errorMessageComponent";
import { ApiList } from "../../ErrorHandler/utils";

const { forwardRef, useImperativeHandle } = React;

const BrowseLogs = forwardRef((props: any, ref) => {
  const appContext = useContext(AppContext);
  const stage = appContext.stage;
  const region = appContext.region.publicRegion;
  const account = get(accountMapping, `${stage}.${region}`, []);
  const [errorMessage, setErrorMessage] = useState(null)
  const params = useParams();
  const clientId = params['clientId'];
  const domainName = params['domainName'];

  const [selectedBrowseTimeZoneOption, setSelectedBrowseTimeZoneOption] =
    React.useState({
      inputDisplay: 'UTC',
      label: 'UTC',
      value: 'UTC',
      code: 'UTC',
      offset: '0000',
      mins: 0,
    });

  const handleBrowseTimezoneChange = (selectedOption) => {
    setSelectedBrowseTimeZoneOption(selectedOption);
  };

  let url = useLocation().search;
  url = url.replaceAll('_', ':');
  const browseWindow = url
    ? Object.fromEntries(new URLSearchParams(url))
    : {
        type: 'relative',
        amount: 1,
        unit: 'year',
      };
  const [browseDate, setBrowseDate] = useState<any>(browseWindow);

  const [getLogs, { loading: logsLoading, data: logs, error: error }] = useGetLogsQuery();

  useEffect(() => {
    var startMoment, endMoment;

    if ('absolute' === browseDate.type) {
      startMoment = moment(browseDate.startDate);
      endMoment = moment(browseDate.endDate);
    } else if ('relative' === browseDate.type) {
      startMoment = moment().subtract(browseDate.amount, browseDate.unit);
      endMoment = moment();
    }

    const startTimeStamp = startMoment.utc().format();
    const endTimeStamp = endMoment.utc().format();

    getLogs({
      variables: {
        domainId: clientId + ':' + domainName,
        startTime: startTimeStamp,
        endTime: endTimeStamp,
      },
    });
  }, [
    browseDate.amount,
    browseDate.endDate,
    browseDate.startDate,
    browseDate.type,
    browseDate.unit,
    clientId,
    domainName,
    getLogs,
  ]);

  const isErrorPresent = isError(error)
   useEffect(() => {
    if (isErrorPresent) {
      setErrorMessage("Unable to get Logs. Failed with " + error.message);
    }
  }, [isErrorPresent, error]);

  const handleBrowseDateChange = ({ detail }) => {
    setBrowseDate(detail.value);
    var startMoment, endMoment;

    if ('absolute' === detail.value.type) {
      startMoment = moment(detail.value.startDate);
      endMoment = moment(detail.value.endDate);
    } else if ('relative' === detail.value.type) {
      startMoment = moment().subtract(detail.value.amount, detail.value.unit);
      endMoment = moment();
    }

    const startTimeStamp = startMoment.utc().format();
    const endTimeStamp = endMoment.utc().format();

    getLogs({
      variables: {
        domainId: clientId + ':' + domainName,
        startTime: startTimeStamp,
        endTime: endTimeStamp,
      },
    });

    let browseDateObj = cloneDeep(detail);
    if ('absolute' === browseDateObj.value.type) {
      browseDateObj.value.startDate = browseDateObj.value.startDate.replaceAll(
        ':',
        '_'
      );
      browseDateObj.value.endDate = browseDateObj.value.endDate.replaceAll(
        ':',
        '_'
      );
    }

    const queryParams = new URLSearchParams(browseDateObj.value).toString();

    window.history.replaceState(null, null, `?${queryParams}`);
  };

  if (!(columnDefinitions.filter((c) => c.id === 'log_group').length > 0)) {
    columnDefinitions.splice(0, 0, {
      id: 'log_group',
      header: 'Log Group',
      cell: (e) => {
        if (e.status === '3-Done' && e.logFiles) {
          const files = e.logFiles;
          if (files.length > 0) {
            return (
              (
                <Popover
                  dismissAriaLabel="Close"
                  fixedWidth
                  header={e.logGroup}
                  position="right"
                  size="large"
                  triggerType="text"
                  renderWithPortal={true}
                  content={
                    <div style={{ overflowY: 'auto', maxHeight: '500px' }}>
                      {files.map((file) => (
                        <SpaceBetween direction="horizontal" size="l">
                          {/* <a id="myLink" onClick={() => history.push(`/${clientId}/${domainName}/log/${'randomID'}`)} style={{whiteSpace:"pre-line"}}> {file.name || "-"} </a> */}
                          <Link
                            to={{
                              pathname: `/${clientId}/${domainName}/log/${file}`,
                            }}
                            target="_blank"
                          >
                            {file.split('/').slice(-1)[0] || '-'}
                          </Link>
                          <p style={{ float: 'right' }}> {file.status} </p>
                        </SpaceBetween>
                      ))}
                    </div>
                  }
                >
                  {e.hourly_timestamp.split(' ')[1] || '-'}
                </Popover>
              ) || '--'
            );
          } else {
            return <p>{e.hourly_timestamp.split(' ')[1] || '-'}</p>;
          }
        } else {
          return <p>{e.hourly_timestamp.split(' ')[1] || '-'}</p>;
        }
      },
      sortingField: 'log_group',
    });
  }

  const handleBrowseLogsRefresh = () => {
    handleBrowseDateChange({ detail: { value: browseDate } });
  };

  useImperativeHandle(ref, () => ({
    callHandleBrowseDateChanged: () => {
      handleBrowseDateChange({ detail: { value: browseDate } });
    },
  }));

  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);

  const logsItems = get(logs, 'getLogs.currentDomain.requests', []);

  if (logsItems.length > 0) {
    for (let i = 0; i < logsItems.length; i++) {
      if ('logFiles' in logsItems[i] && logsItems[i]['logFiles'].length > 0) {
        let files = logsItems[i]['logFiles'];
        if (files[0].split('/').length !== 9) {
          break;
        } else {
          logsItems[i]['s3Path'] = cloneDeep(logsItems[i]['logFiles']);
          for (let j = 0; j < files.length; j++) {
            let file = files[j];
            const filePath = file.split('/');
            let [year, month, day, hour, logGroup, logFile] = filePath.slice(3);
            year = year.split('=')[1];
            month = month.split('=')[1];
            day = day.split('=')[1];
            hour = hour.split('=')[1];
            logsItems[i]['logFiles'][j] = [
              year,
              month,
              day,
              hour,
              logGroup,
              logFile,
            ].join('/');
          }
        }
      }
    }
  }

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(logsLoading ? [] : logsItems, {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES,
      empty: (
        <EmptyState
          title="No Logs Requested"
          subtitle="No logs to display."
          action={<span></span>}
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can’t find a match."
          action={
            <Button onClick={() => actions.setFiltering('')}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  return (
    <SpaceBetween size="l" direction="vertical">
        {errorMessage ? (
        <ErrorMessageComponent errorMessage={errorMessage} apiName={ApiList.BROWSE_LOGS}/>
      ) : (
        <>
      <GlobalQueue
        refreshData={handleBrowseLogsRefresh}
        isLoading={logsLoading}
        globalLogRequests={logs}
        selectedBrowseTimeZoneOption={selectedBrowseTimeZoneOption}
      />
      <Table
        {...collectionProps}
        header={
          <Header
            counter={`(${items.length})`}
            actions={
              <SpaceBetween direction="horizontal" size="l">
                <LogRedactionFeedbackWrapper
                  buttonContent="Downloading Instructions"
                  isTot={true}
                  domainId={clientId + ':' + domainName}
                  account={account}
                />
                <DateRangePicker
                  onChange={handleBrowseDateChange}
                  value={browseDate}
                  relativeOptions={relativeOptions}
                  i18nStrings={i18nStrings}
                  placeholder="Filter by a date and time range"
                  showClearButton={false}
                  isValidRange={isValidRequestRange}
                  timeOffset={selectedBrowseTimeZoneOption.mins}
                />
                <Select
                  selectedOption={selectedBrowseTimeZoneOption}
                  onChange={({ detail }) =>
                    handleBrowseTimezoneChange(detail.selectedOption)
                  }
                  options={timezoneOptions}
                  selectedAriaLabel="Selected"
                />
                <Button iconName="refresh" onClick={handleBrowseLogsRefresh} />
              </SpaceBetween>
            }
          >
            Browse Logs
          </Header>
        }
        columnDefinitions={[
          {
            id: 'log_hour',
            header: 'Log Hour',
            cell: (e) => {
              var timestamp = e.hourly_timestamp.split(' ')[0] || '-';
              var unix_timestamp = moment(timestamp).unix();
              return convertTZ(unix_timestamp, selectedBrowseTimeZoneOption);
            },
            sortingField: 'log_hour',
          },
          ...columnDefinitions,
        ]}
        stickyHeader={true}
        resizableColumns={false}
        wrapLines={preferences.wraplines}
        loading={logsLoading}
        loadingText={
          logsLoading ? 'Loading logs...' : 'There were no logs requested.'
        }
        visibleColumns={preferences.visibleContent}
        items={items}
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
          />
        }
        filter={
          <div className="input-container">
            <PropertyFilter
              className="input-filter"
              i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
              {...propertyFilterProps}
              countText={getFilterCounterText(filteredItemsCount)}
            />
          </div>
        }
        preferences={
          <Preferences
            preferences={preferences}
            setPreferences={setPreferences}
            disabled={false}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            visibleContentOptions={VISIBLE_CONTENT_OPTIONS}
          />
        }
      />
       </>
      )}
    </SpaceBetween>
  );
});

export { BrowseLogs };
