import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Alert,
  Box,
  Button,
  DateRangePicker,
  DateRangePickerProps,
  Header,
  Input,
  Link,
  Pagination,
  PropertyFilter,
  SpaceBetween,
  Table,
} from '@amzn/awsui-components-react';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useFlyout } from '../../../hooks/useFlyout';
import { CommonDomainProps } from '../../../models/types';
import {
  getFilterCounterText,
  Preferences,
} from '../../../utils/tablePreferences';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from './../../../utils/constants';
import {
  columns,
  DEFAULT_PREFERENCES,
  FILTERING_PROPERTIES,
  i18nStrings,
  PAGE_SIZE_OPTIONS,
  RELATIVE_OPTIONS,
  VISIBLE_CONTENT_OPTIONS,
} from './constants';
import { useTmsQueryArchive } from './hooks/useTmsQueryArchive';
import { TmsDetails } from './TmsDetails';
import { isError } from "../../../ErrorHandler/apiErrorHandler";
import { ErrorMessageComponent } from "../../../ErrorHandler/errorMessageComponent";
import { ApiList }from "../../../ErrorHandler/utils";

const TMSIssuesArchive = (props: CommonDomainProps) => {
  const setFlyout = useFlyout();
  const [errorMessage, setErrorMessage] = useState(null);
  const [currentDate, setCurrentDate] = React.useState<any>({
    type: 'relative',
    amount: 1,
    unit: 'week',
  });

  const [valuePrefix, setValuePrefix] = React.useState('');

  const domainIdentifier = props.domainIdentifier;

  var [getTMSQueryArchive, { loading, data: domain, error }] =
    useTmsQueryArchive();

  const isErrorPresent = isError(error)
   useEffect(() => {
    if (isErrorPresent) {
        setErrorMessage("Unable to get archived TMS issue. Failed with " + error.message);
    }
  }, [isErrorPresent, error]);


  useEffect(() => {
    var firstday = moment(Date.now() - 7 * 24 * 60 * 60 * 1000);
    var lastday = moment(new Date());
    getTMSQueryArchive({
      variables: {
        domainIdentifier: domainIdentifier,
        startTime: firstday.toISOString(),
        endTime: lastday.toISOString(),
        issuePrefix: valuePrefix,
      },
    });
  }, [domainIdentifier, getTMSQueryArchive, valuePrefix]);

  const handlePrefixChange = () => {
    var startMoment, endMoment;

    if ('absolute' === currentDate.type) {
      startMoment = moment(currentDate.startDate);
      endMoment = moment(currentDate.endDate);
    } else if ('relative' === currentDate.type) {
      startMoment = moment().subtract(currentDate.amount, currentDate.unit);
      endMoment = moment();
    }

    getTMSQueryArchive({
      variables: {
        domainIdentifier: domainIdentifier,
        startTime: startMoment.toISOString(),
        endTime: endMoment.toISOString(),
        issuePrefix: valuePrefix,
      },
    });
  };

  const handleDateChange = ({ detail }) => {
    var startMoment, endMoment;

    if ('absolute' === detail.value.type) {
      startMoment = moment(detail.value.startDate);
      endMoment = moment(detail.value.endDate);
    } else if ('relative' === detail.value.type) {
      startMoment = moment().subtract(detail.value.amount, detail.value.unit);
      endMoment = moment();
    }

    getTMSQueryArchive({
      variables: {
        domainIdentifier: domainIdentifier,
        startTime: startMoment.toISOString(),
        endTime: endMoment.toISOString(),
        issuePrefix: valuePrefix,
      },
    });

    setCurrentDate(detail.value);
  };

  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(domain ? get(domain, 'domain.tmsIssuesArchive', []) : [], {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES,
      empty: (
          <EmptyState
            title="No TMS issues"
            subtitle="No TMS Archive issues to display."
            action={<span></span>}
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can’t find a match."
          action={
            <Button onClick={() => actions.setFiltering('')}>
              Clear filter
            </Button>
          }
        />
      ),
    },

    pagination: { pageSize: preferences.pageSize },
    sorting: {},
  });

  function EmptyState({ title, subtitle, action }) {
    return (
      <Box textAlign="center" color="inherit">
        <Box variant="strong" textAlign="center" color="inherit">
          {title}
        </Box>
        <Box variant="p" padding={{ bottom: 's' }} color="inherit">
          {subtitle}
        </Box>
        {action}
      </Box>
    );
  }
  const isValidRange: DateRangePickerProps.ValidationFunction = (e) => {
    const timeTmsArchive = (start, end) => {
      const a = moment(end).isBefore(moment(start));
      return a;
    };
    const valid_range = (start, end) => {
      const diff =
        moment(end).toDate().getTime() - moment(start).toDate().getTime();
      const diff_days = diff / (1000 * 3600 * 24);
      console.log('Total days between start and end date ', diff_days);
      return diff_days;
    };
    if ('absolute' === e.type) {
      const [a] = e.startDate.split('T'),
        [n] = e.endDate.split('T');
      if (!a || !n)
        return {
          valid: false,
          errorMessage:
            'The selected date range is incomplete. Select a start and end date for the date range.',
        };
      if (
        moment(e.startDate).isAfter(moment()) ||
        moment(e.endDate).isAfter(moment())
      )
        return {
          valid: false,
          errorMessage:
            'Either of start date or end date cannot be future date.',
        };
      if (valid_range(e.startDate, e.endDate) > 31) {
        return {
          valid: false,
          errorMessage:
            'Difference between start and end date cannot be greater than 31 days',
        };
      }
      if (timeTmsArchive(e.startDate, e.endDate))
        return {
          valid: !1,
          errorMessage: 'The end date must be greater than the start date.',
        };
    } else if ('relative' === e.type) {
      if (isNaN(e.amount))
        return {
          valid: !1,
          errorMessage:
            'The selected date range is incomplete. Specify a duration for the date range.',
        };
    }
    return { valid: !0 };
  };
  return (
      <div>
      {errorMessage ? (
        <ErrorMessageComponent errorMessage={errorMessage} apiName={ApiList.TMS_ISSUES_ARCHIVE}/>
      ) : (
    <Table
      {...collectionProps}
      header={
        <div>
          <Header
            actions={
              <SpaceBetween direction="horizontal" size="s">
                <label>Search by Prefix:</label>
                <Input
                  onChange={({ detail }) => setValuePrefix(detail.value)}
                  value={valuePrefix}
                />
                <DateRangePicker
                  onChange={handleDateChange}
                  value={currentDate}
                  relativeOptions={RELATIVE_OPTIONS}
                  isValidRange={isValidRange}
                  i18nStrings={i18nStrings}
                  placeholder="Filter by a date and time range"
                />
                <Button variant="primary" onClick={handlePrefixChange}>
                  Fetch Issues
                </Button>
              </SpaceBetween>
            }
          ></Header>
        </div>
      }
      columnDefinitions={[
        {
          id: 'issueType',
          sortingField: 'issueType',
          name: 'Issue type',
          header: 'Issue type',
          cell: (e) => (
            <Button
              variant="link"
              onClick={() =>
                setFlyout({
                  title: e.issueType,
                  size: 'm',
                  content: <TmsDetails description={e.details} />,
                })
              }
            >
              <span style={{ color: '#00a1c9' }}>{e.issueType}</span>
            </Button>
          ),
        },
        ...columns,
      ]}
      stickyHeader={true}
      resizableColumns={true}
      wrapLines={preferences.wraplines}
      loading={loading}
      loadingText={
        loading ? 'Loading TMS Archive issues...' : 'No TMS issues found.'
      }
      visibleColumns={preferences.visibleContent}
      items={items}
      pagination={
        <Pagination
          {...paginationProps}
          ariaLabels={{
            nextPageLabel: 'Next page',
            previousPageLabel: 'Previous page',
            pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
          }}
        />
      }
      filter={
        <PropertyFilter
          {...propertyFilterProps}
          i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
          countText={getFilterCounterText(filteredItemsCount)}
        />
      }
      preferences={
        <Preferences
          preferences={preferences}
          setPreferences={setPreferences}
          disabled={false}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          visibleContentOptions={VISIBLE_CONTENT_OPTIONS}
        />
      }
    />
      )}
    </div>
  );
};

export { TMSIssuesArchive };
