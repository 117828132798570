// This component renders the Index shard storage skew table
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Button,
  ExpandableSection,
  Header,
  Pagination,
  SpaceBetween,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { AnyObject } from '../../../../../models/types';
import {
  DEFAULT_PREFERENCES,
  indexShardStorageColumns,
  PAGE_SIZE_OPTIONS,
  VISIBLE_CONTENT_OPTIONS,
} from '../columns';
import {
  EmptyState,
  getFilterCounterText,
  Preferences,
} from './../../../../../utils/tablePreferences';

interface IndexShardStorageSkewInfoProps {
  indexShardStorageSkew: Array<Array<object>>;
  dropdownsInfo: Array<Array<Array<boolean>>>;
  diNumber: number;
  setDropdownsInfo: (state: Array<Array<Array<boolean>>>) => void;
  isLoading: boolean;
}

const IndexShardStorageSkewInfo = (props: IndexShardStorageSkewInfoProps) => {
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(
    props.indexShardStorageSkew[props.diNumber].length > 0
      ? props.indexShardStorageSkew[props.diNumber][0]['valuesForTable']
      : [],
    {
      filtering: {
        empty: (
          <EmptyState
            title="No Skew"
            subtitle="No issues to display."
            action={<span></span>}
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={
              <Button onClick={() => actions.setFiltering('')}>
                Clear filter
              </Button>
            }
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
    }
  );
  return (
    <React.Fragment>
      {props.indexShardStorageSkew[props.diNumber].length > 0 && (
        <SpaceBetween direction="vertical" size="xs">
          {props.indexShardStorageSkew[props.diNumber].map(
            (indexType: AnyObject, j) => (
              <ExpandableSection
                header={<Header variant="h2">Index shard storage skew</Header>}
              >
                <Table
                  header={
                    <Header variant="h3">
                      {
                        <span>
                          {indexType.type.charAt(0).toUpperCase() +
                            indexType.type.slice(1)}{' '}
                          {indexType.desc}
                        </span>
                      }
                    </Header>
                  }
                  {...collectionProps}
                  columnDefinitions={indexShardStorageColumns}
                  stickyHeader={true}
                  wrapLines={preferences.wraplines}
                  loading={props.isLoading}
                  loadingText={props.isLoading ? 'Loading' : 'No results'}
                  visibleColumns={preferences.visibleContent}
                  items={items}
                  pagination={
                    <Pagination
                      {...paginationProps}
                      ariaLabels={{
                        nextPageLabel: 'Next page',
                        previousPageLabel: 'Previous page',
                        pageLabel: (pageNumber) =>
                          `Page ${pageNumber} of all pages`,
                      }}
                    />
                  }
                  filter={
                    <TextFilter
                      {...filterProps}
                      countText={getFilterCounterText(filteredItemsCount)}
                      filteringAriaLabel="Filter instances"
                    />
                  }
                  preferences={
                    <Preferences
                      preferences={preferences}
                      setPreferences={setPreferences}
                      disabled={false}
                      pageSizeOptions={PAGE_SIZE_OPTIONS}
                      visibleContentOptions={VISIBLE_CONTENT_OPTIONS}
                    />
                  }
                />
              </ExpandableSection>
            )
          )}
        </SpaceBetween>
      )}
    </React.Fragment>
  );
};

export { IndexShardStorageSkewInfo };
