import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks/dist/cjs/interfaces';
import { DateRangePickerProps } from '@amzn/awsui-components-react';
import moment from 'moment';
import { Link } from 'react-router-dom';

export const JunoNavbarTitle = 'OpenSearch Serverless';

export const JunoNavbarOptions = [
  {
    label: 'Report a bug',
    value: 'https://tiny.amazon.com/r2102p8o/issuamazissucrea',
  },
  {
    label: 'New Feature Request',
    value: 'https://tiny.amazon.com/1563wef8c',
  },
  {
    label: 'Release History',
    value:
      'https://w.amazon.com/bin/view/AWS/SearchServices/OpsTumbler/Release',
  },
  {
    label: 'Developer Guide',
    value:
      'https://w.amazon.com/bin/view/AWS/SearchServices/OpsTumbler/DeveloperGuide',
  },
  {
    label: 'User Guide',
    value:
      'https://w.amazon.com/bin/view/AWS/SearchServices/OpsTumbler/UserGuide',
  },
];

// ========================= API PATHS =================

export const JUNO_BASE_PATH = '/juno';

export const JUNO_ACCOUNT_OVERVIEW_PATH = `${JUNO_BASE_PATH}/:awsAccountId`;
export const JUNO_COLLECTION_OVERVIEW_PATH = `${JUNO_BASE_PATH}/:awsAccountId/:collectionId`;
export const JUNO_WORKER_OVERVIEW_PATH = `${JUNO_BASE_PATH}/worker/:awsAccountId/:dataPlaneType/:workerId`;
export const JUNO_PLACEMENTCONSTRAINT_OVERVIEW_PATH = `${JUNO_BASE_PATH}/pci/:awsAccountId/:placementConstraintId`;
export const JUNO_ACCESS_POLICY_DETAILS_PATH = `${JUNO_BASE_PATH}/:awsAccountId/policy/access/:policyType/:policyName`;
export const JUNO_SECURITY_POLICY_DETAILS_PATH = `${JUNO_BASE_PATH}/:awsAccountId/policy/security/:policyType/:policyName`;
export const JUNO_SECURITY_CONFIG_DETAILS_PATH = `${JUNO_BASE_PATH}/:awsAccountId/config/:configId`;

export const JUNO_LOG_PATH = `${JUNO_BASE_PATH}/log`;
export const JUNO_DETAIL_LOG_RECORD_PATH = `${JUNO_BASE_PATH}/log/:recordID+`;

export const JUNO_OPS_DASHBOARD_PATH = `${JUNO_BASE_PATH}/beholder/dashboards`;

// =======================================================

export const junoSupportedRegionStage = {
  alpha: ['iad', 'pdx'],
  beta: ['iad', 'pdx'],
  gamma: ['iad', 'pdx'],
  prod: ['iad', 'dub', 'pdx', 'nrt', 'fra', 'sin', 'syd', 'cmh'],
};

export const i18nStrings = {
  todayAriaLabel: 'Today',
  nextMonthAriaLabel: 'Next month',
  previousMonthAriaLabel: 'Previous month',
  customRelativeRangeDurationLabel: 'Duration',
  customRelativeRangeDurationPlaceholder: 'Enter duration',
  customRelativeRangeOptionLabel: 'Custom range',
  customRelativeRangeOptionDescription: 'Set a custom range in the past',
  customRelativeRangeUnitLabel: 'Unit of time',
  formatRelativeRange: (e) => {
    const t = 1 === e.amount ? e.unit : e.unit + 's';
    return `Last ${e.amount} ${t}`;
  },
  formatUnit: (e, t) => (1 === t ? e : e + 's'),
  relativeModeTitle: 'Relative range',
  absoluteModeTitle: 'Absolute range',
  relativeRangeSelectionHeading: 'Choose a range',
  startDateLabel: 'Start date',
  endDateLabel: 'End date',
  startTimeLabel: 'Start time',
  endTimeLabel: 'End time',
  clearButtonLabel: 'Clear',
  cancelButtonLabel: 'Cancel',
  applyButtonLabel: 'Apply',
};

export const relativeOptions: ReadonlyArray<DateRangePickerProps.RelativeOption> =
  [
    {
      key: 'previous-5-hours',
      amount: 5,
      unit: 'hour',
      type: 'relative',
    },
    {
      key: 'previous-20-hours',
      amount: 20,
      unit: 'hour',
      type: 'relative',
    },
    {
      key: 'previous-3-days',
      amount: 3,
      unit: 'day',
      type: 'relative',
    },
    {
      key: 'previous-1-week',
      amount: 1,
      unit: 'week',
      type: 'relative',
    },
    {
      key: 'previous-1-month',
      amount: 1,
      unit: 'month',
      type: 'relative',
    },
  ];

export const isValidRequestRange: DateRangePickerProps.ValidationFunction = (
  e
) => {
  const t = (start, end) => {
    const a = moment(end).isBefore(moment(start));
    return a;
  };

  // var startMoment, endMoment;
  // if ('absolute' === e.type) {
  //   startMoment = moment(e.startDate);
  //   endMoment = moment(e.endDate);
  // } else if ('relative' === e.type) {
  //   startMoment = moment().subtract(e.amount, e.unit);
  //   endMoment = moment();
  // }
  if ('absolute' === e.type) {
    const [a] = e.startDate.split('T'),
      [n] = e.endDate.split('T');
    if (!a || !n)
      return {
        valid: !1,
        errorMessage:
          'The selected date range is incomplete. Select a start and end date for the date range.',
      };
    if (
      moment(e.startDate).isAfter(moment()) ||
      moment(e.endDate).isAfter(moment())
    )
      return {
        valid: !1,
        errorMessage: 'Either of start date or end date cannot be future date.',
      };
    if (t(e.startDate, e.endDate))
      return {
        valid: !1,
        errorMessage: 'The end date must be greater than the start date.',
      };
  } else if ('relative' === e.type) {
    if (isNaN(e.amount))
      return {
        valid: !1,
        errorMessage:
          'The selected date range is incomplete. Specify a duration for the date range.',
      };
  }
  return { valid: !0 };
};

export const logGroupOptions = [
  {
    label: 'JunoServiceGateway',
    options: [
      {
        label: 'EnvoyProxyMetrics',
        value: 'JunoServiceGateway-EnvoyProxyMetrics',
      },
    ],
  },
  {
    label: 'JunoSecurityAuthService',
    options: [
      {
        label: 'ApplicationLogs-Cell1',
        value: 'GAMMA-us-east-1-JunoSecurityAuthService-ApplicationLogs-Cell1',
      },
      {
        label: 'ApplicationLogs-Cell2',
        value: 'GAMMA-us-east-1-JunoSecurityAuthService-ApplicationLogs-Cell2',
      },
    ],
  },
  {
    label: 'JunoSearchWorker',
    options: [
      { label: 'Deprecation', value: 'JunoSearchWorker-Deprecation' },
      { label: 'MetricLogs', value: 'JunoSearchWorker-MetricLogs' },
      { label: 'Search-SlowLogs', value: 'JunoSearchWorker-Search-SlowLogs' },
      { label: 'ServerLogs', value: 'JunoSearchWorker-ServerLogs' },
      { label: 'ServiceMetrics', value: 'JunoSearchWorker-ServiceMetrics' },
    ],
  },
  {
    label: 'JunoSearchCoordinator',
    options: [
      {
        label: 'BeholderMetrics',
        value: 'JunoSearchCoordinator-BeholderMetrics',
      },
      { label: 'Deprecation', value: 'JunoSearchCoordinator-Deprecation' },
      { label: 'MetricLogs', value: 'JunoSearchCoordinator-MetricLogs' },
      { label: 'ServerLogs', value: 'JunoSearchCoordinator-ServerLogs' },
      {
        label: 'ServiceMetrics',
        value: 'JunoSearchCoordinator-ServiceMetrics',
      },
    ],
  },
  {
    label: 'JunoIndexingCoordinator',
    options: [
      {
        label: 'AppContainer-STDOUT',
        value: 'gamma-us-east-1-JunoIndexingCoordinator-AppContainer-STDOUT',
      },
      {
        label: 'ApplicationLogs',
        value: 'gamma-us-east-1-JunoIndexingCoordinator-ApplicationLogs',
      },
      {
        label: 'Deprecation',
        value: 'gamma-us-east-1-JunoIndexingCoordinator-Deprecation',
      },
      {
        label: 'ServiceMetrics',
        value: 'gamma-us-east-1-JunoIndexingCoordinator-ServiceMetrics',
      },
    ],
  },
  {
    label: 'JunoIndexingWorker',
    options: [
      {
        label: 'AppContainer-STDOUT',
        value: 'gamma-us-east-1-JunoIndexingWorker-AppContainer-STDOUT',
      },
      {
        label: 'ApplicationLogs',
        value: 'gamma-us-east-1-JunoIndexingWorker-ApplicationLogs',
      },
      {
        label: 'Indexing-SlowLogs',
        value: 'gamma-us-east-1-JunoIndexingWorker-Indexing-SlowLogs',
      },
      {
        label: 'ServiceMetrics',
        value: 'gamma-us-east-1-JunoIndexingWorker-ServiceMetrics',
      },
    ],
  },
];

export const visibleContent = [
  'ResponseCode',
  'ErrorCount',
  '@timestamp',
  '@message',
  'ServiceName',
  'Path',
  'Method',
  'CollectionId',
  'AwsAccountId',
  'RequestId',
];

export const columnDefinitions = [
  {
    id: '@timestamp',
    header: 'Timestamp',
    cell: (e) => (e['@timestamp'] ? e['@timestamp'] : ''),
    sortingField: '@timestamp',
  },
  {
    id: 'ErrorCount',
    header: 'ErrorCount',
    cell: (e) => (e['ErrorCount'] ? e['ErrorCount'] : ''),
    sortingField: 'ErrorCount',
  },
  {
    id: '@message',
    header: 'Message',
    cell: (e) => (
      <Link
        to={{
          pathname: `/juno/log/${e['@ptr'] ? e['@ptr'] : ''}`,
        }}
        target="_blank"
      >
        {e['@message'] ? e['@message'].substring(0, 150) + ' ...' : ''}
      </Link>
    ),
    sortingField: '@message',
    maxWidth: 'inherit',
  },
  {
    id: 'ResponseCode',
    header: 'ResponseCode',
    cell: (e) => (e['ResponseCode'] ? e['ResponseCode'] : ''),
    sortingField: 'ResponseCode',
  },
  {
    id: 'Path',
    header: 'Path',
    cell: (e) => (e['Path'] ? e['Path'] : ''),
    sortingField: 'Path',
  },
  {
    id: 'Method',
    header: 'Method',
    cell: (e) => (e['Method'] ? e['Method'] : ''),
    sortingField: 'Method',
  },
  {
    id: 'ServiceName',
    header: 'ServiceName',
    cell: (e) => (e['ServiceName'] ? e['ServiceName'] : ''),
    sortingField: 'ServiceName',
  },
  {
    id: 'CollectionId',
    header: 'CollectionId',
    cell: (e) => (e['CollectionId'] ? e['CollectionId'] : ''),
    sortingField: 'CollectionId',
  },
  {
    id: 'AwsAccountId',
    header: 'AwsAccountId',
    cell: (e) => (e['AwsAccountId'] ? e['AwsAccountId'] : ''),
    sortingField: 'AwsAccountId',
  },
  {
    id: 'RequestId',
    header: 'RequestId',
    cell: (e) => (e['RequestId'] ? e['RequestId'] : ''),
    sortingField: 'RequestId',
  },
];

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Options',
    options: visibleContent.map((c) => {
      return { id: c, label: c };
    }),
  },
];

export const FILTERING_PROPERTIES: PropertyFilterProperty[] =
  visibleContent.map((c) => {
    return {
      propertyLabel: c,
      key: c,
      groupValuesLabel: `${c} Values`,
      operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
    };
  });

export const PAGE_SIZE_OPTIONS = [
  { value: 50, label: '50 Items' },
  { value: 100, label: '100 Items' },
  { value: 500, label: '500 Items' },
  { value: 1000, label: '1000 Items' },
  { value: 8000, label: '8000 Items' },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 1000,
  visibleContent: visibleContent,
  wraplines: false,
};
