
export const ErrorCodes = {
  BAD_REQUEST: '400',
  UNAUTHORIZED: '401',
  FORBIDDEN: '403',
  NOT_FOUND: '404',
  TOO_MANY_REQUESTS: '429',
  INTERNAL_SERVER_ERROR: '500',
  NOT_IMPLEMENTED: '501',
  BAD_GATEWAY: '502',
  SERVICE_UNAVAILABLE: '503',
  GATEWAY_TIMEOUT: '504',
};



export const isError = (error: Error) => {
  if (!error) {
    return false;
  }

  const errorMessage = error.message;

  if (errorMessage.includes(ErrorCodes.UNAUTHORIZED)) {
    console.log(ErrorCodes.UNAUTHORIZED, errorMessage);  //add logging later
    window.location.reload(true);
    return false; // Return false after handling '401' error and reloading the page
  } else if (Object.values(ErrorCodes).some(code => errorMessage.includes(code))) {
    console.log('Error', errorMessage); // add logging instead of console here
  } else if (errorMessage.includes('unexpected character') || errorMessage.includes('Unexpected token')) { // Temporary fix to handle gateway timeout exception (need to check server handling)
    console.log(ErrorCodes.BAD_GATEWAY, errorMessage);
  } else if (errorMessage.includes('NetworkError when attempting to fetch resource')) {
    return false;  // No need to set Error state on refreshing page  firefox issue
  } else {
    console.log('Unhandled error:', errorMessage);
  }
  return true;
};
