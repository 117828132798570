import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {JunoConfigDetailsParam} from "../../../models/types";
import {Box, ColumnLayout, Container, Header, SpaceBetween} from "@amzn/awsui-components-react";
import {TimezoneToggle} from "../../../Components/TimezoneToggle";
import {useGetSecurityConfigQuery} from "../../hooks/Account/useGetSecurityConfigQuery";
import ReactJson from "react-json-view"

const ValueWithLabel = ({ label, children }) => (
    <div>
        <Box margin={{ bottom: 'xxxs' }} color="text-label">
            {label}
        </Box>
        <div>{children}</div>
    </div>
);

const SecurityConfigDetails = (props) => {
    const { awsAccountId, configId} = useParams<JunoConfigDetailsParam>();

    const [
        getSecurityConfig,
        { data: SecurityConfigData },
    ] = useGetSecurityConfigQuery();

    useEffect(() => {
        getSecurityConfig({
            variables: {
                awsAccountId: awsAccountId,
                id: decodeURIComponent(configId),
            },
        });

    }, [awsAccountId, configId]);

    const isLoaded = () => {
        if (SecurityConfigData &&
            SecurityConfigData.getSecurityConfig &&
            SecurityConfigData.getSecurityConfig.securityConfigDetail)
        {
            return true;
        }
        return false;
    }

    return (
        <SpaceBetween direction="vertical" size="l">
            <Container header={<Header variant="h2">Config Information</Header>}>
                <ColumnLayout columns={3} variant="text-grid">
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Id">
                            {
                                isLoaded() && SecurityConfigData.getSecurityConfig.securityConfigDetail.id ?
                                    SecurityConfigData.getSecurityConfig.securityConfigDetail.id
                                    :
                                    '--'
                            }
                        </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Creation Date">
                            {
                                isLoaded() && SecurityConfigData.getSecurityConfig.securityConfigDetail.createdDate ?
                                    <TimezoneToggle
                                        date={new Date(SecurityConfigData.getSecurityConfig.securityConfigDetail.createdDate)}/>
                                    :
                                    '--'
                            }
                        </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Last Modified Date">
                            {
                                isLoaded() && SecurityConfigData.getSecurityConfig.securityConfigDetail.lastModifiedDate ?
                                    <TimezoneToggle
                                        date={new Date(SecurityConfigData.getSecurityConfig.securityConfigDetail.lastModifiedDate)}/>
                                    :
                                    '--'
                            }
                        </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Version">
                            {
                                isLoaded() && SecurityConfigData.getSecurityConfig.securityConfigDetail.configVersion ?
                                    SecurityConfigData.getSecurityConfig.securityConfigDetail.configVersion
                                    :
                                    '--'
                            }
                        </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Type">
                            {
                                isLoaded() && SecurityConfigData.getSecurityConfig.securityConfigDetail.type ?
                                    <span>{SecurityConfigData.getSecurityConfig.securityConfigDetail.type}</span>
                                    :
                                    '--'
                            }
                        </ValueWithLabel>
                    </SpaceBetween>
                </ColumnLayout>
            </Container>

            {
                isLoaded() && SecurityConfigData.getSecurityConfig.securityConfigDetail ?
                    <Container header={<Header variant="h2">Policy Document</Header>}>
                        <ReactJson
                            src={SecurityConfigData.getSecurityConfig}
                            name={false}
                            displayDataTypes={false}
                            displayObjectSize={false}
                            enableClipboard={false}
                        />
                    </Container>
                    :
                    ''
            }
        </SpaceBetween>
    )

};

export { SecurityConfigDetails };
