import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../models/types';

export interface GetHawkeyeAnalysisStatusVars {
    analysisId: string;
}

const GET_HAWKEYE_ANALYSIS_STATUS_GQL_QUERY = gql`
    query GetHawkeyeAnalysisStatus(
      $analysisId: String!,
    ) {
        getHawkeyeAnalysisStatus(
            analysisId: $analysisId,
        )
    }
`;
 
export const useGetHawkeyeAnalysisStatusQuery = () => {
    return useLazyQuery<AnyObject, GetHawkeyeAnalysisStatusVars>(GET_HAWKEYE_ANALYSIS_STATUS_GQL_QUERY, {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
    });
};