import { get } from 'lodash';
import moment from 'moment';
import { PlotData } from 'plotly.js';

export const dashboardConfig = (region: string, rootAccountId: string) => {
  return {
    displayMode: 'full',
    widgetMarginX: 10,
    widgetMarginY: 10,
    style: 'polaris',
    internal: {
      accountId: rootAccountId,
      stage: 'prod',
    },
    region,
  };
};

export const METRICS_DURATION = {
  '1h': () => ({
    startTime: moment().subtract(1, 'hours').unix(),
    endTime: moment().unix(),
    period: 300,
  }),
  '3h': () => ({
    endTime: moment().unix(),
    startTime: moment().subtract(3, 'hours').unix(),
    period: 300,
  }),
  '12h': () => ({
    endTime: moment().unix(),
    startTime: moment().subtract(12, 'hours').unix(),
    period: 300,
  }),
  '1d': () => ({
    endTime: moment().unix(),
    startTime: moment().subtract(1, 'days').unix(),
    period: 300,
  }),
  '3d': () => ({
    endTime: moment().unix(),
    startTime: moment().subtract(3, 'days').unix(),
    period: 300,
  }),
  '1w': () => ({
    endTime: moment().unix(),
    startTime: moment().subtract(1, 'weeks').unix(),
    period: 300,
  }),
  '2w': () => ({
    endTime: moment().unix(),
    startTime: moment().subtract(2, 'weeks').unix(),
    period: 300,
  }),
  '1m': () => ({
    endTime: moment().unix(),
    startTime: moment().subtract(1, 'months').unix(),
    period: 3600,
  }),
  '3m': () => ({
    endTime: moment().unix(),
    startTime: moment().subtract(3, 'months').unix(),
    period: 3600,
  }),
} as { [key: string]: any };

// If unit is Megabytes then converting that into Gigabytes. Added unit parameter on plotdata.
export const getPlotData = (cwMetricsData: any, utcOffset: number) => {
  const timeStamps = get(cwMetricsData, 'cwMetrics.timeStamps', [0, 0]).map(
    (timestamp: number) => new Date((timestamp + utcOffset) * 1000)
  );
  return get(cwMetricsData, 'cwMetrics.externalMetrics', [
    { values: [0, 0], label: 'Loading...' },
  ]).map((metricData: any) => ({
    x: timeStamps,
    y:
      metricData.unit === 'Megabytes'
        ? metricData.values.map((v) => v / 1000)
        : metricData.values,
    type: 'scatter',
    mode: 'lines',
    name: metricData.label,
    unit:
      metricData.unit !== 'None'
        ? metricData.unit === 'Megabytes'
          ? 'GB'
          : metricData.unit
        : '',
  })) as PlotData[];
};

export const cw_metric_map = new Map(
  [
    ['ES Nodes', 'ES Nodes: Number of nodes in the cluster, including master and warm nodes (Src: ES API via CWAgent)'],
    ['ES Cluster Status', 'ES Cluster Status: Indicates if a cluster has any shards in RED, YELLOW or GREEN state (Src: ES API via CWAgent)'],
    ['ES Cluster Shards State', 'ES Cluster Shards State: Indicates state of shards. During blue-green, shards should be relocating (Src: ES API via CWAgent)'],
    ['ES Data Plane Total Request Count', 'ES Data Plane Total Request Count: Total number of requests being made to the DataPlane (Src: n/a )'],
    ['ES Searchable Documents', 'ES Searchable Documents: Total number of searchable documents in the cluster (Src: ES API via CWAgent )'],
    ['ES Deleted Documents', 'ES Deleted Documents: Total number of documents marked as deleted in the cluster but not expunged (Src: ES API via CWAgent )'],
    ['ES EBS Volume IOPS', 'ES EBS Volume IOPS: Average EBS IOPS rate per node (Src: CWAgent)'],
    ['ES EBS Volume Latency', 'ES EBS Volume Latency: Average latency per node (Src: CWAgent)'],
    ['ES EBS Volume Throughput', 'ES EBS Volume Throughput: Average EBS throughput per node (Src: CWAgent)'],
    ['ES EBS Volume Disk Queue Depth', 'ES EBS Volume Disk Queue Depth: Average EBS Disk Queue Depth per node (Src: CWAgent)'],
    ['ES Kibana Healthy Nodes', 'ES Kibana Healthy Nodes: Fraction of healthy Kibana instances in the cluster (Src: n/a)'],
    ['ES WarmSysMemoryUtilization', 'ES WarmSysMemoryUtilization: Reports the min-max-avg of System memory across all warm nodes (Src: CWAgent)'],
    ['ES SysMemoryUtilization', 'ES SysMemoryUtilization: Reports the min-max-avg of System memory across all data nodes (Src: CWAgent)'],
    ['ES Master JVM Memory Pressure', 'ES Master JVM Memory Pressure: Reports the min-max-avg of JVM memory for OpenSearch processes across all master nodes (Src: CWAgent)'],
    ['ES Warm JVM Memory Pressure', 'ES Warm JVM Memory Pressure: Reports the min-max-avg of JVM memory for OpenSearch processes across all warm nodes (Src: CWAgent)'],
    ['ES JVM Memory Pressure', 'ES JVM Memory Pressure: Reports the min-max-avg of JVM memory for OpenSearch processes across all data nodes (Src: CWAgent)'],
    ['ES Master Free Storage Space', 'ES Master Free Storage Space: Free disk space across all master nodes (Src: n/a)'],
    ['ES Warm Free Storage Space', 'ES Warm Free Storage Space: Free disk space across all warm nodes (Src: n/a)'],
    ['ES Free Storage Space', 'ES Free Storage Space: Free disk space across all data nodes (Src: n/a)'],
    ['ES Master CPU Credit Balance', 'ES Master CPU Credit Balance: CPU credit balance across all burstable master nodes (Src: n/a)'],
    ['ES CPU Credit Balance', 'ES CPU Credit Balance: CPU credit balance across all burstable data nodes (Src: n/a)'],
    ['ES Master CPU Utilization', 'ES Master CPU Utilization: CPU across all master nodes (Src: CWAgent)'],
    ['ES Warm CPU Utilization', 'ES Warm CPU Utilization: CPU across all warm nodes (Src: CWAgent)'],
    ['ES CPU Utilization', 'ES CPU Utilization: CPU across all data nodes (Src: CWAgent)'],
    ['ES Cluster Writes Blocked', 'ES Cluster Writes Blocked: Indicates if a cluster was write-blocked (Src: n/a)'],
    ['ES Request Rate', 'ES Request Rate: Shard Level Request rate across all data (and warm?) nodes (Src: CWAgent)'],
    ['ES Latency', 'ES Latency: Shard Level Request latency across all data (and warm?) nodes (Src: CWAgent)'],
    ['ES Index Queue State', 'ES Index Queue State: Reports the size of write pool queue (at point in time) and rejections (cumulative since last OS process startup on node) (Src: CWAgent)'],
    ['ES Force Merge Queue State', 'ES Force Merge Queue State: Reports the size of force-merge pool queue (at point in time) and rejections (cumulative since last OS process startup on node) (Src: CWAgent)'],
    ['ES Bulk Queue State', 'ES Bulk Queue State: Report bulk pool queue size (at point in time) and rejections (cumulative since last OS process startup on node). This applies only for ES versions < 6.3. See ES Index Queue State for versions > 6.3 (Src: CWAgent)'],
    ['ES Indexing Pressure State', 'ES Indexing Pressure State: 1.PrimaryWriteRejected: The total number of rejections happened on the primary shards due to indexing pressure since the last OpenSearch Service process startup.\n' +
        '2.CoordinatingWriteRejected: The total number of rejections happened on the coordinating node due to indexing pressure since the last OpenSearch Service process startup.\n' +
        '3.ReplicaWriteRejected : The total number of rejections happened on the replica shards due to indexing pressure since the last OpenSearch Service process startup. (Src: CWAgent)'],
    ['ES Indexing Pressure RejectionBreakup', 'ES Indexing Pressure RejectionBreakup: Reports the cause for shard indexing pressure rejection based on various parameters since the last OpenSearch Service process startup.' +
        'See: https://opensearch.org/docs/latest/tuning-your-cluster/availability-and-recovery/shard-indexing-backpressure/  (Src: CWAgent)'],
    ['ES UnreachableMasterNode', 'ES UnreachableMasterNode: The number of master eligible nodes which are not part of the cluster (Src: /_nodes API via BeagleRock)'],
    ['ES Merge', 'Stats on merges operations which are highly resource intensive (Src: BeagleStone)'],
    ['ES Flush', 'Stats on flush executed on data nodes. Helps relate disk I/O trends with flushes for better analysis during RCAs, live issues, perf analysis (Src: BeagleStone)'],
    ['ES Refresh', 'Stats on refresh executed on data nodes. Helps understand impact of scheduled refreshes and tune refresh intervals as per Cx use case (Src: BeagleStone)'],
    ['ES Script', 'Helps analyse resource skewness across nodes as scripts executed only on the primary shards (Src: BeagleStone)'],
    ['ES Translog', 'Helps troubleshoot issues related to free disk space and fine tuning lucene flushes by monitoring translog growth trends (Src: BeagleStone)'],
    ['ES Indexing', 'Shard Level Indexing Request rate across all data (Src: CWAgent/BeagleStone)'],
    ['ES Process', 'Number of FDs used for translog and segment files (Src: BeagleStone)'],
    ['ES FieldData', 'FieldData cache contains field data and global ordinals used to support aggregations on certain field types. These are on-heap data structures and affect JVM performance (Src: BeagleStone)'],
    ['ES Segments', 'Heap used by Lucene to load the index into the terms dictionary. Affects JVM performance (Src: BeagleStone)'],
    ['ES Throttled', 'Throttled stats during Indexing operatinos (Src: BeagleStone)'],
    ['ES Deletions', 'Stats related to Deletion operations being performed (Src: BeagleStone)'],
    ['ES Doc Rest Status', 'Stats related to Doc Rest Status in Bulk request by coordinator node (Src: BeagleStone)'],
  ]
);
