import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../../models/types';


export interface GetPoliciesStatsVars {
    awsAccountId: String;
}

const GET_POLICIES_STATS = gql`
    query GetPoliciesStats(
            $awsAccountId: String
        ){
        getPoliciesStats(
            awsAccountId: $awsAccountId
        )
    }
`;

export const useGetPoliciesStatsQuery = () => {
    return useLazyQuery<AnyObject, GetPoliciesStatsVars>(GET_POLICIES_STATS, {
        errorPolicy: 'all',
    });
};
