// Contains function that iterates through the indices and detects all the ones with the skew, appending them to the mapping of different
// index types to the respective indices and also returns the number of nodes per each different index type - hot,warm or mixed,
// which is used later on in the message.
import { AnyObject } from '../../../../models/types';
import { getDataInBytes, getMedianAndPercent, shardStates } from './constants';

export const parseshardsResp = (
  shardsResp: Array<AnyObject>,
  nodeDImapping: { [key: string]: Set<string> },
  di: string
) => {
  // Object for mapping index to number of replica and primary shards
  const primaryReplicaInIndexMapping: { [key: string]: AnyObject } = {};
  // Mapping of index to nodes with shards and size
  // index1 -> [node1->{shards->2,size->123}] and so on for each index
  const indexNode: { [key: string]: AnyObject } = {};
  // Sample object in the shardsResp
  // {
  //   "node": "lZUOkcM",
  //   "index": "perapp-api_v2-crit-2020.07.04-000136",
  //   "state": "STARTED",
  //   "store": "10.1gb",
  //   "ip": "172.16.31.72",
  //   "docs": "6023163",
  //   "shard": "9",
  //   "prirep": "p"
  // }
  for (let obj of shardsResp) {
    let [state, ip, index, prirep, store] = [
      obj.state,
      obj.ip,
      obj.index,
      obj.prirep,
      obj.store,
    ];
    if (state === shardStates.STARTED && nodeDImapping[di].has(ip)) {
      // Primary and replica shard count
      if (index in primaryReplicaInIndexMapping) {
        if (prirep === 'p') {
          primaryReplicaInIndexMapping[index]['p'] += 1;
        } else {
          primaryReplicaInIndexMapping[index]['r'] += 1;
        }
      } else {
        if (prirep === 'p') {
          primaryReplicaInIndexMapping[index] = { p: 1, r: 0 };
        } else {
          primaryReplicaInIndexMapping[index] = { r: 1, p: 0 };
        }
      }
      if (index in indexNode) {
        if (ip in indexNode[index]) {
          indexNode[index][ip].shards += 1;
          indexNode[index][ip].size += getDataInBytes(store);
        } else {
          indexNode[index][ip] = {
            shards: 1,
            size: getDataInBytes(store),
          };
        }
      } else {
        indexNode[index] = {
          [ip]: { shards: 1, size: getDataInBytes(store) },
        };
      }
    }
  }
  return {
    primaryReplicaInIndexMapping: primaryReplicaInIndexMapping,
    indexNode: indexNode,
  };
};

const addIndexToShardCountSkew = (
  indexType: string,
  indexTypesShardCount: { [key: string]: AnyObject },
  countRowObj: AnyObject,
  minShards: number,
  maxShards: number,
  shardCountThreshold: number
) => {
  if (indexType in indexTypesShardCount) {
    indexTypesShardCount[indexType].rows.push(countRowObj);
    if (maxShards - minShards > shardCountThreshold) {
      indexTypesShardCount[indexType].skewIndices += 1;
    }
  } else {
    if (maxShards - minShards > shardCountThreshold) {
      indexTypesShardCount[indexType] = {
        rows: [countRowObj],
        skewIndices: 1,
      };
    } else {
      indexTypesShardCount[indexType] = {
        rows: [countRowObj],
        skewIndices: 0,
      };
    }
  }
};

const addIndexToShardStorageSkew = (
  indexType: string,
  indexTypesShardStorage: { [key: string]: AnyObject },
  storageRowObj: AnyObject,
  minSize: number,
  maxSize: number,
  shardStorageThreshold: number
) => {
  if (indexType in indexTypesShardStorage) {
    indexTypesShardStorage[indexType].rows.push(storageRowObj);
    if (maxSize - minSize > (minSize * shardStorageThreshold) / 100) {
      indexTypesShardStorage[indexType].skewIndices += 1;
    }
  } else {
    if (maxSize - minSize > (minSize * shardStorageThreshold) / 100) {
      indexTypesShardStorage[indexType] = {
        rows: [storageRowObj],
        skewIndices: 1,
      };
    } else {
      indexTypesShardStorage[indexType] = {
        rows: [storageRowObj],
        skewIndices: 0,
      };
    }
  }
};

export const setIndexTypes = (
  shardsResp: Array<AnyObject>,
  nodeDImapping: { [key: string]: Set<string> },
  di: string,
  nodeBoxTypeMapping: { [key: string]: string },
  indexTypesShardCount: { [key: string]: AnyObject },
  indexTypesShardStorage: { [key: string]: AnyObject },
  shardStorageThreshold: number,
  shardCountThreshold: number
) => {
  // Parsing the shards file to get indexNode and primary-replica index mapping
  const results = parseshardsResp(shardsResp, nodeDImapping, di);
  const primaryReplicaInIndexMapping = results.primaryReplicaInIndexMapping;
  const indexNode = results.indexNode;

  // Iterating over each index and getting min-max size and shards and checking if skew is present in particular index or not.
  const numberOfNodesPerIndexType: { [key: string]: Set<string> } = {};
  const numberOfNodesForMixedType = new Set<string>();
  for (let index in indexNode) {
    let minShards = Infinity;
    let maxShards = -Infinity;
    let minSize = Infinity;
    let maxSize = -Infinity;
    let totalSize = -1;
    let sizeArray = [];
    let shardArray = [];
    // Set for checking the boxtype of all the nodes in current index from which we can determine index type- warm,hot or mixed
    const nodeTypes = new Set<string>();
    // Iterating over all nodes in current index
    for (let node in indexNode[index]) {
      nodeTypes.add(nodeBoxTypeMapping[node]);
      if (nodeBoxTypeMapping[node] in numberOfNodesPerIndexType) {
        numberOfNodesPerIndexType[nodeBoxTypeMapping[node]].add(node);
      } else {
        numberOfNodesPerIndexType[nodeBoxTypeMapping[node]] = new Set();
        numberOfNodesPerIndexType[nodeBoxTypeMapping[node]].add(node);
      }

      totalSize += indexNode[index][node].size;
      sizeArray.push(indexNode[index][node].size);
      shardArray.push(indexNode[index][node].shards);
      if (indexNode[index][node].shards < minShards) {
        minShards = indexNode[index][node].shards;
      }
      if (indexNode[index][node].shards > maxShards) {
        maxShards = indexNode[index][node].shards;
      }
      if (indexNode[index][node].size < minSize) {
        minSize = indexNode[index][node].size;
      }
      if (indexNode[index][node].size > maxSize) {
        maxSize = indexNode[index][node].size;
      }
    }
    // IndexShardCount
    const countRowObj: AnyObject = {};
    countRowObj.indexName = index;
    countRowObj.primaryShards = primaryReplicaInIndexMapping[index].p;
    countRowObj.replicaShards = primaryReplicaInIndexMapping[index].r;
    countRowObj.minVal = minShards;
    countRowObj.maxVal = maxShards;
    countRowObj.diff = maxShards - minShards;
    countRowObj.size = totalSize;

    const medianAndPercentCount = getMedianAndPercent(
      shardArray,
      maxShards - minShards
    );
    countRowObj.median = medianAndPercentCount.median;
    countRowObj.rangePercent = medianAndPercentCount.rangePercent;

    // Appending 'hasSkew' to indices with skew to differentiate them while rendering in table (highlighted)
    if (maxShards - minShards > shardCountThreshold) {
      countRowObj.indexName += 'hasSkew';
    }
    // Checking the index type from the generated set and deciding where to push the data for this index
    if (nodeTypes.size === 1) {
      const indexType = nodeTypes.values().next().value;
      addIndexToShardCountSkew(
        indexType,
        indexTypesShardCount,
        countRowObj,
        minShards,
        maxShards,
        shardCountThreshold
      );
    } else {
      for (let node in indexNode[index]) {
        numberOfNodesForMixedType.add(node);
      }
      addIndexToShardCountSkew(
        'mixed',
        indexTypesShardCount,
        countRowObj,
        minShards,
        maxShards,
        shardCountThreshold
      );
    }

    // IndexShardStorage
    const storageRowObj: AnyObject = {};
    storageRowObj.indexName = index;
    storageRowObj.primaryShards = primaryReplicaInIndexMapping[index].p;
    storageRowObj.replicaShards = primaryReplicaInIndexMapping[index].r;
    storageRowObj.minVal = minSize;
    storageRowObj.maxVal = maxSize;
    storageRowObj.diff = maxSize - minSize;
    storageRowObj.size = totalSize;

    const medianAndPercentSize = getMedianAndPercent(
      sizeArray,
      maxSize - minSize
    );
    storageRowObj.median = medianAndPercentSize.median;
    storageRowObj.rangePercent = medianAndPercentSize.rangePercent;

    // Appending 'hasSkew' to indices with skew to differentiate them while rendering in table (highlighted)
    if (maxSize - minSize > (minSize * shardStorageThreshold) / 100) {
      storageRowObj.indexName += 'hasSkew';
    }

    // Checking the index type from the generated set and deciding where to push the data for this index
    if (nodeTypes.size === 1) {
      const indexType = nodeTypes.values().next().value;
      addIndexToShardStorageSkew(
        indexType,
        indexTypesShardStorage,
        storageRowObj,
        minSize,
        maxSize,
        shardStorageThreshold
      );
    } else {
      addIndexToShardStorageSkew(
        'mixed',
        indexTypesShardStorage,
        storageRowObj,
        minSize,
        maxSize,
        shardStorageThreshold
      );
    }
  }
  numberOfNodesPerIndexType['mixed'] = numberOfNodesForMixedType;
  return numberOfNodesPerIndexType;
};
