import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../../models/types';


export interface GetScalingAuditHistoryVars {
    awsAccountId: String;
    placementConstraintId: String;
    dataPlaneType: String;
    namespace: String;
    startTime: String;
    endTime: String;
}

const GET_SCALING_AUDIT_HISTORY = gql`
    query GetScalingAuditHistory(
            $awsAccountId: String
            $placementConstraintId: String
            $dataPlaneType: String
            $namespace: String
            $startTime: String
            $endTime: String
        ){
        getScalingAuditHistory(
            awsAccountId: $awsAccountId
            placementConstraintId: $placementConstraintId
            dataPlaneType: $dataPlaneType
            namespace: $namespace
            startTime: $startTime
            endTime: $endTime
        )
    }
`;

export const useGetScalingAuditHistoryQuery = () => {
    return useLazyQuery<AnyObject, GetScalingAuditHistoryVars>(GET_SCALING_AUDIT_HISTORY, {
        errorPolicy: 'all',
    });
};