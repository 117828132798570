import React from 'react';
import { EuiCallOut } from '@elastic/eui';

export const ErrorDisplay = (props) => {
  return (
    <EuiCallOut
      color="danger"
      iconType="alert"
      title={props.title || 'Error has occured'}
    >
      {props.message || 'An unexpected error has occured'}
    </EuiCallOut>
  );
};
