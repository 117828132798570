import React from 'react';
import { executeNodeShellCommand } from '../../../../utils';
import { EuiPanel, EuiCodeBlock, EuiText, EuiProgress } from '@elastic/eui';
import { ErrorDisplay } from '../../../../components/ErrorDisplay';
import ElementErrorBoundary from '../../../../components/ErrorBoundary/ElementErrorBoundary';

export class Console extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isError: false,
      output: '',
      secondaryError: false,
    };
    this.initShell()
      .then(() => {})
      .catch((e) => {
        console.log(e);
        this.setState({ isError: e.toString(), isLoading: false });
      });
  }

  initShell = async () => {
    const { type, cmd, args, cluster, node } = this.props;
    if (typeof cluster !== 'string' || typeof node !== 'string') {
      this.setState({
        isError: 'Both cluster and node IP should be strings.',
        isLoading: false,
      });
    } else if (type === 'shell') {
      if (typeof cmd === 'string' && typeof args === 'string') {
        const result = await executeNodeShellCommand(
          'aes/domain/dp/node/shell',
          cluster,
          node,
          {
            base_cmd: cmd,
            args,
            truncate_output: false,
          }
        );
        if (result.status === 200 && result.data.data.executeCommand.success) {
          const [{ stdout, stderr }, { status }] =
            result.data.data.executeCommand.data;
          this.setState({
            isLoading: false,
            output: `${stdout}${stdout && stderr ? '\n' : ''}${stderr}`,
            ...(status !== 'ok'
              ? { secondaryError: true }
              : { secondaryError: false }),
          });
        } else if (result.status === 200) {
          this.setState({
            isError: JSON.stringify(
              result.data.data.executeCommand,
              undefined,
              4
            ),
            isLoading: false,
          });
        } else {
          this.setState({
            isError: 'Request Failed/Timed Out.',
            isLoading: false,
          });
        }
      } else {
        this.setState({
          isError: 'Both command and arguments should be strings.',
          isLoading: false,
        });
      }
    } else {
      if (typeof cmd === 'string') {
        const result = await executeNodeShellCommand(cmd, cluster, node, {
          truncate_output: false,
          ...args,
        });
        if (result.status === 200 && result.data.data.executeCommand.success) {
          const [{ stdout, stderr }, { status }] =
            result.data.data.executeCommand.data;
          this.setState({
            isLoading: false,
            output: `${stdout}${stdout && stderr ? '\n' : ''}${stderr}`,
            ...(status !== 'ok'
              ? { secondaryError: true }
              : { secondaryError: false }),
          });
        } else if (result.status === 200) {
          this.setState({
            isError: JSON.stringify(
              result.data.data.executeCommand,
              undefined,
              4
            ),
            isLoading: false,
          });
        } else {
          this.setState({
            isError: 'Request Failed/Timed Out.',
            isLoading: false,
          });
        }
      } else {
        this.setState({
          isError: 'The command should be a string.',
          isLoading: false,
        });
      }
    }
  };

  render() {
    const { props, state } = this;
    const { type, cmd, args } = props;
    const { isError, isLoading, output, secondaryError } = state;
    let content = null;
    if (isError) {
      content = <ErrorDisplay message={isError} />;
    } else if (!isLoading) {
      content = (
        <ElementErrorBoundary key={'code-block'}>
          <EuiCodeBlock
            language="shell"
            fontSize="m"
            paddingSize="m"
            overflowHeight="500"
            whiteSpace="pre-wrap"
            isCopyable={true}
          >
            {output}
          </EuiCodeBlock>
        </ElementErrorBoundary>
      );
    }

    return (
      <ElementErrorBoundary key={'single-console'}>
        <EuiPanel style={isLoading ? { position: 'relative' } : {}}>
          {isLoading ? (
            <EuiProgress size="xs" position="absolute" color="primary" />
          ) : null}
          <EuiText>
            <b>{'Type : '}</b>
            {type}
            <br />
            <b>{'Command : '}</b>
            {JSON.stringify(cmd)}
            <br />
            <b>{'Arguments : '}</b>
            {JSON.stringify(args, undefined, 3)}
          </EuiText>
          {secondaryError ? (
            <span style={{ color: 'red' }}>{'An error occured.'}</span>
          ) : null}
          <br />
          {content}
        </EuiPanel>
      </ElementErrorBoundary>
    );
  }
}
