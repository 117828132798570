import React from 'react';
import {
  LineChart,
  Box,
  Button,
  Container,
  Header,
} from '@amzn/awsui-components-react/polaris';
import { LINE_CHART_I18N_STRINGS } from './constants';

const getData = (data) => {
  const ret = [];
  for (const idx in data) {
    const point = {};
    point['x'] = new Date(data[idx]['x']);
    point['y'] = data[idx]['y'];
    ret.push(point);
  }
  return ret;
};

const seriesGenerator = (data) => {
  const series = [];
  const titles = Object.keys(data);
  for (const title in titles) {
    const item = {
      type: 'line',
      valueFormatter: (t) => t.toLocaleString('en-US'),
    };
    item['title'] = titles[title];
    item['data'] = getData(data[titles[title]]);
    series.push(item);
  }
  return series;
};

const IssueGraph = (props) => {
  const startTime = new Date(props.request['startTime']);
  const endTime = new Date(props.request['endTime']);
  const series = props.data;
  const headerValue = props.headerValue;
  const localTimeZone = Date()
    .toString()
    .match(/\(([A-Za-z\s].*)\)/)[1];
  return (
    <Container header={<Header variant="h2">{headerValue}</Header>}>
      <LineChart
        series={seriesGenerator(series)}
        xDomain={[startTime, new Date(endTime.getTime())]}
        i18nStrings={LINE_CHART_I18N_STRINGS}
        ariaLabel="Multiple data series line chart"
        errorText="Error loading data."
        height={150}
        hideFilter
        loadingText="Loading chart"
        recoveryText="Retry"
        statusType="finished"
        xScaleType="time"
        xTitle={'Time (' + localTimeZone + ')'}
        empty={
          <Box textAlign="center" color="inherit">
            <b>No data available</b>
            <Box variant="p" color="inherit">
              There is no data available
            </Box>
          </Box>
        }
        noMatch={
          <Box textAlign="center" color="inherit">
            <b>No matching data</b>
            <Box variant="p" color="inherit">
              There is no matching data to display
            </Box>
            <Button>Clear filter</Button>
          </Box>
        }
      />
    </Container>
  );
};
export { IssueGraph };
