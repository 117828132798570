import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Button,
  Header,
  Pagination,
  PropertyFilter,
  SpaceBetween,
  Table,
} from '@amzn/awsui-components-react';
import { useState } from 'react';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from './../../utils/constants';
import { ExportCSV } from './../../utils/exportCSV';
import {
  EmptyState,
  getFilterCounterText,
  PAGE_SIZE_OPTIONS,
  Preferences,
} from './../../utils/tablePreferences';

const TableComponent = (props) => {
  const [preferences, setPreferences] = useState(props.tableDefaultPreferences);
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(props.dataLoading ? [] : props.data, {
    propertyFiltering: {
      filteringProperties: props.filteringProperties,
      empty: (
        <EmptyState
          title="No Records."
          subtitle="No Records."
          action={<span></span>}
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can’t find a match."
          action={
            <Button onClick={() => actions.setFiltering('')}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
  });

  return (
    <Table
      {...collectionProps}
      header={
        <div>
          <Header
            counter={`(${items.length} of ${filteredItemsCount})`}
            actions={
              <SpaceBetween direction="horizontal" size="s">
                <ExportCSV
                  data={items}
                  filename={props.csvExportFileName}
                  loading={false}
                />
              </SpaceBetween>
            }
          >
            {props.tableHeaderName}
          </Header>
        </div>
      }
      columnDefinitions={props.columnDefinitions}
      stickyHeader={true}
      resizableColumns={true}
      wrapLines={preferences.wraplines}
      variant={preferences.variant ? preferences.variant : 'container'}
      loading={props.dataLoading}
      loadingText={
        props.dataLoading
          ? 'Loading records...'
          : 'There were no records.'
      }
      visibleColumns={preferences.visibleContent}
      items={items}
      pagination={
        <Pagination
          {...paginationProps}
          ariaLabels={{
            nextPageLabel: 'Next page',
            previousPageLabel: 'Previous page',
            pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
          }}
        />
      }
      filter={
        <div className="input-container">
          <PropertyFilter
            className="input-filter"
            expandToViewport={true}
            i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
            {...propertyFilterProps}
            countText={getFilterCounterText(filteredItemsCount)}
          />
        </div>
      }
      preferences={
        <Preferences
          preferences={preferences}
          setPreferences={setPreferences}
          disabled={false}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          visibleContentOptions={props.visibleContentOptions}
        />
      }
    />
  );
};

export { TableComponent };
