import {
  Box,
  ColumnLayout,
  Container,
  Header,
  SpaceBetween,
} from '@amzn/awsui-components-react';

const ValueWithLabel = ({ label, children }) => (
    <div>
      <Box margin={{ bottom: 'xxxs' }} color="text-label">
        {label}
      </Box>
      <div>{children}</div>
    </div>
);

const PlacementConstraintDetails = (props) => {
    return (
      <Container header={<Header variant="h2">PlacementConstraint Group Details</Header>}>
          <ColumnLayout columns={3} variant="text-grid">
              <SpaceBetween size="l">
                  <ValueWithLabel label="AWSAccountId">
                      {props.awsAccountId
                          ? props.awsAccountId
                          : '--'}
                  </ValueWithLabel>
              </SpaceBetween>
              <SpaceBetween size="l">
                  <ValueWithLabel label="PlacementConstraintId">
                      {props.placementConstraintId
                          ? props.placementConstraintId
                          : '--'}
                  </ValueWithLabel>
              </SpaceBetween>
              <SpaceBetween size="l">
                  <ValueWithLabel label="Namespace">
                      {props.namespace
                          ? props.namespace
                          : '--'}
                  </ValueWithLabel>
              </SpaceBetween>
          </ColumnLayout>
      </Container>
  );
};

export { PlacementConstraintDetails };