import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../../models/types';
 

export interface GetWorkersListForCollectionVars {
    awsAccountId: String;
    dataPlaneType: String;
    placementConstraintId: String;
}

const GET_WORKERS_LIST_FOR_COLLECTION = gql`
    query GetWorkersListForCollection(
            $awsAccountId: String
            $dataPlaneType: String
            $placementConstraintId: String
        ){
        getWorkersListForCollection(
            awsAccountId: $awsAccountId
            dataPlaneType: $dataPlaneType
            placementConstraintId: $placementConstraintId
        )
    }
`;
 
export const useGetWorkersListForCollectionQuery = () => {
    return useLazyQuery<AnyObject, GetWorkersListForCollectionVars>(GET_WORKERS_LIST_FOR_COLLECTION, {
        errorPolicy: 'all',
    });
};
