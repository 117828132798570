import { AnyObject } from '../../models/types';

type DataType = 'boolean' | 'text' | 'number' | 'object';

export interface Field {
  dataType: DataType;
  label: string;
  value: any;
}

export interface FormField {
  title: string;
  description: string;
  fields: Field[];
}

export interface FormFields {
  ec2Instances: FormField;
  ebs: FormField;
  es: FormField;
  misc: FormField;
}

export const getDataType = (field: any) => {
  const dataType = typeof field;
  switch (dataType) {
    case 'boolean':
      return 'boolean';
    case 'string':
      return 'text';
    case 'number':
      return 'number';
    case 'object':
      return Array.isArray(field) ? 'array' : 'text';
    default:
      return 'text';
  }
};

export const getFormFields = (limits: AnyObject) => {
  const finalFormFields: FormFields = {
    ec2Instances: {
      title: 'Instance Limits',
      description:
        'Set of configurations limits for EC2 instances and instances types. In case of large node counts, reachout to PM before updating.',
      fields: [],
    },
    ebs: {
      title: 'EBS Limits',
      description: 'These settings are applicable to EBS volumes.',
      fields: [],
    },
    es: {
      title: 'ES Limits',
      description: 'Set of Elasticsearch settings allowed to be override.',
      fields: [],
    },
    misc: {
      title: 'Others',
      description: 'Miscellaneous limits on domain',
      fields: [],
    },
  };

  Object.keys(limits).forEach((config: string) => {
    const formField = {
      dataType: getDataType(limits[config]) as DataType,
      label: config,
      value: limits[config],
    };
    // If setting belongs to EC2
    if (config.toLocaleLowerCase().indexOf('instance') > -1) {
      finalFormFields.ec2Instances.fields.push(formField);
    } else if (config.toLocaleLowerCase().indexOf('ebs') > -1) {
      // If setting belongs to EBS
      finalFormFields.ebs.fields.push(formField);
    } else if (
      config.toLocaleLowerCase().indexOf('index') > -1 ||
      config.toLocaleLowerCase().indexOf('search') > -1 ||
      config.toLocaleLowerCase().indexOf('field') > -1
    ) {
      // If setting belongs to ES related
      finalFormFields.es.fields.push(formField);
    } else {
      finalFormFields.misc.fields.push(formField);
    }
  });
  return finalFormFields;
};
