import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../models/types';
 
interface CommandHistoryVars {
    path: String;
    start_time: String;
    end_time: String;
    state: String;
  }

const FETCH_COMMAND_HISTORY = gql`
    query fetchCommandHistoryCommand (
        $path: String
        $start_time: String
        $end_time: String
        $state: String)
    {
        fetchCommandHistory(
            path: $path
            start_time: $start_time
            end_time: $end_time,
            state: $state
        ) {
            domain_id
            arguments {
                domain_identifier
            }
            time
            request_command
            source_host
            user
            request_client_id
            cli_command
            request_id
          }
    }
`;
 
export const useFetchCommandHistoryQuery = () => {
    return useLazyQuery<AnyObject, CommandHistoryVars>(FETCH_COMMAND_HISTORY, {
        errorPolicy: 'all',
    });
};