export const API_OPTIONS = [
  {
    value: '_cat',
  },
  {
    value: '_cluster',
  },
  {
    value: '_nodes',
  },
  {
    value: '_tasks',
  },
  {
    value: '_snapshot',
  },
  {
    value: '_opendistro',
  },
  {
    value: '_ultrawarm',
  },
  {
    value: '_kraken',
  },
  {
    value: '_cold',
  },
];

// Generate few runtime.
export const APIS = {
  cat: [
    {
      value: 'aliases',
    },
    {
      value: 'allocation',
    },
    {
      value: 'count',
    },
    {
      value: 'fielddata',
    },
    {
      value: 'health',
    },
    {
      value: 'indices',
    },
    {
      value: 'indices?expand_wildcards=all'
    },
    {
      value: 'master',
    },
    {
      value: 'nodeattrs',
    },
    {
      value: 'nodes',
    },
    {
      value: 'nodes?h=ip,id,n&full_id',
    },
    {
      value: 'plugins',
    },
    {
      value: 'recovery?active_only',
    },
    {
      value: 'recovery',
    },
    {
      value: 'repositories',
    },
    {
      value: 'pending_tasks',
    },
    {
      value: 'tasks',
    },
    {
      value: 'thread_pool',
    },
    {
      value: 'shards',
    },
    {
      value: 'segments',
    },
    {
      value: 'snapshots',
    },
    {
      value: 'snapshots/cs-*',
    },
    {
      value: 'snapshots/cs-automated',
    },
    {
      value: 'snapshots/cs-automated-enc',
    },
    {
      value: 'snapshots/cs-ultrawarm',
    },
    {
      value: 'templates',
    },
  ],
  cluster: [
    {
      value: 'thread_pool',
    },
    {
      value: 'allocation/explain',
    },
    {
      value: 'settings',
    },
    {
      value: 'settings?flat_settings'
    },
    {
      value: 'settings?flat_settings&include_defaults',
    },
    {
      value: 'health',
    },
    {
      value: 'state',
    },
    {
      value: 'stats',
    },
  ],
  nodes: [
    {
      value: 'usage',
    },
    {
      value: 'hot_threads',
    },
    {
      value: 'stats',
    },
  ],
  snapshot: [
    {
      value: 'cs-*',
    },
    {
      value: 'cs-automated',
    },
    {
      value: 'cs-automated-enc',
    },
    {
      value: 'cs-automated/_all',
    },
    {
      value: 'cs-automated-enc/_all',
    },
    {
      value: 'cs-ultrawarm',
    },
    {
      value: 'cs-remote-store-translog'
    },
    {
      value: 'cs-remote-store-segment'
    },
    {
      value: 'cs-remote-store-cluster-state'
    }
  ],
  tasks: [],
  opendistro: [
    {
      value: '_ism',
    },
    {
      value: '_security/api/securityconfig',
    },
    {
      value: '_security/api/internalusers',
    },
    {
      value: '_security/api/roles',
    },
    {
      value: '_security/api/rolesmapping',
    },
    {
      value: '_security/api/tenants',
    },
  ],
  ultrawarm: [
    {
      value: 'migration/_status',
    }
  ],
  kraken: [
    {
      value: 'stats',
    },
    {
      value: 'migration/stats/hot_to_warm',
    }
  ],
  cold: [
    {
      value: 'migration/_status',
    },
    {
      value: 'indices/_search',
    },
    {
      value: 'stats',
    }
  ]
};
