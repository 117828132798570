import {
  Box,
  ColumnLayout,
  Container,
  Header,
  SpaceBetween,
} from '@amzn/awsui-components-react';

const ValueWithLabel = ({ label, children }) => (
    <div>
      <Box margin={{ bottom: 'xxxs' }} color="text-label">
        {label}
      </Box>
      <div>{children}</div>
    </div>
);

const AccountDetails = (props) => {
    return (
      <Container header={<Header variant="h2">Account Details</Header>}>
          <ColumnLayout columns={4} variant="text-grid">
              <SpaceBetween size="l">
                  <ValueWithLabel label="Control Plane Cell Name">
                      {props.CPCellName
                          ? props.CPCellName.getCPCellName
                          : '--'}
                  </ValueWithLabel>
              </SpaceBetween>
              <SpaceBetween size="l">
                  <ValueWithLabel label="Data Plane Cell Name">
                      {props.DPCellName
                          ? props.DPCellName.getDPCellName
                          : '--'}
                  </ValueWithLabel>
              </SpaceBetween>
              <SpaceBetween size="l">
                  <ValueWithLabel label="SearchOCUs">
                      {props.searchOCUsCount
                          ? props.searchOCUsCount
                          : '--'}
                  </ValueWithLabel>
              </SpaceBetween>
              <SpaceBetween size="l">
                  <ValueWithLabel label="IndexingOCUs">
                      {props.indexingOCUsCount
                          ? props.indexingOCUsCount
                          : '--'}
                  </ValueWithLabel>
              </SpaceBetween>
              <SpaceBetween size="l">
                  <ValueWithLabel label="Number of Collections">
                      {props.accountCollectionsCount
                          ? props.accountCollectionsCount
                          : '--'}
                  </ValueWithLabel>
              </SpaceBetween>
              <SpaceBetween size="l"></SpaceBetween>
              <SpaceBetween size="l">
                  <ValueWithLabel label="Max SearchOCUs">
                      {props.maxSearchOCUs
                          ? props.maxSearchOCUs
                          : '--'}
                  </ValueWithLabel>
              </SpaceBetween>
              <SpaceBetween size="l">
                  <ValueWithLabel label="Max IndexingOCUs">
                      {props.maxIndexingOCUs
                          ? props.maxIndexingOCUs
                          : '--'}
                  </ValueWithLabel>
              </SpaceBetween>
          </ColumnLayout>
      </Container>
  );
};

export { AccountDetails };
