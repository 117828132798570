import { gql, useQuery } from '@apollo/client';
import { UpgradeHistory } from '../model';

interface UpgradeDetailsVars {
  domainIdentifier: string;
}

interface UpgradeDetailsData {
  domain: {
    upgradeHistory: UpgradeHistory[];
  };
}

const GQL_UPGRADE_HISTORY = gql`
  query getDomainUpgradeHistory($domainIdentifier: String!) {
    domain(domainIdentifier: $domainIdentifier) {
      upgradeHistory
    }
  }
`;
export const useUpgradeHistoryQuery = (vars: UpgradeDetailsVars) => {
  return useQuery<UpgradeDetailsData, UpgradeDetailsVars>(GQL_UPGRADE_HISTORY, {
    variables: { ...vars },
  });
};
