import { gql, useMutation } from '@apollo/client';
import { AnyObject } from '../../models/types';

interface RequestOnDemandDataVars {
  domainId: string;
  eventType: string;
  startTime: string;
  endTime: string;
  reBackfill: string
}

export const REQUEST_ON_DEMAND_DATA = gql`
  mutation RequestOnDemandData(
    $domainId: String
    $eventType: String
    $startTime: String
    $endTime: String
    $reBackfill: String
  ) {
    requestOnDemandData(
      domainId: $domainId
      eventType: $eventType
      startTime: $startTime
      endTime: $endTime
      reBackfill: $reBackfill
    )
  }
`;

export const useRequestOnDemandDataMutation = () => {
  return useMutation<AnyObject, RequestOnDemandDataVars>(REQUEST_ON_DEMAND_DATA,
    {
      fetchPolicy: 'no-cache'
    });
};