export const LSEToolNavbarTitle = 'Amazon OpenSearch Service LSE Tool 2.0';

export const LSEToolNavbarOptions = [
  {
    label: 'Report a bug',
    value: 'https://tiny.amazon.com/ek71ltxf',
  },
  {
    label: 'New Feature Request',
    value: 'https://tiny.amazon.com/1fl6am7vy',
  },
  {
    label: 'Release History',
    value: 'https://w.amazon.com/bin/view/AWS/SearchServices/LSETool/Release',
  },
  {
    label: 'User Guide',
    value:
      'https://w.amazon.com/bin/view/AWS/SearchServices/InfrastructureEngineering/SOP/lse-tool',
  },
];

export const HOME_PATH = '/lse-tool';

export const HISTORY_PATH = HOME_PATH + '/history';

export const DETAILS_PATH = HOME_PATH + '/details';
