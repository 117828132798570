import { TMSIssueChart } from './Components/Home/TMSIssueChart';

export const getClosestFifthMinuteTime = (time) => {
  const coeff = 1000 * 60 * 5;
  return new Date(Math.floor(time / coeff) * coeff);
};

export const getEndTimeUtc = (time) => {
  const roundedTime = getClosestFifthMinuteTime(time);
  const fiveMinutes = 5;
  const currentTime = new Date();
  if (currentTime.getTime() - time < 10 * 60 * 1000) {
    roundedTime.setMinutes(roundedTime.getMinutes() - fiveMinutes);
  }
  return roundedTime.toISOString().substring(0, 19) + 'Z';
};

export const getSelectedStartTimeUtc = (endTime, timeWindow) => {
  const end = new Date(endTime);
  let value = 0;
  if (timeWindow.includes('hours')) {
    value = parseInt(timeWindow.replace('hours', '')) * 3600000;
  } else if (timeWindow.includes('days')) {
    value = parseInt(timeWindow.replace('days', '')) * 86400000;
  } else if (timeWindow.includes('weeks')) {
    value = parseInt(timeWindow.replace('weeks', '')) * 604800000;
  }
  return new Date(end.getTime() - value).toISOString().substring(0, 19) + 'Z';
};

export const getRoundedEndTimeUtc = (time) => {
  const roundedTime = getClosestFifthMinuteTime(time);
  return roundedTime.toISOString().substring(0, 19) + 'Z';
};

export const getS3ObjectKey = (
  endTime,
  selectedTimeWindow,
  selectedInterval
) => {
  const time = endTime.split('T');
  const endTimeDate = time[0].split('-');
  const endTimeTime = time[1].split(':');
  const s3Key = [
    'stack',
    '/year=',
    endTimeDate[0],
    '/month=',
    endTimeDate[1],
    '/day=',
    endTimeDate[2],
    '/hour=',
    endTimeTime[0],
    '/',
    endTimeTime[1],
    '_timeWindow=',
    selectedTimeWindow,
    '_interval=',
    selectedInterval,
    '.gz',
  ];
  return s3Key.join('');
};

export const getDetailPageS3ObjectKey = (
  endTime,
  selectedTimeWindow,
  selectedInterval,
  selectedIssueTypes,
  slectedLseStartTime,
  slectedLseEndTime
) => {
  const time = endTime.split('T');
  const endTimeDate = time[0].split('-');
  const s3Key = [
    'report',
    '/year=',
    endTimeDate[0],
    '/month=',
    endTimeDate[1],
    '/day=',
    endTimeDate[2],
    '/endTimeUtc=',
    endTime.replaceAll(':', '-'),
    '_timeWindow=',
    selectedTimeWindow,
    '_interval=',
    selectedInterval,
    '_issueTypes=',
    selectedIssueTypes.replaceAll(':', '-'),
    '_lseStartTime=',
    slectedLseStartTime.replaceAll(':', '-'),
    '_lseEndTime=',
    slectedLseEndTime.replaceAll(':', '-'),
    '.gz',
  ];
  return s3Key.join('');
};

export const getInterval = (timeWindow) => {
  if (timeWindow === '7days') {
    return '15m';
  } else if (timeWindow === '4weeks') {
    return '1h';
  } else {
    return '5m';
  }
};

export const getTmsItems = (data) => {
  const tmsItems = [];
  for (const property in data) {
    const tmp = {};
    tmp['name'] = property;
    tmp['description'] = (
      <TMSIssueChart name={property} chartData={data[property]} />
    );
    tmsItems.push(tmp);
  }
  return tmsItems;
};

export const issueTypesToString = (selectedIssueTypes) => {
  let selectedItems = '';
  let tmp = '';
  selectedIssueTypes.forEach(function getTMS(selectedItem) {
    selectedItems += tmp;
    selectedItems += selectedItem['name'];
    tmp = ',';
  });
  return selectedItems;
};
