import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Box,
  Button,
  Pagination,
  PropertyFilter,
  Table,
} from '@amzn/awsui-components-react';
import { get } from 'lodash';
import { useState, useEffect } from 'react';
import { useFlyout } from '../../../hooks/useFlyout';
import { CommonDomainProps } from '../../../models/types';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from './../../../utils/constants';
import {
  getFilterCounterText,
  Preferences,
} from './../../../utils/tablePreferences';
import { useTmsQuery } from './hooks/useTmsQuery';
import { TmsDetails } from './TmsDetails';
import {
  columns,
  DEFAULT_PREFERENCES,
  FILTERING_PROPERTIES,
  PAGE_SIZE_OPTIONS,
  VISIBLE_CONTENT_OPTIONS,
} from './utils';
import { isError } from "../../../ErrorHandler/apiErrorHandler";
import { ErrorMessageComponent } from "../../../ErrorHandler/errorMessageComponent";
import { ApiList }from "../../../ErrorHandler/utils";

const TMSIssues = (props: CommonDomainProps) => {
  const setFlyout = useFlyout();
  const { loading, data, error } = useTmsQuery({
    domainIdentifier: props.domainIdentifier,
  });
  const [errorMessage, setErrorMessage] = useState(null);

  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(data ? get(data, 'domain.tmsIssues', []) : [], {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES,
      empty: (
        <EmptyState
          title="No TMS issues"
          subtitle="No TMS issues to display."
          action={<span></span>}
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can’t find a match."
          action={
            <Button onClick={() => actions.setFiltering('')}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
  });

   const isErrorPresent = isError(error)
   useEffect(() => {
    if (isErrorPresent) {
        setErrorMessage("Unable to get TMS issue. Failed with " + error.message);
    }
  }, [isErrorPresent, error]);
  function EmptyState({ title, subtitle, action }) {
    return (
      <Box textAlign="center" color="inherit">
        <Box variant="strong" textAlign="center" color="inherit">
          {title}
        </Box>
        <Box variant="p" padding={{ bottom: 's' }} color="inherit">
          {subtitle}
        </Box>
        {action}
      </Box>
    );
  }
  return (
      <div>
      {errorMessage ? (
        <ErrorMessageComponent errorMessage={errorMessage} apiName={ApiList.TMS_ISSUES}/>
      ) : (
    <Table
      {...collectionProps}
      columnDefinitions={[
        {
          id: 'issueType',
          sortingField: 'issueType',
          name: 'Issue type',
          header: 'Issue type',
          cell: (e) => (
            <Button
              variant="link"
              onClick={() =>
                setFlyout({
                  title: e.issueType,
                  size: 'm',
                  content: <TmsDetails description={e.details} />,
                })
              }
            >
              <span style={{ color: '#00a1c9' }}>{e.issueType}</span>
            </Button>
          ),
        },
        ...columns,
      ]}
      stickyHeader={true}
      resizableColumns={true}
      wrapLines={preferences.wraplines}
      loading={loading}
      loadingText={loading ? 'Loading TMS issues...' : 'No TMS issues found.'}
      visibleColumns={preferences.visibleContent}
      items={items}
      pagination={
        <Pagination
          {...paginationProps}
          ariaLabels={{
            nextPageLabel: 'Next page',
            previousPageLabel: 'Previous page',
            pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
          }}
        />
      }
      filter={
        <PropertyFilter
          {...propertyFilterProps}
          i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
          countText={getFilterCounterText(filteredItemsCount)}
        />
      }
      preferences={
        <Preferences
          preferences={preferences}
          setPreferences={setPreferences}
          disabled={false}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          visibleContentOptions={VISIBLE_CONTENT_OPTIONS}
        />
      }
    />
  )}
  </div>
  );
};

export { TMSIssues };
