import { ExpandableSection } from '@amzn/awsui-components-react';
import {
  EuiButtonEmpty,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiSpacer,
} from '@elastic/eui';
import { useState } from 'react';
import { CommonDomainProps } from '../../models/types';
import { ExternalCwWidget } from './ExternalCwWidget';
import { ExternalWidget } from './model';
import { cw_metric_map } from './utils';

interface ExternalDashboardProps extends CommonDomainProps {
  widgets: ExternalWidget[];
  metricRange: any;
  utcOffset: number;
  generateShareParams?: () => string;
}

const ExternalDashboard = (props: ExternalDashboardProps) => {
  const [gridview, setGridview] = useState<boolean>(false);
  return (
    <div
      style={{
        backgroundColor: '#f2f3f3',
        minHeight: '500px',
        padding: '10px',
      }}
    >
      <EuiFlexGroup justifyContent="flexEnd" alignItems="flexEnd">
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty
            size="s"
            flush="left"
            iconSide="left"
            color="text"
            textProps={{ style: { overflow: 'initial' } }}
            onClick={() => setGridview(!gridview)}
            iconType={gridview ? 'visTable' : 'grid'}
          >
            Toggle dashboard layout
          </EuiButtonEmpty>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiHorizontalRule margin={'xs'} />
      <EuiFlexGrid columns={gridview ? 3 : 1} gutterSize="xl">
        {props.widgets.map((widget: any) => (
          <EuiFlexItem>
            <ExpandableSection
              header={widget.title}
              className="expandableSection"
            >
              {cw_metric_map.get(widget.title)}
            </ExpandableSection>
            <ExternalCwWidget
              utcOffset={props.utcOffset}
              title={widget.title}
              metrics={widget.metrics}
              period={props.metricRange.period}
              startTime={props.metricRange.startTime}
              endTime={props.metricRange.endTime}
              domainIdentifier={props.domainIdentifier}
              generateShareParams={props.generateShareParams}
            />
          </EuiFlexItem>
        ))}
      </EuiFlexGrid>
    </div>
  );
};

export { ExternalDashboard };
