import { gql, useLazyQuery } from '@apollo/client';

interface UpdateRoleArnData {
  updateRoleArnResponse: JSON;
}

export interface UpdateRoleArnVars {
  awsAccountId: string;
  regions: string[];
  roleArn: string;
}

const UPDATE_ROLE = gql`
  query updateRoleArn(
    $awsAccountId: String!
    $regions: [String]
    $roleArn: String!
  ) {
    updateRoleArnResponse(
      awsAccountId: $awsAccountId
      regions: $regions
      roleArn: $roleArn
    )
  }
`;

export const useUpdateRoleArnQuery = () => {
  return useLazyQuery<UpdateRoleArnData, UpdateRoleArnVars>(UPDATE_ROLE, {
    errorPolicy: 'all',
  });
};
