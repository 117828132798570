import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../../models/types';
 

export interface GetWorkersListForAccountVars {
    awsAccountId: String;
    dataPlaneType: String;
}

const GET_WORKERS_LIST_FOR_ACCOUNT = gql`
    query GetWorkersListForAccount(
            $awsAccountId: String
            $dataPlaneType: String
        ){
        getWorkersListForAccount(
            awsAccountId: $awsAccountId
            dataPlaneType: $dataPlaneType
        )
    }
`;
 
export const useGetWorkersListForAccountQuery = () => {
    return useLazyQuery<AnyObject, GetWorkersListForAccountVars>(GET_WORKERS_LIST_FOR_ACCOUNT, {
        errorPolicy: 'all',
    });
};
