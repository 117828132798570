import React, { Fragment } from 'react';
import {
  EuiPage,
  EuiPageBody,
  EuiPanel,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiLoadingSpinner,
  EuiSelect,
  EuiButton,
  EuiTabbedContent,
} from '@elastic/eui';

import { apiCall } from '../utils';
import { ErrorToast } from '../components/ErrorToast';
import { ClusterTab } from './Cluster/ClusterTab';
import { ComponentTab } from './Component/ComponentTab';
import { ComponentVersionTab } from './ComponentVersion/ComponentVersionTab';
import { TaskTab } from './Task/TaskTab';

export class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      config: {
        airport: '',
        region: '',
        stage: '',
      },
      errorStatus: {
        configFetch: false,
        stackList: false,
      },
      optionDisabled: {
        stackSelect: true,
      },
      selectOptions: {
        stackList: [],
      },
      loadingData: {
        stackList: false,
      },
      selected: {
        stack: null,
      },
    };
  }

  getClusterTab = () => {
    return (
      <div>
        <EuiPanel>
          <ClusterTab stackName={this.state.selected.stack} />
        </EuiPanel>
      </div>
    );
  };

  getComponentTab = () => {
    return (
      <div>
        <EuiPanel>
          <ComponentTab stackName={this.state.selected.stack} />
        </EuiPanel>
      </div>
    );
  };

  getComponentVersionTab = () => {
    return (
      <div>
        <EuiPanel id="componentVersionTabPanel">
          <ComponentVersionTab stackName={this.state.selected.stack} />
        </EuiPanel>
      </div>
    );
  };

  getTaskTab() {
    return (
      <div>
        <EuiPanel id="taskTabPanel">
          <TaskTab stackName={this.state.selected.stack} />
        </EuiPanel>
      </div>
    );
  }

  getTabs = () => {
    return (
      <EuiTabbedContent
        tabs={[
          {
            id: 'component',
            name: 'COMPONENT',
            content: this.getComponentTab(),
          },
          {
            id: 'component_version',
            name: 'COMPONENT VERSION',
            content: this.getComponentVersionTab(),
          },
          {
            id: 'cluster',
            name: 'CLUSTER',
            content: this.getClusterTab(),
          },
          {
            id: 'task',
            name: 'TASK OPERATIONS',
            content: this.getTaskTab(),
          },
        ]}
      />
    );
  };

  onChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const { selected } = this.state;
    selected[name] = value;
    this.setState({ selected });
  };

  getConfig = async () => {
    return await apiCall('/getConfig', {});
  };

  componentDidMount() {
    this.getConfig().then((result) => {
      if (result.statusCode === 200) {
        const { optionDisabled } = this.state;
        optionDisabled.stackSelect = false;
        this.setState(
          {
            config: result.body,
            optionDisabled,
          },
          () => {
            const { selectOptions, selected } = this.state;
            const defaultStackName = this.getDefaultStack();
            selected.stack = defaultStackName;
            selectOptions.stackList = [
              { text: defaultStackName, value: defaultStackName },
            ];
            this.setState({ selectOptions, selected });
          }
        );
      } else {
        const { errorStatus } = this.state;
        errorStatus.configFetch = result.body;
        this.setState({ errorStatus });
      }
    });
  }

  getStackList = async () => {
    const { loadingData, errorStatus, selectOptions } = this.state;
    let stackList = [];
    let data = {};
    try {
      loadingData.stackList = true;
      this.setState({ loadingData });
      let nextToken = null;
      do {
        data = { ...data, nextToken };
        const response = await apiCall('/stack/list', data);
        if (response.statusCode === 200) {
          stackList = stackList.concat(
            response.body.stackList.stacks.map((stack) => ({
              text: stack.name,
              value: stack.name,
            }))
          );
          nextToken = response.body.nextToken;
        } else {
          throw JSON.stringify(response.body);
        }
      } while (nextToken);
      stackList.sort((a, b) => {
        if (a.value === b.value) return 0;
        else if (a.value > b.value) return 1;
        else return -1;
      });
      selectOptions.stackList = stackList;
      this.setState({ selectOptions });
    } catch (e) {
      console.log(e);
      errorStatus.stackList = e.toString();
      this.setState({ errorStatus });
    } finally {
      loadingData.stackList = false;
      this.setState({ loadingData });
    }
  };

  closeError = (name) => {
    const { errorStatus } = this.state;
    errorStatus[name] = false;
    this.setState({ errorStatus });
  };

  getDefaultStack = () => {
    const {
      config: { region, stage },
    } = this.state;
    if (!region || !stage) return null;
    const stageMap = {
      alpha: 'dev',
      beta: 'integ',
      gamma: 'staging',
      prod: 'prod',
    };
    return `swift-${region}-${stageMap[stage]}`;
  };

  renderConfigData = () => {
    const { state, closeError } = this;
    const {
      errorStatus: { configFetch },
      config,
    } = state;
    const { region, airport, stage } = config;
    return (
      <Fragment>
        {(configFetch && (
          <ErrorToast
            error={configFetch}
            title={'Fetching endpoint configuration failed. Please Refresh.'}
            closer={() => closeError('configFetch')}
          />
        )) || (
            <EuiForm>
              <EuiFormRow>
                <EuiText size={'xs'}>
                  <h2>{`Region : ${airport} (${region})`}</h2>
                </EuiText>
              </EuiFormRow>
              <EuiFormRow>
                <EuiText size={'xs'}>
                  <h2>{`Stage : ${stage}`}</h2>
                </EuiText>
              </EuiFormRow>
            </EuiForm>
          )}
      </Fragment>
    );
  };

  renderStackForm = () => {
    const { state, getStackList, closeError } = this;
    const {
      loadingData,
      optionDisabled,
      selectOptions,
      selected,
      errorStatus,
    } = state;

    return (
      <Fragment>
        <EuiFlexItem>
          <EuiForm>
            {loadingData.stackList && <EuiLoadingSpinner size="l" />}
            <EuiFormRow>
              <ErrorToast
                error={errorStatus.stackList}
                title={'Stack Listing Failed'}
                closer={() => closeError('stackList')}
              />
            </EuiFormRow>
            <EuiFormRow
              label="Stack"
              id="stack"
              onChange={this.onChange}
              helpText="Enter the stack name."
            >
              <EuiSelect
                disabled={optionDisabled.stackSelect}
                placeholder="Stack"
                options={selectOptions.stackList}
                value={selected.stack || ' '}
                name="stack"
              />
            </EuiFormRow>
            <EuiButton onClick={getStackList}>{'Get Stack List'}</EuiButton>
          </EuiForm>
        </EuiFlexItem>
      </Fragment>
    );
  };

  render() {
    const { state, renderConfigData, renderStackForm } = this;
    const {
      errorStatus: { configFetch },
    } = state;
    return (
      <div>
        <EuiPage>
          <EuiPageBody>
            <EuiPanel>
              <EuiFlexGroup>
                <EuiFlexItem>{renderConfigData()}</EuiFlexItem>
                {!configFetch && renderStackForm()}
              </EuiFlexGroup>
            </EuiPanel>
            <br />
            <br />
            {this.getTabs()}
          </EuiPageBody>
        </EuiPage>
      </div>
    );
  }
}
