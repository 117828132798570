import {
    Link,
    Container,
    Header,
    SpaceBetween
} from '@amzn/awsui-components-react';
import { ExplorerLinksList } from './Links'
const HyperLink = ({text, link}) => {
    return (
        <Link href={link}>
            {text}
        </Link>
    );
};

const ExplorerLinks = () => {
    return(
        <Container header={<Header variant="h2" description="Use the below URLs to list available commands and their usage details.">Explorer Links</Header>}>
            <SpaceBetween size="l">
            {ExplorerLinksList.map(({ text, link }) => (
                <HyperLink text={text} link={link} >
                </HyperLink>
            ))}
            </SpaceBetween>
        </Container>
    );
};

export { ExplorerLinks };