import { Flashbar } from '@amzn/awsui-components-react';
import { useRef } from 'react';
import Plot from 'react-plotly.js';
import { useToastNotifications } from '../../../hooks/useToastList';
import { INCOMPLETE_DATA_MESSAGE } from './constants';
import {CWMetricsVars, useGetExternalCwQuery} from './hooks/useGetExternalCwQuery';
import { ExternalMetrics, PERIOD } from './model';
import { cw_metric_map, getPlotData } from './utils';

declare global {
  interface Window {
    iGraphHelperSnapshot: any;
  }
}

interface ExternalCwWidgetProps{
  metrics: ExternalMetrics[];
  title: string;
  startTime: Date;
  endTime: Date;
  period: PERIOD;
  utcOffset: number;
  generateShareParams?: () => string;
}

var shareIcon = {
  width: 500,
  height: 600,
  path: `M404.9,0c45.1,0,81.5,37.1,81.5,82.8c0,45.7-36.5,82.8-81.5,82.8c-24.2,0-46-10.7-60.9-27.7l-160.9,88.1
  c3.6,9.3,5.5,19.5,5.5,30.1c0,13.9-3.3,26.9-9.3,38.4l153.8,95.4c13.8-25.8,40.7-43.4,71.7-43.4c45.1,0,81.5,37.1,81.5,82.8
  c0,45.7-36.5,82.8-81.5,82.8s-81.5-37.1-81.5-82.8l0.1-3.5L156.3,322.1c-13.7,10.5-30.7,16.7-49.1,16.7
  c-45.1,0-81.5-37.1-81.5-82.8s36.5-82.8,81.5-82.8c21.8,0,41.6,8.7,56.3,22.9l163.4-89.4c-2.2-7.5-3.4-15.5-3.4-23.8
  C323.4,37.1,359.8,0,404.9,0z M404.9,382.1c-25.4,0-46.1,21-46.1,47.1c0,26,20.7,47.1,46.1,47.1s46.1-21,46.1-47.1
  C451,403.1,430.3,382.1,404.9,382.1z M107.1,208.9c-25.4,0-46.1,21-46.1,47.1s20.7,47.1,46.1,47.1s46.1-21,46.1-47.1
  S132.5,208.9,107.1,208.9z M404.9,35.7c-25.4,0-46.1,21-46.1,47.1c0,26,20.7,47.1,46.1,47.1s46.1-21,46.1-47.1
  C451,56.8,430.3,35.7,404.9,35.7z`,
};

var linkIcon = {
  width: 500,
  height: 600,
  svg: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="" class="bi bi-link-45deg" viewBox="0 0 16 16">
    <path d="M4.715 6.542L3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.001 1.001 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
    <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 0 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 0 0-4.243-4.243L6.586 4.672z"/>
  </svg>`,
};

var infoIcon = {
  width: 400,
  height: 500,
  svg: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.033 16.01c.564-1.789 1.632-3.932 1.821-4.474.273-.787-.211-1.136-1.74.209l-.34-.64c1.744-1.897 5.335-2.326 4.113.613-.763 1.835-1.309 3.074-1.621 4.03-.455 1.393.694.828 1.819-.211.153.25.203.331.356.619-2.498 2.378-5.271 2.588-4.408-.146zm4.742-8.169c-.532.453-1.32.443-1.761-.022-.441-.465-.367-1.208.164-1.661.532-.453 1.32-.442 1.761.022.439.466.367 1.209-.164 1.661z"/></svg>`,
};

function GetExternalCwQuery(vars: CWMetricsVars){
  return useGetExternalCwQuery(vars);
}

const ExternalCwWidget = (props: ExternalCwWidgetProps) => {
  const containerRef = useRef(null);
  const toastNotifications = useToastNotifications();

  let call_error = ""
  let call_loading = false

  let allData = []
  for(let i = 0; i < props.metrics.length; i++){
    const { loading, data, error} = GetExternalCwQuery({
      namespace: props.metrics[i].namespace,
      metric_name: props.metrics[i].metric_name,
      dimensions: props.metrics[i].dimensions,
      startTime: props.startTime,
      endTime: props.endTime,
      period: props.period,
      statistics: props.metrics[i].statistics,
    })
    allData.push(data)
    call_error = error;
    call_loading = loading && call_loading;
  }

  const hasNextToken = false;

  if (call_error) {
    toastNotifications.addError(call_error, {
      title: `Unable to get metrics for ${props.title}`,
    });
  }
  const plotData = getPlotData(allData, props.utcOffset);
  return (
    <>
      {hasNextToken && (
          <Flashbar
              items={[
                {
                  type: 'error',
                  content: INCOMPLETE_DATA_MESSAGE,
                },
              ]}
          />
      )}
      <Plot
        ref={containerRef}
        data={plotData}
        style={
          call_loading
            ? {
                opacity: '0.5',
                position: 'relative',
                display: 'inline-block',
              }
            : { position: 'relative', display: 'inline-block' }
        }
        revision={0}
        layout={{
          annotations: [
            {
              xref: 'paper',
              yref: 'paper',
              x: 0.5,
              y: -0.3,
              xanchor: 'center',
              yanchor: 'top',
              text: '',
              showarrow: false,
              font: {
                family: 'Arial',
                size: 12,
                color: 'rgb(150,150,150)',
              },
            },
          ],
          height: 350,
          xaxis: { showgrid: false, zeroline: false },
          yaxis: {
            // @ts-ignore Added unit field in plotData to simplify logic.
            title: plotData ? plotData[0].unit : '',
            titlefont: {
              family: 'Arial',
              size: 12,
            },
            zeroline: false,
            rangemode: 'tozero',
          },
          showlegend: true,
          legend: {
            orientation: 'h',
          },
          margin: {
            l: 50,
            r: 50,
            b: 100,
            t: 100,
            pad: 2,
          },
          title: props.title,
        }}
        config={{
          responsive: true,
          displayModeBar: true,
          scrollZoom: false,
          displaylogo: false,
          modeBarButtonsToRemove: [
            'lasso2d',
            'select2d',
            'sendDataToCloud',
            'zoom2d',
            'pan2d',
            'zoomIn2d',
            'zoomOut2d',
            'autoScale2d',
            'resetScale2d',
            'hoverClosestCartesian',
            'hoverCompareCartesian',
            'zoom3d',
            'pan3d',
            'orbitRotation',
            'tableRotation',
            'resetCameraDefault3d',
            'resetCameraLastSave3d',
            'hoverClosest3d',
            'zoomInGeo',
            'zoomOutGeo',
            'resetGeo',
            'hoverClosestGeo',
            'hoverClosestGl2d',
            'hoverClosestPie',
            'toggleHover',
            'resetViews',
            'toggleSpikelines',
          ],
          modeBarButtonsToAdd: [
            {
              title: cw_metric_map.get(props.title),
              name: 'MetricInfo',
              icon: infoIcon,
              click: () => {},
            },
            {
              title: '',
              name: 'snapshot',
              icon: shareIcon,
              click: () => {
                if (
                  window.iGraphHelperSnapshot &&
                  containerRef &&
                  containerRef.current
                ) {
                  // iGraphHelper is installed, launch snapshot dialog ready to snapshot page element
                  window.iGraphHelperSnapshot.snapshot(containerRef.current.el);
                } else {
                  // Not installed, open up installation page plus message customer
                  window.open('https://w.amazon.com/bin/view/IGraphHelper');
                  alert('Please install latest iGraph Helper first');
                }
              },
            },
            {
              title: 'Copy link for this metric ',
              name: 'shareMetric',
              icon: linkIcon,
              click: () => {
                const params = props.generateShareParams
                  ? props.generateShareParams()
                  : `title=${props.title.replace(/\s/g, '_')}&startTime=${
                      props.startTime
                    }&endTime=${props.endTime}`;
                const sharableLink = `${window.location.origin}${window.location.pathname}#/${props.title}/shared-metric?${params}`;
                const el = document.createElement('textarea');
                el.value = sharableLink;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
              },
            },
          ],
        }}
        className={call_loading ? 'chart-spinner' : ''}
      />
    </>
  );
};

export { ExternalCwWidget };
