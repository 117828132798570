import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Button,
  DateRangePicker,
  Header,
  Pagination,
  Popover,
  PropertyFilter,
  Select,
  SpaceBetween,
  Flashbar,
  Table,
} from '@amzn/awsui-components-react';
import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import { cloneDeep, get } from 'lodash';
import moment from 'moment';
import { AppContext } from '../../utils/appContext';
import { Link } from 'react-router-dom';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from '../../utils/constants';
import {
  EmptyState,
  getFilterCounterText,
  Preferences,
} from '../../utils/tablePreferences';
import {
  DEFAULT_ELB_PREFERENCES,
  DEFAULT_PA_PREFERENCES,
  LIST_EXECUTION_FILTERING_PROPERTIES,
  PAGE_SIZE_OPTIONS,
  columnDefinitions,
  LIST_EXECUTION_VISIBLE_CONTENT_OPTIONS,
} from './constants';
import { isError } from "../../ErrorHandler/apiErrorHandler";
import { ErrorMessageComponent } from "../../ErrorHandler/errorMessageComponent";
import { ApiList } from "../../ErrorHandler/utils";
import {useListExecutionsQuery} from "./useListExecutionsQuery";
import {useRequestOnDemandDataMutation} from "./useRequestOnDemandData";
import { useGetOnDemandDomainQuery } from './useGetOnDemandDomainQuery';

const { forwardRef, useImperativeHandle } = React;

const ListExecutions = forwardRef((props: any, ref) => {
  const appContext = useContext(AppContext);
  const [errorMessage, setErrorMessage] = useState(null)
  const params = useParams();
  const clientId = params['clientId'];
  const domainName = params['domainName'];
  const domainId = `${clientId}:${domainName}`
  const [reBackfill, setReBackfill] = useState(false)
  const [flashbarItems, setFlashbarItems] = useState([]);
  const [warningFlashbarItems, setWarningFlashbarItems] = useState([])
  const [getOnDemandDomain, { loading: getDomainLoading, data: getDomainResponse, error: getDomainError }] = useGetOnDemandDomainQuery()
  const domainResponse = get(getDomainResponse, 'getOnDemandDomain', '')
  const dashboardEndpoint = `https://${domainResponse}/_dashboards`
  const [listExecutions, { loading: executionsLoading, data: executionList, error: error }] = useListExecutionsQuery();
  const [preferences, setPreferences] = useState(DEFAULT_ELB_PREFERENCES);
  const [domainQuery, setDomainQuery] = useState(false)
  const [requestOnDemandLogs] = useRequestOnDemandDataMutation();

  const isErrorPresent = isError(error)
   useEffect(() => {
    if (isErrorPresent) {
      setErrorMessage("Unable to get list of executions. Failed with " + error.message);
    }
  }, [isErrorPresent, error]);

  const executionListItems = get(executionList, 'listExecutions', []).filter(item => {
    return item.record.Type === props.type
  });

  const filteredItems = useMemo(() => {
    const flattened_list = []
    for(let i=0 ; i< executionListItems.length; i++)
    {
      flattened_list.push(executionListItems[i].record)
    }
    if (props.startTime === undefined && props.endTime === undefined) {
      return flattened_list
    }
    const filtered = flattened_list.filter((item) => {
      const formattedStartTimeStamp = moment.utc(props.startTime).format('YYYYMMDDTHHmm') + 'Z';
      const formattedEndTimeStamp = moment.utc(props.endTime).format('YYYYMMDDTHHmm') + 'Z';
      const withinStartTime = props.startTime ? item.StartTime === formattedStartTimeStamp : true;
      const withinEndTime = props.endTime ? item.EndTime === formattedEndTimeStamp : true;
      const OnDemandType = props.type ? item.Type === props.type : true;
      return withinStartTime && withinEndTime && OnDemandType;
    });
    // If filtered list is empty, return an empty array
    return filtered.length > 0 ? filtered : [];
  }, [executionListItems, props.startTime, props.endTime]);

  let tableHeader = 'ELB Logs'
  let DEFAULT_PREFERENCES = DEFAULT_ELB_PREFERENCES
  if (props.type === 'PA')
  {
    tableHeader = 'PA Logs'
    DEFAULT_PREFERENCES = DEFAULT_PA_PREFERENCES
  }
  if(props.startTime)
  {
    tableHeader = 'Your request'
  }
  

  const handleExecutionListRefresh = () => {
    listExecutions({
      variables: {
        domainId: clientId + ':' + domainName
      },
    });
  };

  useEffect(() => {
    listExecutions({
      variables: {
        domainId: clientId + ':' + domainName
      },
    });
    setDomainQuery(true)
  }, []);

  useEffect(() => {
    getOnDemandDomain({ variables: {
        domainId: domainId
      }
    });
  },[domainQuery])

  const handleRebackfillClick = (item) => {
    requestOnDemandLogs({ variables: {
        domainId: clientId + ':' + domainName,
        eventType: item.Type,
        startTime: item.StartTime,
        endTime: item.EndTime,
        reBackfill: 'true'
      }
    });
    setReBackfill(true)
    setFlashbarItems([
        {
          type: 'info',
          content: 'ReBackfilling started.',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setFlashbarItems([]),
        },
      ]);
  };

  useImperativeHandle(ref, () => ({
    callRefreshExecutionList: () => {
      handleExecutionListRefresh();
    },
  }));

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(executionsLoading ? [] : filteredItems, {
    propertyFiltering: {
      filteringProperties: LIST_EXECUTION_FILTERING_PROPERTIES,
      empty: (
        <EmptyState
          title="No Backfill Request"
          subtitle="No execution List to display."
          action={<span></span>}
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can’t find a match."
          action={
            <Button onClick={() => actions.setFiltering('')}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  if (!getDomainLoading) {
    let insertIndex = columnDefinitions.length - 1;
    if (!columnDefinitions.some((c) => c.id === 'logs')) {
      columnDefinitions.splice(insertIndex, 0, {
        id: 'logs',
        header: 'Logs',
        cell: (e) => {
          const file = e.ExecutionId;
          if (e.BackfillStatus === 'Completed' || e.BackfillStatus === 'PartiallyCompleted') {
            return (
                (
                    <Popover
                        dismissAriaLabel="Close"
                        fixedWidth
                        header={'Show logs'}
                        position="right"
                        size="large"
                        triggerType="text"
                        renderWithPortal={true}
                        content={
                          <div style={{ overflowY: 'auto', maxHeight: '500px' }}>
                            <SpaceBetween direction="horizontal" size="l">
                              <Link
                                  to={{
                                    pathname: `/${clientId}/${domainName}/ondemand/${e.ExecutionId}`
                                  }}
                                  target="_blank"
                              >
                                {e.Type}
                              </Link>
                            </SpaceBetween>
                          </div>
                        }
                    >
                      {"Show Logs"}
                    </Popover>
                ) || '--'
            );
          } else {
            return <p>{'-'}</p>;
          }
        },
        sortingField: 'logs',
      });
    }
    insertIndex++;

    if (!columnDefinitions.some((c) => c.id === 'aggregations')){
      columnDefinitions.splice(insertIndex, 0, {
        id: 'aggregations',
        header: 'Aggregations',
        cell: (e) => {
            return (
                (
                    <Popover
                        dismissAriaLabel="Close"
                        fixedWidth
                        header={'Request Aggregations'}
                        position="right"
                        size="large"
                        triggerType="text"
                        renderWithPortal={true}
                        content={
                          <div style={{ overflowY: 'auto', maxHeight: '500px' }}>
                            <SpaceBetween direction="horizontal" size="l">
                              <Link
                                  to={{
                                    pathname: `/${clientId}/${domainName}/elb/aggregations`
                                  }}
                                  target="_blank"
                              >
                                Aggregations
                              </Link>
                            </SpaceBetween>
                          </div>
                        }
                    >
                      {"Request Aggregations"}
                    </Popover>
                ) || '--'
            );
        },
        sortingField: 'aggregations',
      });
    }

  }

  return (
    <SpaceBetween size="l" direction="vertical">
        {errorMessage ? (
        <ErrorMessageComponent errorMessage={errorMessage} apiName={ApiList.LIST_EXECUTIONS}/>
      ) : (
        <>
        {reBackfill &&
          (<Flashbar items={flashbarItems} />)
        }
        {props.startTime &&
          (<Flashbar items={warningFlashbarItems} />)
        }
      <Table
        {...collectionProps}
        header={
            <>
              <Header
                counter={`(${items.length})`}
                actions={
                  <SpaceBetween direction="horizontal" size="l">
                    <Button iconName="refresh" onClick={handleExecutionListRefresh} />
                  </SpaceBetween>
                }
              >
                {tableHeader}
              </Header>
              {props.type === "PA" && (
                <SpaceBetween size="m" direction="horizontal">
                  <div>
                    <Header variant="h3">
                      Kibana Dashboard Link:{" "}
                      <a href={`https://${domainResponse}/_dashboards`} target="_blank" rel="noopener noreferrer">
                        PA Data
                      </a>
                    </Header>
                    <p>
                      The above dashboard contains aggregations around PA Data. Login to the dashboard and follow the below steps:
                    </p>
                    <ul>
                      <li>1: Authenticate yourself by signing with your corporate ID i.e by clicking on 'Amazon'.</li>
                      <li>2: Click on the sidebar. Click on Dashboard and open 'perfTop' dashboards according to your usecase.</li>
                      <li>3: Add the domain ident in the filter for which you want to see the aggregations.</li>
                      <li>4: Choose the timestamp between which you requested the PA data.</li>
                    </ul>
                  </div>
                </SpaceBetween>
              )}
            </>
          }
        columnDefinitions={[
          ...columnDefinitions,
          {
            id: 'KibanaLink',
            header: 'KibanaLink',
            cell: (e) => (
                <a href={dashboardEndpoint} target="_blank" rel="noopener noreferrer">
                  Kibana dashboard link
                </a>
            ),
          },
            {
          id: 're_backfill',
          header: 'Re Backfill',
          cell: (e) => (
            <Button
                onClick={() => handleRebackfillClick(e)}
                disabled={e.BackfillStatus === 'Completed' || e.BackfillStatus === 'started'}
            >
              Re-backfill
            </Button>
          ),
        }
        ]}
        stickyHeader={true}
        resizableColumns={false}
        wrapLines={preferences.wraplines}
        loading={executionsLoading}
        loadingText={
          executionsLoading ? 'Loading execution list...' : 'There were no logs requested.'
        }
        visibleColumns={preferences.visibleContent}
        items={items}
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
          />
        }
        filter={
          <div className="input-container">
            <PropertyFilter
              className="input-filter"
              i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
              {...propertyFilterProps}
              countText={getFilterCounterText(filteredItemsCount)}
            />
          </div>
        }
        preferences={
          <Preferences
            preferences={preferences}
            setPreferences={setPreferences}
            disabled={false}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            visibleContentOptions={LIST_EXECUTION_VISIBLE_CONTENT_OPTIONS}
          />
        }
      />
       </>
      )}
    </SpaceBetween>
  );
});

export { ListExecutions };
