import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../../models/types';

interface DomainProgressVars {
    domainId: string;
    changeId : string
  }

const GQL_BG_PPROGRESS = gql`
  query getBGProgress($domainId: String,$changeId: String) {
    progress(domainId: $domainId, changeId: $changeId)
  }
`;


export const useDomainProgressQuery = () => {
    return useLazyQuery<AnyObject, DomainProgressVars>(GQL_BG_PPROGRESS, {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
    });
};
