import { Button } from '@amzn/awsui-components-react';
import { get } from 'lodash';
import { useHistory } from 'react-router';
import { TableComponent } from '../../../Components/Common/TableComponent';

const WorkersListForAccount = (props) => {
  const history = useHistory();
  // Workers table configs

  const workersListVisibleContent = [
    'workerId',
    'smHeartbeatCount',
    'generation',
    'placementConstraint',
    'workerMetadata',
    'cpuUtilizationPercent',
    'diskAvailableSpaceGb',
    'diskTotalSpaceGb',
    'memoryUtilizationPercent',
  ];

  const WORKERS_LIST_VISIBLE_CONTENT_OPTIONS = [
    {
      label: 'Options',
      options: workersListVisibleContent.map((c) => {
        return { id: c, label: c };
      }),
    },
  ];

  const WORKERS_LIST_FILTERING_PROPERTIES: any = workersListVisibleContent.map(
    (c) => {
      return {
        propertyLabel: c,
        key: c,
        groupValuesLabel: `${c} Values`,
        operators:
          c === 'CreatedAt' || c === 'UpdatedAt'
            ? [':', '!:', '=', '!=', '>', '>=', '<', '<=']
            : [':', '!:', '=', '!='],
      };
    }
  );

  const WORKERS_LIST_DEFAULT_PREFERENCES = {
    pageSize: 10,
    visibleContent: workersListVisibleContent,
    wraplines: false,
  };

  const workersListColumnDefinitions = [
    {
      id: 'workerId',
      header: 'Worker Id',
      cell: (e) => (
        <Button
          href={`/juno/worker/${props.awsAccountId}/${props.dataPlaneType}/${e.workerId}`}
          variant="link"
          onFollow={(event) => {
            event.preventDefault();
            history.push({
              pathname: `/juno/worker/${props.awsAccountId}/${props.dataPlaneType}/${e.workerId}`,
              state: {
                awsAccountId: props.awsAccountId,
                workerId: e.workerId,
                dataPlaneType: props.dataPlaneType,
              },
            }); // Add your state here...
          }}
        >
          <span style={{ color: '#00a1c9' }}>
            {e['workerId'] ? e['workerId'] : ''}
          </span>
        </Button>
      ),
      sortingField: 'workerId',
    },
    {
      id: 'generation',
      header: 'Generation',
      cell: (e) => (e['generation'] ? e['generation'] : ''),
      sortingField: 'generation',
    },
    {
      id: 'placementConstraint',
      header: 'PlacementConstraint',
      cell: (e) => (
          <Button
              href={`/juno/pci/${props.awsAccountId}/${e.placementConstraint}`}
              variant="link"
              onFollow={(event) => {
                event.preventDefault();
                history.push({
                  pathname: `/juno/pci/${props.awsAccountId}/${e.placementConstraint}`,
                  state: {
                    awsAccountId: props.awsAccountId,
                    placementConstraintId: e.placementConstraint,
                    namespace: 'default',
                  },
                }); // Add your state here...
              }}
          >
          <span style={{ color: '#00a1c9' }}>
            {e['placementConstraint'] ? e['placementConstraint'] : ''}
          </span>
          </Button>
      ),
      sortingField: 'placementConstraint',
    },
    {
      id: 'workerMetadata',
      header: 'AvailabilityZone',
      cell: (e) =>
        e['workerMetadata'] ? e['workerMetadata']['availabilityZone'] : '',
      sortingField: 'UpdatedAt',
    },
    {
      id: 'cpuUtilizationPercent',
      header: 'CpuUtilizationPercent',
      cell: (e) =>
        e['measurements']
          ? e['measurements']['cpuUtilizationPercent']
            ? e['measurements']['cpuUtilizationPercent'].toFixed(2)
            : ''
          : '',
      sortingField: 'cpuUtilizationPercent',
    },
    {
      id: 'diskAvailableSpaceGb',
      header: 'DiskAvailableSpaceGb',
      cell: (e) =>
        e['measurements']
          ? e['measurements']['diskAvailableSpaceGb']
            ? e['measurements']['diskAvailableSpaceGb'].toFixed(2)
            : ''
          : '',
      sortingField: 'diskAvailableSpaceGb',
    },
    {
      id: 'diskTotalSpaceGb',
      header: 'DiskTotalSpaceGb',
      cell: (e) =>
        e['measurements']
          ? e['measurements']['diskTotalSpaceGb']
            ? e['measurements']['diskTotalSpaceGb'].toFixed(2)
            : ''
          : '',
      sortingField: 'diskTotalSpaceGb',
    },
    {
      id: 'memoryUtilizationPercent',
      header: 'MemoryUtilizationPercent',
      cell: (e) =>
        e['measurements']
          ? e['measurements']['memoryUtilizationPercent']
            ? e['measurements']['memoryUtilizationPercent'].toFixed(2)
            : ''
          : '',
      sortingField: 'memoryUtilizationPercent',
    },
    {
      id: 'smHeartbeatCount',
      header: 'SMHeartbeatCount',
      cell: (e) =>
        e['measurements'] ? e['measurements']['smHeartbeatCount'] : '',
      sortingField: 'smHeartbeatCount',
    },
  ];

  return (
    <TableComponent
      tableDefaultPreferences={WORKERS_LIST_DEFAULT_PREFERENCES}
      dataLoading={props.workersListForAccountLoading}
      data={
        Array.isArray(props.workersListForAccount)
          ? props.workersListForAccount
          : get(props.workersListForAccount, 'getWorkersListForAccount', [])
      }
      filteringProperties={WORKERS_LIST_FILTERING_PROPERTIES}
      csvExportFileName={`${props.dataPlaneType}workersList.csv`}
      columnDefinitions={workersListColumnDefinitions}
      visibleContentOptions={WORKERS_LIST_VISIBLE_CONTENT_OPTIONS}
      tableHeaderName={`${props.dataPlaneType} Workers`}
    />
  );
};

export { WorkersListForAccount };
