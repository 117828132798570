export const OpsTransparencyNavbarTitle = "Ops Transparency"

export const OpsTransparencyNavbarOptions = [
    {
      label: 'Report a bug',
      value: 'https://tiny.amazon.com/r2102p8o/issuamazissucrea',
    },
    {
      label: 'New Feature Request',
      value: 'https://tiny.amazon.com/1563wef8c',
    },
    {
      label: 'Release History',
      value:
        'https://w.amazon.com/bin/view/AWS/SearchServices/OpsTumbler/Release',
    },
    {
      label: 'User Guide',
      value:
        'https://w.amazon.com/bin/view/AWS/SearchServices/InfrastructureEngineering/OperatorTransparency/Procedures/SOPforOperatorTransparencySystem',
    },
]

export const HOME_PATH = "/ops-transparency";

export const ONBOARDED_PATH = HOME_PATH + "/onboarded";

export const VERIFY_ALL_PATH = HOME_PATH + "/verify/all";

export const VERIFY_ACTIONABLE_ALL_PATH = HOME_PATH + "/verify/actionable/all";

export const ONBOARDING_PATH = HOME_PATH +"/onboarding/:aws_account_id";