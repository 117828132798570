import { Box, Spinner } from '@amzn/awsui-components-react';
import { Dashboard } from '@amzn/cloudwatchdashboards-inside';
import { EuiButtonEmpty } from '@elastic/eui';
import React, { useEffect, useRef, useState } from 'react';
import { dashboardConfig } from './utils';

export interface CloudwatchWidget {
  type: string;
  properties: {
    metrics: string | object[];
    title: string;
    region: string;
  };
}

interface CloudWatchProps {
  widgets: CloudwatchWidget[];
  region: string;
  rootAccountId: string;
  isOptInRegion: boolean;
}

const CloudWatch = (props: CloudWatchProps) => {
  const [gridview, setGridview] = useState<boolean>(false);
  const dashboard = useRef<{ [key: string]: any }>({});
  const dashboardContainer = useRef(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    dashboard.current = new Dashboard(
      //@ts-ignore
      dashboardContainer.current,
      'EmbedInternal',
      props.isOptInRegion ? 'us-east-1' : props.region, // TODO:: Check if all region works with us-east-1 then replace it static us-east-1
      dashboardConfig(props.region, props.rootAccountId),
      {}
    );
    dashboard.current.on('ready', () => {
      setLoading(false);
      dashboard.current.updateStyle({
        'min-height': '800px',
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dashboard.current.update(
      { widgets: props.widgets },
      {
        widgetsPerRow: gridview ? 3 : 1,
        widgetHeightInUnits: 5,
      }
    );
  }, [props.widgets, gridview]);
  return (
    <React.Fragment>
      {loading ? (
        <Box textAlign="center">
          <Spinner size="large" />
        </Box>
      ) : null}
      <div>
        {!loading ? (
          <React.Fragment>
            <Box float="left">
              <p>
                This tab supports only 2 timezones and can be changed using
                below custom dropdown button. UTC is default timezone.
              </p>
            </Box>
            <Box float="right">
              <EuiButtonEmpty
                size="s"
                flush="left"
                iconSide="left"
                color="text"
                textProps={{
                  style: { overflow: 'initial' },
                }}
                onClick={() => setGridview(!gridview)}
                iconType={gridview ? 'visTable' : 'grid'}
              >
                Toggle dashboard layout
              </EuiButtonEmpty>
            </Box>
          </React.Fragment>
        ) : null}
        <div ref={dashboardContainer} />
      </div>
    </React.Fragment>
  );
};

export { CloudWatch };
