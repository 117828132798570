import { gql, useLazyQuery } from '@apollo/client';

interface DetailPageFetchingData {
  lseTool: JSON;
}

interface DetailPageRequestingData {
  lseTool: String;
}

export interface detailPageFetchingVars {
  key: String;
}

export interface detailPageRequestingVars {
  endTimeUtc: String;
  timeWindow: String;
  interval: String;
  issueTypes: String;
  lseStartTimeUtc: String;
  lseEndTimeUtc: String;
}

const DETAIL_PAGE_FETCHING_DATA = gql`
  query getDetailPageData($key: String) {
    detailPageFetchingData(key: $key)
  }
`;

const DETAIL_PAGE_REQUESTING_DATA = gql`
  query sendDetailPageReqeust(
    $endTimeUtc: String
    $timeWindow: String
    $interval: String
    $issueTypes: String
    $lseStartTimeUtc: String
    $lseEndTimeUtc: String
  ) {
    detailPageRequestingData(
      endTimeUtc: $endTimeUtc
      timeWindow: $timeWindow
      interval: $interval
      issueTypes: $issueTypes
      lseStartTimeUtc: $lseStartTimeUtc
      lseEndTimeUtc: $lseEndTimeUtc
    )
  }
`;

export const useDetailPageDataFetchingQuery = () => {
  return useLazyQuery<DetailPageFetchingData, detailPageFetchingVars>(
    DETAIL_PAGE_FETCHING_DATA,
    {
      errorPolicy: 'all',
    }
  );
};

export const useDetailPageDataRequestingQuery = () => {
  return useLazyQuery<DetailPageRequestingData, detailPageRequestingVars>(
    DETAIL_PAGE_REQUESTING_DATA,
    {
      errorPolicy: 'all',
    }
  );
};
