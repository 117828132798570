import React from 'react';
import { apiCall, convertEpochToDate } from '../../utils';
import { Component } from '../Component/Component';
import { ClusterInSync } from './ClusterInSync';
import { ClusterNodes } from './ClusterNodes';
import { ClusterFeature } from './ClusterFeature';
import { StackDetails } from '../Stack/StackDetails';
import { ComponentVersionDetails } from '../ComponentVersion/ComponentVersionList';
import { DeployComponent } from './Component/DeployComponent';
import { RemoveComponent } from './Component/RemoveComponent';

import {
  EuiTitle,
  EuiButtonIcon,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiTable,
  EuiTableBody,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell,
  EuiLoadingSpinner,
  EuiPanel,
  EuiButtonEmpty,
  EuiButton,
} from '@elastic/eui';

export class ClusterDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clusterId: props.clusterId,
      isRequestActive: true,
      isResponseUpdated: false,
      isModalVisible: false,
      isStackModalVisible: false,
      createdBy: '',
      lastModifiedBy: '',
      lastModificationTime: '',
      componentName: [],
      componentVersion: [],
      isRemoveComponentActive: false,
      selectedComponent: '',
      selectedStack: '',
      statusCode: '',
      headers: '',
      exception: '',
      items: [],
    };
    this.componentMap = new Map();
    this.showModal = this.showModal.bind(this);
    this.updateDeletedComponent = this.updateDeletedComponent.bind(this);
    this.deleteComponent = this.deleteComponent.bind(this);
    this.showDeployComponentModal = this.showDeployComponentModal.bind(this);
    this.closeDeployComponentModal = this.closeDeployComponentModal.bind(this);
  }

  componentDidMount() {
    this.getClusterDetails();
  }

  componentDidUpdate() {
    if (this.state.isRequestActive) {
      this.getClusterDetails();
    }
  }

  updateResult(response) {
    let i;
    let majorVersion;
    let minorVersion;
    let time;
    let componentScope;
    let componentScopeValue;
    const componentNameList = [];
    const componentVersion = [];
    let component;
    let componentName;

    this.componentMap = new Map();
    response = response.body;
    const length = response.clusterDescription.componentVersionsList.length;
    time = response.clusterDescription.auditProperties.lastModificationTime;
    time = convertEpochToDate(time);
    this.setState({
      createdBy: response.clusterDescription.auditProperties.createdBy,
    });
    this.setState({
      lastModifiedBy:
        response.clusterDescription.auditProperties.lastModifiedBy,
    });
    this.setState({ lastModificationTime: time });

    for (i = 0; i < length; i++) {
      component = new Component();
      majorVersion =
        response.clusterDescription.componentVersionsList[i].majorVersion;
      minorVersion =
        response.clusterDescription.componentVersionsList[i].minorVersion;
      componentName =
        response.clusterDescription.componentVersionsList[i].componentName;
      componentScope =
        response.clusterDescription.componentVersionsList[i]
          .componentScopeDefinition.componentScope;
      componentScopeValue =
        response.clusterDescription.componentVersionsList[i]
          .componentScopeDefinition.componentScopeValue;

      componentNameList.push(componentName);
      componentVersion.push(
        response.clusterDescription.componentVersionsList[i].componentName +
          '-' +
          majorVersion +
          '-' +
          minorVersion
      );
      component.setComponentDetails(
        componentName,
        majorVersion,
        minorVersion,
        componentScope,
        componentScopeValue
      );
      this.componentMap.set(componentName, component);
    }

    this.setState({ componentName: componentNameList });
    this.setState({ componentVersion: componentVersion });
  }

  updateException(response) {
    this.setState({ headers: JSON.stringify(response.headers) });
    this.setState({ exception: JSON.stringify(response.body) });
  }

  updateResponse(response) {
    this.setState({ statusCode: response.statusCode });
    if (response.statusCode === 200) {
      this.updateResult(response);
    } else {
      this.updateException(response);
    }
  }

  getClusterDetails = async () => {
    const data = {
      cluster: {
        stackName: this.props.stackName,
        clusterId: this.props.clusterId,
      },
    };
    const response = await apiCall('/cluster/describe', data);
    this.setState({ isRequestActive: false });
    this.updateResponse(response);
    this.setState({ isResponseUpdated: true });
  };

  closeModal = () => {
    this.setState({ isModalVisible: false });
  };

  showModal(event) {
    if (event.target.id === '') {
      return;
    }
    this.setState({ selectedComponent: event.target.id });
    this.setState({ isModalVisible: true });
  }

  showDeployComponentModal() {
    this.setState({ isDeployComponentVisible: true });
  }

  closeDeployComponentModal() {
    this.setState({ isDeployComponentVisible: false });
    this.setState({ isRequestActive: true });
  }

  closeStackModal = () => {
    this.setState({ isStackModalVisible: false });
  };

  showStackModal(event) {
    this.setState({ selectedStack: event.target.id });
    this.setState({ isStackModalVisible: true });
  }

  displayStackName() {
    const stackname = [];
    stackname.push(
      <span>
        <EuiButtonEmpty
          flush="left"
          textProps={{ id: this.props.stackname }}
          id={this.props.stackName}
          onClick={(event) => {
            this.showStackModal(event);
          }}
        >
          {this.props.stackName}
        </EuiButtonEmpty>
      </span>
    );
    return stackname;
  }

  deleteComponent = (event) => {
    const componentObject = this.componentMap.get(event.target.name);
    this.componentDetails = componentObject.getComponentDetails();
    this.setState({ isRemoveComponentActive: true });
  };

  updateDeletedComponent() {
    this.setState({ isRemoveComponentActive: false });
    this.setState({ isRequestActive: true });
  }

  displayComponent() {
    let i;
    const components = [];
    const length = this.state.componentVersion.length;
    for (i = 0; i < length; i++) {
      components.push(
        <span>
          <EuiButtonEmpty
            flush="left"
            textProps={{ id: this.state.componentName[i] }}
            id={this.state.componentName[i]}
            onClick={(event) => {
              this.showModal(event);
            }}
          >
            {this.state.componentVersion[i]}
          </EuiButtonEmpty>
          {!this.props.hideDeleteComponent && (
            <EuiButtonIcon
              iconType="trash"
              aria-label="removecomponent"
              color="danger"
              name={this.state.componentName[i]}
              id="removecomponent"
              onClick={this.deleteComponent}
            />
          )}
        </span>
      );
    }
    return components;
  }

  displayException() {
    return (
      <div>
        <EuiPanel>
          <b>statusCode:</b>
          {this.state.statusCode}
          <br />
          <br />
          <b>headers:</b>
          {this.state.headers}
          <br />
          <br />
          <b>body:</b>
          {this.state.exception}
        </EuiPanel>
      </div>
    );
  }

  displayClusterDetails() {
    return (
      <div>
        <EuiTable>
          <EuiTableBody>
            <EuiTableRow>
              <EuiTableHeaderCell>{'Property'}</EuiTableHeaderCell>
              <EuiTableHeaderCell>{'Value'}</EuiTableHeaderCell>
            </EuiTableRow>
            <EuiTableRow>
              <EuiTableHeaderCell>{'Stack Name'}</EuiTableHeaderCell>
              <EuiTableRowCell>
                {this.displayStackName()}
                {this.displayStackDetails()}
              </EuiTableRowCell>
            </EuiTableRow>
            <EuiTableRow>
              <EuiTableHeaderCell>Cluster Id </EuiTableHeaderCell>
              <EuiTableRowCell>{this.props.clusterId}</EuiTableRowCell>
            </EuiTableRow>
            <EuiTableRow>
              <EuiTableHeaderCell>Cluster Sync Status</EuiTableHeaderCell>
              <EuiTableRowCell align={'left'}>
                <ClusterInSync
                  stackName={this.props.stackName}
                  clusterId={this.props.clusterId}
                />
              </EuiTableRowCell>
            </EuiTableRow>
            <EuiTableRow>
              <EuiTableHeaderCell>Created By </EuiTableHeaderCell>
              <EuiTableRowCell>{this.state.createdBy}</EuiTableRowCell>
            </EuiTableRow>
            <EuiTableRow>
              <EuiTableHeaderCell>Last Modified By </EuiTableHeaderCell>
              <EuiTableRowCell>{this.state.lastModifiedBy}</EuiTableRowCell>
            </EuiTableRow>
            <EuiTableRow>
              <EuiTableHeaderCell>Last Modification Time </EuiTableHeaderCell>
              <EuiTableRowCell>
                {this.state.lastModificationTime}
              </EuiTableRowCell>
            </EuiTableRow>
            <EuiTableRow>
              <EuiTableHeaderCell> Components </EuiTableHeaderCell>
              <EuiTableRowCell>
                {this.displayComponent()}
                {this.displayComponentVersionDetails()}
                {this.state.isRemoveComponentActive && (
                  <RemoveComponent
                    stackName={this.props.stackName}
                    clusterId={this.props.clusterId}
                    componentDetails={this.componentDetails}
                    updateDeletedComponent={this.updateDeletedComponent}
                  />
                )}
              </EuiTableRowCell>
            </EuiTableRow>
            <EuiTableRow>
              <EuiTableHeaderCell>Node IPs </EuiTableHeaderCell>
              <EuiTableRowCell>
                <ClusterNodes
                  stackName={this.props.stackName}
                  clusterId={this.props.clusterId}
                />
              </EuiTableRowCell>
            </EuiTableRow>
            <EuiTableRow>
              <EuiTableHeaderCell>Feature Tag</EuiTableHeaderCell>
              <EuiTableRowCell>
                <ClusterFeature
                  stackName={this.props.stackName}
                  clusterId={this.props.clusterId}
                />
              </EuiTableRowCell>
            </EuiTableRow>
          </EuiTableBody>
        </EuiTable>
        {!this.props.hideDeployComponent && (
          <EuiButton id="button" onClick={this.showDeployComponentModal}>
            Deploy Component
          </EuiButton>
        )}
        {this.displayDeployComponent()}
      </div>
    );
  }

  displayDeployComponent() {
    let modal = '';
    const width = 1500;
    if (this.state.isDeployComponentVisible) {
      modal = (
        <EuiOverlayMask>
          <EuiModal onClose={this.closeDeployComponentModal} maxWidth={width}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>Deploy Component</EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
              <DeployComponent
                stackName={this.props.stackName}
                clusterId={this.props.clusterId}
              />
            </EuiModalBody>
          </EuiModal>
        </EuiOverlayMask>
      );
    }
    return modal;
  }

  displayStackDetails() {
    let model = '';
    if (this.state.isStackModalVisible) {
      model = (
        <EuiOverlayMask>
          <EuiModal onClose={this.closeStackModal} id="stackdetails">
            <EuiModalHeader>
              <EuiModalHeaderTitle>Stack Details</EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
              <StackDetails stackName={this.props.stackName} />
            </EuiModalBody>
          </EuiModal>
        </EuiOverlayMask>
      );
    }
    return model;
  }

  displayComponentVersionDetails() {
    let modal = '';
    const componentName = this.state.selectedComponent;

    if (this.state.isModalVisible) {
      const componentObject = this.componentMap.get(componentName);
      const componentDetails = componentObject.getComponentDetails();
      modal = (
        <EuiOverlayMask>
          <EuiModal id="componentversion" onClose={this.closeModal}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>Component Details</EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
              <ComponentVersionDetails
                componentName={this.state.selectedComponent}
                componentScope={componentDetails.componentScope}
                componentScopeValue={componentDetails.componentScopeValue}
                stackName={this.props.stackName}
              />
            </EuiModalBody>
          </EuiModal>
        </EuiOverlayMask>
      );
    }
    return modal;
  }

  displayLoadSpinner() {
    return <EuiLoadingSpinner size="xl" id="loadingSpinner" />;
  }

  render() {
    let content = this.displayLoadSpinner();
    if (this.state.isResponseUpdated) {
      if (this.state.statusCode === 200) {
        content = this.displayClusterDetails();
      } else {
        content = this.displayException();
      }
    }

    return (
      <div>
        <EuiTitle size="m">
          <h2>Cluster Details</h2>
        </EuiTitle>
        <br />
        {content}
        {this.displayDeployComponent()}
      </div>
    );
  }
}
