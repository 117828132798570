import {
  Autosuggest,
  Button,
  Container,
  Flashbar,
  FormField,
  Grid,
  Header,

  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { CodeViewer } from '../../Components/Common/CodeViewer';
import { DISCLOSURE_MESSAGE } from '../../constants';
import { CommonDomainProps } from '../../models/types';
import { AutosuggestOptionsType } from './../../utils/constants';
import { APIS, API_OPTIONS } from './constants';
import { useExecuteApiMutation } from '../../hooks/useExecuteApiMutation';

interface ApiExplorerProps extends CommonDomainProps {
  apiRoot: AutosuggestOptionsType;
  apiPath: AutosuggestOptionsType;
  domainIdentifier: string;
  instanceId: string;
}

interface ApiExplorerUiState {
  apiRoot: AutosuggestOptionsType;
  apiPath: AutosuggestOptionsType;
}

const ApiExplorer = (props: ApiExplorerProps) => {
  const [executeApi, { loading }] = useExecuteApiMutation();
  const [apiResponse, setApiResponse] = useState<string | object>('');

  const [formValues, setFormValues] = useState<ApiExplorerUiState>({
    apiRoot: props.apiRoot,
    apiPath: props.apiPath,
  });
  const handleExecuteApi = async () => {
    try {
      const apiRoot = get(formValues, 'apiRoot.value', '_cat');
      const apiPath = get(formValues, 'apiPath.value', '').split('?')[0];
      const queryParams = get(formValues, 'apiPath.value', '').split('?')[1];
      const tableFormat = `format=v${formValues.apiRoot.value === '_cat' ? '&v' : ''
        }`;
      const resp = await executeApi({
        variables: {
          url: `${apiRoot}/${apiPath}`,
          domainIdentifier: props.domainIdentifier,
          instanceId: props.instanceId,
          queryParams: queryParams
            ? `${queryParams}&${tableFormat}`
            : tableFormat,
        },
      });

      let esResponse = get(resp, 'data.executeGetAPI.data', '');
      try {
        esResponse = JSON.parse(esResponse);
      } catch (e) {
        // Ignoring error response could be in text format
      } finally {
        setApiResponse(esResponse);
      }
    } catch (e) {
      // TODO
    }
  };
  // const handleCreatePath = (searchValue: string, flattenedOptions: any[]) => {
  //   if (!searchValue)
  //   {
  //     return;
  //   }
  //   const normalizedSearchValue = searchValue.trim().toLowerCase();
  //   if (!normalizedSearchValue)
  //   {
  //     return;
  //   }
  //   const newOption = {
  //     label: searchValue,
  //   };
  //   // Create the option if it doesn't exist.
  //   if (
  //     flattenedOptions.findIndex(
  //       (option: any) =>
  //         option.label.trim().toLowerCase() === normalizedSearchValue
  //     ) === -1
  //   )
  //   {
  //     APIS[formValues.apiRoot[0].label.substring(1)].push(newOption);
  //   }
  //   setFormValues((state) => ({
  //     ...state,
  //     apiPath: [newOption],
  //   }));
  // };

  useEffect(() => {
    if (props.apiRoot && props.apiPath) {
      handleExecuteApi();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.apiRoot, props.apiPath]);
  return (
    <Container
      header={
        <div>
          <Header variant="h2">API Explorer</Header>
          <Flashbar items={[
            {
              type: "error",
              content: DISCLOSURE_MESSAGE,
            }
          ]} />
        </div>
      }
    >
      <SpaceBetween direction="vertical" size="l">
        <Grid
          gridDefinition={[
            { colspan: { default: 5, xxs: 5 } },
            { colspan: { default: 5, xxs: 5 } },
            { colspan: { default: 7, xxs: 2 } },
          ]}
        >
          <FormField
            constraintText="Choose root API from list. These APIS are at cluster level i.e (http://localhost:9200/_cat/)"
            label={<strong>Root API</strong>}
          >
            <Select
              selectedOption={formValues.apiRoot}
              onChange={(selectedApiRoot) => {
                console.log(selectedApiRoot.detail.selectedOption);
                setFormValues((state) => ({
                  ...state,
                  apiRoot: selectedApiRoot.detail
                    .selectedOption as AutosuggestOptionsType,
                  apiPath: { value: '' } as AutosuggestOptionsType,
                }));
              }}
              options={API_OPTIONS}
              selectedAriaLabel="Selected"
              placeholder="Choose root API"
            />
          </FormField>
          <FormField
            constraintText="Choose API path or add custom path"
            label={<strong>Path</strong>}
          >
            <Autosuggest
              onChange={(selectedApiPath) => {
                setFormValues((state) => ({
                  ...state,
                  apiPath: selectedApiPath.detail,
                }));
              }}
              value={formValues.apiPath.value}
              options={APIS[formValues.apiRoot.value.substring(1)]}
              enteredTextLabel={(value) => `Current Path: "${value}"`}
              ariaLabel="Autosuggest example with suggestions"
              placeholder="Choose or create path"
              empty="Choose or create path"
            />
          </FormField>
          <div style={{ marginTop: "26px" }}>
            <Button variant="normal" onClick={handleExecuteApi} loading={loading}>
              Execute
            </Button>
          </div>
        </Grid>
        <CodeViewer
          codeData={
            typeof apiResponse === 'string'
              ? apiResponse
              : JSON.stringify(apiResponse, null, 4)
          }
          language={{ label: 'javascript', value: 'javascript' }}
          theme={{ label: 'coy', value: 'coy' }}
        />
      </SpaceBetween>
    </Container>
  );
};

ApiExplorer.defaultProps = {
  apiRoot: { value: '_cat' },
  apiPath: { value: 'master' },
} as Partial<ApiExplorerProps>;

export { ApiExplorer };
