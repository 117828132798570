import { gql, useLazyQuery } from '@apollo/client';
import { KeyEvent } from '../../models/types';

interface KeyEventsData {
  domainKeyEvents: { keyEvents: KeyEvent };
}

export interface keyEventsVars {
  domainId: string;
  startTime: string;
  endTime: string;
}

const KEY_EVENTS = gql`
  query getKeyEvents($domainId: String!, $startTime: String, $endTime: String) {
    domainKeyEvents(
      domainId: $domainId
      startTime: $startTime
      endTime: $endTime
    ) {
      keyEvents {
        event_time
        source
        action
        action_state
        host
        domain_ident
        id
        tagging
        version
        dynamicFields
      }
    }
  }
`;

export const useKeyEventsQuery = () => {
  return useLazyQuery<KeyEventsData, keyEventsVars>(KEY_EVENTS, {
    errorPolicy: 'all',
  });
};
