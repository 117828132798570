import { EuiGlobalToastList } from '@elastic/eui';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import { EuiToastProps } from '@elastic/eui/src/components/toast/toast';
import React, { useCallback } from 'react';

export interface ToastListOptions {
  addDanger: (toast: string | EuiToastProps) => {};
  addError: (error: Error, toast: string | EuiToastProps) => {};
  addSuccess: (toast: string | EuiToastProps) => {};
  addWarning: (toast: string | EuiToastProps) => {};
  remove: (removedToast: Toast) => void;
}

export const ToastListContext = React.createContext<ToastListOptions>(
  {} as ToastListOptions
);

interface FlyoutProps {
  children: React.ReactNode;
}

let toastId = 0;

export const ToastList = (props: FlyoutProps) => {
  const [toasts, setToasts] = React.useState<Toast[]>([]);

  const addWarning = useCallback(
    (toast: string | EuiToastProps) => {
      let newToast: Toast;
      if (typeof toast === 'object') {
        newToast = {
          ...toast,
          id: `toasts_${toastId++}`,
          color: 'warning',
        };
      } else {
        newToast = {
          id: `toasts_${toastId++}`,
          title: toast,
          color: 'warning',
        };
      }
      setToasts((oldToasts) => [...oldToasts, newToast]);
      return newToast;
    },
    [setToasts]
  );

  const addSuccess = useCallback(
    (toast: string | EuiToastProps) => {
      let newToast: Toast;
      if (typeof toast === 'object') {
        newToast = {
          ...toast,
          id: `toasts_${toastId++}`,
          color: 'success',
        };
      } else {
        newToast = {
          id: `toasts_${toastId++}`,
          title: toast,
          iconType: 'check',
          color: 'success',
        };
      }

      setToasts((oldToasts) => [...oldToasts, newToast]);
      return newToast;
    },
    [setToasts]
  );

  const addDanger = useCallback(
    (toast: string | EuiToastProps) => {
      let newToast: Toast;
      if (typeof toast === 'object') {
        newToast = {
          ...toast,
          id: `toasts_${toastId++}`,
          color: 'danger',
        };
      } else {
        newToast = {
          id: `toasts_${toastId++}`,
          title: toast,
          color: 'danger',
        };
      }
      setToasts((oldToasts) => [...oldToasts, newToast]);
      return newToast;
    },
    [setToasts]
  );

  const addError = useCallback(
    (error: Error, toast: string | EuiToastProps) => {
      let newToast: Toast;
      if (typeof toast === 'object') {
        newToast = {
          ...toast,
          id: `toasts_${toastId++}`,
          color: 'danger',
          text: error.message,
        };
      } else {
        newToast = {
          id: `toasts_${toastId++}`,
          title: toast,
          text: toast,
          color: 'danger',
        };
      }
      setToasts((oldToasts) => [...oldToasts, newToast]);
      return newToast;
    },
    [setToasts]
  );

  const remove = useCallback(
    (removedToast: Toast) => {
      setToasts(toasts.filter((toast) => toast.id !== removedToast.id));
    },
    [toasts, setToasts]
  );

  return (
    <ToastListContext.Provider
      value={{
        addError,
        addDanger,
        addSuccess,
        addWarning,
        remove,
      }}
    >
      {props.children}
      <EuiGlobalToastList
        toasts={toasts}
        dismissToast={remove}
        toastLifeTimeMs={60000}
      />
    </ToastListContext.Provider>
  );
};
