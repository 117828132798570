import { Button } from '@amzn/awsui-components-react';
import React, { useEffect } from 'react';
import { LogRedactionFeedbackModal } from './LogRedactionFeedbackModal';

const LogRedactionFeedbackWrapper = (props) => {
  const [visible, setVisible] = React.useState(false);
  useEffect(() => {
    console.log(`${visible} in the use effect.`);
  }, [visible]);
  const closeLogRedactionModal = (isModal) => {
    setVisible(isModal);
  };
  return (
    <React.Fragment>
      <Button variant="link" onClick={() => setVisible(true)}>
        {props.buttonContent}
      </Button>
      {visible ? (
        <LogRedactionFeedbackModal
          visible={visible}
          closeLogRedactionModal={() => closeLogRedactionModal(false)}
          e={props.e}
          isTot={props.isTot}
          domainId={props.domainId}
          account={props.account}
        />
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

export { LogRedactionFeedbackWrapper };
