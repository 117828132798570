import https from 'https';
import axios from 'axios';

export function getRootApiLocation() {
  return '/api/sdpds';
}

export function httpCall(opts) {
  return new Promise((resolve) => {
    const req = https.request(opts, (res) => {
      res.setEncoding('utf8');
      let data = '';
      res.on('data', (chunk) => {
        data += chunk;
      });
      res.on('end', () => {
        const { statusCode, headers, body, error, message } = JSON.parse(data);
        resolve({ statusCode, headers, body: body || error || message });
      });
    });
    req.on('error', () => {
      const response = {
        statusCode: 401,
        body: {
          'Error ': 'Refresh Page to refresh midway cookies',
        },
      };
      resolve(response);
    });
    req.write(opts.body);
    req.end();
  });
}

export function apiCall(path, data) {
  const request = {
    path: `${getRootApiLocation()}${path}`,
    method: 'POST',
    json: true,
    headers: {
      'Content-Type': 'application/json',
      'kbn-xsrf': 'true',
    },
    body: JSON.stringify(data),
  };
  return httpCall(request);
}

export function convertEpochToDate(EpochTime) {
  EpochTime = parseInt(EpochTime);
  const digit = `${EpochTime}`.length;
  if (digit <= 10) {
    EpochTime = EpochTime * 1000;
  }
  const date = new Date(EpochTime);
  return date.toLocaleString('en-IN', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
    timeZoneName: 'short',
  });
}

export function getDefaultStack(config) {
  const stageMap = {
    beta: 'integ',
    gamma: 'staging',
    prod: 'prod',
  };
  const { region, stage } = config;

  //point to SDPDS Gamma for both PDX Beta and Gamma
  if (region === 'us-west-2' && stage === 'gamma') {
    return `swift-us-west-2-integ`;
  }
  return `swift-${region}-${stageMap[stage]}`;
}

const axiosTumblerApiInstance = axios.create({
  baseURL: `/graphql`,
  timeout: 1000 * 60 * 10,
  headers: { 'kbn-xsrf': 'true', 'Content-Type': 'application/json' },
});

export const executeNodeShellCommand = async (command, cluster, node, args) => {
  return await axiosTumblerApiInstance.post(
    '',
    JSON.stringify({
      operationName: 'ExecuteInstanceCommand',
      query:
        'mutation ExecuteInstanceCommand($url: String!, $args: JSON) {\n  executeCommand(url: $url, args: $args)\n}\n',
      variables: {
        args: {
          domain_identifier: cluster,
          ip_address: node,
          truncate_output: false,
          ...args,
        },
        url: command,
      },
    })
  );
};
