import { gql, useQuery } from '@apollo/client';
import { AnyObject } from '../../../models/types';

interface DomainLimitsVars {
  domainIdentifier: string;
}

interface DomainLimitsData {
  domain: {
    limits: AnyObject;
  };
}

const GET_LIMITS = gql`
  query getLimits($domainIdentifier: String!) {
    domain(domainIdentifier: $domainIdentifier) {
      limits
    }
  }
`;

export const useDomainLimitsQuery = (vars: DomainLimitsVars) => {
  return useQuery<DomainLimitsData, DomainLimitsVars>(GET_LIMITS, {
    variables: { ...vars },
  });
};
