import {
  ExpandableSection,
  Header,
} from '@amzn/awsui-components-react/polaris';

const ImpactedAccount = (props) => {
  const impactedAccounts = props.impactedAccounts || [];
  return (
    <ExpandableSection
      defaultExpanded
      variant="container"
      header={
        <Header variant="h2">
          Impacted Accounts ({impactedAccounts.length})
        </Header>
      }
    >
      {impactedAccounts.join(' ')}
    </ExpandableSection>
  );
};
export { ImpactedAccount };
