import React from 'react';
import {
  EuiButton,
  EuiForm,
  EuiFormRow,
  EuiSelect,
  EuiFieldText,
} from '@elastic/eui';

import { ComponentVersionTable } from './Utilities/ComponentVersionTable';
export class DeployComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRequestActive: false,
      componentTable: '',
      componentScope: '',
    };
    this.action = 'ADD';
    this.componentName = '';
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.setRequest = this.setRequest.bind(this);
    this.setComponentTable = this.setComponentTable.bind(this);
  }

  componentDidUpdate() {
    if (this.state.isRequestActive) {
      this.setComponentTable();
    }
  }

  onChangeHandler(event) {
    const name = event.target.name;
    const value = event.target.value;
    switch (name) {
      case 'componentName':
        this.componentName = value;
        break;
      case 'componentScope':
        this.setState({ componentScope: value });
        break;
      case 'componentValue':
        this.componentValue = value;
        break;
      // no default
    }
  }

  setRequest = () => {
    this.setState({ isRequestActive: true });
    this.setState({ componentTable: '' });
  };

  displayComponentTable() {
    return (
      <ComponentVersionTable
        componentName={this.componentName}
        componentScope={this.state.componentScope}
        componentValue={this.componentValue}
        action={this.action}
        stackName={this.props.stackName}
        clusterId={this.props.clusterId}
      />
    );
  }

  setComponentTable() {
    this.setState({ isRequestActive: false });
    this.setState({ componentTable: this.displayComponentTable() });
  }

  render() {
    const scopeOptions = [
      { value: '', text: 'Select Scope' },
      { value: 'GLOBAL', text: 'GLOBAL' },
      { value: 'ACCOUNT', text: 'ACCOUNT' },
    ];
    return (
      <div>
        <EuiForm>
          <EuiFormRow
            label="Component Name"
            id="componentName"
            onChange={this.onChangeHandler}
          >
            <EuiFieldText name="componentName" />
          </EuiFormRow>
          <span id="space2" />
          <EuiFormRow
            label="Component Scope"
            id="componentScope"
            onChange={this.onChangeHandler}
          >
            <EuiSelect
              options={scopeOptions}
              name="componentScope"
              value={this.state.componentScope}
            />
          </EuiFormRow>
          {this.state.componentScope === 'ACCOUNT' && (
            <EuiFormRow
              label="Component Value"
              id="componentValue"
              onChange={this.onChangeHandler}
            >
              <EuiFieldText name="componentValue" />
            </EuiFormRow>
          )}
          <EuiButton id="versionSearchButton" onClick={this.setRequest}>
            search version
          </EuiButton>
        </EuiForm>
        {this.state.componentTable}
      </div>
    );
  }
}
