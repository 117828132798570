import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import moment from 'moment';
import React, { useContext } from 'react';
import { AppContext } from '../utils/appContext';
interface TimezoneToggleProps {
  date: Date;
}

const TimezoneToggle = (props: TimezoneToggleProps) => {
  const appContext = useContext(AppContext);
  const handleToggle = () => {
    switch (appContext.timezone) {
      case 'human':
        appContext.setTimezone('local');
        break;
      case 'local':
        appContext.setTimezone('utc');
        break;
      case 'utc':
        appContext.setTimezone('human');
        break;
    }
  };
  const renderDate = () => {
    switch (appContext.timezone) {
      case 'human':
        const momentDate = moment(props.date);
        return momentDate.fromNow();
      case 'local':
        return props.date.toString();
      case 'utc':
        return props.date.toISOString();
    }
  };
  return (
    <EuiFlexGroup alignItems={'center'} gutterSize={'xs'}>
      <EuiFlexItem grow={false}>
        <div style={{ cursor: 'pointer' }} onClick={handleToggle}>
          {renderDate()}
        </div>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export { TimezoneToggle };
