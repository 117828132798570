import {
  Box,
  Button,
  Checkbox,
  Grid,
  Popover,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator/internal';
import { useState } from 'react';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { languages, themes } from '../../utils/codeViewerConstants';
import { selectedOptionsType } from './../../utils/constants';

export const CodeViewer = (props) => {
  const [wrapLongLines, setWrapLongLines] = useState(true);
  const [showLineNumbers, setShowLineNumbers] = useState(false);
  const [language, setLanguage] = useState(props.language);
  const [theme, setTheme] = useState(props.theme);
  return (
    <SpaceBetween direction="vertical" size="l">
      <Grid
        gridDefinition={[
          { colspan: { xxs: 2 }, offset: { xxs: 5 } },
          { colspan: { xxs: 2 } },
          { colspan: { default: 3, xxs: 1 } },
          { colspan: { default: 3, xxs: 2 } },
        ]}
      >
        <Select
          selectedOption={theme}
          onChange={(selectedTheme) =>
            setTheme(selectedTheme.detail.selectedOption as selectedOptionsType)
          }
          options={themes}
          selectedAriaLabel="Selected"
        />
        <Select
          selectedOption={language}
          onChange={(selectedLang) =>
            setLanguage(
              selectedLang.detail.selectedOption as selectedOptionsType
            )
          }
          options={languages}
          selectedAriaLabel="Selected"
        />
        <Checkbox
          onChange={({ detail }) => setWrapLongLines(detail.checked)}
          checked={wrapLongLines}
        >
          Wrap Lines
        </Checkbox>
        <Checkbox
          onChange={({ detail }) => setShowLineNumbers(detail.checked)}
          checked={showLineNumbers}
        >
          Show Line Numbers
        </Checkbox>
      </Grid>
      <Grid
        gridDefinition={[{ colspan: { xxs: 11 } }, { colspan: { xxs: 1 } }]}
      >
        <div
          style={{
            fontFamily: '"Roboto Mono",Consolas,Menlo,Courier,monospace',
          }}
        >
          <SyntaxHighlighter
            language={language.label}
            style={coy}
            showLineNumbers={showLineNumbers}
            wrapLongLines={wrapLongLines}
          >
            {props.codeData}
          </SyntaxHighlighter>
        </div>
        <Box display="inline-block">
          <Popover
            size="small"
            position="top"
            triggerType="custom"
            dismissButton={false}
            content={
              <StatusIndicator type="success">
                API Response copied
              </StatusIndicator>
            }
          >
            <Button
              // variant="inline-icon"
              iconName="copy"
              onClick={() => {
                navigator.clipboard.writeText(props.codeData);
              }}
            >
              Copy
            </Button>
          </Popover>
        </Box>
      </Grid>
    </SpaceBetween>
  );
};
