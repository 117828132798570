import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../models/types';
 
export interface ViewLogVars {
    bucket: String;
    key: String;
    ticket: String;
    description: String;
}

const VIEW_LOG = gql`
    query ViewLog(
      $bucket: String, 
      $key: String,
      $ticket: String,
      $description: String
    ) {
        viewLog(
          bucket: $bucket, 
          key: $key,
          ticket: $ticket,
          description: $description
        )
    }
`;
 
export const useViewLogQuery = () => {
    return useLazyQuery<AnyObject, ViewLogVars>(VIEW_LOG, {
        errorPolicy: 'all',
    });
};