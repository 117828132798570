import * as React from 'react';
import { Link } from '@amzn/awsui-components-react/polaris/';

interface InfoLinkProps{
  onFollow: ()=>void;
}
const InfoLink = (props:InfoLinkProps) => {
  return (
    <Link variant="info" onFollow={props.onFollow}>
      Info
    </Link>
  );
};

export { InfoLink };