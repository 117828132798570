import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../../models/types';
 

export interface GetCPCellNameVars {
    awsAccountId: String;
}

const GET_CP_CELL_NAME = gql`
    query GetCPCellName(
            $awsAccountId: String
        ){
        getCPCellName(
            awsAccountId: $awsAccountId
        )
    }
`;
 
export const useGetCPCellNameQuery = () => {
    return useLazyQuery<AnyObject, GetCPCellNameVars>(GET_CP_CELL_NAME, {
        errorPolicy: 'all',
    });
};
