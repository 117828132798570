import { gql, useQuery } from '@apollo/client';
import { OtsVerifyItem } from '../../../models/types';

interface OtsVerifyAllData {
  otsVerifyAll: { otsVerifyItems: OtsVerifyItem[] };
}

// export interface OtsAllVars {
//   awsAccountId: string;
// }

const OTS_VERIFY_ALL = gql`
  query otsVerifyGetAll {
    otsVerifyAll {
      otsVerifyItems {
        account_id
        action_required
        current_beta_tokens_attribute
        current_hostclass
        current_ots_state
        current_spie_tags
        domain_id
        domain_name
        expected_ots_state
        last_update_time
        region
      }
    }
  }
`;

// export const useOtsAllQuery = () => {
//   return useLazyQuery<OtsVerifyAllData, OtsAllVars>(OTS_ALL, {
//     errorPolicy: 'all',
//   });
// };

export const useOtsVerifyAllQuery = () => {
  return useQuery<OtsVerifyAllData>(OTS_VERIFY_ALL);
};
