import { Alert, SpaceBetween } from '@amzn/awsui-components-react';
import * as React from 'react';
import { AnalysisStatusChecker } from './AnalysisStatusChecker';
import { HistoricalAnalysis } from './HistoricalAnalysis';
import { TriggerAnalysis } from './TriggerAnalysis';

const HawkEye = (props) => {
  const [visible, setVisible] = React.useState(true);
  // const notice =
  //   'HawkEye UI development in progress. Please stay tuned...!! Analysis triggering and status checker is in working state. Documentation: https://w.amazon.com/bin/view/AWS/SearchServices/ProjectHawkEye/UserGuide';
  return (
    <SpaceBetween direction="vertical" size="l">
      <Alert
        onDismiss={() => setVisible(false)}
        visible={visible}
        dismissAriaLabel="Close alert"
        header="Development in process..!!"
      >
        <span className="custom-wrapping">
          HawkEye UI development in progress. Please stay tuned...!! Analysis
          triggering and status checker is in working state. Please refer
          <a
            className="custom-wrapping"
            href="https://w.amazon.com/bin/view/AWS/SearchServices/ProjectHawkEye/UserGuide"
          >
            Documentation
          </a>{' '}
          to understand API usage.
        </span>
      </Alert>
      <TriggerAnalysis domainIdentifier={props.domainIdentifier} />
      <AnalysisStatusChecker />
      <HistoricalAnalysis />
    </SpaceBetween>
  );
};

export { HawkEye };
