import {
    Box,
    Button,
    Container,
    Header, Modal, SpaceBetween,
    Tabs,
} from '@amzn/awsui-components-react';
import React from "react";
import { TimezoneToggle } from "../../../Components/TimezoneToggle";
import { TableComponent } from '../../../Components/Common/TableComponent';
import { get } from "lodash";
import { useHistory } from "react-router";
import CopyText from "../../../utils/copy-text";

const Policies = (props) => {
    const history = useHistory();

    const policiesVisibleContent = [
        'created_date',
        'last_modified_date',
        'name',
        'type',
        'version'
    ];

    const POLICIES_VISIBLE_CONTENT_OPTIONS = [
        {
            label: 'Options',
            options: policiesVisibleContent.map((c) => {
                return { id: c, label: c };
            }),
        },
    ];

    const POLICIES_FILTERING_PROPERTIES: any =
        policiesVisibleContent.map((c) => {
            return {
                propertyLabel: c,
                key: c,
                groupValuesLabel: `${c} Values`,
                operators:
                    c === 'created_date' || c === 'last_modified_date'
                        ? [':', '!:', '=', '!=', '>', '>=', '<', '<=']
                        : [':', '!:', '=', '!='],
            };
        });

    const POLICIES_DEFAULT_PREFERENCES = {
        pageSize: 10,
        visibleContent: policiesVisibleContent,
        wraplines: false,
        variant: "borderless"
    };

    const [visible, setVisible] = React.useState(false);
    const policiesColumnDefinitions = [
        {
            id: "name",
            header: "Name",
            cell: (e) => (
                <React.Fragment>
                    <CopyText
                        copyText={e.name}
                        copyButtonLabel={''}
                        successText="Policy Name copied"
                        errorText="Policy Name copied"
                    />
                    <Button
                        href={`/juno/${props.awsAccountId}/policy/${e.type == 'data' ? 'access' : 'security'}/${e.type}/${e.name}`}
                        variant="link"
                        onFollow={(event) => {
                            event.preventDefault();
                            history.push({
                                pathname: `/juno/${props.awsAccountId}/policy/${e.type == 'data' ? 'access' : 'security'}/${e.type}/${e.name}`,
                            });
                        }}
                    >
                        <span style={{ color: '#00a1c9' }}>{e['name'] ? e['name'] : ''}</span>
                    </Button>
                </React.Fragment>
            ),
            sortingField: "name",
            isRowHeader: true
        },
        {
            id: "version",
            header: "Version",
            cell: (e) => e['policy_version'] ?
                (
                    <CopyText
                        copyText={e.policy_version}
                        copyButtonLabel={e['policy_version']}
                        successText="Policy Version copied"
                        errorText="Policy Version copied"
                    />
                ) : (
                    ''
                ),
            sortingField: "version"
        },
        {
            id: "created_date",
            header: "Created Date",
            cell: (e) =>
            e.created_date ? (
                <TimezoneToggle date={new Date(e.created_date)} />
            ) : (
                ''
            ),
            sortingField: "created_date",
        },
        {
            id: "last_modified_date",
            header: "Last Modified Date",
            cell: (e) =>
                e.last_modified_date ? (
                <TimezoneToggle date={new Date(e.last_modified_date)} />
            ) : (
                ''
            ),
            sortingField: "last_modified_date",
        },
        {
            id: "type",
            header: "Type",
            cell: (e) => (e.type ? e.type : ''),
            sortingField: 'type',
        }
    ];

    const tabs = [
        {
            id: 'access',
            label: 'Data Access',
            content: (
                <TableComponent
                    tableDefaultPreferences={POLICIES_DEFAULT_PREFERENCES}
                    dataLoading={props.accessDataPoliciesLoading}
                    data={
                        Array.isArray(props.accessDataPoliciesData)
                            ? props.accessDataPoliciesData
                            : get(props.accessDataPoliciesData, 'listAccessPolicies', [])
                    }
                    filteringProperties={POLICIES_FILTERING_PROPERTIES}
                    csvExportFileName={'accessPolicies.csv'}
                    columnDefinitions={policiesColumnDefinitions}
                    visibleContentOptions={POLICIES_VISIBLE_CONTENT_OPTIONS}
                    tableHeaderName={'Policies'}
                />
            ),
            disabled: false,
        },
        {
            id: 'network',
            label: 'Network Access',
            content: (
                <TableComponent
                    tableDefaultPreferences={POLICIES_DEFAULT_PREFERENCES}
                    dataLoading={props.securityNetworkPoliciesLoading}
                    data={
                        Array.isArray(props.securityNetworkPoliciesData)
                            ? props.securityNetworkPoliciesData
                            : get(props.securityNetworkPoliciesData, 'listSecurityPolicies', [])
                    }
                    filteringProperties={POLICIES_FILTERING_PROPERTIES}
                    csvExportFileName={'networkPolicies.csv'}
                    columnDefinitions={policiesColumnDefinitions}
                    visibleContentOptions={POLICIES_VISIBLE_CONTENT_OPTIONS}
                    tableHeaderName={'Policies'}
                />
            ),
            disabled: false,
        },
        {
            id: 'encryption',
            label: 'Encryption',
            content: (
                <TableComponent
                    tableDefaultPreferences={POLICIES_DEFAULT_PREFERENCES}
                    dataLoading={props.securityEncryptionPoliciesLoading}
                    data={
                        Array.isArray(props.securityEncryptionPoliciesData)
                            ? props.securityEncryptionPoliciesData
                            : get(props.securityEncryptionPoliciesData, 'listSecurityPolicies', [])
                    }
                    filteringProperties={POLICIES_FILTERING_PROPERTIES}
                    csvExportFileName={'encryptionPolicies.csv'}
                    columnDefinitions={policiesColumnDefinitions}
                    visibleContentOptions={POLICIES_VISIBLE_CONTENT_OPTIONS}
                    tableHeaderName={'Policies'}
                />
            ),
            disabled: false,
        },
    ];

    console.log(props.securityConfigsData)

    /* If the user is in Account Overview page, then include saml configs too */
    if (props.includeSAML) {
        const configsVisibleContent = [
            'created_date',
            'last_modified_date',
            'id',
            'type',
            'version'
        ];

        const configsColumnDefinitions = policiesColumnDefinitions.slice();
        configsColumnDefinitions.shift();
        configsColumnDefinitions.shift();

        configsColumnDefinitions.unshift(
            {
                id: "version",
                header: "Version",
                cell: (e) => e['config_version'] ?
                    (
                        <CopyText
                            copyText={e.policy_version}
                            copyButtonLabel={e['config_version']}
                            successText="Config Version copied"
                            errorText="Config Version copied"
                        />
                    ) : (
                        ''
                    ),
                sortingField: "version"
            },
        )

        configsColumnDefinitions.unshift(
            {
                id: "id",
                header: "Config Id",
                cell: (e) => (
                    <React.Fragment>
                        <CopyText
                            copyText={e.id}
                            copyButtonLabel={''}
                            successText="Config Id copied"
                            errorText="Config Id copied"
                        />
                        <Button
                            href={`/juno/${props.awsAccountId}/config/${encodeURIComponent(e.id)}`}
                            variant="link"
                            onFollow={(event) => {
                                event.preventDefault();
                                history.push({
                                    pathname: `/juno/${props.awsAccountId}/config/${encodeURIComponent(e.id)}`,
                                });
                            }}
                        >
                            <span style={{ color: '#00a1c9' }}>{e['id'] ? e['id'] : ''}</span>
                        </Button>
                    </React.Fragment>
                ),
                sortingField: "id",
                isRowHeader: true
            }
        )

        const CONFIGS_FILTERING_PROPERTIES: any =
            configsVisibleContent.map((c) => {
                return {
                    propertyLabel: c,
                    key: c,
                    groupValuesLabel: `${c} Values`,
                    operators:
                        c === 'created_date' || c === 'last_modified_date'
                            ? [':', '!:', '=', '!=', '>', '>=', '<', '<=']
                            : [':', '!:', '=', '!='],
                };
            });

        const CONFIGS_DEFAULT_PREFERENCES = {
            pageSize: 10,
            visibleContent: configsVisibleContent,
            wraplines: false,
            variant: "borderless"
        };

        const CONFIGS_VISIBLE_CONTENT_OPTIONS = [
            {
                label: 'Options',
                options: configsVisibleContent.map((c) => {
                    return { id: c, label: c };
                }),
            },
        ];

        tabs.push(
            {
                id: 'saml',
                label: 'SAML',
                content: (
                    <TableComponent
                        tableDefaultPreferences={CONFIGS_DEFAULT_PREFERENCES}
                        dataLoading={props.securityConfigsLoading}
                        data={
                            Array.isArray(props.securityConfigsData)
                                ? props.securityConfigsData
                                : get(props.securityConfigsData, 'listSecurityConfigs', [])
                        }
                        filteringProperties={CONFIGS_FILTERING_PROPERTIES}
                        csvExportFileName={'securityConfigs.csv'}
                        columnDefinitions={configsColumnDefinitions}
                        visibleContentOptions={CONFIGS_VISIBLE_CONTENT_OPTIONS}
                        tableHeaderName={'Configs'}
                    />
                ),
                disabled: false,

            }
        );
    }

    return (
        <Container
            header={<Header variant="h2">List of Policies</Header>}
        >
            <Tabs tabs={tabs}></Tabs>
        </Container>

    );
};

export { Policies };