import {
  EuiFieldNumber,
  EuiFieldText,
  EuiSwitch,
  EuiTextArea,
} from '@elastic/eui';
import React from 'react';

interface ComponentMapping {
  boolean: React.ReactNode;
  text: React.ReactNode;
  number: React.ReactNode;
  array: React.ReactNode;
  [key: string]: any;
}
export const COMPONENT_MAPPING = {
  boolean: ({ value, ...rest }: any) => <EuiSwitch checked={value} {...rest} />,
  text: (props: any) => <EuiFieldText {...props} />,
  number: (props: any) => <EuiFieldNumber {...props} />,
  array: (props: any) => <EuiTextArea {...props} />,
} as ComponentMapping;
