import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../../models/types';


export interface GetWorkerDetailsVars {
    awsAccountId: String;
    workerId: String;
    dataPlaneType: String;
}

const GET_WORKER_DETAILS = gql`
    query GetWorkerDetails(
            $awsAccountId: String
            $workerId: String
            $dataPlaneType: String
        ){
        getWorkerDetails(
            awsAccountId: $awsAccountId
            workerId: $workerId
            dataPlaneType: $dataPlaneType
        )
    }
`;

export const useGetWorkerDetailsQuery = () => {
    return useLazyQuery<AnyObject, GetWorkerDetailsVars>(GET_WORKER_DETAILS, {
        errorPolicy: 'all',
    });
};