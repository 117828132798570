import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../../models/types';

export interface ClusterInfoVars {
  domainIdentifier: string;
  instanceId: string;
}

export interface ElasticResponse {
  cluster: {
    health?: AnyObject;
    settings?: AnyObject;
    stats?: AnyObject;
    state?: AnyObject;
    allocationExplain?: AnyObject;
    decommission?: AnyObject
  };
  cat: {
    nodes?: AnyObject;
  };
}

export interface KibanaResponse {
  health: 'red' | 'green' | 'yellow' | string;
}

export interface ClusterInfoData {
  domain: {
    es60: ElasticResponse;
    kibana: {
      health: KibanaResponse;
    };
    clusterData: {
        serverType: string;
        discoveryMode: string;
    };
  };
}

export const CLUSTER_INFO = gql`
  query getClusterInfo($domainIdentifier: String!, $instanceId: String!) {
    domain(domainIdentifier: $domainIdentifier, instanceId: $instanceId) {
      es60 {
        cluster {
          health
          settings
          stats
          state
          allocationExplain
          decommission
        }
        cat {
          nodes
        }
      }
      kibana {
        health
      }
      clusterData {
        serverType
        discoveryMode
      }
    }
  }
`;

export const useClusterInfoQuery = () => {
  return useLazyQuery<ClusterInfoData, ClusterInfoVars>(CLUSTER_INFO, {
    errorPolicy: 'all',
  });
};
