import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../models/types';

interface UseGetOnDemandDomainVars {
  domainId: string;
}

export const GET_ON_DEMAND_DOMAIN = gql`
  query GetOnDemandDomain(
    $domainId: String
  ) {
    getOnDemandDomain(
      domainId: $domainId
    )
  }
`;

export const useGetOnDemandDomainQuery = () => {
  return useLazyQuery<AnyObject, UseGetOnDemandDomainVars>(GET_ON_DEMAND_DOMAIN,
    {
      fetchPolicy: 'no-cache'
    });
};