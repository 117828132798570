import React from 'react';
import { apiCall, convertEpochToDate } from '../../utils';

import {
  EuiFormRow,
  EuiFieldText,
  EuiToast,
  EuiSpacer,
  EuiButton,
  EuiText,
  EuiLoadingSpinner,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTable,
  EuiTableBody,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell,
} from '@elastic/eui';

export class TaskTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      taskId: '',
      cancelapplytaskerror: '',
      cancelmovetaskerror: '',
      describetaskresult: '',
      describetaskerror: '',
      restartapplytaskerror: '',
      restartmovetaskerror: '',
      describetaskstatus: '',
      loading: false,
    };
    this.taskId = '';
  }

  cancelApplyTaskHandler = async () => {
    const data = { taskID: this.taskId };
    const response = await apiCall('/task/apply/cancel', data);
    if (response.statusCode === 200) {
      this.setState({ cancelapplytaskerror: 'success' });
    } else {
      this.setState({
        cancelapplytaskerror:
          JSON.stringify(response.body) + '--' + this.taskId,
      });
    }
  };

  restartApplyTaskHandler = async () => {
    const data = { taskID: this.taskId };
    const response = await apiCall('/task/apply/restart', data);
    if (response.statusCode === 200) {
      this.setState({ restartapplytaskerror: 'success' });
    } else {
      this.setState({
        restartapplytaskerror:
          JSON.stringify(response.body) + '--' + this.taskId,
      });
    }
  };

  describeTaskCaller = async () => {
    this.taskId = this.state.taskId;
    const data = { taskID: this.taskId };
    const response = await apiCall('/task/describe', data);
    if (response.statusCode === 200) {
      this.setState({ describetaskresult: response.body });
      this.setState({
        describetaskstatus: response.body.taskDetails.taskStatus,
      });
      this.setState({ describetaskerror: '' });
    } else {
      this.setState({ describetaskresult: '' });
      this.setState({
        describetaskerror: JSON.stringify(response.body) + '--' + this.taskId,
      });
    }
    this.setState({ loading: false });
  };

  onChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
  };

  describeTaskHandler = () => {
    this.setState({ loading: true });
    this.setState({ describetaskresult: '' });
    this.setState({ describetaskstatus: '' });
    this.describeTaskCaller();
  };

  onApplyClose = () => {
    this.setState({ cancelapplytaskerror: '' });
  };

  onRestartApplyClose = () => {
    this.setState({ restartapplytaskerror: '' });
  };

  onDescribeTaskClose = () => {
    this.setState({ describetaskerror: '' });
  };

  getDescribetaskError() {
    if (this.state.describetaskerror !== '') {
      return (
        <div>
          <EuiToast
            title="Describe task failed"
            color="danger"
            iconType="alert"
            id="describetasktoast"
            onClose={() => this.onDescribeTaskClose()}
          >
            <EuiText>{this.state.describetaskerror}</EuiText>
          </EuiToast>
          <EuiSpacer size="xl" />
        </div>
      );
    }
  }

  getCancelApplyError() {
    if (this.state.cancelapplytaskerror === 'success') {
      return (
        <div>
          <EuiToast
            title="Cancel Apply Task Successfull"
            color="success"
            iconType="check"
            id="cancelsuccesstoast"
            onClose={() => this.onApplyClose()}
          >
            <EuiText>TASK ID : {this.taskId}</EuiText>
          </EuiToast>
          <EuiSpacer size="xl" />
        </div>
      );
    } else {
      return (
        <div>
          <EuiToast
            title="Cancel Task Failed !!"
            color="danger"
            iconType="alert"
            id="cancelfailuretoast"
            onClose={() => this.onApplyClose()}
          >
            <EuiText>{this.state.cancelapplytaskerror}</EuiText>
          </EuiToast>
          <EuiSpacer size="xl" />
        </div>
      );
    }
  }

  getRestartApplyError() {
    if (this.state.restartapplytaskerror === 'success') {
      return (
        <div>
          <EuiToast
            title="Restart Apply Task Successfull"
            color="success"
            iconType="check"
            id="restartsuccesstoast"
            onClose={() => this.onRestartApplyClose()}
          >
            <EuiText>TASK ID : {this.taskId}</EuiText>
          </EuiToast>
          <EuiSpacer size="xl" />
        </div>
      );
    } else {
      return (
        <div>
          <EuiToast
            title="Restart Apply Task Failed !!"
            color="danger"
            iconType="alert"
            id="restartfailuretoast"
            onClose={() => this.onRestartApplyClose()}
          >
            <EuiText>{this.state.restartapplytaskerror}</EuiText>
          </EuiToast>
          <EuiSpacer size="xl" />
        </div>
      );
    }
  }

  getTaskContent() {
    if (this.state.describetaskresult !== '') {
      const creationDate = convertEpochToDate(
        this.state.describetaskresult.auditProperties.creationTime
      );
      const lastModifiedDate = convertEpochToDate(
        this.state.describetaskresult.auditProperties.lastModificationTime
      );

      return (
        <div>
          <EuiTable>
            <EuiTableBody>
              <EuiTableRow>
                <EuiTableHeaderCell />
              </EuiTableRow>
              <EuiTableRow>
                <EuiTableHeaderCell>Creation Time </EuiTableHeaderCell>
                <EuiTableRowCell>{creationDate}</EuiTableRowCell>
              </EuiTableRow>
              <EuiTableRow>
                <EuiTableHeaderCell>Last Modification Time</EuiTableHeaderCell>
                <EuiTableRowCell>{lastModifiedDate}</EuiTableRowCell>
              </EuiTableRow>
              <EuiTableRow>
                <EuiTableHeaderCell>Last Modified By </EuiTableHeaderCell>
                <EuiTableRowCell>
                  {this.state.describetaskresult.auditProperties.lastModifiedBy}
                </EuiTableRowCell>
              </EuiTableRow>
              <EuiTableRow>
                <EuiTableHeaderCell>Task Id</EuiTableHeaderCell>
                <EuiTableRowCell>
                  {this.state.describetaskresult.taskDetails.taskID}
                </EuiTableRowCell>
              </EuiTableRow>
              <EuiTableRow>
                <EuiTableHeaderCell>Task Status</EuiTableHeaderCell>
                <EuiTableRowCell>
                  {this.state.describetaskresult.taskDetails.taskStatus}
                </EuiTableRowCell>
              </EuiTableRow>
            </EuiTableBody>
          </EuiTable>
          <EuiSpacer size="xl" />
          <EuiFlexGroup>
            <EuiFlexItem style={{ maxWidth: 150 }}>
              <EuiButton
                onClick={this.cancelApplyTaskHandler}
                id="cancel_apply_task"
                isDisabled={this.state.describetaskstatus !== 'FAILED'}
              >
                {' '}
                CANCEL TASK
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem style={{ maxWidth: 150 }}>
              <EuiButton
                onClick={this.restartApplyTaskHandler}
                id="restart_apply_task"
                isDisabled={this.state.describetaskstatus !== 'FAILED'}
              >
                {' '}
                RESTART TASK
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        {this.getDescribetaskError()}
        {this.state.cancelapplytaskerror && this.getCancelApplyError()}
        {this.state.restartapplytaskerror && this.getRestartApplyError()}
        <EuiFormRow
          id="taskId"
          onChange={this.onChangeHandler}
          label="Task Id"
          helpText="Enter the task id"
        >
          <EuiFieldText name="taskId" value={this.state.taskId} />
        </EuiFormRow>
        <EuiButton onClick={this.describeTaskHandler} id="describetask">
          {' '}
          Task Details{' '}
        </EuiButton>
        <EuiSpacer size="xl" />
        {this.state.loading === true && <EuiLoadingSpinner size="l" />}
        {this.getTaskContent()}
      </div>
    );
  }
}
