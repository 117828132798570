import {
  Button,
  Container,
  Grid,
  Header,
  Select,
  SpaceBetween,
  Popover,
  Box,
  Spinner,
  DateRangePicker,
  ExpandableSection,
  Flashbar,
  FormField,
  Link,
} from '@amzn/awsui-components-react';
import HeatMap from "react-heatmap-grid";
import React, { useEffect, useState, useRef } from 'react';
import { get } from 'lodash';
import { CommonDomainProps } from '../../models/types';
import { AutosuggestOptionsType } from './../../utils/constants';
import { useExecuteApiMutation } from '../../hooks/useExecuteApiMutation';
import { isError } from "../../ErrorHandler/apiErrorHandler";
import { ErrorMessageComponent } from "../../ErrorHandler/errorMessageComponent";
import { ApiList } from "../../ErrorHandler/utils";
import { useParams } from 'react-router';
import moment from 'moment';
import {
    i18nStrings, isValidRequestRangeForOnDemand,
    relativeOptions,
    REQUEST_LOGS_INSTRUCTION_HEADER,
    REQUEST_LOGS_INSTRUCTIONS, timezoneOptions
} from "../Logs/constants";
import {useRequestOnDemandDataMutation} from "../OnDemand/useRequestOnDemandData";
import {ListExecutions} from "../OnDemand/ListExecutions";

export const API_OPTIONS = [
    {
        value: 'Latency',
    },
    {
        value: 'CPU_Utilization',
    },
    {
        value: 'IO_TotThroughput',
    },
    {
        value: 'ShardBulkDocs',
    },
    {
        value: 'ShardEvents',
    },
];

interface PerformanceAnalyserProps extends CommonDomainProps {
  domainIdentifier: string;
  instanceId: string;
  metric: string;
  authUser: boolean
}

interface PAMetric {
  metric: AutosuggestOptionsType;
}

/**
 * Performance analyser component to fetch PA metrics and RCA insights
 */
const PerformanceAnalyser = (props: PerformanceAnalyserProps) => {
  const [executeApi, { loading, error }] = useExecuteApiMutation();
  const [apiResponse, setApiResponse] = useState<string | object>('');
  const [isPAEnabled, setIsPAEnabled] = useState<boolean>(null);
  const [formValues, setFormValues] = useState<PAMetric>({
      metric: props.metric
  });

  const params = useParams();
  const clientId = params['clientId'];
  const domainName = params['domainName'];
  const ListExecutionsRef = useRef();

  const [selectedRequestTimeZoneOption, setSelectedRequestTimeZoneOption] =
    useState({
      inputDisplay: 'UTC',
      label: 'UTC',
      value: 'UTC',
      code: 'UTC',
      offset: '0000',
      mins: 0,
    });

  const [requestDate, setRequestDate] = useState<any>({
    type: 'relative',
    amount: 3,
    unit: 'hour',
  });
  const [flashbarItems, setFlashbarItems] = useState([]);
  const [dataAvailabilityFlashbarItems, setDataAvailabilityFlashbarItems] = useState([
      {
        type: 'info',
        content: 'PA data is available from 11th december 2023.',
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([]),
      },
  ])
  const [requestOnDemandLogs] = useRequestOnDemandDataMutation();
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [submit, setSubmit] = useState(false)
  const handleRequestLogs = async () => {
    var startMoment, endMoment;

    if ('absolute' === requestDate.type) {
      startMoment = moment(requestDate.startDate);
      endMoment = moment(requestDate.endDate);
    } else if ('relative' === requestDate.type) {
      startMoment = moment().subtract(requestDate.amount, requestDate.unit);
      endMoment = moment();
    }

    const startTimeStamp = startMoment.utc().format('YYYYMMDDTHHmm') + 'Z';
    const endTimeStamp = endMoment.utc().format('YYYYMMDDTHHmm') + 'Z';
    setStartTime(startTimeStamp)
    setEndTime(endTimeStamp)
      try{
        var resp;
          resp = await requestOnDemandLogs({
            variables: {
              domainId: clientId + ':' + domainName,
              eventType: 'PA',
              startTime: startTimeStamp,
              endTime: endTimeStamp,
              reBackfill: 'false'
            },
          });
          if (resp?.data?.requestOnDemandData?.success) {
            const status = resp?.data?.requestOnDemandData?.data;
            setFlashbarItems([
              {
                type: 'success',
                content: status,
                dismissible: true,
                dismissLabel: 'Dismiss message',
                onDismiss: () => setFlashbarItems([]),
              },
            ]);
          } else {
            const errorMessage = resp?.data?.requestOnDemandData?.error;
            setFlashbarItems([
              {
                type: 'error',
                content: `Error occured while requesting backfill. ${errorMessage}`,
                dismissible: true,
                dismissLabel: 'Dismiss message',
                onDismiss: () =>
                    setFlashbarItems([]),
                id: 'on_demand_error',
              },
            ]);
          }
          setSubmit(true)
      } catch (e) {
          // TODO
        }
  };
  const handleRequestDateChange = ({ detail }) => {
    setRequestDate(detail.value);
  };
  const handleRequestTimezoneChange = (selectedOption) => {
    setSelectedRequestTimeZoneOption(selectedOption);
  };
  const [errorMessage, setErrorMessage] = useState(null)
  const isErrorPresent = isError(error)
  useEffect(() => {
    if (isErrorPresent) {
      setErrorMessage("Unable to get Performance Analyser. Failed with " + error.message);
    }
  }, [isErrorPresent, error]);

    /**
     * Checks if PA is enabled on the cluster and update react state.
     */
  const handlePAEnabled = async() => {
      const resp = await executeApi({
          variables: {
              url: `_opendistro/_performanceanalyzer/cluster/config`,
              domainIdentifier: props.domainIdentifier,
              instanceId: props.instanceId
          }
      });
      let esResponse = get(resp, 'data.executeGetAPI.data', '');
      try {
          setIsPAEnabled(esResponse.currentPerformanceAnalyzerClusterState > 0);
      } catch (e) {
          return setIsPAEnabled(false);
      }
  }

    /**
     * Fetches PA metrics for selected metric. Fetches metric for past 5 min stored on cluster. Metrics response is
     * updated to react state.
     */
  const handleExecuteApi = async () => {
    try {
      const  starTimeMillis = Date.now() - 300000;
      const  endTimeMillis = Date.now();
      const resp = await executeApi({
        variables: {
          url: `_opendistro/_performanceanalyzer/_agent/metrics`,
          domainIdentifier: props.domainIdentifier,
          instanceId: props.instanceId,
          queryParams: "metrics=" + formValues.metric.value + "&agg=max&dim=ShardID,IndexName&nodes=all&starttime="
              + starTimeMillis + "&endtime=" + endTimeMillis + "samplingperiod=5"
        }
      });

      let esResponse = get(resp, 'data.executeGetAPI.data', '');
      try {
        esResponse = JSON.parse(esResponse);
      } catch (e) {
        // Ignoring error response could be in text format
      } finally {
        setApiResponse(esResponse);
      }
    } catch (e) {
      // TODO
    }
  };

  /**
   * Fetches and updates states on page load.
   */
  useEffect(() => {
      handlePAEnabled();
      handleExecuteApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    function renderNoDataAvailable() {
        return (
            <div style={{marginTop: "26px"}}>
                <strong>No data available</strong>
            </div>
        );
    }

    /**
   * Renders heat map based on metrics fetched from PA. Heat map has list of nodes as y axis and shards as x axis.
   * Each cell represents  metric value of the shard running on the instance. On click each cell provides more details
   * about the shard
   */
  function renderHeatMap() {
    if (!apiResponse) {
      return;
    }

    let nodeIds = [];
    let dataValuesList = [];
    let maxShardsPerNode = 0;
    let cellOverview = {};
    for (let nodeId in apiResponse) {

        if (!apiResponse[nodeId].data || !apiResponse[nodeId].data.records) {
            continue;
        }
        cellOverview[nodeId] = {};
        let records = apiResponse[nodeId].data.records;
        let nodeRecords = {};
        let dataValues = [];
        let nodeShardCount = 0;
        records.filter(record => record[0] !== null)
            .sort((a, b) => b[2] - a[2])
            .forEach((record, counter) => {
                let nodeRecord = {};
                nodeRecord['ShardID'] = record[0];
                nodeRecord['IndexName'] = record[1];
                nodeRecords[record[0]] = nodeRecord;
                cellOverview[nodeId][counter] = nodeRecord;
                dataValues.push(record[2]);
                nodeShardCount++;
            });

        // Skip if there is no metric available for the node
        if (dataValues.length == 0) {
            continue;
        }

        nodeIds.push(nodeId);
        dataValuesList.push(dataValues);
        if (nodeShardCount > maxShardsPerNode) {
            maxShardsPerNode = nodeShardCount;
        }

    }

    let xLabelsVisibility = new Array(maxShardsPerNode).fill(false);
    let dimIds = [...Array(maxShardsPerNode).keys()];

    if (nodeIds.length == 0) {
        return renderNoDataAvailable();
    } else {
        return (<HeatMap
            xLabels={dimIds}
            yLabels={nodeIds}
            xLabelsVisibility={xLabelsVisibility}
            yLabelWidth={200}
            xLabelWidth={0}
            data={dataValuesList}
            squares
            height={45}
            cellStyle={(background, value, min, max, data, x, y) => ({
                background: `rgb(255, 0, 0, ${1 - (max - value) / (max - min)})`,
                fontSize: "11.5px",
                color: "#090909"
            })}
            cellRender={(value, x, y) => value && <div>
                <Popover
                    dismissButton={false}
                    position="top"
                    size="small"
                    triggerType="custom"
                    content={JSON.stringify(cellOverview[y][x], null, 2)}
                >
                    {String(value).substring(0, 5)}
                </Popover>
            </div>}
        />);
    }
  }

  /**
   * Decides which component to render
   */
  function renderContent() {
   if (errorMessage) {
       return <ErrorMessageComponent errorMessage={errorMessage} apiName={ApiList.PERFORMANCE_ANALYSER}/>;
   } else {
       if (isPAEnabled == null) {
          return renderLoading();
      }
      if (isPAEnabled == false) {
          return renderPANotEnabled();
      }
      return renderPAMetricViewer();
   }
  }

  /**
   * Renders loading screen while fetching data.
   */
  function renderLoading() {
      return (
          <Box textAlign="center">
              <Spinner size="large" />
          </Box>
      )
  }

  /**
   * Renders information if PA is not enabled.
   */
  function renderPANotEnabled() {
      return ( <div><strong>PA is not enabled in cluster</strong></div>);
  }

  /**
   * Renders heat map if PA is enabled.
   */
  function renderPAMetricViewer() {
      return (
          <div>
              <SpaceBetween direction="vertical" size="l">
                  <Grid
                      gridDefinition={[
                          { colspan: { default: 5, xxs: 5 } },
                          { colspan: { default: 5, xxs: 5 } },
                          { colspan: { default: 7, xxs: 2 } },
                      ]}
                  >
                      <FormField
                          constraintText="Performance analyser metric to analyse"
                          label={<strong>PA metric for last 5 min</strong>}
                      >
                          <Select
                              selectedOption={formValues.metric}
                              onChange={(selectedApiRoot) => {
                                  console.log(selectedApiRoot.detail.selectedOption);
                                  setFormValues((state) => ({
                                      ...state,
                                      metric: selectedApiRoot.detail
                                          .selectedOption as AutosuggestOptionsType
                                  }));
                              }}
                              options={API_OPTIONS}
                              selectedAriaLabel="Selected"
                              placeholder="Choose metric"
                          />
                      </FormField>
                      <div style={{ marginTop: "26px" }}>
                          <Button variant="normal" onClick={handleExecuteApi} loading={loading}>
                              Execute
                          </Button>
                      </div>
                  </Grid>
              </SpaceBetween>
              {renderHeatMap()}
          </div>

      );
  }

  // React component default return
  return (
      <>
        <SpaceBetween direction="vertical" size="l">
            <Container
              header={
                <div>
                  <Header variant="h2">Performance analyser</Header>
                </div>
              }
            >
                <div>
                   {renderContent()}
                </div>
            </Container>
            <Container>
                  <Flashbar items={dataAvailabilityFlashbarItems}/>
                  <Flashbar items={flashbarItems} />
              <ExpandableSection
                header={
                  <Header
                    variant="h2"
                    info={
                      <div
                        style={{ display: 'inline-block' }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Popover
                          dismissAriaLabel="Close"
                          fixedWidth
                          header={REQUEST_LOGS_INSTRUCTION_HEADER}
                          position="right"
                          size="large"
                          triggerType="text"
                          renderWithPortal={true}
                          content={REQUEST_LOGS_INSTRUCTIONS.map((line) => (
                            <p>{line}</p>
                          ))}
                        >
                          <Link variant="info">Info</Link>
                        </Popover>
                      </div>
                    }
                  >
                    Request PA Data
                  </Header>
                }
                variant="container"
              >
                <SpaceBetween direction="horizontal" size="l">
                    <FormField label={<strong>Date</strong>}>
                      <DateRangePicker style={{ marginRight: '20px', marginLeft : '20px' }}
                        onChange={handleRequestDateChange}
                        value={requestDate}
                        relativeOptions={relativeOptions}
                        i18nStrings={i18nStrings}
                        placeholder="Filter by a date and time range"
                        isValidRange={isValidRequestRangeForOnDemand}
                        showClearButton={false}
                        timeOffset={selectedRequestTimeZoneOption.mins}
                      />
                    </FormField>
                    <FormField label={<strong>Time Zone</strong>}>
                      <Select style={{ marginRight: '20px', marginLeft : '20px' }}
                        selectedOption={selectedRequestTimeZoneOption}
                        onChange={({ detail }) =>
                          handleRequestTimezoneChange(detail.selectedOption)
                        }
                        options={timezoneOptions}
                        selectedAriaLabel="Selected"
                      />
                    </FormField>
                    <div style={{ marginTop: '26px' }}>
                      <Button variant="normal" onClick={handleRequestLogs}>
                        Submit
                      </Button>
                    </div>
                </SpaceBetween>
              </ExpandableSection>
            </Container>
            {submit && (
                <Container>
                    <ListExecutions type='PA' startTime={startTime} endTime={endTime} ref={ListExecutionsRef}/>
                </Container>
            )}
            <Container>
                <ListExecutions type='PA' ref={ListExecutionsRef}/>
            </Container>
        </SpaceBetween>
      </>
  );
};

PerformanceAnalyser.defaultProps = {
    metric: { value: 'CPU_Utilization' },
} as Partial<PerformanceAnalyserProps>;

export { PerformanceAnalyser };
