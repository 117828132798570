
import React from 'react';
import { usePutCwLogs } from "../Components/hooks/usePutCwLogs";
import getDomainIdentifierFromParams from "./utils";
import { CommonDomainProps } from "../models/types";

interface errorMessageComponentProps extends CommonDomainProps {
  errorMessage: string;
  apiName: string;
}

interface lseErrorMessageComponentProps extends CommonDomainProps {
  errorMessage: string;
}

export const ErrorMessageComponent = (props:errorMessageComponentProps ) => {

  const domainIdentifier = getDomainIdentifierFromParams();

  usePutCwLogs({
        logsMessage: props.errorMessage, domainIdent: domainIdentifier, apiName: props.apiName
    })

  return (
    <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
      {props.errorMessage}
    </div>
  );
};


//will optimise/generalise if extended to more components
export const LseErrorMessageComponent = (props:lseErrorMessageComponentProps) => {
  return (
    <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
      {props.errorMessage}
    </div>
  );
}
