export const VISIBLE_COLUMNS = [
  'analysis',
  'createdBy',
  'startTime',
  'endTime',
  'status',
];

export const VISIBLE_CONTENT = [
  'analysis',
  'createdBy',
  'startTime',
  'endTime',
  'status',
];

export const VISIBLE_CONTENT_OPTIONS = {
  title: 'Select visible content',
  options: [
    {
      label: '',
      options: [
        { id: 'analysis', label: 'Analysis' },
        { id: 'createdBy', label: 'Created By' },
        { id: 'startTime', label: 'Startx Time' },
        { id: 'endTime', label: 'End Time' },
        { id: 'status', label: 'Status' },
      ],
    },
  ],
};

export const PAGE_SIZE_PREFERENCE = {
  title: 'Select page size',
  options: [
    { value: 5, label: '5 resources' },
    { value: 10, label: '10 resources' },
  ],
};

export const COLUMN_DEFINITIONS = [
  {
    id: 'analysis',
    header: 'Analysis',
    cell: (item) => item.analysis || '-',
  },
  {
    id: 'createdBy',
    header: 'Created By',
    cell: (item) => item.createdBy || '-',
  },
  {
    id: 'startTime',
    header: 'Start Time',
    cell: (item) => item.startTime || '-',
  },
  {
    id: 'endTime',
    header: 'End time',
    cell: (item) => item.endTime || '-',
  },
  {
    id: 'status',
    header: 'Status',
    cell: (item) => item.status || '-',
  },
];
