import { getInitialAppContext } from './../../utils/appContext';
import { junoSupportedRegionStage } from './constants';


const appContext = getInitialAppContext();
const stage = appContext.stage;
const airportRegionCode = appContext.region.airportCode.toLowerCase();

export const isJunoSupportedRegion = () => {
  var _isJunoSupportedRegion = false;
  if (stage in junoSupportedRegionStage) {
    if (junoSupportedRegionStage[stage].includes(airportRegionCode)) {
      _isJunoSupportedRegion = true;
    }
  }
  return _isJunoSupportedRegion;
}

export const dashboardConfig = (
  region: string,
  accountId: string,
  stage: string
) => {
  return {
    displayMode: 'full',
    widgetMarginX: 10,
    widgetMarginY: 10,
    style: 'polaris',
    internal: {
      accountId: accountId,
      stage: stage,
    },
    region,
  };
};

export const junoBeholderDashboardStyle = {
  overflowY: 'auto',
  height: '100%',
  position: 'absolute',
  paddingRight: '30px',
};