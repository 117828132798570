import { gql, useQuery } from '@apollo/client';
import { OtsVerifyItem } from '../../../../models/types';

interface OtsVerifyAllData {
  otsVerifyActionableAll: { otsVerifyItems: OtsVerifyItem[] };
}

const OTS_ACTIONABLE_VERIFY_ALL = gql`
  query otsVerifyActionableGetAll {
    otsVerifyActionableAll {
      otsVerifyItems {
        account_id
        action_required
        current_beta_tokens_attribute
        current_hostclass
        current_ots_state
        current_spie_tags
        domain_id
        domain_name
        expected_ots_state
        last_update_time
        region
      }
    }
  }
`;

export const useOtsVerifyActionableAllQuery = () => {
  return useQuery<OtsVerifyAllData>(OTS_ACTIONABLE_VERIFY_ALL);
};
