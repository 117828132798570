import {
  SpaceBetween
} from '@amzn/awsui-components-react'
import { useRef, useState } from 'react';
import { RequestLogs } from './RequestLogs';
import { BrowseLogs } from './BrowseLogs';
import { ListExecutions } from "../OnDemand/ListExecutions";
import React, { useContext, useEffect, } from 'react';

const Logs = (props) => {
const browseLogsRef = useRef();
const ListExecutionsRef = useRef();
const [isOnDemandLogs, setOnDemandLogs] = useState(false);



const callHandleBrowseDateChanged = (isOnDemand) => {
    setOnDemandLogs(isOnDemand)
      if (isOnDemand) {
          ListExecutionsRef.current?.callRefreshExecutionList();
        } else {
          browseLogsRef.current?.callHandleBrowseDateChanged();
        }
}


return (
  <SpaceBetween direction="vertical" size="l">
    <RequestLogs 
        refreshData={callHandleBrowseDateChanged}
    />
    {isOnDemandLogs === true ? (
      <ListExecutions type='ELB' ref={ListExecutionsRef} />
      ) : (
        <BrowseLogs ref={browseLogsRef} />
      )}
  </SpaceBetween>
)
}

export { Logs };