import React from 'react';

import { EuiText } from '@elastic/eui';

export default class TextErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <EuiText size="xs" color="danger">
          {'UI ERROR'}
        </EuiText>
      );
    } else {
      return this.props.children;
    }
  }
}
